import { createSelector } from 'reselect';

const selectAgentDetails = (state) => state.agent;

export const selectAgent = createSelector(
  [selectAgentDetails],
  (agent) => agent.agent
);

export const selectAgents = createSelector(
  [selectAgentDetails],
  (agent) => agent.agents
);

export const selectCarriers = createSelector(
  [selectAgentDetails],
  (agent) => agent.carriersFromDB
);

export const selectCarriersObj = createSelector(
  [selectAgentDetails],
  (agent) => agent.carriersObj
);

export const selectStates = createSelector(
  [selectAgentDetails],
  (agent) => agent.statesFromDB
);

export const selectOccupations = createSelector(
  [selectAgentDetails],
  (agent) => agent.occupationsFromDB
);

export const selectCurrentStep = createSelector(
  [selectAgentDetails],
  (agent) => agent.currentStep
);

export const selectFormData = createSelector(
  [selectAgentDetails],
  (agent) => agent.formData
);

export const selectVerifyAgent = createSelector(
  [selectAgentDetails],
  (agent) => agent.verifyAgent
);

export const selectClientToken = createSelector(
  [selectAgentDetails],
  (agent) => agent.clientToken
);

export const selectStartPhoneVerify = createSelector(
  [selectAgentDetails],
  (agent) => agent.startPhoneVerify
)

export const selectIsPhoneVerified = createSelector(
  [selectAgentDetails],
  (agent) => agent.isPhoneVerified
)

export const selectSendPhoneErr = createSelector(
  [selectAgentDetails],
  (agent) => agent.sendPhoneErr
)

export const selectCodePhoneErr = createSelector(
  [selectAgentDetails],
  (agent) => agent.codePhoneErr
)

export const selectPersonVeriff = createSelector(
  [selectAgentDetails],
  (agent) => agent.personVeriff
)

export const selectAttemptsVeriff = createSelector(
  [selectAgentDetails],
  (agent) => agent.attemptsVeriff
)