import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { DatePicker } from '../../../components/date-picker/date-picker.component';
import { HEALTH_QUOTE_CMS_DATA } from '../../../pages/quotes/health/health.cms';
import Checkbox from '../../checkbox/checkbox.component';
import { checked } from '../../utils/checked.utils';
import InfoIcon from '../../info-icon/info-icon.component';
import '../../../App.css';

const PreExistingConditions = ({
  values,
  touched,
  errors,
  handleChange,
}) => {
  const {
    form: {
      preExistingConditions: {
        title,
        label,
        description,
        expDelDate,
        additionalInfo,
        preExitingArr,
      },
    },
  } = HEALTH_QUOTE_CMS_DATA;

  const [showDeliveryDateView, setShowDeliveryDateView] = useState(false);
  
  useEffect(() => {
    values.isPregnant === true
      ? setShowDeliveryDateView(true)
      : setShowDeliveryDateView(false);
  }, [values.isPregnant, setShowDeliveryDateView]);

  useEffect(() => {
    if (values.isPregnant === false) {
      values.expDelDate = '';
    }
  }, [values.expDelDate, values.isPregnant, showDeliveryDateView]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Label className='ml-1'>{label}</Form.Label>
            {
              preExitingArr &&
                preExitingArr.length !== 0 ? (
                  <>
                    {preExitingArr.map((item, i) => (
                      <Form.Group
                        key={i}
                        className='mr-2 ml-1'
                        controlId={item.controlId}
                      >
                        <Checkbox
                          name={item.value}
                          id={item.controlId}
                        />
                        <Form.Label
                          className={checked(values[item.value])}
                        > 
                          <InfoIcon 
                            content={item.detail}
                            label={item.label}
                          />
                        </Form.Label> 
                      </Form.Group>
                    ))}
                  </>
            ) : null}
          </Form.Row>
          {showDeliveryDateView ? (
            <Form.Row>
              <Form.Group as={Col} lg={12} controlId='delivery-date'>
                <Form.Label>{expDelDate}</Form.Label>
                <DatePicker
                  name='expDelDate'
                  value={values.expDelDate}
                  minDate={new Date()}
                  maxDate={new Date('01-01-2100')}
                  autoComplete='nope'
                  touched={touched}
                  errors={errors}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.expDelDate}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          ) : null}
          <Form.Row>
            <Form.Group as={Col} lg={12} controlId='additional-info'>
              <Form.Label>{additionalInfo}</Form.Label>
              <Form.Control
                name='preExistAddInfo'
                as='textarea'
                rows={5}
                value={values.preExistAddInfo}
                onChange={handleChange}
                isValid={
                  touched.preExistAddInfo &&
                  !errors.preExistAddInfo
                }
                isInvalid={!!errors.preExistAddInfo}
                maxLength={255}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.preExistAddInfo}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

export default PreExistingConditions;
