import * as yup from 'yup';
import { RESET_PASSWORD_CMS_DATA } from './reset-password.cms';

const { password, errObj } = RESET_PASSWORD_CMS_DATA;

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(password.required)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, password.invalid),
  confirmPassword: yup
    .string()
    .required(password.required)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, password.invalid)
    .test('password-match', errObj.notMatches, function(value) {
      return this.parent.password === value;
    })
});

export default resetPasswordSchema;
