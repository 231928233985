import * as yup from 'yup';

const VehicleSchema = yup.object().shape({
  vin: yup
    .string()
    .trim()
    .typeError('Please enter a VIN')
    .required('Please enter a VIN')
    .min(9, 'Please enter a VIN with at least nine characters')
    .max(18, 'VIN cannot be more than 18 characters'),
  year: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Please enter a year')
    .min(1900, 'Please enter a valid year > 1900')
    .max(2050, 'Please enter a valid year < 2050'),
  make: yup
    .string()
    .trim()
    .typeError('Please provide make')
    .required('Please provide make'),
  model: yup
    .string()
    .trim()
    .typeError('Please enter a model')
    .required('Please enter a model'),
  subModel: yup.string()
    .trim()
    .max(20, 'Please limit entry to 20 characters'),
  ownership: yup
    .string()
    .trim()
    .typeError('Please provide ownership')
    .required('Please provide ownership'),
  primaryUse: yup
    .array()
    .typeError('Please enter primary usage(s)')
    .required('Please enter primary usage(s)'),
  parking: yup
    .array()
    .typeError('Please enter night parking(s)')
    .required('Please enter night parking(s)'),
  additionalInformation: yup
    .string()
    .trim()
    .max(255, 'Please limit entry to 255 characters'),
  annualMileage: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Please enter annual mileage')
    .min(1, 'Please enter a mileage > 0')
    .max(250000, 'Please enter a mileage < 250000'),
});

export default VehicleSchema;
