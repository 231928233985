const healthActionTypes = {
  FETCH_HEALTH_FROM_DB_START: 'FETCH_HEALTH_FROM_DB_START',
  FETCH_HEALTH_FROM_DB_SUCCESS: 'FETCH_HEALTH_FROM_DB_SUCCESS',
  FETCH_HEALTH_FROM_DB_FAILURE: 'FETCH_HEALTH_FROM_DB_FAILURE',
  FETCH_HEALTHS_FROM_DB_START: 'FETCH_HEALTHS_FROM_DB_START',
  FETCH_HEALTHS_FROM_DB_SUCCESS: 'FETCH_HEALTHS_FROM_DB_SUCCESS',
  FETCH_HEALTHS_FROM_DB_FAILURE: 'FETCH_HEALTHS_FROM_DB_FAILURE',
  START_HEALTH_SAVE: 'START_HEALTH_SAVE',
  START_HEALTH_UPDATE: 'START_HEALTH_UPDATE',
  START_HEALTH_QUOTE_PAGE_SAVE: 'START_HEALTH_QUOTE_PAGE_SAVE',
  HEALTH_QUOTE_PAGE_SAVE_SUCCESS: 'HEALTH_QUOTE_PAGE_SAVE_SUCCESS',
  START_HEALTH_QUOTE_PAGE_UPDATE: 'START_HEALTH_QUOTE_PAGE_UPDATE',
  HEALTH_QUOTE_PAGE_UPDATE_SUCCESS: 'HEALTH_QUOTE_PAGE_UPDATE_SUCCESS',
  TOGGLE_HEALTH_FORM_VIEW: 'TOGGLE_HEALTH_FORM_VIEW',
  SET_MEMBER_HEALTH_ID: 'SET_MEMBER_HEALTH_ID',
  SET_MEMBER_ID: 'SET_MEMBER_ID',
  SET_IS_SUBMITTED: 'SET_IS_SUBMITTED',
  SET_CLEAR_HEALTH_FORM: 'SET_CLEAR_HEALTH_FORM',
  RESET_FORM: 'RESET_FORM'
};

export default healthActionTypes;
