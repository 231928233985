import React from 'react';
import DatePicker from 'react-datepicker';
import { useFormikContext } from 'formik';

const AppDatePicker = ({ name, errors, touched, type, value, ...props }) => {
  const { setFieldValue } = useFormikContext();
  
  return (
    <DatePicker
      {...props}
      className={
        props.currentIndex !== undefined
          ? `form-control ${touched[type] ? `is-${!!errors[type] ? 'invalid' : 'valid'}` : ''}`
          : `form-control ${touched[name] ? `is-${!!errors[name] ? 'invalid' : 'valid'}` : ''}`
      }
      onChange={(value) => setFieldValue(name, value)}
      isValid={touched[name] && !errors[name]}
      isInvalid={!!errors[name]}
      selected={value ? new Date(value) : ''}
      showYearDropdown
      dateFormatCalendar='MMMM'
      yearDropdownItemNumber={15}
      scrollableYearDropdown
    />
  );
};

export { AppDatePicker as DatePicker };
