import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  clearErrMessage,
  startLogin,
  setIsSubmitted,
  startFetchingUsersFromDB,
} from '../../redux/auth/auth.actions';
import {
  selectDisplayMessage,
  selectUsers,
} from '../../redux/auth/auth.selectors';
import { toggleLoginRegistrationView } from '../../redux/navigation/navigation.actions';
import { selectIsInitiatedFromAgentShowcase } from '../../redux/navigation/navigation.selectors';
import { LOGIN_CMS_DATA } from './login.cms';
import loginSchema from './login.validation.schema';
import ShowModal from '../modal/show-modal.component';
import chrome from './chrome-icon.webp';

const Login = ({
  history,
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  users,
  displayMessage,
  clearErrMessage,
  startFetchingUsersFromDB,
  isInitiatedFromAgentShowcase,
  toggleLoginRegistrationView,
}) => {
  const { email, password, action, label, modal } = LOGIN_CMS_DATA;

  const [isMemberLoginAsAgent, setIsMemberLoginAsAgent] = useState(false);
  const [isInterestInAgent, setIsInterestInAgent] = useState(false);
  const [userEmails, setUserEmails] = useState([]);
  const [storeUsers, setStoreUsers] = useState([]);
  const [show, setShow] = useState(false);

  const path = window.location.pathname;
  const agentHP = '/agent-home';
  const quoteSId = document.getElementById('quote-steps');
  const displayC = document.querySelector('.display-b');
  const quoteSH = quoteSId ? quoteSId.getBoundingClientRect().height : 0;
  const displayH = displayC ? displayC.getBoundingClientRect().height : 0;

  const emailVal = values.email;

  let isMember;
  let isAgent;

  if (
    path.includes('member') ||
    path === '/'
  ) {
    isMember = true;
  }

  if (path.includes('agent')) {
    isAgent = true;
  }

  const handleClick = () => {
    clearErrMessage();
    toggleLoginRegistrationView('registration');
    if (isMember) {
      window.scrollTo({
        top: displayH + quoteSH,
        behavior: 'smooth'
      });
    }
    if (isAgent) {
      window.scrollTo({
        top: 130,
        behavior: 'smooth'
      });
    }
  };

  const handleCancel = () => {
    if (show) {
      setShow(false);
      setIsMemberLoginAsAgent(false);
      setIsInterestInAgent(false);
    }
    history.push('/');
  }

  const handleClose = () => {
    if (show) {
      setShow(false);
    }
    setIsInterestInAgent(false);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    startFetchingUsersFromDB();
  }, [startFetchingUsersFromDB]);

  useEffect(() => {
    if (users && users.length > 0) {
      for (let u of users) {
        if (u.email && isInitiatedFromAgentShowcase) {
          if (userEmails < users.length) {
            setUserEmails(userEmails => [...userEmails, u.email]);
            setStoreUsers(storeUsers => [
              ...storeUsers,
              {
                email: u.email,
                isMember: u.isMember,
                isAgent: u.isAgent,
              }
            ])
          }
        }
      }
    }
  }, [
    users,
    storeUsers,
    setStoreUsers,
    setUserEmails,
    userEmails,
    isInitiatedFromAgentShowcase,
  ])

  useEffect(() => {
    if (userEmails && userEmails.length > 0) {
      if (emailVal !== '' && !userEmails.includes(emailVal)) {
        setIsMemberLoginAsAgent(false);
      } 
    }
  }, [
    emailVal,
    userEmails,
    setIsMemberLoginAsAgent,
  ])

  useEffect(() => {
    if (storeUsers && storeUsers.length > 0) {
      for (let u of storeUsers) {
        if (
          u.email === emailVal &&
          !u.isAgent &&
          isInitiatedFromAgentShowcase
        ) {
          setIsMemberLoginAsAgent(true);
        }
        if (
          u.email === emailVal &&
          u.isAgent &&
          isInitiatedFromAgentShowcase
        ) {
          setIsMemberLoginAsAgent(false);
        }
      }
    }
  }, [
    storeUsers,
    emailVal,
    setIsMemberLoginAsAgent,
    isInitiatedFromAgentShowcase,
  ])

  return (
    <>
      <Card className=
        {path === agentHP
          ? 'rounded border-0 text-dark mt-3 login-card'
          : 'rounded border-green text-dark login-card'
        }
      >
        <Card.Body>
          <FormikForm>
            <p className='pb-0 lh-sm text-center text-d-pink font-weight-normal'>
              <img
                alt='Chrome'
                src={chrome}
                height='34'
                width='34'
                className='mr-2 google-icon'
              />
              {path === agentHP ? (
                'Please use Chrome web browser!'
              ) : (
                'Recommend using Chrome browser!'
              )} 
            </p>
            <Form.Group controlId='loginEmail' className='mb-3'>
              {/* <Form.Label>{email.label}</Form.Label> */}
              <Form.Control
                // readOnly={path !== agentHP ? true : false}
                type='email'
                name='email'
                className='login-border'
                placeholder={email.placeholder}
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
                onClick={() => clearErrMessage()}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='loginPassword' className='mb-3'>
              {/* <Form.Label>{password.label}</Form.Label> */}
              <Form.Control
                // readOnly={path !== agentHP ? true : false}
                type='password'
                name='password'
                className='login-border'
                placeholder={password.placeholder}
                value={values.password}
                onChange={handleChange}
                isValid={touched.password && !errors.password}
                isInvalid={!!errors.password}
                onClick={() => clearErrMessage()}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.password}
              </Form.Control.Feedback>
              {displayMessage && !isMemberLoginAsAgent ? (
                <div className='error_message'>{displayMessage}</div>
              ) : null}
            </Form.Group>
            <Form.Text className='text-right mb-3 mt-0'>
              <Link
                to='/forgot-password'
                className='text-pink'
              >
                {action.forgotPassword}
              </Link>
            </Form.Text>
            <Button
              block
              className='mb-3'
              onClick={() => {
                if (isMemberLoginAsAgent && emailVal) {
                  setShow(true);
                }
                if (!isMemberLoginAsAgent && emailVal) {
                  handleSubmit();
                }
              }}
            >
              {action.login}
            </Button>
            <Form.Text className='text-left'>
              {isMemberLoginAsAgent ? (
                <>
                  {label.member}{' '}
                </>
              ) : (
                <>
                  {label.register}{' '}
                </>
              )}
              <Link
                // className='text-light border-bottom text-decoration-none'
                to={useLocation().pathname}
                className='text-pink'
                onClick={() => {
                  if (isMemberLoginAsAgent) {
                    setShow(true);
                    setIsInterestInAgent(true);
                  } else {
                    handleClick();
                  } 
                }}
              >
                {action.register}
              </Link>
            </Form.Text>
          </FormikForm>
        </Card.Body>
      </Card>
      <ShowModal
        show={show}
        heading={
          isMemberLoginAsAgent && !isInterestInAgent
            ? modal.heading.member
            : isMemberLoginAsAgent && isInterestInAgent
              ? modal.heading.intInAgent
              : ''
        }
        secondary={modal.action.agent}
        primary={modal.action.member}
        handleClose={handleClose}
        handleSecondary={handleSubmit}
        handlePrimary={handleCancel}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  users: selectUsers,
  currentUser: selectUser,
  displayMessage: selectDisplayMessage,
  isInitiatedFromAgentShowcase: selectIsInitiatedFromAgentShowcase,
});

//use HOC withFormik() to
//create a formik component wrapping the Login component
const FormikLogin = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema: loginSchema,
  handleSubmit: (
    values,
    {
      props: {
        startLogin,
        isInitiatedFromAgentShowcase,
        setIsSubmitted
      },
      resetForm,
      setSubmitting,
    }
  ) => {
    setSubmitting(true);
    startLogin(values, isInitiatedFromAgentShowcase);
    resetForm();
    setSubmitting(false);
    setIsSubmitted(true);
  },
})(Login);

export default withRouter(
  connect(mapStateToProps, {
    startLogin,
    setIsSubmitted,
    clearErrMessage,
    startFetchingUsersFromDB,
    toggleLoginRegistrationView,
  })(FormikLogin)
);
