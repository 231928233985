import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GET_QUOTES_CMS_DATA } from './get-quotes.cms';
import { Container } from 'react-bootstrap';
import { Card, CardGroup, Col, Row } from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import {
  startFetchingMemberFromDB
} from '../../redux/member/member.actions';
import { selectUser } from '../../redux/auth/auth.selectors';
import { selectMember } from '../../redux/member/member.selectors';
import MemberSteps from '../member/member-steps.component'
import './get-quotes.styles.css';

const GetQuotes = ({
  member,
  currentUser,
  startFetchingMemberFromDB
}) => {

  const { cards, cards1 } = GET_QUOTES_CMS_DATA;

  const screenW = window.innerWidth;
  const isMemberLastStep = member && member.isMemberLastStep;

  const navigateToPage = (event) => {
    window.location.href = event.currentTarget.dataset.goto;
  };

  useEffect(() => {
    if (currentUser.member) {
      startFetchingMemberFromDB(currentUser.member);
    }
  }, [
    currentUser.member,
    startFetchingMemberFromDB,
  ])

  return (
    <section className='ie-form-scaffold'>
      {isMemberLastStep === false ? (
        <MemberSteps />
      ) : isMemberLastStep === true ? (
          <Container>
            <header className='py-4'>
              <h2>{GET_QUOTES_CMS_DATA.heading}</h2>
              <p>{GET_QUOTES_CMS_DATA.subHeading}</p>
            </header>
            <Row className='justify-content-center'>
              {cards.map((card, i) => (
                <CardGroup key={i} as={Col} xs={6} md={4} lg={2} className='mb-4'>
                  <Card
                    data-goto={card.goto}
                    onClick={navigateToPage}
                    className='text-center pt-4 pb-3 px-2'
                  >
                    <Card.Img
                      variant='top'
                      src={require(`${card.imageUrl}`)}
                      alt={card.title}
                      width={screenW < 525 ? '48' : '48'}
                      height={screenW < 525 ? '43' : '43'}
                      className={
                        screenW < 525
                          ? 'w-25 m-auto fluid'
                          : 'w-30 m-auto fluid'
                      }
                    />
                    <Card.Body className='p-2'>
                      <p className='m-0 font-weight-normal'>{card.title}</p>
                    </Card.Body>
                  </Card>
                </CardGroup>
              ))}
            </Row>
            <Row className='justify-content-center'>
              {cards1.map((card, i) => (
                <CardGroup key={i} as={Col} xs={6} md={4} lg={2} className='mb-4'>
                  <Card
                    data-goto={card.goto}
                    onClick={navigateToPage}
                    className='text-center pt-4 pb-3 px-2'
                  >
                    <Card.Img
                      variant='top'
                      src={require(`${card.imageUrl}`)}
                      alt={card.title}
                      width={screenW < 525 ? '48' : '48'}
                      height={screenW < 525 ? '43' : '43'}
                      className={
                        screenW < 525
                          ? 'w-25 m-auto fluid'
                          : 'w-30 m-auto fluid'
                      }
                    />
                    <Card.Body className='p-2'>
                      <p className='m-0 font-weight-normal'>{card.title}</p>
                    </Card.Body>
                  </Card>
                </CardGroup>
              ))}
            </Row>
          </Container>
        ) : null}
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  member: selectMember,
  currentUser: selectUser,
});

export default withRouter(connect(mapStateToProps, {
  startFetchingMemberFromDB,
})(GetQuotes));