import dotenv from 'dotenv';
dotenv.config();

export const maxBids = process.env.REACT_APP_MAX_BIDS;
export const agentBidTL = process.env.REACT_APP_AGENT_BID_TIME_LENGTH;
export const agentTLCrQt = process.env.REACT_APP_AGENT_CREATE_QUOTE_TIME_LENGTH;
export const memberTLSlQt = process.env.REACT_APP_MEMBER_SELECT_TIME_LENGTH;
export const totalTime = process.env.REACT_APP_QUOTE_TIME_LENGTH;
// agentBidTL = 57600000, agentTLCrQt = 129600000, memberTLSlQt = 345600000,
// totalTime = 518400000

export const getTimeLeft = (createdDate, timeLength) => {
  const timeDiff =
    createdDate
      ? (Date.parse(new Date(createdDate)) + parseInt(timeLength)) - Date.parse(new Date())
      : null
  return timeDiff;
}

export const agentBidTD = (createdDate) => {
  const timeDiff =
    createdDate
      ? (Date.parse(new Date(createdDate)) + parseInt(agentBidTL)) - Date.parse(new Date())
      : null
  return timeDiff;
}

export const memberSlQtTD = (createdDate) => {
  const timeDiff =
    createdDate
      ? (Date.parse(new Date(createdDate)) + parseInt(memberTLSlQt)) - Date.parse(new Date())
      : null
  return timeDiff;
}

export const createdQuoteDate = (quote) => {
  const date =
    quote.createdAgentQuoteDate 
      ? quote.createdAgentQuoteDate 
      : quote.createdAgentPropQuoteDate && quote.createdAgentVehQuoteDate
        ? quote.createdAgentPropQuoteDate
        : quote.createdDate 
  return date;
}

export const combinedCreatedQuoteDate = (quote) => {
  const date =
    quote.createdAgentPropQuoteDate && quote.createdAgentVehQuoteDate
      ? quote.createdAgentPropQuoteDate 
      : quote.createdDate
  return date;
}

export const getNotExpiredBids = (bids) => {
  let result = [];
  if (bids && bids.length !== 0) {
    for (let bid of bids) {
      const timeLeft = agentBidTD(bid.createdDate)
      if (timeLeft > 0) {
        result.push(bid);
      }
    }
  }
  return result;
}

export const createQuoteData = (storeQuote, agent) => {
  let bidData = {
    type: storeQuote.quoteType, 
    agentId: agent._id ? agent._id : '',
    quoteId: storeQuote._id ? storeQuote._id : '',
    createdDate: storeQuote.createdDate ? storeQuote.createdDate : '',
    isCaptive: agent.isCaptive ? agent.isCaptive : '',
    servicesProvidedTo: agent.servicesProvidedTo ? agent.servicesProvidedTo : '',
  }
  return bidData;
}

export const getDateString = (date) => {
  const createdDate = new Date(date);
  const createdDateSt = createdDate.toDateString().split(' ');
  const dateSt = `${createdDateSt[1]} ${createdDateSt[2]}, ${createdDateSt[3]}`
  return dateSt;
}

