const annuityQuoteActionTypes = {
  FETCH_ANNUITY_QUOTES_FROM_DB_START: 'FETCH_ANNUITY_QUOTES_FROM_DB_START',
  FETCH_ANNUITY_QUOTES_FROM_DB_SUCCESS: 'FETCH_ANNUITY_QUOTES_FROM_DB_SUCCESS',
  FETCH_ANNUITY_QUOTES_FROM_DB_FAILURE: 'FETCH_ANNUITY_QUOTES_FROM_DB_FAILURE',
  FETCH_ANNUITY_QUOTE_FROM_DB_START: 'FETCH_ANNUITY_QUOTE_FROM_DB_START',
  FETCH_ANNUITY_QUOTE_FROM_DB_SUCCESS: 'FETCH_ANNUITY_QUOTE_FROM_DB_SUCCESS',
  FETCH_ANNUITY_QUOTE_FROM_DB_FAILURE: 'FETCH_ANNUITY_QUOTE_FROM_DB_FAILURE',
  START_ANNUITY_QUOTE_SAVE: 'START_ANNUITY_QUOTE_SAVE',
  ANNUITY_QUOTE_SAVE_SUCCESS: 'ANNUITY_QUOTE_SAVE_SUCCESS',
  START_ANNUITY_QUOTE_UPDATE: 'START_ANNUITY_QUOTE_UPDATE',
  ANNUITY_QUOTE_UPDATE_SUCCESS: 'ANNUITY_QUOTE_UPDATE_SUCCESS',
  START_LAST_STEP_ANNUITY_QUOTE_UPDATE: 'START_LAST_STEP_ANNUITY_QUOTE_UPDATE',
  START_ANNUITY_QUOTE_DELETE: 'START_ANNUITY_QUOTE_DELETE',
  ANNUITY_QUOTE_DELETE_SUCCESS: 'ANNUITY_QUOTE_DELETE_SUCCESS',
  ANNUITY_DECREMENT_CURRENT_STEP: 'ANNUITY_DECREMENT_CURRENT_STEP',
  ANNUITY_INCREMENT_CURRENT_STEP: 'ANNUITY_INCREMENT_CURRENT_STEP',
  START_CHECKING_CHAT_GROUP: 'START_CHECKING_CHAT_GROUP',
  CHECKING_CHAT_GROUP_SUCCESS: 'CHECKING_CHAT_GROUP_SUCCESS',
  SAVE_DELETE_ANNUITY_COMET_CHAT: 'SAVE_DELETE_ANNUITY_COMET_CHAT',
  SAVE_DELETE_ANNUITY_COMET_CHAT_SUCCESS: 'SAVE_DELETE_ANNUITY_COMET_CHAT_SUCCESS',
  SET_ANNUITY_QUOTE_ID: 'SET_ANNUITY_QUOTE_ID',
  SET_ANNUITY_FORM_DATA: 'SET_ANNUITY_FORM_DATA',
  SET_MEMBERS_INFO: 'SET_MEMBERS_INFO',
  RESET_FORM: 'RESET_FORM',
}

export default annuityQuoteActionTypes;
