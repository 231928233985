import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../redux/auth/auth.selectors';
import { selectDisplayMessage } from '../../redux/auth/auth.selectors';
import PasswordRequirements from '../registration/password.requirements.component';
import { RESET_PASSWORD_CMS_DATA } from './reset-password.cms';
import resetPasswordSchema from './reset-password.validation.schema';
import {
  setIsSubmitted,
  startResetPasswordInProfile,
} from '../../redux/profile/profile.actions';
import {
  selectIsSubmitted,
} from '../../redux/profile/profile.selectors';
import { Container } from 'react-bootstrap';

const ResetPassword = ({
  values,
  touched,
  errors,
  history,
  isSubmitted,
  displayMessage,
  handleChange,
  handleSubmit,
  currentUser,
  setIsSubmitted,
}) => {
  const { password, action } = RESET_PASSWORD_CMS_DATA;

  const path = window.location.pathname;
  const MProfile = '/member-profile';
  const AProfile = '/agent-profile';
  
  values.email = currentUser.email;
  
  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitted(false);
      history.push('/');
    }
  }, [isSubmitted, setIsSubmitted, history]);

  return (
    <section>
      <Container className='p-0'>
        <FormikForm>
          <div className=
            {path === AProfile
              ? 'ie-form mt-3 mx-3'
              : path === MProfile
                ? 'ie-form mt-5'
                : ''
            }
          >
            <Row>
              <Col lg='3'></Col>
              <Col lg='6' className='text-center'>
                {displayMessage ? (
                  <p className='text-danger'>{displayMessage}</p>
                ) : null}
              </Col>
              <Col lg='3'></Col>
            </Row>
            <Row>
              <Col lg='4'></Col>
              <Col lg='4'>
                <br />
                <br />
                  <Form.Group controlId='newPassword'>
                    <Form.Control
                      type='password'
                      name='password'
                      placeholder={password.placeholder}
                      value={values.password}
                      onChange={handleChange}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='confirmNewPassword'>
                    <Form.Control
                      type='password'
                      name='confirmPassword'
                      placeholder={password.confirmPassword}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      isValid={touched.confirmPassword && !errors.confirmPassword}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <PasswordRequirements data={password.rules} />
              </Col>
              <Col lg='4'></Col>
            </Row>
          </div>
          <div className=
            {path === AProfile
              ? 'mx-3 float-right'
              : path === MProfile
                ? 'float-right'
                : ''
            }
          >
            <Button onClick={handleSubmit} >
              {action.resetPassword}
            </Button>
          </div>
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikResetPassword = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    confirmPassword: '',
  }),
  validationSchema: resetPasswordSchema,
  handleSubmit: (
    { email, password },
    { props: {
      setIsSubmitted,
      startResetPasswordInProfile
    }, resetForm, setSubmitting }
  ) => {
    setSubmitting(true);
    startResetPasswordInProfile({
      email,
      password,
    });
    resetForm();
    setSubmitting(false);
    setIsSubmitted(true);
  },
})(ResetPassword);

const mapStateToProps = createStructuredSelector({
  displayMessage: selectDisplayMessage,
  currentUser: selectUser,
  isSubmitted: selectIsSubmitted,
});

// wrap formik component with redux functions
export default withRouter(
  connect(mapStateToProps, {
    setIsSubmitted,
    startResetPasswordInProfile,
  })(FormikResetPassword)
);