import { Field } from 'formik';
import React, { createRef, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import {
  selectStates,
  selectMember,
} from '../../redux/member/member.selectors';
import { calculateAge } from '../../redux/member/member.utils';
import { DatePicker } from '../date-picker/date-picker.component';
import InfoIcon from '../info-icon/info-icon.component';
import RadioButton from '../radio-button/radio-button.component';

const Identification = ({
  values,
  handleChange,
  touched,
  errors,
  states,
}) => {
  const {
    form: {
      identification: { title, description, label },
    },
    popOver: { idDeatails: { content } },
  } = MEMBER_CMS_DATA;

  const [showDocumentIssuanceState, setShowDocumentIssuanceState] = useState(
    false
  );

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bidding';

  const stateRef = createRef();

  useEffect(() => {
    if (values.expiration === null) {
      values.expiration = '';
    }
  }, [values.expiration])

  useEffect(() => {
    if (values.documentType === 'passport' || values.documentType === 'none') {
      setShowDocumentIssuanceState(false);
    }
    if (
      values.documentType === 'driversLicense' ||
      values.documentType === 'stateID' ||
      values.documentType === 'driversPermit'
    ) {
      setShowDocumentIssuanceState(true);
    }
  }, [values.documentType, setShowDocumentIssuanceState]);

  useEffect(() => {
    if (values.documentType === false) {
      values.documentType = '';
    }
  }, [values.documentType]);
  
  useEffect(() => {
    let currentAge = calculateAge(values.dateOfBirth);
    
    if (currentAge >= 15 && currentAge < 18) {
      if (label.dropdown.documentTypes.length === 4) {
        label.dropdown.documentTypes.push({ value: 'none', key: 'None' });
      }
    } 
    if (currentAge > 18) {
      if (label.dropdown.documentTypes.length === 5) {
        label.dropdown.documentTypes.pop();
      }
    } 
  }, [values.dateOfBirth, label.dropdown.documentTypes]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <div>
            <Form.Row>
              <Form.Group as={Col} lg={12} controlId='document-type'>
                <Form.Label>{label.documentType}</Form.Label>
                <InfoIcon
                  className='float-right'
                  content={content.docType}
                />
                <br/>
                {label.dropdown.documentTypes.map((option, i) => (
                  <RadioButton
                    key={i}
                    idx={i}
                    name='documentType'
                    option={option}
                  />
                ))}
                <Form.Control.Feedback type='invalid'>
                  {errors.documentType}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={6} control-id='document-number'>
                <Form.Label>{label.documentNumber}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='documentNumber'
                  value={values.documentNumber}
                  onChange={handleChange}
                  isValid={touched.documentNumber && !errors.documentNumber}
                  isInvalid={!!errors.documentNumber}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.documentNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                lg={6}
                controlId='expiration'
                optional='true'
              >
                <Form.Label>{label.expiration}</Form.Label>
                <DatePicker
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='expiration'
                  value={values.expiration}
                  minDate={new Date('01-01-1960')}
                  maxDate={new Date('01-01-3000')}
                  touched={touched}
                  errors={errors}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.expiration}
                </Form.Control.Feedback>
              </Form.Group>
              {/* show this section only when selected document type is passport */}
              {showDocumentIssuanceState ? (
                <Form.Group as={Col} lg={6} control-id='passport-issue-state'>
                  <Form.Label>{label.documentIssueState}</Form.Label>
                  <Field name='documentIssueState'>
                  {({ form, field }) => (
                    <Typeahead   
                      disabled={
                        path === agentBid ? true : false
                      }    
                      id='state-select-contact'
                      ref={stateRef}
                      name='documentIssueState'
                      options={states}
                      isValid={touched.documentIssueState && !errors.documentIssueState}
                      isInvalid={!!errors.documentIssueState}
                      selected={[values.documentIssueState]}
                      onChange={(selectedState) => {
                        if (selectedState.length) {
                          form.setFieldValue(
                            field.name,
                            selectedState[0].value
                          );
                        }
                      }}
                      onInputChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </Field>
                  <Form.Control.Feedback type='invalid'>
                    {errors.documentIssueState}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : null}
            </Form.Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  member: selectMember,
  states: selectStates,
});

export default connect(mapStateToProps, {})(Identification);
