import { Field } from 'formik';
import React, { createRef, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import { selectStates, selectAgent } from '../../redux/agent/agent.selectors';
import { DatePicker } from '../date-picker/date-picker.component';
import Dropdown from '../dropdown/dropdown.component';
import {
  selectPrimeMember,
} from '../../redux/member/member.selectors';

const Identification = ({
  values,
  handleChange,
  touched,
  errors,
  states,
  reset,
}) => {
  const {
    form: {
      identification: { title, description, label },
    },
  } = AGENT_CMS_DATA;

  const [showState, setShowState] = useState(false);

  const stateRef = createRef();

  const path = window.location.pathname;
  const agentP = '/agent-profile';

  const DOptions = label.dropdown.documentTypes;
  let documentType = values.documentType;

  useEffect(() => {
    for (let o of DOptions) {
      if (path === '/agent-profile' && documentType) {
        if (o.value !== documentType) {
          o.disabled = 'true';
        }
      } else {
        o.disabled = 'false';
      }
    }
  }, [path, DOptions, documentType])

  useEffect(() => {
    reset && stateRef.current.clear();
  }, [reset, stateRef]);

  useEffect(() => {
    if (documentType === 'passport') {
      setShowState(false);
    }

    if (
      documentType === 'driversLicense' ||
      documentType === 'stateID'
    ) {
      setShowState(true);
    }
  }, [documentType]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={6} controlId='document-type'>
              <Form.Label>{label.documentType}</Form.Label>
              <Dropdown
                isDisabled={path === agentP ? true : false}
                name='documentType'
                selected={values.documentType}
                options={DOptions}
                touched={touched}
                errors={errors}
                values={values}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.documentType}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={6} controlId='document-number'>
              <Form.Label>{label.documentNumber}</Form.Label>
              <Form.Control
                readOnly={path === agentP ? true : false}
                name='documentNumber'
                value={values.documentNumber}
                onChange={handleChange}
                isValid={touched.documentNumber && !errors.documentNumber}
                isInvalid={!!errors.documentNumber}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.documentNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={6} control-id='expiration'>
              <Form.Label>{label.expiration}</Form.Label>
              <DatePicker
                readOnly={path === agentP ? true : false}
                name='expiration'
                value={values.expiration}
                minDate={new Date('01-01-1960')}
                maxDate={new Date('01-01-3000')}
                autoComplete='nope'
                touched={touched}
                errors={errors}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.expiration}
              </Form.Control.Feedback>
            </Form.Group>
            {showState ? (
              <Form.Group as={Col} lg={6} readOnly={true}>
                <Form.Label>{label.documentIssueState}</Form.Label>
                <Field name='documentIssueState'>
                  {({ form, field }) => (
                    <Typeahead
                      disabled={path === agentP ? true : false}
                      id='documentIssueState-select-input'
                      ref={stateRef}
                      name='documentIssueState'
                      options={states}
                      selected={[values.documentIssueState]}
                      isValid={
                        touched.documentIssueState && !errors.documentIssueState
                      }
                      isInvalid={!!errors.documentIssueState}
                      onChange={(selectedState) => {
                        if (selectedState.length) {
                          form.setFieldValue(
                            field.name,
                            selectedState[0].value
                          );
                        }
                      }}
                      onInputChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </Field>
                <Form.Control.Feedback type='invalid'>
                  {errors.documentIssueState}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  states: selectStates,
  agent: selectAgent,
  primeMember: selectPrimeMember,
});

export default connect(mapStateToProps, {})(Identification);
