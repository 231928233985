export const BENEFITS_CMS_DATA = {
  benefits: [
    {
      title: 'Our services are free',
      imgUrl: './images/cannabis.webp',
      desc: `One feature of InsurEasier is that the services are completely free for users. Unlike other insurance platforms that may charge fees, you can access a growing array of insurance options without any additional costs. You simply pay your premiums, as you normally would, and all we ask is that you use us for all insurance needs.`
    },
    {
      title: `We're harassment free`,
      imgUrl: './images/shield.webp',
      desc: `We prioritize creating a harassment-free environment for our users. Gone are the days of incessant phone calls and emails from agents or carriers. With InsurEasier, you can browse and compare insurance options at your own pace, without feeling pressured or overwhelmed, while still having access to agents and their expertise.`,
    },
    {
      title: 'Safely encrypted and Fast',
      imgUrl: './images/encryption.webp',
      desc: `Your safety and security are our top priorities. All information shared on our platform is safely encrypted, ensuring that your personal and sensitive data remains protected at all times. Plus, this platform is designed to provide fast and efficient service, allowing you to quickly obtain quotes and make informed decisions about your insurance coverage.`,
    },
    {
      title: 'One site for all insurances',
      imgUrl: './images/computer.webp',
      desc: `InsurEasier serves as a one-stop destination for all your insurance needs. Whether you're in the market for auto, home, health, life, or another type of insurance, you can find it all on this platform. This streamlines the purchasing process and saves you time and effort by eliminating the need to visit multiple websites or platforms.`,
    },
    {
      title: 'Competing agents',
      imgUrl: './images/user.webp',
      desc: `We foster healthy competition among agents on our platform, ultimately benefiting you, the user. InsurEasier's structure facilitates both Captive and Independent carriers - unlike any other site. And, with multiple agents competing for your business, you can rest assured that you're getting the best possible coverage at the most competitive rate.`
    },
    {
      title: 'Easier Process',
      imgUrl: './images/like.webp',
      desc: `As our name suggests, InsurEasier makes the entire insurance-buying process easier and more convenient. Our intuitive features ensure that individuals can navigate the platform with ease. Whether you're browsing insurance options, finalizing your coverage, or just viewing policies already in force, InsurEasier simplifies every step of the process.`,
    },
  ]
}
