const animalQuoteActionTypes = {
  FETCH_ANIMALS_QUOTES_FROM_DB_START: 'FETCH_ANIMALS_QUOTES_FROM_DB_START',
  FETCH_ANIMALS_QUOTES_FROM_DB_SUCCESS: 'FETCH_ANIMALS_QUOTES_FROM_DB_SUCCESS',
  FETCH_ANIMALS_QUOTES_FROM_DB_FAILURE: 'FETCH_ANIMALS_QUOTES_FROM_DB_FAILURE',
  FETCH_ANIMALS_QUOTE_FROM_DB_START: 'FETCH_ANIMALS_QUOTE_FROM_DB_START',
  FETCH_ANIMALS_QUOTE_FROM_DB_SUCCESS: 'FETCH_ANIMALS_QUOTE_FROM_DB_SUCCESS',
  FETCH_ANIMALS_QUOTE_FROM_DB_FAILURE: 'FETCH_ANIMALS_QUOTE_FROM_DB_FAILURE',
  START_ANIMALS_QUOTE_SAVE: 'START_ANIMALS_QUOTE_SAVE',
  ANIMALS_QUOTE_SAVE_SUCCESS: 'ANIMALS_QUOTE_SAVE_SUCCESS',
  START_ANIMALS_QUOTE_UPDATE: 'START_ANIMALS_QUOTE_UPDATE',
  ANIMALS_QUOTE_UPDATE_SUCCESS: 'ANIMALS_QUOTE_UPDATE_SUCCESS',
  START_LAST_STEP_ANIMALS_QUOTE_UPDATE: 'START_LAST_STEP_ANIMALS_QUOTE_UPDATE',
  LAST_STEP_ANIMALS_QUOTE_UPDATE_SUCCESS: 'LAST_STEP_ANIMALS_QUOTE_UPDATE_SUCCESS',
  ANIMALS_DECREMENT_CURRENT_STEP: 'ANIMALS_DECREMENT_CURRENT_STEP',
  ANIMALS_INCREMENT_CURRENT_STEP: 'ANIMALS_INCREMENT_CURRENT_STEP',
  START_CHECKING_CHAT_GROUP_ANIMAL: 'START_CHECKING_CHAT_GROUP_ANIMAL',
  CHECKING_CHAT_GROUP_ANIMAL_SUCCESS: 'CHECKING_CHAT_GROUP_ANIMAL_SUCCESS',
  SAVE_DELETE_ANIMAL_COMET_CHAT: 'SAVE_DELETE_ANIMAL_COMET_CHAT',
  SAVE_DELETE_ANIMAL_COMET_CHAT_SUCCESS: 'SAVE_DELETE_ANIMAL_COMET_CHAT_SUCCESS',
  SET_ANIMAL_QUOTE_ID: 'SET_ANIMAL_QUOTE_ID',
  SET_ANIMAL_FORM_DATA: 'SET_ANIMAL_FORM_DATA',
  SET_MEMBERS_INFO: 'SET_MEMBERS_INFO',
  RESET_FORM: 'RESET_FORM',
}

export default animalQuoteActionTypes;
