import React, { useState } from 'react';

import loadable from '@loadable/component';

const VehicleQuoteDetails = loadable(() => import('./vehicle-quote-details.component'));

const VehicleQuotesDetails = ({ vehicleQuotes }) => {
  const [storeFilterQuotes, setStoreFilterQuotes] = useState(false)

  return (
    <div>
      {vehicleQuotes &&
        vehicleQuotes.length !== 0 ? (
        <>
          {vehicleQuotes.map((vq, i) => (
            <div key={i}>
              {vq && vq.isLastStep ? (
                <VehicleQuoteDetails
                  vehicle={vq}
                  elmId={vq._id}
                  storeFilterQuotes = {storeFilterQuotes}
                  setStoreFilterQuotes = {setStoreFilterQuotes}
                />
              ) : null}
            </div>
          ))}
        </>
      ) : null}  
    </div>
  );
};

export default VehicleQuotesDetails;
