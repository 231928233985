import animalBiddingActionTypes from './animal-bidding.types';
import authActionTypes from '../../auth/auth.types';

let INITIAL_STATE = {
  showAnimalQuotes: true,
  isAnimalSelectedByAi: false,
  isSubmit: false,
  isAnimalBid: false,
  reset: false,
};

// let PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));

// if (PERSISTED_STATE) {
//   INITIAL_STATE = JSON.parse(PERSISTED_STATE.agent);
// }

const animalBiddingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case animalBiddingActionTypes.SET_IS_SUBMIT:
      return {
        ...state,
        isSubmit: action.payload,
      };
    case animalBiddingActionTypes.SET_IS_ANIMAL_BID:
      return {
        ...state,
        isAnimalBid: action.payload,
      };
    case animalBiddingActionTypes.SET_IS_ANIMAL_SELECTED_BY_AI:
      return {
        ...state,
        isAnimalSelectedByAi: action.payload,
      };
    case animalBiddingActionTypes.SET_SHOW_ANIMAL_QUOTES:
      return {
        ...state,
        showAnimalQuotes: action.payload,
      };
    case animalBiddingActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        showAnimalQuotes: true,
        isAnimalSelectedByAi: false,
        isSubmit: false,
        isAnimalBid: false,
        reset: false,
      };
    default:
      return state;
  }
};

export default animalBiddingReducer;
