import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertMemberLifePolicyFromDBSave } from "./life.utils";
import memberlifePolicyActionTypes from "./life.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  lifePolicySaveSuccess,
  lifePolicySaveFailure,
  lifePolicyUpdateSuccess,
  lifePolicyUpdateFailure,
  lifePolicyDeleteSuccess,
  lifePolicyDeleteFailure,
  fetchMembersFromDBSuccess,
  fetchMembersFromDBFailure,
  fetchLifePolicyFromDBSuccess,
  fetchLifePolicyFromDBFailure,
} from "./life.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchMembersFromDB() {
  try {
    const response = yield axios.get(`/api/members`);
    const members = yield response.data;
    yield put(fetchMembersFromDBSuccess(members));
  } catch (e) {
    yield put(fetchMembersFromDBFailure(e));
  }
}

function* fetchLifePolicyFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/member-life-policy/${id}`);
    const lifePolicy = yield response.data;
    yield put(fetchLifePolicyFromDBSuccess(lifePolicy));
  } catch (e) {
    yield put(fetchLifePolicyFromDBFailure(e));
  }
}

function* saveLifePolicy({ payload: formData }) {
  try {
    const data = yield call(convertMemberLifePolicyFromDBSave, formData);
    const formDataFiles = new FormData();
    for (let i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let files = response.data ? response.data.files : [];
      let chunks = response.data ? response.data.chunks : [];
      if (files && chunks) {
        for (let m = 0; m < files.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === files[m]._id
          );
          files[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(files) ? files : [];
      axios.post(`/api/member-life-policy`, data, configureAxios());
    });
    yield put(lifePolicySaveSuccess());
  } catch (e) {
    yield put(lifePolicySaveFailure(e));
  }
}

function* updateLifePolicy({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberLifePolicyFromDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let files = response.data ? response.data.files : [];
          let chunks = response.data ? response.data.chunks : [];
          if (files && chunks) {
            for (let m = 0; m < files.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === files[m]._id
              );
              files[m].chunkId = chunks[index]._id;
            }
          }
          data.documents = Array.isArray(files)
            ? data.documents.concat(files)
            : [];
          axios.patch(`/api/member-life-policy/${id}`, data, configureAxios());
        });
      yield put(lifePolicyUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(`/api/member-life-policy/${id}`, data, configureAxios());
      yield put(lifePolicyUpdateSuccess());
    }
  } catch (e) {
    yield put(lifePolicyUpdateFailure(e));
  }
}

function* deleteLifePolicy({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/member-life-policy/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(lifePolicyDeleteSuccess());
  } catch (e) {
    yield put(lifePolicyDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    memberlifePolicyActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchMembersFromDB() {
  yield takeEvery(
    memberlifePolicyActionTypes.FETCH_MEMBERS_FROM_DB_START,
    fetchMembersFromDB
  );
}

function* onFetchLifePolicyFromDB() {
  yield takeEvery(
    memberlifePolicyActionTypes.FETCH_LIFE_POLICY_FROM_DB_START,
    fetchLifePolicyFromDB
  );
}

function* onLifePolicySave() {
  yield takeEvery(
    memberlifePolicyActionTypes.START_LIFE_POLICY_SAVE,
    saveLifePolicy
  );
}

function* onUpdateLifePolicy() {
  yield takeEvery(
    memberlifePolicyActionTypes.START_LIFE_POLICY_UPDATE,
    updateLifePolicy
  );
}

function* onDeleteLifePolicy() {
  yield takeEvery(
    memberlifePolicyActionTypes.START_LIFE_POLICY_DELETE,
    deleteLifePolicy
  );
}

export function* memberLifePolicySagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onLifePolicySave),
    call(onFetchMembersFromDB),
    call(onFetchLifePolicyFromDB),
    call(onUpdateLifePolicy),
    call(onDeleteLifePolicy),
  ]);
}
