const propertyQuoteActionTypes = {
  FETCH_PROPERTY_QUOTES_FROM_DB_START: 'FETCH_PROPERTY_QUOTES_FROM_DB_START',
  FETCH_PROPERTY_QUOTES_FROM_DB_SUCCESS: 'FETCH_PROPERTY_QUOTES_FROM_DB_SUCCESS',
  FETCH_PROPERTY_QUOTES_FROM_DB_FAILURE: 'FETCH_PROPERTY_QUOTES_FROM_DB_FAILURE',
  FETCH_PROPERTY_QUOTE_FROM_DB_START: 'FETCH_PROPERTY_QUOTE_FROM_DB_START',
  FETCH_PROPERTY_QUOTE_FROM_DB_SUCCESS: 'FETCH_PROPERTY_QUOTE_FROM_DB_SUCCESS',
  FETCH_PROPERTY_QUOTE_FROM_DB_FAILURE: 'FETCH_PROPERTY_QUOTE_FROM_DB_FAILURE',
  START_PROPERTY_QUOTE_SAVE: 'START_PROPERTY_QUOTE_SAVE',
  PROPERTY_QUOTE_SAVE_SUCCESS: 'PROPERTY_QUOTE_SAVE_SUCCESS',
  START_PROPERTY_QUOTE_UPDATE: 'START_PROPERTY_QUOTE_UPDATE',
  PROPERTY_QUOTE_UPDATE_SUCCESS: 'PROPERTY_QUOTE_UPDATE_SUCCESS',
  START_LAST_STEP_PROPERTY_QUOTE_UPDATE: 'START_LAST_STEP_PROPERTY_QUOTE_UPDATE',
  LAST_STEP_PROPERTY_QUOTE_UPDATE_SUCCESS: 'LAST_STEP_PROPERTY_QUOTE_UPDATE_SUCCESS',
  PROPERTY_DECREMENT_CURRENT_STEP: 'PROPERTY_DECREMENT_CURRENT_STEP',
  PROPERTY_INCREMENT_CURRENT_STEP: 'PROPERTY_INCREMENT_CURRENT_STEP',
  START_CHECKING_CHAT_GROUP_PROPERTY: 'START_CHECKING_CHAT_GROUP_PROPERTY',
  CHECKING_CHAT_GROUP_PROPERTY_SUCCESS: 'CHECKING_CHAT_GROUP_PROPERTY_SUCCESS',
  SAVE_DELETE_PROPERTY_COMET_CHAT: 'SAVE_DELETE_PROPERTY_COMET_CHAT',
  SAVE_DELETE_PROPERTY_COMET_CHAT_SUCCESS: 'SAVE_DELETE_PROPERTY_COMET_CHAT_SUCCESS',
  SET_PROPERTY_NO_COMBINE_ID: 'SET_PROPERTY_NO_COMBINE_ID',
  SET_PROPERTY_FORM_DATA: 'SET_PROPERTY_FORM_DATA',
  SET_PROPERTY_COMBINE_CV: 'SET_PROPERTY_COMBINE_CV',
  SET_PROPERTY_COMBINE_ID: 'SET_PROPERTY_COMBINE_ID',
  SET_MEMBERS_INFO: 'SET_MEMBERS_INFO',
  RESET_FORM: 'RESET_FORM',
}

export default propertyQuoteActionTypes;
