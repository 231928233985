import React, { useState } from 'react';

import loadable from '@loadable/component';

const AnnuityQuoteDetails = loadable(() => import('./annuity-quote-details.component'));

const AnnuityQuotesDetails = ({ annuityQuotes }) => {
  const [storeFilterQuotes, setStoreFilterQuotes] = useState(false)

  return (
    <div>
      {annuityQuotes &&
        annuityQuotes.length !== 0 ? (
        <>
          {annuityQuotes.map((an, i) => (
            <div key={i}>
              {an && an.isLastStep ? (
                <AnnuityQuoteDetails
                  annuity={an}
                  elmId={an._id}
                  storeFilterQuotes = {storeFilterQuotes}
                  setStoreFilterQuotes = {setStoreFilterQuotes}
                />
              ) : null}
            </div>
          ))}
        </>
      ) : null}  
    </div>
  );
};

export default AnnuityQuotesDetails;
