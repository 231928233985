import { HEALTH_QUOTE_CMS_DATA }
  from '../../../pages/quotes/health/health.cms';
import healthActionTypes from './health.types';
import authActionTypes from '../../auth/auth.types';

const {
  form: {
    familyHistories: {
      label: {
        dropdown: { relationships },
      },
    },
  },
} = HEALTH_QUOTE_CMS_DATA;

const INITIAL_STATE = {
  relationshipOptions: relationships,
  healthsFromDB: [],
  healthFromDB: {},
  showHealthForm: false,
  memberId: '',
  memberHealthId: '',
  isSubmitted: false,
  clearHealthForm: false,
  reset: false,
};

const healthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case healthActionTypes.FETCH_HEALTHS_FROM_DB_SUCCESS:
      return {
        ...state,
        healthsFromDB: action.payload,
      };
    case healthActionTypes.FETCH_HEALTH_FROM_DB_SUCCESS:
      return {
        ...state,
        healthFromDB: action.payload,
      };
    case healthActionTypes.TOGGLE_HEALTH_FORM_VIEW:
      return {
        ...state,
        showHealthForm: action.payload,
      };
    case healthActionTypes.SET_MEMBER_ID:
      return {
        ...state,
        memberId: action.payload,
        healthFromDB: {},
      };
    case healthActionTypes.SET_MEMBER_HEALTH_ID:
      return {
        ...state,
        memberHealthId: action.payload,
      };
    case healthActionTypes.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    case healthActionTypes.SET_CLEAR_HEALTH_FORM:
      return {
        ...state,
        healthFromDB: {},
        clearHealthForm: action.payload,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        relationshipOptions: relationships,
        healthsFromDB: [],
        healthFromDB: {},
        showHealthForm: false,
        memberId: '',
        memberHealthId: '',
        isSubmitted: false,
        clearHealthForm: false,
        reset: false,
      };
    case healthActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default healthReducer;
