import { MEMBER_COMPANION_ANIMAL_POLICIES_CMS_DATA } 
  from '../../../pages/policies/companion-animal/companion-animal-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import memberCompanionAnimalPolicyActionTypes 
  from './companion-animal.types';

const payload = {
  context: 'memberCompanionAnimalPolicy',
  status: '',
  error: ''
}

export const setClearForm = (clear) => ({
  type: memberCompanionAnimalPolicyActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const startFetchingAnimalsFromDB = () => ({
  type: memberCompanionAnimalPolicyActionTypes.FETCH_ANIMALS_FROM_DB_START,
});

export const fetchAnimalsFromDBSuccess = (storedAnimals) => ({
  type: memberCompanionAnimalPolicyActionTypes.FETCH_ANIMALS_FROM_DB_SUCCESS,
  payload: storedAnimals,
});

export const fetchAnimalsFromDBFailure = (e) => ({
  type: memberCompanionAnimalPolicyActionTypes.FETCH_ANIMALS_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: memberCompanionAnimalPolicyActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: memberCompanionAnimalPolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: memberCompanionAnimalPolicyActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_COMPANION_ANIMAL_POLICIES_CMS_DATA.errMsg.states,
});

export const startFetchingCompanionAnimalFromDB = (id) => ({
  type: memberCompanionAnimalPolicyActionTypes.FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_START,
  payload: id,
});

export const fetchCompanionAnimalFromDBSuccess = (policy) => ({
  type: memberCompanionAnimalPolicyActionTypes.FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchCompanionAnimalFromDBFailure = (e) => ({
  type: memberCompanionAnimalPolicyActionTypes.FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_FAILURE,
  payload: e,
});

export const startCompanionAnimalPolicySave = (policy) => ({
  type: memberCompanionAnimalPolicyActionTypes.START_COMPANION_ANIMAL_POLICY_SAVE,
  payload: policy
});

export const memberCompanionAnimalPolicySaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const memberCompanionAnimalPolicySaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCompanionAnimalPolicyUpdate = (id, formData) => ({
  type: memberCompanionAnimalPolicyActionTypes.START_COMPANION_ANIMAL_POLICY_UPDATE,
  payload: { id, formData }
});

export const companionAnimalPolicyUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const companionAnimalPolicyUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCompanionAnimalPolicyDelete = (id, PId) => ({
  type: memberCompanionAnimalPolicyActionTypes.START_COMPANION_POLICY_DELETE,
  payload: { id, PId },
});

export const companionAnimalPolicyDeleteSuccess = () => ({
  type: memberCompanionAnimalPolicyActionTypes.COMPANION_POLICY_DELETE_SUCCESS,
});

export const companionAnimalPolicyDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});
