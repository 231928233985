import { createSelector } from 'reselect';

const selectProperty = (state) => state.property;

export const selectProperties = createSelector(
  [selectProperty],
  (property) => property.propertiesFromDB
);

export const selectPropertyById = createSelector(
  [selectProperty],
  (property) => property.propertyById
);

export const selectStates = createSelector(
  [selectProperty],
  (property) => property.statesFromDB
);

export const selectIsSubmitted = createSelector(
  [selectProperty],
  (property) => property.isSubmitted
);

export const selectShowPropertyForm = createSelector(
  [selectProperty],
  (property) => property.showPropertyForm
);

export const selectShowPropertyOptions = createSelector(
  [selectProperty],
  (property) => property.showPropertyOptions
);

export const selectShowPropertyInfoEdit = createSelector(
  [selectProperty],
  (property) => property.showPropertyInfoEdit
);

export const selectClearPropertyForm = createSelector(
  [selectProperty],
  (property) => property.clearPropertyForm
);

export const selectReset = createSelector(
  [selectProperty],
  (property) => property.reset 
)