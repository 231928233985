import { ModalState, ModalTypes } from '../../modal/modal.types';
import combineCoverageQuoteActionTypes from './combine-coverages.types';

const payload = {
  context: 'combineCoverageQuote',
  status: '',
  error: '',
};

export const decrementCurrentStep = (currentStep) => ({
  type: combineCoverageQuoteActionTypes.COMBINE_DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: combineCoverageQuoteActionTypes.COMBINE_INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const setCombineId = (id) => ({
  type: combineCoverageQuoteActionTypes.SET_COMBINE_ID,
  payload: id,
});

export const setBidCombine = (id) => ({
  type: combineCoverageQuoteActionTypes.SET_BID_COMBINE,
  payload: id,
});

export const setCombinedFormData = (data) => ({
  type: combineCoverageQuoteActionTypes.SET_COMBINED_FORM_DATA,
  payload: data,
});

export const startCheckingChatGroup = (id) => ({
  type: combineCoverageQuoteActionTypes.START_CHECKING_CHAT_GROUP_COMBINE,
  payload: id,
})

export const checkingChatGroupSuccess = () => ({
  type: combineCoverageQuoteActionTypes.CHECKING_CHAT_GROUP_COMBINE_SUCCESS,
})

export const saveDeleteCometChat = (id) => ({
  type: combineCoverageQuoteActionTypes.SAVE_DELETE_COMBINE_COMET_CHAT,
  payload: id,
})

export const saveDeleteCometChatSuccess = () => ({
  type: combineCoverageQuoteActionTypes.SAVE_DELETE_COMBINE_COMET_CHAT_SUCCESS,
})

export const startFetchingCombineCoverageQuotesFromDB = () => ({
  type: combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_START,
});

export const fetchCombineCoverageQuotesFromDBSuccess = (quote) => ({
  type: combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchCombineCoverageQuotesFromDBFailure = (e) => ({
  type: combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingCombineCoverageQuoteFromDB = (id) => ({
  type: combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchCombineCoverageQuoteFromDBSuccess = (quote) => ({
  type: combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchCombineCoverageQuoteFromDBFailure = (e) => ({
  type: combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startCombineCoverageQuoteSave = (quotes) => ({
  type: combineCoverageQuoteActionTypes.START_COMBINE_COVERAGE_QUOTE_SAVE,
  payload: quotes,
});

export const combineCoverageQuoteSaveSuccess = () => ({
  type: combineCoverageQuoteActionTypes.COMBINE_COVERAGE_QUOTE_SAVE_SUCCESS,
});

export const combineCoverageQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCombineCoverageQuoteUpdate = (id, formData) => ({
  type: combineCoverageQuoteActionTypes.START_COMBINE_COVERAGE_QUOTE_UPDATE,
  payload: { id, formData },
});

export const combineCoverageQuoteUpdateSuccess = () => ({
  type: combineCoverageQuoteActionTypes.COMBINE_COVERAGE_QUOTE_UPDATE_SUCCESS,
});

export const combineCoverageQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const lastStepCombineCoverageQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.QUOTE_REQUEST_SUCCESS,
  },
});



