import { Form as FormikForm, withFormik } from 'formik';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { startForgotPassword } from '../../redux/auth/auth.actions';
import { selectDisplayMessage } from '../../redux/auth/auth.selectors';
import { FORGOT_PASSWORD_CMS_DATA } from './forgot-password.cms';
import forgotPasswordSchema from './forgot-password.validation.schema';

const ForgotPassword = ({
  status,
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  displayMessage,
}) => {
  const {
    email,
    label,
    action,
    errObj: { account },
  } = FORGOT_PASSWORD_CMS_DATA;

  return (
    <Container className='ie-form-scaffold'>
      <Row>
        <Col className='text-center'>
          {displayMessage && displayMessage === account.exists ? (
            <p className='text-success'>{displayMessage}</p>
          ) : displayMessage && displayMessage === account.missing ? (
            <p className='text-danger'>{displayMessage}</p>
          ) : null}
          <p id='default-text-forgot-password'>{label.heading}</p>
        </Col>
      </Row>
      <Row className='mx-auto'>
        <Col lg='4'></Col>
        <Col lg='4'>
          <br />
          <br />
          <FormikForm className='rounded'>
            <Form.Group controlId='forgotPassword'>
              <Form.Control
                className='shadow-lg'
                type='email'
                name='email'
                value={values.email}
                placeholder={email.placeholder}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant='success'
              className='float-right'
              onClick={() => handleSubmit()}
            >
              {action.submit}
            </Button>
          </FormikForm>
        </Col>
        <Col lg='4'></Col>
      </Row>
    </Container>
  );
};

const hideDefaultTextForgotPassword = () => {
  const defaultText = document.querySelector('#default-text-forgot-password');
  defaultText.classList.add('hide');
};

const mapStateToProps = createStructuredSelector({
  displayMessage: selectDisplayMessage,
});

const FormikForgotPassword = withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: forgotPasswordSchema,
  handleSubmit: (
    values,
    { props: { startForgotPassword }, resetForm, setSubmitting }
  ) => {
    startForgotPassword(values);
    hideDefaultTextForgotPassword();
    resetForm();
    setSubmitting(false);
  },
})(ForgotPassword);

export default withRouter(
  connect(mapStateToProps, { startForgotPassword })(FormikForgotPassword)
);
