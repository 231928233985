import AgentInfo from '../../components/agent-steps/agent-info.component';
import AgentPayment from '../../components/agent-steps/agent-payment.component';
import AgentIdentification from '../../components/agent-steps/agent-identification.component';
import AgentLicenses from '../../components/agent-steps/agent-licenses.component';
import AgentAppointments from '../../components/agent-steps/agent-appointments.component';
import AgentVeriff from '../../components/agent-steps/agent-veriff.component';
import AgentLicenseVerify from '../../components/agent-steps/agent-license-verification.component';
import AgentPhoneVerify from '../../components/agent-steps/agent-twilio.component'

export const RULE_ENGINE = {
  'agent-dashboard': [
    [
      {
        heading: 'Agent Information',
        subHeading:
          'All forms must be completed (except fields labeled as optional) and your idenity and licenses must be verified and active to gain access the Agent portal.',
      },
    ],
    [
       {
         key: 'agentPayment',
         name: 'Agent Payment',
         component: AgentPayment,
       },
      {
        key: 'agentVeriff',
        name: 'Agent Verification',
        component: AgentVeriff,
      },
      {
        key: 'agentInfo',
        name: 'Agent Info',
        component: AgentInfo,
      },
      {
        key: 'twilioVerify',
        name: 'Phone Verification',
        component: AgentPhoneVerify,
      },
      {
        key: 'agentId',
        name: 'Agent Identification',
        component: AgentIdentification,
      },
      {
        key: 'agentLicenses',
        name: 'Agent Licenses',
        component: AgentLicenses,
      },
      {
        key: 'AgentLicenseVerify',
        name: 'Agent License Verification',
        component: AgentLicenseVerify,
      },
      {
        key: 'agentAppointments',
        name: 'Agent Appointments',
        component: AgentAppointments,
      },
    ],
  ],
};
