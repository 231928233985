export const MEMBER_PROPERTY_POLICY_CMS_DATA = {
  heading: 'My Property Policy',
  subHeading: 'Enter the information pertaining to the property on a single policy; a separate policy form should be created for each address. Agents will be required to fill in all the information but you are only required to enter the Property and related Documents to store; feel free to enter any other pertinent information you\'d like.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
    },
    propertyDetails: {
      title: 'Property on Policy',
      description: 'Please enter the property address for this policy by either selecting from "Stored property" or selecting "Add Another." This is how the policy will be referenced.',
      label: {
        storedProperty: 'Stored property',
        streetAddress: 'Street address',
        city: 'City',
        state: 'State',
        zipCode: 'Zip code',
      },
    },
    documents: {
      title: 'Documents',
      description: 'Here is where you can upload documents related to the policy, like declaration pages, full policy coverage, photos of property, etc. Only upload documents pertinent to the policy.',
      label: 'Upload your documents and images',
    },
    effectiveDates: {
      title: 'Effective Dates',
      description: 'This is the date the policy began or begins and when it is up for renewal.',
      label: {
        start: 'Policy start date',
        end: 'Policy end date',
      }      
    },
    policyType: {
      title: 'Policy Type',
      description: 'It is good to know the policy type so that you have a good idea of what you are covered for in case of a loss. All coverages have some form of Liability coverage. Almost all of these have some type of coverage for items that you take off the premises, like a cell phone, laptop, etc. This is good to know. Do not be afraid to ask the Agent.',
      label: {
        selectPolicy: 'Select a policy type',
        otherPolicy: 'Other policy type',
      },
      dropdown: [
        { value: 'ho-1', label: 'HO-1 (Basic Form - Covers named perils only)' },
        { value: 'ho-2', label: 'HO-2 (Broad Form - Covers more Perils than HO-1, only)' },
        { value: 'ho-3', label: 'HO-3 (Special Form - Covers all perils except those in exclusions; more than HO-2)' },
        { value: 'hob', label: 'HOB (Similar to HO-3 but might cover a few more perils and items)' },
        { value: 'ho-4', label: 'HO-4 (Tenant’s or Renter’s Form - Covers belongings and Liability only)' },
        { value: 'ho-5', label: 'HO-5 (Comprehensive Form - Less exclusions than HO-3)' },
        { value: 'ho-6', label: 'HO-6 (Condo Form - Covers belongings, Liability, and some internal structure)' },
        { value: 'ho-7', label: 'HO-7 (Mobile Home Form - Like HO-3 for mobile homes)' },
        { value: 'ho-8', label: 'HO-8 (Older Home Form – Used to inure older homes)' },
        { value: 'other', label: 'Business or other'},
      ]
    },
    coverageTypes: {
      title: 'Coverage Types',
      description: 'Is your structure or the contents within covered with Replacement Cost or Actual Cash Value? Either way, there is a dollar amount that represents the most that will be paid out for a loss.',
      label: {
        structureCoverage: 'Structure coverage',
        contentsCoverage: 'Contents coverage',
        structureRV: 'Structure Replacement value',
        structureCV: 'Structure Actual Cash Value',
        contentsRV: 'Contents Replacement value',
        contentsCV: 'Contents Actual Cash Value',
      },
      dropdown: [
        { value: 'replacementValue', label: 'Replacement Cost' },
        { value: 'actualCashValue', label: 'Actual Cash Value' },
      ]     
    },
    deductible: {
      title: 'Deductible',
      description: 'The deductible is the amount that you must pay before the insurance covers the rest. Usually, the Carrier just deducts this amount from the total coverage. Also, the deductibles are given as a percentage of the value (of structure or contents) or as a specefic amount.',
      label: {
        namedPerils: 'Named perils',
        unNamedPerils: 'Unnamed perils',
        namedPerilsP: 'Named perils percentage',
        namedPerilsA: 'Named perils amount',
        unNamedPerilsP: 'Unnamed perils percentage',
        unNamedPerilsA: 'Unnamed perils amount',
      },
      dropdown: [
        { value: 'percentage', label: 'Percentage' },
        { value: 'amount', label: 'Amount' },
      ]     
    },
    scheduledItems: {
      title: 'Scheduled Items',
      description: 'If there are any specific items that you have coverage, at a specified amount, list those and their value here.',
      label: {
        item: 'Item',
        replacementValue: 'Replacement value',
      }
    },
    additionalInfo: {
      title: 'Additional Information',
      description: 'Any other pertinent information about the policy, including highlights unique to this Carrier, additional endorcements, etc.',
      label: {
        additionalInfo: 'Please provide additional information'
      }
    } 
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
  },
  modal: {
    heading: 'Do you want to delete',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    content: {
      policyType: 'Policy types determine what type of structure is covered; what perils, like wind, hail, fire, etc., are covered (or excluded); and what parts of the structure are covered. HO stands for Homeowners. HO-1 through HO-3, HOB, and HO-5 are in ascending order of perils covered and all cover the entire structure. HO-7 and HO-8 are like those but HO-7 is for Mobile Homes and HO-8 is for Old/Historic Homes. HO-6 assumes the exterior structure is covered by an HOA, like with Condos, some Townhomes, etc., and covers the interior, with internal structure. HO-4 does NOT cover structure, only drywall in.',
      structureCoverage: 'Replacement Cost takes inflation into account and will replace the Structure with the quality of craftsmanship and materials used, at their current market value, at the time of loss. Whereas, Actual Cash Value depreciates the value of your Structure, due to wear, etc., and does NOT take inflation into account.',
      contentsCoverage: 'Replacement Cost takes inflation into account and will replace contents with new items, at their current market value, at the time of loss. Whereas, Actual Cash Value depreciates the value of your belongings, due to wear, etc., and does NOT take inflation into account.',
      namedPerils: 'Named Perils are perils, like wind, hail, fire, etc., that are listed in the policy. So, here you will find either a percentage of the value given for the Structure as the deductible amount or a cash amount. The deductible is the amount that you would pay before the insurance pays the rest.',
      unNamedPerils: 'In most cased there are Unamed Perils which are perils that are NOT listed in the policy but are still covered (as long as they are NOT listed in Exclusions). Many times this deductible is higher than the amount for Named Perils but can be the same if you or the Agent has requested it to be. Refer to Named Perils for more on deductibles.',
      item: 'This is an item that you want to attach a specific value to in case it is lost or destroyed. This might include jewelry, collectibles, art, etc. Make sure to provide the appraised or Replacement value.',
    }
  },
  readOnlyTitles: {
    coverageTypes: {
      structureCoverage: {
        numbers: [
          {
            key: 'replacementCostValue',
            title: 'Replacement value of structure',
            dataType: 'number',
            value: ''
          },
          {
            key: 'actualCashValue',
            title: 'Actual Cash Value of structure',
            dataType: 'number',
            value: ''
          },
        ],
        booleans: [
          {
            key: 'replacementCost',
            title: 'Replacement cost',
            dataType: 'boolean',
            value: ''
          },
          {
            key: 'actualCash',
            title: 'Actual cash',
            dataType: 'boolean',
            value: ''
          },
        ],
      },
      contentsCoverage: {
        numbers: [
          {
            key: 'replacementCostValue',
            title: 'Replacement value of structure',
            dataType: 'number',
            value: ''
          },
          {
            key: 'actualCashValue',
            title: 'Actual Cash Value of structure',
            dataType: 'number',
            value: ''
          },
        ],
        booleans: [
          {
            key: 'replacementCost',
            title: 'Replacement cost',
            dataType: 'boolean',
            value: ''
          },
          {
            key: 'actualCash',
            title: 'Actual cash',
            dataType: 'boolean',
            value: ''
          },
        ],
      },
    },
    deductible: {
      namedPerils: {
        booleans: [
          {
            key: 'percentage',
            title: 'Deductible (Percentage of Structure value)',
            dataType: 'boolean',
            value: '',
          },
          {
            key: 'amount',
            title: 'Amount',
            dataType: 'boolean',
            value: '',
          },
        ],
        numbers: [
          {
            key: 'amountValue',
            title: 'Deductible Amount',
            dataType: 'number',
            value: '',
          },
        ],
        percentages: [
          {
            key: 'percentageValue',
            title: 'Deductible (Percentage of Structure value)',
            dataType: 'percent',
            value: '',
          },
        ],
      },
      unNamedPerils: {
        booleans: [
          {
            key: 'percentage',
            title: 'Deductible (Percentage of Structure value)',
            dataType: 'boolean',
            value: '',
          },
          {
            key: 'amount',
            title: 'Amount',
            dataType: 'boolean',
            value: '',
          },
        ],
        numbers: [
          {
            key: 'amountValue',
            title: 'Deductible Amount',
            dataType: 'number',
            value: '',
          },
        ],
        percentages: [
          {
            key: 'percentageValue',
            title: 'Deductible (Percentage of Structure value)',
            dataType: 'percent',
            value: '',
          },
        ],
      },
    },
    effectiveDates: {
      dates: [
        {
          key: 'start',
          title: 'Income start date',
          dataType: 'date',
          value: ''
        },
        {
          key: 'end',
          title: 'Income end date',
          dataType: 'date',
          value: ''
        },
      ]
    },
    policyType: {
      booleans: [
        {
          key: 'HO1',
          title: 'HO-1 (Basic Form - Covers named perils only)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HO2',
          title: 'HO-2 (Broad Form - Covers more Perils than HO-1, only)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HO3',
          title: 'HO-3 (Special Form - Covers all perils except those in exclusions; more than HO-2)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HOB',
          title: 'HOB (Similar to HO-3 but might cover a few more perils and items)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HO4',
          title: 'HO-4 (Tenant’s or Renter’s Form - Covers belongings and Liability only)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HO5',
          title: 'HO-5 (Comprehensive Form - Less exclusions than HO-3)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HO6',
          title: 'HO-6 (Condo Form - Covers belongings, Liability, and some internal structure)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HO7',
          title: 'HO-7 (Mobile Home Form - Like HO-3 for mobile homes)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HO8',
          title: 'HO-8 (Older Home Form – Used to inure older homes)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'other',
          title: 'Business or other',
          dataType: 'boolean',
          value: ''
        },
      ],
      strings: [
        {
          key: 'otherType',
          title: 'Other type',
          dataType: 'string',
          value: ''
        },
      ],
    },
  }
}
  


