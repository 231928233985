import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import agentReducer from './agent/agent.reducer';
import agentBiddingReducer from './agent-biddings/agent-bidding.reducer';
import authReducer from './auth/auth.reducer';
import companionAnimalReducer from './quotes/companion-animal/companion-animal.reducer';
import formCanvasReducer from './form-canvas/form-canvas.reducer';
import healthReducer from './quotes/health/health.reducer';
import memberReducer from './member/member.reducer';
import modalReducer from './modal/modal.reducer';
import navigationReducer from './navigation/navigation.reducer';
import memberAnnuityPolicyReducer from './policies/annuity/annuity.reducer';
import memberCompanionAnimalPolicyReducer from './policies/companion-animal/companion-animal.reducer';
import memberLifePolicyReducer from './policies/life/life.reducer';
import memberMedicalPolicyReducer from './policies/medical/medical.reducer';
import memberPropertyPolicyReducer from './policies/property/property.reducer';
import memberVehiclePolicyReducer from './policies/vehicle/vehicle.reducer';
import propertyReducer from './quotes/property/property.reducer';
import vehicleReducer from './quotes/vehicle/vehicle.reducer';
import memberHistoryReducer from './quotes/member-history/member-history.reducer';
import animalQuoteReducer from './requested-quotes/companion-animal/companion-animal.reducer';
import propertyQuoteReducer from './requested-quotes/property/property.reducer';
import vehicleQuoteReducer from './requested-quotes/vehicle/vehicle.reducer';
import combineCoverageQuoteReducer from './requested-quotes/combine-coverages/combine-coverages.reducer';
import healthQuoteReducer from './requested-quotes/health/health.reducer';
import lifeQuoteReducer from './requested-quotes/life/life.reducer';
import annuityQuoteReducer from './requested-quotes/annuity/annuity.reducer';
import profileReducer from './profile/profile.reducer';
import memberDocumentReducer from './policies/document/document.reducer';
import agentAnnuityQuoteReducer from './agent-quotes/annuity/annuity.reducer';
import agentAnimalQuoteReducer from './agent-quotes/companion-animal/companion-animal.reducer';
import agentLifeQuoteReducer from './agent-quotes/life/life.reducer';
import agentMedicalQuoteReducer from './agent-quotes/medical/medical.reducer';
import agentPropertyQuoteReducer from './agent-quotes/property/property.reducer';
import agentVehicleQuoteReducer from './agent-quotes/vehicle/vehicle.reducer';
import annuityBiddingReducer from './agent-biddings/annuity/annuity-bidding.reducer';
import animalBiddingReducer from './agent-biddings/animal/animal-bidding.reducer';
import propertyBiddingReducer from './agent-biddings/property/property-bidding.reducer';
import vehicleBiddingReducer from './agent-biddings/vehicle/vehicle-bidding.reducer';
import combineBiddingReducer from './agent-biddings/combine/combine-bidding.reducer';
import lifeBiddingReducer from './agent-biddings/life/life-bidding.reducer';
import healthBiddingReducer from './agent-biddings/health/health-bidding.reducer';

const persistConfig = {
  key: 'insureasier',
  storage,
  whitelist: [
    'auth',
    'navigation',
    'agent',
    'member',
    'annuityQuote',
    'animalQuote',
    'lifeQuote',
    'healthQuote',
    'propertyQuote',
    'vehicleQuote',
    'combineCoverageQuote',
    'agentBidding',
    'annuityBiddingReducer',
    'animalBiddingReducer',
    'propertyBiddingReducer',
    'vehicleBiddingReducer',
    'combineBiddingReducer',
    'lifeBiddingReducer',
    'healthBiddingReducer',
  ],
};

const rootReducer = combineReducers({
  navigation: navigationReducer,
  auth: authReducer,
  health: healthReducer,
  modal: modalReducer,
  companionAnimal: companionAnimalReducer,
  vehicle: vehicleReducer,
  property: propertyReducer,
  member: memberReducer,
  agent: agentReducer,
  agentBidding: agentBiddingReducer,
  profile: profileReducer,
  formCanvas: formCanvasReducer,
  memberCompanionAnimalPolicy: memberCompanionAnimalPolicyReducer,
  memberVehiclePolicy: memberVehiclePolicyReducer,
  memberPropertyPolicy: memberPropertyPolicyReducer,
  memberAnnuityPolicy: memberAnnuityPolicyReducer,
  memberLifePolicy: memberLifePolicyReducer,
  memberMedicalPolicy: memberMedicalPolicyReducer,
  memberHistory: memberHistoryReducer,
  animalQuote: animalQuoteReducer,
  propertyQuote: propertyQuoteReducer,
  vehicleQuote: vehicleQuoteReducer,
  combineCoverageQuote: combineCoverageQuoteReducer,
  healthQuote: healthQuoteReducer,
  lifeQuote: lifeQuoteReducer,
  annuityQuote: annuityQuoteReducer,
  memberDocument: memberDocumentReducer,
  agentAnnuityQuote: agentAnnuityQuoteReducer,
  agentAnimalQuote: agentAnimalQuoteReducer,
  agentLifeQuote: agentLifeQuoteReducer,
  agentMedicalQuote: agentMedicalQuoteReducer,
  agentPropertyQuote: agentPropertyQuoteReducer,
  agentVehicleQuote: agentVehicleQuoteReducer,
  annuityBidding: annuityBiddingReducer,
  animalBidding: animalBiddingReducer,
  propertyBidding: propertyBiddingReducer,
  vehicleBidding: vehicleBiddingReducer,
  combineBidding: combineBiddingReducer,
  lifeBidding: lifeBiddingReducer,
  healthBidding: healthBiddingReducer,
});

export default persistReducer(persistConfig, rootReducer);
