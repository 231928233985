import { PROPERTY_QUOTES_CMS_DATA } from '../../../pages/quotes/property/property.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import propertyActionTypes from './property.types';

const payload = {
  context: 'property',
  status: '',
  error: '',
};

export const setIsSubmitted = (toggle) => ({
  type: propertyActionTypes.SET_IS_SUBMITTED,
  payload: toggle,
});

export const togglePropertyForm = (toggle) => ({
  type: propertyActionTypes.TOGGLE_PROPERTY_FORM,
  payload: toggle,
});

export const togglePropertyOptions = (toggle) => ({
  type: propertyActionTypes.TOGGLE_PROPERTY_OPTIONS,
  payload: toggle,
});

export const togglePropertyInfoEdit = (toggle) => ({
  type: propertyActionTypes.TOGGLE_PROPERTY_INFO_EDIT,
  payload: toggle,
});

export const setClearPropertyForm = (clear) => ({
  type: propertyActionTypes.SET_CLEAR_PROPERTY_FORM,
  payload: clear,
});

export const startLoadingStatesFromDB = () => ({
  type: propertyActionTypes.LOAD_STATES_FROM_DB_START,
});

export const loadStatesFromDBSuccess = (data) => ({
  type: propertyActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data,
});

export const loadStatesFromDBFailure = (e) => ({
  type: propertyActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: PROPERTY_QUOTES_CMS_DATA.errMsg.states,
});

export const startFetchingAllPropertiesFromDB = () => ({
  type: propertyActionTypes.FETCH_ALL_PROPERTIES_FROM_DB_START,
});

export const fetchAllPropertiesFromDBSuccess = (properties) => ({
  type: propertyActionTypes.FETCH_ALL_PROPERTIES_FROM_DB_SUCCESS,
  payload: properties,
});

export const fetchAllPropertiesFromDBFailure = (e) => ({
  type: propertyActionTypes.FETCH_ALL_PROPERTIES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingPropertyFromDB = (id) => ({
  type: propertyActionTypes.FETCH_PROPERTY_FROM_DB_START,
  payload: id,
});

export const fetchPropertyFromDBSuccess = (property) => ({
  type: propertyActionTypes.FETCH_PROPERTY_FROM_DB_SUCCESS,
  payload: property,
});

export const fetchPropertyFromDBFailure = (e) => ({
  type: propertyActionTypes.FETCH_PROPERTY_FROM_DB_FAILURE,
  payload: e,
});

export const startPropertySave = (property) => ({
  type: propertyActionTypes.START_PROPERTY_SAVE,
  payload: property,
});

export const propertySaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const propertySaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startPropertyUpdate = (id, formData) => ({
  type: propertyActionTypes.START_PROPERTY_UPDATE,
  payload: { id, formData },
});

export const propertyUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const propertyUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startPropertyInQuoteSave = (pq) => ({
  type: propertyActionTypes.START_PROPERTY_IN_QUOTE_SAVE,
  payload: pq,
});

export const propertyInQuoteSaveSuccess = () => ({
  type: propertyActionTypes.PROPERTY_IN_QUOTE_SAVE_SUCCESS,
});

export const propertyInQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startPropertyInQuoteUpdate = (id, formData) => ({
  type: propertyActionTypes.START_PROPERTY_IN_QUOTE_UPDATE,
  payload: { id, formData },
});

export const propertyInQuoteUpdateSuccess = () => ({
  type: propertyActionTypes.PROPERTY_IN_QUOTE_UPDATE_SUCCESS,
});

export const propertyInQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startPropertyDelete = (id, PId) => ({
  type: propertyActionTypes.START_PROPERTY_DELETE,
  payload: { id, PId },
});

export const propertyDeleteSuccess = () => ({
  type: propertyActionTypes.PROPERTY_DELETE_SUCCESS,
});

export const propertyDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

