const memberDocumentActionTypes = {
  FETCH_UPLOADED_FILES_FROM_DB_START: 'FETCH_UPLOADED_FILES_FROM_DB_START',
  FETCH_UPLOADED_FILES_FROM_DB_SUCCESS: 'FETCH_UPLOADED_FILES_FROM_DB_SUCCESS',
  FETCH_UPLOADED_FILES_FROM_DB_FAILURE: 'FETCH_UPLOADED_FILES_FROM_DB_FAILURE',
  FETCH_UPLOADED_CHUNKS_FROM_DB_START: 'FETCH_UPLOADED_CHUNKS_FROM_DB_START',
  FETCH_UPLOADED_CHUNKS_FROM_DB_SUCCESS: 'FETCH_UPLOADED_CHUNKS_FROM_DB_SUCCESS',
  FETCH_UPLOADED_CHUNKS_FROM_DB_FAILURE: 'FETCH_UPLOADED_CHUNKS_FROM_DB_FAILURE',
  FETCH_UPLOADED_CHUNK_FROM_DB_START: 'FETCH_UPLOADED_CHUNK_FROM_DB_START',
  FETCH_UPLOADED_CHUNK_FROM_DB_SUCCESS: 'FETCH_UPLOADED_CHUNK_FROM_DB_SUCCESS',
  FETCH_UPLOADED_CHUNK_FROM_DB_FAILURE: 'FETCH_UPLOADED_CHUNK_FROM_DB_FAILURE',
  FETCH_UPLOADED_FILE_FROM_DB_START: 'FETCH_UPLOADED_FILE_FROM_DB_START',
  FETCH_UPLOADED_FILE_FROM_DB_SUCCESS: 'FETCH_UPLOADED_FILE_FROM_DB_SUCCESS',
  FETCH_UPLOADED_FILE_FROM_DB_FAILURE: 'FETCH_UPLOADED_FILE_FROM_DB_FAILURE',
  SET_SHOW_FORM: 'SET_SHOW_FORM',
  SET_IS_AGENT: 'SET_IS_AGENT',
  START_DELETE_DOCUMENT: 'START_DELETE_DOCUMENT',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  SET_CLEAR_UPLOAD_FILE_AND_CHUNK: 'SET_CLEAR_UPLOAD_FILE_AND_CHUNK',
  STORE_DOC_CHUNK_INFO: 'STORE_DOC_CHUNK_INFO',
}

export default memberDocumentActionTypes;