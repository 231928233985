export const MEMBER_COMPANION_ANIMAL_POLICIES_CMS_DATA = {
  heading: 'My Animal Policy',
  subHeading: 'Enter the information pertaining to each animal on a single policy; if on a separate policy, you\'ll be able to and want to fill out another form for each policy. Agents will be required to fill in all the information but you are only required to enter the Name(s) and related Documents to store; feel free to enter any other pertinent information you\'d like.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    },
    documents: {
      title: 'Documents',
      description: 'Here is where you can upload documents related to the policy, like declaration pages, full policy coverage, etc. Only upload documents pertinent to the policy.',
      label: 'Upload your documents and images',
    },
    companionAnimals: {
      title: 'Companion Animal(s)',
      description: 'Please enter all the names of the pets you have on this policy. This is how the policy will be referenced.',
      label: {
        name: 'Name',
        species: 'Species',
        breed: 'Type or Breed name(s)',
        dropdown: {
          species: [
            { value: 'dog', label: 'Dog' },
            { value: 'cat', label: 'Cat' },
            { value: 'bird', label: 'Bird' },
            { value: 'fish', label: 'Fish' },
            { value: 'horse', label: 'Equine/Horse' },
            { value: 'livestock', label: 'Livestock' },
            { value: 'exotic', label: 'Exotic' },
          ]
        },
      },
    },
    upload: {
      title: 'Documents',
      description: 'Here is where you can upload documents related to the policy, like declaration pages, full policy coverage, photo of your companion, etc. Only upload documents pertinent to the policy.',
      label: {
        documents: 'Upload your documents and images'
      }
    },
    additionalInfo: {
      title: 'Additional Info',
      description: 'Any other pertinent information about the policy, including highlights, endorcements, etc.',
      label: {
        additionalInfo: 'Please provide additional information'
      }
    } 
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
  },
  modal: {
    heading: 'Do you want to delete',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
}

