import { createSelector } from 'reselect';

const selectVehicleQuote = (state) => state.vehicleQuote

export const selectCurrentStep = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.currentStep
);

export const selectVehicleFormData = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.formData
);

export const selectNoCombineId = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.noCombineId
);

export const selectVehicleCombineId = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.combineId
);

export const selectVehicleCombineCV = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.combineCV
);

export const selectVehicleQuotesFromDB = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.vehicleQuotesFromDB
);

export const selectMembersInfo = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.membersInfo
);

export const selectVehicleQuoteFromDB = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.vehicleQuoteFromDB
);

export const selectTimer = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.timer
);

export const selectReset = createSelector(
  [selectVehicleQuote],
  (vehicleQuote) => vehicleQuote.reset
);





