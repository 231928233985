export const AGENT_BENEFITS_CMS_DATA = {
  title: 'How can we help you grow?',
  imageUrl: './images/watering.png',
  introduction: `In the fast-paced world of insurance sales, agents are constantly seeking new ways to enhance their productivity and efficiency while delivering exceptional service to their clients. 
    InsurEasier, a cutting-edge insurance buying and selling platform, offers a range of benefits tailored specifically for agents, empowering them to succeed in today's competitive market.
    Let's explore the three key advantages agents can enjoy by selling policies on our platform.`,
  benefits: [
    {
      title: 'Pay-for-Performance Model',
      imageUrl: './images/insurance-2.webp',
      description1: `One of the most significant challenges agents face in traditional insurance sales is the uncertainty and financial risk associated with purchasing leads upfront. 
        With InsurEasier, agents can bid farewell to wasted marketing budgets and unnecessary expenses.
        Our platform operates on a pay-for-performance model, which means agents only pay for leads when the lead has decided to convert their quote into a policy.`,
      description2: `This innovative approach ensures that agents maximize their return on investment by focusing their efforts on the highest quality leads with a near certain likelihood of conversion.`,
    },
    {
      title: 'Effortless Client Acquisition',
      imageUrl: './images/insurance-1.webp',
      description1: `Gone are the days of cold calling, sending bulk mailers, or relying on traditional leads or live transfers. 
        InsurEasier simplifies the process of client acquisition by providing agents with a steady stream of qualified leads without the need for intrusive marketing tactics or gimmicks.
        Through our platform, agents can effortlessly attract clients who are actively seeking insurance solutions, saving time and resources while maximizing efficiency.`,
      description2: `By eliminating the need for traditional outreach methods, InsurEasier empowers agents to focus their time and energy on providing exceptional service and building meaningful relationships with their clients.`
    },
    {
      title: 'Let Us Work for You',
      imageUrl: './images/insurance-3.webp',
      description1: `Finding and connecting with potential clients can be a daunting task for agents, especially in a crowded marketplace. 
        InsurEasier simplifies this process by leveraging automation to identify and match agents with qualified leads.
        Our website serves as a powerful tool for agents, driving traffic to them through targeted marketing campaigns and personalized recommendations.`,
      description2: `InsurEasier's advanced technology ensure that agents are connected with leads that have the highest potential for conversion. 
        This proactive approach allows agents to focus on what they do best – building relationships and closing deals.`,
    },
  ]
}