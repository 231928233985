import formCanvasTypes from './form-canvas.types';

const INITIAL_STATE = {
  currentStep: 0,
  formData: {},
};

const formCanvasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case formCanvasTypes.REVISIT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    case formCanvasTypes.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        formData: {
          ...state.formData,
          [state.currentStep]: {
            values: action.payload.data,
            storeToDb: action.payload.storeToDb,
          },
        },
      };
    default:
      return state;
  }
};

export default formCanvasReducer;
