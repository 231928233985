import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RULE_ENGINE } from './rule-engine';
import ProgressBar from '../../components/progress-bar/progress-bar.component';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  selectUser,
  selectIsUserLoggedIn,
} from '../../redux/auth/auth.selectors';
import {
  decrementCurrentStep,
  incrementCurrentStep,
  startFetchingAgentFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
  selectCurrentStep,
} from '../../redux/agent/agent.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../redux/member/member.selectors';

const AgentSteps = ({
  currentStep,
  primeMember,
  currentUser,
  decrementCurrentStep,
  incrementCurrentStep,
  startFetchingAgentFromDB,
  startFetchingPrimeMemberFromDB,
}) => {
  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const { heading, subHeading } = RULE_ENGINE[path][0][0];

  let FormComponent =
    RULE_ENGINE[path][1] 
      ? RULE_ENGINE[path][1][currentStep].component
      : '';
  let steps = RULE_ENGINE[path][1];

  const decrement = () => {
    decrementCurrentStep(currentStep);
  }

  const increment = () => {
    incrementCurrentStep(currentStep);
  }

  useEffect(() => {
    startFetchingAgentFromDB(currentUser.agent);
  }, [
    currentUser,
    startFetchingAgentFromDB,
  ]);

  useEffect(() => {
    startFetchingPrimeMemberFromDB(currentUser.member);
  }, [startFetchingPrimeMemberFromDB, currentUser])

  return (
    <section className='ie-form-scaffold pt-5'>
      <ProgressBar
        steps={steps}
        currentStep={currentStep}
      >
      </ProgressBar>
      <Container className='pt-5'>
        <header className='py-4'>
          <h1>{heading}</h1>
          <p className='font-size-xl'>{subHeading}</p>
        </header>
        <FormComponent
          currentStep={currentStep}
          decrement={decrement}
          increment={increment}
          primeMember={primeMember}
        />
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
  currentStep: selectCurrentStep,
  primeMember: selectPrimeMember,
  isUserLoggedIn: selectIsUserLoggedIn,
});

export default withRouter(
  connect(mapStateToProps, {
    decrementCurrentStep,
    incrementCurrentStep,
    startFetchingAgentFromDB,
    startFetchingPrimeMemberFromDB,
  })(AgentSteps)
);