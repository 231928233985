import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Showcase from '../../components/agent-showcase/showcase.component';
import AgentBenefits from '../../components/agent-benefits/agent-benefits.component';
import AgentHowItWorks from '../../components/agent-how-work/agent-how-work.component';
import { selectGoToAgentDashboard } from '../../redux/navigation/navigation.selectors';

const AgentLanding = ({ goToAgentDashboard, history }) => {
  useEffect(() => {
    if (goToAgentDashboard) {
      history.push('/agent-dashboard');
    } 
  }, [goToAgentDashboard, history]);

  return (
    <>
      <Showcase />
      <AgentBenefits />
      <AgentHowItWorks />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  goToAgentDashboard: selectGoToAgentDashboard,
});

export default connect(mapStateToProps, {})(withRouter(AgentLanding));
