import vehicleActionTypes from './vehicle.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  makes: [],
  selectedMake: [],
  modelsForSelectedMake: [],
  selectedModel: [],
  vehiclesFromDB: [],
  vehicleFromDB: {},
  reset: false,
  isSubmitted: false,
  showVehicleForm: false,
  showVehicleOptions: false,
  showVehicleInfoEdit: false,
  clearVehicleForm: false,
};

const vehicleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case vehicleActionTypes.FETCH_ALL_VEHICLES_FROM_DB_SUCCESS:
      return {
        ...state,
        vehiclesFromDB: action.payload,
      };
    case vehicleActionTypes.FETCH_VEHICLE_FROM_DB_SUCCESS:
      return {
        ...state,
        vehicleFromDB: action.payload,
      };
    case vehicleActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS:
      return {
        ...state,
        makes: action.payload,
      };
    case vehicleActionTypes.SELECT_MAKE:
      return {
        ...state,
        selectedMake: action.payload,
      };
    case vehicleActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS:
      return {
        ...state,
        modelsForSelectedMake: action.payload,
      };
    case vehicleActionTypes.SELECT_MODEL:
      return {
        ...state,
        selectedModel: action.payload,
      };
    case vehicleActionTypes.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    case vehicleActionTypes.TOGGLE_VEHICLE_FORM:
      return {
        ...state,
        showVehicleForm: action.payload,
      };
    case vehicleActionTypes.TOGGLE_VEHICLE_OPTIONS:
      return {
        ...state,
        showVehicleOptions: action.payload,
      };
    case vehicleActionTypes.TOGGLE_VEHICLE_INFO_EDIT:
      return {
        ...state,
        showVehicleInfoEdit: action.payload,
      };
    case vehicleActionTypes.SET_CLEAR_VEHICLE_FORM:
      return {
        ...state,
        clearVehicleForm: action.payload,
        vehicleFromDB: {},
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        makes: [],
        selectedMake: [],
        modelsForSelectedMake: [],
        selectedModel: [],
        vehiclesFromDB: [],
        vehicleFromDB: {},
        reset: false,
        isSubmitted: false,
        showVehicleForm: false,
        showVehicleOptions: false,
        showVehicleInfoEdit: false,
        clearVehicleForm: false,
      };
    case vehicleActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default vehicleReducer;
