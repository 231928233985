const memberLifePolicyActionTypes = {
  FETCH_MEMBERS_FROM_DB_START: 'FETCH_MEMBERS_FROM_DB_START',
  FETCH_MEMBERS_FROM_DB_SUCCESS: 'FETCH_MEMBERS_FROM_DB_SUCCESS',
  FETCH_MEMBERS_FROM_DB_FAILURE: 'FETCH_MEMBERS_FROM_DB_FAILURE',
  FETCH_LIFE_POLICY_FROM_DB_START: 'FETCH_LIFE_POLICY_FROM_DB_START',
  FETCH_LIFE_POLICY_FROM_DB_SUCCESS: 'FETCH_LIFE_POLICY_FROM_DB_SUCCESS',
  FETCH_LIFE_POLICY_FROM_DB_FAILURE: 'FETCH_LIFE_POLICY_FROM_DB_FAILURE',
  LOAD_STATES_FROM_DB_START: 'LOAD_STATES_FORM_DB_START',
  LOAD_STATES_FROM_DB_SUCCESS: 'LOAD_STATES_FROM_DB_SUCCESS',
  LOAD_STATES_FROM_DB_FAILURE: 'LOAD_STATES_FROM_DB_FAILURE',
  START_LIFE_POLICY_SAVE: 'START_LIFE_POLICY_SAVE',
  START_LIFE_POLICY_UPDATE: 'START_LIFE_POLICY_UPDATE',
  START_LIFE_POLICY_DELETE: 'START_LIFE_POLICY_DELETE',
  LIFE_POLICY_DELETE_SUCCESS: 'LIFE_POLICY_DELETE_SUCCESS',
  SET_CLEAR_FORM: 'SET_CLEAR_FORM',
  RESET_FORM: 'RESET_FORM',
}

export default memberLifePolicyActionTypes;