import { ModalState, ModalTypes } from '../../modal/modal.types';
import memberDocumentActionTypes from './document.types';

const payload = {
  context: 'memberDocument',
  status: '',
  error: ''
}

export const startFetchingUploadedFilesFromDB = () => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_FILES_FROM_DB_START,
});

export const fetchUploadedFilesFromDBSuccess = (files) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_FILES_FROM_DB_SUCCESS,
  payload: files,
});

export const fetchUploadedFilesFromDBFailure = (e) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_FILES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingUploadedFileFromDB = (id) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_FILE_FROM_DB_START,
  payload: id,
});

export const fetchUploadedFileFromDBSuccess = (file) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_FILE_FROM_DB_SUCCESS,
  payload: file,
});

export const fetchUploadedFileFromDBFailure = (e) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_FILE_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingUploadedChunksFromDB = (ids) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_CHUNKS_FROM_DB_START,
  payload: ids,
});

export const fetchUploadedChunksFromDBSuccess = (files) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_CHUNKS_FROM_DB_SUCCESS,
  payload: files,
});

export const fetchUploadedChunksFromDBFailure = (e) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_CHUNKS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingUploadedChunkFromDB = (id) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_CHUNK_FROM_DB_START,
  payload: id,
});

export const fetchUploadedChunkFromDBSuccess = (file) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_CHUNK_FROM_DB_SUCCESS,
  payload: file,
});

export const fetchUploadedChunkFromDBFailure = (e) => ({
  type: memberDocumentActionTypes.FETCH_UPLOADED_CHUNK_FROM_DB_FAILURE,
  payload: e,
});

export const setShowForm = (toggle) => ({
  type: memberDocumentActionTypes.SET_SHOW_FORM,
  payload: toggle
});

export const setIsAgent = (toggle) => ({
  type: memberDocumentActionTypes.SET_IS_AGENT,
  payload: toggle
});

export const storeDocChunkInfo = (info) => ({
  type: memberDocumentActionTypes.STORE_DOC_CHUNK_INFO,
  payload: info
});

export const setClearUploadFile = () => ({
  type: memberDocumentActionTypes.SET_CLEAR_UPLOAD_FILE_AND_CHUNK,
});

export const startDocumentDelete = (id, policyId, policyType, chunkId, quoteType) => ({
  type: memberDocumentActionTypes.START_DELETE_DOCUMENT,
  payload: { id, policyId, policyType, chunkId, quoteType },
});

export const documentDeleteSuccess = () => ({
  type: memberDocumentActionTypes.DELETE_DOCUMENT_SUCCESS,
});

export const documentDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});



