import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { VEHICLE_CMS_DATA } from '../../../pages/quotes/vehicle/vehicle.cms';
import Dropdown from '../../dropdown/dropdown.component';
import InfoIcon from '../../info-icon/info-icon.component';
import NumberFormation from '../../number-format/number-format.component';
import RadioButton from '../../radio-button/radio-button.component';
import { useHistory } from 'react-router-dom';

const VehicleInfo = ({ values, errors, touched, handleChange }) => {
  const {
    form: {
      vehicle: {
        info: { title, description, label },
      },
    },
    popOver: { information }
  } = VEHICLE_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={7}>
              <Form.Label>{label.ownership}</Form.Label>
              <br/>
              {label.dropdown.ownership.map((option, i) => (
                <RadioButton
                  key={i}
                  idx={i}
                  name='ownership'
                  option={option}
                />
              ))}
              <Form.Control.Feedback type='invalid'>
                {errors.ownership}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={5}>
              <Form.Label>{label.annualMileage}</Form.Label>
              <NumberFormation
                readOnly={
                  path === agentBid ? true : false
                }
                name='annualMileage'
                value={values.annualMileage}
                errors={errors}
                touched={touched}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.annualMileage}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg={6} controlId='primaryUse' ie-hint-multiselect='true'>
              <Form.Label>{label.primaryUse}</Form.Label>
              <Dropdown
                isDisabled={
                  path === agentBid ? true : false
                }
                name='primaryUse'
                selected={values.primaryUse}
                options={label.dropdown.primaryUse}
                touched={touched}
                errors={errors}
                values={values}
                isMulti={true}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.primaryUse}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId='parking' ie-hint-multiselect='true'>
              <Form.Label>{label.parking}</Form.Label>
              <Dropdown
                isDisabled={
                  path === agentBid ? true : false
                }
                name='parking'
                selected={values.parking}
                options={label.dropdown.parking}
                touched={touched}
                errors={errors}
                values={values}
                isMulti={true}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.parking}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group optional='true' as={Col} lg={12}>
              <Form.Label>{label.additionalInformation}</Form.Label>
              <InfoIcon
                className='float-right'
                content={information.content.addInfo}
              />
              <Form.Control
                readOnly={
                  path === agentBid ? true : false
                }
                as='textarea'
                rows='3'
                name='additionalInformation'
                value={values.additionalInformation}
                onChange={handleChange}
                isValid={
                  touched.additionalInformation && !errors.additionalInformation
                }
                isInvalid={!!errors.additionalInformation}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.additionalInformation}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

export default VehicleInfo;
