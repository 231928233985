import React from 'react';
import Agent from '../agent/agent.component';
import ResetPassword from '../../components/reset-password/profile-reset-password.component';

const AgentProfile = () => {  
  return (
    <>
      <Agent />
      <ResetPassword />
    </>
  );
};

export default AgentProfile;

