import React from 'react';

const ErrorMessages = ({ errors }) => {
  const errorsArray = (errs) => {
    return Object.keys(errs).map((key) => errs[key]);
  }

  return (
    <>
      {errorsArray(errors).length !== 0 ? (
          <div className='text-right error_message'>
            {errorsArray(errors).map((error, i) => (
              <div key={`error${i}`}>
                {typeof error === 'object' ? (
                  <div key={`error${i}${i}`}>
                    {error.map((err, i) => (
                      <div key={`err${i}`}>
                        {err !== undefined ? (
                          <div key={`err${i}${i}`}>
                            {errorsArray(err).map((e, i) => (
                              <p key={`e${i}`}> {e}</p>
                            ))}
                          </div>
                        ): null}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p key={`error${i}`}>{error}</p>
                )}
              </div>
            ))}
          </div>
      ) : null}
    </>
  )
};

export default ErrorMessages;
