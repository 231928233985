import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  setClearMemberForm,
  toggleMemberForm,
  startFetchingMemberFromDB,
} from '../../redux/member/member.actions';
import {
  selectMember,
  selectMembers,
} from '../../redux/member/member.selectors';
import {
  startFetchingMemberHistoryByIdFromDB
} from '../../redux/quotes/member-history/member-history.actions';
import {
  selectMemberHistoryById
} from '../../redux/quotes/member-history/member-history.selectors';

const AddMembers = ({
  values,
  touched,
  errors,
  name,
  member,
  toggleMemberForm,
  setClearMemberForm,
}) => {
  const {
    form: {
      selectMemberType: { title, description, memberRelation, dropdown },
    },
  } = MEMBER_CMS_DATA;

  const [memberType, setMemberType] = useState('');

  const handleMemberSelection = (val) => {
    if (val.value) {
      toggleMemberForm(true);
      setClearMemberForm();
    }
    setMemberType(val.value);
  };

  if (values && values.memberType === '') {
    values.memberType = memberType;
  }

  return (
    <div className='ie-form' id='member-types'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={6} controlId='memberTypes'>
              <Form.Label>{memberRelation}</Form.Label>
              <Select
                name='HMemberType'
                options={dropdown.memberTypes}
                selected={values.HMemberType}
                values={values}
                onChange={handleMemberSelection}
                touched={touched}
                isValid={touched.type && !errors.type}
                isInvalid={!!errors.type}
                className={`select ${
                  touched[name]
                    ? `is-${!!errors[name] ? 'invalid' : 'valid'}`
                    : ''
                }`}
                theme={(theme) => ({
                  ...theme,
                  boxShadow: '0 0 0 0.2rem rgba(0,146,112,.25)',
                  colors: {
                    ...theme.colors,
                    primary50: 'rgba(0,146,112,.25)',
                    primary25: 'rgba(0,146,112,.25)',
                    primary: '#349270',
                  },
                })}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.HMemberType}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  member: selectMember,
  members: selectMembers,
  memberHistoryById: selectMemberHistoryById,
});

export default connect(mapStateToProps, {
  setClearMemberForm,
  toggleMemberForm,
  startFetchingMemberFromDB,
  startFetchingMemberHistoryByIdFromDB,
})(AddMembers);