export const GET_QUOTES_CMS_DATA = {
  heading: 'Request Quotes',
  subHeading: `Which type of insurance would you like to attack now? You'll be getting quotes from some of our Agents and thousands of possible Carriers.`,
  cards: [
    {
      imageUrl: `./images/cars.webp`,
      title: `Vehicle`,
      text: `Let our agents compare and offer road, water, or industrial vehicle quotes to best fit your needs and desires.`,
      goto: `/household-vehicle-quotes`,
    },
    {
      imageUrl: `./images/home.webp`,
      title: `Property`,
      text: `Secure your home, business, or other properties with Carriers backed by the World's leading insurers.`,
      goto: `/household-property-quotes`,
    },
    {
      imageUrl: `./images/combined.webp`,
      title: `Combined`,
      text: `Combine your Home with your Auto insurance to save money. Many carriers give discounts for combining these two coverages.`,
      goto: `/household-combined-quotes`,
    },
    {
      imageUrl: `./images/health.webp`,
      title: `Medical`,
      text: 'Get Long Term, including Affordable Care Act (ACA) or ObamaCare policies, or Short Term policies to protect yourself and the ones you love.', // 'Learn/know your options and consider Supplemental for Deductibles and Maximums.',
      goto: `/household-health-quotes`,
    },
  ],
  cards1: [
    {
      title: `Life`,
      imageUrl: `./images/care.webp`,
      text: `Making sure the ones you love are protected; with options, like Universal Life, that can also go towards retirement.`,
      goto: `/household-life-quotes`,
    },
    {
      imageUrl: `./images/money-bag.webp`,
      title: `Annuity`,
      text: `Consider your options for retirement funds, whether it's to invest in the future or stretch a lump sum.`,
      goto: `/household-annuity-quotes`,
    },
    {
      imageUrl: `./images/pet.webp`,
      title: `Animal`,
      text: `Let us take care of your furry/hairy or maybe not so furry/hairy companions.`,
      goto: `/household-animal-quotes`,
    },
  ],
};
