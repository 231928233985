import React, { useEffect } from 'react';
import { Container, Card, CardGroup, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  selectUser,
} from '../../redux/auth/auth.selectors';
import {
  startFetchingAgentFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
} from '../../redux/agent/agent.selectors';
import AgentSteps from '../agent/agent-steps.component';
import { AGENT_DASHBOARD_CMS_DATA } from './agent.dashboard.cms';

const AgentDashboard = ({
  agent,
  currentUser,
  startFetchingAgentFromDB,
}) => {

  const {
    initial: {
      heading, subHeading, cards
    }
  } = AGENT_DASHBOARD_CMS_DATA;

  const screenW = window.innerWidth;
    
  const navigateToPage = (event) => {
    window.location.href = event.currentTarget.dataset.goto;
  };

  useEffect(() => {
    startFetchingAgentFromDB(currentUser.agent);
  }, [
    currentUser.agent,
    startFetchingAgentFromDB,
  ]);

  return (
    <section className='ie-form-scaffold'>  
      {!agent.isAgentLastStep ? (
        <AgentSteps />
      ) : (
        <Container>
          <p className='text-right'>
            Agent Id: {agent && agent._id ? agent._id.slice(-6) : ''}
          </p>
          <header className='pt-5 pb-4'>
            <h3 className='pb-2'>{heading}</h3>
            <p>{subHeading}</p>
          </header> 
          <Row className='justify-content-center'>
            {cards.map((card, i) => (
              <CardGroup key={i} as={Col} xs={12} md={6} lg={3} className='mb-4'>
                <Card
                  onClick={navigateToPage}
                  data-goto={card.goto}
                  className='text-center pt-4 pb-2 border-orange'
                >
                  <Card.Img
                    src={require(`${card.imageUrl}`)}
                    alt={card.title}
                    width={screenW < 525 ? '65' : '65'}
                    height={screenW < 525 ? '64' : '64'}
                    className=
                    {screenW < 525
                      ? 'w-18 mx-auto fluid mt-2'
                      : 'w-25 mx-auto fluid mt-2'
                    }
                  />
                  <Card.Body className='p-4'>
                    <p className='m-0 pb-3 font-size-l font-weight-normal'>{card.title}</p>
                    <p className='text-left p-0 m-0'>{card.text}</p>
                  </Card.Body>
                </Card>
              </CardGroup>
            ))}
          </Row>
        </Container>
      )}
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
});

export default connect(mapStateToProps, {
  startFetchingAgentFromDB,
})(withRouter(AgentDashboard));
