import React, { Component } from 'react';
import { connect } from 'react-redux';

const authGuard = OriginalComponent => {
  class MixedComponent extends Component {
    componentDidMount = () => {
      if (!this.props.isAuthenticated && !this.props.token) {
        this.props.history.push('/');
      }
    };
    
    componentDidUpdate = () => {
      if (!this.props.isAuthenticated && !this.props.token) {
        this.props.history.push('/');
      }
    };

    render() {
      return <>
        <OriginalComponent />
      </>;
    }
  }

  const mapStateToProps = ({ auth: { token, isAuthenticated } }) => ({
    token,
    isAuthenticated
  });

  return connect(mapStateToProps)(MixedComponent);
};

export default authGuard;
