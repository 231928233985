import * as yup from 'yup';

const WorkDetailsSchema = yup.object().shape({
  occupations: yup
    .array()
    .of(
      yup.object().shape({
        occupation: yup.string().trim(),
      })
    )
    .optional(),
  annualIncome: yup
    .number()
    .typeError('Please provide annual income')
    .min(1, 'Please provide a valid annual income')
    .max(100000000000, 'Annual income cannot be more than 100000000000')
    .optional(),
  militaryServiceType: yup.string().trim(),
})

export default WorkDetailsSchema;