import React from 'react'
import Container from 'react-bootstrap/Container';
import { AGENT_BENEFITS_CMS_DATA } from './agent-benefits.cms';
import { Col, Row, Card } from 'react-bootstrap';

const AgentBenefits = () => {
  const { title, introduction, imageUrl, benefits } = AGENT_BENEFITS_CMS_DATA;
  const screenW = window.innerWidth;

  return (
    <section id='agent-benefits-section'>
      <Container className='my-5'>
        <div className='text-center'>
          <Card.Img
            src={require(`${imageUrl}`)}
            alt={title}
            width='70'
            height='79'
            className='w-7 mb-4 text-center'
          />
        </div>
        <h2 className='w-100 mt-0 pt-0 text-d-pink text-center'>{title}</h2>
        <p
          className={
            screenW < 991
              ? 'font-size-l px-1'
              : 'font-size-l px-4'
          }
        >
          {introduction}
        </p>
      </Container>
      {benefits.map((benefit, i) => (
        <div
          key={i}
          className='d-flex justify-content-center agent-bg-pink'
        >
          <Row
            className={
              screenW < 991
                ? 'container d-flex align-items-center py-0'
                : 'container d-flex align-items-center py-4'
              }
          >
            <Col lg={5} className={
              screenW < 991
                ? 'px-1 py-3'
                : 'px-4 py-3'
              }
            >

              <Card.Img
                src={require(`${benefit.imageUrl}`)}
                alt={benefit.title}
                width='430'
                height='280'
                className='w-100 h-100'
              />
            </Col>
            <Col lg={7}
              className={
                screenW < 991
                  ? 'py-2 px-1'
                  : 'py-2 px-4'
              }
            >
              <h3 className='text-pink pb-2 text-center'>{benefit.title}</h3>
              <p>{benefit.description1}</p>
              <p>{benefit.description2}</p>
            </Col>
          </Row>
        </div>
      ))}
    </section>
  )
}

export default AgentBenefits;