import * as yup from 'yup';

const BussDetailsSchema = yup.object().shape({
  businessName: yup
    .string()
    .trim()
    .min(2, 'Business name should have at least 2 characters')
    .max(255, 'Business name must not exceed 255 characters')
    .optional(),
  businessOwnership: yup.string().trim(),
  businessStreet: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(255, 'Please provide no more than 255 characters')
    .optional(),
  businessCity: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(20, 'Please provide no more than 20 characters')
    .optional(),
  businessState: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .optional(),
  businessZipCode: yup
    .number()
    .typeError('Please enter a valid zip code')
    .min(1001, 'Please enter a valid zip code')
    .max(99950, 'Please enter a valid zip code')
    .optional(),
})

export default BussDetailsSchema;