import memberActionTypes from "./member.types";
import authActionTypes from "../auth/auth.types";

let INITIAL_STATE = {
  membersFromDB: [],
  member: {},
  formData: {},
  currentStep: 0,
  primeMember: {},
  occupationsFromDB: [],
  statesFromDB: [],
  showMemberForm: false,
  showMemberTypes: false,
  showMemberInfoEdit: false,
  reset: false,
  isAdult: false,
  isSubmitted: false,
  isUpdated: false,
  isMember: false,
  startPhoneVerify: null,
  isPhoneVerified: null,
  codePhoneErr: null,
  sendPhoneErr: null,
};

let PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.member) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.member);
}

const memberReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberActionTypes.FETCH_ALL_MEMBERS_FROM_DB_SUCCESS:
      return {
        ...state,
        membersFromDB: action.payload,
      };
    case memberActionTypes.FETCH_PRIME_MEMBER_FROM_DB_SUCCESS:
      return {
        ...state,
        primeMember: action.payload,
      };
    case memberActionTypes.FETCH_MEMBER_FROM_DB_SUCCESS:
      return {
        ...state,
        member: action.payload,
      };
    case memberActionTypes.MEMBER_SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case memberActionTypes.DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case memberActionTypes.INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case memberActionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case memberActionTypes.SET_IS_MEMBER:
      return {
        ...state,
        isMember: action.payload,
      };
    case memberActionTypes.TOGGLE_MEMBER_FORM:
      return {
        ...state,
        showMemberForm: action.payload,
      };
    case memberActionTypes.TOGGLE_MEMBER_TYPES:
      return {
        ...state,
        showMemberTypes: action.payload,
      };
    case memberActionTypes.TOGGLE_MEMBER_INFO_EDIT:
      return {
        ...state,
        showMemberInfoEdit: action.payload,
      };
    case memberActionTypes.LOAD_OCCUPATIONS_FROM_DB_SUCCESS:
      return {
        ...state,
        occupationsFromDB: action.payload,
      };
    case memberActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      };
    case memberActionTypes.MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        startPhoneVerify: action.payload,
      };
    case memberActionTypes.MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        sendPhoneErr: action.payload,
      };
    case memberActionTypes.MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isPhoneVerified: action.payload,
      };
    case memberActionTypes.MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        codePhoneErr: action.payload,
      };
    case memberActionTypes.SET_CLEAR_MEMBER_FORM:
      return {
        ...state,
        member: {},
      };
    case memberActionTypes.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    case memberActionTypes.SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: action.payload,
      };
    case memberActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        membersFromDB: [],
        member: {},
        formData: {},
        currentStep: 0,
        primeMember: {},
        occupationsFromDB: [],
        statesFromDB: [],
        showMemberForm: false,
        showMemberTypes: false,
        showMemberInfoEdit: false,
        reset: false,
        isAdult: false,
        isSubmitted: false,
        isUpdated: false,
        isMember: false,
        startPhoneVerify: null,
        isPhoneVerified: null,
        codePhoneErr: null,
        sendPhoneErr: null,
      };
    default:
      return state;
  }
};

export default memberReducer;
