import React from 'react'
import Container from 'react-bootstrap/Container';
import { AGENT_HOW_IT_WORKS_CMS_DATA } from './agent-how-work.cms';
import { Col, Row, Card } from 'react-bootstrap';

const AgentHowItWorks = () => {
  const { title, imageUrl, steps } = AGENT_HOW_IT_WORKS_CMS_DATA;

  return (
    <section className='py-5' id='agent-steps'>
      <Container className='px-0'>
        <div className='text-center'>
          <Card.Img
            src={require(`${imageUrl}`)}
            alt={title}
            width='70'
            height='80'
            className='w-7 mb-4 text-center'
            loading='lazy'
          />
        </div>
        <h2 className='mt-0 pb-4 pt-0 text-green text-center'>{title}</h2>
        <Row>
          {steps.map((step, i) => (
            <Col
              key={i} as={Col} xs={12} md={6} lg={4}
              className='mb-4 text-left' 
            >
              <Card
                className='agent-bg-lg pt-4 pb-0 px-2'
              >
                <Card.Img
                  src={require(`${step.imageUrl}`)}
                  alt={step.title}
                  width='48'
                  height='60'
                  className='w-17 px-3 py-3 ml-3 mb-3 bg-green'
                  loading='lazy'
                />
                <Card.Body className='pl-3'>
                  <p className='pb-0 font-weight-normal'>{step.title}</p>
                  <p className='font-size-m text-muted'>{step.desc}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>  
      </Container>
    </section>
  )
}

export default AgentHowItWorks;