import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RULE_ENGINE } from './rule-engine';
import ProgressBar from '../../components/progress-bar/progress-bar.component';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  selectUser,
} from '../../redux/auth/auth.selectors';
import {
  decrementCurrentStep,
  incrementCurrentStep,
  startLoadingStatesFromDB,
  startLoadingOccupationsFromDB,
  startFetchingPrimeMemberFromDB
} from '../../redux/member/member.actions';
import {
  selectStates,
  selectOccupations,
  selectFormData,
  selectCurrentStep,
  selectPrimeMember,
} from '../../redux/member/member.selectors';
import {
  startFetchingMemberHistoryByIdFromDB
} from '../../redux/quotes/member-history/member-history.actions';
import {
  selectMemberHistoryById
} from '../../redux/quotes/member-history/member-history.selectors';

const MemberSteps = ({
  states,
  occupations,
  primeMember,
  currentStep,
  currentUser,
  decrementCurrentStep,
  incrementCurrentStep,
  startLoadingStatesFromDB,
  startLoadingOccupationsFromDB,
  startFetchingPrimeMemberFromDB,
  startFetchingMemberHistoryByIdFromDB,
}) => {
  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const { heading, subHeading } = RULE_ENGINE[path] ? RULE_ENGINE[path][0][0] : '';

  let FormComponent =
    RULE_ENGINE[path][1]
      ? RULE_ENGINE[path][1][currentStep].component
      : '';
  let steps = RULE_ENGINE[path][1];
  const noOfSteps = RULE_ENGINE[path][1].length

  const decrement = () => {
    decrementCurrentStep(currentStep);
  }

  const increment = () => {
    incrementCurrentStep(currentStep);
  }

  useEffect(() => {
    if (currentUser.member) {
      startFetchingPrimeMemberFromDB(currentUser.member);
    }
  }, [currentUser.member, startFetchingPrimeMemberFromDB]);

  useEffect(() => {
    if (primeMember.memberHistoryId) {
      startFetchingMemberHistoryByIdFromDB(primeMember.memberHistoryId);
    }
  }, [
    primeMember.memberHistoryId,
    startFetchingMemberHistoryByIdFromDB
  ]);

  useEffect(() => {
    if (states.length === 0) {
      startLoadingStatesFromDB();
    }
  }, [states, startLoadingStatesFromDB]);

  useEffect(() => {
    if (occupations.length === 0) {
      startLoadingOccupationsFromDB();
    }
  }, [occupations, startLoadingOccupationsFromDB]);

  return (
    <section className='ie-form-scaffold pt-5'>
      <ProgressBar
        steps={steps}
        currentStep={currentStep}
      />
      <Container className='py-5'>
        <header className='pb-4'>
          <h2>{heading}</h2>
          <p className='font-weight-normal font-size-l'>{subHeading}</p>
        </header>
        <FormComponent
          decrement={decrement}
          increment={increment}
          currentStep={currentStep}
          isLastStep={currentStep === (noOfSteps - 1) ? true : false}
        />
      </Container>
    </section>
  )
}

const mapStateToProps = createStructuredSelector({
  currentStep: selectCurrentStep,
  currentUser: selectUser,
  data: selectFormData,
  states: selectStates,
  occupations: selectOccupations,
  primeMember: selectPrimeMember,
  memberHistoryById: selectMemberHistoryById,
});

export default withRouter(
  connect(mapStateToProps, {
    decrementCurrentStep,
    incrementCurrentStep,
    startLoadingStatesFromDB,
    startLoadingOccupationsFromDB,
    startFetchingPrimeMemberFromDB,
    startFetchingMemberHistoryByIdFromDB,
  })(MemberSteps)
)
