import React, { useState } from 'react';
import uuid from 'react-uuid';

import loadable from '@loadable/component';

const PropertyQuoteDetails = loadable(() => import('./property-quote-details.component'));

const PropertyQuotesDetails = ({ propertyQuotes }) => {
  const [storeFilterQuotes, setStoreFilterQuotes] = useState(false)

  return (
    <div>
      {propertyQuotes &&
        propertyQuotes.length !== 0 ? (
        <>
          {propertyQuotes.map((p, i) => (
            <div key={i}>
              {p && p.isLastStep ? (
                  <PropertyQuoteDetails
                    property={p}
                    elmId={uuid()}
                    storeFilterQuotes = {storeFilterQuotes}
                    setStoreFilterQuotes = {setStoreFilterQuotes}
                  />
              ) : null}
            </div>
          ))}
        </>
      ) : null}  
    </div>
  );
};

export default PropertyQuotesDetails;
