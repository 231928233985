import React, { useEffect, useState } from 'react';
import { Col, Form, Row, ButtonGroup } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useHistory } from 'react-router-dom';
import { MEMBER_CMS_DATA } from '../../../pages/member/member.cms';
import RadioButton from '../../radio-button/radio-button.component';

const MemberFelonyHistory = ({
  values,
  errors,
  touched,
  handleChange,
}) => {
  const {
    form: {
      history: {
        felony: { title, description, label },
      },
    },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  const [showFelonyOption, setShowFelonyOption] = useState(false);

  useEffect(() => {
    if (values.felonyStatus === 'true') {
      setShowFelonyOption(true);
    }

    if (values.felonyStatus === 'false') {
      setShowFelonyOption(false);
      values.felonyReason = '';
    }
  }, [values.felonyStatus, values.felonyReason, setShowFelonyOption]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={6} control-id='felony-status'>
              <Form.Label>{label.status}</Form.Label>
              <br/>
                <ButtonGroup>
                  {label.dropdown.currentStatus.map((option, i) => (
                    <RadioButton
                      key={i}
                      idx={i}
                      name='felonyStatus'
                      option={option}
                    />
                  ))}
                </ButtonGroup>
              <Form.Control.Feedback type='invalid'>
                {errors.felonyStatus}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          {showFelonyOption ? (
            <Form.Row>
              <Form.Group as={Col} controlId='felony-reason' optional='true'>
                <Form.Label>{label.reason}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='felonyReason'
                  as='textarea'
                  rows={5}
                  value={values.felonyReason}
                  onChange={handleChange}
                  isValid={touched.felonyReason && !errors.felonyReason}
                  isInvalid={!!errors.felonyReason}
                  maxLength={255}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.felonyReason}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default MemberFelonyHistory;