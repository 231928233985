import vehicleBiddingActionTypes from './vehicle-bidding.types';
import authActionTypes from '../../auth/auth.types';

let INITIAL_STATE = {
  showVehicleQuotes: true,
  isVehicleSelectedByAi: false,
  isSubmit: false,
  isVehicleBid: false,
  reset: false,
};

// let PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));

// if (PERSISTED_STATE) {
//   INITIAL_STATE = JSON.parse(PERSISTED_STATE.agent);
// }

const vehicleBiddingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case vehicleBiddingActionTypes.SET_IS_SUBMIT:
      return {
        ...state,
        isSubmit: action.payload,
      };
    case vehicleBiddingActionTypes.SET_IS_VEHICLE_BID:
      return {
        ...state,
        isVehicleBid: action.payload,
      };
    case vehicleBiddingActionTypes.SET_IS_VEHICLE_SELECTED_BY_AI:
      return {
        ...state,
        isVehicleSelectedByAi: action.payload,
      };
    case vehicleBiddingActionTypes.SET_SHOW_VEHICLE_QUOTES:
      return {
        ...state,
        showVehicleQuotes: action.payload,
      };
    case vehicleBiddingActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        showAllQuotes: false,
        showVehicleQuotes: true,
        isVehicleSelectedByAi: false,
        isSubmit: false,
        isVehicleBid: false,
        reset: false,
      };
    default:
      return state;
  }
};

export default vehicleBiddingReducer;
