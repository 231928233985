import navigationActionTypes from './navigation.types';

export const setCurrentUserType = (userType) => ({
  type: navigationActionTypes.SET_CURRENT_USER_TYPE,
  payload: userType,
});

export const showAccountActivation = (toggleView) => ({
  type: navigationActionTypes.SHOW_ACCOUNT_ACTIVATION_VIEW,
  payload: toggleView,
});

export const toggleLoginRegistrationView = (showView) => ({
  type: navigationActionTypes.TOGGLE_LOGIN_REGISTRATION_VIEW,
  payload: showView,
});

export const showMemberShowcase = (toggleView) => ({
  type: navigationActionTypes.SHOW_MEMBER_SHOWCASE_VIEW,
  payload: toggleView,
});

export const showAgentShowcase = (toggleView) => ({
  type: navigationActionTypes.SHOW_AGENT_SHOWCASE_VIEW,
  payload: toggleView,
});

export const showMemberDashboard = (toggleView) => ({
  type: navigationActionTypes.SHOW_MEMBER_DASHBOARD_VIEW,
  payload: toggleView,
});

export const showAgentDashboard = (toggleView) => ({
  type: navigationActionTypes.SHOW_AGENT_DASHBOARD_VIEW,
  payload: toggleView,
});
