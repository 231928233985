import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  setClearMemberForm,
  toggleMemberForm,
  toggleMemberTypes,
} from '../../redux/member/member.actions';
import {
  selectMember,
} from '../../redux/member/member.selectors';
import HouseholdMember
  from '../../pages/household-members/household-member.component';

const MemberFormModal = ({
  showMemberInfo,
  toggleMemberTypes,
  toggleMemberForm,
  setShowMemberInfo,
}) => {
  
  const handleClose = () => {
    toggleMemberTypes(false);
    toggleMemberForm(false);
    setShowMemberInfo(false);
    setClearMemberForm(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Modal
      size='xl'
      show={showMemberInfo}
      dialogClassName='modal-100w'
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded border-bottom-0 justify-content-end'>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body className='border-top border-grey'>
        <HouseholdMember setShowMemberInfo={setShowMemberInfo}/>
      </Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='btn btn-outline-danger'
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  member: selectMember,
});

export default connect(mapStateToProps, {
  setClearMemberForm,
  toggleMemberForm,
  toggleMemberTypes,
})(MemberFormModal);
