import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  propertyBiddingSaveSuccess,
  propertyBiddingSaveFailure,
  propertyBiddingUpdateSuccess,
  propertyBiddingUpdateFailure,
} from "./property-bidding.actions";
import propertyBiddingActionTypes from "./property-bidding.types";
import { convertPropertyBiddingForDBSave } from "./property-bidding.utils";

function* savePropertyBidding({ payload: formData }) {
  try {
    const data = yield call(convertPropertyBiddingForDBSave, formData);
    yield axios.post("/api/property-bidding", data, configureAxios());
    yield put(propertyBiddingSaveSuccess());
  } catch (e) {
    yield put(propertyBiddingSaveFailure(e));
  }
}

function* updatePropertyBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertPropertyBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/property-bidding/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(propertyBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(propertyBiddingUpdateFailure(e));
  }
}

function* onSavepropertyBiddingStart() {
  yield takeEvery(
    propertyBiddingActionTypes.START_PROPERTY_BIDDING_SAVE,
    savePropertyBidding
  );
}

function* onUpdatepropertyBiddingStart() {
  yield takeEvery(
    propertyBiddingActionTypes.START_PROPERTY_BIDDING_UPDATE,
    updatePropertyBidding
  );
}

export function* propertyBiddingSagas() {
  yield all([
    call(onSavepropertyBiddingStart),
    call(onUpdatepropertyBiddingStart),
  ]);
}
