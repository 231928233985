import { createSelector } from 'reselect';

const selectCompanionAnimal = (state) => state.companionAnimal;

export const selectAnimals = createSelector(
  [selectCompanionAnimal],
  (companionAnimal) => companionAnimal.animalsFromDB
);

export const selectAnimal = createSelector(
  [selectCompanionAnimal],
  (companionAnimal) => companionAnimal.animalFromDB
);

export const selectIsSubmitted = createSelector(
  [selectCompanionAnimal],
  (companionAnimal) => companionAnimal.isSubmitted
);

export const selectShowAnimalForm = createSelector(
  [selectCompanionAnimal],
  (companionAnimal) => companionAnimal.showAnimalForm
);

export const selectShowAnimalInfoEdit = createSelector(
  [selectCompanionAnimal],
  (companionAnimal) => companionAnimal.showAnimalInfoEdit
);

export const selectClearAnimalForm = createSelector(
  [selectCompanionAnimal],
  (companionAnimal) => companionAnimal.clearAnimalForm
);

export const selectReset = createSelector(
  [selectCompanionAnimal],
  (companionAnimal) => companionAnimal.reset
);