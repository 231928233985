import React from 'react';
import {
  Container, Card, CardGroup, Col, Row} from 'react-bootstrap';import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MEMBER_PROFILE_DASHBOARD_CMS_DATA } from './profile.dashboard.cms';

const MemberProfileDashboard = () => {  
  const navigateToPage = (event) => {
    window.location.href = event.currentTarget.dataset.goto;
  };

  const screenW = window.innerWidth;

  const initialStage = MEMBER_PROFILE_DASHBOARD_CMS_DATA.initial;
  const cards = initialStage.cards.map((card) => {
    card.onClick = navigateToPage;
    return card;
  });

  return (
    <section className='ie-form-scaffold'>
      <Container>
        <header className='py-4'>
          <h2>{initialStage.heading}</h2>
          <p>{initialStage.subHeading}</p>
        </header>
        <Row>
          {cards.map((card, i) => (
            <CardGroup key={i} as={Col} xs={6} md={3} lg={2} className='mb-4'>
              <Card
                data-goto={card.goto}
                onClick={navigateToPage}
                className='text-center pt-4 pb-3 px-2'
              >
                <Card.Img
                  variant='top'
                  src={
                    card.title2 === 'Information'
                      ? require(`../get-quotes/images/data.webp`)
                      :  card.title2 === 'Family'
                        ? require(`../get-quotes/images/family.webp`)
                        :  card.title2 === 'Health'
                          ? require(`../get-quotes/images/health.webp`)
                          :  card.title2 === 'Properties'
                            ? require(`../get-quotes/images/home.webp`)
                            :  card.title2 === 'Vehicles'
                              ? require(`../get-quotes/images/cars.webp`)
                              :  card.title2 === 'Animals'
                                ? require(`../get-quotes/images/pet.webp`)
                                : ''
                  }
                  alt={card.title}
                  width='48'
                  height='43'
                  className={
                    screenW < 525
                      ? 'w-25 m-auto fluid'
                      : 'w-30 m-auto fluid'
                  }
                />
                <Card.Body className='py-2'>
                  <p className='font-weight-normal m-auto'>{card.title}</p>
                  <p className='font-weight-normal m-auto'>{card.title2}</p>
                </Card.Body>
              </Card>
            </CardGroup>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(connect()(MemberProfileDashboard));
