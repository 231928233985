import React, { useState } from 'react';
import { Form as FormikForm, withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Button, Col, Form, Row, } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import { selectAgent, selectStates } from '../../redux/agent/agent.selectors';
import PhoneFormat from '../phone-format/phone-format.component';
import agentTwilioSchema from './validations/agent.twilio.validation.schema';
import {
  setFormData,
  startAgentUpdate,
  startFetchingAgentFromDB,
  startGetCodeToVerifyPhoneNumber,
  startCheckCodeToVerifyPhoneNumber,
} from "../../redux/agent/agent.actions";
import {
  selectFormData,
  selectSendPhoneErr,
  selectCodePhoneErr,
  selectStartPhoneVerify,
  selectIsPhoneVerified,
} from '../../redux/agent/agent.selectors';
import {
  getTimeLeft
} from '../utils/get-bids.utils'
import { selectUser } from '../../redux/auth/auth.selectors';
import ErrorMessages from '../../components/error-message/error-messages.component';
import { useEffect } from 'react';

const AgentPhoneVerify = ({
  values,
  touched,
  errors,
  agent,
  increment,
  decrement,
  currentUser,
  handleChange,
  isSubmitting,
  handleSubmit,
  codePhoneErr,
  sendPhoneErr,
  isPhoneVerified,
  startPhoneVerify,
  startFetchingAgentFromDB,
}) => {
  const {
    form: {
      button,
      contactInfo: { titleTwl, descriptionTwl, label },
    },
  } = AGENT_CMS_DATA;

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showCheckVerifyCode, setShowCheckVerifyCode] = useState(false);
  const [isCodeExpired, setIsCodeExpired] = useState(false);
  const [isPhoneDBValid, setIsPhoneDBValid] = useState(false);
  const [isCheckValid, setIsCheckValid] = useState(false);
  const [isSendErr, setIsSendErr] = useState(false);
  const [isCheckErr, setIsCheckErr] = useState(false);
  
  useEffect(() => {
    if (isPhoneDBValid === false) {
      if (startPhoneVerify && startPhoneVerify.accountSid) {
        if (isCodeExpired === false) {
          if (
            !isPhoneVerified &&
            isSendErr === false &&
            isCheckErr === false &&
            isCheckValid === false &&
            isCodeExpired === false 
          ) {
            setShowPhoneNumber(false);
            setShowCheckVerifyCode(true);
          } 
        } else {
          setShowPhoneNumber(true);
          setShowCheckVerifyCode(false);
        }
      } else if (!startPhoneVerify) {
        setShowPhoneNumber(true);
        setShowCheckVerifyCode(false);
      }
    } else {
      setShowPhoneNumber(false);
      setShowCheckVerifyCode(false);
    }
  }, [
    isSendErr,
    isCheckErr,
    isCheckValid,
    isCodeExpired,
    isPhoneDBValid,
    startPhoneVerify,
    isPhoneVerified,
    setShowPhoneNumber,
    setShowCheckVerifyCode,
  ])
  
  useEffect(() => {
    if (startPhoneVerify && startPhoneVerify.dateCreated) {
      const timeLength = 600000;
      const timeLeft = getTimeLeft(startPhoneVerify.dateCreated, timeLength);
      if (timeLeft < 0) {
        setIsCodeExpired(true);
      } else {
        setIsCodeExpired(false);        
      }
    }
  }, [
    startPhoneVerify,
    setIsCodeExpired,
  ])

  useEffect(() => {
    if (
      agent &&
      agent.details &&
      agent.details.isPhoneValid
    ) {
      setIsPhoneDBValid(true);
    } else {
      setIsPhoneDBValid(false);
    }
  }, [agent, setIsPhoneDBValid])

  useEffect(() => {
    startFetchingAgentFromDB(currentUser.agent);
  }, [
    currentUser,
    startFetchingAgentFromDB,
  ]);

  useEffect(() => {
    if (
      isPhoneVerified &&
      isPhoneVerified.verificationResult &&
      isPhoneVerified.verificationResult.status === 'approved'
    ) {
      setIsCheckValid(true);
    } else {
      setIsCheckValid(false);
    }
  }, [isPhoneVerified, setIsCheckValid])

  useEffect(() => {
    if (sendPhoneErr && sendPhoneErr.e) {
      setIsSendErr(true);
    } else {
      setIsSendErr(false);
    }
  }, [sendPhoneErr, setIsSendErr])

  useEffect(() => {
    if (codePhoneErr && codePhoneErr.e) {
      setIsCheckErr(true);
    } else {
      setIsCheckErr(false);
    }
  }, [codePhoneErr, setIsCheckErr])

  return (
    <>
      <div className='ie-form'>
        <FormikForm noValidate>
          <Row>
            <Col lg={4}>
              <h5>{titleTwl}</h5>
              <h6>{descriptionTwl}</h6>
            </Col>
            <Col lg={8}>
              {showPhoneNumber &&
              isSendErr === false ? (
                <Form.Row>
                  <Form.Group as={Col} lg={5} controlId='phone-number'>
                    <Form.Label>{label.phoneNumber}</Form.Label>
                    <PhoneFormat
                      name='phoneNumber'
                      value={values.phoneNumber}
                      errors={errors}
                      touched={touched}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.phoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                ) : showCheckVerifyCode && !isCheckValid ? (
                  <Form.Row>
                    <Form.Group as={Col} lg={5} controlId='verified-code'>
                      <Form.Label>{label.code}</Form.Label>
                      <Form.Control
                        name='verifiedCode'
                        value={values.verifiedCode}
                        onChange={handleChange}
                        isValid={touched.verifiedCode && !errors.verifiedCode}
                        isInvalid={!!errors.verifiedCode}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.verifiedCode}
                      </Form.Control.Feedback>
                      {isPhoneVerified && isCheckValid === false ? (
                        <p className='text-danger'>{label.invCode}</p>
                      ) : null} 
                    </Form.Group>
                  </Form.Row>
                ) : isCheckValid === true || isPhoneDBValid === true ? (
                  <p className='text-success'>{label.valid}</p>
                ) : isSendErr === true ? (
                  <>
                    <p className='text-danger'>{label.invPhone}</p>  
                    <p className='text-danger'>{label.lgOut}</p>
                  </>
                ) : isCheckErr === true ? (
                  <>
                    <p className='text-danger'>{label.invCodeFv}</p>  
                    <p className='text-danger'>{label.lgOut}</p>
                  </>
                ): null}
            </Col>
          </Row>
        </FormikForm>
      </div>
      {!isSendErr && !isCheckErr ? (
      <>
        <div className='float-left pb-5'>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              decrement();
              window.scrollTo({ top: 0 });
            }}
          >
            {button.previous}
          </Button>
        </div>
        <ErrorMessages errors={errors} />
        {!isPhoneDBValid && !isCheckValid ? (
          <div className='float-right pb-5'>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}
            >
              {showPhoneNumber || isCodeExpired
                ? button.submit
                : showCheckVerifyCode && !isCodeExpired
                  ? button.verify
                  : button.submit
              }
            </Button>
          </div>
        ) : isCheckValid === true || isPhoneDBValid === true ? (
          <div className='float-right'>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                increment()
                setTimeout(() => {
                  window.location.reload();
                }, 200)
                window.scrollTo({ top: 0 });
              }}
            >
              {button.next}
            </Button>
          </div>
        ) : null}
      </>
      ) : null}
    </>
  );
};

const getTime = (createdDate, timeLength) => {
  const timeDiff =
    createdDate
      ? (Date.parse(new Date(createdDate)) + timeLength) - Date.parse(new Date())
      : null
  return timeDiff;
}

const FormikAgentPhoneVerify = withFormik({
  mapPropsToValues: ({ startPhoneVerify }) => ({
    phoneNumber: 
      startPhoneVerify && startPhoneVerify.to
        ? startPhoneVerify.to
        : '',
    verifiedCode: '',
  }),
  validationSchema: agentTwilioSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props:
      {
        agent,
        currentUser,
        startPhoneVerify,
        isPhoneVerified,
        startGetCodeToVerifyPhoneNumber,
        startCheckCodeToVerifyPhoneNumber,
      },
      setSubmitting
    }
  ) => {
    const isPhoneValid =
      agent &&
        agent.details &&
        agent.details.isPhoneValid
        ? true
        : false
    
    const id = currentUser.agent;
    const timeLength = 600000;
    const timeLeft =
      startPhoneVerify
        ? getTime(startPhoneVerify.dateCreated, timeLength)
        : '';

    setSubmitting(true);

    if (isPhoneValid === false && id) {
      if (
        !startPhoneVerify ||
        startPhoneVerify === undefined ||
        timeLeft < 0
      ) {
        startGetCodeToVerifyPhoneNumber(id, values);
      } else if (startPhoneVerify && startPhoneVerify.accountSid) {
        if (timeLeft > 0) {
          if (
            (isPhoneVerified && isPhoneVerified.status !== 'approved') ||
            (isPhoneVerified === null || isPhoneVerified === undefined)
          ){
            startCheckCodeToVerifyPhoneNumber(id, values);
          } 
        }
      }
    }
    setSubmitting(false);
  },
})(AgentPhoneVerify);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
  states: selectStates,
  data: selectFormData,
  startPhoneVerify: selectStartPhoneVerify,
  isPhoneVerified: selectIsPhoneVerified,
  codePhoneErr: selectCodePhoneErr,
  sendPhoneErr: selectSendPhoneErr,
});

export default withRouter(
  connect(mapStateToProps, {
    setFormData,
    startAgentUpdate,
    startFetchingAgentFromDB,
    startGetCodeToVerifyPhoneNumber,
    startCheckCodeToVerifyPhoneNumber,
  })(FormikAgentPhoneVerify)
);
