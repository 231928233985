import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import { DatePicker } from '../date-picker/date-picker.component';
import { useHistory } from 'react-router-dom';
import Dropdown from '../dropdown/dropdown.component';
import RadioButton from '../radio-button/radio-button.component';

const PersonalDetails = ({
  values,
  handleChange,
  touched,
  errors
}) => {
  const {
    form: {
      personalDetails: { title, description, label },
    },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  const pdtDate =
    values.dateOfBirth && values.dateOfBirth !== ''
      ? new Date(values.dateOfBirth)
      : '';

  // Get the UTC time by adding the offset
  const utcTime = pdtDate
    ? new Date(pdtDate.getTime() + pdtDate.getTimezoneOffset() * 60000)
    : ''; // Adding 7 hours in milliseconds (1 hour = 60 minutes = 60 * 1000 milliseconds)

  // Convert the UTC time to a string in ISO 8601 format (GMT)
  const gmtTime = utcTime ? utcTime.toISOString() : '';

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <div>
            <Form.Row>
              <Form.Group as={Col} lg={4} controlId='first-name'>
                <Form.Label>{label.firstName}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='firstName'
                  value={values.firstName}
                  onChange={handleChange}
                  isValid={touched.firstName && !errors.firstName}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={4}
                controlId='middle-name'
                optional='true'
              >
                <Form.Label>{label.middleName}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='middleName'
                  value={values.middleName}
                  onChange={handleChange}
                  isValid={touched.middleName && !errors.middleName}
                  isInvalid={!!errors.middleName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.middleName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId='last-name'>
                <Form.Label>{label.lastName}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='lastName'
                  value={values.lastName}
                  onChange={handleChange}
                  isValid={touched.lastName && !errors.lastName}
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} lg={4} controlId='date-birth'>
                <Form.Label>{label.dateOfBirth}</Form.Label>
                <DatePicker
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='dateOfBirth'
                  value={gmtTime}
                  minDate={new Date('01-01-1800')}
                  maxDate={new Date()}
                  autoComplete='nope'
                  touched={touched}
                  errors={errors}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.dateOfBirth}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} lg={4} controlId='mem-type'>
                <Form.Label>{label.memberType}</Form.Label>
                <Dropdown
                  name='memberType'
                  isDisabled={true}
                  options={label.dropdown.memberTypes}
                  selected={values.memberType}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.memberType}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} lg={7} controlId='gender'>
                <Form.Label>{label.gender}</Form.Label>
                <br/>
                {label.dropdown.genders.map((option, i) => (
                  <RadioButton
                    key={i}
                    idx={i}
                    name='gender'
                    option={option}
                  />
                ))}
                <Form.Control.Feedback type='invalid'>
                  {errors.gender}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PersonalDetails;

