import { MEMBER_LIFE_POLICY_CMS_DATA } 
  from '../../../pages/policies/life/life-policy.cms';

const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));
let memberDetails;

if (PERSISTED_STATE && PERSISTED_STATE.auth) {
  memberDetails = JSON.parse(PERSISTED_STATE.auth);
}

export const convertMemberLifePolicyFromDBSave = (data) => {
  const {
    form: { guaranteedPremium, lifeCoverageType },
  } = MEMBER_LIFE_POLICY_CMS_DATA;

  const hasGuaranteedPremium = guaranteedPremium.dropdown;
  const lifeCoverageTypes = lifeCoverageType.dropdown.typesOfCoverage;
  const subTypes = lifeCoverageType.dropdown.subTypes;
  const premiumRider = lifeCoverageType.dropdown.premiumRider;
  
  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    memberId: memberDetails ? memberDetails.user._id : '',
    storedMember: data.storedMember ? data.storedMember : '',
    additionalMember: data.additionalMember ? data.additionalMember : [],
    documents: data.documents ? data.documents : [],
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    deathBenefit: data.faceValue ? data.faceValue : '',
    accidentalDB: data.accidentalDB ? data.accidentalDB : '',
    isGuaranteedPremium: {
      isTypeOf: 
        data.guaranteedPremium.length !== 0
          ? data.guaranteedPremium === hasGuaranteedPremium[0].value
          : false,
      yearlyIncrements: 
        data.guaranteedPremium === hasGuaranteedPremium[1].value
          ? data.increments
          : '',
    },
    lifeCoverageType: {
      termLife: {
        isTypeOf:
          data.typeOfCoverage !== null
            ? data.typeOfCoverage === lifeCoverageTypes[0].value
            : false,
        durationOfIncome: {
          start:
            data.typeOfCoverage === lifeCoverageTypes[0].value
              ? data.startDate
              : '',
          end:
            data.typeOfCoverage === lifeCoverageTypes[0].value
              ? data.endDate
              : '',
        },
        hasPremiumRider: 
          data.premiumRider !== null
            ? data.premiumRider === premiumRider[0].value
            : false,
      },
      wholeLife: {
        isTypeOf:
          data.typeOfCoverage !== null
            ? data.typeOfCoverage === lifeCoverageTypes[1].value
            : false,
        maturityDate:
          data.typeOfCoverage === lifeCoverageTypes[1].value
            ? data.maturityDateW
            : '',
        estMaturityPayOut:
          data.typeOfCoverage === lifeCoverageTypes[1].value
            ? data.maturityPayOutW
            : '',
      },
      universalLife: {
        isTypeOf:
          data.typeOfCoverage !== null
            ? data.typeOfCoverage === lifeCoverageTypes[2].value
            : false,
        maturityDate:
          data.typeOfCoverage === lifeCoverageTypes[2].value
            ? data.maturityDateU
            : '',
        estMaturityPayOut:
          data.typeOfCoverage === lifeCoverageTypes[2].value
            ? data.maturityPayOutU
            : '',
        scheduledRetirement:
          data.typeOfCoverage === lifeCoverageTypes[2].value
            ? data.retirementDate
            : '',
        receiveAtRetirement:
          data.typeOfCoverage === lifeCoverageTypes[2].value
            ? data.receiveAtRetire
            : '',
        subType: {
          fixed: {
            isTypeOf:
              data.subType !== null
                ? data.subType === subTypes[0].value
                : false,
            setInterest:
              data.subType === subTypes[0].value
                ? data.setInterest
                : '',
          },
          indexed: {
            isTypeOf:
              data.subType !== null
                ? data.subType === subTypes[1].value
                : false,
            guaranteedInterest:
              data.subType === subTypes[1].value
                ? data.guaranteedInterest
                : '',
          },
          variable: {
            isTypeOf:
              data.subType !== null
                ? data.subType === subTypes[2].value
                : false,
            predictedInterest:
              data.subType === subTypes[2].value
                ? data.predictedInterest
                : '',
          }
        },      
      },
    },
    additionalInfo: data.additionalInfo ? data.additionalInfo : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  }
  return payload;
}


