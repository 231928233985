import { Field } from 'formik';
import React, { createRef, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import {
  selectStates,
} from '../../redux/member/member.selectors';
import RadioButton from '../radio-button/radio-button.component';

const BusinessDetails = ({
  values,
  handleChange,
  touched,
  errors,
  states,
  reset,
}) => {
  const {
    form: {
      businessDetails: { title, description, label },
    },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  const stateRef = createRef();

  useEffect(() => {
    reset && stateRef.current.clear();
  }, [reset, stateRef]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <div>
            <Form.Row>
              <Form.Group
                as={Col}
                lg={6}
                control-id='business-name'
                optional='true'
              >
                <Form.Label>{label.businessName}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='businessName'
                  defaultValue={values.businessName}
                  onChange={handleChange}
                  isValid={touched.businessName && !errors.businessName}
                  isInvalid={!!errors.businessName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.businessName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={6}
                controlId='business-ownership'
                optional='true'
              >
                <Form.Label>{label.businessOwnership}</Form.Label>
                <br/>
                {label.dropdown.businessOwnershipChoices.map((option, i) => (
                  <RadioButton
                    key={i}
                    idx={i}
                    name='businessOwnership'
                    option={option}
                  />
                ))}
                <Form.Control.Feedback type='invalid'>
                  {errors.businessOwnership}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                lg={6}
                control-id='business-street'
                optional='true'
              >
                <Form.Label>{label.businessStreet}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='businessStreet'
                  value={values.businessStreet}
                  onChange={handleChange}
                  isValid={touched.businessStreet && !errors.businessStreet}
                  isInvalid={!!errors.businessStreet}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.businessStreet}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={6}
                control-id='business-city'
                optional='true'
              >
                <Form.Label>{label.businessCity}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='businessCity'
                  value={values.businessCity}
                  onChange={handleChange}
                  isValid={touched.businessCity && !errors.businessCity}
                  isInvalid={!!errors.businessCity}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.businessCity}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                lg={6}
                control-id='business-state'
                optional='true'
              >
                <Form.Label>{label.businessState}</Form.Label>
                <Field name='businessState'>
                  {({ form, field }) => (
                    <Typeahead
                      disabled={
                        path === agentBid ? true : false
                      }
                      id='business-state-select-business'
                      ref={stateRef}
                      name='businessState'
                      options={states}
                      isValid={touched.businessState && !errors.businessState}
                      isInvalid={!!errors.businessState}
                      selected={[values.businessState]}
                      onChange={(selectedState) => {
                        if (selectedState.length) {
                          form.setFieldValue(
                            field.name,
                            selectedState[0].value
                          );
                        }
                      }}
                      onInputChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </Field>
                <Form.Control.Feedback type='invalid'>
                  {errors.businessState}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={6}
                control-id='business-zip-code'
                optional='true'
              >
                <Form.Label>{label.businessZipCode}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='businessZipCode'
                  value={values.businessZipCode}
                  onChange={handleChange}
                  isValid={touched.businessZipCode && !errors.businessZipCode}
                  isInvalid={!!errors.businessZipCode}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.businessZipCode}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  states: selectStates,
});

export default connect(mapStateToProps, {})(BusinessDetails);
