import { ModalState, ModalTypes } from '../../modal/modal.types';
import lifeQuoteActionTypes from './life.types';

const payload = {
  context: 'lifeQuote',
  status: '',
  error: '',
};

export const decrementCurrentStep = (currentStep) => ({
  type: lifeQuoteActionTypes.LIFE_DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: lifeQuoteActionTypes.LIFE_INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const setLifeFormData = (data) => ({
  type: lifeQuoteActionTypes.SET_LIFE_FORM_DATA,
  payload: data,
});

export const setQuoteId = (id) => ({
  type: lifeQuoteActionTypes.SET_LIFE_QUOTE_ID,
  payload: id,
});

export const setHealthInfosLife = (data) => ({
  type: lifeQuoteActionTypes.SET_HEALTH_INFOS_LIFE,
  payload: data,
});

export const setMembersInfo = (members) => ({
  type: lifeQuoteActionTypes.SET_MEMBERS_INFO,
  payload: members,
});

export const startCheckingChatGroup = (id) => ({
  type: lifeQuoteActionTypes.START_CHECKING_CHAT_GROUP_LIFE,
  payload: id,
})

export const checkingChatGroupSuccess = () => ({
  type: lifeQuoteActionTypes.CHECKING_CHAT_GROUP_SUCCESS_LIFE,
})

export const saveDeleteCometChat = (id) => ({
  type: lifeQuoteActionTypes.SAVE_DELETE_LIFE_COMET_CHAT,
  payload: id,
})

export const saveDeleteCometChatSuccess = () => ({
  type: lifeQuoteActionTypes.SAVE_DELETE_LIFE_COMET_CHAT_SUCCESS,
})

export const startFetchingLifeQuotesFromDB = () => ({
  type: lifeQuoteActionTypes.FETCH_LIFE_QUOTES_FROM_DB_START,
});

export const fetchLifeQuotesFromDBSuccess = (quote) => ({
  type: lifeQuoteActionTypes.FETCH_LIFE_QUOTES_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchLifeQuotesFromDBFailure = (e) => ({
  type: lifeQuoteActionTypes.FETCH_LIFE_QUOTES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingLifeQuoteFromDB = (id) => ({
  type: lifeQuoteActionTypes.FETCH_LIFE_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchLifeQuoteFromDBSuccess = (quote) => ({
  type: lifeQuoteActionTypes.FETCH_LIFE_QUOTE_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchLifeQuoteFromDBFailure = (e) => ({
  type: lifeQuoteActionTypes.FETCH_LIFE_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startLifeQuoteSave = (quotes) => ({
  type: lifeQuoteActionTypes.START_LIFE_QUOTE_SAVE,
  payload: quotes,
});

export const lifeQuoteSaveSuccess = () => ({
  type: lifeQuoteActionTypes.LIFE_QUOTE_SAVE_SUCCESS,
});

export const lifeQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLifeQuoteUpdate = (id, formData) => ({
  type: lifeQuoteActionTypes.START_LIFE_QUOTE_UPDATE,
  payload: { id, formData },
});

export const lifeQuoteUpdateSuccess = () => ({
  type: lifeQuoteActionTypes.LIFE_QUOTE_UPDATE_SUCCESS,
});

export const lifeQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLastStepLifeQuoteUpdate = (id, formData) => ({
  type: lifeQuoteActionTypes.START_LAST_STEP_LIFE_QUOTE_UPDATE,
  payload: { id, formData },
});

export const lastStepLifeQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.QUOTE_REQUEST_SUCCESS,
  },
});

export const lastStepLifeQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});


