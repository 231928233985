import React, { useState, useEffect } from 'react';
import { Button, Table, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startFetchingVehicleFromDB,
  toggleVehicleForm,
  setClearVehicleForm,
  startVehicleDelete,
} from '../../../redux/quotes/vehicle/vehicle.actions';
import {
  selectShowVehicleForm,
} from '../../../redux/quotes/vehicle/vehicle.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import { VEHICLE_CMS_DATA } from './vehicle.cms';
import { useHistory } from 'react-router-dom';
import ShowModal from '../../../components/modal/show-modal.component';
import VehicleFormModal from '../../../components/quotes/vehicle/vehicle-form-modal.component';
import VehicleQuote from './vehicle.component';

const EditVehicles = ({
  primeMember,
  currentUser,
  toggleVehicleForm,
  startVehicleDelete,
  setClearVehicleForm,
  showVehicleForm,
  startFetchingVehicleFromDB,
  startFetchingPrimeMemberFromDB,
}) => {
  const {
    heading,
    subHeading,
    modal,
  } = VEHICLE_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname;
  const vehicleP = '/household-properties-information';

  const [show, setShow] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [storeVehicleInfo, setStoreVehicleInfo] = useState([]);
  const [showVehicleInfo, setShowVehicleInfo] = useState(false);
  const [showVehicleFormOnly, setShowVehicleFormOnly] = useState(false);

  const handleDelete = () => {
    startVehicleDelete(storeIds[0], storeIds[1]);
    setClearVehicleForm(true);
    setShow(false);
    window.location.reload();
  }

  const handleCancel = () => {
    if (!showVehicleForm) {
      setStoreIds([]);
      setStoreVehicleInfo([]);
    } 
    setShow(false);
    if (path === vehicleP) {
      window.scrollTo({ top: 0 });
    }
  }

  const householdVehicles =
    primeMember && primeMember.householdVehicles
      ? primeMember.householdVehicles 
      : []

  useEffect(() => {
    if (!primeMember._id) {
      startFetchingPrimeMemberFromDB(currentUser.member);
    }
  }, [
    primeMember,
    currentUser.member,
    startFetchingPrimeMemberFromDB
  ]);

  useEffect(() => {
    if (primeMember) {
      let hps = primeMember.householdVehicles;
      if (hps && hps.length === 0) {
        toggleVehicleForm(true);
        setShowVehicleFormOnly(true);
      }
      if (hps && hps.length > 0) {
        setShowVehicleFormOnly(false);
        toggleVehicleForm(false);
      }
    }
  }, [
    primeMember,
    toggleVehicleForm,
    setShowVehicleFormOnly,
  ])

  return (
    <section className='ie-form-scaffold'>
      <Container>
        {!showVehicleFormOnly ? (
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
        ) : null}
        {showVehicleFormOnly ? (
          <VehicleQuote />
        ) : (
          <div className='pb-5 mb-4 text-right'>
            {householdVehicles && householdVehicles.length !== 0 ? (
              <>
                <Table
                  bordered
                  responsive
                  className='shadow-sm p-3 mb-4 bg-white rounded'
                >
                  <thead className='bg-light'>
                    <tr>
                      <th style={{ width: '9rem' }} className='text-left'>Vin</th>
                      <th style={{ width: '6rem' }}>Make</th>
                      <th style={{ width: '6rem' }}>Model</th>
                      <th style={{ width: '6rem' }}>Year</th>
                      <th style={{ width: '12rem' }}></th>
                    </tr>
                  </thead>
                  {householdVehicles.map((hv, i) => (
                    <tbody key={hv._id}>
                      <tr key={i}>
                        <td className='text-left'>
                          <p style={{ width: '9rem' }} className='m-0'>
                            {hv.specs.vin}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {hv.specs.make}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {hv.specs.model}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {hv.specs.year}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '12rem' }} className='m-0 float-right'>
                            <Button
                              className='mr-1'
                              variant='outline-danger'
                              onClick={() => {
                                setStoreIds([hv._id, currentUser.member]);
                                setStoreVehicleInfo([hv.specs.vin, hv.specs.make, hv.specs.model, hv.specs.year])
                                setShow(true);
                              }}
                            >
                              {modal.action.delete}
                            </Button>
                            <Button
                              className='ml-1'
                              variant='primary'
                              onClick={() => {
                                if (hv._id) {
                                  startFetchingVehicleFromDB(hv._id);
                                  setStoreIds([hv._id, currentUser.member]);
                                }
                                toggleVehicleForm(true);
                                setShowVehicleInfo(!showVehicleInfo);
                                setStoreVehicleInfo([hv.specs.vin, hv.specs.make, hv.specs.model, hv.specs.year])
                              }}
                            >
                              {modal.action.update}                      
                            </Button>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
                <div className='text-left'>
                  <Button
                    variant='primary'
                    onClick={() => {
                      setClearVehicleForm(true);
                      setShowVehicleInfo(!showVehicleInfo);
                      toggleVehicleForm(true);
                      setStoreIds([]);
                      setStoreVehicleInfo([]);
                    }}
                  >
                    {modal.action.add}
                  </Button>
                </div>
              </>
            ) : null}
            <VehicleFormModal
              showVehicleInfo={showVehicleInfo}
              setShowVehicleInfo={setShowVehicleInfo}
            />
            <ShowModal
              show={show}
              heading={`${heading} ${storeVehicleInfo[0]}, ${storeVehicleInfo[1]}, ${storeVehicleInfo[2]}, ${storeVehicleInfo[3]}?`}
              secondary={modal.action.cancel}
              primary={modal.action.delete}
              handleClose={handleCancel}
              handleSecondary={handleCancel}
              handlePrimary={handleDelete}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  primeMember: selectPrimeMember,
  showVehicleForm: selectShowVehicleForm,
});

export default connect(mapStateToProps, {
  startVehicleDelete,
  toggleVehicleForm,
  setClearVehicleForm,
  startFetchingVehicleFromDB,
  startFetchingPrimeMemberFromDB,
})(EditVehicles);