export const ModalTypes = {
  OPEN_MODAL: 'OPEN_MODAL',
  DISMISS_MODAL: 'DISMISS_MODAL',
};

export const ModalState = {
  SUCCESS: 'success',
  POLICY_SUCCESS: 'policySuccess',
  AGENT_SUCCESS: 'agentSuccess',
  AGENT_BID_SUCCESS: 'agentBidSuccess',
  MEMBER_SELECT_SUCCESS: 'memberSelectSuccess',
  AGENT_QUOTE_SUCCESS: 'agentQuoteSuccess',
  AGENT_UPDATE_QUOTE_SUCCESS: 'agentUpdateQuoteSuccess',
  QUOTE_REQUEST_SUCCESS: 'quoteRequestSuccess',
  RESET_PASSWORD_SUCCESS: 'resetPasswordSuccess',
  ERROR: 'error',
};
