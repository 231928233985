const agentLifeQuoteActionTypes = {
  FETCH_AGENT_LIFE_QUOTE_FROM_DB_START: 'FETCH_AGENT_LIFE_QUOTE_FROM_DB_START',
  FETCH_AGENT_LIFE_QUOTE_FROM_DB_SUCCESS: 'FETCH_AGENT_LIFE_QUOTE_FROM_DB_SUCCESS',
  FETCH_AGENT_LIFE_QUOTE_FROM_DB_FAILURE: 'FETCH_AGENT_LIFE_QUOTE_FROM_DB_FAILURE',
  LOAD_STATES_FROM_DB_START: 'LOAD_STATES_FORM_DB_START',
  LOAD_STATES_FROM_DB_SUCCESS: 'LOAD_STATES_FROM_DB_SUCCESS',
  LOAD_STATES_FROM_DB_FAILURE: 'LOAD_STATES_FROM_DB_FAILURE',
  START_AGENT_LIFE_QUOTE_SAVE: 'START_AGENT_LIFE_QUOTE_SAVE',
  START_AGENT_LIFE_QUOTE_UPDATE: 'START_AGENT_LIFE_QUOTE_UPDATE',
  START_AGENT_LIFE_QUOTE_DELETE: 'START_AGENT_LIFE_QUOTE_DELETE',
  AGENT_LIFE_QUOTE_DELETE_SUCCESS: 'AGENT_LIFE_QUOTE_DELETE_SUCCESS',
  SET_CLEAR_FORM: 'SET_CLEAR_FORM',
  SET_IS_AGENT: 'SET_IS_AGENT',
  RESET_FORM: 'RESET_FORM',
}

export default agentLifeQuoteActionTypes;