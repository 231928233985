import { AGENT_PROPERTY_QUOTE_CMS_DATA }
  from '../../../pages/agent-quotes/property/property-quote.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import agentPropertyQuoteActionTypes from './property.types';

const payload = {
  context: 'agentPropertyQuote',
  status: '',
  error: '',
}

export const setClearPropertyForm = (clear) => ({
  type: agentPropertyQuoteActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const setIsAgent = (agent) => ({
  type: agentPropertyQuoteActionTypes.SET_IS_AGENT,
  payload: agent
});

export const startFetchingAgentPropertyQuoteFromDB = (id) => ({
  type: agentPropertyQuoteActionTypes.FETCH_AGENT_PROPERTY_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAgentPropertyQuoteFromDBSuccess = (policy) => ({
  type: agentPropertyQuoteActionTypes.FETCH_AGENT_PROPERTY_QUOTE_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchAgentPropertyQuoteFromDBFailure = (e) => ({
  type: agentPropertyQuoteActionTypes.FETCH_AGENT_PROPERTY_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: agentPropertyQuoteActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: agentPropertyQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: agentPropertyQuoteActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: AGENT_PROPERTY_QUOTE_CMS_DATA.errMsg.states,
});

export const startAgentPropertyQuoteSave = (data) => ({
  type: agentPropertyQuoteActionTypes.START_AGENT_PROPERTY_QUOTE_SAVE,
  payload: data
});

export const agentPropertyQuoteSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_QUOTE_SUCCESS,
  },
});

export const agentPropertyQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentPropertyQuoteUpdate = (id, formData) => ({
  type: agentPropertyQuoteActionTypes.START_AGENT_PROPERTY_QUOTE_UPDATE,
  payload: { id, formData }
});

export const agentPropertyQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_UPDATE_QUOTE_SUCCESS,
  },
});

export const agentPropertyQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentPropertyQuoteDelete = (id, pId) => ({
  type: agentPropertyQuoteActionTypes.START_AGENT_PROPERTY_QUOTE_DELETE,
  payload: { id, pId },
});

export const agentPropertyQuoteDeleteSuccess = () => ({
  type: agentPropertyQuoteActionTypes.AGENT_PROPERTY_QUOTE_DELETE_SUCCESS,
});

export const agentPropertyQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});