export const AGENT_DASHBOARD_CMS_DATA = {
  initial: {
    heading: 'My Agent Dashobard',
    subHeading: 'In which way can we make it Easier to get and take care of prospects and clients?',
    cards: [
      {
        imageUrl: './images/online.webp',
        title: 'Bidding',
        text:
          'Bid on Members to create quotes and policies for.',
        goto: '/agent-bidding',
      },
      {
        imageUrl: './images/law.webp',
        title: 'My Bids',
        text:
          'View Members that you have bid on.',
        goto: '/agent-bids',
      },
      {
        imageUrl: './images/customer.webp',
        title: 'My Client(s)',
        text:
          'View policy information for Members',
        goto: '/agent-clients',
      },
    ],
  },
};
