const combineBiddingActionTypes = {
  SET_SHOW_COMBINE_QUOTES: 'SET_SHOW_COMBINE_QUOTES',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_COMBINE_BID: 'SET_IS_COMBINE_BID',
  SET_IS_COMBINE_SELECTED_BY_AI: 'SET_IS_COMBINE_SELECTED_BY_AI',
  START_COMBINE_BIDDING_SAVE: 'START_COMBINE_BIDDING_SAVE',
  COMBINE_BIDDING_SAVE_SUCCESS: 'COMBINE_BIDDING_SAVE_SUCCESS',
  START_COMBINE_BIDDING_UPDATE: 'START_COMBINE_BIDDING_UPDATE',
  COMBINE_BIDDING_UPDATE_SUCCESS: 'COMBINE_BIDDING_UPDATE_SUCCESS',
  RESET_FORM: 'RESET_FORM',
};

export default combineBiddingActionTypes;
