import agentCompanionAnimalQuoteActionTypes from './companion-animal.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  statesFromDB: [],
  agentAnimalQuoteFromDB: {},
  isAgent: false,
  reset: false,
}

const agentAnimalQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case agentCompanionAnimalQuoteActionTypes.FETCH_AGENT_COMPANION_ANIMAL_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        agentAnimalQuoteFromDB: action.payload,
      }
    case agentCompanionAnimalQuoteActionTypes.SET_IS_AGENT:
      return {
        ...state,
        isAgent: true,
      }
    case agentCompanionAnimalQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      }
    case agentCompanionAnimalQuoteActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        agentAnimalQuoteFromDB: {},
      }
    case agentCompanionAnimalQuoteActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      }
    case authActionTypes.LOGOUT:
      return {
        ...state,
        statesFromDB: [],
        agentAnimalQuoteFromDB: {},
        isAgent: false,
        reset: false,
      }
    default:
      return state
  }
}

export default agentAnimalQuoteReducer;