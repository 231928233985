import { Field, FieldArray } from 'formik';
import React, { createRef, useState, useEffect } from 'react';
import { Button, Col, Form, Row, InputGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import {
  setCarriersObject,
  startLoadingCarriersFromDB,
  startLoadingOccupationsFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectCarriers,
  selectCarriersObj,
  selectOccupations,
} from '../../redux/agent/agent.selectors';
import Dropdown from '../dropdown/dropdown.component';
import ErrorMessage from '../error-message/error-message.component';

const Carriers = ({ 
  values, 
  touched, 
  errors, 
  carriersFromDB, 
  reset,
  carriersObj,
  occupations,
  setCarriersObject,
  startLoadingCarriersFromDB,
  startLoadingOccupationsFromDB,
}) => {
  const {
    form: {
      carriers: { title, description, label },
    },
  } = AGENT_CMS_DATA;

  const carrierRef = createRef();

  const [show, setShow] = useState(false);

  let isCaptive = values.isCaptive;
  let carriers = values.carriers;
  let carriersObject = {};

  if (carriersFromDB && carriersFromDB.length > 0) {
    for (let cd of carriersFromDB) {
      carriersObject[cd.value] = true;
    }
  }

  if (isCaptive === 'yes' && carriers.length > 3) {
    for (let i = carriers.length; i > 3; i--) {
      carriers.pop();
    }
  }

  useEffect(() => {
    reset && carrierRef.current.clear();
  }, [reset, carrierRef]);

  useEffect(() => {
    if (carriersFromDB.length === 0) {
      startLoadingCarriersFromDB();
    }
  }, [carriersFromDB, startLoadingCarriersFromDB]);

  useEffect(() => {
    if (occupations.length === 0) {
      startLoadingOccupationsFromDB();
    }
  }, [occupations, startLoadingOccupationsFromDB]);

  useEffect(() => {
    if (carriers && carriers.length > 0) {
      for (let i = 0; i < carriers.length; i++) {
        const carrierElm = document.getElementById(`select-member-carrier${i}`)
        let children = carrierElm ? carrierElm.childNodes : '';
        let content = children ? children[0].textContent : '';

        if (children.length === 1 && content === 'No matches found.') {
          setShow(true);
        }
      }
    }
  }, [carriers, setShow]);

  useEffect(() => {
    if (Object.keys(carriersObj).length === 0) {
      setCarriersObject(carriersObject);
    }
    values.carriersObj = carriersObject
  }, [values.carriersObj, carriersObj, carriersObject, setCarriersObject])

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={6} controlId='is-captive'>
              <Form.Label>{label.isCaptive}</Form.Label>
              <Dropdown
                name='isCaptive'
                selected={values.isCaptive}
                options={label.dropdown}
                touched={touched}
                errors={errors}
                values={values}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.isCaptive}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {isCaptive !== '' &&
            carriers.length !== 0 ? (
            <FieldArray
              name='carriers'
              render={(arrayHelpers) => (
                <div>
                  {carriers.map((o, i) => (
                    <div key={`${i}${i}`}>
                      <Form.Row>
                        <Form.Group as={Col} lg={12} controlId='carriers'>
                          <Form.Label>{label.carriers}</Form.Label>
                          <InputGroup>
                            <Field name={`carriers[${i}].carrier`}>
                              {({ field, form }) => (
                                <Typeahead
                                  id={`select-member-carrier${i}`}
                                  ref={carrierRef}
                                  name={`carriers[${i}].carrier`}
                                  options={carriersFromDB}
                                  selected={[carriers[i].carrier]}
                                  isValid={touched.carriers && !errors.carriers}
                                  isInvalid={!!errors.carriers}
                                  onChange={(selectedCarrier) => {
                                    if (selectedCarrier.length !== 0) {
                                      form.setFieldValue(
                                        field.name,
                                        selectedCarrier[0].value
                                      );
                                    }
                                  }}
                                  onInputChange={(value, emptyLabel) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                />
                              )}
                            </Field>
                            {isCaptive !== '' &&
                              carriers.length > 1 && 
                              i >= 0 ? (
                              <InputGroup.Prepend>
                                <Button
                                  variant='secondary'
                                  onClick={() => {
                                    arrayHelpers.remove(i);
                                  }}
                                >
                                  Remove
                                </Button>
                              </InputGroup.Prepend>
                            ) : null} 
                            <Form.Control.Feedback type='invalid'>
                              <ErrorMessage
                                name={`carriers[${i}].carrier`}
                              />
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} lg={12}>
                          {isCaptive === 'yes' &&
                            carriers.length < 3 &&
                            carriers.length - 1 === i ? (
                            <div className='float-left'>
                              <Button
                                variant='primary'
                                onClick={() => {
                                  if (carriers[i].carrier !== '') {
                                    arrayHelpers.push({ carrier: '' });
                                  }
                                }}
                              >
                                Add Another
                              </Button>
                            </div>
                          ) : isCaptive === 'no' &&
                            carriers.length - 1 === i ? (
                            <div className='float-left'>
                              <Button
                                variant='primary'
                                onClick={() => {
                                  if (carriers[i].carrier !== '') {
                                    arrayHelpers.push({ carrier: '' });
                                  }
                                }}
                              >
                                Add Another
                              </Button>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>
                    </div>
                  ))}
                </div>
              )}
            ></FieldArray>
          ) : null}
        </Col>
      </Row>
      <Modal
        show={show}
        dialogClassName={`ie-modal`}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>Attention!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please select a carrier from the provided list or <Link to='/contact' target='_blank'> contact us </Link>
          if you want to add a carrier, not on the list
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-success'
            onClick={() => setShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  carriersFromDB: selectCarriers,
  occupations: selectOccupations,
  carriersObj: selectCarriersObj,
});

export default connect(mapStateToProps, {
  setCarriersObject,
  startLoadingCarriersFromDB,
  startLoadingOccupationsFromDB,
})(Carriers);
