import { createSelector } from 'reselect';

const selectPropertyQuote = (state) => state.propertyQuote

export const selectCurrentStep = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.currentStep
);

export const selectPropertyFormData = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.formData
);

export const selectNoCombineId = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.noCombineId
);

export const selectCombineId = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.combineId
);

export const selectMembersInfo = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.membersInfo
);

export const selectPropertyCombineCV = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.combineCV
);

export const selectPropertyQuotesFromDB = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.propertyQuotesFromDB
);

export const selectPropertyQuoteFromDB = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.propertyQuoteFromDB
);

export const selectIsSubmitted = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.isSubmitted
);

export const selectReset = createSelector(
  [selectPropertyQuote],
  (propertyQuote) => propertyQuote.reset
);





