export const PROPERTY_QUOTES_CMS_DATA = {
  heading: 'My Property',
  subHeading:
    'For property insurance, including home or business. Note that the more detailed and accurate the information you provide, the better our Agents can place you with the appropriate Carrier and more accurate quotes.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel',
    },
    property: {
      addProp: {
        title: 'My Properties',
        description: 'Please select Add a property to add a property to get quotes for. To view or edit a property already entered, please select the address.',
        label: 'My properties',
        dropdown: {
          addProp: [
            { value: 'addProperty', label: 'Add a property' }
          ]
        }
      },
      purpose: {
        title: 'Purpose',
        description:
          "Let us know if this property is your residence, a place of business (including a rental property), or both. If it's for business, please elaborate on the business and/or arrangements.",
        type: {
          label: 'This is my',
          dropdown: [
            { value: 'residence', key: 'Residence' },
            { value: 'business', key: 'Business' },
            { value: 'both', key: 'Both' },
          ],
        },
        subType: {
          residence: {
            label: 'Residence type',
            dropdown: [
              { value: 'home', key: 'Home' },
              { value: 'dorm', key: 'Dorm' },
              { value: 'dependent', key: 'Dependent dwelling' },
            ],
          },
          business: {
            label: 'Business type',
            elaborate: 'Please elaborate on business',
            dropdown: [
              { value: 'farm', key: 'Farm' },
              { value: 'garage', key: 'Garage' },
              { value: 'office', key: 'Office' },
              { value: 'rental', key: 'Rental' },
              { value: 'shop', key: 'Shop' },
              { value: 'other', key: 'Other' },
            ],
          },
          farm: {
            label: {
              acres: 'How many acres of crops?',
              isCrop: 'Is any of the land for crops?'
            },
            dropdown: [
              { value: 'true', key: 'Yes' },
              { value: 'false', key: 'No' },
            ],
          }
        },
      },
      details: {
        title: 'Property Details',
        description:
          'If any structure has another address, please fill out separate Qoutes Requests for each.',
        address: {
          label: {
            street: 'Street address',
            city: 'City',
            state: 'State',
            zipCode: 'Zip code',
          },
        },
        yearObtained: {
          label: 'Since',
        },
        ownershipType: {
          label: 'Ownership',
          dropdown: [
            { value: 'own', key: 'Own' },
            { value: 'finance', key: 'Finance' },
            { value: 'lease', key: 'Lease' },
          ],
        },
        responsibilityType: {
          label: 'Responsibility',
          dropdown: [
            { value: 'entireStructure', key: 'Entire structure' },
            { value: 'interiorOnly', key: 'Interior only' },
          ],
        },
        propertyType: {
          label: 'Structure type',
          isResidence: {
            dropdown: [
              { value: 'house', label: 'House (single-family dwelling)' },
              { value: 'townHome', label: 'Townhome (single unit)' },
              { value: 'multiplex', label: 'Multiplex (single unit)' },
              { value: 'apartment', label: 'Apartment (single unit)' },
              { value: 'condo', label: 'Condo (single unit)' },
              { value: 'tiny', label: 'Tiny house' },
              { value: 'mobile', label: 'Mobile home' },
            ],
          },
          isBusiness: {
            noOfUnits: {
              label: 'No. of units',
            },
            noOfBuildings: {
              label: 'No. of buildings',
            },
            dropdown: [
              { value: 'house', label: 'House (single-family dwelling)' },
              { value: 'townhome', label: 'Townhome' },
              { value: 'multiplex', label: 'Multiplex' },
              { value: 'apartment', label: 'Apartment (single unit)' },
              { value: 'apartmentComplex', label: 'Apartment complex' },
              { value: 'condo', label: 'Condo (single unit)' },
              { value: 'condoBuilding', label: 'Condo building' },
              { value: 'tiny', label: 'Tiny house' },
              { value: 'mobile', label: 'Mobile home' },
              { value: 'smallUnit', label: 'Small business building' },
              { value: 'unitInABuilding', label: 'Unit(s) within a larger building' },
              { value: 'barn', label: 'Barn' },
              { value: 'noStructures', label: 'No Structures' },
            ],
          },
        },
        basement: {
          exists: {
            label: 'Basement(s)',
            dropdown: [
              { value: 'true', key: 'Yes' },
              { value: 'false', key: 'No' },
            ],
          },
          noOfBasements: {
            label: 'How many?',
          },
        }
      },
      construction: {
        title: 'Construction Details',
        description:
          "Admitedly, most Agents can look this information up online. However, some have antiquated systems but might be able to offer a better quote. Plus, there might be discrepancies. So, it's better to provide this information to the Agent for them to reference.",
        frame: {
          label: 'Frame type',
          dropdown: [
            { value: 'wood', label: 'Wood supported' },
            { value: 'iBeam', label: 'I-Beam supported' },
            { value: 'hBeam', label: 'H-Beam supported' },
            { value: 'aluminum', label: 'Aluminum frame' },
            { value: 'other', label: 'Other' },
          ],
        },
        wall: {
          label: 'Exterior wall type',
          dropdown: [
            { value: 'stone', label: 'Stone' },
            { value: 'brick', label: 'Brick' },
            { value: 'imitation', label: 'Imitation stone/brick' },
            { value: 'stucco', label: 'Stucco' },
            { value: 'natural', label: 'Natural wood siding' },
            { value: 'engineered', label: 'Engineered wood siding' },
            { value: 'vinyl', label: 'Vinyl siding' },
            { value: 'fiber', label: 'Fiber siding' },
            { value: 'metal', label: 'Metal siding' },
            { value: 'other', label: 'Other' },
          ],
        },
        roof: {
          shape: {
            label: 'Roof shape',
            dropdown: [
              { value: 'gable', label: 'Gable' },
              { value: 'hipped', label: 'Hipped' },
              { value: 'mansard', label: 'Mansard' },
              { value: 'gambrel', label: 'Gambrel' },
              { value: 'flat', label: 'Flat' },
              { value: 'pitched', label: 'Pitched' },
              { value: 'butterfly', label: 'Butterfly' },
              { value: 'other', label: 'Other' },
            ],
          },
          material: {
            label: 'Roof material',
            dropdown: [
              { value: 'concreteTile', label: 'Concrete tile' },
              { value: 'clayTile', label: 'Clay tile' },
              { value: 'temperedGlassTile', label: 'Tempered glass tile' },
              { value: 'asphaltShingle', label: 'Asphalt shingle (most common)' },
              { value: 'vinylMembrane', label: 'Vinyl membrane' },
              { value: 'metalAluminumShake', label: 'Metal/aluminum shake' },
              { value: 'woodShake', label: 'Wood shake' },
              { value: 'other', label: 'Other' },
            ],
          },
          year: {
            label: 'Roof rating',
            dropdown: [
              { value: '15', label: '15 years' },
              { value: '20', label: '20 years' },
              { value: '25', label: '25 years' },
              { value: '30', label: '30 years' },
              { value: '40', label: '40 years' },
              { value: '50', label: '50+ years' },
            ],
          },
          installationYear: {
            label: 'Roof installation year (approx. is ok)',
          },
        },
        foundation: {
          label: 'Foundation type',
          dropdown: [
            { value: 'woodPierAndBeam', label: 'Wood pier and beam' },
            { value: 'concretePierAndGradeBeam', label: 'Concrete pier and grade beam' },
            { value: 'concreteSlab', label: 'Concrete slab' },
            { value: 'other', label: 'Other' },
          ],
        },
        otherInfo: {
          label:
            'Please elaborate on any inquiry for which you selected Other or 50+. Also, feel free to express any concerns or doubts.',
        },
      },
      ownership: {
        title: 'Ownership Details',
        description:
          "The Agents will access most of this but take note of their quotes and be suspicious. They might give you a better rate while making the Main structure value higher but this could also be increasing your deductible so be careful. Note that most insurances do not cover the land, in most cases (farm policies would be an exception), and if there's a shed, fence, or something similar, in most cases, that's automatically covered up to at least 10% of the value of the Main structure.",
        value: { label: 'Main structure value' },
        year: { label: 'Year built' },
        area: { label: 'Square footage' },
        stories: { label: 'No. of stories' },
        renovation: {
          label: 'Any renovations?',
          dropdown: [
            { value: 'true', key: 'Yes' },
            { value: 'false', key: 'No' },
          ],
          details: {
            label: 'Please provide renovation details',
          },
        },
      },
      scheduledItems: {
        title: 'Items',
        description: 'If there are any specific items that you have coverage, at a specified amount, list those and their value here.',
        label: {
          item: 'Scheduled item',
          replacementValue: 'Replacement value',
        }
      },
      coverage: {
        scheduledItems: {
          title: 'Scheduled Items',
          description: 'If there are any specific items that you have coverage, at a specified amount, list those and their value here.',
          label: {
            item: 'Schedule item',
            replacementValue: 'Replacement value',
          }
        },
        title: 'Coverage Details',
        description:
          "This section is what you control. The more you put in the higher your premium but the more coverage you'll have.",
        required: {
          label:
            "How much coverage do you want on your personal items or contents?",
        },
        liability: {
          label:
            'How much liability coverage do you want?',
        },
        scheduled: {
          label:
            "Please elaborate on anything here.",
        },
      },
    },
  },
  modal: {
    heading: 'Caution! Removing this Property will delete associated Policies. Consider updating the Form. Do you want to continue deleting',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    purpose: {
      content: {
        residenceType: "If it is your Home but not a Dorm, select Home; if it's your Dorm or a dependents Dorm, select Dorm; and if it's not a Dorm but for a dependent, select Dependent dwelling. If it is a Home and a Rental, make sure Both is selected in previous inquiry. Put Home here and Rental in Business type.",
      }
    },
    property: {
      content: {
        responsibility: "Some properties have structure coverage through the HOA (common in Townehomes and owned or financed Apartments and Condos), Mortgage, or another joint insurance policy. So, please specify whether you are responsible for the Entire structure (including the outer frame and exterior) or the Interior only (drywall in) with the policy you would be purchasing through this site.",
        yearObtained: "What year did you purchase, finance, or start the lease on this property? Current year is fine.",
        propertyType: "For businesses that have multiple units at a single address, please note that in Structure type there are separate selections for single vs multi-dwelling units. With the multi-dwelling units, please specify how many units. Apartment complexes allow for multiple buildings at the same address.",
        noOfUnits: "Please specify how many units you own only at this address (could be 1 or more). Again, if there are more at another address, please add another property for that address. Different addresses will change how a Carrier rates it.",
        noOfBuildings: "If there are multiple structures (apartment buildings) at the same address, please specify how many (could be 1 or more).",
        hasBasement: "Are there any basements? Note that, even if there are multi-levels, these will not count as additional stories (they are separate).",
        noOfBasements: "If there is more than a wall that separates rooms in the basement(s) or there are multiple levels, count those as separate basements.",
      }
    },
    construction: {
      content: {
        roofYear: "This is how many years your roof is designed to last. More years = better quality. If you know that you have a high quality roof, it might be worthwhile to find out, if you don't already know.",
        roofInstallationYear: "What year was your roof installed. Based on the quality and the year it was installed, Carriers will decide how long they are willing to cover your roof at Replacement Cost (RC) vs Actual Cash Value (ACV). You can learn more about RC and ACV when getting a quote.",
      },
    },
    ownership: {
      content: {
        value: "This is not usually the appraisal value of a property. Instead, it is the amount that it would cost to replace the main structure. Especially in Homeowners policies, this dictates many other coverages as well as a percentage, like other structures, personal property, Loss of use, etc., as well as the deductible in most cases. Too high could mean higher deductible but too low could mean not enough coverage.",
        year: "What year was the Main structure built? Can be found in your local Central Appraisal District (or CAD) website.",
        area: "For our purposes the area (or square footage) does not include the garage or basements. Can be found in your local Central Appraisal District (or CAD) website.",
        stories: "Not including the basement(s), how many stories is the Main structure?",
        hasRenovations: "Have there been any renovations to the property, including ones previous to when you moved in, that you are aware of? This would also include converted garages or finished basement space. You'll be able to elaborate below.",
      },
    },
    coverage: {
      content: {
        requiredCoverage: "This usually starts around $10,000 and would cover things included from the dry wall in, like destruction of the dry wall, furniture, appliances, etc., due to perls listed in the policy (fire, windstorm, etc.). Look for coverage of items that you might take with you away from your property, like phones, laptops, etc.",
        requiredLiability: "This usually starts around $25,000 but make sure it meets the standards of the landlord, HOA, or other governing entity. This covers you in case someone has an accident on your property.",
        item: "Would you like to schedule any items that you want to attach a specific value to, like fine art, antiques, collectibles, jewelry, or anything that you think needs special coverage? Note that most personal items and contents are already covered, like in a Homeowners policy, personal items and contents are covered at 50% of the Main structure value (maybe more per the Carrier or less in some States).",
        replacementValue: "This is the value you want to place on the item - often the appraised value.",
        scheduledItemsInfo: "Feel free to include any concerns about the property, additional structures, additional requests, etc.",
      },
    },
  }
};

