import { AGENT_VEHICLE_QUOTE_CMS_DATA }
  from '../../../pages/agent-quotes/vehicle/vehicle-quote.cms';

export const convertAgentVehicleQuoteForDBSave = (data) => {
  const {
    form: {
      medicalCoverage,
      personalIP
    },
  } = AGENT_VEHICLE_QUOTE_CMS_DATA;

  const isMedicalCoverage = medicalCoverage.dropdown;
  const isPersonalIP = personalIP.dropdown;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    createdAgentQuoteDate: !data.isSelected ? new Date() : '',
    policyStartDate: data.isSelected ? new Date() : '',
    storedVehicles: data.storedVehicles ? data.storedVehicles : [],
    documents: data.documents ? data.documents : [],
    polDocuments: data.polDocuments ? data.polDocuments : [],
    quoteDetails: {
      type: data.quoteType ? data.quoteType : '',
      quoteId: data.quoteId ? data.quoteId : '',
      agentId: data.agentId ? data.agentId : '',
      biddingId: data.biddingId ? data.biddingId : '',
      createdDate: data.quoteCreatedDate ? data.quoteCreatedDate : '',
      isCaptive: data.isCaptive ? data.isCaptive : '',
      servicesProvidedTo: data.servicesProvidedTo ? data.servicesProvidedTo : '',
      isSelected: data.isSelected ? data.isSelected : false,
      isPolicy: data.isPolicy ? data.isPolicy : false,
    },
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    effectiveDates: {
      start: data.startDate ? data.startDate : '',
      end: data.endDate ? data.endDate : '',
    },
    bodyInjuryLL: {
      eachPerson:
        data.BILPerson
          ? data.BILPerson
          : '',
      eachOccurrence:
        data.BILOccurrence
          ? data.BILOccurrence
          : '',
    },
    propertyDLL:
      data.propertyDLL
        ? data.propertyDLL
        : '',
    uninsuredBIP: {
      eachPerson:
        data.unPerson
          ? data.unPerson
          : '',
      eachOccurrence:
        data.unOccurrence
          ? data.unOccurrence
          : '',
    },
    ownPropertyDL:
      data.ownPropertyDL
        ? data.ownPropertyDL
        : '',
    deductibles: {
      collision:
        data.DCollision
          ? data.DCollision
          : '',
      other:
        data.DOther
          ? data.DOther
          : '',
    },
    medicalCoverage: {
      isTypeOf:
        data.hasMedCov === isMedicalCoverage[0].value
          ? true
          : false,
      value:
        data.covValue
          ? data.covValue
          : '',
    },
    personalIP: {
      isTypeOf:
        data.hasPP === isPersonalIP[0].value
          ? true
          : false,
      value:
        data.PPValue
          ? data.PPValue
          : '',
    },
    additionalInfo: data.additionalInfo ? data.additionalInfo : '',
  };
  return payload;
}




