import axios from 'axios';

export const configureAxios = () => {
  const PERSISTED_STATE = JSON.parse(
    localStorage.getItem('persist:insureasier')
  );
  const authState = JSON.parse(PERSISTED_STATE.auth);
  axios.defaults.headers.post['x-auth-token'] = authState.token;
  axios.defaults.headers.delete['x-auth-token'] = authState.token;

  let config = {
    headers: {
      'x-auth-token': authState.token,
    },
  };

  return config;
};
