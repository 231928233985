import * as yup from 'yup';
import { REGISTRATION_CMS_DATA } from './registration.cms';

const { email, password } = REGISTRATION_CMS_DATA;

const registrationSchema = yup.object().shape({
  email: yup
    .string()
    .required(email.required)
    .email(email.invalid),
  password: yup
    .string()
    .required(password.required)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, password.invalid)
});

export default registrationSchema;
