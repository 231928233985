import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  loadStatesFromDBFailure,
  loadStatesFromDBSuccess,
  propertySaveSuccess,
  propertySaveFailure,
  propertyUpdateSuccess,
  propertyUpdateFailure,
  propertyDeleteSuccess,
  propertyDeleteFailure,
  propertyInQuoteSaveSuccess,
  propertyInQuoteSaveFailure,
  propertyInQuoteUpdateSuccess,
  propertyInQuoteUpdateFailure,
  fetchAllPropertiesFromDBSuccess,
  fetchAllPropertiesFromDBFailure,
  fetchPropertyFromDBSuccess,
  fetchPropertyFromDBFailure,
} from "./property.actions";
import propertyActionTypes from "./property.types";
import { convertPropertyForDBSave } from "./property.utils";

function* saveProperty({ payload: formData }) {
  try {
    const data = yield call(convertPropertyForDBSave, formData);
    yield axios.post("/api/properties", data, configureAxios());
    yield put(propertySaveSuccess());
  } catch (e) {
    yield put(propertySaveFailure(e));
  }
}

function* updateProperty({ payload: { id, formData } }) {
  try {
    const data = yield call(convertPropertyForDBSave, formData);
    const response = yield axios.patch(
      `/api/properties/${id}`,
      data,
      configureAxios()
    );
    const property = yield JSON.parse(response.config.data);
    yield put(propertyUpdateSuccess(property));
  } catch (e) {
    yield put(propertyUpdateFailure(e));
  }
}

function* deleteProperty({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/properties/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(propertyDeleteSuccess());
  } catch (e) {
    yield put(propertyDeleteFailure(e));
  }
}

function* savePropertyQuote({ payload: formData }) {
  try {
    const data = yield call(convertPropertyForDBSave, formData);
    yield axios.post("/api/properties", data, configureAxios());
    yield put(propertyInQuoteSaveSuccess());
  } catch (e) {
    yield put(propertyInQuoteSaveFailure(e));
  }
}

function* updatePropertyQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertPropertyForDBSave, formData);
    const response = yield axios.patch(
      `/api/properties/${id}`,
      data,
      configureAxios()
    );
    const property = yield JSON.parse(response.config.data);
    yield put(propertyInQuoteUpdateSuccess(property));
  } catch (e) {
    yield put(propertyInQuoteUpdateFailure(e));
  }
}

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchAllPropertiesFromDB() {
  try {
    const response = yield axios.get(`/api/properties`);
    const properties = yield response.data;
    yield put(fetchAllPropertiesFromDBSuccess(properties));
  } catch (e) {
    yield put(fetchAllPropertiesFromDBFailure(e));
  }
}

function* fetchPropertyFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/properties/${id}`);
    const property = yield response.data;
    yield put(fetchPropertyFromDBSuccess(property));
  } catch (e) {
    yield put(fetchPropertyFromDBFailure(e));
  }
}

function* onPropertySaveStart() {
  yield takeEvery(propertyActionTypes.START_PROPERTY_SAVE, saveProperty);
}

function* onPropertyUpdateStart() {
  yield takeEvery(propertyActionTypes.START_PROPERTY_UPDATE, updateProperty);
}

function* onPropertyDeleteStart() {
  yield takeEvery(propertyActionTypes.START_PROPERTY_DELETE, deleteProperty);
}

function* onPropertyQuoteSaveStart() {
  yield takeEvery(
    propertyActionTypes.START_PROPERTY_IN_QUOTE_SAVE,
    savePropertyQuote
  );
}

function* onPropertyQuoteUpdateStart() {
  yield takeEvery(
    propertyActionTypes.START_PROPERTY_IN_QUOTE_UPDATE,
    updatePropertyQuote
  );
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    propertyActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchPropertiesFromDB() {
  yield takeEvery(
    propertyActionTypes.FETCH_ALL_PROPERTIES_FROM_DB_START,
    fetchAllPropertiesFromDB
  );
}

function* onFetchPropertyFromDB() {
  yield takeEvery(
    propertyActionTypes.FETCH_PROPERTY_FROM_DB_START,
    fetchPropertyFromDB
  );
}

export function* propertySagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onPropertySaveStart),
    call(onPropertyUpdateStart),
    call(onPropertyDeleteStart),
    call(onPropertyQuoteSaveStart),
    call(onPropertyQuoteUpdateStart),
    call(onFetchPropertiesFromDB),
    call(onFetchPropertyFromDB),
  ]);
}
