export const scrollIntoViewForms = (
  url,
  selectionHeight,
  showForm,
  showEditForm,
) => {

  const table = document.querySelector('table');
  const header = document.querySelector('header');
  const tableHeight = table ? table.getBoundingClientRect().height : '';
  let headerHeight = header ? header.getBoundingClientRect().height : '';

  const screenW = window.innerWidth;
  const path = window.location.pathname.replace(/\//g, '');
  const animalQP = 'household-animal-quotes';
  const vehicleQP = 'household-vehicle-quotes';

  if (screenW < 525 && path !== url) {
    if (
      path === animalQP
    ) {
      headerHeight = headerHeight + 15
    }
    if (
      path === vehicleQP
    ) {
      headerHeight = headerHeight + 20
    }
  }
  if (showForm && !showEditForm) {
    if (screenW > 525) {
      if (path !== url) {
        window.scrollTo({
          top: headerHeight + selectionHeight + 14,
          behavior: 'smooth'
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    } else {
      if (path !== url) {
        if (path === vehicleQP) {
          window.scrollTo({
            top: headerHeight + selectionHeight + 28,
            behavior: 'smooth'
          });
        } else {
          window.scrollTo({
            top: headerHeight + selectionHeight + 50,
            behavior: 'smooth'
          });
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  }
  if (!showForm && showEditForm) {
    if (path !== url) {
      window.scrollTo({
        top: headerHeight + selectionHeight,
        behavior: 'smooth'
      });
    }
  }
  if (showForm && showEditForm) {
    if (path !== url) {
      if (screenW <= 525) {
        if (path === vehicleQP) {
          window.scrollTo({
            top: headerHeight + selectionHeight + tableHeight + 69,
            behavior: 'smooth'
          });
        } else {
          window.scrollTo({
            top: headerHeight + selectionHeight + tableHeight + 88,
            behavior: 'smooth'
          });
        }
      }
      if (screenW > 525 && screenW < 1200) {
        if (path === animalQP || path === vehicleQP) {
          window.scrollTo({
            top: headerHeight + selectionHeight + tableHeight + 52,
            behavior: 'smooth'
          });
        } else {
          window.scrollTo({
            top: headerHeight + selectionHeight + tableHeight + 25,
            behavior: 'smooth'
          });
        }
      }
      if (screenW > 1200) {
        window.scrollTo({
          top: headerHeight + selectionHeight + tableHeight + 52,
          behavior: 'smooth'
        });
      }
    }
  }
};
