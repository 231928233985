import { ModalState, ModalTypes } from '../../modal/modal.types';
import vehicleQuoteActionTypes from './vehicle.types';

const payload = {
  context: 'vehicleQuote',
  status: '',
  error: '',
};

export const decrementCurrentStep = (currentStep) => ({
  type: vehicleQuoteActionTypes.VEHICLE_DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: vehicleQuoteActionTypes.VEHICLE_INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const setVehicleFormData = (data) => ({
  type: vehicleQuoteActionTypes.SET_VEHICLE_FORM_DATA,
  payload: data,
});

export const setTimer = (time) => ({
  type: vehicleQuoteActionTypes.SET_TIMER,
  payload: time,
});

export const setNoCombineId = (id) => ({
  type: vehicleQuoteActionTypes.SET_VEHICLE_NO_COMBINE_ID,
  payload: id,
});

export const setVehicleCombineId = (id) => ({
  type: vehicleQuoteActionTypes.SET_VEHICLE_COMBINE_ID,
  payload: id,
});

export const setCombineCV = (combine) => ({
  type: vehicleQuoteActionTypes.SET_VEHICLE_COMBINE_CV,
  payload: combine,
});

export const setMembersInfo = (members) => ({
  type: vehicleQuoteActionTypes.SET_MEMBERS_INFO,
  payload: members,
});

export const saveDeleteCometChat = (id) => ({
  type: vehicleQuoteActionTypes.SAVE_DELETE_VEHICLE_COMET_CHAT,
  payload: id,
})

export const saveDeleteCometChatSuccess = () => ({
  type: vehicleQuoteActionTypes.SAVE_DELETE_VEHICLE_COMET_CHAT_SUCCESS,
})

export const startFetchingVehicleQuotesFromDB = () => ({
  type: vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTES_FROM_DB_START,
});

export const fetchVehicleQuotesFromDBSuccess = (quote) => ({
  type: vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTES_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchVehicleQuotesFromDBFailure = (e) => ({
  type: vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingVehicleQuoteFromDB = (id) => ({
  type: vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchVehicleQuoteFromDBSuccess = (quote) => ({
  type: vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTE_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchVehicleQuoteFromDBFailure = (e) => ({
  type: vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startVehicleQuoteSave = (quotes) => ({
  type: vehicleQuoteActionTypes.START_VEHICLE_QUOTE_SAVE,
  payload: quotes,
});

export const vehicleQuoteSaveSuccess = () => ({
  type: vehicleQuoteActionTypes.VEHICLE_QUOTE_SAVE_SUCCESS,
});

export const startCheckingChatGroup = (id) => ({
  type: vehicleQuoteActionTypes.START_CHECKING_CHAT_GROUP_VEHICLE,
  payload: id,
})

export const checkingChatGroupSuccess = () => ({
  type: vehicleQuoteActionTypes.CHECKING_CHAT_GROUP_VEHICLE_SUCCESS,
})

export const vehicleQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehicleQuoteUpdate = (id, formData) => ({
  type: vehicleQuoteActionTypes.START_VEHICLE_QUOTE_UPDATE,
  payload: { id, formData },
});

export const vehicleQuoteUpdateSuccess = () => ({
  type: vehicleQuoteActionTypes.VEHICLE_QUOTE_UPDATE_SUCCESS,
});

export const vehicleQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLastStepVehicleQuoteUpdate = (id, formData) => ({
  type: vehicleQuoteActionTypes.START_LAST_STEP_VEHICLE_QUOTE_UPDATE,
  payload: { id, formData },
});

export const lastStepVehicleQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.QUOTE_REQUEST_SUCCESS,
  },
});

export const lastStepVehicleQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehicleQuoteDelete = (id, PId) => ({
  type: vehicleQuoteActionTypes.START_VEHICLE_QUOTE_DELETE,
  payload: { id, PId },
});

export const vehicleQuoteDeleteSuccess = () => ({
  type: vehicleQuoteActionTypes.VEHICLE_QUOTE_DELETE_SUCCESS,
});

export const vehicleQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});




