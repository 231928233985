import { MEMBER_CMS_DATA } from "../../../pages/member/member.cms";

export const convertMemberHistoryForDBSave = (data) => {
  const {
    form: {
      history: {
        felony: {
          label: {
            dropdown: { currentStatus },
          },
        },
      },
    },
  } = MEMBER_CMS_DATA;

  const payload = {
    type: data.memberType ? data.memberType : "",
    felonyStatus: {
      hasBeenConvicted: data.felonyStatus === currentStatus[0].value,
      reasonForConviction: data.felonyReason ? data.felonyReason : "",
    },
  };

  return payload;
};
