import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import lifeQuoteActionTypes from "./life.types";
import { convertLifeQuoteForDBSave } from "./life.utils";
import {
  fetchLifeQuotesFromDBSuccess,
  fetchLifeQuotesFromDBFailure,
  fetchLifeQuoteFromDBSuccess,
  fetchLifeQuoteFromDBFailure,
  lifeQuoteSaveSuccess,
  lifeQuoteSaveFailure,
  lifeQuoteUpdateSuccess,
  lifeQuoteUpdateFailure,
  checkingChatGroupSuccess,
  saveDeleteCometChatSuccess,
  lastStepLifeQuoteUpdateSuccess,
  lastStepLifeQuoteUpdateFailure,
} from "./life.actions";

function* fetchLifeQuotesFromDB() {
  try {
    const response = yield axios.get("/api/life-quote");
    const quotes = yield response.data;
    yield put(fetchLifeQuotesFromDBSuccess(quotes));
  } catch (e) {
    yield put(fetchLifeQuotesFromDBFailure(e));
  }
}

function* fetchLifeQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios(`/api/life-quote/${id}`);
    const quote = yield response.data;
    yield put(fetchLifeQuoteFromDBSuccess(quote));
  } catch (e) {
    yield put(fetchLifeQuoteFromDBFailure(e));
  }
}

function* saveLifeQuote({ payload: formData }) {
  try {
    const data = yield call(convertLifeQuoteForDBSave, formData);
    yield axios.post("/api/life-quote", data, configureAxios());
    yield put(lifeQuoteSaveSuccess());
  } catch (e) {
    yield put(lifeQuoteSaveFailure(e));
  }
}

function* updateLifeQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertLifeQuoteForDBSave, formData);
    const response = yield axios.patch(
      `/api/life-quote/${id}`,
      data,
      configureAxios()
    );
    const quote = yield JSON.parse(response.config.data);
    yield put(lifeQuoteUpdateSuccess(quote));
  } catch (e) {
    yield put(lifeQuoteUpdateFailure(e));
  }
}

function* updateLastStepLifeQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertLifeQuoteForDBSave, formData);
    const response = yield axios.patch(
      `/api/life-quote/${id}`,
      data,
      configureAxios()
    );
    const quotes = yield JSON.parse(response.config.data);
    yield put(lastStepLifeQuoteUpdateSuccess(quotes));
  } catch (e) {
    yield put(lastStepLifeQuoteUpdateFailure(e));
  }
}

function* checkingChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/life-quote/${id}/check-chat-group`);
    yield checkingChatGroupSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* saveDeleteChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/life-quote/${id}/save-delete-chat`);
    yield saveDeleteCometChatSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* onFetchLifeQuotesStart() {
  yield takeEvery(
    lifeQuoteActionTypes.FETCH_LIFE_QUOTES_FROM_DB_START,
    fetchLifeQuotesFromDB
  );
}

function* onFetchLifeQuoteStart() {
  yield takeEvery(
    lifeQuoteActionTypes.FETCH_LIFE_QUOTE_FROM_DB_START,
    fetchLifeQuoteFromDB
  );
}

function* onLifeQuoteSaveStart() {
  yield takeEvery(lifeQuoteActionTypes.START_LIFE_QUOTE_SAVE, saveLifeQuote);
}

function* onLifeQuoteUpdateStart() {
  yield takeEvery(
    lifeQuoteActionTypes.START_LIFE_QUOTE_UPDATE,
    updateLifeQuote
  );
}

function* onLastStepLifeQuoteUpdateStart() {
  yield takeEvery(
    lifeQuoteActionTypes.START_LAST_STEP_LIFE_QUOTE_UPDATE,
    updateLastStepLifeQuote
  );
}

function* onCheckingChatGroup() {
  yield takeEvery(
    lifeQuoteActionTypes.START_CHECKING_CHAT_GROUP_LIFE,
    checkingChatGroup
  );
}

function* onSaveDeleteChatGroup() {
  yield takeEvery(
    lifeQuoteActionTypes.SAVE_DELETE_LIFE_COMET_CHAT,
    saveDeleteChatGroup
  );
}

export function* lifeQuoteSagas() {
  yield all([
    call(onFetchLifeQuotesStart),
    call(onFetchLifeQuoteStart),
    call(onLifeQuoteSaveStart),
    call(onLifeQuoteUpdateStart),
    call(onLastStepLifeQuoteUpdateStart),
    call(onCheckingChatGroup),
    call(onSaveDeleteChatGroup),
  ]);
}
