const agentBiddingActionTypes = {
  FETCH_AGENT_BIDDINGS_FROM_DB_START: 'FETCH_AGENT_BIDDINGS_FROM_DB_START',
  FETCH_AGENT_BIDDINGS_FROM_DB_SUCCESS: 'FETCH_AGENT_BIDDINGS_FROM_DB_SUCCESS',
  FETCH_AGENT_BIDDINGS_FROM_DB_FAILURE: 'FETCH_AGENT_BIDDINGS_FROM_DB_FAILURE',
  FETCH_AGENT_BIDDING_FROM_DB_START: 'FETCH_AGENT_BIDDING_FROM_DB_START',
  FETCH_AGENT_BIDDING_FROM_DB_SUCCESS: 'FETCH_AGENT_BIDDING_FROM_DB_SUCCESS',
  FETCH_AGENT_BIDDING_FROM_DB_FAILURE: 'FETCH_AGENT_BIDDING_FROM_DB_FAILURE',
  START_AGENT_BIDDING_SAVE: 'START_AGENT_BIDDING_SAVE',
  AGENT_BIDDING_SAVE_SUCCESS: 'AGENT_BIDDING_SAVE_SUCCESS',
  START_AGENT_BIDDING_UPDATE: 'START_AGENT_BIDDING_UPDATE',
  AGENT_BIDDING_UPDATE_SUCCESS: 'AGENT_BIDDING_UPDATE_SUCCESS',
  SET_SHOW_ANIMAL_QUOTES: 'SET_SHOW_ANIMAL_QUOTES',
  SET_SHOW_LIFE_QUOTES: 'SET_SHOW_LIFE_QUOTES',
  SET_SHOW_ANNUITY_QUOTES: 'SET_SHOW_ANNUITY_QUOTES',
  SET_SHOW_HEALTH_QUOTES: 'SET_SHOW_HEALTH_QUOTES',
  SET_SHOW_PROPERTY_QUOTES: 'SET_SHOW_PROPERTY_QUOTES',
  SET_SHOW_VEHICLE_QUOTES: 'SET_SHOW_VEHICLE_QUOTES',
  SET_SHOW_COMBINE_QUOTES: 'SET_SHOW_COMBINE_QUOTES',
  SET_SHOW_ALL_QUOTES: 'SET_SHOW_ALL_QUOTES',
  SET_CARRIERS_OBJECT: 'SET_CARRIERS_OBJECT',
  SET_IS_PROPERTY_SELECTED_BY_AI: 'SET_IS_PROPERTY_SELECTED_BY_AI',
  SET_IS_VEHICLE_SELECTED_BY_AI: 'SET_IS_VEHICLE_SELECTED_BY_AI',
  SET_IS_COMBINED_SELECTED_BY_AI: 'SET_IS_COMBINED_SELECTED_BY_AI',
  SET_IS_ANNUITY_SELECTED_BY_AI: 'SET_IS_ANNUITY_SELECTED_BY_AI',
  SET_IS_LIFE_SELECTED_BY_AI: 'SET_IS_LIFE_SELECTED_BY_AI',
  SET_IS_HEALTH_SELECTED_BY_AI: 'SET_IS_HEALTH_SELECTED_BY_AI',
  SET_IS_ANIMAL_SELECTED_BY_AI: 'SET_IS_ANIMAL_SELECTED_BY_AI',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_ANIMAL_BID: 'SET_IS_ANIMAL_BID',
  SET_IS_VEHICLE_BID: 'SET_IS_VEHICLE_BID',
  SET_IS_PROPERTY_BID: 'SET_IS_PROPERTY_BID',
  SET_IS_COMBINE_BID: 'SET_IS_COMBINE_BID',
  SET_IS_LIFE_BID: 'SET_IS_LIFE_BID',
  SET_IS_HEALTH_BID: 'SET_IS_HEALTH_BID',
  SET_IS_ANNUITY_BID: 'SET_IS_ANNUITY_BID',
  RESET_AGENT_BIDDING: 'RESET_AGENT_BIDDING',
  RESET_FORM: 'RESET_FORM',
};

export default agentBiddingActionTypes;
