import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BodyMeasurement
  from '../../../components/quotes/health/body-measurement.component';
import Handicaps
  from '../../../components/quotes/health/handicaps.component';
import OtherInformation
  from '../../../components/quotes/health/other-information.component';
import FamilyHistories
  from '../../../components/quotes/health/family-histories.component';
import PreExistingConditions 
  from '../../../components/quotes/health/pre-existing-conditions.component';
import { HEALTH_QUOTE_CMS_DATA } from './health.cms';
import healthSchema from './health.validation.schema';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import { 
  startHealthSave,
  startHealthUpdate,
  toggleHealthFormView,
} from '../../../redux/quotes/health/health.actions';
import {
  selectHealthFromDB,
  selectReset,
  selectShowHealthForm,
} from '../../../redux/quotes/health/health.selectors';
import {
  startFetchingMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectMember,
} from '../../../redux/member/member.selectors';
import ErrorMessages
  from '../../../components/error-message/error-messages.component';

import loadable from '@loadable/component';

const TobaccoNicotine = loadable(() => import('../../../components/quotes/health/tobacco.component'));
  
const HealthInfo = ({
  values,
  touched,
  errors,
  reset,
  memberId,
  resetForm,
  isSubmitting,
  handleSubmit,
  handleChange,
  memberHealth,
}) => {
  const formikProps = { values, handleChange, touched, errors };
  const {
    heading,
    subHeading,
    form: { button },
  } = HEALTH_QUOTE_CMS_DATA;

  const path = window.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';
  const viewQ = 'member-view-quotes';

  if (values && values.memberId === '') {
    values.memberId = memberId;
  }

  useEffect(() => {
    reset && resetForm();
  }, [reset, resetForm]);

  return (
    <section>
      <Container className='px-0'>
        {path !== agentBid && path !== viewQ ? (
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
        ) : null}
        <FormikForm noValidate>
          <>
            <BodyMeasurement {...formikProps}/>
            <Handicaps {...formikProps} />
            <TobaccoNicotine {...formikProps} />
            <PreExistingConditions {...formikProps} filterOptions />
            <FamilyHistories {...formikProps} />
            <OtherInformation {...formikProps} />

            <div className={
              path !== agentBid && path !== viewQ
                ? 'float-right'
                : ''
            }
            >
              <ErrorMessages errors={errors}/>
              <div className='float-right'>
                {
                  memberHealth &&
                    memberHealth._id &&
                    path !== agentBid && path !== viewQ ? (
                  <Button disabled={isSubmitting} onClick={handleSubmit}>
                    {button.update}
                  </Button>
                  ) : memberHealth &&
                    !memberHealth._id &&
                    path !== agentBid && path !== viewQ ? (
                    <Button disabled={isSubmitting} onClick={handleSubmit}>
                      {button.save}
                    </Button>
                  ) : null}
              </div>
            </div>
          </>
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikHealthInfo = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ memberHealth, memberId, currentUser }) => ({
    memberId: memberId ? memberId : '',
    primeMemberId: currentUser.member ? currentUser.member : '',
    heightF: memberHealth && memberHealth.body
      ? (memberHealth.body.height.feet.zero && 'zero') ||
      (memberHealth.body.height.feet.one && 'one') ||
      (memberHealth.body.height.feet.two && 'two') ||
      (memberHealth.body.height.feet.three && 'three') ||
      (memberHealth.body.height.feet.four && 'four') ||
      (memberHealth.body.height.feet.five && 'five') ||
      (memberHealth.body.height.feet.six && 'six') ||
      (memberHealth.body.height.feet.seven && 'seven') ||
      (memberHealth.body.height.feet.eight && 'eight') ||
      (memberHealth.body.height.feet.nine && 'nine')
      : '',
    heightI: memberHealth && memberHealth.body
      ? (memberHealth.body.height.inches.zero && 'zero') ||
      (memberHealth.body.height.inches.one && 'one') ||
      (memberHealth.body.height.inches.two && 'two') ||
      (memberHealth.body.height.inches.three && 'three') ||
      (memberHealth.body.height.inches.four && 'four') ||
      (memberHealth.body.height.inches.five && 'five') ||
      (memberHealth.body.height.inches.six && 'six') ||
      (memberHealth.body.height.inches.seven && 'seven') ||
      (memberHealth.body.height.inches.eight && 'eight') ||
      (memberHealth.body.height.inches.nine && 'nine') ||
      (memberHealth.body.height.inches.ten && 'ten') ||
      (memberHealth.body.height.inches.eleven && 'eleven')
      : '',
    weight: memberHealth && memberHealth.body ? memberHealth.body.weight.current : '',
    weightChange: memberHealth && memberHealth.body
      ? (memberHealth.body.weight.decreased && 'decreased') ||
      (memberHealth.body.weight.increased && 'increased') ||
      (memberHealth.body.weight.noChange && 'noChange')
      : '',
    weightDiff: memberHealth && memberHealth.body
      ? (memberHealth.body.weight.increasedBy !== 0 ? memberHealth.body.weight.increasedBy : '') ||
      (memberHealth.body.weight.decreasedBy !== 0 ? memberHealth.body.weight.decreasedBy : '')
      : '',
    hasMentalHandicap: memberHealth && memberHealth.handicaps
      ? memberHealth.handicaps.mentalHandicap
        ? 'true'
        : 'false'
      : '',
    hasPhysicalDis: memberHealth && memberHealth.handicaps
      ? memberHealth.handicaps.physicalDisability
        ? 'true'
        : 'false'
      : '',
    handicapAddInfo:
      memberHealth && memberHealth.handicaps
        ? memberHealth.handicaps.additionalInfo
        : '',
    tobaccoUsage: memberHealth && memberHealth.tobaccoNicotine
      ? memberHealth.tobaccoNicotine.status
        ? 'true'
        : 'false'
      : '',
    tobaccoUsageTypes: memberHealth && memberHealth.tobaccoNicotine
      ? memberHealth.tobaccoNicotine.type
      : [],
    hasStopped: memberHealth && memberHealth.tobaccoNicotine
      ? memberHealth.tobaccoNicotine.stoppedUsage
        ? 'true'
        : 'false'
      : '',
    stoppedDate:
      memberHealth &&
        memberHealth.tobaccoNicotine &&
        memberHealth.tobaccoNicotine.stoppedUsageDate !== null
          ? memberHealth.tobaccoNicotine.stoppedUsageDate
          : '',
    isInCessation: memberHealth && memberHealth.tobaccoNicotine
      ? memberHealth.tobaccoNicotine.inCessationProgram
        ? 'true'
        : 'false'
      : '',
    hasPlansToJoinCes: memberHealth && memberHealth.tobaccoNicotine
      ? memberHealth.tobaccoNicotine.plansToJoinCessationProgram
        ? 'true'
        : 'false'
      : '',
    hasHeartDis:
      memberHealth &&
      memberHealth.preExistingConditions &&
      memberHealth.preExistingConditions.heart
        ? memberHealth.preExistingConditions.heart
        : false,
    hasLungDis: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.lung
      : false,
    hasMentalDis: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.nervousSystem
      : false,
    hadGotStudiesDone: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.studies
      : false,
    hadSurgery: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.surgery
      : false,
    advisedAlcoholUsage: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.alcoholUsage
      : false,
    advisedDrugAbuse: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.drugAbuse
      : false,
    hasBoneOrJointDis: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.boneOrJoint
      : false,
    hasDigestiveDis: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.digestive
      : false,
    hasGenUrinDis: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.genitoUrinary
      : false,
    hasGlandDis: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.glandular
      : false,
    hasHearingIm: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.hearing
      : false,
    hasUnexpFever: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.unexpFever
      : false,
    hasVisionIm: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.vision
      : false,
    isAANAMember: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.AANAMember
      : false,
    isHIVPos: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.hiv
      : false,
    isOnMed: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.onMedication
      : false,
    isPregnant: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.pregnancy
      : false,
    expDelDate:
      memberHealth &&
      memberHealth.preExistingConditions &&
      memberHealth.preExistingConditions.expDelDate 
        ? memberHealth.preExistingConditions.expDelDate
        : '',
    preExistAddInfo: memberHealth && memberHealth.preExistingConditions
      ? memberHealth.preExistingConditions.additionalInfo
      : '',
    relationships: memberHealth && memberHealth.familyHistories
      ? memberHealth.familyHistories
      : [{ relationship: '', doa: '', age: '', healthDetails: '' }],
    isReinst: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.reinstatementStatus
      : false,
    engHazAct: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.hazardousActivity
      : false,
    travelStatus: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.travelStatus
      : false,
    pilotStatus: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.pilotStatus
      : false,
    engDrivVi: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.drivingViolations
      : false,
    hasIllDrugs: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.illegalDrugs
      : false,
    hasIllness: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.illness
      : false,
    addInfo: memberHealth && memberHealth.additionalInfo
      ? memberHealth.additionalInfo.additionalInfo
      : '',
  }),
  validationSchema: healthSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: {
      memberHealth,
      startHealthSave,
      startHealthUpdate,
      setShowHealthInfo,
      toggleHealthFormView,
    },
      resetForm,
      setSubmitting
    }
  ) => {
    setSubmitting(true);

    if (memberHealth && memberHealth._id) {
      startHealthUpdate(memberHealth._id, values);
    } else {
      startHealthSave(values);
    }

    resetForm();
    setSubmitting(false);
    toggleHealthFormView(false);
    setShowHealthInfo(false);
  },
})(HealthInfo);

const mapStateToProps = createStructuredSelector({ 
  currentUser: selectUser,
  memberHealth: selectHealthFromDB,
  member: selectMember,
  reset: selectReset,
  showHealthForm: selectShowHealthForm,
});

export default withRouter(
  connect(mapStateToProps, {
    startHealthSave,
    startHealthUpdate,
    toggleHealthFormView,
    startFetchingMemberFromDB,
  })(FormikHealthInfo)
);
