import { ModalState, ModalTypes } from '../../modal/modal.types';
import annuityBiddingActionTypes from './annuity-bidding.types';

const payload = {
  context: 'annuityBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: annuityBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsAnnuityBid = (toggle) => ({
  type: annuityBiddingActionTypes.SET_IS_ANNUITY_BID,
  payload: toggle,
});

export const setShowAllQuotes = (toggle) => ({
  type: annuityBiddingActionTypes.SET_SHOW_ALL_QUOTES,
  payload: toggle,
});

export const setShowAnnuityQuotes = (toggle) => ({
  type: annuityBiddingActionTypes.SET_SHOW_ANNUITY_QUOTES,
  payload: toggle,
});

export const startAnnuityBiddingSave = (values) => ({
  type: annuityBiddingActionTypes.START_ANNUITY_BIDDING_SAVE,
  payload: values,
});

export const annuityBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const annuityBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAnnuityBiddingUpdate = (id, formData) => ({
  type: annuityBiddingActionTypes.START_ANNUITY_BIDDING_UPDATE,
  payload: { id, formData },
});

export const annuityBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const annuityBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

