import React, { useEffect } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  startLoadingCarriersFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectCarriers,
} from '../../redux/agent/agent.selectors';

const Carriers = ({
  carriers,
  startLoadingCarriersFromDB,
}) => {

  useEffect(() => {
    if (carriers && carriers.length === 0)
    startLoadingCarriersFromDB();
  }, [carriers, startLoadingCarriersFromDB])

  return (
    <section id='about-section' className='py-5'>
      <Container>
        <Row>
          <Col md={12}>
            <h1 className='text-center p-4'>Carrier List</h1>
            <div className='pb-5 mb-4'>
              {carriers && carriers.length > 0 ? (
                <>
                  {carriers.map((c, i) => (
                    <p key={i}>{c.value}</p>                  
                  ))}
                </>
              ) : null}
              <p className='py-2'></p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  carriers: selectCarriers,
});

export default connect(mapStateToProps, {
  startLoadingCarriersFromDB,
})(Carriers);