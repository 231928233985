import { Form as FormikForm, withFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import PersonalDetailsSchema from './validations/personal-details.validation.schema';
import memberFormData from './form-data/member-data';
import { calculateAge } from '../../redux/member/member.utils';
import { useHistory } from 'react-router-dom';
import Identification from '../../components/member/member-identification.component';
import PersonalDetails from '../../components/member/member-personal-details.component';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  setFormData,
  startMemberUpdate,
} from '../../redux/member/member.actions';
import {
  selectReset,
  selectFormData,
  selectPrimeMember,
} from '../../redux/member/member.selectors';
import ErrorMessages from '../../components/error-message/error-messages.component';

const MemberPersonalDetails = ({
  values,
  touched,
  errors,
  reset,
  isSubmitting,
  handleSubmit,
  handleChange,
  setFormData,
}) => {
  const formikProps = { values, handleChange, touched, errors, reset };
  const {
    form: { button },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const MPath = 'member-information';

  const [isAdult, setIsAdult] = useState(false);

  useEffect(() => {
    if (values) {
      setFormData(values);
    }
  }, [
    values,
    setFormData,
  ])

  useEffect(() => {
    if (values.dateOfBirth) {
      let dt = values.dateOfBirth;
      let age = calculateAge(dt);
      if (dt.length !== 0) {
        if (age >= 15) {
          setIsAdult(true);
        } else if (age < 15) {
          setIsAdult(false);
        }
      }
    }
  }, [
    isAdult,
    setIsAdult,
    values,
  ])
  
  return (
    <section className='pb-4'>
      <Container className={path !== MPath ? 'px-0' : ''}> 
        <FormikForm noValidate>
          <PersonalDetails {...formikProps} />
          {isAdult ? <Identification {...formikProps} isAdult={isAdult} /> : null}

          <div className='float-right pb-5'>
            <ErrorMessages errors={errors} />
            <div className='float-right'>
              <Button disabled={isSubmitting} onClick={handleSubmit}>
                {button.next}
              </Button>
            </div>
          </div>
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikMemberPersonalDetails = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({
    data,
    primeMember,
    currentUser
  }) => (
    memberFormData(data, primeMember, currentUser, false)
  ),
  validationSchema: PersonalDetailsSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: {
      increment,
      primeMember,
      startMemberUpdate,
    }, setSubmitting }
  ) => {
    setSubmitting(true);

    if (primeMember && primeMember._id) {
      startMemberUpdate(primeMember._id, values);
    }

    setSubmitting(false);
    increment();
    window.scrollTo({ top: 0 });
  },
})(MemberPersonalDetails);

const mapStateToProps = createStructuredSelector({
  reset: selectReset,
  data: selectFormData,
  currentUser: selectUser,
  primeMember: selectPrimeMember,
});

export default withRouter(
  connect(mapStateToProps, {
    setFormData,
    startMemberUpdate,
  })(FormikMemberPersonalDetails)
);
