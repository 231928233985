import { createSelector } from 'reselect';

const selectMemberR = (state) => state.member;

export const selectMembers = createSelector(
  [selectMemberR],
  (member) => member.membersFromDB
);

export const selectPrimeMember = createSelector(
  [selectMemberR],
  (member) => member.primeMember
);

export const selectMember = createSelector(
  [selectMemberR],
  (member) => member.member
);

export const selectIsAdult = createSelector(
  [selectMemberR],
  (member) => member.isAdult
);

export const selectStates = createSelector(
  [selectMemberR],
  (member) => member.statesFromDB
);

export const selectShowMemberForm = createSelector(
  [selectMemberR],
  (member) => member.showMemberForm
);

export const selectShowMemberTypes = createSelector(
  [selectMemberR],
  (member) => member.showMemberTypes
);

export const selectShowMemberInfoEdit = createSelector(
  [selectMemberR],
  (member) => member.showMemberInfoEdit
);

export const selectOccupations = createSelector(
  [selectMemberR],
  (member) => member.occupationsFromDB
);

export const selectIsSubmitted = createSelector(
  [selectMemberR],
  (member) => member.isSubmitted
);

export const selectIsUpdated = createSelector(
  [selectMemberR],
  (member) => member.isUpdated
);

export const selectIsMember = createSelector(
  [selectMemberR],
  (member) => member.isMember
);

export const selectClearMemberForm = createSelector(
  [selectMemberR],
  (member) => member.clearMemberForm
);

export const selectReset = createSelector(
  [selectMemberR],
  (member) => member.reset
);

export const selectFormData = createSelector(
  [selectMemberR],
  (member) => member.formData
)

export const selectCurrentStep = createSelector(
  [selectMemberR],
  (member) => member.currentStep,
)

export const selectStartPhoneVerify = createSelector(
  [selectMemberR],
  (member) => member.startPhoneVerify
)

export const selectIsPhoneVerified = createSelector(
  [selectMemberR],
  (member) => member.isPhoneVerified
)

export const selectSendPhoneErr = createSelector(
  [selectMemberR],
  (member) => member.sendPhoneErr
)

export const selectCodePhoneErr = createSelector(
  [selectMemberR],
  (member) => member.codePhoneErr
)

