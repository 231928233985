import { Form as FormikForm, withFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  clearErrMessage,
  startRegistration,
} from '../../redux/auth/auth.actions';
import { selectDisplayMessage } from '../../redux/auth/auth.selectors';
import { toggleLoginRegistrationView } from '../../redux/navigation/navigation.actions';
import { selectIsInitiatedFromAgentShowcase } from '../../redux/navigation/navigation.selectors';
import PasswordRequirements from './password.requirements.component';
import MemberTermsPrivacy from '../terms-privacy/member-terms-privacy.component';
import AgentTermsPrivacy from '../terms-privacy/agent-terms-privacy.component';
import { REGISTRATION_CMS_DATA } from './registration.cms';
import registrationSchema from './registration.validation.schema';
import { useHistory } from 'react-router-dom';
import chrome from '../login/chrome-icon.webp';

const Registration = ({
  values,
  displayMessage,
  touched,
  errors,
  handleChange,
  handleSubmit,
  toggleLoginRegistrationView,
  clearErrMessage,
}) => {
  const { email, password, label, action } = REGISTRATION_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname;
  const blankHP = '/';
  const memberHP = '/member-home';
  const agentHP = '/agent-home';

  const [show, setShow] = useState(false);

  const handleClick = () => {
    clearErrMessage();
    toggleLoginRegistrationView('login');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
  return (
    <Card className=
      {
        path === agentHP
          ? 'mt-4 text-dark login-card'
          : 'text-dark login-card'
      }
    >
      <Card.Body>
        <FormikForm>
            <p className='pb-1 text-center text-d-pink font-sixe-l font-weight-normal'>
              <img
                alt='Chrome'
                src={chrome}
                height='34'
                width='34'
                className='mr-2 google-icon'
            />
              {path === agentHP ? (
                'Please use Chrome web browser!'
              ) : (
                'Recommend using Chrome web browser!'
              )} 
            </p>
          <Form.Group controlId='regsitrationEmail'>
            <Form.Label>{email.label}</Form.Label>
            <Form.Control
              // readOnly={path !== agentHP ? true : false}
              type='email'
              name='email'
              className='login-border'
              placeholder={email.placeholder}
              value={values.email}
              onChange={handleChange}
              isValid={touched.email && !errors.email}
              isInvalid={!!errors.email}
              onClick={() => clearErrMessage()}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.email}
            </Form.Control.Feedback>
            {displayMessage ? (
              <div className='error_message'>{displayMessage}</div>
            ) : null}
          </Form.Group>
          <Form.Group controlId='regsitrationPassword'>
            <Form.Label>{password.label}</Form.Label>
            <Form.Control
              // readOnly={path !== agentHP ? true : false}
              type='password'
              name='password'
              className='login-border'
              placeholder={password.placeholder}
              value={values.password}
              onChange={handleChange}
              isValid={touched.password && !errors.password}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.password}
            </Form.Control.Feedback>
            <PasswordRequirements data={password.rules} />
          </Form.Group>
          <Form.Text>
            {label.tou}{' '}
              <Link
                to={path}
                className='text-pink'
                onClick={() => {
                  setShow(true);
                }}
                // className='text-light border-bottom text-decoration-none'
              >
                {action.tou}
              </Link>
          </Form.Text>
          <br />
          <Button
            block
            onClick={() => handleSubmit()}
          >
            {action.register}
          </Button>
          <br />
          <Form.Text className='text-center'>
            {label.login}{' '}
            <Link
              to={useLocation().pathname}
              onClick={handleClick}
              className='text-pink'
            >
              {action.login}
            </Link>
          </Form.Text>
        </FormikForm>
      </Card.Body>
      {(path === memberHP ||
        path === blankHP) &&
        show ? (
        <MemberTermsPrivacy
          show={show}
          setShow={setShow}
        />
      ) : null}
      {path === agentHP && show ? (
        <AgentTermsPrivacy
          show={show}
          setShow={setShow}
        />
      ) : null}
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  displayMessage: selectDisplayMessage,
  isInitiatedFromAgentShowcase: selectIsInitiatedFromAgentShowcase,
});

// use HOC withFormik() to
// create a formik component wrapping the Registration component
const FormikRegistration = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema: registrationSchema,
  handleSubmit: (
    { email, password },
    {
      props: { startRegistration, isInitiatedFromAgentShowcase },
      resetForm,
      setSubmitting,
    }
  ) => {
    setSubmitting(true);
    startRegistration({
      email,
      password,
      isAgent: isInitiatedFromAgentShowcase,
      isMember: true,
    });
    resetForm();
    setSubmitting(false);
  },
})(Registration);

// wrap formik component with redux functions
export default withRouter(
  connect(mapStateToProps, {
    startRegistration,
    toggleLoginRegistrationView,
    clearErrMessage,
  })(FormikRegistration)
);
