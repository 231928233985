import healthBiddingActionTypes from './health-bidding.types';
import authActionTypes from '../../auth/auth.types';

let INITIAL_STATE = {
  showHealthQuotes: true,
  isHealthSelectedByAi: false,
  isSubmit: false,
  isHealthBid: false,
  reset: false,
};

// let PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));

// if (PERSISTED_STATE) {
//   INITIAL_STATE = JSON.parse(PERSISTED_STATE.agent);
// }

const healthBiddingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case healthBiddingActionTypes.SET_IS_SUBMIT:
      return {
        ...state,
        isSubmit: action.payload,
      };
    case healthBiddingActionTypes.SET_IS_HEALTH_BID:
      return {
        ...state,
        isAnnuityBid: action.payload,
      };
    case healthBiddingActionTypes.SET_IS_HEALTH_SELECTED_BY_AI:
      return {
        ...state,
        isAnnuitySelectedByAi: action.payload,
      };
    case healthBiddingActionTypes.SET_SHOW_HEALTH_QUOTES:
      return {
        ...state,
        showAnnuityQuotes: action.payload,
      };
    case healthBiddingActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        showHealthQuotes: true,
        isHealthSelectedByAi: false,
        isSubmit: false,
        isHealthBid: false,
        reset: false,
      };
    default:
      return state;
  }
};

export default healthBiddingReducer;
