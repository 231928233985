import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';

export const convertAgentForDBSave = (data) => {
  const {
    form: {
      identification,
      carriers,
      servicesProvidedTo,
    },
  } = AGENT_CMS_DATA;

  const documentTypes = identification.label.dropdown.documentTypes;
  const captive = carriers.label.dropdown;
  const services = servicesProvidedTo.dropdown;

  const payload = {
    details: {
      firstName: data.firstName ? data.firstName : '',
      middleName: data.middleName ? data.middleName : '',
      lastName: data.lastName ? data.lastName : '',
      email: data.email ? data.email : '',
      phoneNumber: data.phoneNumber ? data.phoneNumber : '',
      isPhoneValid: data.isPhoneValid ? data.isPhoneValid : false,
      dateOfBirth: data.dateOfBirth ? data.dateOfBirth : '',
      agency: data.agencyName ? data.agencyName : '',
    },
    address: {
      streetAddress: data.BStreet ? data.BStreet : '',
      city: data.BCity ? data.BCity : '',
      state: data.BState ? data.BState : '',
      zipCode: data.BZipCode ? data.BZipCode : '',
    },
    identification: {
      driversLicense: data.documentType === documentTypes[0].value,
      stateID: data.documentType === documentTypes[1].value,
      passport: data.documentType === documentTypes[2].value,
      state: data.documentIssueState ? data.documentIssueState : '',
      number: data.documentNumber ? data.documentNumber : '',
      expirationDate: data.expiration ? data.expiration : '',
    },
    licenses: data.licenses ? data.licenses : [],
    nationalProducerNumber: data.nationalProducerNumber ? data.nationalProducerNumber : '',
    ssn: data.ssn ? data.ssn : '',
    verifiedAgent: data.verifiedAgent ? data.verifiedAgent : false,
    isFeePaid: data.isFeePaid ? data.isFeePaid : false,
    isCaptive: {
      isTypeOf:
        data.isCaptive
          ? data.isCaptive === captive[0].value
          : false,
      carriers: data.carriers ? data.carriers : [],
    },
    policies: {
      pAndC: data.pAndC ? data.pAndC : [],
      lAndH: data.lAndH ? data.lAndH : [],
    },
    servicesProvidedTo: {
      anyOne: data.applyTo === services[0].value,
      military: data.applyTo === services[1].value,
      specificOccupations: {
        isTypeOf:
          data.applyTo !== null
            ? data.applyTo === services[2].value
            : 'false',
        occupations:
          data.occupations && data.applyTo === services[2].value
            ? data.occupations
            : []
      },
      militaryOccupations: {
        isTypeOf:
          data.applyTo !== null
            ? data.applyTo === services[3].value
            : 'false',
        occupations:
          data.occupations && data.applyTo === services[3].value
            ? data.occupations
            : []
      }
    },
    veriff: data.veriff ? data.veriff : [],
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    carriersAddresses: data.carriersAddresses ? data.carriersAddresses : {},
    isAgentLastStep: data.isAgentLastStep ? data.isAgentLastStep : false,
  };

  return payload;
};