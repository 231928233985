export const MEMBER_VEHICLE_POLICY_CMS_DATA = {
  heading: 'My Vehicle Policy',
  subHeading: 'Enter the information pertaining to each vehicle on a single policy; if on a separate policy, you\'ll be able to and want to fill out another form for each policy. Agents will be required to fill in all the information but you are only required to enter the Vehicle(s) and related Documents to store; feel free to enter any other pertinent information you\'d like.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
    },
    vehicleDetails: {
      title: 'Vehicles on Policy',
      description: 'Please enter all the vehicles you have on this policy by either selecting from "Stored vehicles" or selecting "Add Another," starting with the VIN. This is how the policy will be referenced.',
      label: {
        vehiclesInDB: 'Stored vehicles',
        vin: 'VIN',
        year: 'Year',
        make: 'Make',
        model: 'Model',
        subModel: 'Sub-model',
      },
    },
    specs: {
      title: 'Specifications',
      description: '',
    },
    documents: {
      title: 'Documents',
      description: 'Here is where you can upload documents related to the policy, like declaration pages, full policy coverage, photos of vehicle, etc. Only upload documents pertinent to the policy.',
      label: 'Upload your documents and images'
    },
    effectiveDates: {
      title: 'Effective Dates',
      description: 'This is the date the policy began or begins and when it is up for renewal.',
      label: {
        start: 'Policy start date',
        end: 'Policy end date',
      }      
    },
    bodyInjuryLL: {
      title: 'Bodily Injury Liability Limits',
      description: 'This is the amount of liability coverage available for each individual and the total amount available for each incident. This is in case you are at fault.',
      label: {
        eachPerson: 'Each person',
        eachOccurrence: 'Each Occurrence'
      }      
    },
    propertyDLL: {
      title: 'Property Damage',
      description: 'This is the total amount of property coverage available for each incident. This is in case you are at fault.',
      label: 'Property Damage Liability Limits'
    },
    uninsuredMBIP: {
      title: 'Uninsured/Underinsured Motorist Bodily Injury Protection',
      description: 'This is the amount of liability coverage available for each individual and the total amount available for each incident. This is in case you are NOT at fault but the party at fault didn\'t present coverage.',
      label: {
        eachPerson: 'Each person',
        eachOccurrence: 'Each Occurrence'
      }
    },
    ownPropertyDL: {
      title: 'Own Property Damage Limits',
      description: 'This is the total amount of property coverage available for each incident, in case you are NOT at fault but the party at fault didn\'t present enough coverage.',
      label: 'Own Property Damage Limits'
    },
    deductibles: {
      title: 'Deductibles',
      description: 'Deductibles are the amounts that you would pay before the insurance Carrier covers their responsibilty. Usually, this amount is subtracted from the amount that the insurance carrier pays out to you.',
      label: {
        collision: 'Collision',
        other: 'Other than Collision / Comprehensive'
      },
    },
    medicalCoverage: {
      title: 'Medical Coverage',
      description: "Helps with injuries while in or around your vehicle that didn't involve a collision.",
      label: {
        coverage: 'Medical coverage',
        value: 'How much?'
      },
      dropdown: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
      ]
    },
    personalIP: {
      title: 'Personal Injury Protection',
      description: "Helps with injuries or loss of money from an injury while in or around your vehicle that didn't involve a collision.",
      label: {
        protection: 'Personal injury protection',
        value: 'How much?'
      },
      dropdown: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
      ]
    },
    additionalInfo: {
      title: 'Additional Information',
      description: 'Any other pertinent information about the policy, including highlights unique to this Carrier, additional endorcements, etc.',
      label: {
        additionalInfo: 'Please provide additional information'
      }
    } 
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
    occupations:
      'There was an error loading occupations from database. Please try again later',
  },
  modal: {
    heading: 'Do you want to delete',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    content: {
      bodyInjuryLL: {
        eachPerson: "This value is the maximum amount of liability coverage you have towards any individual that you may have harmed per accident (if you are at fault). If an individual's injuries or settlements exceeds this amount, you are responsible for the remaining cost.",
        eachOccurrence: "This value is the maximum amount of liability coverage you have towards anyone you may have harmed in the accident, regardless of how many are involved (if you are at fault). If the total of injuries or settlements exceeds this amount, you are responsible for the remaining cost."
      },
      uninsuredMBIP: {
        eachPerson: "This value is the maximum amount of coverage you have for any individual that was accompanying you per accident (if you are NOT at fault). This is for incidents that were someone else's fault but they didn't provide any or enough coverage for you or others with you or for uncontrolled circumstances, like something falling on the vehicle.",
        eachOccurrence: "This value is the maximum amount of coverage you have for all individuals that was accompanying you per accident, regardless of how many are involved (if you are NOT at fault). This is for incidents that were someone else's fault but they didn't provide any or enough coverage for you or others with you or for uncontrolled circumstances, like something falling on the vehicle."
      },
      propertyDLL: "This value is the maximum amount of liability coverage you have towards anyone elses property that you might have damaged, including their vehicle (if you are at fault).",
      ownPropertyDL: "This value is the maximum amount of coverage you have for your own property. Fault does NOT matter for this coverage and it can used for any damage to your own property if the vehicle being covered was involved.",
      deductibles: {
        collision: "This is the amount that you must cover before the insurance takes over for an incident that is your fault. Typically, the insurance carrier deducts this amount from what they pay you.",
        other: "This is the amount that you must cover before the insurance takes over for an incident that is NOT your fault or for uncontrolled circumstances, like weather damage. Typically, the insurance carrier deducts this amount from what they pay you.",
      },
      medCoverage: 'This is additional coverage that would go towards medical expenses for any injury that occured in or around the vehicle, without question. Good for taking care of Medical insurance deductibles.',
      personalIP: 'Similar to Medical Coverage, this is additional coverage that would go towards medical expenses for any injury that occured in or around the vehicle as well as many other expenses, like lost income, childcare, etc., without question. Keep in mind that this will cover medical costs first so, if you go this route, make sure that it exceeds your Medical insurance deductible.',
    },
  },
  readOnlyTitles: {
    effectiveDates: {
      dates: [
        {
          key: 'start',
          title: 'Start date',
          dataType: 'date',
          value: ''
        },
        {
          key: 'end',
          title: 'End date',
          dataType: 'date',
          value: ''
        },
      ]
    },
    bodyInjuryLL: {
      numbers: [
        {
          key: 'eachPerson',
          title: 'Each person',
          dataType: 'number',
          info: "This value is the maximum amount of liability coverage you have towards any individual that you may have harmed per accident (if you are at fault). If an individual's injuries or settlements exceeds this amount, you are responsible for the remaining cost.",
          value: ''
        },
        {
          key: 'eachOccurrence',
          title: 'Each occurrence',
          dataType: 'number',
          info: "This value is the maximum amount of liability coverage you have towards anyone you may have harmed in the accident, regardless of how many are involved (if you are at fault). If the total of injuries or settlements exceeds this amount, you are responsible for the remaining cost.",
          value: ''
        },
      ]
    },
    uninsuredBIP: {
      numbers: [
        {
          key: 'eachPerson',
          title: 'Each person',
          dataType: 'number',
          info: "This value is the maximum amount of coverage you have for any individual that was accompanying you per accident (if you are NOT at fault). This is for incidents that were someone else's fault but they didn't provide any or enough coverage for you or others with you or for uncontrolled circumstances, like something falling on the vehicle.",
          value: ''
        },
        {
          key: 'eachOccurrence',
          title: 'Each occurrence',
          dataType: 'number',
          info: "This value is the maximum amount of coverage you have for all individuals that was accompanying you per accident, regardless of how many are involved (if you are NOT at fault). This is for incidents that were someone else's fault but they didn't provide any or enough coverage for you or others with you or for uncontrolled circumstances, like something falling on the vehicle.",
          value: ''
        },
      ]
    },
    deductibles: {
      numbers: [
        {
          key: 'collision',
          title: 'Collision',
          dataType: 'number',
          info: "This is the amount that you must cover before the insurance takes over for an incident that is your fault. Typically, the insurance carrier deducts this amount from what they pay you.",
          value: ''
        },
        {
          key: 'other',
          title: 'Other',
          dataType: 'number',
          info: "This is the amount that you must cover before the insurance takes over for an incident that is NOT your fault or for uncontrolled circumstances, like weather damage. Typically, the insurance carrier deducts this amount from what they pay you.",
          value: ''
        },
      ],
    }
  }
}
  
  


