import React from 'react'

const AgentPrivacy = () => {
  return (
    <>
      <h3 className="mt-4 mb-5 text-center">
        InsurEasier Privacy Policy for Agents
      </h3>
      <p>
        This <span className="font-weight-bold">Privacy Policy for Agents</span>{' '}
        outlines the collection, usage, disclosure, and protection of both your
        data and the data of users who interact with the InsurEasier platform.
        By using the InsurEasier website, portal, or affiliated services, you
        agree to the practices outlined in this policy.
      </p>
      <p className="mt-4">
        <span className="font-weight-bold">Table of Contents (Summary) –</span>{' '}
        Please note that this Table of Contents is provided solely for
        convenience and as a brief overview. You are required to comply with the
        complete and detailed Privacy Policy outlined in the sections below.
      </p>
      <p className="font-weight-bold">1. Definitions</p>
      <p>
        <span className="font-weight-bold">2. Data Collected -</span> Data
        collection is subject to the limitations and guidelines.
      </p>
      <p>
        <span className="font-weight-bold">3. Verification of Identity - </span>
        We reserve the right to verify your identity and relevant credentials
        using the Personal Data and other verification processes.
      </p>
      <p>
        <span className="font-weight-bold">
          4. Data Access and Permissions -{' '}
        </span>
        You may share data only with carriers directly involved in providing
        quotes or policies. Consequences for not complying.
      </p>
      <p>
        <span className="font-weight-bold">5. Usage of Data - </span> Data from
        users is strictly for insurance-related purposes.
      </p>
      <p>
        <span className="font-weight-bold">
          6. Data Security and Protection -
        </span>{' '}
        InsurEasier takes the protection of both agent and user data seriously.
        We expect agents to take security very seriously as well.
      </p>
      <p>
        <span className="font-weight-bold">7. Cookie Usage Policy -</span>{' '}
        InsurEasier would only implements cookies to enhance user experience and
        not for outside spam. You will be informed of any changes.
      </p>
      <p>
        <span className="font-weight-bold">
          8. Data Sharing and Distribution -
        </span>{' '}
        Consequences for not properly disposing of Data or sharing with third
        parties other than carriers for insurance.
      </p>
      <p>
        <span className="font-weight-bold">
          9. Electronic Communications Consent -
        </span>{' '}
        You consent to communications from us.
      </p>
      <p>
        <span className="font-weight-bold">10. Phone Number Usage -</span> You
        agree to receive text messages or phone calls.
      </p>
      <p>
        <span className="font-weight-bold">11. Data Retention Policy -</span>{' '}
        InsurEasier retains your Personal Data for as long as necessary. Once
        data is no longer needed, it will be securely disposed of.
      </p>
      <p>
        <span className="font-weight-bold">
          12. Record-Keeping and Audits -
        </span>{' '}
        Agents must maintain accurate records for audit.
      </p>
      <p className="font-weight-bold">13. International Data Transfers</p>
      <ul>
        <li>
          a. If accessing this site from outside the US, you consent to the
          transfer, storage, and processing of your Personal Data in the US.
        </li>
        <li>
          b. Data may be transferred internationally as required for the purpose
          of providing quotes and policies.
        </li>
      </ul>
      <p>
        <span className="font-weight-bold">14. Children’s Privacy -</span> No
        individuals under the age of 18, unless authorized.
      </p>
      <p>
        <span className="font-weight-bold">
          15. Third-Party Data Processors -
        </span>{' '}
        We may use third-party services to assist.
      </p>
      <p>
        <span className="font-weight-bold">
          16. Your Data Protection Rights -
        </span>{' '}
        Your rights regarding your Personal Data.
      </p>
      <p>
        <span className="font-weight-bold">17. Data Breach Notification -</span>{' '}
        Steps taken in the event of a data breach.
      </p>
      <p>
        <span className="font-weight-bold">
          18. Third-Party Websites and Services -
        </span>{' '}
        We are not responsible for the privacy practices of third parties.
        Review their respective privacy policies.
      </p>
      <p>
        <span className="font-weight-bold">
          19. Consent to Terms of Service -
        </span>{' '}
        You agree to InsurEasier’s Terms of Service.
      </p>
      <p>
        <span className="font-weight-bold">
          20. Consent to Privacy Policy -
        </span>{' '}
        You consent to this Privacy Policy.
      </p>
      <p>
        <span className="font-weight-bold">21. Changes to Policy -</span>{' '}
        InsurEasier has the right to amend or modify.
      </p>
      <p className="mt-5">
        <span className="font-weight-bold">
          Complete Privacy Policy (Detailed) –
        </span>{' '}
        Please refer below for the complete Privacy Policy.
      </p>
      <div>
        <p className="font-weight-bold mb-0">1. Definitions</p>
        <ul>
          <li>
            <span className="font-weight-bold">
              a. “InsurEasier,” “we,” “us,” and “our”
            </span>{' '}
            refer to InsurEasier, LLC, its affiliates, subsidiaries, designated
            management, officers, employees, and authorized representatives.
          </li>
          <li>
            <span className="font-weight-bold">
              b. “InsurEasier’s site,” “this site,” or “our site”
            </span>{' '}
            refers to all web pages, services, and affiliated platforms provided
            under the domain
            <a href="www.insureasier.com"> www.insureasier.com</a>, or any other
            affiliated websites operated by InsurEasier, LLC, including sites
            used for data collection, storage, and agent or user interactions.
          </li>
          <li>
            <span className="font-weight-bold">
              c. “Agent(s),” “you,” and “your”
            </span>{' '}
            refer to any individual, agent, agency, broker, producer, or other
            entity that accesses or utilizes the InsurEasier site, especially
            within the agent portal, whether authorized or unauthorized.
            Unauthorized access will result in immediate termination, fines, and
            potential legal action.
          </li>
          <li>
            <span className="font-weight-bold">d. “User”</span> refers to any
            individual, group, or entity that accesses, references, visits, or
            utilizes the InsurEasier site, particularly within the member
            portal, including any party storing information or requesting quotes
            through the site.
          </li>
          <li>
            <span className="font-weight-bold">e. “Member” or “Members”</span>{' '}
            refer to any user who has registered and gained access to the member
            portal of the InsurEasier site.
          </li>
          <li>
            <span className="font-weight-bold">f. “Data”</span> encompasses any
            information collected, stored, used, or generated through this site,
            in any form, whether written, coded, in media, digital, or other
            forms, and includes Personal Data.
          </li>
          <li>
            <span className="font-weight-bold">g. “Personal Data”</span> refers
            to any Data that can directly or indirectly identify you, the agent,
            or any user, including but not limited to name, contact information,
            financial details, insurance-related information, or other
            identifying characteristics.
          </li>
          <li>
            <span className="font-weight-bold">h. “Carrier”</span> refers to an
            insurance company or provider from which quotes and policies may be
            issued.
          </li>
          <li>
            <span className="font-weight-bold">i. “Quotes Request”</span> means
            a request submitted by a member to obtain insurance quotes via
            InsurEasier for a specific type of insurance.
          </li>
        </ul>
      </div>
      <div>
        <p className="font-weight-bold mb-0">2. Data Collected</p>
        <p>
          InsurEasier collects and processes data that agents input into the
          system, including user data necessary for generating insurance quotes
          or creating policies. You may be required to provide additional data
          relevant to your role as an agent, which may include, but is not
          limited to, policy data, personal data of users, and insurance-related
          information. Data collection is subject to the limitations and
          guidelines outlined in Section 7 of our Terms and Conditions.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">3. Verification of Identity</p>
        <p>
          We reserve the right to verify your identity and relevant credentials
          using the Personal Data you provide. InsurEasier will confirm your
          status as an active, licensed agent before granting access to the
          agent portal. Should you fail to provide valid information, access may
          be restricted, and further actions, including legal action, may be
          pursued.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">4.Data Access and Permissions</p>
        <p>
          Upon being selected by a user to provide quotes or policies, you are
          granted access to the user’s relevant Personal Data, including but not
          limited to, driving records (e.g., CLUE for Auto and Home, medical
          records for Life and Health insurance). You may only share this data
          with carriers directly involved in providing quotes or policies. Any
          further use of data outside the express purpose of fulfilling the
          user’s requests is strictly prohibited and subject to penalties as
          outlined in Section 8 of this policy. By accessing user data, you
          agree to abide by all privacy and data protection laws applicable in
          your jurisdiction. Agents are responsible for ensuring they obtain
          proper user consent for any further use or sharing of their data
          outside of this site. Users retain the right to access, correct, or
          delete their personal information. Agents must accommodate any user
          requests related to data access, correction, or deletion in compliance
          with applicable laws, and notify InsurEasier immediately upon
          receiving such requests.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">5. Usage of Data</p>
        <p>
          Data collected from users is strictly for the purpose of generating
          insurance quotes, creating policies, and facilitating agent-user
          interactions on the InsurEasier platform. Data stored in the system
          may be referenced by agents only for the insurance-related services
          specified and must be updated as necessary to ensure accuracy.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">6. Data Security and Protection</p>
        <p>
          InsurEasier takes the protection of both agent and user data
          seriously. We implement a combination of encryption protocols (such as
          SSL/TLS for data in transit and AES for data at rest), firewalls, and
          secure access controls to safeguard all Personal Data. Agents are
          responsible for maintaining secure access to the platform and must
          utilize robust security practices, including the use of strong
          passwords and secure devices. Agents are required to report any
          security breaches or suspicious activities related to user data
          immediately to InsurEasier.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">7. Cookie Usage Policy</p>
        <ul>
          <li>
            <span className="font-weight-bold">a. What Are Cookies?</span>{' '}
            Cookies are small text files stored on your device (computer,
            tablet, smartphone) when you visit our website. They help us to
            recognize your device, remember your preferences, and enhance your
            overall user experience.
          </li>
          <li>
            <span className="font-weight-bold">
              b. How We Might Use Cookies
            </span>{' '}
            InsurEasier uses cookies to:
            <ul>
              <li>
                <span className="font-weight-bold">i.</span> Improve website
                functionality and performance
              </li>
              <li>
                <span className="font-weight-bold">ii.</span> Remember your
                preferences and settings
              </li>
              <li>
                <span className="font-weight-bold">iii.</span> Analyze website
                traffic and usage patterns
              </li>
              <li>
                <span className="font-weight-bold">iv.</span> Enhance security
                and prevent fraudulent activities
              </li>
            </ul>
          </li>
          <li>
            <span className="font-weight-bold">c.</span> We do not use cookies
            to collect personally identifiable information without your explicit
            consent.
          </li>
          <li>
            <span className="font-weight-bold">
              d. Types of Cookies We Might Use
            </span>
            <ul>
              <li>
                <span className="font-weight-bold">i. Essential Cookies:</span>{' '}
                These cookies are necessary for the website to function
                correctly, allowing you to navigate and use essential features.
              </li>
              <li>
                <span className="font-weight-bold">
                  ii. Performance Cookies:
                </span>{' '}
                These cookies help us understand how visitors interact with our
                site by collecting anonymous data on usage patterns. This
                information allows us to improve our website's functionality and
                performance.
              </li>
              <li>
                <span className="font-weight-bold">
                  iii. Functional Cookies:
                </span>{' '}
                These cookies enable the site to remember your preferences, such
                as language settings, to provide a more personalized experience.
              </li>
              <li>
                <span className="font-weight-bold">
                  iv. Advertising Cookies (if applicable):
                </span>{' '}
                These cookies may be used to deliver advertisements that are
                more relevant to you based on your interests. They may also
                limit the number of times you see an ad and help measure the
                effectiveness of advertising campaigns. We only use these
                internally and do not share them with third parties.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-weight-bold">
              e. Managing Your Cookie Preferences
            </span>{' '}
            You can manage or disable cookies at any time through your web
            browser settings. Please note that disabling cookies may affect the
            functionality and performance of the website, and certain features
            may not be available. For more information on how to manage or
            disable cookies, you can refer to your browser's help documentation
          </li>
          <li>
            InsurEasier only implements cookies solely to enhance user
            experience and not for outside spam. You will be informed of any
            changes in our cookie policy.
          </li>
        </ul>
      </div>
      <div>
        <p className="font-weight-bold mb-0">
          8. Data Sharing and Distribution
        </p>
        <p>
          InsurEasier does not sell or disclose Personal Data to third parties
          for purposes unrelated to the user’s insurance requests. Agents are
          solely responsible for sharing user data with carriers to the extent
          necessary to obtain quotes and policies. Once quotes have been
          generated or policies issued, you are required to discard and properly
          dispose of all user-related Personal Data no longer needed, following
          these guidelines:
          <ul>
            <li>
              <span className="font-weight-bold">- Within 3 days</span> of a
              user’s rejection of your quote;
            </li>
            <li>
              <span className="font-weight-bold">- Within 7 days</span> of a
              user’s acceptance of your quote for policy conversion.
            </li>
          </ul>
        </p>
        <p>
          Failure to properly handle user data or using user data for purposes
          other than those specified in this policy, will result in fines no
          less than $500 per occurrence and may lead to further legal action.
          InsurEasier reserves the right to notify relevant regulatory bodies,
          including state Departments of Insurance, of any misuse.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">
          9. Electronic Communications Consent
        </p>
        <p>
          By using this site, you consent to receive communications from us
          electronically, whether via email, text message, or site
          notifications. You agree that all agreements, notices, disclosures,
          and other communications we provide to you electronically satisfy any
          legal requirement that such communications be in writing.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">10. Phone Number Usage</p>
        <p>
          By providing your phone number, you consent to receive text messages
          or phone calls from InsurEasier regarding your Quotes Request, policy
          inquiries, or other information deemed necessary for your use of our
          site. These communications may include updates, guidance, or
          notifications pertinent to you, your account, or quote or policy
          management.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">11. Data Retention Policy</p>
        <p>
          InsurEasier retains your Personal Data for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, including legal,
          accounting, or reporting requirements. Once data is no longer needed,
          it will be securely deleted or anonymized in accordance with industry
          best practices.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">12. Record-Keeping and Audits</p>
        <p>
          Agents are required to maintain accurate records of all transactions
          and communications related to user data. InsurEasier reserves the
          right to audit agent records to ensure compliance with this policy and
          legal obligations, particularly in cases of disputes, complaints, or
          suspected misuse.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">
          13. International Data Transfers
        </p>
        <p>
          <ul>
            <li>
              <span className="font-weight-bold">c.</span> If you are accessing
              this site from outside the United States, you consent to the
              transfer, storage, and processing of your Personal Data in the
              United States. InsurEasier will take reasonable steps to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy.
            </li>
            <li>
              <span className="font-weight-bold">d.</span> Data may be
              transferred internationally as required for the purpose of
              providing quotes and policies. InsurEasier ensures and requires
              that any cross-border data transfers comply with applicable
              privacy laws, including implementing standard contractual clauses
              where required, especially under the General Data Protection
              Regulation (GDPR) for agents operating in the European Union.
            </li>
          </ul>
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">14. Children’s Privacy</p>
        <p>
          This site is not intended for use by individuals under the age of 18.
          InsurEasier does not knowingly collect Personal Data from children,
          unless provided by a parent or legal guardian or under their
          supervision or if the individual is authorized to do so legally, like
          in the case of a hardship. If we become aware that we have
          inadvertently collected Personal Data directly from a child under 18,
          without proper authorization, we will take steps to delete such
          information promptly. Agents are required to do the same and alert
          InsurEasier if such an incidence has occurred.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">15. Third-Party Data Processors</p>
        <p>
          InsurEasier may use third-party service providers to assist in
          providing our services, such as hosting, data storage, or payment
          processing. These third parties are contractually obligated to
          implement appropriate data protection measures and only process data
          on our behalf and according to our instructions.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">16. Your Data Protection Rights</p>
        <p>
          Depending on your jurisdiction, you may have certain rights regarding
          your Personal Data, including the right to access, correct, update, or
          delete your data, and the right to object to or restrict certain data
          processing activities. To exercise these rights, please contact us at
          support@insureasier.com.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">17. Data Breach Notification</p>
        <p>
          In the event of a data breach that affects your Personal Data,
          InsurEasier will notify you and the appropriate regulatory authorities
          as required by applicable law. Notification will include details of
          the breach, the potential impact, and the steps taken to mitigate any
          harm.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">
          18. Third-Party Websites and Services
        </p>
        <p>
          This Privacy Policy does not apply to any third-party websites or
          services linked to or from our site. InsurEasier is not responsible
          for the privacy practices of such third parties, and we encourage you
          to review their respective privacy policies.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">19. Consent to Terms of Service</p>
        <p>
          By using this site, you also agree to be bound by the InsurEasier
          Terms of Service, which govern your use of this site in conjunction
          with this Privacy Policy.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">20. Consent to Privacy Policy</p>
        <p>
          By accessing and using InsurEasier’s site, you consent to the
          practices described in this Privacy Policy and agree to the
          collection, use, and sharing of your Personal Data as outlined herein.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">21. Changes to Policy</p>
        <p>
          InsurEasier reserves the right to modify or update this Privacy Policy
          at any time. Any changes will be effective immediately upon posting,
          and we will provide notice of such changes by updating the “Effective
          Date” of this Policy or by sending a notification. Continued use of
          the site after any changes signifies your acceptance of the updated
          Privacy Policy.
        </p>
      </div>
      <div>
        <p className="font-weight-bold mb-0">22. Contact Information</p>
        <p>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or your Personal Data, please contact us
          at:InsurEasier, LLC
          <ul>
            <li>a. Address: P.O. Box 1122, Frisco, TX, 75034</li>
            <li>b. Email: support@insureasier.com</li>
            <li>c. Website: www.insureasier.com</li>
            <li>d. Phone number: 844-732-7437</li>
          </ul>
        </p>
      </div>
      <hr />
      <p>
        <span className="font-weight-bold">Effective Date: </span> September 27,
        2024
      </p>
    </>
  )
}

export default AgentPrivacy
