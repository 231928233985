import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import { useHistory } from 'react-router-dom';
import InfoIcon from '../info-icon/info-icon.component';
import RadioButton from '../radio-button/radio-button.component';

const LegalStatus = ({
  values,
  handleChange,
  touched,
  errors,
}) => {
  const {
    form: {
      legalStatus: { title, description, label },
    },
    popOver: { legalStatus: { content } },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <div>
            <Form.Row>
              <Form.Group as={Col} lg={7} controlId='legal-document-type'>
                <Form.Label>{label.legalDocumentType}</Form.Label>
                <InfoIcon
                  className='float-right'
                  content={content.docType}
                />
                <br/>
                {label.dropdown.legalDocumentTypes.map((option, i) => (
                  <RadioButton
                    key={i}
                    idx={i}
                    name='legalDocumentType'
                    option={option}
                  />
                ))}
                <Form.Control.Feedback type='invalid'>
                  {errors.legalDocumentType}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={5}
                control-id='legal-document-number'
                optional='true'
              >
                <Form.Label>{label.legalDocumentNumber}</Form.Label>
                <InfoIcon
                  className='float-right'
                  content={content.docNum}
                />
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='legalDocumentNumber'
                  onChange={handleChange}
                  value={values.legalDocumentNumber}
                  isValid={
                    touched.legalDocumentNumber && !errors.legalDocumentNumber
                  }
                  isInvalid={!!errors.legalDocumentNumber}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.legalDocumentNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LegalStatus;

