const memberPropertyPolicyActionTypes = {
  FETCH_PROPERTIES_FROM_DB_START: 'FETCH_PROPERTIES_FROM_DB_START',
  FETCH_PROPERTIES_FROM_DB_SUCCESS: 'FETCH_PROPERTIES_FROM_DB_SUCCESS',
  FETCH_PROPERTIES_FROM_DB_FAILURE: 'FETCH_PROPERTIES_FROM_DB_FAILURE',
  FETCH_PROPERTY_POLICY_FROM_DB_START: 'FETCH_PROPERTY_POLICY_FROM_DB_START',
  FETCH_PROPERTY_POLICY_FROM_DB_SUCCESS: 'FETCH_PROPERTY_POLICY_FROM_DB_SUCCESS',
  FETCH_PROPERTY_POLICY_FROM_DB_FAILURE: 'FETCH_PROPERTY_POLICY_FROM_DB_FAILURE',
  LOAD_STATES_PROPERTY_FROM_DB_START: 'LOAD_STATES_PROPERTY_FROM_DB_START',
  LOAD_STATES_PROPERTY_FROM_DB_SUCCESS: 'LOAD_STATES_PROPERTY_FROM_DB_SUCCESS',
  LOAD_STATES_PROPERTY_FROM_DB_FAILURE: 'LOAD_STATES_PROPERTY_FROM_DB_FAILURE',
  START_PROPERTY_POLICY_DELETE: 'START_PROPERTY_POLICY_DELETE',
  PROPERTY_POLICY_DELETE_SUCCESS: 'PROPERTY_POLICY_DELETE_SUCCESS',
  START_PROPERTY_POLICY_SAVE: 'START_PROPERTY_POLICY_SAVE',
  START_PROPERTY_POLICY_UPDATE: 'START_PROPERTY_POLICY_UPDATE',
  SET_CLEAR_FORM: 'SET_CLEAR_FORM',
  RESET_FORM: 'RESET_FORM',
}

export default memberPropertyPolicyActionTypes;