import React from 'react'
import Container from 'react-bootstrap/Container'
import { BENEFITS_CMS_DATA } from './benefits.cms'
import { Col, Row, Card, CardGroup } from 'react-bootstrap'

const Benefits = () => {
  const { benefits } = BENEFITS_CMS_DATA

  return (
    <section className="benefits-bg">
      <Container>
        <h2 className="display-b3 w-100 text-green">Benefits</h2>
        <Row>
          {benefits.map((benefit, i) => (
            <CardGroup key={i} as={Col} xs={12} md={6} lg={4} className="mb-4">
              <Card className="mb-1 product-card">
                <div className="d-flex align-items-center p-3 bg-title">
                  <Card.Img
                    src={require(`${benefit.imgUrl}`)}
                    alt={benefit.title}
                    width="40"
                    height="40"
                    className="w-12 p-2 bg-grey"
                  />
                  <h3 className="py-2 px-3 m-auto text-green font-size-xll font-weight-normal">
                    {benefit.title}
                  </h3>
                </div>
                <Card.Body>
                  <p className="text-left">{benefit.desc}</p>
                </Card.Body>
              </Card>
            </CardGroup>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Benefits
