import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import loadable from '@loadable/component';
import AgentPrivacy from './agent-privacy.component';
import AgentPatent from './agent-patent.component';

const AgentTerms = loadable(() => import('./agent-terms.component'));

const AgentTermsPrivacy = ({
  show,
  setShow,
}) => {

  const [showTerms, setShowTerms] = useState(true);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showPatent, setShowPatent] = useState(false);

  const handleClose = () => {
    if (show) {
      setShow(false);
    }
  }

  return (
    <Modal
      size='xl'
      show={show}
      dialogClassName='modal-90w'
      onHide={handleClose}
    >
      <Modal.Header className='rounded border-bottom-0'>
        <div className='d-flex justify-content-start'>
          <Button
            variant={showTerms ? 'warning' : 'primary'}
            className='mr-3'
            onClick={() => {
              setShowPrivacy(false)
              setShowTerms(!showTerms)
              setShowPatent(false)
            }}
          >
            {showTerms ? 'Hide terms' : 'Terms'}
          </Button>
          <Button
            variant={showPrivacy ? 'warning' : 'primary'}
            className='mr-3'
            onClick={() => {
              setShowPrivacy(!showPrivacy)
              setShowTerms(false)
              setShowPatent(false)
            }}
          >
            {showPrivacy ? 'Hide privacy' : 'Privacy'}
          </Button>
          <Button
            variant={showPatent ? 'warning' : 'primary'}
            className='mr-3'
            onClick={() => {
              setShowPrivacy(false)
              setShowTerms(false)
              setShowPatent(!showPatent)
            }}
          >
            {showPatent ? 'Hide patent' : 'Patent'}
          </Button>
        </div>
        <Button
          variant='light'
          className='float-right'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      {showTerms ? (
        <Modal.Body className='border-top border-grey'>
          <AgentTerms />
        </Modal.Body>
      ) : null}
      {showPrivacy ? (
        <Modal.Body className='border-top border-grey'>
          <AgentPrivacy />
        </Modal.Body>
      ) : null}
      {showPatent ? (
        <Modal.Body className='border-top border-grey'>
          <AgentPatent />
        </Modal.Body>
      ) : null}
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='outline-secondary'
          onClick={() => {
            setShow(false);
            setShowTerms(false);
            setShowPrivacy(false);
            setShowPatent(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AgentTermsPrivacy;