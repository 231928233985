export const ANNUITY_QUOTE_CMS_DATA = {
  form: {
    button: {
      next: 'Next',
      previous: 'Previous',
      submit: 'Submit',
      getQuotes: 'Get quotes',
    },
    annuity: {
      title: {
        member: 'Member Selection',
        annuityType: 'Annuity Type',
      },
      description: {
        member: `Please select the Household Member to be associated with this quote. Also, please use "Update existing" if any changes have occured to the Member being included. If you have not entered them yet, please "Add another."`,
        annuityType: 'Please select the type of Annuity you are wanting and answer the corresponding inquiries that will populate. Note that there\'s space below for any concerns, in case you want to be more specific or are not sure, etc.',
      },
      label: {
        member: 'Select member',
        annuityType: 'Select annuity type',
        investedAmt: 'Investment amount',
        downPay: 'Down payment',
        typeOfInvest: 'How would you like to invest?',
        varInv: 'Would you like to be involved in what investments are made?',
        notSureInfoAn: 'What is it that you are wanting from an Annuity?',
        addInfoAn: 'Are there any Riders, like Lifetime Income Benefit or Guaranteed Lifetime Withdrawal Benefit, that you would like to attach or any other requests or concerns?',
        notSureInfoInv: 'What are you wanting from an Annuity?',
        addInfoInv: 'Any other requests or concerns?',
      },
      dropdown: {
        annuityType: [
          { value: 'immediate', key: 'Immediate Income - This is if there is a lump sum of money that you want to invest to start paying out immediately on a monthly, quarterly, or yearly basis' },
          { value: 'deferred', key: 'Deferred (standard) - This is for future planning / retirement' },
          { value: 'notSure', key: 'Not Sure' },
        ],
        typeOfInvest: [
          { value: 'fixed', key: 'Fixed – Fixed rate of interest' },
          { value: 'indexed', key: 'Indexed – Guarantees a minimum rate but also caps the maximum interest' },
          { value: 'variable', key: 'Variable – No guaranteed minimum rate but no limit either' },
          { value: 'notSure', key: 'Not Sure' },
        ],
        varInv: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      }
    },
  },
  popOver: {
    annuityTypes: {
      content: {
        annuityType: "Immediate Income takes a lump sum that you want to invest and pays you a monthly, quarterly, or yearly rate, while the bulk of it is building interest; it's a way to extend that lump sum. Deferred is the typical retirement strategy, investing money towards your retirement. Nowadays, many have a face value that will pay out in case the Member passes away.",
        investedAmt: "This is a lump sum amount that you want to invest up front. You can always add more later for a larger monthly, quarterly, or yearly payout. The amount should not be below $50,000 but is typically, closer to $200,000 or more.",
        downPay: "This is an amount that you want to invest immediately, which can cut down on monthly payments or can greatly increase the pay out when the Annuity starts paying you monthly, quarterly, or yearly.",
        typeOfInvest: "This will determine how much risk you want to take with your investment. Fixed is the least risky and least expensive as the interest rate is set and does not fluctuate. An Indexed policy can fluctuate but will not go below or above set interest rates (e.g. some might not go below 3% interest but might be capped at 8% interest). Variable is completely reliant on the market and in which stocks are being invested in (usually S&P market). You have the choice of being involved in those investments or simply letting the Carrier decide.",
        varInv: "Do you want to be involved in what stocks are invested in? Carriers have formulas for what they invest in but you might have some insight yourself that you want to flaunt.",
        addInfoInv: 'Are there any Riders that you would like to attach, like a Lifetime Income Benefit - guarantees a minimum monthly, quarterly, or yearly payout for a Variable Annuity when the Annuity matures (set age or year to receive benefits) even if the account goes to $0; Guaranteed Lifetime Withdrawal Benefit - allows the Member to withdraw amounts from a Variable account before the maturity date; etc.? Or, are there any questions or concerns?',
        notSureInfoAn: "Consider expenses that you'll likely have on a monthly bases when you retire. Will you have a mortgage payment or other major payments? Do you want to be involved in the money you invest, etc.? What are your retirement plans, etc.? Any riders like a Lifetime Income Benefit - guarantees a minimum monthly, quarterly, or yearly payout for a Variable Annuity when the Annuity matures (set age or year to receive benefits) even if the account goes to $0; Guaranteed Lifetime Withdrawal Benefit - allows the Member to withdraw amounts from a Variable account before the maturity date; etc.?",
      }
    }
  }
}


