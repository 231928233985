import * as yup from 'yup';

const agentSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .required('Please enter first name'),
  middleName: yup
    .string()
    .strict()
    .trim()
    .min(1, 'Please enter at least one characters')
    .max(255, 'Please enter less than 255 characters')
    .optional(),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .required('Please enter last name'),
  phoneNumber: yup
    .string()
    .min(11, 'Please provide 11 digits')
    .max(12, 'Please provide no more than 12 digits')
    .required('Please enter a valid phone number'),
  dateOfBirth: yup.date().required('Please provide a date of birth'),
  agencyName: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .optional(),
  BStreet: yup
    .string()
    .trim()
    .min(0)
    .max(255, 'Please enter no more than 255 characters')
    .required('Please enter street address'),
  BCity: yup
    .string()
    .trim()
    .min(0)
    .max(255, 'Please enter no more than 255 characters')
    .required('Please add city'),
  BState: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .required('Please select state'),
  BZipCode: yup
    .number()
    .typeError('Please enter valid zip code')
    .min(1001, 'Please enter valid zip code')
    .max(99950, 'Please enter valid zip code')
    .required('Please enter zip code'),
  documentType: yup
    .string()
    .trim()
    .required('Please select valid document type'),
  documentNumber: yup
    .string()
    .trim()
    .min(0)
    .max(13, 'Please enter no more than 13 characters')
    .required('Please provide the document number'),
  expiration: yup.date().required('Please select document expiration date'),
  documentIssueState: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .when('documentType', {
      is: (val) => val !== 'passport',
      then: yup.string().required('Please select state'),
      otherwise: yup.string().notRequired(),
    }),
  licenses: yup
    .array()
    .of(
      yup.object().shape({
        state: yup
          .string()
          .trim()
          .min(2, 'Please enter at least two characters')
          .max(255, 'Please enter no more than 255 characters')
          .required('Please select state'),
        number: yup
          .number()
          .typeError('License number must be number')
          .max(9999999999999, 'Please enter no more than 9999999999999')
          .when('state', {
            is: (val) => val !== undefined,
            then: yup.number().required('Please enter license number'),
            otherwise: yup.number().notRequired(),
          }),
        })
    )
    .required('must have licenses'),
  nationalProducerNumber: yup
    .string()
    .trim()
    .min(0)
    .max(13, 'Please enter no more than 13 characters')
    .required('Please enter NPN'),
  ssn: yup
    .number()
    .min(0)
    .max(999999999, 'Please enter no more than 9 characters')
    .required('Please enter SSN'),
  isCaptive: yup.string().required('Please select yes or no'),
  carriersObj: yup
    .object()
    .required('CarriersObj must be requireed'),
  carriers: yup
    .array()
    .of(
      yup.object().shape({
        carrier: yup
          .mixed()
          .test(
            'match',
            'Please select a carrier from the provided list or contact us if you want to add a carrier, not on the list',
            function (carrier) {
              return this.from[1].value.carriersObj[carrier];
            })
      })
    )
    .when('isCaptive', {
      is: (val) => val !== undefined,
      then: yup.array().required('Please select at least one option'),
      otherwise: yup.array().notRequired(),
    })
    .required('must have carriers'),
  pAndC: yup
    .array()
    .ensure()
    .when('lAndH', {
      is: (val) => val.length === 0,
      then: yup.array().required('Please select at least one option'),
      otherwise: yup.array().notRequired(),
    }),
  lAndH: yup.array().ensure(),
  applyTo: yup
    .string()
    .ensure()
    .when('isCaptive', {
      is: (val) => val === 'yes',
      then: yup.string().required('Please select at least one option'),
      otherwise: yup.string().notRequired(),
    }),
  occupations: yup
    .array()
    .of(
      yup.object().shape({
        occupation: yup.string().trim(),
      })   
  )
    .when('applyTo', {
      is: (val) => val.value === 'specificOccupations' ||
        val.value === 'militaryOccupations',
      then: yup.array().required('Please select occupation'),
      otherwise: yup.array().notRequired(),
    })
    .required('must have occupations'),
});

export default agentSchema;
