const vehicleQuoteActionTypes = {
  FETCH_VEHICLE_QUOTES_FROM_DB_START: 'FETCH_VEHICLE_QUOTES_FROM_DB_START',
  FETCH_VEHICLE_QUOTES_FROM_DB_SUCCESS: 'FETCH_VEHICLE_QUOTES_FROM_DB_SUCCESS',
  FETCH_VEHICLE_QUOTES_FROM_DB_FAILURE: 'FETCH_VEHICLE_QUOTES_FROM_DB_FAILURE',
  FETCH_VEHICLE_QUOTE_FROM_DB_START: 'FETCH_VEHICLE_QUOTE_FROM_DB_START',
  FETCH_VEHICLE_QUOTE_FROM_DB_SUCCESS: 'FETCH_VEHICLE_QUOTE_FROM_DB_SUCCESS',
  FETCH_VEHICLE_QUOTE_FROM_DB_FAILURE: 'FETCH_VEHICLE_QUOTE_FROM_DB_FAILURE',
  START_VEHICLE_QUOTE_SAVE: 'START_VEHICLE_QUOTE_SAVE',
  VEHICLE_QUOTE_SAVE_SUCCESS: 'VEHICLE_QUOTE_SAVE_SUCCESS',
  START_VEHICLE_QUOTE_UPDATE: 'START_VEHICLE_QUOTE_UPDATE',
  VEHICLE_QUOTE_UPDATE_SUCCESS: 'VEHICLE_QUOTE_UPDATE_SUCCESS',
  START_LAST_STEP_VEHICLE_QUOTE_UPDATE: 'START_LAST_STEP_VEHICLE_QUOTE_UPDATE',
  LAST_STEP_VEHICLE_QUOTE_UPDATE_SUCCESS: 'LAST_STEP_VEHICLE_QUOTE_UPDATE_SUCCESS',
  START_VEHICLE_QUOTE_DELETE: 'START_VEHICLE_QUOTE_DELETE',
  VEHICLE_QUOTE_DELETE_SUCCESS: 'VEHICLE_QUOTE_DELETE_SUCCESS',
  VEHICLE_DECREMENT_CURRENT_STEP: 'VEHICLE_DECREMENT_CURRENT_STEP',
  VEHICLE_INCREMENT_CURRENT_STEP: 'VEHICLE_INCREMENT_CURRENT_STEP',
  START_CHECKING_CHAT_GROUP_VEHICLE: 'START_CHECKING_CHAT_GROUP_VEHICLE',
  CHECKING_CHAT_GROUP_VEHICLE_SUCCESS: 'CHECKING_CHAT_GROUP_VEHICLE_SUCCESS',
  SAVE_DELETE_VEHICLE_COMET_CHAT: 'SAVE_DELETE_VEHICLE_COMET_CHAT',
  SAVE_DELETE_VEHICLE_COMET_CHAT_SUCCESS: 'SAVE_DELETE_VEHICLE_COMET_CHAT_SUCCESS',
  SET_VEHICLE_NO_COMBINE_ID: 'SET_VEHICLE_NO_COMBINE_ID',
  SET_VEHICLE_FORM_DATA: 'SET_VEHICLE_FORM_DATA',
  SET_VEHICLE_COMBINE_CV: 'SET_VEHICLE_COMBINE_CV',
  SET_VEHICLE_COMBINE_ID: 'SET_VEHICLE_COMBINE_ID',
  SET_MEMBERS_INFO: 'SET_MEMBERS_INFO',
  SET_TIMER: 'SET_TIMER',
  RESET_FORM: 'RESET_FORM',
}

export default vehicleQuoteActionTypes;
