import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import {
  selectMember,
} from '../../redux/member/member.selectors';
import Dropdown from '../dropdown/dropdown.component';

const Education = ({
  values,
  handleChange,
  touched,
  errors,
}) => {
  const {
    form: {
      education: { title, description, label },
    },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <div>
            <Form.Row>
              <Form.Group
                as={Col}
                lg={6}
                controlId='acquisitions'
                optional='true'
              >
                <Form.Label>{label.acquisitions}</Form.Label>
                <Dropdown
                  isDisabled={
                    path === agentBid ? true : false
                  }
                  name='acquisitions'
                  selected={values.acquisitions}
                  options={label.dropdown.acquisitionTypes}
                  touched={touched}
                  errors={errors}
                  values={values}
                  isMulti={true}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.acquisitions}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={6}
                control-id='degree-type'
                optional='true'
              >
                <Form.Label>{label.degreeType}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='degreeType'
                  defaultValue={values.degreeType}
                  onChange={handleChange}
                  isValid={touched.degreeType && !errors.degreeType}
                  isInvalid={!!errors.degreeType}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.degreeType}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  member: selectMember,
});

export default connect(mapStateToProps, {})(Education);
