import { MEMBER_PROPERTY_POLICY_CMS_DATA } 
  from '../../../pages/policies/property/property-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import memberPropertyPolicyActionTypes from './property.types';

const payload = {
  context: 'memberPropertyPolicy',
  status: '',
  error: ''
}

export const startFetchingPropertiesFromDB = () => ({
  type: memberPropertyPolicyActionTypes.FETCH_PROPERTIES_FROM_DB_START,
});

export const fetchPropertiesFromDBSuccess = (propertiesFromDB) => ({
  type: memberPropertyPolicyActionTypes.FETCH_PROPERTIES_FROM_DB_SUCCESS,
  payload: propertiesFromDB,
});

export const fetchPropertiesFromDBFailure = (e) => ({
  type: memberPropertyPolicyActionTypes.FETCH_PROPERTIES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingPropertyPolicyFromDB = (id) => ({
  type: memberPropertyPolicyActionTypes.FETCH_PROPERTY_POLICY_FROM_DB_START,
  payload: id,
});

export const fetchPropertyPolicyFromDBSuccess = (propertyPolicy) => ({
  type: memberPropertyPolicyActionTypes.FETCH_PROPERTY_POLICY_FROM_DB_SUCCESS,
  payload: propertyPolicy,
});

export const fetchPropertyPolicyFromDBFailure = (e) => ({
  type: memberPropertyPolicyActionTypes.FETCH_PROPERTY_POLICY_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: memberPropertyPolicyActionTypes.LOAD_STATES_PROPERTY_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: memberPropertyPolicyActionTypes.LOAD_STATES_PROPERTY_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: memberPropertyPolicyActionTypes.LOAD_STATES_PROPERTY_FROM_DB_FAILURE,
  payload: MEMBER_PROPERTY_POLICY_CMS_DATA.errMsg.states,
});

export const startPropertyPolicySave = (data) => ({
  type: memberPropertyPolicyActionTypes.START_PROPERTY_POLICY_SAVE,
  payload: data
});

export const propertyPolicySaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const propertyPolicySaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const setClearForm = (clear) => ({
  type: memberPropertyPolicyActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const startPropertyPolicyUpdate = (id, formData) => ({
  type: memberPropertyPolicyActionTypes.START_PROPERTY_POLICY_UPDATE,
  payload: { id, formData }
});

export const propertyPolicyUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const propertyPolicyUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startPropertyPolicyDelete = (id, PId) => ({
  type: memberPropertyPolicyActionTypes.START_PROPERTY_POLICY_DELETE,
  payload: { id, PId },
});

export const propertyPolicyDeleteSuccess = () => ({
  type: memberPropertyPolicyActionTypes.PROPERTY_POLICY_DELETE_SUCCESS,
});

export const propertyPolicyDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});





