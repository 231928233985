import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import { convertMemberMedicalPolicyForDBSave } from "./medical.utils";
import memberMedicalPolicyActionTypes from "./medical.types";
import {
  loadStatesFromDBSuccess,
  loadStatesFromDBFailure,
  medicalPolicySaveSuccess,
  medicalPolicySaveFailure,
  medicalPolicyUpdateSuccess,
  medicalPolicyUpdateFailure,
  medicalPolicyDeleteSuccess,
  medicalPolicyDeleteFailure,
  fetchMembersFromDBSuccess,
  fetchMembersFromDBFailure,
  fetchMedicalPolicyFromDBSuccess,
  fetchMedicalPolicyFromDBFailure,
} from "./medical.actions";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* fetchMembersFromDB() {
  try {
    const response = yield axios.get(`/api/members`);
    const members = yield response.data;
    yield put(fetchMembersFromDBSuccess(members));
  } catch (e) {
    yield put(fetchMembersFromDBFailure(e));
  }
}

function* fetchMedicalPolicyFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/member-medical-policy/${id}`);
    const medicalPolicy = yield response.data;
    yield put(fetchMedicalPolicyFromDBSuccess(medicalPolicy));
  } catch (e) {
    yield put(fetchMedicalPolicyFromDBFailure(e));
  }
}

function* saveMedicalPolicy({ payload: formData }) {
  try {
    const data = yield call(convertMemberMedicalPolicyForDBSave, formData);
    const formDataFiles = new FormData();
    for (var i = 0; i < formData.uploadedFiles.length; i++) {
      formDataFiles.append("documents", formData.uploadedFiles[i].file);
    }
    yield axios.post(`/api/upload-document`, formDataFiles).then((response) => {
      let files = response.data ? response.data.files : [];
      let chunks = response.data ? response.data.chunks : [];
      if (files && chunks) {
        for (let m = 0; m < files.length; m++) {
          let index = chunks.findIndex(
            (item, i) => item.files_id === files[m]._id
          );
          files[m].chunkId = chunks[index]._id;
        }
      }
      data.documents = Array.isArray(files) ? files : [];
      axios.post(`/api/member-medical-policy`, data, configureAxios());
    });
    yield put(medicalPolicySaveSuccess());
  } catch (e) {
    yield put(medicalPolicySaveFailure(e));
  }
}

function* updateMedicalPolicy({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberMedicalPolicyForDBSave, formData);
    if (formData.uploadedFiles && formData.uploadedFiles.length > 0) {
      const formDataFiles = new FormData();
      for (let i = 0; i < formData.uploadedFiles.length; i++) {
        formDataFiles.append("documents", formData.uploadedFiles[i].file);
      }
      yield axios
        .post(`/api/upload-document`, formDataFiles)
        .then((response) => {
          let files = response.data ? response.data.files : [];
          let chunks = response.data ? response.data.chunks : [];
          if (files && chunks) {
            for (let m = 0; m < files.length; m++) {
              let index = chunks.findIndex(
                (item, i) => item.files_id === files[m]._id
              );
              files[m].chunkId = chunks[index]._id;
            }
          }
          data.documents = Array.isArray(files)
            ? data.documents.concat(files)
            : [];
          axios.patch(
            `/api/member-medical-policy/${id}`,
            data,
            configureAxios()
          );
        });
      yield put(medicalPolicyUpdateSuccess());
    }
    if (formData.uploadedFiles && formData.uploadedFiles.length === 0) {
      axios.patch(`/api/member-medical-policy/${id}`, data, configureAxios());
      yield put(medicalPolicyUpdateSuccess());
    }
  } catch (e) {
    yield put(medicalPolicyUpdateFailure(e));
  }
}

function* deleteMedicalPolicy({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/member-medical-policy/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(medicalPolicyDeleteSuccess());
  } catch (e) {
    yield put(medicalPolicyDeleteFailure(e));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    memberMedicalPolicyActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onFetchMembersFromDB() {
  yield takeEvery(
    memberMedicalPolicyActionTypes.FETCH_MEMBERS_FROM_DB_START,
    fetchMembersFromDB
  );
}

function* onFetchMedicalPolicyFromDB() {
  yield takeEvery(
    memberMedicalPolicyActionTypes.FETCH_MEDICAL_POLICY_FROM_DB_START,
    fetchMedicalPolicyFromDB
  );
}

function* onMedicalPolicySave() {
  yield takeEvery(
    memberMedicalPolicyActionTypes.START_MEDICAL_POLICY_SAVE,
    saveMedicalPolicy
  );
}

function* onUpdateMedicalPolicy() {
  yield takeEvery(
    memberMedicalPolicyActionTypes.START_MEDICAL_POLICY_UPDATE,
    updateMedicalPolicy
  );
}

function* onDeleteMedicalPolicy() {
  yield takeEvery(
    memberMedicalPolicyActionTypes.START_MEDICAL_POLICY_DELETE,
    deleteMedicalPolicy
  );
}

export function* memberMedicalPolicySagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onMedicalPolicySave),
    call(onFetchMembersFromDB),
    call(onFetchMedicalPolicyFromDB),
    call(onUpdateMedicalPolicy),
    call(onDeleteMedicalPolicy),
  ]);
}
