import React from 'react';
import { Table } from 'react-bootstrap';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import AgentDetails from './agent-details.component';

const AgentsListTable = ({
  agents,
}) => {

  const {
    form: { table: { agentsList } }
  } = AGENT_CMS_DATA;

  return (
    <Table
      bordered
      responsive
      size='sm'
      className='shadow-sm mb-2 rounded table table-bordered text-right'
    >
      <thead className='table-bordered'>
        <tr>
          <th>{agentsList.id}</th>
          <th>{agentsList.email}</th>
          <th>{agentsList.name}</th>
          <th>{agentsList.license}</th>
          <th>{agentsList.cap}</th>
          <th>{agentsList.prop}</th>
          <th>{agentsList.veh}</th>
          <th>{agentsList.ani}</th>
          <th>{agentsList.ann}</th>
          <th>{agentsList.life}</th>
          <th>{agentsList.health}</th>
        </tr>
      </thead>
      <tbody className='font-size-x'>
        {agents.map((agent, i) => (
          <tr key={i}>
            {agent._id ? (
              <AgentDetails
                agent={agent}
              />
            ) : null}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AgentsListTable;