export const HEALTH_QUOTE_CMS_DATA = {
  heading: 'My Household Health',
  subHeading:
    'For Life and Medical Insurance. Note that the more detailed and accurate the information you provide, the better our Agents can place you with the appropriate Carrier and more accurate quotes.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
      hide: 'Hide',
      previous: 'Previous',
      next: 'Next',
      another: `Next Member`,
    },
    membersHealth: {
      title: 'Members Health',
      description: 'Select yourself or the household member for which to enter, view, or edit health information. If you are in the process of getting quotes, you will be guided through each member you requested quotes for.',
      label: 'Members health',
    },
    measurement: {
      title: 'Body Measurements',
      description:
        'Yes, insurance Carriers still use inaccurate BMI measurements, unless you request a policy to have a ParaMed do tests. But, be honest and feel free to use the comment box in the Other Information section for any additional information or for any concerns.',
      weight: {
        label: {
          weightChange: 'Weight over 1 year',
          weight: 'Weight',
          weightDiff: 'How much?',
        },
        dropdown: [
          { value: 'increased', key: 'Increased' },
          { value: 'decreased', key: 'Decreased' },
          { value: 'noChange', key: `No change` },
        ],
      },
      height: {
        label: {
          height: 'Height',
          feet: 'Height',
          inches: 'Height',
        },
        dropdown: {
          feet: [
            { value: 'zero', key: '0' },
            { value: 'one', key: '1' },
            { value: 'two', key: '2' },
            { value: 'three', key: '3' },
            { value: 'four', key: '4' },
            { value: 'five', key: '5' },
            { value: 'six', key: '6' },
            { value: 'seven', key: '7' },
            { value: 'eight', key: '8' },
            { value: 'nine', key: '9' },
          ],
          inches: [
            { value: 'zero', key: '0' },
            { value: 'one', key: '1' },
            { value: 'two', key: '2' },
            { value: 'three', key: '3' },
            { value: 'four', key: '4' },
            { value: 'five', key: '5' },
            { value: 'six', key: '6' },
            { value: 'seven', key: '7' },
            { value: 'eight', key: '8' },
            { value: 'nine', key: '9' },
            { value: 'ten', key: '10' },
            { value: 'eleven', key: '11' },
          ],
        },
      },
    },
    handicaps: {
      title: 'Handicaps',
      description: "Remember, we're here to help.",
      handicapAddInfo: 'Please elaborate',
      hasMentalHandicap: {
        label: 'Mental handicap',
        dropdown: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      },

        hasPhysicalDis: {
        label: 'Physical disability',
        dropdown: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      },
    },
    tobaccoNicotine: {
      title: 'Tobacco or Nicotine',
      description: `We all have bad habits. Unforunately, this one can be a bit more 
        expensive. If you use, one of our Agents will find a Carrier/policy that is
        more lenient.`,
      tobaccoUsage: {
        label: 'Ever used tobacco or nicotine',
        dropdown: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      },
      tobaccoUsageMode: {
        label: 'Type(s) of tobacco or nicotine',
        elaborate: 'Please elaborate',
        dropdown: [
          { value: 'smoke', label: 'Smoke' },
          { value: 'chew', label: 'Chew' },
          { value: 'snuff', label: 'Snuff' },
          { value: 'vape', label: 'Vape' },
          { value: 'other', label: 'Other' },
        ],
      },
      hasStopped: {
        label: 'Stopped use',
        dropdown: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      },
      stoppedDate: {
        label: 'Cessation date',
      },
      isInCessation: {
        label: 'In cessation program',
        dropdown: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      },
      hasPlansToJoinCes: {
        label: 'Plan on joining',
        dropdown: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      },
    },
    familyHistories: {
      title: 'Family History',
      description:
        "Please provide the information for all of your known blood relatives listed. Select one, answer the corresponding inquiries, and Add Another until you've answered for all of them that you are familiar with. If you are unfamiliar with any of them, feel free to skip.",
      label: {
        relationships: 'Relation',
        doa: 'Currently living?',
        age: 'Current age?',
        ageAtDeath: 'Age at death?',
        healthNow: 'Present health',
        healthAtDeath: 'Cause of death',
        dropdown: {
          relationships: [
            { value: 'pgf', label: 'Paternal grandfather', multi: false },
            { value: 'pgm', label: 'Paternal grandmother', multi: false },
            { value: 'mgf', label: 'Maternal grandfather', multi: false },
            { value: 'mgm', label: 'Maternal grandmother', multi: false },
            { value: 'f', label: 'Father', multi: false },
            { value: 'm', label: 'Mother', multi: false },
            { value: 's', label: 'Sibling', multi: true },
          ],
          doa: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
        },
      },
    },
    preExistingConditions: {
      title: 'Pre-Existing Conditions',
      label: 'If you have any pre-existing conditions, please select any that apply. Hover over each one for more details.',
      description:
        "You can bet that if you're getting a Life insurance policy, the Agent will be going over these with you again. So, why now? Well, if an Agent doesn't know this up front and tries to place you with a Life or Health Insurance Carrier that charges way more or doesn't accept people with these conditions then there was much time wasted, as the Agent will need to reaccess this and start searching again for a more suitable Policy or Carrier to satisfy your needs. It's better to go ahead and answer accurately now than to go through more headaches later.",
      expDelDate: 'Expected delivery date',
      additionalInfo:
        'Please share any pre-existing conditions, illnesses, injuries, surgeries, hospitalizations, medical examinations, or special care, whether listed above or not, and elaborate on them. Please include the dates of when these conditions arose or were diagnosed, if possible.',
      preExitingArr: [
        {
          id: 0,
          value: 'hasHeartDis',
          controlId: 'heart',
          label: 'Heart/Circulatory',
          detail: `Any disease or abnormal condition of the heart, circulatory system, or blood vessels, like high blood pressure,
            rapid pulse, rheumatic fever, murmur, coronary artery disease, chest pain, angina, or stroke.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 1,
          value: 'hasLungDis',
          controlId: 'lung',
          label: 'Lung/Respiratory',
          detail: `Any disease of the lungs or respiratory system, including tuberculosis, asthma, bronchitis, emphysema,
            or shortness of breath.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 2,
          value: 'hasDigestiveDis',
          controlId: 'digestive',
          label: 'Digestive',
          detail: `Any digestive system disease, including stomach or duodenal ulcer, indigestion, stomach pain, liver
            or gallbladder disease, or colon or rectal disorder.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 3,
          value: 'hasGenUrinDis',
          controlId: 'genitourinary',
          label: 'Genitourinary',
          detail: `Any genitourinary system disease including albumin, blood or sugar in urine, kidney infection
            or stones, tumor or disease of the prostate, testis, breasts, uterus, or ovaries.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 4,
          value: 'hasMentalDis',
          controlId: 'mental',
          label: 'Nervous system',
          detail: `Any nervous, brain or mental disorder, convulsions, dizziness, headaches, epilepsy, nervous breakdown, or paralysis.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 5,
          value: 'hasBoneOrJointDis',
          controlId: 'bone-joint',
          label: 'Bone/Joint',
          detail: `Any bone or joint disorder, arthritis or rheumatism, bodily deformity, or back or spinal disorder?`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 6,
          value: 'hasVisionIm',
          controlId: 'vision',
          label: 'Vision',
          detail: `Any disease or impairment of vision.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 7,
          value: 'hasHearingIm',
          controlId: 'hearing',
          label: 'Hearing',
          detail: `Any disease or impairment of hearing.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 8,
          value: 'hasGlandDis',
          controlId: 'glandular',
          label: 'Glandular',
          detail: `Gout, diabetes, thyroid or other glandular disorder, cancer, tumor or blood disorder other than AIDS
            or AIDS Related Complex (ARC).`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 9,
          value: 'isHIVPos',
          controlId: 'hiv',
          label: 'HIV',
          detail: `Diagnosed as having Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC), caused by the
            HIV infection, or been treated for AIDS or ARC by a physician or licensed practitioner.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 10,
          value: 'isOnMed',
          controlId: 'on-medication',
          label: 'Medication(s)',
          detail: `Any medication prescribed by a physician.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 11,
          value: 'hasUnexpFever',
          controlId: 'unexplained-fever',
          label: 'Unexplained illness',
          detail: `In the past 10 years, had or received treatment for any unexplained fever, fatigue,
            or chronic cough.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 12,
          value: 'hadGotStudiesDone',
          controlId: 'studies-done',
          label: 'Scans/Studies',
          detail: `In the past 10 years, had any X-rays, electrocardiograms, CT or PET scans, MRI scans, or blood scan or other studies,
            except for an HIV test.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 13,
          value: 'hadSurgery',
          controlId: 'had-surgery',
          label: 'Surgery',
          detail: `In the past 10 years, been advised by a physician to have a surgical operation.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 14,
          value: 'advisedAlcoholUsage',
          controlId: 'alcohol-usage',
          label: 'Alcohol usage',
          detail: `In the past 10 years, been advised by a physician to limit your use of alcohol.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 15,
          value: 'advisedDrugAbuse',
          controlId: 'drug-abuse',
          label: 'Drug abuse',
          detail: `In the past 10 years, used alcohol or other drugs to a degree that required
            treatment or advice from a physician or other licensed practitioner.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 16,
          value: 'isAANAMember',
          controlId: 'anna-member',
          label: 'AA or NA Member',
          detail: `In the past 10 years, been or are currently a member of Alcoholics Anonymous
            or Narcotics Anonymous.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          id: 17,
          value: 'isPregnant',
          controlId: 'pregnancy',
          label: 'Pregnant',
          detail: `Currently pregnant.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
      ]
    },
    otherInformation: {
      title: 'Other Information',
      label: 'The following are additional concerns a carrier might have, please select any that apply.',
      description:
        'Ok, we almost know everything about you... Sorry, that was creepy. Rest assured, all of this stays confidential between you and the underwriters. Thankfully, this is the last section in Health.',
      additionalInfo:
        'Please elaborate or share any additional information or concerns',
      otherInfoArr: [
        {
          value: 'isReinst',
          controlId: 'reinstatement',
          label: 'Policy issues',
          detail: `Ever been declined, postponed, limited, denied reinstatement, or asked to pay an extra premium by any insurance company?`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          value: 'engHazAct',
          controlId: 'hazardous-activity',
          label: 'Hazardous activity',
          detail: `Engaged in any hazardous sports or activities such as motor vehicle racing, boat racing, climbing, parachuting,
            hang gliding, skydiving, skin diving, or scuba diving within the last three years or planning such activities in the
            next six months.`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          value: 'travelStatus',
          controlId: 'travel-status',
          label: 'International Travel',
          detail: `Any intention of traveling or living outside the USA or Canada in the next two years?`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          value: 'pilotStatus',
          controlId: 'pilot-status',
          label: 'Piloting',
          detail: `Flown as a civilian pilot, student pilot, or crew member within the last three years
            or plan such activity in the next 12 months?`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          value: 'engDrivVi',
          controlId: 'driving-violations',
          label: 'Driving violations',
          detail: `Within the last 5 years been convicted of two or more moving violations or driving
            under the influence of alcohol or drugs?`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          value: 'hasIllDrugs',
          controlId: 'illegal-drugs',
          label: 'Illegal drugs',
          detail: `Within the last 5 years taken any illegal drugs?`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
        {
          value: 'hasIllness',
          controlId: 'illness',
          label: 'Illness',
          detail: `Within the last 5 years had any significant illness, been hospitalized, or required
            regular care by a doctor not already listed?`,
          dropdown: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ],
        },
      ]
    },
  },
  popOver: {
    body: {
      content: {
        weightChange: "Did your weight increase or decrease over the past year? 5 lbs or less should not matter.",
        weightDiff: "How much did your weight increase or decrease? This is good for the Agent to know but it can help you as well."
      }
    },
    tobacco: {
      content: {
        usage: "Refer to the comment for this section. If you answer, \"No,\" and the Carrier later finds out that you do, this could nullify some of the coverage. So, please be honest and follow through each inquiry. Btw, those vaporizers count ;). If you don't use, great!",
        stop: "Have you stopped the use of these tobacco or nicotine products completely? If so, you'll be able to offer the year you stopped. If it's the same year, it might be better to answer No and go with a cessation program.",
        isInCessation: "Are you in a cessation program to stop use of these products? Or, are you a rebel?",
        hasPlansToJoinCes: "Alright rebel, work with us here. At least consider this one... ;) Joining a cessation program, that is.",
      }
    },
    familyHistories: {
      content: {
        relation: "Listed here are (blood) relatives that are pertinent to Life insurance quotes. Please go through the list and select a blood relative that you are familiar with and answer the following inquiries (that will populate once a selection is made) about them.",
      }
    },
  }
};

