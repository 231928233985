import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  healthBiddingSaveSuccess,
  healthBiddingSaveFailure,
  healthBiddingUpdateSuccess,
  healthBiddingUpdateFailure,
} from "./health-bidding.actions";
import healthBiddingActionTypes from "./health-bidding.types";
import { convertHealthBiddingForDBSave } from "./health-bidding.utils";

function* saveHealthBidding({ payload: formData }) {
  try {
    const data = yield call(convertHealthBiddingForDBSave, formData);
    yield axios.post("/api/health-bidding", data, configureAxios());
    yield put(healthBiddingSaveSuccess());
  } catch (e) {
    yield put(healthBiddingSaveFailure(e));
  }
}

function* updateHealthBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertHealthBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/health-bidding/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(healthBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(healthBiddingUpdateFailure(e));
  }
}

function* onSaveHealthBiddingStart() {
  yield takeEvery(
    healthBiddingActionTypes.START_HEALTH_BIDDING_SAVE,
    saveHealthBidding
  );
}

function* onUpdateHealthBiddingStart() {
  yield takeEvery(
    healthBiddingActionTypes.START_HEALTH_BIDDING_UPDATE,
    updateHealthBidding
  );
}

export function* healthBiddingSagas() {
  yield all([call(onSaveHealthBiddingStart), call(onUpdateHealthBiddingStart)]);
}
