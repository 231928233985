import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../helpers/configure-axios";
import {
  fetchAgentBiddingsFromDBSuccess,
  fetchAgentBiddingsFromDBFailure,
  fetchAgentBiddingFromDBSuccess,
  fetchAgentBiddingFromDBFailure,
  agentBiddingSaveSuccess,
  agentBiddingSaveFailure,
  agentBiddingUpdateSuccess,
  agentBiddingUpdateFailure,
} from "./agent-bidding.actions";
import agentBiddingActionTypes from "./agent-bidding.types";
import { convertAgentBiddingForDBSave } from "./agent-bidding.utils";

function* fetchAgentBiddingsFromDB() {
  try {
    const response = yield axios.get(`/api/agentBiddings`);
    const agentBiddings = yield response.data;
    yield put(fetchAgentBiddingsFromDBSuccess(agentBiddings));
  } catch (e) {
    yield put(fetchAgentBiddingsFromDBFailure(e));
  }
}

function* fetchAgentBiddingFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agentBiddings/${id}`);
    const agentBidding = yield response.data;
    yield put(fetchAgentBiddingFromDBSuccess(agentBidding));
  } catch (e) {
    yield put(fetchAgentBiddingFromDBFailure(e));
  }
}

function* saveAgentBidding({ payload: formData }) {
  try {
    const data = yield call(convertAgentBiddingForDBSave, formData);
    yield axios.post("/api/agentBiddings", data, configureAxios());
    yield put(agentBiddingSaveSuccess());
  } catch (e) {
    yield put(agentBiddingSaveFailure(e));
  }
}

function* updateAgentBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/agentBiddings/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(agentBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(agentBiddingUpdateFailure(e));
  }
}

function* onFetchAgentBiddingsFromDB() {
  yield takeEvery(
    agentBiddingActionTypes.FETCH_AGENT_BIDDINGS_FROM_DB_START,
    fetchAgentBiddingsFromDB
  );
}

function* onFetchAgentBiddingFromDB() {
  yield takeEvery(
    agentBiddingActionTypes.FETCH_AGENT_BIDDING_FROM_DB_START,
    fetchAgentBiddingFromDB
  );
}

function* onSaveAgentBiddingStart() {
  yield takeEvery(
    agentBiddingActionTypes.START_AGENT_BIDDING_SAVE,
    saveAgentBidding
  );
}

function* onUpdateAgentBiddingStart() {
  yield takeEvery(
    agentBiddingActionTypes.START_AGENT_BIDDING_UPDATE,
    updateAgentBidding
  );
}

export function* agentBiddingSagas() {
  yield all([
    call(onFetchAgentBiddingsFromDB),
    call(onFetchAgentBiddingFromDB),
    call(onSaveAgentBiddingStart),
    call(onUpdateAgentBiddingStart),
  ]);
}
