import { AGENT_LIFE_QUOTE_CMS_DATA }
  from '../../../pages/agent-quotes/life/life-quote.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import agentLifeQuoteActionTypes from './life.types';

const payload = {
  context: 'agentLifeQuote',
  status: '',
  error: '',
}

export const setClearForm = (clear) => ({
  type: agentLifeQuoteActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const setIsAgent = (agent) => ({
  type: agentLifeQuoteActionTypes.SET_IS_AGENT,
  payload: agent
});

export const startFetchingAgentLifeQuoteFromDB = (id) => ({
  type: agentLifeQuoteActionTypes.FETCH_AGENT_LIFE_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAgentLifeQuoteFromDBSuccess = (policy) => ({
  type: agentLifeQuoteActionTypes.FETCH_AGENT_LIFE_QUOTE_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchAgentLifeQuoteFromDBFailure = (e) => ({
  type: agentLifeQuoteActionTypes.FETCH_AGENT_LIFE_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: agentLifeQuoteActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: agentLifeQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: agentLifeQuoteActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: AGENT_LIFE_QUOTE_CMS_DATA.errMsg.states,
});

export const startAgentLifeQuoteSave = (data) => ({
  type: agentLifeQuoteActionTypes.START_AGENT_LIFE_QUOTE_SAVE,
  payload: data
});

export const agentLifeQuoteSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_QUOTE_SUCCESS,
  },
});

export const agentLifeQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentLifeQuoteUpdate = (id, formData) => ({
  type: agentLifeQuoteActionTypes.START_AGENT_LIFE_QUOTE_UPDATE,
  payload: { id, formData }
});

export const agentLifeQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_UPDATE_QUOTE_SUCCESS,
  },
});

export const agentLifeQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentLifeQuoteDelete = (id, pId) => ({
  type: agentLifeQuoteActionTypes.START_AGENT_LIFE_QUOTE_DELETE,
  payload: { id, pId },
});

export const agentLifeQuoteDeleteSuccess = () => ({
  type: agentLifeQuoteActionTypes.AGENT_LIFE_QUOTE_DELETE_SUCCESS,
});

export const agentLifeQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});