import { ANNUITY_QUOTE_CMS_DATA } from '../../../components/requested-quotes/annuity/annuity.cms';

export const convertAnnuityQuoteForDBSave = (data) => {
  const {
    form: {
      annuity: {
        dropdown: { annuityType, typeOfInvest, varInv }
      }
    }
  } = ANNUITY_QUOTE_CMS_DATA;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    member: data.member ? data.member : '',
    createdDate: new Date(),
    primeMemberInfo: data.primeMemberInfo ? data.primeMemberInfo : {},
    membersInfo: data.membersInfo ? data.membersInfo : [],
    isLastStep: data.isLastStep ? data.isLastStep : false,
    isCometChatGr: data.isCometChatGr ? data.isCometChatGr : false,
    createdCometDate: data.createdCometDate ? data.createdCometDate : '',
    isMsgSent: data.isMsgSent ? data.isMsgSent : false,
    selAgentsByAI: data.selAgentsByAI ? data.selAgentsByAI : {},
    isCometExp: data.isCometExp ? data.isCometExp : false,
    isDeleteComet: data.isDeleteComet ? data.isDeleteComet : false,
    annuityType: {
      immediate: {
        isTypeOf:
          data.annuityType !== null
            ? data.annuityType === annuityType[0].value
            : false,
        invest:
          data.investedAmt !== ''
            ? data.investedAmt
            : 0,
      },
      deferred: {
        isTypeOf:
          data.annuityType !== null
            ? data.annuityType === annuityType[1].value
            : false,
        downAmount:
          data.downPay !== ''
            ? data.downPay
            : 0,
      },
      notSure: {
        isTypeOf:
          data.annuityType !== null
            ? data.annuityType === annuityType[2].value
            : false,
        additionalInfo:
          data.notSureInfoAn !== null
            ? data.notSureInfoAn
            : '',
      },
      additionalInfo:
        data.addInfoAn !== null
          ? data.addInfoAn
          : '',
    },
    typeOfInvest: {
      fixed:
        data.typeOfInvest !== null
          ? data.typeOfInvest === typeOfInvest[0].value
          : false,
      indexed:
        data.typeOfInvest !== null
          ? data.typeOfInvest === typeOfInvest[1].value
          : false,
      variable: {
        isTypeOf:
          data.typeOfInvest !== null
            ? data.typeOfInvest === typeOfInvest[2].value
            : false,
        invest:
          data.varInv !== null
            ? data.varInv === varInv[0].value
            : false,
      },
      notSure: {
        isTypeOf:
          data.typeOfInvest !== null
            ? data.typeOfInvest === typeOfInvest[3].value
            : false,
        additionalInfo:
          data.notSureInfoInv !== null
            ? data.notSureInfoInv
            : '',
      },
      additionalInfo:
        data.addInfoInv !== null
          ? data.addInfoInv
          : '',
    },

  }

  return payload;
};
