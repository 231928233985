import { createSelector } from 'reselect';

const selectHealth = (state) => state.health;

export const selectRelationshipOptions = createSelector(
  [selectHealth],
  (health) => health.relationshipOptions
)

export const selectHealthFromDB = createSelector(
  [selectHealth],
  (health) => health.healthFromDB
)

export const selectHealthsFromDB = createSelector(
  [selectHealth],
  (health) => health.healthsFromDB
)

export const selectShowHealthForm = createSelector(
  [selectHealth],
  (health) => health.showHealthForm
)

export const selectMemberId = createSelector(
  [selectHealth],
  (health) => health.memberId
)

export const selectMemberHealthId = createSelector(
  [selectHealth],
  (health) => health.memberHealthId
)

export const selectIsSubmitted = createSelector(
  [selectHealth],
  (health) => health.isSubmitted
);

export const selectClearHealthForm = createSelector(
  [selectHealth],
  (health) => health.clearHealthForm
);

export const selectReset = createSelector(
  [selectHealth],
  (health) => health.reset
)

