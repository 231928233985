import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startFetchingAnimalFromDB,
  toggleAnimalForm,
  toggleAnimalInfoEdit,
  setClearAnimalForm,
  startCompanionAnimalDelete,
} from '../../../redux/quotes/companion-animal/companion-animal.actions';
import {
  selectAnimal,
  selectShowAnimalForm,
  selectShowAnimalInfoEdit,
} from '../../../redux/quotes/companion-animal/companion-animal.selectors';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import {
  COMPANION_ANIMAL_CMS_DATA
} from '../../../pages/quotes/companion-animal/companion-animal.cms';
import { useHistory } from 'react-router-dom';

const EditAnimals = ({
  primeMember,
  currentUser,
  showAnimalForm,
  toggleAnimalForm,
  startFetchingAnimalFromDB,
}) => {
  const {
    modal: { action }
  } = COMPANION_ANIMAL_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname;
  const animalP = '/household-animals-information';

  const [storeIds, setStoreIds] = useState([]);

  const handleUpdate = (id) => {
    if (id) {
      startFetchingAnimalFromDB(id);
    }
    if (!showAnimalForm) {
      toggleAnimalForm(true);
    } 
    if (showAnimalForm) {
      toggleAnimalForm(false);
    } 
  }

  const householdAnimals =
    primeMember && primeMember.householdAnimals
      ? primeMember.householdAnimals
      : [];

  const getAnimalType = (hm) => {
    for (let t in hm.type) {
      if (hm.type[t]) {
        return t;
      }
    }
  }

  return (
    <div className={
      path === animalP
        ? 'pb-5 mb-4 text-right'
        : 'mb-3 text-right'
    } >
      <Table
        bordered
        responsive
        className='shadow-sm p-3 mb-4 bg-white rounded'
      >
        {householdAnimals && householdAnimals.length > 0 ? (
          <>
            <thead className='thead-light'>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            {householdAnimals.map((ha, i) => (
              <tbody key={ha._id}>
                <tr key={i}>
                  <td>{getAnimalType(ha).charAt(0).toUpperCase() + getAnimalType(ha).slice(1)}</td>
                  <td>{ha.name}</td>
                  <td>
                    <Button
                      className='ml-1'
                      variant={
                        showAnimalForm && (ha._id === storeIds[0])
                          ? 'warning'
                          : 'primary'
                      }
                      onClick={() => {
                        handleUpdate(ha._id)
                        setStoreIds([ha._id, currentUser.member]);
                      }}
                    >
                      {showAnimalForm && (ha._id === storeIds[0])
                        ? action.hide
                        : action.update
                      }                      
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </>
        ) : null}
      </Table>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  animal: selectAnimal,
  primeMember: selectPrimeMember,
  showAnimalForm: selectShowAnimalForm,
  showAnimalInfoEdit: selectShowAnimalInfoEdit,
});

export default connect(mapStateToProps, {
  startCompanionAnimalDelete,
  toggleAnimalForm,
  toggleAnimalInfoEdit,
  setClearAnimalForm,
  startFetchingAnimalFromDB,
})(EditAnimals);