import { VEHICLE_CMS_DATA } from '../../../pages/quotes/vehicle/vehicle.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import vehicleActionTypes from './vehicle.types';

const payload = {
  context: 'vehicle',
  status: '',
  error: '',
};

export const setIsSubmitted = (toggle) => ({
  type: vehicleActionTypes.SET_IS_SUBMITTED,
  payload: toggle,
});

export const toggleVehicleForm = (toggle) => ({
  type: vehicleActionTypes.TOGGLE_VEHICLE_FORM,
  payload: toggle,
});

export const toggleVehicleOptions = (toggle) => ({
  type: vehicleActionTypes.TOGGLE_VEHICLE_OPTIONS,
  payload: toggle,
});

export const toggleVehicleInfoEdit = (toggle) => ({
  type: vehicleActionTypes.TOGGLE_VEHICLE_INFO_EDIT,
  payload: toggle,
});

export const setClearVehicleForm = (clear) => ({
  type: vehicleActionTypes.SET_CLEAR_VEHICLE_FORM,
  payload: clear,
});

export const startFetchingAllVehiclesFromDB = () => ({
  type: vehicleActionTypes.FETCH_ALL_VEHICLES_FROM_DB_START,
});

export const fetchAllVehiclesFromDBSuccess = (vehicles) => ({
  type: vehicleActionTypes.FETCH_ALL_VEHICLES_FROM_DB_SUCCESS,
  payload: vehicles,
});

export const fetchAllVehiclesFromDBFailure = (e) => ({
  type: vehicleActionTypes.FETCH_ALL_VEHICLES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingVehicleFromDB = (id) => ({
  type: vehicleActionTypes.FETCH_VEHICLE_FROM_DB_START,
  payload: id,
});

export const fetchVehicleFromDBSuccess = (vehicle) => ({
  type: vehicleActionTypes.FETCH_VEHICLE_FROM_DB_SUCCESS,
  payload: vehicle,
});

export const fetchVehicleFromDBFailure = (e) => ({
  type: vehicleActionTypes.FETCH_VEHICLE_FROM_DB_FAILURE,
  payload: e,
});

export const selectMake = (selectedMake) => (dispatch) => {
  dispatch({
    type: vehicleActionTypes.SELECT_MAKE,
    payload: selectedMake[0],
  });
};

export const selectModel = (selectedModel) => (dispatch) => {
  dispatch({
    type: vehicleActionTypes.SELECT_MODEL,
    payload: selectedModel[0],
  });
};

export const startLoadingVehicleMakeFromDB = () => ({
  type: vehicleActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_START,
});

export const loadVehicleMakeFromDBSuccess = (data) => ({
  type: vehicleActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS,
  payload: data,
});

export const loadVehicleMakeFromDBFailure = (e) => ({
  type: vehicleActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_FAILURE,
  payload: VEHICLE_CMS_DATA.errMsg.makes,
});

export const startLoadingVehicleModelsFromDB = (selectedMake) => ({
  type: vehicleActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_START,
  payload: selectedMake,
});

export const loadVehicleModelsFromDBSuccess = (data) => ({
  type: vehicleActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS,
  payload: data,
});

export const loadVehicleModelsFromDBFailure = (e) => ({
  type: vehicleActionTypes.loadVehicleModelsFromDBFailure,
  payload: VEHICLE_CMS_DATA.errMsg.models,
});

export const startVehicleSave = (vehicle) => ({
  type: vehicleActionTypes.START_VEHICLE_SAVE,
  payload: vehicle,
});

export const vehicleSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const vehicleSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehicleUpdate = (id, formData, nextStep) => ({
  type: vehicleActionTypes.START_VEHICLE_UPDATE,
  payload: { id, formData },
});

export const vehicleUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const vehicleUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehicleInQuoteSave = (vehicle) => ({
  type: vehicleActionTypes.START_VEHICLE_IN_QUOTE_SAVE,
  payload: vehicle,
});

export const vehicleInQuoteSaveSuccess = () => ({
  type: vehicleActionTypes.VEHICLE_IN_QUOTE_SAVE_SUCCESS,
});

export const vehicleInQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehicleInQuoteUpdate = (id, formData) => ({
  type: vehicleActionTypes.START_VEHICLE_IN_QUOTE_UPDATE,
  payload: { id, formData },
});

export const vehicleInQuoteUpdateSuccess = () => ({
  type: vehicleActionTypes.VEHICLE_IN_QUOTE_UPDATE_SUCCESS,
});

export const vehicleInQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehicleDelete = (id, PId) => ({
  type: vehicleActionTypes.START_VEHICLE_DELETE,
  payload: { id, PId },
});

export const vehicleDeleteSuccess = () => ({
  type: vehicleActionTypes.VEHICLE_DELETE_SUCCESS,
});

export const vehicleDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

