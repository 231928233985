export const HEALTH_QUOTE_CMS_DATA = {
  form: {
    button: {
      next: 'Next',
      previous: 'Previous',
      submit: 'Submit',
      getQuotes: 'Get quotes',
      another: 'Next Member',
    },
    health: {
      title: {
        healthPlan: 'Medical Plan',
        member: 'Member Selection',
        health: 'Update Member(s) Health',
        medigap: 'Medigap Requests',
        majorMedical: 'Major Medical Requests',
        visDent: 'Vision and Dental Insurance',
      },
      description: {
        healthPlan: 'Please select which Medical plan you are wanting quotes - for which you qualify. If different Members are in a different plan, please complete a separate Quotes Request for each.',
        member: `Please select the Household Member(s) to be associated with this quote. Also, please use "Update existing" if any changes have occured to any Member that is to be included. If you have not entered (all of) them yet, please "Add another."`,
        health: 'For each Household Member getting insurance, a Health form will need to be filled out or updated.',
        medigap: 'Please specify what Medicare package or Part you are wanting. If you are getting a Medigap that includes Part A and B, along with additional coverages, please select that option and you will be prompted to enter the letter for the specifc part you are interested in. Note that Part F is no longer available. However, the major difference between Part F and G is just that Part F covers the deductible for Part B but the premium for Part G is lower, offsetting the difference.',
        majorMedical: 'Please select from these inquiries to give the Agent a better idea of what coverages you want. If you aren\'t sure, please select Not sure. Note that the Dedcutible and Maximmum Out-Of-Pocket are based on a single person. So, if you are getting a family plan, please expect these amounts to double.',
        visDent: 'If you want Vision or Dental, please select weather you want Basic or Preferred coverages or select Not sure.',
      },
      label: {
        healthPlan: 'Medical plan',
        member: 'Select member(s)',
        health: 'Update members health information',
        reasMedg: 'Qualification for Medigap',
        active: 'Are you actively working?',
        covPart: 'What Part are you wanting?',
        medcSOrMedg: 'Which one of these?',
        majorPlan: 'Long Term or Short Term',
        networkType: 'Network Type',
        policyType: 'Policy Type',
        deductRange: 'Preferred Deductible range (family plan will double this value)',
        maxOutOfPock: 'Preferred Maximum Out-Of-Pocket range (family plan will double this value)',
        visionPlan: 'Vision Plan',
        dentalPlan: 'Dental Plan',
        other: 'Please elaborate',
      },
      dropdown: {
        healthPlan: [
          { value: 'majorMedical', key: 'Major Medical' },
          { value: 'medicare', key: 'Medicare' },
        ],
        reasMedg: [
          { value: 'sixtyFiveOrOver', label: '65 or over (or will be in the next month)' },
          { value: 'ESRD', label: 'Kidney Disease (ESRD – End Stage Renal Disease)' },
          { value: 'ALS', label: 'Amyotrophic Lateral Sclerosis (ALS)' },
          { value: 'disability', label: 'Other disability' },
          { value: 'other', label: 'Other' },
        ],
        covPart: [
          { value: 'hospital', label: 'A - Hospital Coverage' },
          { value: 'medical', label: 'B - Medical Coverage' },
          { value: 'prescription', label: 'D - Prescription Drug Coverage' },
          { value: 'medcSOrMedg', label: 'Medicare Supplement or Medigap coverages that include A & B' },
          { value: 'notSure', label: 'Not Sure' },
        ],
        medcSOrMedg: [
          { value: 'g', key: 'G' },
          { value: 'h', key: 'H' },
          { value: 'j', key: 'J' },
          { value: 'k', key: 'K' },
          { value: 'l', key: 'L' },
          { value: 'm', key: 'M' },
          { value: 'n', key: 'N' },
        ],
        majorPlan: [
          { value: 'LT', key: 'Long Term (ACA - Permanent)' },
          { value: 'ST', key: 'Short Term' },
          { value: 'notSure', key: 'Not sure' },
        ],
        networkType: [
          { value: 'HMO', key: 'HMO (Health Maintenance Organization)' },
          { value: 'PPO', key: 'PPO (Preferred Provider Organization)' },
          { value: 'EPO', key: 'EPO (Exclusive Provider Organization)' },
          { value: 'POS', key: 'POS (Point Of Service)' },
          { value: 'notSure', key: 'Not sure' },
        ],
        policyType: [
          { value: 'catastrophic', key: 'Catastrophic or High Deductible Health Plan (HDHP)' },
          { value: 'bronze', key: 'Bronze – 40% Coinsurance' },
          { value: 'silver', key: 'Silver – 30% Coinsurance' },
          { value: 'gold', key: 'Gold – 20% Coinsurance' },
          { value: 'platinum', key: 'Platinum – 10% Coinsurance' },
          { value: 'notSure', key: 'Not sure' },
        ],
        deductRange: [
          { value: '500To1000', key: '$500 - $1000' },
          { value: '1000To1500', key: '$1000 - $1500' },
          { value: '1500To2000', key: '$1500 - $2000' },
          { value: '2000To2500', key: '$2000 - $2500' },
          { value: '2500To3000', key: '$2500 - $3000' },
          { value: '3000To4000', key: '$3000 - $4000' },
          { value: 'higher', key: 'Higher' },
          { value: 'notSure', key: 'Not sure' },
        ],
        maxOutOfPock: [
          { value: '2000To3000', key: '$2000 - $3000' },
          { value: '3000To4000', key: '$3000 - $4000' },
          { value: '4000To5000', key: '$4000 - $5000' },
          { value: '5000To6000', key: '$5000 - $6000' },
          { value: '6000To7000', key: '$6000 - $7000' },
          { value: '7000To8000', key: '$7000 - $8000' },
          { value: 'higher', key: 'Higher' },
          { value: 'notSure', key: 'Not sure' },
        ],
        visionPlan: [
          { value: 'basic', key: 'Basic' },
          { value: 'preferred', key: 'Preferred' },
          { value: 'notSure', key: 'Not sure' },
          { value: 'none', key: 'None' },
        ],
        dentalPlan: [
          { value: 'basic', key: 'Basic' },
          { value: 'preferred', key: 'Preferred' },
          { value: 'notSure', key: 'Not sure' },
          { value: 'none', key: 'None' },
        ],
        active: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      }
    },
  },
  popOver: {
    medPlan: {
      content: {
        healthPlan: "In most cases, if you are under 65, you will select Major Medical and, if you are over 65, you will select Medicare. Otherwise, if there is a condition or situation that qualifies you, enrollment into anything other than Major Medical is required, which will distinguish which. In Step 3, you will be asked for enrollment qualification.",
      }
    },
    majorMedReq: {
      content: {
        majorPlan: "If you are trying to access Open Enrollment (during November and December) to get on an ACA (Affordable Care Act) plan, then you want to select Long Term. If it's another time of year (can't enroll in ACA) or you are young, healthy (no pre-existing conditions), and aren't worried about being dropped from coverage due to multiple ailments or major injuries, you could save some money with a Short Term plan.",
        networkType: "HMOs usually have lower premiums but are limited to In-Network Providers and specialists require referrals from your Primary Care Physician. POS plans also require referrals for specialist but are little more because they allow Out-Of-Network Providers. EPOs allows the freedom of giong to a specialist without a referral and have larger networks than HMOs but are limited to In-Network. PPOs have the highest premiums but don't require referrals for specialists and you have the option to go Out-Of-Network. EPOs and POSs are less common.",
        policyType: "Metal grades are used to denote the percentage that you pay after a deductible is met. The higher the Metal the lower the percentage that you pay but the premiums are also a bit higher. Then, there are High Deductible Health Plans (HDHP) which have the lowest premiums but are designed for healthy individuals, without chronic conditions. If you want a Health Savings Account (HSA) or a Health Reimbursement Arrangement (HRA), select HDHP and discuss it with the Agents on the Message Board.",
        deductRange: "The deductible is the ammount that you pay, in a year, before the Carrier starts paying any percentage. This is simply a way for you to let the Agents know what range you would like to be in. If you have chronic health conditions, you might want a lower deductible. Note that whatever you select here will double if you are getting a family plan. This isn't necessary for HDHP.",
        maxOutOfPock: "The Maximum Out-Of-Pocket is the most that you'll pay in a year, in addition to your premium, that is. This is simply a way for you to let the Agents know what range you would like to be in. If you have chronic health conditions, you might want a lower Maximum Out-Of-Pocket. Note that whatever you select here will double if you are getting a family plan. This isn't necessary for HDHP.",
      }
    },
    visDent: {
      content: {
        visionPlan: "Do you want a Vision plan? If you simply go for an annual eye exam for Glasses and/or contacts, Basic should suffice. If it's more involved, where contact fittings, lens protection, etc. are needed, Preferred would likely be the better choice. The Carrier sets the limits on amounts (including the amount based on a particular brand) that they cover.",
        dentalPlan: "Do you want a Dental plan? If you simply go for an annual exams with cleaning and maybe some fillings, Basic should suffice. If you're needing Crowns, Denture, Bridges, etc. you might consider a Preferred plan. The Carrier sets the limits on amounts that they cover.",
      }
    },
    medigap: {
      content: {
        reasMedg: "Please select what qualifies the Member(s) for this coverage. If there's more than one reason, select all that apply. And, if it's for more than one Member with different reasons, please also select Other and ue the box to explain who has what qualification, etc. Medigap fills in gaps not covered by the government program that you are under, whether it is Medicare, Medicaid, or CHIP.",
        covPart: "This can get complicated so feel free to select Unsure and discuss it in the Chat Room with the Agents. For those who are familiar with the Medigap they want, please let it be known. If you are simply enrolling in Medicare and do not want Medigap, simply select Part A and B, plus D, if interested in prescriptions.",
      }
    },
  }
}

