import MemberPersonalDetails
  from '../../components/member-steps/member-personal-details.component';
import MemberLegalStatus
  from '../../components/member-steps/member-legal-status.component';
import MemberWorkDetails
  from '../../components/member-steps/member-work-details.component';
import MemberBussDetails
  from '../../components/member-steps/member-business-details.component';
import MemberEducation
  from '../../components/member-steps/member-education.component';
import MemberFelonyHistory
  from '../../components/member-steps/member-felony-history.component';

import loadable from '@loadable/component';

const MemberContactInfo = loadable(() => import('../../components/member-steps/member-contact-info.component'));
const MemberPhoneVerify = loadable(() => import('../../components/member-steps/member-twilio.component'));

export const RULE_ENGINE = {
  'member-quotes': [
    [
      {
        heading: 'Member Information',
        subHeading:
          'Information about Self. Required for all quotes, including quotes for other household members. Note that the more detailed and accurate the information you provide, the better our Agents can place you with the appropriate Carrier and more accurate quotes.',
      },
    ],
    [
      {
        key: 'personalDetails',
        name: 'Member Details',
        component: MemberPersonalDetails,
      },
      {
        key: 'contactInfo',
        name: 'Contact Information',
        component: MemberContactInfo,
      },
      {
        key: 'legalStatus',
        name: 'Legal Status',
        component: MemberLegalStatus,
      },
      {
        key: 'workDetails',
        name: 'Work Details',
        component: MemberWorkDetails,
      },
      {
        key: 'businessDetails',
        name: 'Business Details',
        component: MemberBussDetails,
      },
      {
        key: 'education',
        name: 'Education',
        component: MemberEducation,
      },
      {
        key: 'felonyHistory',
        name: 'Criminal History',
        component: MemberFelonyHistory,
      },
      {
        key: 'phoneVerify',
        name: 'Phone Verification',
        component: MemberPhoneVerify,
      },
    ]
  ],
}