const navigationActionTypes = {
  SET_CURRENT_USER_TYPE: 'SET_CURRENT_USER_TYPE',
  TOGGLE_LOGIN_REGISTRATION_VIEW: 'TOGGLE_LOGIN_REGISTRATION_VIEW',
  SHOW_ACCOUNT_ACTIVATION_VIEW: 'SHOW_ACCOUNT_ACTIVATION_VIEW',
  SHOW_MEMBER_DASHBOARD_VIEW: 'SHOW_MEMBER_DASHBOARD_VIEW',
  SHOW_AGENT_DASHBOARD_VIEW: 'SHOW_AGENT_DASHBOARD_VIEW',
  SHOW_MEMBER_SHOWCASE_VIEW: 'SHOW_MEMBER_SHOWCASE_VIEW',
  SHOW_AGENT_SHOWCASE_VIEW: 'SHOW_AGENT_SHOWCASE_VIEW',
};

export default navigationActionTypes;
