import { AGENT_ANNUITY_QUOTE_CMS_DATA }
  from '../../../pages/agent-quotes/annuity/annuity-quote.cms';

// const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));
// let memberDetails;

// if (PERSISTED_STATE) {
//   memberDetails = JSON.parse(PERSISTED_STATE.auth);
// }

export const convertAgentAnnuityQuoteFromDBSave = (data) => {
  const {
    form: {
      guaranteedPremium, type, subType
    },
  } = AGENT_ANNUITY_QUOTE_CMS_DATA;

  const hasGuaranteedPremium = guaranteedPremium.dropdown;
  const types = type.dropdown.annuity;
  const maturityPayOuts = type.dropdown.maturityPayOut;
  const subTypes = subType.dropdown;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    createdAgentQuoteDate: !data.isSelected ? new Date() : '',
    policyStartDate: data.isSelected ? new Date() : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    storedMember: data.storedMember ? data.storedMember : '',
    documents: data.documents ? data.documents : [],
    polDocuments: data.polDocuments ? data.polDocuments : [],
    quoteDetails: {
      type: data.quoteType ? data.quoteType : '',
      quoteId: data.quoteId ? data.quoteId : '',
      agentId: data.agentId ? data.agentId : '',
      biddingId: data.biddingId ? data.biddingId : '',
      createdDate: data.quoteCreatedDate ? data.quoteCreatedDate : '',
      isCaptive: data.isCaptive ? data.isCaptive : '',
      servicesProvidedTo: data.servicesProvidedTo ? data.servicesProvidedTo : '',
      isSelected: data.isSelected ? data.isSelected : false,
      isPolicy: data.isPolicy ? data.isPolicy : false,
    },
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    isGuaranteedPremium: {
      isTypeOf:
        data.guaranteedPremium
          ? data.guaranteedPremium === hasGuaranteedPremium[0].value
          : false,
      yearlyIncrements:
        data.increments
          ? data.increments
          : '',
    },
    type: {
      immediateIncome: {
        isTypeOf:
          data.typeOfAnnuity !== null
            ? data.typeOfAnnuity === types[0].value
            : false,
        durationOfIncome: {
          start:
            data.typeOfAnnuity === types[0].value
              ? data.startDate
              : '',
          end:
            data.typeOfAnnuity === types[0].value
              ? data.endDate
              : '',
        },
        monthlyIncome:
          data.monthlyIncome !== null
            ? data.monthlyIncome
            : '',
      },
      deferred: {
        isTypeOf:
          data.typeOfAnnuity !== null
            ? data.typeOfAnnuity === types[1].value
            : false,
        maturityDate:
          data.typeOfAnnuity === types[1].value
            ? data.maturityDate
            : '',
        maturityPayOut: {
          paidFull: {
            isTypeOf:
              data.maturityPayOut !== null
                ? data.maturityPayOut === maturityPayOuts[0].value
                : false,
            paidFullAmount:
              data.maturityPayOut === maturityPayOuts[0].value
                ? data.paidFullAmount
                : '',
          },
          monthly: {
            isTypeOf:
              data.maturityPayOut !== null
                ? data.maturityPayOut === maturityPayOuts[1].value
                : false,
            monthlyAmount:
              data.maturityPayOut === maturityPayOuts[1].value
                ? data.monthlyAmount
                : '',
          }
        },
      },
    },
    subType: {
      fixed: {
        isTypeOf:
          data.subType !== null
            ? data.subType === subTypes[0].value
            : false,
        setInterest:
          data.setInterest
            ? data.setInterest
            : '',
      },
      indexed: {
        isTypeOf:
          data.subType !== null
            ? data.subType === subTypes[1].value
            : false,
        guaranteedInterest:
          data.subType === subTypes[1].value
            ? data.guaranteedInterest
            : '',
      },
      variable: {
        isTypeOf:
          data.subType !== null
            ? data.subType === subTypes[2].value
            : false,
        predictedInterest:
          data.subType === subTypes[2].value
            ? data.predictedInterest
            : '',
      }
    },
    additionalInfo: data.additionalInfo,
  }
  return payload;
}




