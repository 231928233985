export const convertHealthBiddingForDBSave = (data) => {
  const payload = {
    type: data.type ? data.type : '',
    agentId: data.agentId ? data.agentId : '',
    quoteId: data.quoteId ? data.quoteId : '',
    agentQuoteId: data.agentQuoteId ? data.agentQuoteId : '',
    createdDate: data.createdDate ? data.createdDate : '',
    selectedDate: data.selectedDate ? data.selectedDate : '',
    isCaptive: data.isCaptive ? data.isCaptive : '',
    servicesProvidedTo:
      data.servicesProvidedTo
        ? data.servicesProvidedTo
        : '',
    isSelected: data.isSelected ? data.isSelected : false,
  };

  return payload;
};



