import React from 'react';
import { Field } from 'formik';

const Checkbox = ({ id, name }) => {
  const path = window.location.pathname.replace(/\//g, '');
  let isAgent = false;
  if (path.includes('agent')) {
    isAgent = true;
  }

  return (
    <Field name={name}>
      {({ field, form }) => (
        <input
          disabled={isAgent ? true : false}
          type='checkbox'
          id={id}
          className='d-none'
          checked={field.value}
          {...field}
        />
      )}
    </Field>
  );
}

export default Checkbox;
