export const PROPERTY_CMS_DATA = {
  form: {
    button: {
      next: 'Next',
      previous: 'Previous',
      submit: 'Submit',
      getQuotes: 'Get quotes',
      add: 'Add another',
      update: 'Update existing',
      hide: 'Hide',
    },
    property: {
      title: {
        member: 'Member Selection',
        property: 'Property Selection',
        reOrACV: 'Content Coverage and Additional Concerns',
        ownOrFinance: 'Suggested Coverages',
        isOwnedOrFinanced: 'Ownership',
        combineCV: 'Combine Coverages',
      },
      description: {
        member: 'Please select the Household Member(s) to be associated with this quote. Also, please use "Update existing" if any changes have occured to any Member that is to be included. If you have not entered (all of) them yet, please "Add another."',
        property: 'Please select the Property that is to be associated with this quote. Also, please use "Update existing" if any changes have occured to any Property that is to be included. If you have not entered it yet, please "Add Another"',
        reOrACV: 'Please feel free to select whether you want Actual Cash Value (ACV) or Replacement Cost on your contents or items, like furniture, in your property, as well as enter any other request or concerns in the box.',
        ownOrFinance: 'Please feel free to select from these options to give the Agent a better idea of your preferences. Note that some coverages depend on what\'s available in the area of the property and on the carriers.',
        isOwnedOrFinanced: '',
        combineCV: 'Most Carriers offer discounts for combining Home and Auto (Vehicle) coverages together.',
      },
      label: {
        member: 'Select member(s)',
        property: 'Select property',
        scale: 'Coverage scale',
        isOwned: 'Do you own property?',
        acv: 'Do you want ACV coverage?',
        floodEarthquake: 'Would you like flood or earthquake coverage?',
        reOrACV: 'Replacement Cost or ACV on contents',
        isOwnedOrFinanced: 'Is your property owned or financed?',
        deductible: 'Preferred deductible amount',
        combineCV: 'Would you like to combine your Auto insurance?',
        combineP: 'Which property would you like to combine with the Auto insurance?',
        additionalInfo: 'Are there any other coverage preferences or concerns?',
      },
      dropdown: {
        reOrACV: [
          { value: 'replacement', key: 'Replacement Cost' },
          { value: 'acv', key: 'ACV' },
          { value: 'unsure', key: 'Unsure' },
        ],
        isOwnedOrFinanced: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
        scale: [
          { value: 'scale1', key: '1 - Least expensive' },
          { value: 'scale2', key: '2 - A bit better coverage' },
          { value: 'scale3', key: '3 - Average coverage' },
          { value: 'scale4', key: '4 - Great coverage' },
          { value: 'scale5', key: '5 - Don\'t let me down' },
        ],
        isOwned: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
        acv: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
        floodEarthquake: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
          { value: 'unsure', key: 'Unsure' },
        ],
        deductible: [
          { value: 'none', key: 'Unsure' },
          { value: '500', key: '$500' },
          { value: '1000', key: '$1,000' },
          { value: '1500', key: '$1,500' },
          { value: '2000', key: '$2,000' },
          { value: 'onePercent', key: '1%' },
          { value: 'twoPercent', key: '2%' },
          { value: 'threePercent', key: '3%' },
          { value: 'fourPercent', key: '4%' },
          { value: 'fivePercent', key: '5%' },
        ],
        combineCV: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      }
    }
  },
  popOver: {
    suggestedCov: {
      content: {
        scale: "This is designed to let the Agents know whether you're interested in more coverages or lower cost.",
        floodEarthquake: "Depending on the area you live in, this will let the Agent know whether you are interested in either flood coverage, earthquake coverage, or both. They might advise you on whether it makes sense for you and likely give you the difference in premium with or without this coverage.",
        deductible: "Preferred deductible might be required to be a certain range by a Mortgage company, HOA, etc., but this will give the Agent an idea. The lower the deductible, the higher the premium. If you qualify and selected, Least Expensive coverage in the Coverage scale inquiry, you can request ACV, explained below in the Replacement Cost or ACV on contents inquiry. The box below is where ACV can be requested.",
      }
    },
    contentCov: {
      content: {
        reOrACV: 'ACV is the depreciated value and Replacement Cost is how much it would cost to replace something at the time of loss, with the item\'s current purchase price (accounts for inflation to get the item brand new). The premium for ACV is lower but doesn\'t fully cover the replacement of belongings.',
      }
    }
  }
}

