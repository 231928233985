import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';

export const convertMemberForDBSave = (data) => {
  const {
    form: {
      personalDetails: {
        label: {
          dropdown: { memberTypes, genders },
        },
      },
      identification: {
        label: {
          dropdown: { documentTypes },
        },
      },
      legalStatus: {
        label: {
          dropdown: { legalDocumentTypes },
        },
      },
      workDetails: {
        label: {
          dropdown: { militaryServiceTypes },
        },
      },
      businessDetails: {
        label: {
          dropdown: { businessOwnershipChoices },
        },
      },
      history: {
        felony: {
          label: {
            dropdown: { currentStatus },
          },
        },
      },
    },
  } = MEMBER_CMS_DATA;

  const payload = {
    type: {
      self: data.memberType === memberTypes[0].value,
      spouse: data.memberType === memberTypes[1].value,
      partner: data.memberType === memberTypes[2].value,
      child: data.memberType === memberTypes[3].value,
      adoptedChild: data.memberType === memberTypes[4].value,
      parent: data.memberType === memberTypes[5].value,
      sibling: data.memberType === memberTypes[6].value,
      careGiver: data.memberType === memberTypes[7].value,
      stepChild: data.memberType === memberTypes[8].value,
      transferStudent: data.memberType === memberTypes[9].value,
    },
    details: {
      firstName: data.firstName ? data.firstName : '',
      middleName: data.middleName ? data.middleName: '',
      lastName: data.lastName ? data.lastName : '',
      email: data.email ? data.email : '',
      male: data.gender === genders[0].value,
      female: data.gender === genders[1].value,
      otherGenders: data.gender === genders[2].value,
      dateOfBirth: data.dateOfBirth ? data.dateOfBirth : '',
      mobile: data.mobile ? data.mobile : '',
      landline: data.landline ? data.landline : '',
      isPhoneValid: data.isPhoneValid ? data.isPhoneValid : false,
    },
    address: {
      streetAddress: data.street ? data.street : '',
      city: data.city ? data.city : '',
      state: data.state ? data.state : '',
      zipCode: data.zipCode ? data.zipCode : '',
    },
    identification: {
      driversLicense:
        data.documentType !== ''
          ? data.documentType === documentTypes[0].value
          : false,
      stateID:
        data.documentType !== ''
          ? data.documentType === documentTypes[1].value
          : false,
      driverPermit:
        data.documentType !== ''
          ? data.documentType === documentTypes[2].value
          : false,
      passport:
        data.documentType !== ''
          ? data.documentType === documentTypes[3].value
          : false,
      none:
        data.documentType === 'none'
          ? true
          : false,
      state: data.documentIssueState ? data.documentIssueState : '',
      number: data.documentNumber ? data.documentNumber : '',
      expirationDate: data.expiration ? data.expiration : '',
    },
    militaryService: {
      isServing: data.militaryServiceType === militaryServiceTypes[0].value,
      isInReserves: data.militaryServiceType === militaryServiceTypes[1].value,
      isVeteran: data.militaryServiceType === militaryServiceTypes[2].value,
      isDirectlyRelatedTo:
        data.militaryServiceType === militaryServiceTypes[3].value,
      none:
        data.militaryServiceType === militaryServiceTypes[4].value,
    },
    education: {
      acquisitions: data.acquisitions ? data.acquisitions : '',
      degreeType: data.degreeType ? data.degreeType : '',
    },
    occupations: data.occupations ? data.occupations : '',
    business: {
      isOwner: data.businessOwnership === businessOwnershipChoices[0].value,
      name: data.businessName,
      address: {
        streetAddress: data.businessStreet ? data.businessStreet : '',
        city: data.businessCity ? data.businessCity : '',
        state: data.businessState ? data.businessState : '',
        zipCode: data.businessZipCode !== null ? data.businessZipCode : '',
      },
    },
    annualIncome: data.annualIncome ? data.annualIncome : '',
    legalStatus: {
      citizen: data.legalDocumentType === legalDocumentTypes[0].value,
      ssn:
        data.legalDocumentType === legalDocumentTypes[0].value
          ? data.legalDocumentNumber
          : '',
      alienRegistration: data.legalDocumentType === legalDocumentTypes[1].value,
      alienRegistrationNumber:
        data.legalDocumentType === legalDocumentTypes[1].value
          ? data.legalDocumentNumber
          : '',
      visa: data.legalDocumentType === legalDocumentTypes[2].value,
      uscisNumber:
        data.legalDocumentType === legalDocumentTypes[2].value
          ? data.legalDocumentNumber
          : '',
    },
    felonyStatus: {
      hasBeenConvicted: data.felonyStatus === currentStatus[0].value,
      reasonForConviction: data.felonyReason ? data.felonyReason : '',
    },
    householdMembers: data.householdMembers ? data.householdMembers : [],
    propertyPolicies: data.propertyPolicies ? data.propertyPolicies : [],
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    profileId: data.profileId ? data.profileId : '',
    agentId: data.agentId ? data.agentId : '',
    carriersAddresses: data.carriersAddresses ? data.carriersAddresses : {},
    isMemberLastStep: data.isMemberLastStep ? data.isMemberLastStep : false,
  };
  return payload;
};

export const calculateAge = (dt) => {
  let birthday = new Date(dt);
  let ageDifMs = Date.now() - birthday.getTime();
  let ageDate = new Date(ageDifMs); // miliseconds from epoch
  let age = Math.abs(ageDate.getUTCFullYear() - 1970);
  return age;
};
