import { ModalState, ModalTypes } from '../../modal/modal.types';
import propertyBiddingActionTypes from './property-bidding.types';

const payload = {
  context: 'propertyBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: propertyBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsPropertyBid = (toggle) => ({
  type: propertyBiddingActionTypes.SET_IS_PROPERTY_BID,
  payload: toggle,
});

export const setShowPropertyQuotes = (toggle) => ({
  type: propertyBiddingActionTypes.SET_SHOW_PROPERTY_QUOTES,
  payload: toggle,
});

export const startPropertyBiddingSave = (values) => ({
  type: propertyBiddingActionTypes.START_PROPERTY_BIDDING_SAVE,
  payload: values,
});

export const propertyBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const propertyBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startPropertyBiddingUpdate = (id, formData) => ({
  type: propertyBiddingActionTypes.START_PROPERTY_BIDDING_UPDATE,
  payload: { id, formData },
});

export const propertyBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const propertyBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

