import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../helpers/configure-axios";
import {
  showMemberShowcase,
  showAgentShowcase,
} from "../navigation/navigation.actions";
import {
  profileSaveSuccess,
  profileSaveFailure,
  profileUpdateSuccess,
  profileUpdateFailure,
  fetchProfileFromDBSuccess,
  fetchProfileFromDBFailure,
  resetPasswordInProfileSuccess,
  resetPasswordInProfileFailure,
} from "./profile.actions";
import profileActionTypes from "./profile.types";
import { convertProfileForDBSave } from "./profile.utils";

function* saveProfile({ payload: formData }) {
  try {
    const data = yield call(convertProfileForDBSave, formData);
    yield axios.post("/api/profiles", data, configureAxios());
    yield put(profileSaveSuccess());
  } catch (e) {
    yield put(profileSaveFailure(e));
  }
}

function* updateProfile({ payload: { id, formData } }) {
  try {
    const data = yield call(convertProfileForDBSave, formData);
    const response = yield axios.patch(
      `/api/profiles/${id}`,
      data,
      configureAxios()
    );
    const profile = yield JSON.parse(response.config.data);
    yield put(profileUpdateSuccess(profile));
  } catch (e) {
    yield put(profileUpdateFailure(e));
  }
}

function* fetchProfileFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/profiles/${id}`);
    const profile = yield response.data;
    yield put(fetchProfileFromDBSuccess(profile));
  } catch (e) {
    yield put(fetchProfileFromDBFailure(e));
  }
}

export function* resetPasswordInProfile({ payload: user }) {
  try {
    const response = yield axios.post("/api/users/reset/password", user);
    yield put(resetPasswordInProfileSuccess(response.data));
    yield put(showMemberShowcase(false));
    yield put(showAgentShowcase(false));
  } catch (e) {
    yield put(resetPasswordInProfileFailure(e));
  }
}

function* onProfileSaveStart() {
  yield takeEvery(profileActionTypes.START_PROFILE_SAVE, saveProfile);
}

function* onProfileUpdateStart() {
  yield takeEvery(profileActionTypes.START_PROFILE_UPDATE, updateProfile);
}

function* onFetchProfileFromDB() {
  yield takeEvery(
    profileActionTypes.FETCH_PROFILE_FROM_DB_START,
    fetchProfileFromDB
  );
}

function* onResetPasswordInProfile() {
  yield takeEvery(
    profileActionTypes.START_RESET_PASSWORD_IN_PROFILE,
    resetPasswordInProfile
  );
}

export function* profileSagas() {
  yield all([
    call(onProfileSaveStart),
    call(onProfileUpdateStart),
    call(onFetchProfileFromDB),
    call(onResetPasswordInProfile),
  ]);
}
