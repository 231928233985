const animalBiddingActionTypes = {
  SET_SHOW_ANIMAL_QUOTES: 'SET_SHOW_ANIMAL_QUOTES',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_ANIMAL_BID: 'SET_IS_ANIMAL_BID',
  SET_IS_ANIMAL_SELECTED_BY_AI: 'SET_IS_ANIMAL_SELECTED_BY_AI',
  START_ANIMAL_BIDDING_SAVE: 'START_ANIMAL_BIDDING_SAVE',
  ANNUITY_BIDDING_SAVE_SUCCESS: 'ANNUITY_BIDDING_SAVE_SUCCESS',
  START_ANIMAL_BIDDING_UPDATE: 'START_ANIMAL_BIDDING_UPDATE',
  ANNUITY_BIDDING_UPDATE_SUCCESS: 'ANNUITY_BIDDING_UPDATE_SUCCESS',
  RESET_FORM: 'RESET_FORM',
};

export default animalBiddingActionTypes;
