import memberCompanionAnimalPolicyActionTypes 
  from './companion-animal.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  animalsFromDB: [],
  statesFromDB: [],
  animalPolicyFromDB: {},
  reset: false,
  clearForm: false,
}

const memberCompanionAnimalPolicyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberCompanionAnimalPolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload
      }
    case memberCompanionAnimalPolicyActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case memberCompanionAnimalPolicyActionTypes.FETCH_ANIMALS_FROM_DB_SUCCESS:
      return {
        ...state,
        animalsFromDB: action.payload
      };
    case memberCompanionAnimalPolicyActionTypes.FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_SUCCESS:
      return {
        ...state,
        animalPolicyFromDB: action.payload
      };
    case memberCompanionAnimalPolicyActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        animalPolicyFromDB: {}
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        animalsFromDB: [],
        statesFromDB: [],
        animalPolicyFromDB: {},
        reset: false,
        clearForm: false,
      }
    default:
      return state;
  }
}

export default memberCompanionAnimalPolicyReducer;