import * as yup from 'yup';
import { calculateAge } from '../../../redux/member/member.utils';

const PersonalDetailsSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required('Please enter first name')
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters'),
  middleName: yup
    .string()
    .trim()
    .min(1, 'Please enter at least one characters')
    .max(255, 'Please enter less than 255 characters')
    .optional(),
  lastName: yup
    .string()
    .trim()
    .required('Please enter last name')
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters'),
  dateOfBirth: yup
    .date()
    .typeError('Date must be valid')
    .required('Please provide a date of birth'),
  gender: yup.string().trim().required('Please select gender'),
  memberType: yup.string().trim().required('Please select member type'),
  documentType: yup
    .string()
    .trim()
    .min(2, 'Please enter document type')
    .max(255, 'Please enter document type')
    .when('dateOfBirth', {
      is: (val) => calculateAge(val) >= 18,
      then: yup.string().trim().required('Please select a document type'),
      otherwise: yup.string().notRequired(),
    }),
  documentNumber: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid document number')
    .max(13, 'Please enter a valid document number')
    .when('dateOfBirth', {
      is: (val) => calculateAge(val) >= 18,
      then: yup.string().required('Please enter a valid document number'),
      otherwise: yup.string().notRequired(),
    }),
  expiration: yup
    .date()
    .typeError('Date must be valid')
    .optional(),
  documentIssueState: yup
    .string()
    .trim()
    .min(2, 'Please enter state of document issue')
    .max(255, 'Please enter state of document issue')
    .when(['dateOfBirth', 'documentType'], {
      is: (dob, val) => calculateAge(dob) >= 18 && val !== 'passport',
      then: yup.string().required('Please provide state of issue'),
      otherwise: yup.string().notRequired(),
    })
    .optional(),
});

export default PersonalDetailsSchema;
