import { Form as FormikForm, withFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Carriers from '../../components/agent/agent-carriers.component';
import Contact from '../../components/agent/agent-contact.component';
import Details from '../../components/agent/agent-details.component';
import Identification from '../../components/agent/agent-identification.component';
import Licenses from '../../components/agent/agent-licenses.component';
import Policies from '../../components/agent/agent-policies.component';
import ServicesProvidedTo from '../../components/agent/agent-services.component';
import {
  startAgentUpdateLastStep,
  startFetchingAgentFromDB,
  startLoadingCarriersFromDB,
  startLoadingOccupationsFromDB,
  startLoadingStatesFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
  selectCarriers,
  selectOccupations,
  selectStates,
  selectCarriersObj,
} from '../../redux/agent/agent.selectors';
import { selectUser } from '../../redux/auth/auth.selectors';
import { AGENT_CMS_DATA } from './agent.cms';
import agentSchema from './agent.validation.schema';
import ErrorMessages from '../../components/error-message/error-messages.component';

const Agent = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  currentUser,
  carriers,
  states,
  agent,
  occupations,
  startFetchingAgentFromDB,
  startLoadingCarriersFromDB,
  startLoadingStatesFromDB,
  startLoadingOccupationsFromDB,
}) => {
  const formikProps = { values, handleChange, touched, errors };
  const {
    heading,
    subHeading,
    form: { button },
  } = AGENT_CMS_DATA;
  
  const errorsA = Object.keys(errors);

  const [showServicesProvided, setShowServicesProvided] = useState(false);

  let space = (errorsA.length * 32) + 40;

  useEffect(() => {
    startFetchingAgentFromDB(currentUser.agent);
  }, [
    currentUser.agent,
    startFetchingAgentFromDB,
  ]);

  useEffect(() => {
    if (values.isCaptive === 'yes') {
      setShowServicesProvided(true);
    }
    if (values.isCaptive === 'no') {
      setShowServicesProvided(false);
      values.applyTo = '';
      values.occupations = [{occupation: ''}];
    }
  }, [
    values.isCaptive,
    values.occupations,
    values.applyTo,
    setShowServicesProvided
  ])

  useEffect(() => {
    if (states.length === 0) {
      startLoadingStatesFromDB();
    }
  }, [states, startLoadingStatesFromDB]);

  useEffect(() => {
    if (carriers.length === 0) {
      startLoadingCarriersFromDB();
    }
  }, [carriers, startLoadingCarriersFromDB]);

  useEffect(() => {
    if (occupations.length === 0) {
      startLoadingOccupationsFromDB();
    }
  }, [occupations, startLoadingOccupationsFromDB]);

  return (
    <section
      className='ie-form-scaffold'
      style={
        errorsA && errorsA.length > 0
          ? { marginBottom: space }
          : { marginBottom: 15 }
      }
    >
      <Container>
        <p className='text-right'>
          Agent Id: {agent && agent._id ? agent._id.slice(-6) : ''}
        </p>
        <header>
          <h2>{heading}</h2>
          <h5>{subHeading}</h5>
        </header>
        <FormikForm noValidate>
          <Details {...formikProps} />
          <Contact {...formikProps} />
          <Identification {...formikProps} />
          <Licenses {...formikProps} />
          <Carriers {...formikProps} />
          <Policies {...formikProps} />
          {showServicesProvided ? (
            <ServicesProvidedTo {...formikProps} />
          ) : null}
          <div className='float-right'>
            <ErrorMessages errors={errors} />
            <div className='float-right'>
              <Button disabled={isSubmitting} onClick={handleSubmit}>
                {button.update}
              </Button>
            </div>
          </div>
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikAgent = withFormik({
  mapPropsToValues: ({ agent, carriersObj, currentUser }) => ({
    carriersObj: carriersObj ? carriersObj : {},
    firstName:
      agent.details && agent.details.firstName
        ? agent.details.firstName
        : '',
    middleName:
      agent.details && agent.details.middleName
        ? agent.details.middleName
        : '',
    lastName:
      agent.details && agent.details.lastName
        ? agent.details.lastName
        : '',
    email:
      agent.details && agent.details.email
        ? agent.details.email
        : '',
    phoneNumber:
      agent.details && agent.details.phoneNumber
        ? agent.details.phoneNumber
        : '',
    isPhoneValid:
      agent.details && agent.details.isPhoneValid
        ? agent.details.isPhoneValid
        : '',
    dateOfBirth:
      agent.details && agent.details.dateOfBirth
        ? new Date(agent.details.dateOfBirth)
        : '',
    agencyName:
      agent.details && agent.details.agency
        ? agent.details.agency
        : '',
    BStreet:
      agent.address && agent.address.streetAddress
        ? agent.address.streetAddress
        : '',
    BCity:
      agent.address && agent.address.city
        ? agent.address.city
        : '',
    BZipCode:
      agent.address && agent.address.zipCode
        ? agent.address.zipCode
        : '',
    BState:
      agent.address && agent.address.state
        ? agent.address.state
        : '',
    documentType:
      agent.identification
        ? (agent.identification.driversLicense && 'driversLicense') ||
          (agent.identification.passport && 'passport') ||
          (agent.identification.stateID && 'stateID')
        : '',
    documentNumber: agent.identification ? agent.identification.number : '',
    documentIssueState: agent.identification ? agent.identification.state : '',
    expiration: agent.identification ? agent.identification.expirationDate : '',
    nationalProducerNumber:
      agent.nationalProducerNumber
        ? agent.nationalProducerNumber
        : '',
    ssn: agent.ssn ? agent.ssn : '',
    licenses:
      agent.licenses && agent.licenses.length !== 0
        ? agent.licenses
        : [ {state: '', number: ''} ],
    isCaptive:
      agent.isCaptive
        ? agent.isCaptive.isTypeOf
          ? 'yes'
          : 'no'
        : '',
    carriers:
      agent.isCaptive && agent.isCaptive.carriers.length !== 0
        ? agent.isCaptive.carriers
        : [{ carrier: '' }],
    pAndC: agent.policies ? agent.policies.pAndC : [],
    lAndH: agent.policies ? agent.policies.lAndH : [],
    applyTo:
      agent.servicesProvidedTo &&
        agent.servicesProvidedTo.specificOccupations &&
        agent.servicesProvidedTo.militaryOccupations
        ?
        (agent.servicesProvidedTo.anyOne && 'anyOne') ||
        (agent.servicesProvidedTo.military && 'military') ||
        (agent.servicesProvidedTo.specificOccupations.isTypeOf && 'specificOccupations') ||
        (agent.servicesProvidedTo.militaryOccupations.isTypeOf && 'militaryOccupations')
      : '',
    occupations:
      agent.servicesProvidedTo &&  
      agent.servicesProvidedTo.specificOccupations &&
      agent.servicesProvidedTo.specificOccupations.isTypeOf === true
        ? agent.servicesProvidedTo.specificOccupations.occupations
        : agent.servicesProvidedTo &&
          agent.servicesProvidedTo.militaryOccupations &&
          agent.servicesProvidedTo.militaryOccupations.isTypeOf === true
          ? agent.servicesProvidedTo.militaryOccupations.occupations
          : [{ occupation: '' }],
    paymentConfirmation: '',
    additionalInfo: '',
    primeMemberId: currentUser ? currentUser.member : '',
    isAgentLastStep: agent.isAgentLastStep ? agent.isAgentLastStep : '',
  }),
  validationSchema: agentSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: { startAgentUpdateLastStep, agent }, setSubmitting }
  ) => {
    setSubmitting(true);
    if (agent._id) {
      startAgentUpdateLastStep(agent._id, values);
    }
    setSubmitting(false);
  },
})(Agent);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
  carriers: selectCarriers,
  states: selectStates,
  occupations: selectOccupations,
  carriersObj: selectCarriersObj,
});

export default withRouter(
  connect(mapStateToProps, {
    startAgentUpdateLastStep,
    startFetchingAgentFromDB,
    startLoadingCarriersFromDB,
    startLoadingStatesFromDB,
    startLoadingOccupationsFromDB,
  })(FormikAgent)
);
