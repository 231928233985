import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { agentData } from './form-data/agent-data';
import {
  setFormData,
  startAgentUpdate,
  startFetchingAgentFromDB,
  startGetPersonFromVeriff,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
  selectStates,
  selectFormData,
} from '../../redux/agent/agent.selectors';
import { selectUser } from '../../redux/auth/auth.selectors';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import ErrorMessages from '../../components/error-message/error-messages.component';
import {
  createVeriffObj,
  hideAndShowElements,
} from '../../Veriff';
import { Veriff } from '@veriff/js-sdk';

const AgentVeriff = ({
  values,
  touched,
  errors,
  agent,
  decrement,
  currentUser,
  handleSubmit,
  isSubmitting,
  setFormData,
  startAgentUpdate,
  startFetchingAgentFromDB,
  startGetPersonFromVeriff,
}) => {
  const formikProps = { values, touched, errors };
  const {
    form: { button, veriffInfo },
  } = AGENT_CMS_DATA;

  const [isNewSession, setIsNewSession] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [countDeclined, setCountDeclined] = useState(0);
  const [calDiff, setCalDiff] = useState(0);

  const veriffArr = agent.veriff ? agent.veriff : [];
  const lastVerification =
    veriffArr && veriffArr.length !== 0
      ? veriffArr[veriffArr.length - 1].verification
      : {}
  const veriffCreated =
    veriffArr && veriffArr.length
      ? veriffArr[veriffArr.length - 1].created
      : ''

  const veriff = Veriff({
    apiKey: process.env.REACT_APP_VERIFF_API_TOKEN,
    parentId: 'veriff-form',
    onSession: function(err, response) {
      // received the response, verification can be started / triggered now
      if (response && response.verification) {
        const veriffObj = createVeriffObj(response.verification);

        if (values && values.veriff) {
          values.veriff.push(veriffObj);
        }
        // Updated agent so we don't loose data
        startAgentUpdate(agent._id, values);
        setFormData(values);
        hideAndShowElements(response.verification)
      }
    }
  });

  veriff.setParams({
    person: {
      givenName: ' ',
      lastName: ' '
    },
    vendorData: agent._id ? agent._id : '',
  });

  const submitIdentification = async () => { 
    veriff.mount({
      submitBtnText: 'Get verified'
    });
    if (currentUser.agent) {
      startFetchingAgentFromDB(currentUser.agent);
    }
    if (calDiff > 0) {
      if (lastVerification) {
        hideAndShowElements(lastVerification)
      }
    }
    window.scrollTo({ top: 0 });
  }

  useEffect(() => {
    startFetchingAgentFromDB(currentUser.agent);
  }, [startFetchingAgentFromDB, currentUser])

  useEffect(() => {
    if (values) {
      setFormData(values);
    }
  }, [setFormData, values])

  useEffect(() => {
    if (veriffCreated) {
      const diff = (
        Date.parse(new Date(veriffCreated)) + 604800000) - Date.parse(new Date()
      );
      setCalDiff(diff);
    }
  }, [setCalDiff, veriffCreated])

  useEffect(() => {
    const veriffId = lastVerification.id;
    if (agent._id && veriffId && isApproved === false) {
      startGetPersonFromVeriff(agent._id, veriffId);
    }
  }, [
    agent._id,
    isApproved,
    lastVerification,
    startGetPersonFromVeriff
  ])

  useEffect(() => {
    if (
      veriffArr &&
      veriffArr.length === 0
    ) {
      setIsNewSession(true);
    } else if (lastVerification.status === 'declined') {
      setIsNewSession(true);
      values.veriff = veriffArr;
    } else {
      setIsNewSession(false);
    }
  }, [veriffArr, lastVerification, setIsNewSession, values.veriff])

  useEffect(() => {
    if (
      lastVerification.status !== 'approved' &&
      lastVerification.status !== 'declined'
    ) {
      setIsCreated(true);
      values.veriff = veriffArr;
    } else {
      setIsCreated(false);
    }
  }, [veriffArr, setIsCreated, lastVerification, values.veriff])

  useEffect(() => {
    if (lastVerification.status === 'approved') {
      setIsApproved(true);
      values.veriff = veriffArr;
    } else {
      setIsApproved(false);
    }
  }, [veriffArr, values.veriff, lastVerification, setIsApproved])

  useEffect(() => {
    if (
      veriffArr.length !== 0 &&
      lastVerification.status !== 'approved'
    ) {
      setIsShowMessage(true);
      values.veriff = veriffArr;
    } else {
      setIsShowMessage(false);
    }
  }, [veriffArr, setIsShowMessage, lastVerification, values.veriff])

  useEffect(() => {
    let countDecl = 0;

    if (
      veriffArr &&
      veriffArr.length !== 0
    ) {
      for (let i = 0; i < veriffArr.length; i++) {
        const verification = veriffArr[i].verification;
        if (
          verification &&
          verification.status === 'declined'
        ) {
          countDecl = countDecl + 1;
        }
      }
      setCountDeclined(countDecl);
      values.veriff = veriffArr;
    } 
  }, [veriffArr, setCountDeclined, values.veriff])  
  
  return (
    <section>
      <FormikForm noValidate {...formikProps}>
        <div className='ie-form'>
          <Row>
            <Col lg={4}>
              <h5>{veriffInfo.title}</h5>
              <h6>{veriffInfo.description}</h6>
            </Col>
            <Col lg={8} id='veriff-form'>
              <Form.Row>
                <Form.Group
                  as={Col}
                  lg={12}
                  controlId='free'
                  className='d-flex justify-content-center'
                >
                  <Form.Label>{
                    isApproved === false
                      ? <p>{veriffInfo.label}</p>
                      : isApproved === true
                        ? < p className='text-success'>{veriffInfo.success}</p>
                        : ''
                  }</Form.Label>
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='pb-2'>
            <Button
              onClick={() => {
                decrement();
                window.scrollTo({ top: 0 });
              }}
            >
              {button.previous}
            </Button>
          </div>
          <ErrorMessages errors={errors} />
          <div className='pb-2'>
            {isNewSession === true && countDeclined <= 2 ? (
              <Button
                disabled={
                  countDeclined > 1
                    ? true
                    : isSubmitting
                }
                onClick={() => {
                  veriff.mount({
                    submitBtnText: 'Get verified'
                  });
                  window.scrollTo({ top: 0 });
                }}
              >
                {button.verify}
              </Button>
            ) : isCreated === true && countDeclined < 2 ? (
              <Button
                disabled={
                  countDeclined > 1
                    ? true
                    : isSubmitting
                }
                onClick={submitIdentification}
              > 
                {button.verify}
              </Button>
            ) : isApproved === true ? (
              <Button
                disabled={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {button.next}
              </Button>
            ) : null}
          </div>
        </div>
        {isShowMessage === true ? (
          <p className='pb-5 text-danger text-right'>
            {countDeclined > 1 ? (
              veriffInfo.blocked
            ) : (
              veriffInfo.error
            )}
          </p>
        ) : null}
      </FormikForm>
    </section>
  );
};

const FormikAgentVeriff = withFormik({
  mapPropsToValues: ({ data, currentUser, agent }) => (
    agentData(agent, data, currentUser, false)
  ),
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: { startAgentUpdate, currentUser, increment, agent }, setSubmitting }
  ) => {
    const veriffArr = agent.veriff ? agent.veriff : [];
    const lastVerification =
      veriffArr && veriffArr.length !== 0
        ? veriffArr[veriffArr.length - 1].verification
        : {}
    
    setSubmitting(true);
    if (currentUser.agent) {
      startAgentUpdate(currentUser.agent, values);
    }
    setSubmitting(false);

    if (lastVerification.status === 'approved') {
      increment();
    }
    setTimeout(() => {
      window.location.reload();
    }, 200);
    window.scrollTo({ top: 0 });
  },
})(AgentVeriff);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
  states: selectStates,
  data: selectFormData,
});

export default withRouter(
  connect(mapStateToProps, {
    setFormData,
    startAgentUpdate,
    startFetchingAgentFromDB,
    startGetPersonFromVeriff,
  })(FormikAgentVeriff)
);
