import * as yup from 'yup';

const agentAppointmentsSchema = yup.object().shape({
  carriersObj: yup
    .object()
    .required('CarriersObj must be requireed'),
  carriers: yup
    .array()
    .of(
      yup.object().shape({
        carrier: yup
          .mixed()
          .test(
            'match',
            'Please select a carrier from the provided list or contact us if you want to add a carrier, not on the list',
            function (carrier) {
              return this.from[1].value.carriersObj[carrier];
            })
      })
    )
    .when('isCaptive', {
      is: (val) => val !== undefined,
      then: yup.array().required('Please select at least one option'),
      otherwise: yup.array().notRequired(),
    })
    .required('must have carriers'),
  pAndC: yup
    .array()
    .ensure()
    .when('lAndH', {
      is: (val) => val.length === 0,
      then: yup.array().required('Please select at least one option'),
      otherwise: yup.array().notRequired(),
    }),
  lAndH: yup.array().ensure(),
  applyTo: yup
    .string()
    .ensure()
    .when('isCaptive', {
      is: (val) => val === 'yes',
      then: yup.string().required('Please select at least one option'),
      otherwise: yup.string().notRequired(),
    }),
  occupations: yup
    .array()
    .of(
      yup.object().shape({
        occupation: yup.string().trim(),
      })
    )
    .when('applyTo', {
      is: (val) => val.value === 'specificOccupations' ||
        val.value === 'militaryOccupations',
      then: yup.array().required('Please select occupation'),
      otherwise: yup.array().notRequired(),
    })
    .required('must have occupations'),
});

export default agentAppointmentsSchema;
