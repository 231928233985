import { ModalState, ModalTypes } from '../../modal/modal.types';
import annuityQuoteActionTypes from './annuity.types';

const payload = {
  context: 'annuityQuote',
  status: '',
  error: '',
};

export const decrementCurrentStep = (currentStep) => ({
  type: annuityQuoteActionTypes.ANNUITY_DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: annuityQuoteActionTypes.ANNUITY_INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const setAnnuityFormData = (data) => ({
  type: annuityQuoteActionTypes.SET_ANNUITY_FORM_DATA,
  payload: data,
});

export const setQuoteId = (id) => ({
  type: annuityQuoteActionTypes.SET_ANNUITY_QUOTE_ID,
  payload: id,
});

export const setMembersInfo = (members) => ({
  type: annuityQuoteActionTypes.SET_MEMBERS_INFO,
  payload: members,
});

export const saveDeleteCometChat = (id) => ({
  type: annuityQuoteActionTypes.SAVE_DELETE_ANNUITY_COMET_CHAT,
  payload: id,
})

export const saveDeleteCometChatSuccess = () => ({
  type: annuityQuoteActionTypes.SAVE_DELETE_ANNUITY_COMET_CHAT_SUCCESS,
})

export const startFetchingAnnuityQuotesFromDB = () => ({
  type: annuityQuoteActionTypes.FETCH_ANNUITY_QUOTES_FROM_DB_START,
});

export const fetchAnnuityQuotesFromDBSuccess = (quote) => ({
  type: annuityQuoteActionTypes.FETCH_ANNUITY_QUOTES_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchAnnuityQuotesFromDBFailure = (e) => ({
  type: annuityQuoteActionTypes.FETCH_ANNUITY_QUOTES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingAnnuityQuoteFromDB = (id) => ({
  type: annuityQuoteActionTypes.FETCH_ANNUITY_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAnnuityQuoteFromDBSuccess = (quote) => ({
  type: annuityQuoteActionTypes.FETCH_ANNUITY_QUOTE_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchAnnuityQuoteFromDBFailure = (e) => ({
  type: annuityQuoteActionTypes.FETCH_ANNUITY_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startCheckingChatGroup = (id) => ({
  type: annuityQuoteActionTypes.START_CHECKING_CHAT_GROUP,
  payload: id,
})

export const checkingChatGroupSuccess = () => ({
  type: annuityQuoteActionTypes.CHECKING_CHAT_GROUP_SUCCESS,
})

export const startAnnuityQuoteSave = (quotes) => ({
  type: annuityQuoteActionTypes.START_ANNUITY_QUOTE_SAVE,
  payload: quotes,
});

export const annuityQuoteSaveSuccess = () => ({
  type: annuityQuoteActionTypes.ANNUITY_QUOTE_SAVE_SUCCESS,
});

export const annuityQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAnnuityQuoteUpdate = (id, formData) => ({
  type: annuityQuoteActionTypes.START_ANNUITY_QUOTE_UPDATE,
  payload: { id, formData },
});

export const annuityQuoteUpdateSuccess = () => ({
  type: annuityQuoteActionTypes.ANNUITY_QUOTE_UPDATE_SUCCESS,
});

export const annuityQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const lastStepAnnuityQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.QUOTE_REQUEST_SUCCESS,
  },
});

export const startAnnuityQuoteDelete = (id, PId) => ({
  type: annuityQuoteActionTypes.START_ANNUITY_QUOTE_DELETE,
  payload: { id, PId },
});

export const annuityQuoteDeleteSuccess = () => ({
  type: annuityQuoteActionTypes.ANNUITY_QUOTE_DELETE_SUCCESS,
});

export const annuityQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});



