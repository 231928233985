import * as yup from 'yup';

const EducationSchema = yup.object().shape({
  acquisitions: yup
    .array()
    .typeError('Please enter acquisitions')
    .optional(),
  degreeType: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(255, 'Please provide no more than 255 characters')
    .optional(),
})

export default EducationSchema;