const annuityBiddingActionTypes = {
  SET_SHOW_ANNUITY_QUOTES: 'SET_SHOW_ANNUITY_QUOTES',
  SET_SHOW_ALL_QUOTES: 'SET_SHOW_ALL_QUOTES',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_ANNUITY_BID: 'SET_IS_ANNUITY_BID',
  SET_IS_ANNUITY_SELECTED_BY_AI: 'SET_IS_ANNUITY_SELECTED_BY_AI',
  START_ANNUITY_BIDDING_SAVE: 'START_ANNUITY_BIDDING_SAVE',
  ANNUITY_BIDDING_SAVE_SUCCESS: 'ANNUITY_BIDDING_SAVE_SUCCESS',
  START_ANNUITY_BIDDING_UPDATE: 'START_ANNUITY_BIDDING_UPDATE',
  ANNUITY_BIDDING_UPDATE_SUCCESS: 'ANNUITY_BIDDING_UPDATE_SUCCESS',
  RESET_FORM: 'RESET_FORM',
};

export default annuityBiddingActionTypes;
