import * as yup from 'yup';

const agentLicensesSchema = yup.object().shape({
  licenses: yup
    .array()
    .of(
      yup.object().shape({
        state: yup
          .string()
          .trim()
          .min(2, 'Please enter at least two characters')
          .max(255, 'Please enter no more than 255 characters')
          .required('Please select state'),
        number: yup
          .number()
          .typeError('License number must be number')
          .max(9999999999999, 'Please enter no more than 9999999999999')
          .when('state', {
            is: (val) => val !== undefined,
            then: yup.number().required('Please enter license number'),
            otherwise: yup.number().notRequired(),
          }),
      })
    )
    .required('must have licenses'),
  nationalProducerNumber: yup
    .string()
    .trim()
    .min(0)
    .max(13, 'Please enter no more than 13 characters')
    .required('Please enter NPN'),
  ssn: yup
    .string()
    .min(4, 'Please enter last four numbers')
    .max(4, 'Please enter last four numbers')
    .required('Please enter SSN'),
});

export default agentLicensesSchema;
