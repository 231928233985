const hideAndShowElements = (response) => {
  let veriffBtn = document.getElementById('veriff-submit-btn');
  let veriffForm = document.querySelector('.veriff-container');

  if (response && response.id) {
    if (veriffBtn) {
      veriffBtn.style.display = 'none';
    }
    if (veriffForm) {
      let newDiv = document.createElement('div');
      let newEl = document.createElement('a');
      let newEl1 = document.createElement('a');

      newEl.appendChild(document.createTextNode('Cancel'));
      newEl.setAttribute('id', 'veriff-cancel');
      newEl.setAttribute('href', '/agent-dashboard');

      newEl1.appendChild(document.createTextNode('Next Step'));
      newEl1.setAttribute('id', 'veriff-refresh');
      newEl1.setAttribute('href', response.url);

      newDiv.setAttribute('id', 'veriff-box');
      newDiv.append(newEl);
      newDiv.append(newEl1);
      
      veriffForm.insertBefore(newDiv, veriffForm.childNodes[0]);

      let veriffBox = document.getElementById('veriff-box');
      let veriffCancel = document.getElementById('veriff-cancel');
      let veriffRefresh = document.getElementById('veriff-refresh');

      if (veriffBox) {
        veriffBox.style.display = 'flex';
        veriffBox.style.justifyContent = 'center';
      }
      if (veriffCancel) {
        veriffCancel.style.padding = '0 10px 10px'
      }
      if (veriffRefresh) {
        veriffRefresh.style.padding = '0 10px 10px'
      }
      if (response.status === 'approve') {
        veriffCancel.style.display = 'none';
        veriffRefresh.style.display = 'none';
      }
    }
  }
}



const createVeriffObj = (response) => {
  let result = {};
  result.created = new Date();
  result.verification = {};
  result.verification.id = response.id;
  result.verification.url = response.url;
  result.verification.status = response.status;

  return result;
}

export {
  createVeriffObj,
  hideAndShowElements,
}