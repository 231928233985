import { MEMBER_ANNUITY_POLICY_CMS_DATA }
  from '../../../pages/policies/annuity/annuity-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import agentCompanionAnimalQuoteActionTypes from './companion-animal.types';

const payload = {
  context: 'agentAnimalQuote',
  status: '',
  error: '',
}

export const setClearForm = (clear) => ({
  type: agentCompanionAnimalQuoteActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const setIsAgent = (agent) => ({
  type: agentCompanionAnimalQuoteActionTypes.SET_IS_AGENT,
  payload: agent
});

export const startFetchingAgentAnimalQuoteFromDB = (id) => ({
  type: agentCompanionAnimalQuoteActionTypes.FETCH_AGENT_COMPANION_ANIMAL_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAgentAnimalQuoteFromDBSuccess = (policy) => ({
  type: agentCompanionAnimalQuoteActionTypes.FETCH_AGENT_COMPANION_ANIMAL_QUOTE_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchAgentAnimalQuoteFromDBFailure = (e) => ({
  type: agentCompanionAnimalQuoteActionTypes.FETCH_AGENT_COMPANION_ANIMAL_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: agentCompanionAnimalQuoteActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: agentCompanionAnimalQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: agentCompanionAnimalQuoteActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_ANNUITY_POLICY_CMS_DATA.errMsg.states,
});

export const startAgentAnimalQuoteSave = (data) => ({
  type: agentCompanionAnimalQuoteActionTypes.START_AGENT_COMPANION_ANIMAL_QUOTE_SAVE,
  payload: data
});

export const agentAnimalQuoteSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_QUOTE_SUCCESS,
  },
});

export const agentAnimalQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentAnimalQuoteUpdate = (id, formData) => ({
  type: agentCompanionAnimalQuoteActionTypes.START_AGENT_COMPANION_ANIMAL_QUOTE_UPDATE,
  payload: { id, formData }
});

export const agentAnimalQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_UPDATE_QUOTE_SUCCESS,
  },
});

export const agentAnimalQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentAnimalQuoteDelete = (id, pId) => ({
  type: agentCompanionAnimalQuoteActionTypes.START_AGENT_ANIMAL_QUOTE_DELETE,
  payload: { id, pId },
});

export const agentAnimalQuoteDeleteSuccess = () => ({
  type: agentCompanionAnimalQuoteActionTypes.AGENT_ANIMAL_QUOTE_DELETE_SUCCESS,
});

export const agentAnimalQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});