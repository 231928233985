import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  healthSaveSuccess,
  healthSaveFailure,
  healthUpdateSuccess,
  healthUpdateFailure,
  healthQuotePageSaveSuccess,
  healthQuotePageSaveFailure,
  healthQuotePageUpdateSuccess,
  healthQuotePageUpdateFailure,
  fetchHealthFromDBSuccess,
  fetchHealthFromDBFailure,
  fetchHealthsFromDBSuccess,
  fetchHealthsFromDBFailure,
} from "./health.actions";
import { convertHealthForDBSave } from "./health.utils";
import healthActionTypes from "./health.types";

function* saveHealth({ payload: formData }) {
  try {
    const data = yield call(convertHealthForDBSave, formData);
    yield axios.post("/api/healths", data, configureAxios());
    yield put(healthSaveSuccess());
  } catch (e) {
    yield put(healthSaveFailure(e));
  }
}

function* updateHealth({ payload: { id, formData } }) {
  try {
    const data = yield call(convertHealthForDBSave, formData);
    const response = yield axios.patch(
      `/api/healths/${id}`,
      data,
      configureAxios()
    );
    const health = yield JSON.parse(response.config.data);
    yield put(healthUpdateSuccess(health));
  } catch (e) {
    yield put(healthUpdateFailure(e));
  }
}

function* saveHealthQuotePage({ payload: formData }) {
  try {
    const data = yield call(convertHealthForDBSave, formData);
    yield axios.post("/api/healths", data, configureAxios());
    yield put(healthQuotePageSaveSuccess());
  } catch (e) {
    yield put(healthQuotePageSaveFailure(e));
  }
}

function* updateHealthQuotePage({ payload: { id, formData } }) {
  try {
    const data = yield call(convertHealthForDBSave, formData);
    const response = yield axios.patch(
      `/api/healths/${id}`,
      data,
      configureAxios()
    );
    const health = yield JSON.parse(response.config.data);
    yield put(healthQuotePageUpdateSuccess(health));
  } catch (e) {
    yield put(healthQuotePageUpdateFailure(e));
  }
}

function* fetchHealthsFromDB() {
  try {
    const response = yield axios.get(`/api/healths`);
    const healths = yield response.data;
    yield put(fetchHealthsFromDBSuccess(healths));
  } catch (e) {
    yield put(fetchHealthsFromDBFailure(e));
  }
}

function* fetchHealthFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/healths/${id}`);
    const health = yield response.data;
    yield put(fetchHealthFromDBSuccess(health));
  } catch (e) {
    yield put(fetchHealthFromDBFailure(e));
  }
}

function* onHealthSaveStart() {
  yield takeEvery(healthActionTypes.START_HEALTH_SAVE, saveHealth);
}

function* onHealthUpdateStart() {
  yield takeEvery(healthActionTypes.START_HEALTH_UPDATE, updateHealth);
}

function* onHealthQuotePageSaveStart() {
  yield takeEvery(
    healthActionTypes.START_HEALTH_QUOTE_PAGE_SAVE,
    saveHealthQuotePage
  );
}

function* onHealthQuotePageUpdateStart() {
  yield takeEvery(
    healthActionTypes.START_HEALTH_QUOTE_PAGE_UPDATE,
    updateHealthQuotePage
  );
}

function* onFetchHealthsFromDB() {
  yield takeEvery(
    healthActionTypes.FETCH_HEALTHS_FROM_DB_START,
    fetchHealthsFromDB
  );
}

function* onFetchHealthFromDB() {
  yield takeEvery(
    healthActionTypes.FETCH_HEALTH_FROM_DB_START,
    fetchHealthFromDB
  );
}

export function* healthSagas() {
  yield all([
    call(onHealthSaveStart),
    call(onFetchHealthFromDB),
    call(onFetchHealthsFromDB),
    call(onHealthUpdateStart),
    call(onHealthQuotePageSaveStart),
    call(onHealthQuotePageUpdateStart),
  ]);
}
