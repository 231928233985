import { createSelector } from 'reselect';

const selectCombineCoverageQuote = (state) => state.combineCoverageQuote

export const selectCombineId = createSelector(
  [selectCombineCoverageQuote],
  (combineCoverageQuote) => combineCoverageQuote.combineId
);

export const selectCurrentStep = createSelector(
  [selectCombineCoverageQuote],
  (combineCoverageQuote) => combineCoverageQuote.currentStep
);

export const selectBidCombine = createSelector(
  [selectCombineCoverageQuote],
  (combineCoverageQuote) => combineCoverageQuote.bidCombine
);

export const selectCombinedFormData = createSelector(
  [selectCombineCoverageQuote],
  (combineCoverageQuote) => combineCoverageQuote.formData
);

export const selectCombineCoverageQuotesFromDB = createSelector(
  [selectCombineCoverageQuote],
  (combineCoverageQuote) => combineCoverageQuote.combineCoverageQuotesFromDB
);

export const selectCombineCoverageQuoteFromDB = createSelector(
  [selectCombineCoverageQuote],
  (combineCoverageQuote) => combineCoverageQuote.combineCoverageQuoteFromDB
);




