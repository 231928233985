import React from 'react';
import { Container } from 'react-bootstrap';
import './progress-bar.component.css';

const ProgressBar = ({ steps, currentStep }) => {

  return (
    <div className='ie-progress-bar-canvas' id='progress-bar'>
      <Container>
        <section className='ie-progress-bar'>
          {steps.map(({ key, name }, index) => (
              <div
                key={key}
                data-step={index}
                className={`${
                  currentStep === index
                    ? 'active'
                    : index < currentStep
                    ? 'visited'
                    : ''
                }`}
              >
                <span>{index + 1}</span>
                <p>{name}</p>
              </div>
          ))}
        </section>
      </Container>
    </div>
  );
};

export default ProgressBar;
