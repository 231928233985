import React from 'react';
import { Container } from 'react-bootstrap';
import { PROFILE_CMS_DATA } from './profile.cms';
import Member from '../member/member.component';
import ResetPassword from '../../components/reset-password/profile-reset-password.component';

const MemberProfile = () => {
  const {
    heading,
    subHeading,
  } = PROFILE_CMS_DATA;

  const screenW = window.innerWidth;

  return (
    <section className='ie-form-scaffold mx-3'>
      <Container>
        <header className={screenW < 525 ? 'pb-5' : 'py-4'}>
          <h2>{heading}</h2>
          <p>{subHeading}</p>
        </header>
        <Member />
        <ResetPassword />
      </Container>
    </section>
  );
};

export default MemberProfile;

