import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Identification from '../agent/agent-identification.component';
import { agentData } from './form-data/agent-data';
import {
  setFormData,
  startAgentUpdate,
  startAgentUpdateLastStep,
  startFetchingAgentFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
  selectFormData,
} from '../../redux/agent/agent.selectors';
import {
  selectPrimeMember,
} from '../../redux/member/member.selectors';
import { selectUser } from '../../redux/auth/auth.selectors';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import agentIdentificationSchema from './validations/agent-identification.validation.schema';
import ErrorMessages from '../../components/error-message/error-messages.component';

const AgentIdentification = ({
  values,
  touched,
  errors,
  currentUser,
  isSubmitting,
  handleSubmit,
  handleChange,
  decrement,
  setFormData,
  startFetchingAgentFromDB,
}) => {
  const formikProps = { values, handleChange, touched, errors };
  const {
    form: { button },
  } = AGENT_CMS_DATA;

  useEffect(() => {
    startFetchingAgentFromDB(currentUser.agent);
  }, [
    currentUser,
    startFetchingAgentFromDB,
  ]);

  useEffect(() => {
    if (values) {
      setFormData(values);
    }
  }, [setFormData, values])

  return (
    <section>
      <Container className='p-0'>
        <FormikForm noValidate>
          <Identification {...formikProps} />
          <div className='float-left pb-5'>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                decrement();
                window.scrollTo({ top: 0 });
              }}
            >
              {button.previous}
            </Button>
          </div>
          <ErrorMessages errors={errors}/>
          <div className='float-right pb-5'>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit();
                startFetchingAgentFromDB(currentUser.agent)
              }}
            >
              {button.next}
            </Button>
          </div>
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikAgentIdentification = withFormik({
  mapPropsToValues: ({ data, currentUser, agent }) => (
    agentData(agent, data, currentUser, false)
  ),
  validationSchema: agentIdentificationSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: { startAgentUpdate, currentUser, increment }, setSubmitting }
  ) => {
    setSubmitting(true);
    if (currentUser.agent) {
      startAgentUpdate(currentUser.agent, values);
    }
    setSubmitting(false);
    increment();
    window.scrollTo({ top: 0 });  },
})(AgentIdentification);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
  primeMember: selectPrimeMember,
  data: selectFormData,
});

export default withRouter(
  connect(mapStateToProps, {
    setFormData,
    startAgentUpdate,
    startAgentUpdateLastStep,
    startFetchingAgentFromDB,
  })(FormikAgentIdentification)
);
