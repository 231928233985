import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  animalBiddingSaveSuccess,
  animalBiddingSaveFailure,
  animalBiddingUpdateSuccess,
  animalBiddingUpdateFailure,
} from "./animal-bidding.actions";
import animalBiddingActionTypes from "./animal-bidding.types";
import { convertAnimalBiddingForDBSave } from "./animal-bidding.utils";

function* saveAnimalBidding({ payload: formData }) {
  try {
    const data = yield call(convertAnimalBiddingForDBSave, formData);
    yield axios.post("/api/animal-bidding", data, configureAxios());
    yield put(animalBiddingSaveSuccess());
  } catch (e) {
    yield put(animalBiddingSaveFailure(e));
  }
}

function* updateAnimalBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAnimalBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/animal-bidding/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(animalBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(animalBiddingUpdateFailure(e));
  }
}

function* onSaveAnimalBiddingStart() {
  yield takeEvery(
    animalBiddingActionTypes.START_ANIMAL_BIDDING_SAVE,
    saveAnimalBidding
  );
}

function* onUpdateAnimalBiddingStart() {
  yield takeEvery(
    animalBiddingActionTypes.START_ANIMAL_BIDDING_UPDATE,
    updateAnimalBidding
  );
}

export function* animalBiddingSagas() {
  yield all([call(onSaveAnimalBiddingStart), call(onUpdateAnimalBiddingStart)]);
}
