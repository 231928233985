import vehicleQuoteActionTypes from "./vehicle.types";
import { ModalTypes } from "../../modal/modal.types";
import authActionTypes from "../../auth/auth.types";

let INITIAL_STATE = {
  currentStep: 0,
  formData: {},
  timer: "",
  noCombineId: "",
  combineId: "",
  membersInfo: [],
  combineCV: false,
  vehicleQuotesFromDB: [],
  vehicleQuoteFromDB: {},
  reset: false,
};

const PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.vehicleQuote) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.vehicleQuote);
}

const vehicleQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case vehicleQuoteActionTypes.VEHICLE_QUOTE_SAVE_SUCCESS:
    case vehicleQuoteActionTypes.VEHICLE_QUOTE_UPDATE_SUCCESS:
    case vehicleQuoteActionTypes.VEHICLE_INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case vehicleQuoteActionTypes.VEHICLE_DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case vehicleQuoteActionTypes.SET_VEHICLE_NO_COMBINE_ID:
      return {
        ...state,
        noCombineId: action.payload,
      };
    case vehicleQuoteActionTypes.SET_VEHICLE_COMBINE_ID:
      return {
        ...state,
        combineId: action.payload,
      };
    case vehicleQuoteActionTypes.SET_VEHICLE_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case vehicleQuoteActionTypes.SET_TIMER:
      return {
        ...state,
        timer: action.payload,
      };
    case vehicleQuoteActionTypes.SET_MEMBERS_INFO:
      return {
        ...state,
        membersInfo: action.payload,
      };
    case vehicleQuoteActionTypes.SET_VEHICLE_COMBINE_CV:
      return {
        ...state,
        combineCV: action.payload,
      };
    case vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTES_FROM_DB_SUCCESS:
      return {
        ...state,
        vehicleQuotesFromDB: action.payload,
      };
    case vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        vehicleQuoteFromDB: action.payload,
      };
    case ModalTypes.OPEN_MODAL:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        noCombineId: "",
        combineId: "",
        combineCV: false,
        reset: false,
        vehicleQuotesFromDB: [],
        vehicleQuoteFromDB: {},
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        noCombineId: "",
        combineId: "",
        combineCV: false,
        reset: false,
        vehicleQuotesFromDB: [],
        vehicleQuoteFromDB: {},
      };
    case vehicleQuoteActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default vehicleQuoteReducer;
