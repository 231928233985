import { MEMBER_VEHICLE_POLICY_CMS_DATA }
  from '../../../pages/policies/vehicle/vehicle-policy.cms';

const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));
let memberDetails;

if (PERSISTED_STATE) {
  memberDetails = JSON.parse(PERSISTED_STATE.auth);
}

export const convertMemberVehiclePolicyForDBSave = (data) => {
  const {
    form: {
      medicalCoverage,
      personalIP
    },
  } = MEMBER_VEHICLE_POLICY_CMS_DATA;

  const isMedicalCoverage = medicalCoverage.dropdown;
  const isPersonalIP = personalIP.dropdown;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    memberId: memberDetails ? memberDetails.user._id : '',
    storedVehicles: data.storedVehicles ? data.storedVehicles : [],
    additionalVehicles: data.additionalVehicles
      ? data.additionalVehicles
      : '',
    documents: data.documents ? data.documents : [],
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    effectiveDates: {
      start: data.startDate ? data.startDate : '',
      end: data.endDate ? data.endDate : '',
    },
    bodyInjuryLL: {
      eachPerson: 
        data.BILPerson
          ? data.BILPerson
          : '',
      eachOccurrence: 
        data.BILOccurrence
          ? data.BILOccurrence
          : '',
    },
    propertyDLL: 
      data.propertyDLL
        ? data.propertyDLL
        : '',
    uninsuredBIP: {
      eachPerson: 
        data.unPerson
          ? data.unPerson
          : '',
      eachOccurrence: 
        data.unOccurrence
          ? data.unOccurrence
          : '',
    },
    ownPropertyDL: 
      data.ownPropertyDL
        ? data.ownPropertyDL
        : '',
    deductibles: {
      collision: 
        data.DCollision
          ? data.DCollision
          : '',
      other: 
        data.DOther
          ? data.DOther
          : '',
    },
    medicalCoverage: {
      isTypeOf: 
        data.hasMedCov === isMedicalCoverage[0].value
          ? true
          : false,
      value: 
        data.covValue
          ? data.covValue
          : '',
    },
    personalIP: {
      isTypeOf: 
        data.hasPP === isPersonalIP[0].value
          ? true
          : false,
      value: 
        data.PPValue
          ? data.PPValue
          : '',
    },
    additionalInfo: data.additionalInfo ? data.additionalInfo : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  };
  return payload;
}

