import { MEMBER_ANNUITY_POLICY_CMS_DATA }
  from '../../../pages/policies/annuity/annuity-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import agentAnnuityQuoteActionTypes from './annuity.types';

const payload = {
  context: 'agentAnnuityQuote',
  status: '',
  error: '',
}

export const setClearForm = () => ({
  type: agentAnnuityQuoteActionTypes.SET_CLEAR_FORM,
});

export const setIsAgent = (agent) => ({
  type: agentAnnuityQuoteActionTypes.SET_IS_AGENT,
  payload: agent
});

export const startFetchingAgentAnnuityQuoteFromDB = (id) => ({
  type: agentAnnuityQuoteActionTypes.FETCH_AGENT_ANNUITY_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAgentAnnuityQuoteFromDBSuccess = (policy) => ({
  type: agentAnnuityQuoteActionTypes.FETCH_AGENT_ANNUITY_QUOTE_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchAgentAnnuityQuoteFromDBFailure = (e) => ({
  type: agentAnnuityQuoteActionTypes.FETCH_AGENT_ANNUITY_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: agentAnnuityQuoteActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: agentAnnuityQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: agentAnnuityQuoteActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_ANNUITY_POLICY_CMS_DATA.errMsg.states,
});

export const startAgentAnnuityQuoteSave = (data) => ({
  type: agentAnnuityQuoteActionTypes.START_AGENT_ANNUITY_QUOTE_SAVE,
  payload: data
});

export const agentAnnuityQuoteSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_QUOTE_SUCCESS,
  },
});

export const agentAnnuityQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentAnnuityQuoteUpdate = (id, formData) => ({
  type: agentAnnuityQuoteActionTypes.START_AGENT_ANNUITY_QUOTE_UPDATE,
  payload: { id, formData }
});

export const agentAnnuityQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_UPDATE_QUOTE_SUCCESS,
  },
});

export const agentAnnuityQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentAnnuityQuoteDelete = (id, pId) => ({
  type: agentAnnuityQuoteActionTypes.START_AGENT_ANNUITY_QUOTE_DELETE,
  payload: { id, pId },
});

export const agentAnnuityQuoteDeleteSuccess = () => ({
  type: agentAnnuityQuoteActionTypes.AGENT_ANNUITY_QUOTE_DELETE_SUCCESS,
});

export const agentAnnuityQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});