import React, {useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../redux/auth/auth.selectors';
import AnimalQuotesDetails
  from '../../components/member-view-quotes/animal-view-quotes/animal-quotes.component';
import PropertyQuotesDetails
  from '../../components/member-view-quotes/property-view-quotes/property-quotes.component';
import VehicleQuotesDetails
  from '../../components/member-view-quotes/vehicle-view-quotes/vehicle-quotes.component';
import CombineQuotesDetails
  from '../../components/member-view-quotes/combine-view-quotes/combine-quotes.component';
import AnnuityQuotesDetails
  from '../../components/member-view-quotes/annuity-view-quotes/annuity-quotes.component';
import LifeQuotesDetails
 from '../../components/member-view-quotes/life-view-quotes/life-quotes.component';
import HealthQuotesDetails
  from '../../components/member-view-quotes/health-view-quotes/health-quotes.component';
import {
  startFetchingPrimeMemberFromDB,
} from '../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../redux/member/member.selectors';
import FilterListGroup from './filter-buttons.component'
import {
  selectShowAnimalQuotes,
  selectShowLifeQuotes,
  selectShowHealthQuotes,
  selectShowAnnuityQuotes,
  selectShowPropertyQuotes,
  selectShowVehicleQuotes,
  selectShowCombineQuotes,
} from '../../redux/agent-biddings/agent-bidding.selectors';

const MemberViewQuotes = ({
  member,
  currentUser,
  showAnimalQuotes,
  showLifeQuotes,
  showHealthQuotes,
  showAnnuityQuotes,
  showPropertyQuotes,
  showVehicleQuotes,
  showCombineQuotes,
  startFetchingPrimeMemberFromDB,
}) => {
  
  useEffect(() => {
    if (currentUser.member) {
      startFetchingPrimeMemberFromDB(currentUser.member);
    }
  }, [
    currentUser.member,
    startFetchingPrimeMemberFromDB,
  ]);

  const requestedQuotes =
    member && member.requestedQuotes
      ? member.requestedQuotes
      : ''

  return (
    <section className='ie-form-scaffold pt-1'>
      <Container>
        <h4 className='py-5 my-4 text-center'>My Quotes</h4>
        <FilterListGroup requestedQuotes={requestedQuotes} />
      </Container>
      <Container>
        {
          requestedQuotes &&
          requestedQuotes.vehicleQuotes &&
          requestedQuotes.vehicleQuotes.length !== 0 &&
          showVehicleQuotes ? (
          <div id='auto-view-quotes'>
            <h5 className='pt-4 pb-3'>Vehicle Quote Requests</h5>
            <VehicleQuotesDetails
              vehicleQuotes={
                requestedQuotes.vehicleQuotes
                  ? requestedQuotes.vehicleQuotes
                  : []
              }
            />
          </div>
        ) : null}
        {
          requestedQuotes &&
          requestedQuotes.propertyQuotes &&
          requestedQuotes.propertyQuotes.length !== 0 &&
          showPropertyQuotes ? (
          <div id='prop-view-quotes'>
            <h5 className='pt-4 pb-3'>Property Quote Requests</h5>
            <PropertyQuotesDetails
              propertyQuotes={
                requestedQuotes.propertyQuotes 
                  ? requestedQuotes.propertyQuotes
                  : []
              }
            />
          </div>
        ) : null}
        {
          requestedQuotes &&
          requestedQuotes.combineCoverageQuotes &&
          requestedQuotes.combineCoverageQuotes.length !== 0 &&
          showCombineQuotes ? (
          <div id='combined-view-quotes'>
            <h5 className='pt-4 pb-3'>Combine Quote Requests</h5>
              <CombineQuotesDetails
                combineCoverageQuotes={
                  requestedQuotes.combineCoverageQuotes
                    ? requestedQuotes.combineCoverageQuotes
                    : []
                }
              />
          </div>
        ) : null}
        {
          requestedQuotes &&
          requestedQuotes.animalQuotes &&
          requestedQuotes.animalQuotes.length !== 0 &&
          showAnimalQuotes ? (
          <div id='animal-view-quotes'>
            <h5 className='pt-4 pb-3'>Animal Quote Requests</h5>
              <AnimalQuotesDetails
                homeAnimalQuotes={
                  requestedQuotes.animalQuotes
                    ? requestedQuotes.animalQuotes
                    : []
                }
              />
          </div>
        ) : null}
        {
          requestedQuotes &&
          requestedQuotes.annuityQuotes &&
          requestedQuotes.annuityQuotes.length !== 0 &&
          showAnnuityQuotes ? (
          <div id='annuity-view-quotes'>
            <h5 className='pt-4 pb-3'>Annuity Quote Requests</h5>
              <AnnuityQuotesDetails
                annuityQuotes={
                  requestedQuotes.annuityQuotes
                    ? requestedQuotes.annuityQuotes
                    : []
                }
              />
          </div>
        ) : null}
        {
          requestedQuotes &&
          requestedQuotes.lifeQuotes &&
          requestedQuotes.lifeQuotes.length !== 0 &&
          showLifeQuotes ? (
          <div id='life-view-quotes'>
            <h5 className='pt-4 pb-3'>Life Quote Requests</h5>
              <LifeQuotesDetails
                lifeQuotes={requestedQuotes.lifeQuotes}
              />
          </div>
        ) : null}
        {
          requestedQuotes &&
          requestedQuotes.healthQuotes &&
          requestedQuotes.healthQuotes.length !== 0 &&
          showHealthQuotes ? (
          <div id='health-view-quotes'>
            <h5 className='pt-4 pb-3'>Health Quote Requests</h5>
              <HealthQuotesDetails
                healthQuotes={requestedQuotes.healthQuotes}
              />
          </div>
        ) : null}
      </Container> 
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  member: selectPrimeMember,
  showAnimalQuotes: selectShowAnimalQuotes,
  showLifeQuotes: selectShowLifeQuotes,
  showHealthQuotes: selectShowHealthQuotes,
  showAnnuityQuotes: selectShowAnnuityQuotes,
  showPropertyQuotes: selectShowPropertyQuotes,
  showVehicleQuotes: selectShowVehicleQuotes,
  showCombineQuotes: selectShowCombineQuotes,
});

export default connect(mapStateToProps, {
  startFetchingPrimeMemberFromDB,
})(MemberViewQuotes);