import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  startAgentUpdate,
  startCheckoutPaypal,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
} from '../../redux/agent/agent.selectors';
import { selectUser } from '../../redux/auth/auth.selectors';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import ErrorMessages from '../error-message/error-messages.component';

const paypalOptions = {
  'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: 'USD',
  locale: 'en_US',
};
const PaypalPayment = ({
  fee,
  agent,
  quoteId,
  isFeePaid,
  quoteType,
  isQuoteFeePaid,
  startCheckoutPaypal,
}) => {

  const completeCheckout = async (id, data) => {
    startCheckoutPaypal(id, data);
    // window.location.reload();
  }

  return (
    <Form.Row className='justify-content-center mt-5'>
      <Form.Group as={Col} lg={8} controlId='paypal-pay' style={{zIndex:0}} >
        <PayPalScriptProvider
          options={paypalOptions}
          style={{zIndex:0}}
        >
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: fee,
                    }, 
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                details.quoteId = quoteId;
                details.quoteType = quoteType;
                details.isQuoteFeePaid = isQuoteFeePaid;
                details.isFeePaid = isFeePaid;
                completeCheckout(agent._id, details);
              });
            }}
            onError={(err) => {
              agent.isPaymentFailure = true;
            }}
          />
        </PayPalScriptProvider>
        {agent.isPaymentFailure && <ErrorMessages errors={['The payment was unsuccessful. Please try it again!']} />}   
      </Form.Group>
    </Form.Row>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
});

export default withRouter(
  connect(mapStateToProps, {
    startAgentUpdate,
    startCheckoutPaypal,
  })(PaypalPayment)
);