import memberMedicalPolicyActionTypes from './medical.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  statesFromDB: [],
  membersFromDB: [],
  medicalPolciyFromDB: {},
  reset: false,
  clearForm: false,
}

const memberMedicalPolicyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberMedicalPolicyActionTypes.FETCH_MEMBERS_FROM_DB_SUCCESS:
      return {
        ...state,
        membersFromDB: action.payload,
      }
    case memberMedicalPolicyActionTypes.FETCH_MEDICAL_POLICY_FROM_DB_SUCCESS:
      return {
        ...state,
        medicalPolciyFromDB: action.payload,
      }
    case memberMedicalPolicyActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        medicalPolciyFromDB: {},
      }
    case memberMedicalPolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      }
    case memberMedicalPolicyActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      }
    case authActionTypes.LOGOUT:
      return {
        ...state,
        statesFromDB: [],
        membersFromDB: [],
        medicalPolciyFromDB: {},
        reset: false,
        clearForm: false,
      }
    default: 
      return state
  }
}

export default memberMedicalPolicyReducer;