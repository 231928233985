export const agentData = (agent, data, currentUser, isLastStep) => {
  return (
    {
      verifiedAgent: agent.verifiedAgent ? agent.verifiedAgent : false,
      carriersObj: data.carriersObj ? data.carriersObj : {},
      firstName:
        data.firstName
          ? data.firstName
          : agent.details && agent.details.firstName
            ? agent.details.firstName
            : '',
      middleName:
        data.middleName
          ? data.middleName
          : agent.details && agent.details.middleName
            ? agent.details.middleName
            : '',
      lastName:
        data.lastName
          ? data.lastName
          : agent.details && agent.details.lastName
            ? agent.details.lastName
            : '',
      email:
        data.email
          ? data.email
          : currentUser && currentUser.email
            ? currentUser.email
            : '',
      phoneNumber:
        data.phoneNumber
          ? data.phoneNumber
          : agent.details && agent.details.phoneNumber
            ? agent.details.phoneNumber
            : '',
      isPhoneValid:
        data.isPhoneValid 
          ? data.isPhoneValid
          : agent && agent.details && agent.details.isPhoneValid
            ? agent.details.isPhoneValid
            : false,
      dateOfBirth:
        data.dateOfBirth
          ? data.dateOfBirth
          : agent.details && agent.details.dateOfBirth
            ? agent.details.dateOfBirth
            : '',
      agencyName:
        data.agencyName
          ? data.agencyName
          : agent.details && agent.details.agency
            ? agent.details.agency
            : '',
      veriff:
        data.veriff && data.veriff.length && data.veriff.length !== 0
          ? data.veriff
          : agent.veriff
            ? agent.veriff
            : [],
      BStreet:
        data.BStreet
          ? data.BStreet
          : agent.address && agent.address.streetAddress
            ? agent.address.streetAddress
            : '',
      BCity:
        data.BCity
          ? data.BCity
          : agent.address && agent.address.city
            ? agent.address.city
            : '',
      BZipCode:
        data.BZipCode
          ? data.BZipCode
          : agent.address && agent.address.zipCode
            ? agent.address.zipCode
            : '',
      BState:
        data.BState
          ? data.BState
          : agent.address && agent.address.state
            ? agent.address.state
            : '',
      documentType:
        data.documentType
          ? data.documentType
          : agent.identification
            ? (agent.identification.driversLicense && 'driversLicense') ||
            (agent.identification.stateID && 'stateID') ||
            (agent.identification.passport && 'passport')
            : '',
      documentNumber:
        data.documentNumber
          ? data.documentNumber
          : agent.identification
            ? agent.identification.number
            : '',
      documentIssueState:
        data.documentIssueState
          ? data.documentIssueState
          : agent.identification
            ? agent.identification.state
            : '',
      expiration:
        data.expiration
          ? data.expiration
          : agent.identification
            ? agent.identification.expirationDate
            : '',
      nationalProducerNumber:
        data.nationalProducerNumber
          ? data.nationalProducerNumber
          : agent.nationalProducerNumber
            ? agent.nationalProducerNumber
            : '',
      ssn:
        data.ssn
          ? data.ssn
          : agent.ssn
            ? agent.ssn
            : '',
      licenses:
        data.licenses && data.licenses.length !== 0 && data.licenses[0].state !== ''
          ? data.licenses
          : agent.licenses && agent.licenses.length !== 0
            ? agent.licenses
            : [{ state: '', number: '' }],
      isCaptive:
        data.isCaptive
          ? data.isCaptive
          : agent.isCaptive && agent.isCaptive.isTypeOf
            ? 'yes'
            : '',
      carriers:
        data.carriers && data.carriers.length !== 0 && data.carriers[0].carrier !== ''
          ? data.carriers
          : agent.isCaptive && agent.isCaptive.carriers && agent.isCaptive.carriers.length !== 0
            ? agent.isCaptive.carriers
            : [{ carrier: '' }],
      pAndC:
        data.pAndC && data.pAndC.length !== 0
          ? data.pAndC
          : agent.policies && agent.policies.pAndC
            ? agent.policies.pAndC
            : [],
      lAndH:
        data.lAndH && data.lAndH.length !== 0
          ? data.lAndH
          : agent.policies && agent.policies.lAndH
            ? agent.policies.lAndH
            : [],
      applyTo:
        data.applyTo && data.applyTo !== false
          ? data.applyTo
          : agent.servicesProvidedTo &&
            agent.servicesProvidedTo.specificOccupations &&
            agent.servicesProvidedTo.militaryOccupations
            ?
            (agent.servicesProvidedTo.anyOne && 'anyOne') ||
            (agent.servicesProvidedTo.military && 'military') ||
            (agent.servicesProvidedTo.specificOccupations.isTypeOf && 'specificOccupations') ||
            (agent.servicesProvidedTo.militaryOccupations.isTypeOf && 'militaryOccupations')
            : '',
      occupations:
        data.occupations && data.occupations.length !== 0 && data.occupations[0].occupation !== ''
          ? data.occupations
          : agent.servicesProvidedTo &&
            agent.servicesProvidedTo.specificOccupations &&
            agent.servicesProvidedTo.specificOccupations.isTypeOf === true
            ? agent.servicesProvidedTo.specificOccupations.occupations
            : agent.servicesProvidedTo &&
              agent.servicesProvidedTo.militaryOccupations &&
              agent.servicesProvidedTo.militaryOccupations.isTypeOf === true
              ? agent.servicesProvidedTo.militaryOccupations.occupations
              : [{ occupation: '' }],
      isFeePaid:
        data.isFeePaid 
          ? data.isFeePaid
          : agent.isFeePaid
            ? agent.isFeePaid
            : '',
      additionalInfo: '',
      primeMemberId: currentUser ? currentUser.member : '',
      isAgentLastStep: isLastStep ? isLastStep : '',
    }
  )
}