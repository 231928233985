import React from 'react';
import ProductCTA from '../../components/product/product-cta.component';
import Showcase from '../../components/showcase/showcase.component';
import Benefits from '../../components/benefits/benefits.component';

const MemberLanding = () => {
  return (
    <>
      <Showcase />
      <Benefits />
      <ProductCTA />
    </>
  );
};

export default MemberLanding;
