import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  lifeBiddingSaveSuccess,
  lifeBiddingSaveFailure,
  lifeBiddingUpdateSuccess,
  lifeBiddingUpdateFailure,
} from "./life-bidding.actions";
import lifeBiddingActionTypes from "./life-bidding.types";
import { convertLifeBiddingForDBSave } from "./life-bidding.utils";

function* saveLifeBidding({ payload: formData }) {
  try {
    const data = yield call(convertLifeBiddingForDBSave, formData);
    yield axios.post("/api/life-bidding", data, configureAxios());
    yield put(lifeBiddingSaveSuccess());
  } catch (e) {
    yield put(lifeBiddingSaveFailure(e));
  }
}

function* updateLifeBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertLifeBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/life-bidding/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(lifeBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(lifeBiddingUpdateFailure(e));
  }
}

function* onSaveLifeBiddingStart() {
  yield takeEvery(
    lifeBiddingActionTypes.START_LIFE_BIDDING_SAVE,
    saveLifeBidding
  );
}

function* onUpdateLifeBiddingStart() {
  yield takeEvery(
    lifeBiddingActionTypes.START_LIFE_BIDDING_UPDATE,
    updateLifeBidding
  );
}

export function* lifeBiddingSagas() {
  yield all([call(onSaveLifeBiddingStart), call(onUpdateLifeBiddingStart)]);
}
