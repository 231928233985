const combineCoverageQuoteActionTypes = {
  FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_START: 'FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_START',
  FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_SUCCESS: 'FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_SUCCESS',
  FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_FAILURE: 'FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_FAILURE',
  FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_START: 'FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_START',
  FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_SUCCESS: 'FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_SUCCESS',
  FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_FAILURE: 'FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_FAILURE',
  START_COMBINE_COVERAGE_QUOTE_SAVE: 'START_COMBINE_COVERAGE_QUOTE_SAVE',
  COMBINE_COVERAGE_QUOTE_SAVE_SUCCESS: 'COMBINE_COVERAGE_QUOTE_SAVE_SUCCESS',
  START_COMBINE_COVERAGE_QUOTE_UPDATE: 'START_COMBINE_COVERAGE_QUOTE_UPDATE',
  COMBINE_COVERAGE_QUOTE_UPDATE_SUCCESS: 'COMBINE_COVERAGE_QUOTE_UPDATE_SUCCESS',
  START_LAST_STEP_COMBINE_COVERAGE_QUOTE_UPDATE: 'START_LAST_STEP_COMBINE_COVERAGE_QUOTE_UPDATE',
  LAST_STEP_COMBINE_COVERAGE_QUOTE_UPDATE_SUCCESS: 'LAST_STEP_COMBINE_COVERAGE_QUOTE_UPDATE_SUCCESS',
  COMBINE_DECREMENT_CURRENT_STEP: 'COMBINE_DECREMENT_CURRENT_STEP',
  COMBINE_INCREMENT_CURRENT_STEP: 'COMBINE_INCREMENT_CURRENT_STEP',
  START_CHECKING_CHAT_GROUP_COMBINE: 'START_CHECKING_CHAT_GROUP_COMBINE',
  CHECKING_CHAT_GROUP_COMBINE_SUCCESS: 'CHECKING_CHAT_GROUP_COMBINE_SUCCESS',
  SAVE_DELETE_COMBINE_COMET_CHAT: 'SAVE_DELETE_COMBINE_COMET_CHAT',
  SAVE_DELETE_COMBINE_COMET_CHAT_SUCCESS: 'SAVE_DELETE_COMBINE_COMET_CHAT_SUCCESS',
  SET_COMBINED_FORM_DATA: 'SET_COMBINED_FORM_DATA',
  SET_COMBINE_ID: 'SET_COMBINE_ID',
  SET_BID_COMBINE: 'SET_BID_COMBINE',
  RESET_FORM: 'RESET_FORM',
}

export default combineCoverageQuoteActionTypes;
