const companionAnimalActionTypes = {
  FETCH_ALL_ANIMALS_FROM_DB_START: 'FETCH_ALL_ANIMALS_FROM_DB_START',
  FETCH_ALL_ANIMALS_FROM_DB_SUCCESS: 'FETCH_ALL_ANIMALS_FROM_DB_SUCCESS',
  FETCH_ALL_ANIMALS_FROM_DB_FAILURE: 'FETCH_ALL_ANIMALS_FROM_DB_FAILURE',
  FETCH_ANIMAL_FROM_DB_START: 'FETCH_ANIMAL_FROM_DB_START',
  FETCH_ANIMAL_FROM_DB_SUCCESS: 'FETCH_ANIMAL_FROM_DB_SUCCESS',
  FETCH_ANIMAL_FROM_DB_FAILURE: 'FETCH_ANIMAL_FROM_DB_FAILURE',
  START_COMPANION_ANIMAL_SAVE: 'START_COMPANION_ANIMAL_SAVE',
  START_COMPANION_ANIMAL_UPDATE: 'START_COMPANION_ANIMAL_UPDATE',
  START_COMPANION_ANIMAL_DELETE: 'START_COMPANION_ANIMAL_DELETE',
  COMPANION_ANIMAL_DELETE_SUCCESS: 'COMPANION_ANIMAL_DELETE_SUCCESS',
  START_COMPANION_ANIMAL_IN_QUOTE_SAVE: 'START_COMPANION_ANIMAL_IN_QUOTE_SAVE',
  COMPANION_ANIMAL_IN_QUOTE_SAVE_SUCCESS: 'COMPANION_ANIMAL_IN_QUOTE_SAVE_SUCCESS',
  START_COMPANION_ANIMAL_IN_QUOTE_UPDATE: 'START_COMPANION_ANIMAL_IN_QUOTE_UPDATE',
  COMPANION_ANIMAL_IN_QUOTE_UPDATE_SUCCESS: 'COMPANION_ANIMAL_IN_QUOTE_UPDATE_SUCCESS',
  SET_IS_SUBMITTED: 'SET_IS_SUBMITTED',
  TOGGLE_ANIMAL_FORM: 'TOGGLE_ANIMAL_FORM',
  TOGGLE_ANIMAL_INFO_EDIT: 'TOGGLE_ANIMAL_INFO_EDIT',
  SET_CLEAR_ANIMAL_FORM: 'SET_CLEAR_ANIMAL_FORM',
  RESET_FORM: 'RESET_FORM',
};

export default companionAnimalActionTypes;

