import memberHistoryActionTypes from './member-history.types';
import { ModalState, ModalTypes } from '../../modal/modal.types';

const payload = {
  context: 'memberHistory',
  status: '',
  error: '',
};

export const startFetchingMemberHistoryFromDB = () => ({
  type: memberHistoryActionTypes.FETCH_MEMBER_HISTORY_FROM_DB_START,
});

export const fetchMemberHistoryFromDBSuccess = (memberHistory) => ({
  type: memberHistoryActionTypes.FETCH_MEMBER_HISTORY_FROM_DB_SUCCESS,
  payload: memberHistory,
});

export const fetchMemberHistoryFromDBFailure = (e) => ({
  type: memberHistoryActionTypes.FETCH_MEMBER_HISTORY_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingMemberHistoryByIdFromDB = (id) => ({
  type: memberHistoryActionTypes.FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_START,
  payload: id,
});

export const fetchMemberHistoryByIdFromDBSuccess = (memberHistory) => ({
  type: memberHistoryActionTypes.FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_SUCCESS,
  payload: memberHistory,
});

export const fetchMemberHistoryByIdFromDBFailure = (e) => ({
  type: memberHistoryActionTypes.FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_FAILURE,
  payload: e,
});

export const startMemberHistorySave = (memberHistory) => ({
  type: memberHistoryActionTypes.START_MEMBER_HISTORY_SAVE,
  payload: memberHistory,
});

export const memberHistorySaveSuccess = () => ({
  type: memberHistoryActionTypes.MEMBER_HISTORY_SAVE_SUCCESS,
});

export const memberHistorySaveFailure = (e) => ({
  type: memberHistoryActionTypes.MEMBER_HISTORY_SAVE_FAILURE,
  payload: {
    ...payload,
    error: e.response.data.message,
  },
});

export const startMemberHistoryUpdate = (id, formData) => ({
  type: memberHistoryActionTypes.START_MEMBER_HISTORY_UPDATE,
  payload: { id, formData },
});

export const memberHistoryUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const memberHistoryUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

