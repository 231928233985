const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));
let memberDetails;

if (PERSISTED_STATE && PERSISTED_STATE.auth) {
  memberDetails = JSON.parse(PERSISTED_STATE.auth);
}

export const convertMemberComapanionAnimalPolicyForDBSave = (data) => {
  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    memberId: memberDetails ? memberDetails.user._id : '',
    storedAnimals: data.storedAnimals ? data.storedAnimals : [],
    additionalAnimals: data.additionalAnimals ? data.additionalAnimals : [],
    documents: data.documents ? data.documents : [],
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    additionalInfo: data.additionalInfo ? data.additionalInfo : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  };
  return payload;
}

