import { LIFE_QUOTE_CMS_DATA } from '../../../components/requested-quotes/life/life.cms';

export const convertLifeQuoteForDBSave = (data) => {
  const {
    form: {
      life: {
        dropdown: { lifeTypes, yearsTerm, faceValue, FVWhole, uniInv, varInv }
      }
    }
  } = LIFE_QUOTE_CMS_DATA;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    primeMemberInfo: data.primeMemberInfo ? data.primeMemberInfo : {},
    membersInfo: data.membersInfo ? data.membersInfo : [],
    createdDate: new Date(),
    member: data.member ? data.member : '',
    isLastStep: data.isLastStep ? data.isLastStep : false,
    isCometChatGr: data.isCometChatGr ? data.isCometChatGr : false,
    isMsgSent: data.isMsgSent ? data.isMsgSent : false,
    selAgentsByAI: data.selAgentsByAI ? data.selAgentsByAI : {},
    createdCometDate: data.createdCometDate ? data.createdCometDate : '',
    isCometExp: data.isCometExp ? data.isCometExp : false,
    isDeleteComet: data.isDeleteComet ? data.isDeleteComet : false,
    insureTypes: {
      termLife: {
        isTypeOf:
          data.lifeTypes !== null
            ? data.lifeTypes === lifeTypes[0].value
            : false,
        yearsTerm: {
          '10':
            data.yearsTerm !== null
              ? data.yearsTerm === yearsTerm[0].value
              : false,
          '15':
            data.yearsTerm !== null
              ? data.yearsTerm === yearsTerm[1].value
              : false,
          '20':
            data.yearsTerm !== null
              ? data.yearsTerm === yearsTerm[2].value
              : false,
          '25':
            data.yearsTerm !== null
              ? data.yearsTerm === yearsTerm[3].value
              : false,
          '30+':
            data.yearsTerm !== null
              ? data.yearsTerm === yearsTerm[4].value
              : false,
        },
        faceValue: {
          '50,000':
            data.FVTerm !== null
              ? data.FVTerm === faceValue[0].value
              : false,
          '100,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[1].value
            : false,
          '200,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[2].value
            : false,
          '300,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[3].value
            : false,
          '500,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[4].value
            : false,
          '750,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[5].value
            : false,
          '1,000,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[6].value
            : false,
          '2,000,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[7].value
            : false,
          '3,000,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[8].value
            : false,
          '5,000,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[9].value
            : false,
          '10,000,000': data.FVTerm !== null
            ? data.FVTerm === faceValue[10].value
            : false,
          'notSure': data.FVTerm !== null
            ? data.FVTerm === faceValue[11].value
            : false,
        },
        addtionalInfo:
          data.lifeTypes === 'termLife'
            ? data.addInfo
            : '',
      },
      wholeLife: {
        isTypeOf:
          data.lifeTypes !== null
            ? data.lifeTypes === lifeTypes[1].value
            : false,
        faceValue: {
          '10,000':
            data.FVWhole !== null
              ? data.FVWhole === FVWhole[0].value
              : false,
          '15,000':
            data.FVWhole !== null
              ? data.FVWhole === FVWhole[1].value
              : false,
          '20,000':
            data.FVWhole !== null
              ? data.FVWhole === FVWhole[2].value
              : false,
          '25,000':
            data.FVWhole !== null
              ? data.FVWhole === FVWhole[3].value
              : false,
          '30,000':
            data.FVWhole !== null
              ? data.FVWhole === FVWhole[4].value
              : false,
          'notSure':
            data.FVWhole !== null
              ? data.FVWhole === FVWhole[5].value
              : false,
        },
        addtionalInfo:
          data.lifeTypes === 'wholeLife' ?
            data.addInfo
            : '',
      },
      uniLife: {
        isTypeOf:
          data.lifeTypes !== null
            ? data.lifeTypes === lifeTypes[2].value
            : false,
        invested: {
          fixed:
            data.uniInv !== null
              ? data.uniInv === uniInv[0].value
              : false,
          indexed:
            data.uniInv !== null
              ? data.uniInv === uniInv[1].value
              : false,
          variable: {
            isTypeOf:
              data.uniInv !== null
                ? data.uniInv === uniInv[2].value
                : false,
            investments:
              data.varInv !== null
                ? data.varInv === varInv[0].value
                : false,
          },
          notSure:
            data.uniInv !== null
              ? data.uniInv === uniInv[3].value
              : false,
        },
        faceValue: {
          '50,000':
            data.FVUni !== null
              ? data.FVUni === faceValue[0].value
              : false,
          '100,000': data.FVUni !== null
            ? data.FVUni === faceValue[1].value
            : false,
          '200,000': data.FVUni !== null
            ? data.FVUni === faceValue[2].value
            : false,
          '300,000': data.FVUni !== null
            ? data.FVUni === faceValue[3].value
            : false,
          '500,000': data.FVUni !== null
            ? data.FVUni === faceValue[4].value
            : false,
          '750,000': data.FVUni !== null
            ? data.FVUni === faceValue[5].value
            : false,
          '1,000,000': data.FVUni !== null
            ? data.FVUni === faceValue[6].value
            : false,
          '2,000,000': data.FVUni !== null
            ? data.FVUni === faceValue[7].value
            : false,
          '3,000,000': data.FVUni !== null
            ? data.FVUni === faceValue[8].value
            : false,
          '5,000,000': data.FVUni !== null
            ? data.FVUni === faceValue[9].value
            : false,
          '10,000,000': data.FVUni !== null
            ? data.FVUni === faceValue[10].value
            : false,
          'notSure': data.FVUni !== null
            ? data.FVUni === faceValue[11].value
            : false,
        },
        downAmount: data.DPUni ? data.DPUni : '',
        addtionalInfo:
          data.lifeTypes === 'uniLife'
            ? data.addInfo
            : '',
      },
      notSure: {
        isTypeOf:
          data.lifeTypes !== null
            ? data.lifeTypes === lifeTypes[3].value
            : false,
        addtionalInfo: data.addInfoNS ? data.addInfoNS : '',
      },
    },
  }

  return payload;
};








