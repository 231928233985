import propertyActionTypes from './property.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  statesFromDB: [],
  propertiesFromDB: [],
  propertyById: {},
  reset: false,
  isSubmitted: false,
  showPropertyForm: false,
  showPropertyOptions: false,
  showPropertyInfoEdit: false,
  clearPropertyForm: false,
};

const propertyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case propertyActionTypes.FETCH_ALL_PROPERTIES_FROM_DB_SUCCESS:
      return {
        ...state,
        propertiesFromDB: action.payload,
      };
    case propertyActionTypes.FETCH_PROPERTY_FROM_DB_SUCCESS:
      return {
        ...state,
        propertyById: action.payload,
      };
    case propertyActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      };
    case propertyActionTypes.TOGGLE_PROPERTY_FORM:
      return {
        ...state,
        showPropertyForm: action.payload,
      };
    case propertyActionTypes.TOGGLE_PROPERTY_OPTIONS:
      return {
        ...state,
        showPropertyOptions: action.payload,
      };
    case propertyActionTypes.TOGGLE_PROPERTY_INFO_EDIT:
      return {
        ...state,
        showPropertyInfoEdit: action.payload,
      };
    case propertyActionTypes.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    case propertyActionTypes.SET_CLEAR_PROPERTY_FORM:
      return {
        ...state,
        clearPropertyForm: true,
        propertyById: {},
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        statesFromDB: [],
        propertiesFromDB: [],
        propertyById: {},
        reset: false,
        isSubmitted: false,
        showPropertyForm: false,
        showPropertyOptions: false,
        showPropertyInfoEdit: false,
        clearPropertyForm: false,
      };
    case propertyActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default propertyReducer;
