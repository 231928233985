const lifeQuoteActionTypes = {
  FETCH_LIFE_QUOTES_FROM_DB_START: 'FETCH_LIFE_QUOTES_FROM_DB_START',
  FETCH_LIFE_QUOTES_FROM_DB_SUCCESS: 'FETCH_LIFE_QUOTES_FROM_DB_SUCCESS',
  FETCH_LIFE_QUOTES_FROM_DB_FAILURE: 'FETCH_LIFE_QUOTES_FROM_DB_FAILURE',
  FETCH_LIFE_QUOTE_FROM_DB_START: 'FETCH_LIFE_QUOTE_FROM_DB_START',
  FETCH_LIFE_QUOTE_FROM_DB_SUCCESS: 'FETCH_LIFE_QUOTE_FROM_DB_SUCCESS',
  FETCH_LIFE_QUOTE_FROM_DB_FAILURE: 'FETCH_LIFE_QUOTE_FROM_DB_FAILURE',
  START_LIFE_QUOTE_SAVE: 'START_LIFE_QUOTE_SAVE',
  LIFE_QUOTE_SAVE_SUCCESS: 'LIFE_QUOTE_SAVE_SUCCESS',
  START_LIFE_QUOTE_UPDATE: 'START_LIFE_QUOTE_UPDATE',
  LIFE_QUOTE_UPDATE_SUCCESS: 'LIFE_QUOTE_UPDATE_SUCCESS',
  START_LAST_STEP_LIFE_QUOTE_UPDATE: 'START_LAST_STEP_LIFE_QUOTE_UPDATE',
  LIFE_DECREMENT_CURRENT_STEP: 'LIFE_DECREMENT_CURRENT_STEP',
  LIFE_INCREMENT_CURRENT_STEP: 'LIFE_INCREMENT_CURRENT_STEP',
  START_CHECKING_CHAT_GROUP_LIFE: 'START_CHECKING_CHAT_GROUP_LIFE',
  CHECKING_CHAT_GROUP_SUCCESS_LIFE: 'CHECKING_CHAT_GROUP_SUCCESS_LIFE',
  SAVE_DELETE_LIFE_COMET_CHAT: 'SAVE_DELETE_LIFE_COMET_CHAT',
  SAVE_DELETE_LIFE_COMET_CHAT_SUCCESS: 'SAVE_DELETE_LIFE_COMET_CHAT_SUCCESS',
  SET_LIFE_QUOTE_ID: 'SET_LIFE_QUOTE_ID',
  SET_LIFE_FORM_DATA: 'SET_LIFE_FORM_DATA',
  SET_HEALTH_INFOS_LIFE: 'SET_HEALTH_INFOS_LIFE',
  SET_MEMBERS_INFO: 'SET_MEMBERS_INFO',
  RESET_FORM: 'RESET_FORM',
}

export default lifeQuoteActionTypes;
