import * as yup from 'yup';
import { calculateAge } from '../../redux/member/member.utils';

const MemberSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required('Please enter first name')
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters'),
  middleName: yup
    .string()
    .trim()
    .min(1, 'Please enter at least one characters')
    .max(255, 'Please enter less than 255 characters')
    .optional(),
  lastName: yup
    .string()
    .trim()
    .required('Please enter last name')
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters'),
  dateOfBirth: yup
    .date()
    .typeError('Date must be valid')
    .required('Please provide a date of birth'),
  gender: yup.string().trim().required('Please select a gender'),
  memberType: yup.string().trim().required('Please select member type'),
  email: yup
    .string()
    .email()
    .min(5, 'Please enter at least five characters')
    .max(255, 'Please enter no more than 255 characters')
    .when('memberType', {
      is: (val) => val === 'self',
      then: yup.string().trim().required('Please enter an email'),
      otherwise: yup.string().notRequired(),
    }),
  mobile: yup
    .string()
    .min(11, 'Please provide at least 11 characters')
    .max(12, 'Please provide no more than 12 characters')
    .when('memberType', {
      is: (val) => val === 'self',
      then: yup.string().trim().required('Please enter mobile number'),
      otherwise: yup.string().notRequired(),
    }),
  landline: yup
    .string()
    .min(11, 'Please provide 11 digits')
    .max(12, 'Please provide no more than 12 digits')
    .optional(),
  street: yup
    .string()
    .trim()
    .min(4, 'Please provide at least 4 characters')
    .max(255, 'Please provide no more than 255 characters')
    .when('memberType', {
      is: (val) => val === 'self',
      then: yup.string().trim().required('Please enter street name'),
      otherwise: yup.string().notRequired(),
    }),
  city: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(20, 'Please provide no more than 20 characters')
    .when('memberType', {
      is: (val) => val === 'self',
      then: yup.string().trim().required('Please enter a valid city'),
      otherwise: yup.string().notRequired(),
    }),
  state: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .when('memberType', {
      is: (val) => val === 'self',
      then: yup.string().trim().required('Please enter state'),
      otherwise: yup.string().notRequired(),
    }),
  zipCode: yup
    .number()
    .typeError('Please enter a valid zip code')
    .min(1001, 'Please enter a valid zip code')
    .max(99950, 'Please enter a valid zip code')
    .when('memberType', {
      is: (val) => val === 'self',
      then: yup.number().required('Please enter zip code'),
      otherwise: yup.number().notRequired(),
    }),
  documentType: yup
    .string()
    .trim()
    .min(2, 'Please enter document type')
    .max(255, 'Please enter document type')
    .when('dateOfBirth', {
      is: (val) => calculateAge(val) >= 18,
      then: yup.string().trim().required('Please select a document type'),
      otherwise: yup.string().notRequired(),
    }),
  documentNumber: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid document number')
    .max(13, 'Please enter a valid document number')
    .when('dateOfBirth', {
      is: (val) => calculateAge(val) >= 18,
      then: yup.string().required('Please enter a valid document number'),
      otherwise: yup.string().notRequired(),
    }),
  expiration: yup
    .date()
    .typeError('Date must be valid')
    .optional(),
  documentIssueState: yup
    .string()
    .trim()
    .min(2, 'Please enter state of document issue')
    .max(255, 'Please enter state of document issue')
    .when(['dateOfBirth', 'documentType'], {
      is: (dob, val) => calculateAge(dob) >= 18 && val !== 'passport',
      then: yup.string().required('Please provide state of issue'),
      otherwise: yup.string().notRequired(),
    })
    .optional(),
  legalDocumentType: yup
    .string()
    .trim()
    .required('Please select a valid document type'),
  legalDocumentNumber: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid document number')
    .max(30, 'Please enter a valid document number')
    .optional(),
  occupations: yup
    .array()
    .of(
      yup.object().shape({
        occupation: yup.string().trim(),
      })
    )
    .optional(),
  annualIncome: yup
    .number()
    .typeError('Please provide annual income')
    .min(0, 'Please provide a valid annual income')
    .max(100000000000, 'Annual income cannot be more than 100000000000')
    .optional(),
  militaryServiceType: yup.string().trim(),
  businessName: yup
    .string()
    .trim()
    .min(2, 'Business name should have at least 2 characters')
    .max(255, 'Business name must not exceed 255 characters')
    .optional(),
  businessOwnership: yup.string().trim(),
  businessStreet: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(255, 'Please provide no more than 255 characters')
    .optional(),
  businessCity: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(20, 'Please provide no more than 20 characters')
    .optional(),
  businessState: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .optional(),
  businessZipCode: yup
    .number()
    .typeError('Please enter a valid zip code')
    .min(1001, 'Please enter a valid zip code')
    .max(99950, 'Please enter a valid zip code')
    .optional(),
  acquisitions: yup
    .array()
    .typeError('Please enter acquisitions')
    .optional(),
  degreeType: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(255, 'Please provide no more than 255 characters')
    .optional(),
  felonyStatus: yup.string().trim().required('Please select an option'),
  felonyReason: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(255, 'Please provide relevant information')
    .optional(),
}, ['mobile', 'landline']);

export default MemberSchema;
