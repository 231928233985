import memberAnnuityPolicyActionTypes from './annuity.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  statesFromDB: [],
  membersFromDB: [],
  annuityPolicy: {},
  reset: false,
}

const memberAnnuityPolicyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberAnnuityPolicyActionTypes.FETCH_MEMBERS_FROM_DB_SUCCESS:
      return {
        ...state,
        membersFromDB: action.payload,
      }
    case memberAnnuityPolicyActionTypes.FETCH_ANNUITY_POLICY_FROM_DB_SUCCESS:
      return {
        ...state,
        annuityPolicy: action.payload,
      }
    case memberAnnuityPolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      }
    case memberAnnuityPolicyActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        annuityPolicy: {},
      }
    case memberAnnuityPolicyActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      }
    case authActionTypes.LOGOUT:
      return {
        ...state,
        statesFromDB: [],
        membersFromDB: [],
        annuityPolicy: {},
        reset: false,
      }
    default: 
      return state
  }
}

export default memberAnnuityPolicyReducer;