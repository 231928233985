const healthBiddingActionTypes = {
  SET_SHOW_HEALTH_QUOTES: 'SET_SHOW_HEALTH_QUOTES',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_HEALTH_BID: 'SET_IS_HEALTH_BID',
  SET_IS_HEALTH_SELECTED_BY_AI: 'SET_IS_HEALTH_SELECTED_BY_AI',
  START_HEALTH_BIDDING_SAVE: 'START_HEALTH_BIDDING_SAVE',
  HEALTH_BIDDING_SAVE_SUCCESS: 'HEALTH_BIDDING_SAVE_SUCCESS',
  START_HEALTH_BIDDING_UPDATE: 'START_HEALTH_BIDDING_UPDATE',
  HEALTH_BIDDING_UPDATE_SUCCESS: 'HEALTH_BIDDING_UPDATE_SUCCESS',
  RESET_FORM: 'RESET_FORM',
};

export default healthBiddingActionTypes;
