import { ModalState, ModalTypes } from '../modal/modal.types';
import profileActionTypes from './profile.types';

const payload = {
  context: 'profile',
  status: '',
  error: '',
};

export const setIsSubmitted = (toggle) => ({
  type: profileActionTypes.SET_IS_SUBMITTED,
  payload: toggle,
});

export const startProfileSave = (profile) => ({
  type: profileActionTypes.START_PROFILE_SAVE,
  payload: profile,
});

export const profileSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const profileSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startProfileUpdate = (id, formData) => ({
  type: profileActionTypes.START_PROFILE_UPDATE,
  payload: { id, formData },
});

export const profileUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const profileUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startFetchingProfileFromDB = (id) => ({
  type: profileActionTypes.FETCH_PROFILE_FROM_DB_START,
  payload: id,
});

export const fetchProfileFromDBSuccess = (member) => ({
  type: profileActionTypes.FETCH_PROFILE_FROM_DB_SUCCESS,
  payload: member,
});

export const fetchProfileFromDBFailure = (e) => ({
  type: profileActionTypes.FETCH_PROFILE_FROM_DB_FAILURE,
  payload: e,
});

export const startResetPasswordInProfile = (user) => ({
  type: profileActionTypes.START_RESET_PASSWORD_IN_PROFILE,
  payload: user,
});

export const resetPasswordInProfileSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.RESET_PASSWORD_SUCCESS,
  },
});

export const resetPasswordInProfileFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});




