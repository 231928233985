import React from 'react';
import {
  getAgentServices,
} from '../utils/agent-services.utils';

const AgentDetails = ({agent}) => {

  const services = getAgentServices(agent);

  const agentId =
    agent && agent._id
      ? (agent._id).slice(-5)
      : ''
  const email = 
    agent && agent.details && agent.details.email
      ? agent.details.email
      : ''
  const firstName = 
    agent && agent.details && agent.details.firstName
      ? agent.details.firstName
      : ''
  const lastName = 
    agent && agent.details && agent.details.lastName
      ? agent.details.lastName
      : ''
  const licenses = 
    agent && agent.licenses 
      ? agent.licenses
      : []
  const isCaptive =
    agent && agent.isCaptive && agent.isCaptive.isTypeOf
      ? true
      : false

  return (  
    <>
      <td>
        {agentId ? agentId : '' }
      </td>
      <td>
        {email ? email : '' }
      </td>
      <td>
        {firstName ? `${firstName} ${lastName}`  : '' }
      </td>
      <td>
        {licenses && licenses.length !== 0 ? (
          <>
            {licenses.map((license, i) => (
              <span key={i}>
                {i !== licenses.length - 1 ? (
                  <>{license.state}, </>
                ) : (
                  <>{license.state}</>
                )}
              </span>
            ))}
          </>
        ) : null}
      </td>
      <td>
        {isCaptive ? 'Yes' : 'No' }
      </td>
      <td>
        {services && services.isOfferProperty ? <>&#x2705;</> : '' }
      </td>
      <td>
        {services && services.isOfferAuto ? <>&#x2705;</> : '' }
      </td> 
      <td>
        {services && services.isOfferAnimal ? <>&#x2705;</> : '' }
      </td> 
      <td>
        {services && services.isOfferAnnuity ? <>&#x2705;</> : '' }
      </td>
      <td>
        {services && services.isOfferLife ? <>&#x2705;</> : '' }
      </td>
      <td>
        {services && services.isOfferMedical ? <>&#x2705;</> : '' }
      </td>
    </>
  );
};

export default AgentDetails;