import { ModalState, ModalTypes } from '../../modal/modal.types';
import animalBiddingActionTypes from './animal-bidding.types';

const payload = {
  context: 'animalBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: animalBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsAnimalBid = (toggle) => ({
  type: animalBiddingActionTypes.SET_IS_ANIMAL_BID,
  payload: toggle,
});

export const setShowAnimalQuotes = (toggle) => ({
  type: animalBiddingActionTypes.SET_SHOW_ANIMAL_QUOTES,
  payload: toggle,
});

export const startAnimalBiddingSave = (values) => ({
  type: animalBiddingActionTypes.START_ANIMAL_BIDDING_SAVE,
  payload: values,
});

export const animalBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const animalBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAnimalBiddingUpdate = (id, formData) => ({
  type: animalBiddingActionTypes.START_ANIMAL_BIDDING_UPDATE,
  payload: { id, formData },
});

export const animalBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const animalBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

