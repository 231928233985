import React, { useState } from 'react';

import loadable from '@loadable/component';

const AnimalQuoteDetails = loadable(() => import('./animal-quote-details.component'));

const AnimalQuotesDetails = ({ homeAnimalQuotes }) => {
  const [storeFilterQuotes, setStoreFilterQuotes] = useState(false)

  return (
    <div>
      {homeAnimalQuotes &&
        homeAnimalQuotes.length !== 0 ? (
        <>
          {homeAnimalQuotes.map((ha, i) => (
            <div key={i}>
              {ha && ha.isLastStep ? (
                <AnimalQuoteDetails
                  homeAnimal={ha}
                  elmId={ha._id}
                  storeFilterQuotes = {storeFilterQuotes}
                  setStoreFilterQuotes = {setStoreFilterQuotes}
                />
              ) : null}
            </div>
          ))}
        </>
      ) : null}  
    </div>
  );
};

export default AnimalQuotesDetails;
