import React, { useEffect, useState } from 'react';
import { Button, Table, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startFetchingAnimalFromDB,
  toggleAnimalForm,
  toggleAnimalInfoEdit,
  setClearAnimalForm,
  startCompanionAnimalDelete,
} from '../../../redux/quotes/companion-animal/companion-animal.actions';
import {
  selectAnimal,
  selectShowAnimalForm,
  selectShowAnimalInfoEdit,
} from '../../../redux/quotes/companion-animal/companion-animal.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import {
  COMPANION_ANIMAL_CMS_DATA
} from './companion-animal.cms';
import ShowModal from '../../../components/modal/show-modal.component';
import CompanionAnimalQuote from './companion-animal.component';
import AnimalFormModal from '../../../components/quotes/companion-animal/animal-form-modal.component';

const EditAnimals = ({
  primeMember,
  currentUser,
  showAnimalForm,
  toggleAnimalForm,
  startCompanionAnimalDelete,
  setClearAnimalForm,
  startFetchingAnimalFromDB,
  startFetchingPrimeMemberFromDB,
}) => {
  const {
    heading,
    subHeading,
    modal
  } = COMPANION_ANIMAL_CMS_DATA;

  const [show, setShow] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [storeAnimalInfo, setStoreAnimalInfo] = useState([]);
  const [showAnimalInfo, setShowAnimalInfo] = useState(false);
  const [showAnimalFormOnly, setShowAnimalFormOnly] = useState(false);

  const handleDelete = () => {
    startCompanionAnimalDelete(storeIds[0], storeIds[1]);
    setClearAnimalForm(true);
    setShow(false);
    window.location.reload();
  }

  const handleCancel = () => {
    if (!showAnimalForm) {
      setStoreIds([]);
      setStoreAnimalInfo([]);
    }
    setShow(false);
    window.scrollTo({ top: 0 });
  }

  const householdAnimals =
    primeMember && primeMember.householdAnimals
      ? primeMember.householdAnimals
      : [];

  const getAnimalType = (hm) => {
    for (let t in hm.type) {
      if (hm.type[t]) {
        return t;
      }
    }
  }

  useEffect(() => {
    if (!primeMember._id) {
      startFetchingPrimeMemberFromDB(currentUser.member);
    }
  }, [
    primeMember,
    currentUser.member,
    startFetchingPrimeMemberFromDB
  ]);

  useEffect(() => {
    if (
      primeMember._id &&
      primeMember._id === currentUser.member &&
      primeMember.householdAnimals
    ) {
      let has = primeMember.householdAnimals;
      if (has.length === 0) {
        toggleAnimalForm(true);
        setShowAnimalFormOnly(true);
      }
      if (has.length > 0) {
        toggleAnimalForm(false);
        setShowAnimalFormOnly(false);
      }
    }
  }, [
    primeMember,
    currentUser.member,
    toggleAnimalForm,
    setShowAnimalFormOnly,
  ])

  return (
    <section className='ie-form-scaffold'>
      <Container>
        {!showAnimalFormOnly ? (
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
        ) : null}
        {showAnimalFormOnly ? (
          <CompanionAnimalQuote />
        ) : (
          <div className='pb-5 mb-4 text-right'>
            {householdAnimals && householdAnimals.length > 0 ? (
              <>
                <Table
                  bordered
                  responsive
                  className='shadow-sm p-3 mb-4 bg-white rounded'
                >
                  <thead className='thead-light'>
                    <tr>
                      <th style={{ width: '6rem' }} className='text-left'>Type</th>
                      <th style={{ width: '6rem' }}>Name</th>
                      <th style={{ width: '12rem' }}></th>
                    </tr>
                  </thead>
                  {householdAnimals.map((ha, i) => (
                    <tbody key={ha._id}>
                      <tr key={i}>
                        <td className='text-left'>
                          <p style={{ width: '6rem' }} className='m-0'>
                            {getAnimalType(ha).charAt(0).toUpperCase() + getAnimalType(ha).slice(1)}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {ha.name}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '12rem' }} className='m-0 float-right'>
                            <Button
                              className='mr-1'
                              variant='outline-danger'
                              onClick={() => {
                                setStoreIds([ha._id, currentUser.member]);
                                setStoreAnimalInfo([
                                  getAnimalType(ha).charAt(0).toUpperCase() + getAnimalType(ha).slice(1),
                                  ha.name
                                ])
                                setShow(true);
                              }}
                            >
                              {modal.action.delete}
                            </Button>
                            <Button
                              className='ml-1'
                              variant='primary'
                              onClick={() => {
                                if (ha._id) {
                                  startFetchingAnimalFromDB(ha._id);
                                  setStoreIds([ha._id, currentUser.member]);
                                }
                                toggleAnimalForm(true);
                                setShowAnimalInfo(!showAnimalInfo);
                              }}
                            >
                              {modal.action.update}
                            </Button>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
                <div className='text-left'>
                  <Button
                    variant='primary'
                    onClick={() => {
                      setShowAnimalInfo(!showAnimalInfo);
                      setClearAnimalForm(true);
                      toggleAnimalForm(true);
                      setStoreIds([]);
                      setStoreAnimalInfo([]);
                    }}
                  >
                    {modal.action.add}
                  </Button>
                </div>
              </>
            ) : null}
            <AnimalFormModal
              showAnimalInfo={showAnimalInfo}
              setShowAnimalInfo={setShowAnimalInfo}
            />
            <ShowModal
              show={show}
              heading={`${heading} ${storeAnimalInfo[1]}?`}
              secondary={modal.action.cancel}
              primary={modal.action.delete}
              handleClose={handleCancel}
              handleSecondary={handleCancel}
              handlePrimary={handleDelete}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  animal: selectAnimal,
  primeMember: selectPrimeMember,
  showAnimalForm: selectShowAnimalForm,
  showAnimalInfoEdit: selectShowAnimalInfoEdit,
});

export default connect(mapStateToProps, {
  startCompanionAnimalDelete,
  toggleAnimalForm,
  toggleAnimalInfoEdit,
  setClearAnimalForm,
  startFetchingAnimalFromDB,
  startFetchingPrimeMemberFromDB,
})(EditAnimals);