export const AGENT_LIFE_QUOTE_CMS_DATA = {
  heading: 'Life Quote',
  subHeading: 'Enter the information pertaining to the single Life policy for the Member listed below. If there are other Members or Life policies, they will need to be entered on another page.',
  form: {
    button: {
      submit: 'Submit',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    },
    personalDetails: {
      title: 'Member Information',
      description: '',
      label: {
        member: 'Select a member on the list or "Add another"',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        gender: 'Gender',
        memberType: 'Member relation',
        otherType: 'Other member relation',
        male: 'Male',
        female: 'Female',
        dateOfBirth: 'Date of birth',
      },
      dropdown: {
        memberType: [
          { value: 'spouse', label: 'Spouse' },
          { value: 'domesticPartner', label: 'Domestic partner' },
          { value: 'child', label: 'Child' },
          { value: 'adoptedChild', label: 'Adopted child' },
          { value: 'parent', label: 'Parent' },
          { value: 'sibling', label: 'Sibling' },
          { value: 'careGiver', label: 'Caregiver' },
          { value: 'stepChild', label: 'Stepchild' },
          { value: 'transferStudent', label: 'Transfer student' },
        ],
        genders: [
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
        ],
      },
    },
    documents: {
      title: 'Document',
      description: 'Please upload the quote document for the Member to view.',
      label: 'Upload quote here',
    },
    deathBenefit: {
      title: 'Face Value / Death Benefit',
      description: '',
      label: 'Face value / Death benefit amount',
    },
    accidentalDB: {
      title: 'Accidental Death Benefit',
      description: '$0.00 if not a rider included on policy.',
      label: 'Accidental death benefit amount',
    },
    guaranteedPremium: {
      title: 'Guaranteed Level Premium',
      description: 'If the premium is set to increase at any point please select No and explain the increment amounts and period(s) in which the increase happens.',
      label: {
        guaranteedPremium: 'Is the policy a Guaranteed Level Premium?',
        increments: 'Increment periods and amounts',
      },
      dropdown: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ]
    },
    lifeCoverageType: {
      title: 'Type of Life Coverage',
      description: 'Please select the type of Life policy and other details will populate in order to provide more details.',
      label: {
        typeOfCoverage: 'Type of life coverage',
        startDate: 'Term start date',
        endDate: 'Term end date',
        premiumRider: 'Does this policy have a Return of Premium Rider?',
        maturityDateW: 'Maturity date',
        maturityPayOutW: 'Estimated maturity pay out',
        maturityDateU: 'Maturity date',
        maturityPayOutU: 'Estimated maturity pay out',
        retirementDate: 'Scheduled retirement date',
        receiveAtRetire: 'Payments to receive at retirement',
        subType: 'Subtype',
        setInterest: 'Set interest',
        guaranteedInterest: 'Guaranteed interest',
        predictedInterest: 'Predicted interest',
      },
      dropdown: {
        typesOfCoverage: [
          { value: 'termLife', label: 'Term Life' },
          { value: 'wholeLife', label: 'Whole Life' },
          { value: 'universalLife', label: 'Universal Life' },
        ],
        premiumRider: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
        subTypes: [
          { value: 'fixed', label: 'Fixed' },
          { value: 'indexed', label: 'Indexed' },
          { value: 'variable', label: 'Variable' },
        ]
      }
    },
    additionalInfo: {
      title: 'Additional Information',
      description: 'Any other pertinent information about the policy, including highlights unique to this Carrier, additional riders, etc. Also feel free to sell yourself and your quote here.',
      label: {
        additionalInfo: 'Please provide additional information'
      }
    }
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
  },
  modal: {
    heading: 'Do you want to delete',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    content: {
      faceValue: 'This is the amount that is to be paid to the beneficiary if the Member, stated on the policy, becomes deceased.. For a Term policy, this will be the money paid if the Member passes away during the term period. For Whole Life and Universal Life policies this will be paid if the Member passes away while still paying the premium on the policy. Universal Life also has invested money that might also be due - keep note of that and ask about it.',
      accidentalDB: 'This is the additional amount (to the Face value) that is to be paid to the beneficiary in the case of the Member on the policy becoming deceased due to an accident, a cause other than what would be considered natural or disease related. Note that this is a rider and might not be included on your policy (or $0.00).',
      guaranteedPremium: 'Beware: If you did not already know, many Annuity and Life policies have premiums that periodically increase, whether each year or after so many years. If you have a Guaranteed Level Premium then you do not need to worry and will continue paying the same premium. This is the type of information that InsurEasier has the Agents provide up-front when purchasing policies through us.',
      lifeCoverage: {
        typeOfCoverage: "Term Life coverage is good for making sure any dependents are taken care of with any payments that are made through the Member's income, like mortgage, car payments, college tuition, etc. Most do not ever pay out because the term ends before the covered individual becomes deceased. Whole Life doesn't expire unless premiums aren't met and are mainly for funeral costs, etc. Also, Whole Life policies can be borrowed from. Universal Life policies are the best of both Term and Whole, and usually offer an Annuity aspect, but are generally more expensive.",
        premiumRider: 'There are not many riders listed on this site yet but this is one that is nice to have with a Term Life policy. Since most Term policies never pay out, due to Terms typically ending before the death of the individual occurs, the Return of Premium rider returns all of the premium money paid if the policy reaches maturity or the end of the Term and the Member is still living (assuming that the terms of the Term policy were kept ;).',
        maturityDateW: 'The Maturity date is the date in which the policy would automatically pay out if the Member has not already passed away. Most of the time the Maturity date is around when the Member turns 100 years of age or so.',
        maturityDateU: 'The Maturity date is the date in which the policy would automatically pay out if the Member has not already passed away. Most of the time the Maturity date is around when the Member turns 100 years of age or so. For a Universal Life Insurance, it is a good idea to track this date to either ask for an extension or find out what options there are for pulling out money that might be left in the investment account before the policy matures so that you don not lose it.',
        maturityPayOutW: 'The Maturity pay out of a Whole Life policy might just be the Face Value but it can also include other investments from the savings account attached to it.',
        maturityPayOutU: 'The Maturity pay out of a Universal Life policy might just be the Face Value but it can also include money from the investment account attached to it.',
        receiveAtRetire: 'Universal Life polices have a built in investment account that is typically used for retirement but can be used simply as an investment tool for after retirement. Either way, interest tables are provided to show or give an estimate on how much money will be built with different pay ins and retirement dates. If the interest is Fixed, these tables are more definite but with Indexed or Variable they can only be estimated. Based on planned retirement and if you will want a lump sum or periodic (monthly, quarterly, etc.) payments an estimated pay out plan can be generated.',
        subType: 'Fixed interest is a set interest amount that does not fluctate. Indexed is when there is a minimum, guaranteed interest, but also a cap or maximum interest amount. Variable has no minimum or maximum limit of interest but there is also a risk of losing invested money.',
        setInterest: 'This is the set interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement.',
        guaranteedInterest: 'This is the minimum interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement. Being an Indexed policy, the interest could be greater so it is likely that, if premimums are kept up, the investment will be much greater.',
        predictedInterest: 'Being that the interest is variable, there is not a minimum or maximum interest rate. Therefore, to give an idea of the potential investment, Insurance Carriers might provide a predicted interest rate based on their investment history with other clients.',
      }
    }
  },
  readOnlyTitles: {
    universalLife: {
      title: 'Universal life',
      dates: [
        {
          key: 'maturityDate',
          title: 'Maturity date',
          dataType: 'date',
          info: 'The Maturity date is the date in which the policy would automatically pay out if the Member has not already passed away. Most of the time the Maturity date is around when the Member turns 100 years of age or so. For a Universal Life Insurance, it is a good idea to track this date to either ask for an extension or find out what options there are for pulling out money that might be left in the investment account before the policy matures so that you do not lose it.',
          value: '',
        },
        {
          key: 'scheduledRetirement',
          title: 'Scheduled retirement date',
          dataType: 'date',
          value: '',
        },
      ],
      strings: [
        {
          key: 'receiveAtRetirement',
          title: 'Payments to receive at retirement',
          dataType: 'string',
          info: 'Universal Life polices have a built in investment account that is typically used for retirement but can be used simply as an investment tool for after retirement. Either way, interest tables are provided to show or give an estimate on how much money will be built with different pay ins and retirement dates. If the interest is Fixed, these tables are more definite but with Indexed or Variable they can only be estimated. Based on planned retirement and if you will want a lump sum or periodic (monthly, quarterly, etc.) payments an estimated pay out plan can be generated.',
          value: '',
        },
      ],
      numbers: [
        {
          key: 'estMaturityPayOut',
          title: 'Maturity pay out',
          dataType: 'number',
          info: 'The Maturity pay out of a Universal Life policy might just be the Face Value but it can also include money from the investment account attached to it.',
          value: '',
        },
      ],
      subType: {
        fixed: {
          percentages: [
            {
              key: 'setInterest',
              title: 'Set interest',
              subtypeTitle: 'Fixed',
              subTitle: 'Subtype',
              dataType: 'percent',
              subTypeInfo: 'Fixed interest is a set interest amount that does not fluctate. Indexed is when there is a minimum, guaranteed interest, but also a cap or maximum interest amount. Variable has no minimum or maximum limit of interest but there is also a risk of losing invested money.',
              info: 'This is the set interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement.',
              value: ''
            },
          ]
        },
        indexed: {
          percentages: [
            {
              key: 'guaranteedInterest',
              title: 'Guaranteed interest',
              subtypeTitle: 'Indexed',
              subTitle: 'Subtype',
              dataType: 'percent',
              subTypeInfo: 'Fixed interest is a set interest amount that does not fluctate. Indexed is when there is a minimum, guaranteed interest, but also a cap or maximum interest amount. Variable has no minimum or maximum limit of interest but there is also a risk of losing invested money.',
              info: 'This is the minimum interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement. Being an Indexed policy, the interest could be greater so it is likely that, if premimums are kept up, the investment will be much greater.',
              value: '',
            },
          ]
        },
        variable: {
          percentages: [
            {
              key: 'predictedInterest',
              title: 'Predicted interest',
              subtypeTitle: 'Variable',
              subTitle: 'Subtype',
              dataType: 'percent',
              subTypeInfo: 'Fixed interest is a set interest amount that does not fluctate. Indexed is when there is a minimum, guaranteed interest, but also a cap or maximum interest amount. Variable has no minimum or maximum limit of interest but there is also a risk of losing invested money.',
              info: 'Being that the interest is variable, there is not a minimum or maximum interest rate. Therefore, to give an idea of the potential investment, Insurance Carriers might provide a predicted interest rate based on their investment history with other clients.',
              value: '',
            },
          ]
        },
      },
    },
    termLife: {
      title: 'Term life',
      booleans: [
        {
          key: 'hasPremiumRider',
          title: 'Does this policy have a Return of Premium Rider?',
          dataType: 'boolean',
          info: 'There are not many riders listed on this site yet but this is one that is nice to have with a Term Life policy. Since most Term policies never pay out, due to Terms typically ending before the death of the individual occurs, the Return of Premium rider returns all of the premium money paid if the policy reaches maturity or the end of the Term and the Member is still living (assuming that the terms of the Term policy were kept ;).',
          value: '',
        },
      ],
      durationOfIncome: {
        dates: [
          {
            key: 'start',
            title: 'Duration start',
            dataType: 'date',
            value: '',
          },
          {
            key: 'end',
            title: 'Duration end',
            dataType: 'date',
            value: '',
          },
        ],
      }
    },
    wholeLife: {
      title: 'Whole life',
      dates: [
        {
          key: 'maturityDate',
          title: 'Maturity date',
          dataType: 'date',
          info: 'The Maturity date is the date in which the policy would automatically pay out if the Member has not already passed away. Most of the time the Maturity date is around when the Member turns 100 years of age or so.',
          value: '',
        },
      ],
      numbers: [
        {
          key: 'estMaturityPayOut',
          title: 'Maturity pay out',
          dataType: 'number',
          info: 'The Maturity pay out of a Whole Life policy might just be the Face Value but it can also include other investments from the savings account attached to it.',
          value: '',
        },
      ]
    },
  }
}






