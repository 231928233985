import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { agentData } from './form-data/agent-data';
import {
  setFormData,
  startAgentUpdate,
  startFetchingAgentFromDB,
  startFetchingVerifyAgentFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
  selectStates,
  selectFormData,
  selectVerifyAgent,
} from '../../redux/agent/agent.selectors';
import { selectUser } from '../../redux/auth/auth.selectors';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
// import agentInfoSchema from './agent-info.validation.schema';
import ErrorMessages from '../error-message/error-messages.component';

const AgentLicenseVerify = ({
  values,
  touched,
  errors,
  agent,
  currentUser,
  decrement,
  verifyAgent,
  handleSubmit,
  isSubmitting,
  setFormData,
  startFetchingAgentFromDB,
  startFetchingVerifyAgentFromDB,
}) => {
  const formikProps = { values, touched, errors };
  const {
    form: { button, agentVerif },
  } = AGENT_CMS_DATA;

  const [isVerifiedAgent, setIsVerifiedAgent] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const username = process.env.REACT_APP_NIPR_USER_NAME;
  const password = process.env.REACT_APP_NIPR_PASSWORD;

  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);

  const parser = new DOMParser();
  const doc = parser.parseFromString(verifyAgent.body, 'text/xml');
  const npnTag = doc.getElementsByTagName('npn');
  const npn =
    npnTag[0] && npnTag[0].childNodes[0]
      ? npnTag[0].childNodes[0].nodeValue
      : '';
  
  const data = {
    id: agent._id,
    authNipr: base64Credentials
  }

  useEffect(() => {
    if (!isSendingRequest) {
      if (
        !verifyAgent.body ||
        values.ssn !== agent.ssn ||
        npn === ''
      ) {
        startFetchingVerifyAgentFromDB(data);
        setIsSendingRequest(true);
      }
    }
  }, [
    data,
    npn,
    values.ssn,
    agent.ssn,
    isSendingRequest,
    setIsSendingRequest,
    verifyAgent.body,
    startFetchingVerifyAgentFromDB,
  ])

  useEffect(() => {
    if (verifyAgent.body) {
      if (values.nationalProducerNumber !== '') {
        if (npn === values.nationalProducerNumber) {
          values.verifiedAgent = true;
          setIsVerifiedAgent(true);
        } else if (
          npn !== values.nationalProducerNumber ||
          npn === ''
        ) {
          values.verifiedAgent = false;
          setIsVerifiedAgent(false);
        }
      }
    }
  }, [
    npn,
    verifyAgent,
    setIsVerifiedAgent,
    values.nationalProducerNumber,
    values.verifiedAgent,
  ])

  useEffect(() => {
    if (values) {
      setFormData(values);
    }
  }, [setFormData, values])

  return (
    <section>
      <FormikForm noValidate {...formikProps}>
        <div className='ie-form'>
          <Row>
            <Col lg={4}>
              <h5>{agentVerif.title}</h5>
              <h6>{agentVerif.description}</h6>
            </Col>
            <Col lg={8}>
              <Form.Row>
                <Form.Group as={Col} lg={12} controlId='free'>
                  {!isVerifiedAgent ? (
                    <p className='text-danger'>{agentVerif.error}</p>
                  ) : (
                    <p className='text-success'>{agentVerif.statusOk}</p>
                  )}
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='pb-2'>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                decrement();
                window.scrollTo({ top: 0 });
              }}
            >
              {button.previous}
            </Button>
          </div>
          <ErrorMessages errors={errors} />
          {isVerifiedAgent ? (
            <div className='pb-2'>
              <Button
                disabled={isSubmitting}
                onClick={() => {
                  handleSubmit();
                  startFetchingAgentFromDB(agent._id);
                }}
              >
                {button.next}
              </Button>
            </div>
          ) : null}
        </div>
      </FormikForm>
    </section>
  );
};

const FormikAgentLicenseVerify = withFormik({
  mapPropsToValues: ({ data, currentUser, agent }) => (
    agentData(agent, data, currentUser, false)
  ),
  // validationSchema: agentInfoSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: { startAgentUpdate, currentUser, increment }, setSubmitting }
  ) => {
    setSubmitting(true);
    if (currentUser.agent) {
      startAgentUpdate(currentUser.agent, values);
    }
    setSubmitting(false);
    increment();
    window.scrollTo({ top: 0 });  },
})(AgentLicenseVerify);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
  states: selectStates,
  data: selectFormData,
  verifyAgent: selectVerifyAgent,
});

export default withRouter(
  connect(mapStateToProps, {
    setFormData,
    startAgentUpdate,
    startFetchingAgentFromDB,
    startFetchingVerifyAgentFromDB,
  })(FormikAgentLicenseVerify)
);
