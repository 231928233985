import { ModalState, ModalTypes } from '../../modal/modal.types';
import lifeBiddingActionTypes from './life-bidding.types';

const payload = {
  context: 'lifeBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: lifeBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsLifeBid = (toggle) => ({
  type: lifeBiddingActionTypes.SET_IS_LIFE_BID,
  payload: toggle,
});

export const setShowLifeQuotes = (toggle) => ({
  type: lifeBiddingActionTypes.SET_SHOW_LIFE_QUOTES,
  payload: toggle,
});

export const startLifeBiddingSave = (values) => ({
  type: lifeBiddingActionTypes.START_LIFE_BIDDING_SAVE,
  payload: values,
});

export const lifeBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const lifeBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLifeBiddingUpdate = (id, formData) => ({
  type: lifeBiddingActionTypes.START_LIFE_BIDDING_UPDATE,
  payload: { id, formData },
});

export const lifeBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const lifeBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

