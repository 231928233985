import { MEMBER_ANNUITY_POLICY_CMS_DATA } 
  from '../../../pages/policies/annuity/annuity-policy.cms';

const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));
let memberDetails;

if (PERSISTED_STATE && PERSISTED_STATE.auth) {
  memberDetails = JSON.parse(PERSISTED_STATE.auth);
}

export const convertMemberAnnuityPolicyFromDBSave = (data) => {
  const {
    form: {
      guaranteedPremium, type, subType
    },
  } = MEMBER_ANNUITY_POLICY_CMS_DATA;

  const hasGuaranteedPremium = guaranteedPremium.dropdown;
  const types = type.dropdown.annuity;
  const maturityPayOuts = type.dropdown.maturityPayOut;
  const subTypes = subType.dropdown;
  
  const payload = {
    memberId: memberDetails ? memberDetails.user._id : '',
    storedMember: data.storedMember,
    additionalMember: data.additionalMember,
    documents: data.documents ? data.documents : [],
    quoteType: data.quoteType ? data.quoteType : '',
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    isGuaranteedPremium: {
      isTypeOf: 
        data.guaranteedPremium
          ? data.guaranteedPremium === hasGuaranteedPremium[0].value
          : false,
      yearlyIncrements: 
        data.increments
          ? data.increments
          : '',
    },
    type: {
      immediateIncome: {
        isTypeOf:
          data.typeOfAnnuity !== null
            ? data.typeOfAnnuity === types[0].value
            : false,
        durationOfIncome: {
          start:
            data.typeOfAnnuity === types[0].value
              ? data.startDate
              : '',
          end:
            data.typeOfAnnuity === types[0].value
              ? data.endDate
              : '',
        },
        monthlyIncome:
          data.monthlyIncome !== null
            ? data.monthlyIncome
            : '',
      },
      deferred: {
        isTypeOf:
          data.typeOfAnnuity !== null
            ? data.typeOfAnnuity === types[1].value
            : false,
        maturityDate: 
          data.typeOfAnnuity === types[1].value
            ? data.maturityDate 
            : '',
        maturityPayOut: {
          paidFull: {
            isTypeOf:
              data.maturityPayOut !== null
                ? data.maturityPayOut === maturityPayOuts[0].value
                : false,
            paidFullAmount:
              data.maturityPayOut === maturityPayOuts[0].value
                ? data.paidFullAmount
                : '',
          },
          monthly: {
            isTypeOf:
              data.maturityPayOut !== null
                ? data.maturityPayOut === maturityPayOuts[1].value
                : false,
            monthlyAmount:
              data.maturityPayOut === maturityPayOuts[1].value
                ? data.monthlyAmount
                : '',
          }
        },
      },
    },
    subType: {
      fixed: {
        isTypeOf:
          data.subType !== null
            ? data.subType === subTypes[0].value
            : false,
        setInterest:
          data.setInterest
            ? data.setInterest
            : '',
      },
      indexed: {
        isTypeOf:
          data.subType !== null
            ? data.subType === subTypes[1].value
            : false,
        guaranteedInterest:
          data.subType === subTypes[1].value
            ? data.guaranteedInterest
            : '',
      },
      variable: {
        isTypeOf:
          data.subType !== null
            ? data.subType === subTypes[2].value
            : false,
        predictedInterest:
          data.subType === subTypes[2].value
            ? data.predictedInterest
            : '',
      }
    },
    additionalInfo: data.additionalInfo,
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  }
  return payload;
}




