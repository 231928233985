import agentLifeQuoteActionTypes from './life.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  statesFromDB: [],
  agentLifeQuoteFromDB: {},
  isAgent: false,
  reset: false,
}

const agentLifeQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case agentLifeQuoteActionTypes.FETCH_AGENT_LIFE_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        agentLifeQuoteFromDB: action.payload,
      }
    case agentLifeQuoteActionTypes.SET_IS_AGENT:
      return {
        ...state,
        isAgent: true,
      }
    case agentLifeQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      }
    case agentLifeQuoteActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        agentLifeQuoteFromDB: {},
      }
    case agentLifeQuoteActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      }
    case authActionTypes.LOGOUT:
      return {
        ...state,
        statesFromDB: [],
        agentLifeQuoteFromDB: {},
        isAgent: false,
        reset: false,
      }
    default:
      return state
  }
}

export default agentLifeQuoteReducer;