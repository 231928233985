import { ModalState, ModalTypes } from '../../modal/modal.types';
import companionAnimalActionTypes from './companion-animal.types';

const payload = {
  context: 'companionAnimal',
  status: '',
  error: '',
};

export const setIsSubmitted = (toggle) => ({
  type: companionAnimalActionTypes.SET_IS_SUBMITTED,
  payload: toggle,
});

export const toggleAnimalForm = (toggle) => ({
  type: companionAnimalActionTypes.TOGGLE_ANIMAL_FORM,
  payload: toggle,
});

export const toggleAnimalInfoEdit = (toggle) => ({
  type: companionAnimalActionTypes.TOGGLE_ANIMAL_INFO_EDIT,
  payload: toggle,
});

export const setClearAnimalForm = (clear) => ({
  type: companionAnimalActionTypes.SET_CLEAR_ANIMAL_FORM,
  payload: clear,
});

export const startFetchingAllAnimalsFromDB = () => ({
  type: companionAnimalActionTypes.FETCH_ALL_ANIMALS_FROM_DB_START,
});

export const fetchAllAnimalsFromDBSuccess = (companionAnimals) => ({
  type: companionAnimalActionTypes.FETCH_ALL_ANIMALS_FROM_DB_SUCCESS,
  payload: companionAnimals,
});

export const fetchAllAnimalsFromDBFailure = (e) => ({
  type: companionAnimalActionTypes.FETCH_ALL_ANIMALS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingAnimalFromDB = (id) => ({
  type: companionAnimalActionTypes.FETCH_ANIMAL_FROM_DB_START,
  payload: id,
});

export const fetchAnimalFromDBSuccess = (companionAnimal) => ({
  type: companionAnimalActionTypes.FETCH_ANIMAL_FROM_DB_SUCCESS,
  payload: companionAnimal,
});

export const fetchAnimalFromDBFailure = (e) => ({
  type: companionAnimalActionTypes.FETCH_ANIMAL_FROM_DB_FAILURE,
  payload: e,
});

export const startCompanionAnimalSave = (companionAnimal) => ({
  type: companionAnimalActionTypes.START_COMPANION_ANIMAL_SAVE,
  payload: companionAnimal,
});

export const companionAnimalSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const companionAnimalSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCompanionAnimalUpdate = (id, formData) => ({
  type: companionAnimalActionTypes.START_COMPANION_ANIMAL_UPDATE,
  payload: { id, formData },
});

export const companionAnimalUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const companionAnimalUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCompanionAnimalInQuoteSave = (pq) => ({
  type: companionAnimalActionTypes.START_COMPANION_ANIMAL_IN_QUOTE_SAVE,
  payload: pq,
});

export const companionAnimalInQuoteSaveSuccess = () => ({
  type: companionAnimalActionTypes.COMPANION_ANIMAL_IN_QUOTE_SAVE_SUCCESS,
});

export const companionAnimalInQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCompanionAnimalInQuoteUpdate = (id, formData) => ({
  type: companionAnimalActionTypes.START_COMPANION_ANIMAL_IN_QUOTE_UPDATE,
  payload: { id, formData },
});

export const companionAnimalInQuoteUpdateSuccess = () => ({
  type: companionAnimalActionTypes.COMPANION_ANIMAL_IN_QUOTE_UPDATE_SUCCESS,
});

export const companionAnimalInQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCompanionAnimalDelete = (id, PId) => ({
  type: companionAnimalActionTypes.START_COMPANION_ANIMAL_DELETE,
  payload: { id, PId },
});

export const companionAnimalDeleteSuccess = () => ({
  type: companionAnimalActionTypes.COMPANION_ANIMAL_DELETE_SUCCESS,
});

export const companionAnimalDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});
