import { MEMBER_MEDICAL_POLICY_CMS_DATA } 
  from '../../../pages/policies/medical/medical-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import memberMedicalPolicyActionTypes from './medical.types';

const payload = {
  context: 'memberMedicalPolicy',
  status: '',
  error: '',
}

export const setClearForm = (clear) => ({
  type: memberMedicalPolicyActionTypes.SET_CLEAR_FORM,
  payload: clear,
});

export const startFetchingMembersFromDB = () => ({
  type: memberMedicalPolicyActionTypes.FETCH_MEMBERS_FROM_DB_START,
});

export const fetchMembersFromDBSuccess = (data) => ({
  type: memberMedicalPolicyActionTypes.FETCH_MEMBERS_FROM_DB_SUCCESS,
  payload: data,
});

export const fetchMembersFromDBFailure = (e) => ({
  type: memberMedicalPolicyActionTypes.FETCH_MEMBERS_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: memberMedicalPolicyActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: memberMedicalPolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: memberMedicalPolicyActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_MEDICAL_POLICY_CMS_DATA.errMsg.states,
});

export const startFetchingMedicalPolicyFromDB = (id) => ({
  type: memberMedicalPolicyActionTypes.FETCH_MEDICAL_POLICY_FROM_DB_START,
  payload: id,
});

export const fetchMedicalPolicyFromDBSuccess = (medicalPolicy) => ({
  type: memberMedicalPolicyActionTypes.FETCH_MEDICAL_POLICY_FROM_DB_SUCCESS,
  payload: medicalPolicy,
});

export const fetchMedicalPolicyFromDBFailure = (e) => ({
  type: memberMedicalPolicyActionTypes.FETCH_MEDICAL_POLICY_FROM_DB_FAILURE,
  payload: e,
});

export const startMedicalPolicySave = (data) => ({
  type: memberMedicalPolicyActionTypes.START_MEDICAL_POLICY_SAVE,
  payload: data
});

export const medicalPolicySaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const medicalPolicySaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startMedicalPolicyUpdate = (id, formData) => ({
  type: memberMedicalPolicyActionTypes.START_MEDICAL_POLICY_UPDATE,
  payload: { id, formData }
});

export const medicalPolicyUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const medicalPolicyUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startMedicalPolicyDelete = (id, PId) => ({
  type: memberMedicalPolicyActionTypes.START_MEDICAL_POLICY_DELETE,
  payload: { id, PId },
});

export const medicalPolicyDeleteSuccess = () => ({
  type: memberMedicalPolicyActionTypes.MEDICAL_POLICY_DELETE_SUCCESS,
});

export const medicalPolicyDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});