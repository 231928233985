import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { HEALTH_QUOTE_CMS_DATA } from '../../../pages/quotes/health/health.cms';
import Checkbox from '../../checkbox/checkbox.component';
import InfoIcon from '../../info-icon/info-icon.component';
import { checked } from '../../utils/checked.utils';
import '../../../App.css';

const OtherInformation = ({ values, touched, errors, handleChange }) => {
  const {
    form: {
      otherInformation: {
        title,
        label,
        description,
        additionalInfo,
        otherInfoArr,
      },
    },
  } = HEALTH_QUOTE_CMS_DATA;

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Label>{label}</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {otherInfoArr && otherInfoArr.length !== 0 ? (
              <>
                {otherInfoArr.map((item, i) => (
                  <Form.Group
                    key={i}
                    className='mx-2'
                    controlId={item.controlId}
                  >
                    <Checkbox
                      name={item.value}
                      id={item.controlId}
                    />
                    <Form.Label 
                      className={checked(values[item.value])}
                    >
                      <InfoIcon 
                        label={item.label}
                        content={item.detail}
                      />
                    </Form.Label>
                    </Form.Group>
                ))}
              </>
            ) : null}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={12} controlId='additional-info'>
              <Form.Label>{additionalInfo}
            </Form.Label>
              <Form.Control
                name='addInfo'
                as='textarea'
                rows={5}
                value={values.addInfo}
                onChange={handleChange}
                isValid={
                  touched.addInfo &&
                  !errors.addInfo
                }
                isInvalid={!!errors.addInfo}
                maxLength={255}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.addInfo}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

export default OtherInformation;
