import { Field } from 'formik';
import React, { createRef, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { VEHICLE_CMS_DATA } from '../../../pages/quotes/vehicle/vehicle.cms';
import {
  selectMake,
  selectModel,
  startLoadingVehicleMakeFromDB,
  startLoadingVehicleModelsFromDB,
} from '../../../redux/quotes/vehicle/vehicle.actions';
import {
  selectMakes,
  selectModelsForSelectedMake,
} from '../../../redux/quotes/vehicle/vehicle.selectors';
import InfoIcon from '../../info-icon/info-icon.component';
import { useHistory } from 'react-router-dom';

const VehicleSpecs = ({
  values,
  errors,
  touched,
  handleChange,
  startLoadingVehicleModelsFromDB,
  startLoadingVehicleMakeFromDB,
  selectMake,
  selectModel,
  makes,
  modelsForSelectedMake,
  reset,
  EFormRef,
}) => {
  
  const {
    form: {
      vehicle: {
        specs: { title, description, label },
      },
    },
    popOver: { specs }
  } = VEHICLE_CMS_DATA

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const umbPolicy = 'member-umbrella-policy';
  const agentBid = 'agent-bids';

  const makeRef = createRef();
  const modelRef = createRef();

  useEffect(() => {
    reset && makeRef.current.clear();
  }, [reset, makeRef]);

  useEffect(() => {
    reset && modelRef.current.clear();
  }, [reset, modelRef]);

  useEffect(() => {
    if (makes.length === 0) {
      startLoadingVehicleMakeFromDB();
    }
  }, [makes, startLoadingVehicleMakeFromDB]);

  return (
    <div
      className={ path !== umbPolicy ? 'ie-form' : ''}
      ref={EFormRef}
    >
      <Row>
        {path !== umbPolicy ? (
          <Col lg={4}>
            <h5>{title}</h5>
            <h6>{description}</h6>
          </Col>
        ) : null}
        <Col lg={path !== umbPolicy ? 8 : 12}>
          <Form.Row>
            <Form.Group as={Col} lg={8}>
              <Form.Label>{label.vin}</Form.Label>
              <InfoIcon
                className='float-right'
                content={specs.content.vin}
              />
              <Form.Control
                readOnly={
                  path === agentBid ? true : false
                }
                name='vin'
                value={values.vin}
                onChange={handleChange}
                isValid={touched.vin && !errors.vin}
                isInvalid={!!errors.vin}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.vin}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4}>
              <Form.Label>{label.year}</Form.Label>
              <Form.Control
                readOnly={
                  path === agentBid ? true : false
                }
                name='year'
                value={values.year}
                onChange={handleChange}
                isValid={touched.year && !errors.year}
                isInvalid={!!errors.year}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.year}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg={4}>
              <Form.Label>{label.make}</Form.Label>
              <Field name='make'>
                {({ form, field }) => (
                  <Typeahead
                    disabled={
                      path === agentBid ? true : false
                    }
                    id='make-select-input'
                    ref={makeRef}
                    name='make'
                    options={makes}
                    selected={[values.make]}
                    isValid={touched.make && !errors.make}
                    isInvalid={!!errors.make}
                    onChange={(selectedMake) => {
                      if (selectedMake.length) {
                        selectMake(selectedMake);
                        if (selectedMake[0] !== 'Faraday&Future Inc.') {
                          startLoadingVehicleModelsFromDB(selectedMake);
                        }
                        form.setFieldValue(field.name, selectedMake[0]);
                      }
                    }}
                    onInputChange={(value) => {
                      form.setFieldValue(field.name, value);
                    }}
                  />
                )}
              </Field>
              <Form.Control.Feedback type='invalid'>
                {errors.make}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4}>
              <Form.Label>{label.model}</Form.Label>
              <Field name='model'>
                {({ form, field }) => (
                  <Typeahead
                    disabled={
                      path === agentBid ? true : false
                    }
                    id='model-select-input'
                    name='model'
                    ref={modelRef}
                    options={modelsForSelectedMake}
                    selected={[values.model]}
                    isValid={touched.model && !errors.model}
                    isInvalid={!!errors.model}
                    inputProps={{
                      value: values.model || '',
                    }}
                    onChange={(selectedModel) => {
                      if (selectedModel.length > 0) {
                        selectModel(selectedModel);
                        form.setFieldValue(field.name, selectedModel[0]);
                      }
                    }}
                    onInputChange={(value) => {
                      form.setFieldValue(field.name, value);
                    }}
                  />
                )}
              </Field>
              <Form.Control.Feedback type='invalid'>
                {errors.model}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4} optional='true'>
              <Form.Label>{label.subModel}</Form.Label>
              <Form.Control
                readOnly={
                  path === agentBid ? true : false
                }
                type='text'
                name='subModel'
                value={values.subModel}
                isValid={touched.subModel && !errors.subModel}
                isInvalid={!!errors.subModel}
                onChange={handleChange}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.subModel}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  makes: selectMakes,
  modelsForSelectedMake: selectModelsForSelectedMake,
});

export default connect(mapStateToProps, {
  selectMake,
  startLoadingVehicleModelsFromDB,
  selectModel,
  startLoadingVehicleMakeFromDB,
})(VehicleSpecs);
