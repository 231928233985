import { createSelector } from 'reselect';

const selectNavigation = (state) => state.navigation;

export const selectIsInitiatedFromAgentShowcase = createSelector(
  [selectNavigation],
  (nav) => nav.initiatedFromAgentShowcase
);

export const selectIsInitiatedFromMemberShowcase = createSelector(
  [selectNavigation],
  (nav) => nav.initiatedFromMemberShowcase
);

export const selectGoToActivateAccount = createSelector(
  [selectNavigation],
  (nav) => nav.goToActivateAccount
);

export const selectGoToMemberShowcase = createSelector(
  [selectNavigation],
  (nav) => nav.goToMemberShowcase
);

export const selectGoToAgentShowcase = createSelector(
  [selectNavigation],
  (nav) => nav.goToAgentShowcase
);

export const selectGoToMemberDashboard = createSelector(
  [selectNavigation],
  (nav) => nav.goToMemberDashboard
);

export const selectGoToAgentDashboard = createSelector(
  [selectNavigation],
  (nav) => nav.goToAgentDashboard
);
