import { createSelector } from 'reselect';

const selectMemberHistoryR = (state) => state.memberHistory;

export const selectMemberHistory = createSelector(
  [selectMemberHistoryR],
  (memberHistory) => memberHistory.memberHistory
);

export const selectMemberHistoryById = createSelector(
  [selectMemberHistoryR],
  (memberHistory) => memberHistory.memberHistoryById
);

export const selectReset = createSelector(
  [selectMemberHistoryR],
  (memberHistory) => memberHistory.reset
);


