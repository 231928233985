import companionAnimalActionTypes from './companion-animal.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  animalsFromDB: [],
  animalFromDB: {},
  reset: false,
  isSubmitted: false,
  showAnimalForm: false,
  showAnimalInfoEdit: false,
  clearAnimalForm: false,
};

const companionAnimalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case companionAnimalActionTypes.FETCH_ALL_ANIMALS_FROM_DB_SUCCESS:
      return {
        ...state,
        animalsFromDB: action.payload,
      };
    case companionAnimalActionTypes.FETCH_ANIMAL_FROM_DB_SUCCESS:
      return {
        ...state,
        animalFromDB: action.payload,
      };
    case companionAnimalActionTypes.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    case companionAnimalActionTypes.TOGGLE_ANIMAL_FORM:
      return {
        ...state,
        showAnimalForm: action.payload,
      };
    case companionAnimalActionTypes.TOGGLE_ANIMAL_INFO_EDIT:
      return {
        ...state,
        showAnimalInfoEdit: action.payload,
      };
    case companionAnimalActionTypes.SET_CLEAR_ANIMAL_FORM:
      return {
        ...state,
        clearAnimalForm: action.payload,
        animalFromDB: {},
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        animalsFromDB: [],
        animalFromDB: {},
        reset: false,
        isSubmitted: false,
        showAnimalForm: false,
        showAnimalInfoEdit: false,
        clearAnimalForm: false,
      };
    case companionAnimalActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default companionAnimalReducer;
