import React, { useState, useEffect } from 'react';
import { Button, Table, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startFetchingPropertyFromDB,
  togglePropertyForm,
  setClearPropertyForm,
  startPropertyDelete,
} from '../../../redux/quotes/property/property.actions';
import {
  selectPropertyById,
  selectShowPropertyForm,
} from '../../../redux/quotes/property/property.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import { PROPERTY_QUOTES_CMS_DATA } from './property.cms';
import { useHistory } from 'react-router-dom';
import ShowModal from '../../../components/modal/show-modal.component';
import PropertyFormModal from '../../../components/quotes/property/property-form-modal.component';
import PropertyQuote from './property.component';

const EditProperties = ({
  primeMember,
  currentUser,
  togglePropertyForm,
  startPropertyDelete,
  setClearPropertyForm,
  showPropertyForm,
  startFetchingPropertyFromDB,
  startFetchingPrimeMemberFromDB,
}) => {
  const {
    heading,
    subHeading,
    modal,
  } = PROPERTY_QUOTES_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname;
  const propertyP = '/household-properties-information';

  const [show, setShow] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [storeAddress, setStoreAddress] = useState([]);
  const [showPropertyInfo, setShowPropertyInfo] = useState(false);
  const [showPropertyFormOnly, setShowPropertyFormOnly] = useState(false);

  const handleDelete = () => {
    startPropertyDelete(storeIds[0], storeIds[1]);
    setShow(false);
    window.location.reload();
  }

  const handleCancel = () => {
    if (!showPropertyForm) {
      setStoreIds([]);
      setStoreAddress([]);
    } 
    setShow(false);
    if (path === propertyP) {
      window.scrollTo({ top: 0 });
    }
  }

  const householdProperties =
    primeMember && primeMember.householdProperties
      ? primeMember.householdProperties 
      : []

  const getOwnerShipType = (pt) => {
    if (pt.ownership) {
      for (let t in pt.ownership) {
        if (pt.ownership[t]) {
          return t;
        }
      }
    }
  }  

  const getPurposeType = (pt) => {
    if (pt.purpose) {
      for (let t in pt.purpose) {
        if (pt.purpose[t]) {
          return t;
        }
      }
    }
  }  

  useEffect(() => {
    if (!primeMember._id) {
      startFetchingPrimeMemberFromDB(currentUser.member);
    }
  }, [
    primeMember,
    currentUser.member,
    startFetchingPrimeMemberFromDB
  ]);

  useEffect(() => {
    if (primeMember) {
      let hps = primeMember.householdProperties;
      if (hps && hps.length === 0) {
        togglePropertyForm(true);
        setShowPropertyFormOnly(true);
      }
      if (hps && hps.length > 0) {
        setShowPropertyFormOnly(false);
        togglePropertyForm(false);
      }
    }
  }, [
    primeMember,
    togglePropertyForm,
    setShowPropertyFormOnly,
  ])

  return (
    <section className='ie-form-scaffold'>
      <Container>
        {!showPropertyFormOnly ? (
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
        ) : null}
        {showPropertyFormOnly ? (
          <PropertyQuote />
        ) : (
          <div className='pb-5 mb-4 text-right'>
            {householdProperties && householdProperties.length !== 0 ? (
              <>
                <Table
                  bordered
                  responsive
                  className='shadow-sm p-3 mb-4 bg-white rounded'
                > 
                  <thead className='thead-light'>
                    <tr>
                      <th style={{ width: '6rem' }} className='text-left'>Purpose</th>
                      <th style={{ width: '6rem' }}>Ownership</th>
                      <th style={{ width: '10rem' }}>Street</th>
                      <th style={{ width: '6rem' }}>City</th>
                      <th style={{ width: '6rem' }}>State</th>
                      <th style={{ width: '6rem' }}>Zip Code</th>
                      <th style={{ width: '12rem' }}></th>
                    </tr>
                  </thead>
                  {householdProperties.map((hp, i) => (
                    <tbody key={hp._id}>
                      <tr key={i}>
                        <td className='text-left'>
                          <p style={{ width: '6rem' }} className='m-0'>
                            {
                              getPurposeType(hp).charAt(0).toUpperCase() + getPurposeType(hp).slice(1)
                            }
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {
                              getOwnerShipType(hp).charAt(0).toUpperCase() + getOwnerShipType(hp).slice(1)
                            }
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '10rem' }} className='m-0 float-right'>
                            {hp.address.streetAddress}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {hp.address.city}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {hp.address.state}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '6rem' }} className='m-0 float-right'>
                            {hp.address.zipCode}
                          </p>
                        </td>
                        <td>
                          <p style={{ width: '12rem' }} className='m-0 float-right'>
                            <Button
                              className='mr-1'
                              variant='outline-danger'
                              onClick={() => {
                                setStoreIds([hp._id, currentUser.member]);
                                setStoreAddress([hp.address.streetAddress, hp.address.city, hp.address.state, hp.address.zipCode])
                                setShow(true);
                              }}
                            >
                              {modal.action.delete}
                            </Button>
                            <Button
                              className='ml-1'
                              variant='primary'
                              onClick={() => {
                                if (hp._id) {
                                  startFetchingPropertyFromDB(hp._id);
                                  setStoreIds([hp._id, currentUser.member]);
                                }
                                togglePropertyForm(true);
                                setShowPropertyInfo(!showPropertyInfo);
                                setStoreAddress([hp.address.streetAddress, hp.address.city, hp.address.state, hp.address.zipCode]);
                              }}
                            >
                              {modal.action.update}                      
                            </Button>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
                <div className='text-left'>
                  <Button
                    variant='primary'
                      onClick={() => {
                      setClearPropertyForm(true);
                      setShowPropertyInfo(!showPropertyInfo);
                      togglePropertyForm(true);
                      setStoreIds([]);
                      setStoreAddress([]);
                    }}
                  >
                    {modal.action.add}
                  </Button>
                </div>
              </>
            ) : null}
            <PropertyFormModal
              showPropertyInfo={showPropertyInfo}
              setShowPropertyInfo={setShowPropertyInfo}
            />
            <ShowModal
              show={show}
              heading={`${heading} ${storeAddress[0]}, ${storeAddress[1]}, ${storeAddress[2]}, ${storeAddress[3]}?`}
              secondary={modal.action.cancel}
              primary={modal.action.delete}
              handleClose={handleCancel}
              handleSecondary={handleCancel}
              handlePrimary={handleDelete}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  property: selectPropertyById,
  primeMember: selectPrimeMember,
  showPropertyForm: selectShowPropertyForm,
});

export default connect(mapStateToProps, {
  startPropertyDelete,
  togglePropertyForm,
  setClearPropertyForm,
  startFetchingPropertyFromDB,
  startFetchingPrimeMemberFromDB,
})(EditProperties);