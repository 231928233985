const memberActionTypes = {
  START_MEMBER_SAVE: 'START_MEMBER_SAVE',
  START_MEMBER_UPDATE: 'START_MEMBER_UPDATE',
  MEMBER_UPDATE_SUCCESS: 'MEMBER_UPDATE_SUCCESS',
  MEMBER_UPDATE_FAILURE: 'MEMBER_UPDATE_FAILURE',
  START_MEMBER_DELETE: 'START_MEMBER_DELETE',
  MEMBER_DELETE_SUCCESS: 'MEMBER_DELETE_SUCCESS',
  START_MEMBER_QUOTE_SAVE: 'START_MEMBER_QUOTE_SAVE',
  MEMBER_QUOTE_SAVE_SUCCESS: 'MEMBER_QUOTE_SAVE_SUCCESS',
  START_MEMBER_QUOTE_UPDATE: 'START_MEMBER_QUOTE_UPDATE',
  MEMBER_QUOTE_UPDATE_SUCCESS: 'MEMBER_QUOTE_UPDATE_SUCCESS',
  FETCH_PRIME_MEMBER_FROM_DB_START: 'FETCH_PRIME_MEMBER_FROM_DB_START',
  FETCH_PRIME_MEMBER_FROM_DB_SUCCESS: 'FETCH_PRIME_MEMBER_FROM_DB_SUCCESS',
  FETCH_PRIME_MEMBER_FROM_DB_FAILURE: 'FETCH_PRIME_MEMBER_FROM_DB_FAILURE',
  FETCH_MEMBER_FROM_DB_START: 'FETCH_MEMBER_FROM_DB_START',
  FETCH_MEMBER_FROM_DB_SUCCESS: 'FETCH_MEMBER_FROM_DB_SUCCESS',
  FETCH_MEMBER_FROM_DB_FAILURE: 'FETCH_MEMBER_FROM_DB_FAILURE',
  FETCH_ALL_MEMBERS_FROM_DB_START: 'FETCH_ALL_MEMBERS_FROM_DB_START',
  FETCH_ALL_MEMBERS_FROM_DB_SUCCESS: 'FETCH_ALL_MEMBERS_FROM_DB_SUCCESS',
  FETCH_ALL_MEMBERS_FROM_DB_FAILURE: 'FETCH_ALL_MEMBERS_FROM_DB_FAILURE',
  LOAD_OCCUPATIONS: 'LOAD_OCCUPATIONS',
  LOAD_STATES_FROM_DB_START: 'LOAD_STATES_FROM_DB_START',
  LOAD_STATES_FROM_DB_SUCCESS: 'LOAD_STATES_FROM_DB_SUCCESS',
  LOAD_STATES_FROM_DB_FAILURE: 'LOAD_STATES_FROM_DB_FAILURE',
  LOAD_OCCUPATIONS_FROM_DB_START: 'LOAD_OCCUPATIONS_FROM_DB_START',
  LOAD_OCCUPATIONS_FROM_DB_SUCCESS: 'LOAD_OCCUPATIONS_FROM_DB_SUCCESS',
  LOAD_OCCUPATIONS_FROM_DB_FAILURE: 'LOAD_OCCUPATIONS_FROM_DB_FAILURE',
  TOGGLE_IDENTIFICATION_DETAILS: 'TOGGLE_IDENTIFICATION_DETAILS',
  TOGGLE_MEMBER_FORM: 'TOGGLE_MEMBER_FORM',
  TOGGLE_MEMBER_TYPES: 'TOGGLE_MEMBER_TYPES',
  TOGGLE_MEMBER_INFO_EDIT: 'TOGGLE_MEMBER_INFO_EDIT',
  SET_IS_MEMBER: 'SET_IS_MEMBER',
  SET_IS_SUBMITTED: 'SET_IS_SUBMITTED',
  SET_IS_UPDATED: 'SET_IS_UPDATED',
  SET_CLEAR_MEMBER_FORM: 'SET_CLEAR_MEMBER_FORM',
  MEMBER_SET_FORM_DATA: 'MEMBER_SET_FORM_DATA',
  DECREMENT_CURRENT_STEP: 'DECREMENT_CURRENT_STEP',
  INCREMENT_CURRENT_STEP: 'INCREMENT_CURRENT_STEP',
  MEMBER_START_GET_CODE_TO_VERIFY_PHONE_NUMBER: 'MEMBER_START_GET_CODE_TO_VERIFY_PHONE_NUMBER',
  MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS: 'MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS',
  MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE: 'MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE',
  MEMBER_START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER: 'MEMBER_START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER',
  MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS: 'MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS',
  MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE: 'MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE',
  RESET_FORM: 'RESET_FORM',
};

export default memberActionTypes;
