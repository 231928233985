import * as yup from 'yup';

const agentTwilioSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .min(11, 'Please provide at least 11 characters')
    .max(13, 'Please provide no more than 13 characters')
    .required('Please enter a valid phone number'),
  verifiedCode: yup
    .string()
    .min(6, 'Please provide at least 6 characters')
    .max(6, 'Please provide no more than 6 characters')
    .optional()
})

export default agentTwilioSchema;