export const convertAnimalQuoteForDBSave = (data) => {
  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    members: data.members ? data.members : [],
    animals: data.animals ? data.animals : [],
    createdDate: new Date(),
    isLastStep: data.isLastStep ? data.isLastStep : false,
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    primeMemberInfo: data.primeMemberInfo ? data.primeMemberInfo : {},
    membersInfo: data.membersInfo ? data.membersInfo : [],
    animalsInfo: data.animalsInfo ? data.animalsInfo : [],
    isCometChatGr: data.isCometChatGr ? data.isCometChatGr : false,
    isMsgSent: data.isMsgSent ? data.isMsgSent : false,
    selAgentsByAI: data.selAgentsByAI ? data.selAgentsByAI : {},
    createdCometDate: data.createdCometDate ? data.createdCometDate : '',
    isCometExp: data.isCometExp ? data.isCometExp : false,
    isDeleteComet: data.isDeleteComet ? data.isDeleteComet : false,
  };

  return payload;
};
