import { VEHICLE_CMS_DATA } from '../../../components/requested-quotes/vehicle/vehicle.cms';

export const convertVehicleQuoteForDBSave = (data) => {

  const {
    form: {
      vehicle: {
        liabilityC: {
          label: {
            dropdown: { liability, mdpi, deductibleV, propDamage },
          },
        },
        combineCV: {
          label: {
            dropdown: { combineCV },
          },
        }
      },
      history: {
        auto: {
          label: {
            dropdown: { coverageOptions, coverageLength },
          },
        },
        dl: {
          label: {
            dropdown: { revocationOptions },
          },
        },
      }
    }
  } = VEHICLE_CMS_DATA;

  const payload = {
    members: data.members ? data.members : [],
    isLastStep: data.isLastStep ? data.isLastStep : false,
    createdDate: new Date(),
    quoteType: data.quoteType ? data.quoteType : '',
    vehicles: data.vehicles ? data.vehicles : [],
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    primeMemberInfo: data.primeMemberInfo ? data.primeMemberInfo : {},
    membersInfo: data.membersInfo ? data.membersInfo : [],
    vehiclesInfo: data.vehiclesInfo ? data.vehiclesInfo : [],
    isCometChatGr: data.isCometChatGr ? data.isCometChatGr : false,
    isMsgSent: data.isMsgSent ? data.isMsgSent : false,
    selAgentsByAI: data.selAgentsByAI ? data.selAgentsByAI : {},
    createdCometDate: data.createdCometDate ? data.createdCometDate : '',
    isCometExp: data.isCometExp ? data.isCometExp : false,
    isDeleteComet: data.isDeleteComet ? data.isDeleteComet : false,
    onlyLiability: {
      isTypeOf:
        data.isOnlyLia !== null  
          ? data.isOnlyLia
          : '',
      vehicles: data.onlyLiability ? data.onlyLiability : [],
    },
    liabilityCov: {
      '25000/50000':
        data.liabilityC !== null
          ? data.liabilityC === liability[0].value
          : false,
      '50000/100000':
        data.liabilityC !== null
          ? data.liabilityC === liability[1].value
          : false,
      '100000/300000':
        data.liabilityC !== null
          ? data.liabilityC === liability[2].value
          : false,
      '250000/500000':
        data.liabilityC !== null
          ? data.liabilityC === liability[3].value
          : false,
      '500000/500000':
        data.liabilityC !== null
          ? data.liabilityC === liability[4].value
          : false,
    },
    propDamage: {
      '25000':
        data.propDamage !== null
          ? data.propDamage === propDamage[0].value
          : false,
      '50000':
        data.propDamage !== null
          ? data.propDamage === propDamage[1].value
          : false,
      '100000':
        data.propDamage !== null
          ? data.propDamage === propDamage[2].value
          : false,
      '250000':
        data.propDamage !== null
          ? data.propDamage === propDamage[3].value
          : false,
    },
    medicalPip: {
      medical:
        mdpi !== null
          ? data.mdpi === mdpi[0].value
          : false,
      pip:
        mdpi !== null
          ? data.mdpi === mdpi[1].value
          : false,
      neither:
        mdpi !== null
          ? data.mdpi === mdpi[2].value
          : false,
      unsure:
        mdpi !== null
          ? data.mdpi === mdpi[3].value
          : false,
    },
    deductible: {
      none:
        deductibleV !== null
          ? data.deductibleV === deductibleV[0].value
          : false,
      250:
        deductibleV !== null
          ? data.deductibleV === deductibleV[1].value
          : false,
      500:
        deductibleV !== null
          ? data.deductibleV === deductibleV[2].value
          : false,
      1000:
        deductibleV !== null
          ? data.deductibleV === deductibleV[3].value
          : false,
      1500:
        deductibleV !== null
          ? data.deductibleV === deductibleV[4].value
          : false,
      2000:
        deductibleV !== null
          ? data.deductibleV === deductibleV[5].value
          : false,
      3000:
        deductibleV !== null
          ? data.deductibleV === deductibleV[6].value
          : false,
    },
    additionalInfo: data.addInfoL ? data.addInfoL : '',
    combineCV: {
      true:
        combineCV !== null
          ? data.combineCV === combineCV[0].value
          : false,
      false:
        combineCV !== null
          ? data.combineCV === combineCV[1].value
          : false,
    },
    autoCoverage: {
      memberHasAutoCoverage:
        data.autoCoverage === coverageOptions[0].value,
      startDate:
        data.autoCoverageStartDate &&
          data.autoCoverageStartDate !== null
          ? data.autoCoverageStartDate
          : '',
      expirationDate:
        data.autoCoverageExpirationDate &&
          data.autoCoverageExpirationDate !== null
          ? data.autoCoverageExpirationDate
          : '',
      coverageLength: {
        lessThanOneYear:
          data.coverageLength !== null
            ? data.coverageLength === coverageLength[0].value
            : false,
        oneToThreeYears:
          data.coverageLength !== null
            ? data.coverageLength === coverageLength[1].value
            : false,
        threeToFiveYears:
          data.coverageLength !== null
            ? data.coverageLength === coverageLength[2].value
            : false,
        moreThanFiveYear:
          data.coverageLength !== null
            ? data.coverageLength === coverageLength[3].value
            : false,
      },
      priorExpirationDate:
        data.autoCoveragePriorExpirationDate &&
          data.autoCoveragePriorExpirationDate !== null
          ? data.autoCoveragePriorExpirationDate
          : '',
      reasonForLapse:
        data.reasonForAutoCoverageLapse
          ? data.reasonForAutoCoverageLapse : '',
    },
    driversLicenseStatus: {
      suspendedOrRevoked:
        data.dlRevocationStatus === revocationOptions[0].value,
      reasonForSuspension:
        data.reasonForDLRevocation ? data.reasonForDLRevocation : '',
    },
  }

  return payload;
};








