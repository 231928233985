import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import { ModalState, ModalTypes } from '../modal/modal.types';
import agentActionTypes from './agent.types';

const payload = {
  context: 'agent',
  status: '',
  error: '',
};

export const setCarriersObject = (obj) => ({
  type: agentActionTypes.SET_AGENT_CARRIERS_OBJECT,
  payload: obj,
});

export const setFormData = (data) => ({
  type: agentActionTypes.SET_FORM_DATA,
  payload: data,
});

export const decrementCurrentStep = (currentStep) => ({
  type: agentActionTypes.DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: agentActionTypes.INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const startFetchingAllAgentsFromDB = () => ({
  type: agentActionTypes.FETCH_ALL_AGENTS_FROM_DB_START,
});

export const fetchAllAgentsFromDBSuccess = (agents) => ({
  type: agentActionTypes.FETCH_ALL_AGENTS_FROM_DB_SUCCESS,
  payload: agents,
});

export const fetchAllAgentsFromDBFailure = (e) => ({
  type: agentActionTypes.FETCH_ALL_AGENTS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingAgentFromDB = (id) => ({
  type: agentActionTypes.FETCH_AGENT_FROM_DB_START,
  payload: id,
});

export const fetchAgentFromDBSuccess = (agent) => ({
  type: agentActionTypes.FETCH_AGENT_FROM_DB_SUCCESS,
  payload: agent,
});

export const fetchAgentFromDBFailure = (e) => ({
  type: agentActionTypes.FETCH_AGENT_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingVerifyAgentFromDB = (data) => ({
  type: agentActionTypes.FETCH_VERIFY_AGENT_START,
  payload: { data }
});

export const fetchVerifyAgentFromDBSuccess = (verifyAgent) => ({
  type: agentActionTypes.FETCH_VERIFY_AGENT_SUCCESS,
  payload: verifyAgent
});

export const fetchVerifyAgentFromDBFailure = () => ({
  type: agentActionTypes.FETCH_VERIFY_AGENT_FAILURE
});

export const startFetchingBraintree = (id) => ({
  type: agentActionTypes.FETCH_BRAINTREE_CLIENT_TOKEN_START,
  payload: id,
});

export const fetchBraintreeClientTokenFromDBSuccess = (token) => ({
  type: agentActionTypes.FETCH_BRAINTREE_CLIENT_TOKEN_SUCCESS,
  payload: token,
});

export const fetchBraintreeClientTokenFromDBFailure = (e) => ({
  type: agentActionTypes.FETCH_BRAINTREE_CLIENT_TOKEN_FAILURE,
  payload: e,
});

export const startCheckoutPaypal = (id, data) => ({
  type: agentActionTypes.START_CHECKOUT_PAYPAL,
  payload: { id, data },
});

export const checkoutPaypalSuccess = (data) => ({
  type: agentActionTypes.CHECKOUT_PAYPAL_SUCCESS,
  payload: data,
});

export const checkoutPaypalFailure = (e) => ({
  type: agentActionTypes.CHECKOUT_PAYPAL_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: agentActionTypes.LOAD_STATES_FROM_DB_START,
});

export const loadStatesFromDBSuccess = (data) => ({
  type: agentActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data,
});

export const loadStatesFromDBFailure = () => ({
  type: agentActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: AGENT_CMS_DATA.errMsg.states,
});

export const startLoadingCarriersFromDB = () => ({
  type: agentActionTypes.LOAD_AGENT_CARRIERS_FROM_DB_START,
});

export const loadCarriersFromDBSuccess = (data) => ({
  type: agentActionTypes.LOAD_AGENT_CARRIERS_FROM_DB_SUCCESS,
  payload: data,
});

export const loadCarriersFromDBFailure = () => ({
  type: agentActionTypes.LOAD_AGENT_CARRIERS_FROM_DB_FAILURE,
  payload: AGENT_CMS_DATA.errMsg.carriers,
});

export const startLoadingOccupationsFromDB = () => ({
  type: agentActionTypes.LOAD_OCCUPATIONS_FROM_DB_START,
});

export const loadOccupationsFromDBSuccess = (data) => ({
  type: agentActionTypes.LOAD_OCCUPATIONS_FROM_DB_SUCCESS,
  payload: data,
});

export const loadOccupationsFromDBFailure = (e) => ({
  type: agentActionTypes.LOAD_OCCUPATIONS_FROM_DB_FAILURE,
  payload: AGENT_CMS_DATA.errMsg.carriers,
});

export const startGetPersonFromVeriff = (id, veriffId) => ({
  type: agentActionTypes.START_GET_PERSON_FROM_VERIFF,
  payload: { id, veriffId }
});

export const getPersonFromVeriffSuccess = (data) => ({
  type: agentActionTypes.GET_PERSON_FROM_VERIFF_SUCCESS,
  payload: data
});

export const getPersonFromVeriffFailure = (e) => ({
  type: agentActionTypes.GET_PERSON_FROM_VERIFF_FAILURE,
  payload: e
});

export const startGetCodeToVerifyPhoneNumber = (id, data) => ({
  type: agentActionTypes.START_GET_CODE_TO_VERIFY_PHONE_NUMBER,
  payload: { id, data }
}); 


export const getCodeToVerifyPhoneNumberSuccess = (data) => ({
  type: agentActionTypes.GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS,
  payload: data,

});

export const getCodeToVerifyPhoneNumberFailure = (e) => ({
  type: agentActionTypes.GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE,
  payload: e,
})

export const startCheckCodeToVerifyPhoneNumber = (id, data) => ({
  type: agentActionTypes.START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER,
  payload: { id, data }
});

export const checkCodeToVerifyPhoneNumberSuccess = (data) => ({
  type: agentActionTypes.CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS,
  payload: data,
});

export const checkCodeToVerifyPhoneNumberFailure = (e) => ({
  type: agentActionTypes.CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE,
  payload: e,
});

export const startAgentUpdate = (id, formData) => ({
  type: agentActionTypes.START_AGENT_UPDATE,
  payload: { id, formData },
});

export const agentUpdateSuccess = () => ({
  type: agentActionTypes.AGENT_UPDATE_SUCCESS,
});

export const agentUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentUpdateLastStep = (id, formData) => ({
  type: agentActionTypes.START_AGENT_UPDATE_LAST_STEP,
  payload: { id, formData },
});

export const agentUpdateLastStepSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_SUCCESS,
  },
});

export const agentUpdateLastStepFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});