import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  setClearVehicleForm,
} from '../../../redux/quotes/vehicle/vehicle.actions';
import VehicleQuote
  from '../../../pages/quotes/vehicle/vehicle.component';

const VehicleFormModal = ({
  showVehicleInfo,
  setShowVehicleInfo,
  setClearVehicleForm,
}) => {
  
  const handleClose = () => {
    setShowVehicleInfo(false);
    setClearVehicleForm(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Modal
      size='xl'
      show={showVehicleInfo}
      dialogClassName='modal-100w'
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded border-bottom-0 justify-content-end'>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body className='border-top border-grey'>
        <VehicleQuote setShowVehicleInfo={setShowVehicleInfo}/>
      </Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='btn btn-outline-danger'
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
});

export default connect(mapStateToProps, {
  setClearVehicleForm,
})(VehicleFormModal);
