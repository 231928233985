import annuityBiddingActionTypes from './annuity-bidding.types';
import authActionTypes from '../../auth/auth.types';

let INITIAL_STATE = {
  showAllQuotes: false,
  showAnnuityQuotes: true,
  isAnnuitySelectedByAi: false,
  isSubmit: false,
  isAnnuityBid: false,
  reset: false,
};

// let PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));

// if (PERSISTED_STATE) {
//   INITIAL_STATE = JSON.parse(PERSISTED_STATE.agent);
// }

const annuityBiddingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case annuityBiddingActionTypes.SET_IS_SUBMIT:
      return {
        ...state,
        isSubmit: action.payload,
      };
    case annuityBiddingActionTypes.SET_IS_ANNUITY_BID:
      return {
        ...state,
        isAnnuityBid: action.payload,
      };
    case annuityBiddingActionTypes.SET_SHOW_ALL_QUOTES:
      return {
        ...state,
        showAllQuotes: action.payload,
      };
    case annuityBiddingActionTypes.SET_IS_ANNUITY_SELECTED_BY_AI:
      return {
        ...state,
        isAnnuitySelectedByAi: action.payload,
      };
    case annuityBiddingActionTypes.SET_SHOW_ANNUITY_QUOTES:
      return {
        ...state,
        showAnnuityQuotes: action.payload,
      };
    case annuityBiddingActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        showAllQuotes: false,
        showAnnuityQuotes: true,
        isAnnuitySelectedByAi: false,
        isSubmit: false,
        isAnnuityBid: false,
        reset: false,
      };
    default:
      return state;
  }
};

export default annuityBiddingReducer;
