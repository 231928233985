import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  setClearPropertyForm,
} from '../../../redux/quotes/property/property.actions';

import loadable from '@loadable/component';

const PropertyQuote = loadable(() => import('../../../pages/quotes/property/property.component'));

const PropertyFormModal = ({
  showPropertyInfo,
  setShowPropertyInfo,
  setClearPropertyForm,
}) => {
  
  const handleClose = () => {
    setShowPropertyInfo(false);
    setClearPropertyForm(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Modal
      size='xl'
      show={showPropertyInfo}
      dialogClassName='modal-100w'
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded border-bottom-0 justify-content-end'>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body className='border-top border-grey'>
        <PropertyQuote setShowPropertyInfo={setShowPropertyInfo}/>
      </Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='btn btn-outline-danger'
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
});

export default connect(mapStateToProps, {
  setClearPropertyForm,
})(PropertyFormModal);
