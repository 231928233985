import propertyBiddingActionTypes from './property-bidding.types';
import authActionTypes from '../../auth/auth.types';

let INITIAL_STATE = {
  showPropertyQuotes: true,
  isPropertySelectedByAi: false,
  isSubmit: false,
  isPropertyBid: false,
  reset: false,
};

// let PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));

// if (PERSISTED_STATE) {
//   INITIAL_STATE = JSON.parse(PERSISTED_STATE.agent);
// }

const propertyBiddingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case propertyBiddingActionTypes.SET_IS_SUBMIT:
      return {
        ...state,
        isSubmit: action.payload,
      };
    case propertyBiddingActionTypes.SET_IS_PROPERTY_BID:
      return {
        ...state,
        isPropertyBid: action.payload,
      };
    case propertyBiddingActionTypes.SET_SHOW_ALL_QUOTES:
      return {
        ...state,
        showAllQuotes: action.payload,
      };
    case propertyBiddingActionTypes.SET_IS_PROPERTY_SELECTED_BY_AI:
      return {
        ...state,
        isPropertySelectedByAi: action.payload,
      };
    case propertyBiddingActionTypes.SET_SHOW_PROPERTY_QUOTES:
      return {
        ...state,
        showPropertyQuotes: action.payload,
      };
    case propertyBiddingActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        showAllQuotes: false,
        showPropertyQuotes: true,
        isPropertySelectedByAi: false,
        isSubmit: false,
        isPropertyBid: false,
        reset: false,
      };
    default:
      return state;
  }
};

export default propertyBiddingReducer;
