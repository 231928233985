import { ModalState, ModalTypes } from '../../modal/modal.types';
import healthQuoteActionTypes from './health.types';

const payload = {
  context: 'healthQuote',
  status: '',
  error: '',
};

export const decrementCurrentStep = (currentStep) => ({
  type: healthQuoteActionTypes.HEALTH_DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: healthQuoteActionTypes.HEALTH_INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const setQuoteId = (id) => ({
  type: healthQuoteActionTypes.SET_HEALTH_QUOTE_ID,
  payload: id,
});

export const startCheckingChatGroup = (id) => ({
  type: healthQuoteActionTypes.START_CHECKING_CHAT_GROUP_HEALTH,
  payload: id,
})

export const checkingChatGroupSuccess = () => ({
  type: healthQuoteActionTypes.CHECKING_CHAT_GROUP_SUCCESS_HEALTH,
})

export const saveDeleteCometChat = (id) => ({
  type: healthQuoteActionTypes.SAVE_DELETE_HEALTH_COMET_CHAT,
  payload: id,
})

export const saveDeleteCometChatSuccess = () => ({
  type: healthQuoteActionTypes.SAVE_DELETE_HEALTH_COMET_CHAT_SUCCESS,
})

export const setIncrementCount = (count) => ({
  type: healthQuoteActionTypes.SET_HEALTH_INCREMENT_COUNT,
  payload: count,
});

export const setDecrementCount = (count) => ({
  type: healthQuoteActionTypes.SET_HEALTH_DECREMENT_COUNT,
  payload: count,
});

export const setHealthFormData = (data) => ({
  type: healthQuoteActionTypes.SET_HEALTH_FORM_DATA,
  payload: data,
});

export const setHealthInfos = (data) => ({
  type: healthQuoteActionTypes.SET_HEALTH_INFOS,
  payload: data,
});

export const setMembersInfo = (members) => ({
  type: healthQuoteActionTypes.SET_MEMBERS_INFO,
  payload: members,
});

export const startFetchingHealthQuotesFromDB = () => ({
  type: healthQuoteActionTypes.FETCH_HEALTH_QUOTES_FROM_DB_START,
});

export const fetchHealthQuotesFromDBSuccess = (quote) => ({
  type: healthQuoteActionTypes.FETCH_HEALTH_QUOTES_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchHealthQuotesFromDBFailure = (e) => ({
  type: healthQuoteActionTypes.FETCH_HEALTH_QUOTES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingHealthQuoteFromDB = (id) => ({
  type: healthQuoteActionTypes.FETCH_HEALTH_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchHealthQuoteFromDBSuccess = (quote) => ({
  type: healthQuoteActionTypes.FETCH_HEALTH_QUOTE_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchHealthQuoteFromDBFailure = (e) => ({
  type: healthQuoteActionTypes.FETCH_HEALTH_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startHealthQuoteSave = (quotes) => ({
  type: healthQuoteActionTypes.START_HEALTH_QUOTE_SAVE,
  payload: quotes,
});

export const healthQuoteSaveSuccess = () => ({
  type: healthQuoteActionTypes.HEALTH_QUOTE_SAVE_SUCCESS,
});

export const healthQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startHealthQuoteUpdate = (id, formData) => ({
  type: healthQuoteActionTypes.START_HEALTH_QUOTE_UPDATE,
  payload: { id, formData },
});

export const healthQuoteUpdateSuccess = () => ({
  type: healthQuoteActionTypes.HEALTH_QUOTE_UPDATE_SUCCESS,
});

export const healthQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLastStepHealthQuoteUpdate = (id, formData) => ({
  type: healthQuoteActionTypes.START_LAST_STEP_HEALTH_QUOTE_UPDATE,
  payload: { id, formData },
});

export const lastStepHealthQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.QUOTE_REQUEST_SUCCESS,
  },
});

export const lastStepHealthQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

