import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import agentActionTypes from '../../redux/agent/agent.types';
import agentBiddingActionTypes from '../../redux/agent-biddings/agent-bidding.types';
import profileActionTypes from '../../redux/profile/profile.types';
import 
  companionAnimalActionTypes 
from '../../redux/quotes/companion-animal/companion-animal.types';
import 
  healthActionTypes 
from '../../redux/quotes/health/health.types';
import 
  memberHistoryActionTypes 
from '../../redux/quotes/member-history/member-history.types';
import 
  memberActionTypes 
from '../../redux/member/member.types';
import 
  propertyActionTypes 
from '../../redux/quotes/property/property.types';
import 
  vehicleActionTypes 
from '../../redux/quotes/vehicle/vehicle.types';
import 
  memberVehiclePolicyActionTypes 
from '../../redux/policies/vehicle/vehicle.types';
import 
  memberCompanionAnimalPolicyActionTypes 
from '../../redux/policies/companion-animal/companion-animal.types';
import 
  memberPropertyPolicyActionTypes 
from '../../redux/policies/property/property.types';
import 
  memberAnnuityPolicyActionTypes 
from '../../redux/policies/annuity/annuity.types';
import 
  memberLifePolicyActionTypes 
from '../../redux/policies/life/life.types';
import memberMedicalPolicyActionTypes
  from '../../redux/policies/medical/medical.types';
import animalQuoteActionTypes
  from '../../redux/requested-quotes/companion-animal/companion-animal.types';
import propertyQuoteActionTypes
  from '../../redux/requested-quotes/property/property.types';
import vehicleQuoteActionTypes
  from '../../redux/requested-quotes/vehicle/vehicle.types';
import combineCoverageQuoteActionTypes 
  from '../../redux/requested-quotes/combine-coverages/combine-coverages.types';
import healthQuoteActionTypes
  from '../../redux/requested-quotes/health/health.types';
import lifeQuoteActionTypes
  from '../../redux/requested-quotes/life/life.types';
import annuityQuoteActionTypes
  from '../../redux/requested-quotes/annuity/annuity.types';
import agentAnnuityQuoteActionTypes
  from '../../redux/agent-quotes/annuity/annuity.types';
import agentCompanionAnimalQuoteActionTypes
  from '../../redux/agent-quotes/companion-animal/companion-animal.types';
import agentLifeQuoteActionTypes
  from '../../redux/agent-quotes/life/life.types';
import agentMedicalQuoteActionTypes
  from '../../redux/agent-quotes/medical/medical.types';
import agentPropertyQuoteActionTypes
  from '../../redux/agent-quotes/property/property.types';
  import agentVehicleQuoteActionTypes
from '../../redux/agent-quotes/vehicle/vehicle.types';
import { MODAL_CMS_DATA } from './modal.cms';
import { ModalState, ModalTypes } from '../../redux/modal/modal.types';
import './modal.styles.css';

const formMap = {
  companionAnimal: companionAnimalActionTypes,
  health: healthActionTypes,
  member: memberActionTypes,
  memberHistory: memberHistoryActionTypes,
  property: propertyActionTypes,
  vehicle: vehicleActionTypes,
  agent: agentActionTypes,
  agentBidding: agentBiddingActionTypes,
  profile: profileActionTypes,
  memberCompanionAnimalPolicy: memberCompanionAnimalPolicyActionTypes,
  memberVehiclePolicy: memberVehiclePolicyActionTypes,
  memberPropertyPolicy: memberPropertyPolicyActionTypes,
  memberAnnuityPolicy: memberAnnuityPolicyActionTypes,
  memberLifePolicy: memberLifePolicyActionTypes,
  memberMedicalPolicy: memberMedicalPolicyActionTypes,
  animalQuote: animalQuoteActionTypes,
  propertyQuote: propertyQuoteActionTypes,
  vehicleQuote: vehicleQuoteActionTypes,
  combineCoverageQuote: combineCoverageQuoteActionTypes,
  healthQuote: healthQuoteActionTypes,
  lifeQuote: lifeQuoteActionTypes,
  annuityQuote: annuityQuoteActionTypes,
  agentAnnuityQuote: agentAnnuityQuoteActionTypes,
  agentAnimalQuote: agentCompanionAnimalQuoteActionTypes,
  agentLifeQuote: agentLifeQuoteActionTypes,
  agentMedicalQuote: agentMedicalQuoteActionTypes,
  agentPropertyQuote: agentPropertyQuoteActionTypes,
  agentVehicleQuote: agentVehicleQuoteActionTypes,
};

const AppModal = () => {
  const dispatch = useDispatch();
  const { status, context } = useSelector((state) => state.modal);
  const history = useHistory();
  const content = MODAL_CMS_DATA [
    status ||
    ModalState.SUCCESS ||
    ModalState.AGENT_SUCCESS ||
    ModalState.AGENT_BID_SUCCESS ||
    ModalState.AGENT_QUOTE_SUCCESS ||
    ModalState.AGENT_UPDATE_QUOTE_SUCCESS ||
    ModalState.POLICY_SUCCESS ||
    ModalState.RESET_PASSWORD_SUCCESS ||
    ModalState.QUOTE_REQUEST_SUCCESS ||
    ModalState.ERROR 
  ];
  const { heading, description, action, goto } =
    content[context] || content.default;
  
  const path = history.location.pathname.replace(/\//g, '');
  const memberP = 'member-information';
  const memberD = 'member-dashboard';

  let isAgent = false;
  let isPolicy = false;

  if (path.includes('agent')) {
    isAgent = true;
  }

  if (path.includes('policy')) {
    isPolicy = true;
  }

  const onHide = () => {
    dispatch({ type: ModalTypes.DISMISS_MODAL });
  };

  const handlePrimary = () => {
    onHide();
    if (isAgent || isPolicy) {
      history.push(goto.dashboard);
    } else {
      history.push(goto.quotes);
    }
    window.location.reload();
    window.scrollTo({ top: 0 });
  };

  const handleSecondary = () => {
    onHide();
    dispatch({
      type: formMap[context].RESET_FORM,
    });
    if (path === memberP) {
      history.push(goto.household);
    } else if (path.includes('policy')) {
      history.push(window.location.pathname);
    } else {
      history.push(goto.dashboard);
    }
    window.location.reload();
    window.scrollTo({ top: 0 });
  };

  const handleBidPrimary = () => {
    onHide();
    if (isAgent) {
      history.push(goto.bid);
    } else {
      history.go(0);
    }
  };

  const handleBidSecondary = () => {
    onHide();
  };

  const handleAgentQuotePrimary = () => {
    onHide();
    setTimeout(() => {
      history.push('/empty')
      history.goBack('/agent-bidding');
    }, 500)
    window.location.reload();
  };

  return (
    <Modal show={!!status} dialogClassName={`ie-modal ${status}`} centered>
      {status === ModalState.SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              path === memberD
                ? description.primeMember
                : description.quote
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={handleSecondary}
            >
              {
                path === memberD
                  ? action.dashboard
                  : action.secondary
              }
            </Button>
            <Button onClick={handlePrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.POLICY_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={handleSecondary}
            >
              {action.secondary}
            </Button>
            <Button onClick={handlePrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.QUOTE_REQUEST_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={handleSecondary}
            >
              {action.secondary}
            </Button>
            <Button onClick={handlePrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.AGENT_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button onClick={handlePrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.AGENT_BID_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleBidSecondary}
              >
                {action.secondary}
              </Button>
            <Button onClick={handleBidPrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.MEMBER_SELECT_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button onClick={handleBidPrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.AGENT_QUOTE_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAgentQuotePrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.AGENT_UPDATE_QUOTE_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button onClick={handleAgentQuotePrimary}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.RESET_PASSWORD_SUCCESS ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : status === ModalState.ERROR ? (
        <>
          <Modal.Header>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button onClick={onHide}>{action.primary}</Button>
          </Modal.Footer>
        </>
      ) : null}
    </Modal>
  );
};

export { AppModal as Modal };
