import React, { useState } from 'react';
import uuid from 'react-uuid';

import loadable from '@loadable/component';

const CombineQuoteDetails = loadable(() => import('./combine-quote-details.component'));

const CombineQuotesDetails = ({ combineCoverageQuotes }) => {
  const [storeFilterQuotes, setStoreFilterQuotes] = useState(false)

  return (
    <div>
      {combineCoverageQuotes &&
        combineCoverageQuotes.length !== 0 ? (
        <>
          {combineCoverageQuotes.map((c, i) => (
            <div key={i}>
              {c && c.isLastStep ? (
                <CombineQuoteDetails
                  combined={c}
                  elmId={uuid()}
                  storeFilterQuotes = {storeFilterQuotes}
                  setStoreFilterQuotes = {setStoreFilterQuotes}
                />
              ) : null}
            </div>
          ))}
        </>
      ) : null}  
    </div>
  );
};

export default CombineQuotesDetails;
