import { useFormikContext } from 'formik';
import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const Dropdown = ({
  name,
  errors,
  touched,
  values,
  type,
  style,
  id,
  isMulti,
  isDisabled,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  // this section works only for dropdowns with currentIndex prop set
  if (props.currentIndex !== undefined) {
    var nameTokens = name.split('.');
    if (nameTokens[1] === 'relationship') {
      values[name] = values.relationships[props.currentIndex].relationship;
    } else if (nameTokens[1] === 'doa') {
      values[name] = values.relationships[props.currentIndex].doa;
    }
  }

  const options = isMulti
    ? values[name]
    : props.options.find(({ value }) => value === values[name]) || null;

  return (
    <Select
      {...props}
      id={id}
      isDisabled={isDisabled}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      components={animatedComponents}
      className={
        props.currentIndex !== undefined
          ? `select ${touched[type] ? `is-${!!errors[type] ? 'invalid' : 'valid'}` : ''}`
          : `select ${touched[name] ? `is-${!!errors[name] ? 'invalid' : 'valid'}` : ''}`
      }
      value={options}
      onChange={(val) => {
        let value = val;
        if (!isMulti) {
          value = val.value;
        }
        setFieldValue(name, value);
      }}
      isOptionDisabled={(option) => option.disabled === 'true'}
      isValid={touched.type && !errors.type}
      isInvalid={!!errors.type}
      theme={(theme) => ({
        ...theme,
        boxShadow: '0 0 0 0.2rem rgba(0,146,112,.25)',
        colors: {
          ...theme.colors,
          primary50: 'rgba(0,146,112,.25)',
          primary25: 'rgba(0,146,112,.25)',
          primary: '#349270',
        },
      })}
    />
  );
};

export default Dropdown;
