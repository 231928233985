export const PRODUCTS_CMS_DATA = {
  products: [
    {
      title: `Vehicle (Auto) Insurance`,
      imageUrl: './images/car.svg',
      text: `We offer insurances for all types of vehicles, including your personal or
      business car or truck, motorcycles, watercrafts, construction vehicles, farming
      vehicles, etc. Don't get caught without proper insurance. We make it simple and
      convenient to get quotes from multiple Agents and many Carriers, as well as
      implementing easier ways to compare what coverages you would actually be getting
      before you encounter a unwanted surprise later.`,
    },
    {
      title: `Property Insurance`,
      imageUrl: `./images/property.svg`,
      text: `Secure your home and any other properties, including small businesses, as
      well as your personal and policy information here, in one place. When you are
      needing to make a claim, you'll already be in enough stress and do not need to
      add to it by trying to remember which Carrier you are with and where you are
      keeping pertinent documents, phone numbers, URLs, etc. Instead, access your
      informaiton whenever or wherever you are by visiting this site.`,
    },
    {
      title: `Life Insurance`,
      imageUrl: `./images/life.svg`,
      text: `Find the Life policy that is right for you and, more importantly, your
      loved ones. Be informed about policies and ask our Agents questions through our
      chat room that is set up every time you request a quote. Agents are chosen for
      your specific needs so you rest assured that they will be able to resolve any
      issues you might have. Plus, the Agents are being peer reviewed by other Agents
      in the chat room. Plan for the future, making sure you and your loved ones are
      taken care of.`,
    },
    {
      title: `Medical (Health) Insurance`,
      imageUrl: `./images/health.svg`,
      text: `Whether you are needing a Major Medical Plan or a Medicare Supplemental
      Plan, protect yourself and the ones you love and we'll protect your information,
      to reference and get quotes any time, on your time. Once you request a quote,
      you will not need to enter that information again, making the next time you
      request a quote much quicker and easier. All you need to do is add information
      pertinent to the new quote you are getting or edit information that has changed
      since the last time you updated the pertinent information. Note that Affordable
      Care Act (ACA) or ObamaCare policies are offered here.`,
    },
    {
      title: `Annuities`,
      imageUrl: `./images/annuity.svg`,
      text: `Consider your options. Annuities can be a great way to plan for retirement
      or extend a lump sum of cash. They usually offer other benefits as well, like a
      built in Life policy (sometimes very similar to having a Universal Life policy).
      Allow our agents to present optimal quotes, based on your concerns, for you to
      choose from. Once you choose a quote, the corresponding agent will help you turn
      the quote into a policy, leaving you confident in your decision.`,
    },
    {
      title: `Companion Animal (Pet) Insurance`,
      imageUrl: `./images/pet.svg`,
      text: `We take care of you so that you can focus on others, like your furry or
      maybe not so furry companions. It's easy to forget about their need to go to a
      veterinarian because you don't want to think about anything bad happening to
      them. You simply want to cuddle and spend time with them. But the bills can get
      expensive and you don't wanna lose them, especially when we make it so easy to
      get them insured. Don't waste time when you don't need to. Let us work for you.`,
    },
  ],
};