import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import AccountActivation from './components/account-activation/account-activation.component';
import ForgotPassword from './components/forgot-password/forgot-password.component';
import ResetPassword from './components/reset-password/reset-password.component';
import authGuard from './helpers/authGuard';
import Carriers from './pages/carriers/carriers.component';
import AgentDashboard from './pages/agent-dashboard/agent-dashboard.component';
import AgentLanding from './pages/agent-landing/agent-landing.component';
import GetQuotes from './pages/get-quotes/get-quotes.component';
import MembersHealthEditing from './pages/quotes/health/health-editing.component';
import MembersEditing from './pages/household-members/household-members-editing.component';
import MemberDashboard from './pages/member-dashboard/member-dashboard.component';
import MemberLanding from './pages/member-landing/member-landing.component';
import MemberPoliciesDashboard from './pages/member-policies-dashboard/member-policies-dashboard.component';
import MemberProfileDashboard from './pages/profile-dashboard/profile-dashboard.component';
import Member from './pages/member/member.component';
import AgentsList from './pages/agents-list/agents-list.component';
import EditProperties from './pages/quotes/property/property-editing.component';
import EditVehicles from './pages/quotes/vehicle/vehicle-editing.component';
import EditAnimals from './pages/quotes/companion-animal/companion-animal-editing.component';
import MemberProfile from './pages/profile/member-profile.component';
import AgentProfile from './pages/profile/agent-profile.component';
import MemberViewQuotes
  from './pages/member-view-quotes/member-view-quotes.component';

import loadable from '@loadable/component';

const About = loadable(() => import('./pages/about/about.component'));
const Contact = loadable(() => import('./pages/contact/contact.component'));
const LifeSteppers = loadable(() => import('./pages/requested-quotes/life/life-steppers.component'));
const AnnuitySteppers = loadable(() => import('./pages/requested-quotes/annuity/annuity-steppers.component'));
const HealthSteppers = loadable(() => import('./pages/requested-quotes/health/health-steppers.component'));
const VehicleSteppers = loadable(() => import('./pages/requested-quotes/vehicle/vehicle-steppers.component'));
const PropertySteppers = loadable(() => import('./pages/requested-quotes/property/property-steppers.component'));
const AnimalSteppers = loadable(() => import('./pages/requested-quotes/companion-animal/companion-animal-steppers.component'));
const CombineCoverageSteppers = loadable(() => import('./pages/requested-quotes/combine-coverages/combine-coverage-steppers.component'));
const BiddingProcess = loadable(() => import('./pages/agent-bidding/agent-bidding.component'));
const AgentBids = loadable(() => import('./pages/agent-bids/agent-bids.component'));
const AgentClients = loadable(() => import('./pages/agent-clients/agent-clients.component'));
const AllAnnuityPolicies = loadable(() => import('./pages/policies/annuity/annuity-all-policies.component'));
const AllAnimalPolicies = loadable(() => import('./pages/policies/companion-animal/companion-animal-all-policies.component'));
const AllLifePolicies = loadable(() => import('./pages/policies/life/life-all-policies.component'));
const AllMedicalPolicies = loadable(() => import('./pages/policies/medical/medical-all-policies.component'));
const AllPropertyPolicies = loadable(() => import('./pages/policies/property/property-all-policies.component'));
const AllVehiclePolicies = loadable(() => import('./pages/policies/vehicle/vehicle-all-policies.component'));

ReactDOM.render(
  <BrowserRouter>
    <App>
      <Switch>
        <Route
          exact
          path='/'
          component={MemberLanding}
        />
        <Route
          path='/agent-home'
          component={AgentLanding}
        />
        <Route
          path='/about'
          component={About}
        />
        <Route
          path='/contact'
          component={Contact}
        />
        <Route
          path='/carriers'
          component={Carriers}
        />
        <Route
          path='/member-profile'
          component={authGuard(MemberProfile)}
        />
        <Route
          path='/agent-profile'
          component={authGuard(AgentProfile)}
        />
        <Route
          path='/member-dashboard'
          component={authGuard(MemberDashboard)}
        />
        <Route
          path='/member-policies'
          component={authGuard(MemberPoliciesDashboard)}
        />
        <Route
          path='/member-profile-dashboard'
          component={authGuard(MemberProfileDashboard)}
        />
        <Route
          path='/member-animal-policy'
          component={authGuard(AllAnimalPolicies)}
        />
        <Route
          path='/member-vehicle-policy'
          component={authGuard(AllVehiclePolicies)}
        />
        <Route
          path='/member-property-policy'
          component={authGuard(AllPropertyPolicies)}
        />
        <Route
          path='/member-annuity-policy'
          component={authGuard(AllAnnuityPolicies)}
        />
        <Route
          path='/member-life-policy'
          component={authGuard(AllLifePolicies)}
        />
        <Route
          path='/member-medical-policy'
          component={authGuard(AllMedicalPolicies)}
        />
        <Route
          path='/member-quotes'
          component={authGuard(GetQuotes)}
        />
        <Route
          path='/member-information'
          component={authGuard(Member)}
        />
        <Route
          path='/household-members-information'
          component={authGuard(MembersEditing)}
        />
        <Route
          path='/household-animals-information'
          component={authGuard(EditAnimals)}
        />
        <Route
          path='/household-properties-information'
          component={authGuard(EditProperties)}
        />
        <Route
          path='/household-vehicle-information'
          component={authGuard(EditVehicles)}
        />
        <Route
          path='/household-health-information'
          component={authGuard(MembersHealthEditing)}
        />
        <Route
          path='/household-animal-quotes'
          component={authGuard(AnimalSteppers)}
        />
        <Route
          path='/household-property-quotes'
          component={authGuard(PropertySteppers)}
        />
        <Route
          path='/household-vehicle-quotes'
          component={authGuard(VehicleSteppers)}
        />
        <Route
          path='/household-combined-quotes'
          component={authGuard(CombineCoverageSteppers)}
        />
        <Route
          path='/household-health-quotes'
          component={authGuard(HealthSteppers)}
        />
        <Route
          path='/household-life-quotes'
          component={authGuard(LifeSteppers)}
        />
        <Route
          path='/household-annuity-quotes'
          component={authGuard(AnnuitySteppers)}
        />
        <Route
          path='/member-view-quotes'
          component={authGuard(MemberViewQuotes)}
        />
        <Route
          path='/agent-dashboard'
          component={authGuard(AgentDashboard)}
        />
        <Route
          path='/agents-list'
          component={authGuard(AgentsList)}
        />
        <Route
          path='/agent-bidding'
          component={authGuard(BiddingProcess)}
        />
        <Route
          path='/agent-bids'
          component={authGuard(AgentBids)}
        />
        <Route
          path='/agent-clients'
          component={authGuard(AgentClients)}
        />
        <Route
          path='/activate-account'
          component={AccountActivation}
        />
        <Route
          path='/forgot-password'
          component={ForgotPassword}
        />
        <Route
          path='/reset-password/:email'
          component={ResetPassword}
        />
      </Switch>
    </App>
  </BrowserRouter>,
  document.getElementById('root')
);
