import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import {
  selectAgent,
} from '../../redux/agent/agent.selectors';
import {
  selectIsInitiatedFromAgentShowcase,
} from '../../redux/navigation/navigation.selectors';
import { logout } from '../../redux/auth/auth.actions';

const BrowserWarningModal = ({show, setShow, logout, portalAccessedAsAgent}) => {
  const agentH = '/agent-home';
  const history = useHistory();

  const handleClose = () => {
    setShow(false);
    logout();
    if (portalAccessedAsAgent) {
      history.push(agentH);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Modal
      size='m'
      show={show}
      dialogClassName='modal-100w'
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded justify-content-end'>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body className='text-danger bordered py-4 font-size-xl text-center'>
        Please use Chrome web browser!
      </Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='btn btn-outline-danger'
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  agent: selectAgent,
  portalAccessedAsAgent: selectIsInitiatedFromAgentShowcase,
});

export default connect(mapStateToProps, {
  logout
})(BrowserWarningModal);
