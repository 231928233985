import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { PROPERTY_QUOTES_CMS_DATA } from './property.cms';
import propertySchema from './property.validation.schema';
import EditProperties from '../../../components/quotes/property/property-editing.component';
import Ownership from '../../../components/quotes/property/property-ownership.component';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  setIsSubmitted,
  startLoadingStatesFromDB,
  startPropertySave,
  startPropertyUpdate,
  startPropertyInQuoteSave,
  startPropertyInQuoteUpdate,
  togglePropertyInfoEdit,
  togglePropertyForm,
} from '../../../redux/quotes/property/property.actions';
import {
  selectPropertyById,
  selectReset,
  selectStates,
  selectIsSubmitted,
  selectShowPropertyForm,
  selectShowPropertyInfoEdit,
} from '../../../redux/quotes/property/property.selectors';
import {
  setPropertyFormData,
} from '../../../redux/requested-quotes/property/property.actions';
import {
  selectPropertyFormData,
} from '../../../redux/requested-quotes/property/property.selectors';
import {
  setCombinedFormData,
} from '../../../redux/requested-quotes/combine-coverages/combine-coverages.actions';
import {
  selectCombinedFormData,
} from '../../../redux/requested-quotes/combine-coverages/combine-coverages.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import ErrorMessages from '../../../components/error-message/error-messages.component';
import {
  scrollIntoViewForms
} from '../../../components/auto-scrolling/scroll-into-view-forms.utils';

import loadable from '@loadable/component';

const Coverage = loadable(() => import('../../../components/quotes/property/property-coverage.component'));
const Construction = loadable(() => import('../../../components/quotes/property/property-construction.component'));
const Purpose = loadable(() => import('../../../components/quotes/property/property-purpose.component'));

const PropertyQuote = ({
  values,
  touched,
  errors,
  reset,
  resetForm,
  isSubmitting,
  handleSubmit,
  handleChange,
  states,
  property,
  currentUser,
  isSubmitted,
  setIsSubmitted,
  showPropertyInfoEdit,
  showPropertyForm,
  togglePropertyForm,
  togglePropertyInfoEdit,
  startLoadingStatesFromDB,
  startFetchingPrimeMemberFromDB,
}) => {
  const {
    heading,
    subHeading,
    form: { button },
  } = PROPERTY_QUOTES_CMS_DATA;

  const formikProps = { values, handleChange, touched, errors, reset, resetForm };
  
  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const propertyP = 'household-properties-information';
  const agentBid = 'agent-bids';

  const selection = document.getElementById('property-slt');
  const selectionHeight =
    selection
      ? selection.getBoundingClientRect().height + 60
      : '';

  const [isOwned, setIsOwned] = useState(false);
  const [isFinanced, setIsFinanced] = useState(false);

  useEffect(() => {
    if (path !== propertyP) {
      scrollIntoViewForms(
        propertyP,
        selectionHeight,
        showPropertyForm,
        showPropertyInfoEdit,
      )
    }
  }, [
    path,
    propertyP,
    selectionHeight,
    showPropertyForm,
    showPropertyInfoEdit,
  ])

  useEffect(() => {
    if (
      path !== propertyP &&
      isSubmitted
    ) {
      setIsSubmitted(false);
      setTimeout(() => {
        startFetchingPrimeMemberFromDB(currentUser.member);
        window.scrollTo({
          top: selectionHeight,
          behavior: 'smooth'
        });
      }, 500)
    }
  }, [
    path,
    history,
    propertyP,
    selectionHeight,
    isSubmitted,
    setIsSubmitted,
    currentUser.member,
    startFetchingPrimeMemberFromDB,
  ])

  useEffect(() => {
    reset && resetForm();
  }, [reset, resetForm]);

  useEffect(() => {
    if (states.length === 0) {
      startLoadingStatesFromDB();
    }
  }, [states, startLoadingStatesFromDB]);

  useEffect(() => {
    if (values.ownershipType === 'own') {
      if (values.responsibilityType === 'entireStructure') {
        setIsOwned(true);
        setIsFinanced(false);
      }
      if (values.responsibilityType === 'interiorOnly') {
        setIsOwned(false);
        setIsFinanced(false);
      }
    }

    if (values.ownershipType === 'finance') {
      if (values.responsibilityType === 'entireStructure') {
        setIsOwned(false);
        setIsFinanced(true);
      }
      if (values.responsibilityType === 'interiorOnly') {
        setIsOwned(false);
        setIsFinanced(false);
      }
    }
  }, [
    values,
    setIsOwned,
    setIsFinanced,
  ]);

  return (
    <section>
      <Container className='px-0'>
        {path === propertyP ? (
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
        ) : null}
        <FormikForm noValidate>
          {showPropertyInfoEdit ? (
            <EditProperties {...formikProps} />
          ) : null}
        
          {showPropertyForm ? (
            <>
              {/* Show this section only if the user selects from the purpose dropdown */}
              <Purpose {...formikProps} />
              {/* Show these sections only if the property is owned or financed */}
              {isOwned || isFinanced ? (
                <>
                  <Construction {...formikProps} />
                  <Ownership {...formikProps} />
                  <Coverage {...formikProps} />
                </>
              ) : (
              // Show this section only if the property is leased 
                <Coverage {...formikProps} />
              )}
                <div className='float-right'>
                  <ErrorMessages errors={errors}/>
                  {path !== agentBid ? (
                    <div className='float-right'>
                      {path !== propertyP ? (
                        <Button
                          variant='outline-danger'
                          className='mr-3'
                          onClick={() => {
                            togglePropertyForm(false);
                            togglePropertyInfoEdit(false);
                          }}
                        >
                          {button.cancel}
                        </Button>
                      ) : null}
                      {property._id ? (
                        <Button
                          disabled={isSubmitting}
                          onClick={handleSubmit}
                        >
                          {button.update}
                        </Button>
                      ) : (
                        <Button
                          disabled={isSubmitting}
                          onClick={handleSubmit}
                        >
                          {button.save}
                        </Button>
                      )}
                    </div>
                  ) : null}
              </div>
            </>
          ) : null}
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikPropertyQuote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ property, currentUser }) => ({
    addProperty: '',
    purpose: property && property.purpose
      ? (property.purpose.residence && 'residence') ||
      (property.purpose.business && 'business') ||
      (property.purpose.both && 'both')
      : '',
    residenceType: property.residenceType
      ? (property.residenceType.dorm && 'dorm') ||
      (property.residenceType.forDependent && 'dependent') ||
      (property.residenceType.homeResidence && 'home')
      : '',
    businessType: property.businessType
      ? (property.businessType.farming.isTypeOf && 'farm') ||
      (property.businessType.garage.isTypeOf && 'garage') ||
      (property.businessType.office.isTypeOf && 'office') ||
      (property.businessType.rental.isTypeOf && 'rental') ||
      (property.businessType.shop.isTypeOf && 'shop') ||
      (property.businessType.other.isTypeOf && 'other')
      : '',
    isCrop:
      property.businessType &&
      property.businessType.farming.isCrop 
        ? 'true' 
        : 'false',
    farmSize:
      property.businessType &&
      property.businessType.farming.farmSize
        ? property.businessType.farming.farmSize
        : '',
    businessTypeElaboration: property.businessType
      ? (property.businessType.farming.farmingInfo ? property.businessType.farming.farmingInfo : '') ||
      (property.businessType.garage.garageInfo ? property.businessType.garage.garageInfo : '') ||
      (property.businessType.office.officeInfo ? property.businessType.office.officeInfo : '') ||
      (property.businessType.rental.rentalInfo ? property.businessType.rental.rentalInfo : '') ||
      (property.businessType.shop.shopInfo ? property.businessType.shop.shopInfo : '') ||
      (property.businessType.other.otherInfo ? property.businessType.other.otherInfo : '')
      : '',
    yearObtained: property.yearObtained && property.yearObtained !== null
      ? property.yearObtained
      : '',
    streetAddress: property.address
      ? property.address.streetAddress
      : '',
    city: property.address
      ? property.address.city
      : '',
    state: property.address
      ? property.address.state
      : '',
    zipCode: property.address && property.address.zipCode !== null
      ? property.address.zipCode
      : '',
    ownershipType: property.ownership
      ? (property.ownership.finance && 'finance') ||
      (property.ownership.lease && 'lease') ||
      (property.ownership.own && 'own')
      : '',
    responsibilityType: property.responsibility
      ? (property.responsibility.entireStructure && 'entireStructure') ||
      (property.responsibility.interiorOnly && 'interiorOnly') 
      : 'entireStructure',
    propertyType: property.housingProperty || property.businessProperty
      ? (property.housingProperty.apartment && 'apartment') ||
      (property.housingProperty.condo && 'condo') ||
      (property.housingProperty.house && 'house') ||
      (property.housingProperty.mobileHome && 'mobile') ||
      (property.housingProperty.multiplex && 'multiplex') ||
      (property.housingProperty.tinyHouse && 'tiny') ||
      (property.housingProperty.townHome && 'townHome') ||
      (property.businessProperty.apartment && 'apartment') ||
      (property.businessProperty.apartmentComplex.isTypeOf && 'apartmentComplex') ||
      (property.businessProperty.barn && 'barn') ||
      (property.businessProperty.condo && 'condo') ||
      (property.businessProperty.condoBuilding.isTypeOf && 'condoBuilding') ||
      (property.businessProperty.house && 'house') ||
      (property.businessProperty.mobileHome && 'mobile') ||
      (property.businessProperty.multiplex.isTypeOf && 'multiplex') ||
      (property.businessProperty.noStructures && 'noStructures') ||
      (property.businessProperty.smallBusinessBuilding && 'smallUnit') ||
      (property.businessProperty.unitInABuilding.isTypeOf && 'unitInABuilding') ||
      (property.businessProperty.tinyHouse && 'tiny') ||
      (property.businessProperty.townHome.isTypeOf && 'townhome') 
      : '',
    noOfUnits: property.businessProperty
      ? (property.businessProperty.apartmentComplex.noOfUnits !== null
        ? property.businessProperty.apartmentComplex.noOfUnits
        : '') ||
      (property.businessProperty.condoBuilding.noOfUnits !== null
        ? property.businessProperty.condoBuilding.noOfUnits
        : '') ||
      (property.businessProperty.multiplex.noOfUnits !== null
        ? property.businessProperty.multiplex.noOfUnits
        : '') ||
      (property.businessProperty.townHome.noOfUnits !== null
        ? property.businessProperty.townHome.noOfUnits
        : '') ||
      (property.businessProperty.unitInABuilding.noOfUnits !== null
        ? property.businessProperty.unitInABuilding.noOfUnits
        : '')
      : '',
    noOfBuildings:
      property.businessProperty &&
      property.businessProperty.apartmentComplex.noOfBuildings !== null 
        ? property.businessProperty.apartmentComplex.noOfBuildings
        : '',
    frame: property.frameType ? property.frameType : [],
    wall: property.wallType ? property.wallType : [],
    roofShape: property.roofType ? property.roofType.shape : [],
    roofMaterial: property.roofType ? property.roofType.material : [],
    roofYear: property.roofType && property.roofType.yearType
      ? (property.roofType.yearType.fifteen && '15') ||
      (property.roofType.yearType.twenty && '20') ||
      (property.roofType.yearType.twentyFive && '25') ||
      (property.roofType.yearType.thirty && '30') ||
      (property.roofType.yearType.fourty && '40') ||
      (property.roofType.yearType.fiftyPlus && '50+')
      : '',
    roofInstallationYear: property.roofType && property.roofType.yearOfInstallation !== null
      ? property.roofType.yearOfInstallation
      : '',
    hasBasement: property.basement
      ? property.basement.hasBasement
        ? 'true'
        : 'false'
      : '',
    noOfBasements:
      property.basement &&
      property.basement.hasBasement !== null
        ? property.basement.noOfBasements
        : '',
    basementFoundation: property.foundationType ? property.foundationType : [],
    otherConstructionInfo: property.otherConstructionInfo ? property.otherConstructionInfo : '',
    value:
      property.ownershipDetails &&
      property.ownershipDetails.valueOfStructure !== null
        ? property.ownershipDetails.valueOfStructure
        : '',
    year:
      property.ownershipDetails &&
      property.ownershipDetails.yearBuilt !== null
        ? property.ownershipDetails.yearBuilt
        : '',
    area:
      property.ownershipDetails &&
      property.ownershipDetails.squareFootage !== null
        ? property.ownershipDetails.squareFootage
        : '',
    stories:
      property.ownershipDetails &&
      property.ownershipDetails.noOfStories !== null
        ? property.ownershipDetails.noOfStories
        : '',
    hasRenovations: property.ownershipDetails
      ? property.ownershipDetails.renovations
        ? 'true'
        : 'false'
      : '',
    renovationDetails: property.ownershipDetails
      ? property.ownershipDetails.renovationDetails
      : '',
    requiredCoverage:
      property.rentalDetails &&
      property.rentalDetails.contentsCoverage !== null
        ? property.rentalDetails.contentsCoverage
        : '',
    requiredLiability:
      property.rentalDetails &&
      property.rentalDetails.liabilityCoverage !== null
        ? property.rentalDetails.liabilityCoverage
        : '',
    scheduledItems: property.scheduledItems && property.scheduledItems.length > 0
      ? property.scheduledItems
      : [{ item: '', replacementValue: '' }],
    scheduledItemsInfo: property.scheduledItemsInfo ? property.scheduledItemsInfo : '',
    primeMemberId: currentUser ? currentUser.member : '',
    propertyId: property._id ? property._id : '',
  }),
  validationSchema: propertySchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    {
      props: {
        startPropertySave,
        startPropertyUpdate,
        startPropertyInQuoteSave,
        startPropertyInQuoteUpdate,
        togglePropertyForm,
        setIsSubmitted,
        property,
        propertyData,
        combinedData,
        setCombinedFormData,
        setPropertyFormData,
        setShowPropertyInfo,
        togglePropertyInfoEdit,
      },
      resetForm,
      setSubmitting,
    }
  ) => {
    const history = createBrowserHistory();
    const path = history.location.pathname.replace(/\//g, '');
    const propertyP = 'household-properties-information';
    const propertyQP = 'household-property-quotes';
    const combinedQP = 'household-combined-quotes';
    
    setSubmitting(true);

    if (path === propertyP) {
      if (property && property._id) {
        startPropertyUpdate(property._id, values);
      } else {
        startPropertySave(values);
      }
    } else {
      if (property && property._id) {
        startPropertyInQuoteUpdate(property._id, values);
      } else {
        startPropertyInQuoteSave(values);
      }
    }

    resetForm();
    setSubmitting(false);
    setIsSubmitted(true);
    togglePropertyForm(false);
    togglePropertyInfoEdit(false);

    if (setShowPropertyInfo) {
      setShowPropertyInfo(false);
    }

    if (path === propertyQP) {
      if (propertyData) {
        propertyData.property = `ownership: ${values.ownershipType}, address: ${values.streetAddress}, ${values.city}, ${values.state}, ${values.zipCode}, id: ${property._id}`
        setPropertyFormData(propertyData);
      }
    }
    if (path === combinedQP) {
      if (combinedData) {
        combinedData.property = `ownership: ${values.ownershipType}, address: ${values.streetAddress}, ${values.city}, ${values.state}, ${values.zipCode}, id: ${property._id}`
        setCombinedFormData(combinedData);
      }
    }
  },
})(PropertyQuote);

const mapStateToProps = createStructuredSelector({
  states: selectStates,
  reset: selectReset,
  property: selectPropertyById,
  currentUser: selectUser,
  isSubmitted: selectIsSubmitted,
  propertyData: selectPropertyFormData,
  combinedData: selectCombinedFormData,
  primeMember: selectPrimeMember,
  showPropertyForm: selectShowPropertyForm,
  showPropertyInfoEdit: selectShowPropertyInfoEdit,
});

export default withRouter(
  connect(mapStateToProps, {
    setPropertyFormData,
    setIsSubmitted,
    togglePropertyInfoEdit,
    togglePropertyForm,
    startLoadingStatesFromDB,
    startPropertySave,
    startPropertyUpdate,
    setCombinedFormData,
    startPropertyInQuoteSave,
    startPropertyInQuoteUpdate,
    startFetchingPrimeMemberFromDB,
  })(FormikPropertyQuote)
);
