import { ErrorMessage, Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  clearErrMessage,
  startAccountActivation,
} from '../../redux/auth/auth.actions';
import { selectDisplayMessage } from '../../redux/auth/auth.selectors';
import { toggleLoginRegistrationView } from '../../redux/navigation/navigation.actions';
import {
  selectGoToAgentShowcase,
  selectGoToMemberShowcase,
} from '../../redux/navigation/navigation.selectors';
import { ACCOUNT_ACTIVATION_CMS_DATA } from './account.activation.cms';
import activationSchema from './account.activation.validation.schema';

const AccountActivation = ({
  values,
  handleChange,
  handleSubmit,
  displayMessage,
  goToMemberShowcase,
  gotToAgentShowcase,
  history,
  clearErrMessage,
}) => {
  const { token, text, button } = ACCOUNT_ACTIVATION_CMS_DATA;

  useEffect(() => {
    if (goToMemberShowcase) {
      history.push('/');
      setTimeout(() => {
        window.location.reload();
      }, 500)
    }
  }, [goToMemberShowcase, history]);
  
  useEffect(() => {
    if (gotToAgentShowcase) {
      history.push('/agent-home');
      setTimeout(() => {
        window.location.reload();
      }, 500)
    }

  }, [gotToAgentShowcase, history]);

  return (
    <Container className='ie-form-scaffold'>
      <h1 className='display-4 text-center'>{text.header}</h1>
      <br />
      <Row>
        <Col lg={2}></Col>
        <Col lg={8}>
          <p>{text.verify}</p>
          <br />
          <p>{text.check}</p>
        </Col>
        <Col lg={2}></Col>
      </Row>
      <Row>
        <Col lg='4'></Col>
        <Col lg='4'>
          <br />
          <br />
          <br />
          <FormikForm className='rounded'>
            <Form.Group controlId='verifyEmail'>
              <Form.Control
                type='text'
                name='accountActivationToken'
                placeholder={token.placeholder}
                value={values.accountActivationToken}
                onChange={handleChange}
                onClick={() => clearErrMessage()}
              />
              <ErrorMessage
                name='accountActivationToken'
                component='div'
                className='error_message'
              ></ErrorMessage>
              {displayMessage ? (
                <div className='error_message'>{displayMessage}</div>
              ) : null}
            </Form.Group>
          </FormikForm>
          <Button
            variant='success'
            className='float-right'
            onClick={() => handleSubmit()}
          >
            {button.text}
          </Button>
        </Col>
        <Col lg='4'></Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  displayMessage: selectDisplayMessage,
  goToMemberShowcase: selectGoToMemberShowcase,
  gotToAgentShowcase: selectGoToAgentShowcase,
});

//use HOC withFormik() to create
//a formik component wrapping the AccountActivation component
const FormikAccountActivation = withFormik({
  mapPropsToValues: () => ({
    accountActivationToken: '',
  }),
  validationSchema: activationSchema,
  handleSubmit: (
    { accountActivationToken },
    { props: { startAccountActivation }, resetForm, setSubmitting }
  ) => {
    startAccountActivation({ accountActivationToken });
    resetForm();
    setSubmitting(false);
  },
})(AccountActivation);

export default connect(mapStateToProps, {
  startAccountActivation,
  toggleLoginRegistrationView,
  clearErrMessage,
})(FormikAccountActivation);
