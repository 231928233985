import { AGENT_VEHICLE_QUOTE_CMS_DATA }
  from '../../../pages/agent-quotes/vehicle/vehicle-quote.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import agentVehicleQuoteActionTypes from './vehicle.types';

const payload = {
  context: 'agentVehicleQuote',
  status: '',
  error: '',
}

export const setClearVehicleForm = (clear) => ({
  type: agentVehicleQuoteActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const setIsAgent = (agent) => ({
  type: agentVehicleQuoteActionTypes.SET_IS_AGENT,
  payload: agent
});

export const startFetchingAgentVehicleQuoteFromDB = (id) => ({
  type: agentVehicleQuoteActionTypes.FETCH_AGENT_VEHICLE_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAgentVehicleQuoteFromDBSuccess = (policy) => ({
  type: agentVehicleQuoteActionTypes.FETCH_AGENT_VEHICLE_QUOTE_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchAgentVehicleQuoteFromDBFailure = (e) => ({
  type: agentVehicleQuoteActionTypes.FETCH_AGENT_VEHICLE_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: agentVehicleQuoteActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: agentVehicleQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: agentVehicleQuoteActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: AGENT_VEHICLE_QUOTE_CMS_DATA.errMsg.states,
});

export const startAgentVehicleQuoteSave = (data) => ({
  type: agentVehicleQuoteActionTypes.START_AGENT_VEHICLE_QUOTE_SAVE,
  payload: data
});

export const agentVehicleQuoteSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_QUOTE_SUCCESS,
  },
});

export const agentVehicleQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentVehicleQuoteUpdate = (id, formData) => ({
  type: agentVehicleQuoteActionTypes.START_AGENT_VEHICLE_QUOTE_UPDATE,
  payload: { id, formData }
});

export const agentVehicleQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_UPDATE_QUOTE_SUCCESS,
  },
});

export const agentVehicleQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentVehicleQuoteDelete = (id, pId) => ({
  type: agentVehicleQuoteActionTypes.START_AGENT_VEHICLE_QUOTE_DELETE,
  payload: { id, pId },
});

export const agentVehicleQuoteDeleteSuccess = () => ({
  type: agentVehicleQuoteActionTypes.AGENT_VEHICLE_QUOTE_DELETE_SUCCESS,
});

export const agentVehicleQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});