export const VEHICLE_CMS_DATA = {
  heading: 'My Vehicle',
  subHeading:
    'For vehicle quotes. Note that the more detailed and accurate the information you provide, the better our Agents can place you with the appropriate Carrier and more accurate quotes.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel',
    },
    vehicle: {
      addVehicle: {
        title: 'My Vehicles',
        description: 'Please select Add a vehicle to add a vehicle to get quotes for. To view or edit a vehicle already entered, please select the Vehicle Make, Model, and VIN.',
        label: 'My vehicles',
        dropdown: {
          addVehicle: [
            { value: 'addVehicle', label: 'Add a vehicle' },
          ]
        }
      },
      specs: {
        title: 'Specifications',
        description:
          "We try to keep an extensive list of Vehicles but if it's not populating, feel free to add it. We have Agents that can quote any type of Vehicle, whether it's a Car, Truck, Motorcycle, Industrial Equipment, Watercraft, etc. However, horses should be with Companion Animals ;).",
        label: {
          vin: 'VIN',
          year: 'Year',
          make: 'Make',
          model: 'Model',
          subModel: 'Sub-model',
        },
      },
      info: {
        title: 'Information',
        description:
          "Tell a bit more about the Vehicle so we can try to save you money. It's good to get Vehicle insurance quotes every six months, which is easy to do on this site.",
        label: {
          annualMileage: 'Annual mileage',
          additionalInformation:
            "Please share any pertinent information",
          ownership: 'Ownership',
          primaryUse: 'Use(s)',
          parking: 'Night parking',
          dropdown: {
            ownership: [
              { value: 'own', key: 'Owned' },
              { value: 'finance', key: 'Financed' },
              { value: 'lease', key: 'Leased' },
            ],
            primaryUse: [
              { value: 'business', label: 'Business' },
              { value: 'commute', label: 'Commute' },
              { value: 'farm', label: 'Farm' },
              { value: 'freight', label: 'Freight' },
              { value: 'industrial', label: 'Industrial or heavy equipment' },
              { value: 'recreation', label: 'Recreation' },
              { value: 'watercraft', label: 'Watercraft' },
            ],
            parking: [
              { value: 'privateGarage', label: 'Private garage' },
              { value: 'driveway', label: 'Driveway' },
              { value: 'parkingGarage', label: 'Parking garage' },
              { value: 'parkingLot', label: 'Parking lot' },
              { value: 'carPort', label: 'Car port' },
              { value: 'street', label: 'Street' },
              { value: 'notAtHome', label: 'Not parked at home' },
            ],
          },
        },
      },
    },
  },
  errMsg: {
    makes:
      'There was an error loading vehicle makes from database. Please try again later',
    models:
      'There was an error loading models from database. Please try again later',
  },
  modal: {
    heading: 'Caution! Removing this Vehicle might delete associated Policies. Consider updating the Form. Do you want to continue deleting',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    specs: {
      content: {
        vin: "This is the most important part of this section, please make sure this number matches your Vehicle.",
      }
    },
    information: {
      content: {
        addInfo: "Please share information that you think would give you a discount, like anti-theft features, where it's parked at night if not at home, aftermarket modifications, etc.",
      }
    },
  }
};

