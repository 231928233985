import { VEHICLE_CMS_DATA } from '../../../pages/quotes/vehicle/vehicle.cms';

export const convertVehicleForDBSave = (data) => {
  const {
    form: {
      vehicle: {
        info: {
          label: {
            dropdown: { ownership },
          },
        },
      },
    },
  } = VEHICLE_CMS_DATA;

  const payload = {
    usage: data.primaryUse ? data.primaryUse : [],
    parking: data.parking ? data.parking : [],
    specs: {
      vin: data.vin,
      year: data.year,
      make: data.make,
      model: data.model,
      subModel: data.subModel,
    },
    ownership: {
      own: data.ownership === ownership[0].value,
      finance: data.ownership === ownership[1].value,
      lease: data.ownership === ownership[2].value,
    },
    annualMileage: data.annualMileage ? data.annualMileage : '',
    additionalInfo: data.additionalInformation ? data.additionalInformation : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  };

  return payload;
}