import * as yup from 'yup';
import { LOGIN_CMS_DATA } from './login.cms';

const { email, password } = LOGIN_CMS_DATA;

const loginSchema = yup.object().shape({
  email: yup.string().required(email.required),
  password: yup.string().required(password.required)
});

export default loginSchema;
