const memberCompanionAnimalPolicyActionTypes = {
  LOAD_STATES_FROM_DB_START: 'LOAD_STATES_FORM_DB_START',
  LOAD_STATES_FROM_DB_SUCCESS: 'LOAD_STATES_FROM_DB_SUCCESS',
  LOAD_STATES_FROM_DB_FAILURE: 'LOAD_STATES_FROM_DB_FAILURE',
  START_COMPANION_ANIMAL_POLICY_SAVE: 'START_COMPANION_ANIMAL_POLICY_SAVE',
  FETCH_ANIMALS_FROM_DB_START: 'FETCH_ANIMALS_FROM_DB_START',
  FETCH_ANIMALS_FROM_DB_SUCCESS: 'FETCH_ANIMALS_FROM_DB_SUCCESS',
  FETCH_ANIMALS_FROM_DB_FAILURE: 'FETCH_ANIMALS_FROM_DB_FAILURE',
  FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_START: 'FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_START',
  FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_SUCCESS: 'FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_SUCCESS',
  FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_FAILURE: 'FETCH_COMPANION_ANIMAL_POLICY_FROM_DB_FAILURE',
  START_COMPANION_ANIMAL_POLICY_UPDATE: 'START_COMPANION_ANIMAL_POLICY_UPDATE',
  START_COMPANION_POLICY_DELETE: 'START_COMPANION_POLICY_DELETE',
  COMPANION_POLICY_DELETE_SUCCESS: 'COMPANION_POLICY_DELETE_SUCCESS',
  SET_CLEAR_FORM: 'SET_CLEAR_FORM',
  RESET_FORM: 'RESET_FORM',
}

export default memberCompanionAnimalPolicyActionTypes;