import { MEMBER_VEHICLE_POLICY_CMS_DATA } 
  from '../../../pages/policies/vehicle/vehicle-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import memberVehiclePolicyActionTypes 
  from './vehicle.types';

const payload = {
  context: 'memberVehiclePolicy',
  status: '',
  error: ''
}

export const selectMake = (selectedMake) => (dispatch) => {
  dispatch({
    type: memberVehiclePolicyActionTypes.SELECT_MAKE,
    payload: selectedMake[0],
  });
};

export const selectModel = (selectedModel) => (dispatch) => {
  dispatch({
    type: memberVehiclePolicyActionTypes.SELECT_MODEL,
    payload: selectedModel[0],
  });
};

export const setClearForm = (clear) => ({
  type: memberVehiclePolicyActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const startLoadingVehicleMakeFromDB = () => ({
  type: memberVehiclePolicyActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_START_POLICY,
});

export const loadVehicleMakeFromDBSuccess = (data) => ({
  type: memberVehiclePolicyActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS_POLICY,
  payload: data,
});

export const loadVehicleMakeFromDBFailure = (e) => ({
  type: memberVehiclePolicyActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_FAILURE_POLICY,
  payload: MEMBER_VEHICLE_POLICY_CMS_DATA.errMsg.makes,
});

export const startLoadingVehicleModelsFromDB = (selectedMake) => ({
  type: memberVehiclePolicyActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_START,
  payload: selectedMake,
});

export const loadVehicleModelsFromDBSuccess = (data) => ({
  type: memberVehiclePolicyActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS,
  payload: data,
});

export const loadVehicleModelsFromDBFailure = (e) => ({
  type: memberVehiclePolicyActionTypes.loadVehicleModelsFromDBFailure,
  payload: MEMBER_VEHICLE_POLICY_CMS_DATA.errMsg.models,
});

export const startFetchingVehiclesFromDB = () => ({
  type: memberVehiclePolicyActionTypes.FETCH_VEHICLES_FROM_DB_START,
});

export const fetchVehiclesFromDBSuccess = (storedVehicles) => ({
  type: memberVehiclePolicyActionTypes.FETCH_VEHICLES_FROM_DB_SUCCESS,
  payload: storedVehicles,
});

export const fetchVehiclesFromDBFailure = (e) => ({
  type: memberVehiclePolicyActionTypes.FETCH_VEHICLES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingVehiclePolicyFromDB = (id) => ({
  type: memberVehiclePolicyActionTypes.FETCH_VEHICLE_POLICY_FROM_DB_START,
  payload: id,
});

export const fetchVehiclePolicyFromDBSuccess = (vehiclePolicy) => ({
  type: memberVehiclePolicyActionTypes.FETCH_VEHICLE_POLICY_FROM_DB_SUCCESS,
  payload: vehiclePolicy,
});

export const fetchVehiclePolicyFromDBFailure = (e) => ({
  type: memberVehiclePolicyActionTypes.FETCH_VEHICLE_POLICY_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: memberVehiclePolicyActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: memberVehiclePolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: memberVehiclePolicyActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_VEHICLE_POLICY_CMS_DATA.errMsg.states,
});

export const startVehiclePolicySave = (memberVehiclePolicy) => ({
  type: memberVehiclePolicyActionTypes.START_VEHICLE_POLICY_SAVE,
  payload: memberVehiclePolicy
});

export const vehiclePolicySaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const vehiclePolicySaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehiclePolicyUpdate = (id, formData) => ({
  type: memberVehiclePolicyActionTypes.START_VEHICLE_POLICY_UPDATE,
  payload: { id, formData }
});

export const vehiclePolicyUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const vehiclePolicyUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehiclePolicyDelete = (id, PId) => ({
  type: memberVehiclePolicyActionTypes.START_VEHICLE_POLICY_DELETE,
  payload: { id, PId },
});

export const vehiclePolicyDeleteSuccess = () => ({
  type: memberVehiclePolicyActionTypes.VEHICLE_POLICY_DELETE_SUCCESS,
});

export const vehiclePolicyDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});
