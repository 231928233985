export const FILTER_AGENT_QUOTES_CMS_DATA = {
  subHeading: {
    bid: 'Bidding',
    animal: 'Animal Quote Requests',
    life: 'Life Quote Requests',
    health: 'Medical Quote Requests',
    annuity: 'Annuity Quote Requests',
    property: 'Property Quote Requests',
    vehicle: 'Vehicle Quote Requests',
    combined: 'Combined Property and Vehicle Quote Requests',
  },
  quoteTypes: {
    all: 'Select all',
    deAll: 'Deselect all',
    animals: 'Animal',
    hideAnimals: 'Hide animals',
    lifes: 'Life',
    hideLifes: 'Hide life',
    healths: 'Medical',
    hideHealths: 'Hide medical',
    annuities: 'Annuity',
    hideAnnu: 'Hide annuities',
    properties: 'Property',
    hideProp: 'Hide property',
    vehicles: 'Vehicle',
    hideVeh: 'Hide vehicle',
    combines: 'Combined',
    hideComb: 'Hide combined',
  }
}