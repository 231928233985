export const LOGIN_CMS_DATA = {
  email: {
    label: 'Email',
    placeholder: 'Enter Email Address',
    required: 'Email is a required field'
  },
  password: {
    label: 'Password',
    placeholder: 'Enter Password',
    required: 'Password is a required field'
  },
  label: {
    register: "Don't have an account?",
    member: "We see that you're a Member; would you like to register as an agent?"
  },
  action: {
    forgotPassword: 'Forgot Password?',
    login: 'Login',
    register: 'Register Now'
  },
  errObj: {
    invalid: 'Invalid Email or Password.'
  },
  modal: {
    heading: {
      member: 'Oops! You are on the the Agent landing page but you are registered as a Member only. '
        + 'If you want to be re-directed to the Member login, please select Member. However, '
        + 'if you have an insurance license and would like to register as an Agent, please select Agent'
        + 'Registration. Note that by doing so you agree to the Agent\'s Terms of Service and Privacy'
        + 'Policy, which can be accessed at the bottom of each Agent page.',
      intInAgent: "If you have an insurance license and would like to continue registering as an Agent, "
        + "please select Agent Registration. However, if you want to be re-directed to the Member login, "
        + "please select Member.",
    },
    action: {
      member: 'Member',
      agent: 'Agent Registration',
    }
  },
};
