import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  companionAnimalSaveSuccess,
  companionAnimalSaveFailure,
  companionAnimalUpdateSuccess,
  companionAnimalUpdateFailure,
  companionAnimalDeleteSuccess,
  companionAnimalDeleteFailure,
  companionAnimalInQuoteSaveSuccess,
  companionAnimalInQuoteSaveFailure,
  companionAnimalInQuoteUpdateSuccess,
  companionAnimalInQuoteUpdateFailure,
  fetchAllAnimalsFromDBSuccess,
  fetchAllAnimalsFromDBFailure,
  fetchAnimalFromDBSuccess,
  fetchAnimalFromDBFailure,
} from "./companion-animal.actions";
import companionAnimalActionTypes from "./companion-animal.types";
import { convertCompanionAnimalForDBSave } from "./companion-animal.utils";

function* saveCompanionAnimal({ payload: formData }) {
  try {
    const data = yield call(convertCompanionAnimalForDBSave, formData);
    yield axios.post("/api/companion-animals", data, configureAxios());
    yield put(companionAnimalSaveSuccess());
  } catch (e) {
    yield put(companionAnimalSaveFailure(e));
  }
}

function* updateCompanionAnimal({ payload: { id, formData } }) {
  try {
    const data = yield call(convertCompanionAnimalForDBSave, formData);
    const response = yield axios.patch(
      `/api/companion-animals/${id}`,
      data,
      configureAxios()
    );
    const animal = yield JSON.parse(response.config.data);
    yield put(companionAnimalUpdateSuccess(animal));
  } catch (e) {
    yield put(companionAnimalUpdateFailure(e));
  }
}

function* deleteAnimal({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/companion-animals/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(companionAnimalDeleteSuccess());
  } catch (e) {
    yield put(companionAnimalDeleteFailure(e));
  }
}

function* saveCompanionAnimalInQuote({ payload: formData }) {
  try {
    const data = yield call(convertCompanionAnimalForDBSave, formData);
    yield axios.post("/api/companion-animals", data, configureAxios());
    yield put(companionAnimalInQuoteSaveSuccess());
  } catch (e) {
    yield put(companionAnimalInQuoteSaveFailure(e));
  }
}

function* updateCompanionAnimalInQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertCompanionAnimalForDBSave, formData);
    const response = yield axios.patch(
      `/api/companion-animals/${id}`,
      data,
      configureAxios()
    );
    const animal = yield JSON.parse(response.config.data);
    yield put(companionAnimalInQuoteUpdateSuccess(animal));
  } catch (e) {
    yield put(companionAnimalInQuoteUpdateFailure(e));
  }
}

function* fetchAllAnimalsFromDB() {
  try {
    const response = yield axios.get(`/api/companion-animals`);
    const animals = yield response.data;
    yield put(fetchAllAnimalsFromDBSuccess(animals));
  } catch (e) {
    yield put(fetchAllAnimalsFromDBFailure(e));
  }
}

function* fetchAnimalFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/companion-animals/${id}`);
    const animal = yield response.data;
    yield put(fetchAnimalFromDBSuccess(animal));
  } catch (e) {
    yield put(fetchAnimalFromDBFailure(e));
  }
}

function* onCompanionAnimalSaveStart() {
  yield takeEvery(
    companionAnimalActionTypes.START_COMPANION_ANIMAL_SAVE,
    saveCompanionAnimal
  );
}

function* onCompanionAnimalUpdateStart() {
  yield takeEvery(
    companionAnimalActionTypes.START_COMPANION_ANIMAL_UPDATE,
    updateCompanionAnimal
  );
}

function* onCompanionAnimalDeleteStart() {
  yield takeEvery(
    companionAnimalActionTypes.START_COMPANION_ANIMAL_DELETE,
    deleteAnimal
  );
}

function* onCompanionAnimalInQuoteSaveStart() {
  yield takeEvery(
    companionAnimalActionTypes.START_COMPANION_ANIMAL_IN_QUOTE_SAVE,
    saveCompanionAnimalInQuote
  );
}

function* onCompanionAnimalInQuoteUpdateStart() {
  yield takeEvery(
    companionAnimalActionTypes.START_COMPANION_ANIMAL_IN_QUOTE_UPDATE,
    updateCompanionAnimalInQuote
  );
}

function* onFetchAnimalsFromDB() {
  yield takeEvery(
    companionAnimalActionTypes.FETCH_ALL_ANIMALS_FROM_DB_START,
    fetchAllAnimalsFromDB
  );
}

function* onFetchAnimalFromDB() {
  yield takeEvery(
    companionAnimalActionTypes.FETCH_ANIMAL_FROM_DB_START,
    fetchAnimalFromDB
  );
}

export function* companionAnimalSagas() {
  yield all([
    call(onCompanionAnimalSaveStart),
    call(onCompanionAnimalUpdateStart),
    call(onCompanionAnimalDeleteStart),
    call(onCompanionAnimalInQuoteSaveStart),
    call(onCompanionAnimalInQuoteUpdateStart),
    call(onFetchAnimalsFromDB),
    call(onFetchAnimalFromDB),
  ]);
}
