import * as yup from 'yup';
import { ACCOUNT_ACTIVATION_CMS_DATA } from './account.activation.cms';

const { token } = ACCOUNT_ACTIVATION_CMS_DATA;

const activationSchema = yup.object().shape({
  accountActivationToken: yup.string().required(token.required)
});

export default activationSchema;
