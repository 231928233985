import { all, call } from 'redux-saga/effects';
import { agentSagas } from './agent/agent.sagas';
import { agentBiddingSagas } from './agent-biddings/agent-bidding.sagas';
import { propertySagas } from './quotes/property/property.sagas';
import { memberSagas } from './member/member.sagas';
import { vehicleSagas } from './quotes/vehicle/vehicle.sagas';
import { healthSagas } from './quotes/health/health.sagas';
import { authSagas } from './auth/auth.sagas';
import { profileSagas } from './profile/profile.sagas';
import { companionAnimalSagas } from './quotes/companion-animal/companion-animal.sagas';
import { memberHistorySagas } from './quotes/member-history/member-history.sagas';
import { memberCompanionAnimalPolicySagas }
  from './policies/companion-animal/companion-animal.sagas';
import { memberVehiclePolicySagas }
  from './policies/vehicle/vehicle.sagas';
import { memberPropertyPolicySagas }
  from './policies/property/property.sagas';
import { memberAnnuityPolicySagas }
  from './policies/annuity/annuity.sagas';
import { memberLifePolicySagas }
  from './policies/life/life.sagas';
import { memberMedicalPolicySagas }
  from './policies/medical/medical.sagas';
import { animalQuoteSagas }
  from './requested-quotes/companion-animal/companion-animal.sagas';
import { propertyQuoteSagas }
  from './requested-quotes/property/property.sagas';
import { vehicleQuoteSagas }
  from './requested-quotes/vehicle/vehicle.sagas';
import { combineCoverageQuoteSagas }
  from './requested-quotes/combine-coverages/combine-coverages.sagas';
import { healthQuoteSagas }
  from './requested-quotes/health/health.sagas';
import { lifeQuoteSagas }
  from './requested-quotes/life/life.sagas';
import { annuityQuoteSagas }
  from './requested-quotes/annuity/annuity.sagas';
import { memberDocumentSagas }
  from './policies/document/document.sagas';
import { agentAnnuityQuoteSagas }
  from './agent-quotes/annuity/annuity.sagas';
import { agentAnimalQuoteSagas }
  from './agent-quotes/companion-animal/companion-animal.sagas';
import { agentLifeQuoteSagas }
  from './agent-quotes/life/life.sagas';
import { agentMedicalQuoteSagas }
  from './agent-quotes/medical/medical.sagas';
import { agentPropertyQuoteSagas }
  from './agent-quotes/property/property.sagas';
import { agentVehicleQuoteSagas }
  from './agent-quotes/vehicle/vehicle.sagas';
import { annuityBiddingSagas }
  from './agent-biddings/annuity/annuity-bidding.sagas';
import { animalBiddingSagas }
  from './agent-biddings/animal/animal-bidding.sagas';
import { propertyBiddingSagas }
  from './agent-biddings/property/property-bidding.sagas';
import { vehicleBiddingSagas }
  from './agent-biddings/vehicle/vehicle-bidding.sagas';
import { combineBiddingSagas }
  from './agent-biddings/combine/combine-bidding.sagas';
import { lifeBiddingSagas }
  from './agent-biddings/life/life-bidding.sagas';
import { healthBiddingSagas }
  from './agent-biddings/health/health-bidding.sagas';
  
export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(companionAnimalSagas),
    call(agentSagas),
    call(agentBiddingSagas),
    call(profileSagas),
    call(propertySagas),
    call(memberSagas),
    call(healthSagas),
    call(memberHistorySagas),
    call(vehicleSagas),
    call(memberCompanionAnimalPolicySagas),
    call(memberVehiclePolicySagas),
    call(memberPropertyPolicySagas),
    call(memberAnnuityPolicySagas),
    call(memberLifePolicySagas),
    call(memberMedicalPolicySagas),
    call(animalQuoteSagas),
    call(propertyQuoteSagas),
    call(vehicleQuoteSagas),
    call(combineCoverageQuoteSagas),
    call(healthQuoteSagas),
    call(lifeQuoteSagas),
    call(annuityQuoteSagas),
    call(memberDocumentSagas),
    call(agentAnnuityQuoteSagas),
    call(agentAnimalQuoteSagas),
    call(agentLifeQuoteSagas),
    call(agentMedicalQuoteSagas),
    call(agentPropertyQuoteSagas),
    call(agentVehicleQuoteSagas),
    call(annuityBiddingSagas),
    call(animalBiddingSagas),
    call(propertyBiddingSagas),
    call(vehicleBiddingSagas),
    call(combineBiddingSagas),
    call(lifeBiddingSagas),
    call(healthBiddingSagas),
  ]);
}
