import { Field } from 'formik';
import React, { createRef, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import { selectAgent, selectStates } from '../../redux/agent/agent.selectors';
import {
  selectPrimeMember,
} from '../../redux/member/member.selectors';
import {
  setFormData,
} from '../../redux/agent/agent.actions';
import {
  selectFormData,
} from '../../redux/agent/agent.selectors';

const Contact = ({
  values,
  handleChange,
  touched,
  errors,
  states,
  reset,
}) => {
  const {
    form: {
      contactInfo: { title, description, label },
    },
  } = AGENT_CMS_DATA;

  const stateRef = createRef();

  useEffect(() => {
    reset && stateRef.current.clear();
  }, [reset, stateRef]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={7} controlId='email'>
              <Form.Label>{label.email}</Form.Label>
              <Form.Control
                name='email'
                readOnly={true}
                value={values.email}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId='street'>
              <Form.Label>{label.street}</Form.Label>
              <Form.Control
                name='BStreet'
                value={values.BStreet}
                onChange={handleChange}
                isValid={touched.BStreet && !errors.BStreet}
                isInvalid={!!errors.BStreet}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.BStreet}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={4} controlId='city'>
              <Form.Label>{label.city}</Form.Label>
              <Form.Control
                name='BCity'
                value={values.BCity}
                onChange={handleChange}
                isValid={touched.BCity && !errors.BCity}
                isInvalid={!!errors.BCity}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.BCity}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4}>
              <Form.Label>{label.state}</Form.Label>
              <Field name='BState'>
                {({ form, field }) => (
                  <Typeahead
                    id='state-select-agent-contact'
                    ref={stateRef}
                    name='BState'
                    options={states}
                    selected={[values.BState]}
                    isValid={touched.BState && !errors.BState}
                    isInvalid={!!errors.BState}
                    onChange={(selectedState) => {
                      if (selectedState.length) {
                        form.setFieldValue(
                          field.name,
                          selectedState[0].value
                        );
                      }
                    }}
                    onInputChange={(value) => {
                      form.setFieldValue(field.name, value);
                    }}
                  />
                )}
              </Field>
              <Form.Control.Feedback type='invalid'>
                {errors.state}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId='zip-code'>
              <Form.Label>{label.zipCode}</Form.Label>
              <Form.Control
                name='BZipCode'
                value={values.BZipCode}
                onChange={handleChange}
                isValid={touched.BZipCode && !errors.BZipCode}
                isInvalid={!!errors.BZipCode}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.BZipCode}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  agent: selectAgent,
  states: selectStates,
  primeMember: selectPrimeMember,
  data: selectFormData,
});

export default connect(mapStateToProps, {
  setFormData,
})(Contact);
