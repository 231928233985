import { Field } from 'formik';
import React from 'react';

const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ meta }) => {
      const error = meta.error;
      const touch = meta.touched;
      return touch && error ? error : null;
    }}
  </Field>
);

export default ErrorMessage;
