export const ACCOUNT_ACTIVATION_CMS_DATA = {
  token: {
    placeholder: 'Enter Secret Token',
    required: 'Token field is required'
  },
  text: {
    header: 'Please Enter Your Token',
    verify: `For security purposes, you must
    verify your email before you proceed.`,
    check: `Please check the email you provided for your
    unique, secret token and enter (copy and paste) it below to proceed.
    Once you've entered it correctly, you will be 
    directed to the home page to login.`
  },
  button: {
    text: 'Verify'
  },
  errObj: {
    invalid: `Invalid token. Please re-check your email and token. 
    Select and copy the token provided and paste it into the box here.
    Make sure NOT to copy any additional charactors, including the quotation marks or spaces.`
  }
};
