const APP_ID = process.env.REACT_APP_COMETCHAT_APP_ID;
const AUTH_KEY = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
const WIDGET_ID = process.env.REACT_APP_COMETCHAT_WIDGET_ID;
const REGION = process.env.REACT_APP_COMETCHAT_REGION;

const launchWidget = async () => {
  try {
    await window.CometChatWidget.launch({
      widgetID: WIDGET_ID,
      docked: true,
      alignment: "right",
      roundedCorners: true,
      height: "550px",
      width: "800px",
      defaultID: "658612bb85ac9cb687332553",
      defaultType: "user",
    });
  } catch (e) {
    console.error("Chat Widget creation failed due to: ", e);
  }
};

const initChat = async () => {
  try {
    if (window.CometChatWidget) {
      await window.CometChatWidget.init({
        appID: APP_ID,
        appRegion: REGION,
        authKey: AUTH_KEY,
      });
      await launchWidget();
    }
  } catch (e) {
    console.error("Chat initialization failed due to ", e);
  }
};

const createChatUser = async (id, firstName) => {
  try {
    const user = new window.CometChatWidget.CometChat.User(id);
    user.setName(firstName);
    await window.CometChatWidget.createOrUpdateUser(user);
    await loginToChat(id);
  } catch (e) {
    console.error("Chat Account creation failed due to: ", e);
  }
};

const loginToChat = async (id, firstName) => {
  try {
    await window.CometChatWidget.login({ uid: id });
    await launchWidget();
  } catch (e) {
    await createChatUser(id, firstName);
  }
};

const logoutChat = async () => {
  try {
    await window.CometChatWidget.logout();
  } catch (e) {
    console.error(e);
  }
};

export { initChat, loginToChat, createChatUser, launchWidget, logoutChat };
