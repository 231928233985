import { createSelector } from 'reselect';

const selectHealthQuote = (state) => state.healthQuote

export const selectCurrentStep = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.currentStep
);

export const selectHealthFormData = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.formData
);

export const selectQuoteId = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.quoteId
);

export const selectCurrentCount = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.count
);

export const selectMembersInfo = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.membersInfo
);

export const selectHealthQuotesFromDB = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.healthQuotesFromDB
);

export const selectHealthQuoteFromDB = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.healthQuoteFromDB
);

export const selectHealthInfos = createSelector(
  [selectHealthQuote],
  (healthQuote) => healthQuote.healthInfos
);






