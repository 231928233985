export const AGENT_CMS_DATA = {
  heading: 'Agent Information',
  subHeading:
    'All forms must be completed (except fields labeled as optional) and your identity and licenses must be verified and active to gain access to the Agent portal.',
  form: {
    button: {
      update: 'Update',
      next: 'Next',
      previous: 'Previous',
      verify: 'Verify',
      submit: 'Submit',
    },
    personalDetails: {
      title: 'Personal Information',
      description:
        'We will use this, your ID, and License Details to verify your identity and that your license(s) are current.',
      label: {
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        dateOfBirth: 'Date of birth',
        agencyName: 'Agency name',
      },
    },
    veriffInfo: {
      title: 'Identity Verification',
      description: 'This step is to verify your identity, to make sure you are who you claim to be. We use Veriff and their biometric verification process. Please follow directions or you will be declined and will not recieve a refund.',
      label: "Please prepare your driver's license, passport, or state ID to verify your identity. Enter your name as it appears on your ID. If you do not follow directions correctly, you will be declined and will need to contact us to be verified, which will take longer.",
      error: 'To verify your identity, please complete all steps accurately, as directed, and make sure that your document matches your identity',
      blocked: 'You have been blocked. Both attempts have been declined. Contact us directly if you think you reached this decision in error',
      success: 'Press Next',
    },
    agentVerif: {
      title: 'Agent Verification',
      description: 'Agent verification description',
      label: 'Agent label',
      error: 'The NPN number or Social Security Number entered do not match your identity. Please go to the previous step to correct any information.',
      statusOk: 'Your NPN matches your identity! Please proceed to the next step!'
    },
    table: {
      agentsList: {
        id: 'Id',
        email: 'Email',
        name: 'Name',
        license: 'License',
        prop: 'Property',
        veh: 'Vehicle',
        comb: 'Combined',
        ani: 'Animal',
        ann: 'Annuity',
        life: 'Life',
        health: 'Health',
        cap: 'Captives',
      },
    },
    contactInfo: {
      title: 'Contact Information',
      titleTwl: 'Phone Verification',
      descriptionTwl: 'Please enter your phone number to retreive a verification code that will then need to be entered here. By entering your phone number, you agree to receive text notifications from InsurEasier.',
      description:
        'This will be used to autofil contact information for any Member that you write a policy for and the rare case that we might need to contact you.',
      label: {
        email: 'Email',
        phoneNumber: 'Enter your phone number',
        code: 'Please enter the verification code sent to your phone',
        valid: 'Your phone number is verified! Please click Next to continue.',
        invCode: 'Invalid code. Please try again.',
        invCodeFv: 'You entered an invalid code more than five times.',
        invPhone: 'Too many requests.',
        lgOut: 'Please log out, wait 10 minutes, and try again.',
        street: 'Street address',
        city: 'City',
        zipCode: 'Zip code',
        state: 'State',
      },
    },
    paymentInfo: {
      title: 'Payment',
      description: 'We are waiving the monthly payments of $25/mo while in beta testing (a fee that will ensue in the future) but we do need to charge a non-refundable $10 verification fee. We use real time biometrics to verify you and we make sure your license is current.',
      label: 'Please enter your payment information. Note that the payment method will be stored for any bids and future monthly payments so please keep it up-to-date or you will not be able to continue bidding. We are not charging a monthly fee yet because we are still in the beta testing stage but we do ask that you check on bids daily so that we can grow and offer you more real time prospects. It might be slow at first but with your help this site will grow quickly. If you are not using the site often, we reserve the right to remove your account and you would need to pay the verification fee again to re-register. Thank you for joining!',
      winBid: 'Win! Pay Fee',
      agentFee: 'Please pay fee!'
    },
    identification: {
      title: 'Identification Details',
      description:
        'Make sure that this information belongs to you and that it is accurate. There are huge fines, not to mention legal action, for entering false information (refer to the Terms & Conditions and Privacy Policy in this Agent portal, below).',
      label: {
        documentType: 'Document type',
        documentNumber: 'Document number',
        documentIssueState: 'Issued state',
        expiration: 'Expiration',
        dropdown: {
          documentTypes: [
            { value: 'driversLicense', label: "Driver's license" },
            { value: 'stateID', label: 'State ID' },
            { value: 'passport', label: 'Passport' },
          ],
        },
      },
    },
    license: {
      title: 'License Details',
      description:
        'We will be using this information to verify that your license(s) are active. If you have licenses in other states, feel free to add those so as to be able to offer quotes in those states as well.',
      label: {
        states: 'License state',
        number: 'License number',
        nationalProducerNumber: 'National Producer Number',
        ssn: 'Last 4 Digits of SSN',
      },
    },
    carriers: {
      title: 'Carrier Details',
      description:
        'Please specify whether you are a captive Agent or not. Note that if you are captive, you will only be able to enter 3 Carriers but unless you are truly an Independent entering "No" will hurt your chances of entering a bid. If you\'re an Independent Agent, please don\'t hold back and enter all Carriers that you can write with. If a Carrier is not listed, please contact us, giving the name of the Carrier along with their website and other contact information for us to add.',
      label: {
        isCaptive: 'Are you captive?',
        carriers: 'Select Carrier',
        dropdown: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
      },
    },
    policies: {
      title: 'Policies',
      description:
        'Please select every type of policy that you are able to write with the Carriers that you have specified. This will determine what policies will populate for you to bid on.',
      pAndC: {
        label: 'Property & Casualty',
        dropdown: [
          { value: 'property', label: 'Property' },
          { value: 'auto', label: 'Auto' },
          { value: 'pet', label: 'Pet' },
        ],
      },
      lAndH: {
        label: 'Life, Accident, Health & HMO',
        dropdown: [
          { value: 'medical', label: 'Medical' },
          { value: 'medicare', label: 'Medicare/Medigap' },
          { value: 'life', label: 'Life' },
          { value: 'annuity', label: 'Annuity' },
          { value: 'supplemental', label: 'Supplemental' },
        ],
      },
      supplemental: {
        label: 'Supplemental',
        dropdown: [
          { value: 'disability', label: 'Disability' },
          { value: 'accident', label: 'Accident' },
          { value: 'cancer', label: 'Cancer' },
          { value: 'critical', label: 'Critical' },
          { value: 'hospital', label: 'Hospital' },
          { value: 'dental', label: 'Dental' },
          { value: 'vision', label: 'Vision' },
        ],
      },
    },
    servicesProvidedTo: {
      title: 'Specific Insured Group(s)',
      description:
        'Please verify if you can write policies for anyone, by selecting "Anyone;" if are only able to insure military, including veterans and immediate family members, by selecting "Military;" or if are only able to insure specified occupations, by selecting "Specific occupations." You can select more than one. If you can only write for "Specific occupations" make sure to enter all occupations, from the list, that you are able to write for. If the occupation is not on the list, please contact us to have it added so that it can be added to the Member list as well.',
      additionalInfo: 'Additional information for specific optional groups',
      occupation: 'Occupation',
      dropdown: [
        { value: 'anyOne', label: 'Anyone' },
        { value: 'military', label: 'Military' },
        { value: 'specificOccupations', label: 'Specific occupations' },
        { value: 'militaryOccupations', label: 'Military and Specific occupations' },
      ],
    },
    paymentConfirmation: {
      title: 'I agree to pay',
      dropdown: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ],
    },
    credits: [
      {
        id: 0,
        value: '100',
        controlId: 'one-hundred',
        label: '100',
        dropdown: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        id: 1,
        value: '200',
        controlId: 'two-hundred',
        label: '200',
        dropdown: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        id: 2,
        value: '300',
        controlId: 'three-hundred',
        label: '300',
        dropdown: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        id: 3,
        value: '400',
        controlId: 'three-hundred',
        label: '400',
        dropdown: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        id: 4,
        value: '500',
        controlId: 'four-hundred',
        label: '500',
        dropdown: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
    ],
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
    occupations:
      'There was an error loading occupations from database. Please try again later',
  },
};