import healthQuoteActionTypes from "./health.types";
import { ModalTypes } from "../../modal/modal.types";
import authActionTypes from "../../auth/auth.types";

let INITIAL_STATE = {
  currentStep: 0,
  formData: {},
  quoteId: "",
  healthQuotesFromDB: [],
  healthQuoteFromDB: {},
  healthInfos: {},
  membersInfo: [],
  count: 0,
  reset: false,
};

const PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.healthQuote) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.healthQuote);
}

const healthQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case healthQuoteActionTypes.HEALTH_QUOTE_SAVE_SUCCESS:
    case healthQuoteActionTypes.HEALTH_QUOTE_UPDATE_SUCCESS:
    case healthQuoteActionTypes.HEALTH_INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case healthQuoteActionTypes.HEALTH_DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case healthQuoteActionTypes.SET_HEALTH_QUOTE_ID:
      return {
        ...state,
        quoteId: action.payload,
      };
    case healthQuoteActionTypes.SET_HEALTH_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case healthQuoteActionTypes.SET_HEALTH_INFOS:
      return {
        ...state,
        healthInfos: action.payload,
      };
    case healthQuoteActionTypes.SET_MEMBERS_INFO:
      return {
        ...state,
        membersInfo: action.payload,
      };
    case healthQuoteActionTypes.SET_HEALTH_INCREMENT_COUNT:
      return {
        ...state,
        count: state.count + 1,
      };
    case healthQuoteActionTypes.SET_HEALTH_DECREMENT_COUNT:
      return {
        ...state,
        count: state.count - 1,
      };
    case healthQuoteActionTypes.FETCH_HEALTH_QUOTES_FROM_DB_SUCCESS:
      return {
        ...state,
        healthQuotesFromDB: action.payload,
      };
    case healthQuoteActionTypes.FETCH_HEALTH_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        healthQuoteFromDB: action.payload,
      };
    case ModalTypes.OPEN_MODAL:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        quoteId: "",
        healthQuotesFromDB: [],
        healthQuoteFromDB: {},
        healthInfos: {},
        membersInfo: [],
        count: 0,
        reset: false,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        quoteId: "",
        healthQuotesFromDB: [],
        healthQuoteFromDB: {},
        healthInfos: {},
        membersInfo: [],
        count: 0,
        reset: false,
      };
    case healthQuoteActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default healthQuoteReducer;
