import React from 'react';
import { Field } from 'formik';
import { ToggleButton } from 'react-bootstrap';
import './radio-button.style.css';

const RadioButton = ({
  idx,
  name,
  option,
}) => {
  const path = window.location.pathname.replace(/\//g, '');
  let isAgent = false;
  if (path.includes('agent')) {
    isAgent = true;
  }

  return (
    <Field name={name}>
      {
        ({ field }) => {
          return (
            <ToggleButton
              disabled={
                isAgent && field.value !== option.value
                  ? true
                  : false
              }
              id={option.value}
              type='radio'
              className={
                field.value === option.value 
                  ? 'mr-3 px-3 rounded border-success text-light zindex'
                  : isAgent 
                    ? 'mr-3 px-3 rounded border-secondary text-dark cursor zindex'
                    : 'mr-3 px-3 rounded border-secondary text-dark rd-btn zindex'
              }
              variant={'outline-success'}
              {...field}
              value={option.value}
              checked={field.value === option.value}
            >
              {option.key}
            </ToggleButton>
          )
        }
      }
    </Field>
  )
}

export default RadioButton;
