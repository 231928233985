import React, {useState} from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FILTER_AGENT_QUOTES_CMS_DATA } from '../agent-bidding/cms-data/filter.cms';
import {
  setShowAllQuotes,
  setShowAnimalQuotes,
  setShowLifeQuotes,
  setShowHealthQuotes,
  setShowAnnuityQuotes,
  setShowPropertyQuotes,
  setShowVehicleQuotes,
  setShowCombineQuotes,
} from '../../redux/agent-biddings/agent-bidding.actions';
import {
  selectShowAllQuotes,
  selectShowAnimalQuotes,
  selectShowLifeQuotes,
  selectShowHealthQuotes,
  selectShowAnnuityQuotes,
  selectShowPropertyQuotes,
  selectShowVehicleQuotes,
  selectShowCombineQuotes,
} from '../../redux/agent-biddings/agent-bidding.selectors';
import BidPledgingModal from '../../components/bid-pledging/bid-pledging-modal.component';

const FilterListGroup = ({
  agent,
  requestedQuotes,
  showAllQuotes,
  showAnimalQuotes,
  showLifeQuotes,
  showHealthQuotes,
  showAnnuityQuotes,
  showPropertyQuotes,
  showVehicleQuotes,
  showCombineQuotes,
  setShowAllQuotes,
  setShowAnimalQuotes,
  setShowLifeQuotes,
  setShowHealthQuotes,
  setShowAnnuityQuotes,
  setShowPropertyQuotes,
  setShowVehicleQuotes,
  setShowCombineQuotes,
}) => {
  const {
    quoteTypes: {
      all, deAll, animals, lifes, healths, annuities, properties, vehicles, combines,
    }
  } = FILTER_AGENT_QUOTES_CMS_DATA;

  const [showBidPledging, setShowBidPledging] = useState(false);

  const screenW = window.innerWidth;

  const selectAll = () => {
    if (showAllQuotes === true) {
      setShowPropertyQuotes(true);
      setShowVehicleQuotes(true);
      setShowCombineQuotes(true);
      setShowLifeQuotes(true);
      setShowHealthQuotes(true);
      setShowAnnuityQuotes(true);
      setShowAnimalQuotes(true);
    }
    if (showAllQuotes === false) {
      setShowPropertyQuotes(false);
      setShowVehicleQuotes(false);
      setShowCombineQuotes(false);
      setShowLifeQuotes(false);
      setShowHealthQuotes(false);
      setShowAnnuityQuotes(false);
      setShowAnimalQuotes(false);
    }
  }

  return (
    <div className='mb-4'> 
      {agent ? (
        <div className='d-flex w-100 justify-content-between'>
          <p className='text-left'>
            <Link
              to={window.location.pathname}
              onClick={() => setShowBidPledging(!showBidPledging)}
            >
              Bid amount table
            </Link>
          </p>
          <p className='text-right text-success'>
            <strong>Click to show or hide each policy type</strong>
          </p>
        </div>
      ) : (
        <p className='text-right text-success'>
          <strong>Click to show or hide each policy type</strong>
        </p>
      )}

      <ListGroup
        horizontal={screenW < 992 ? false : true}
        breakpoint='sm'
        className='text-center'
      >
        <ListGroup.Item
          action
          onClick={() => {
            setShowAllQuotes(!showAllQuotes);
            selectAll();
          }}
        >
          {
            showAllQuotes ? all : deAll
          }
        </ListGroup.Item>
        {requestedQuotes &&
          requestedQuotes.vehicleQuotes &&
          requestedQuotes.vehicleQuotes.length !== 0 ? (
          <ListGroup.Item
            action
            variant={showVehicleQuotes ? 'success' : ''}
            onClick={() => setShowVehicleQuotes(!showVehicleQuotes)}
          >
            {vehicles}
          </ListGroup.Item>
        ) : null}
        {requestedQuotes &&
          requestedQuotes.propertyQuotes &&
          requestedQuotes.propertyQuotes.length !== 0 ? (
          <ListGroup.Item
            action
            variant={showPropertyQuotes ? 'success' : ''}
            onClick={() => setShowPropertyQuotes(!showPropertyQuotes)}
          >
            {properties}
          </ListGroup.Item>
        ) : null}
        {requestedQuotes &&
          requestedQuotes.combineCoverageQuotes &&
          requestedQuotes.combineCoverageQuotes.length !== 0 ? (
          <ListGroup.Item
            action
            variant={showCombineQuotes ? 'success' : ''}
            onClick={() => setShowCombineQuotes(!showCombineQuotes)}
          >
            {combines}
          </ListGroup.Item>
        ) : null}
        {requestedQuotes &&
          requestedQuotes.animalQuotes &&
          requestedQuotes.animalQuotes.length !== 0 ? (
          <ListGroup.Item
            action
            variant={showAnimalQuotes ? 'success' : ''}
            onClick={() => setShowAnimalQuotes(!showAnimalQuotes)}
          >
            {animals}
          </ListGroup.Item>
        ) : null}
        {requestedQuotes &&
          requestedQuotes.annuityQuotes &&
          requestedQuotes.annuityQuotes.length !== 0 ? (
          <ListGroup.Item
            action
            variant={showAnnuityQuotes ? 'success' : ''}
            onClick={() => setShowAnnuityQuotes(!showAnnuityQuotes)}
          >
            {annuities}
          </ListGroup.Item>
        ) : null}
        {requestedQuotes &&
          requestedQuotes.lifeQuotes &&
          requestedQuotes.lifeQuotes.length !== 0 ? (
          <ListGroup.Item
            action
            variant={showLifeQuotes ? 'success' : ''}
            onClick={() => setShowLifeQuotes(!showLifeQuotes)}
          >
            {lifes}
          </ListGroup.Item>
        ) : null}
        {requestedQuotes &&
          requestedQuotes.healthQuotes &&
          requestedQuotes.healthQuotes.length !== 0 ? (
          <ListGroup.Item
            action
            variant={showHealthQuotes ? 'success' : ''}
            onClick={() => setShowHealthQuotes(!showHealthQuotes)}
          >
            {healths}
          </ListGroup.Item>
        ) : null}
      </ListGroup>
      {showBidPledging ? (
        <BidPledgingModal 
          showBidPledging={showBidPledging}
          setShowBidPledging={setShowBidPledging}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  showAllQuotes: selectShowAllQuotes,
  showAnimalQuotes: selectShowAnimalQuotes,
  showLifeQuotes: selectShowLifeQuotes,
  showHealthQuotes: selectShowHealthQuotes,
  showAnnuityQuotes: selectShowAnnuityQuotes,
  showPropertyQuotes: selectShowPropertyQuotes,
  showVehicleQuotes: selectShowVehicleQuotes,
  showCombineQuotes: selectShowCombineQuotes,
});

export default connect(mapStateToProps, {
  setShowAllQuotes,
  setShowAnimalQuotes,
  setShowLifeQuotes,
  setShowHealthQuotes,
  setShowAnnuityQuotes,
  setShowPropertyQuotes,
  setShowVehicleQuotes,
  setShowCombineQuotes,
})(FilterListGroup);
