import { ACCOUNT_ACTIVATION_CMS_DATA } from '../../components/account-activation/account.activation.cms';
import { FORGOT_PASSWORD_CMS_DATA } from '../../components/forgot-password/forgot-password.cms';
import { LOGIN_CMS_DATA } from '../../components/login/login.cms';
import { REGISTRATION_CMS_DATA } from '../../components/registration/registration.cms';
import agentActionTypes from '../agent/agent.types';
import authActionTypes from '../auth/auth.types';
import navigationActionTypes from '../navigation/navigation.types';

export const clearErrMessage = () => ({
  type: authActionTypes.CLEAR_ERROR_MESSAGE,
});

export const setIsSubmitted = (submit) => ({
  type: authActionTypes.SET_IS_SUBMITTED,
  payload: submit,
});

export const setIsRefresh = (toggle) => ({
  type: authActionTypes.SET_IS_REFRESH,
  payload: toggle,
});

export const startRegistration = (user) => ({
  type: authActionTypes.START_REGISTRATION,
  payload: user,
});

export const registrationSuccess = (user) => ({
  type: authActionTypes.REGISTRATION_SUCCESS,
  payload: user,
});

export const registrationFailure = () => ({
  type: authActionTypes.REGISTRATION_FAILURE,
  payload: REGISTRATION_CMS_DATA.errObj.accountExists,
});

export const startAccountActivation = (accountActivationToken) => ({
  type: authActionTypes.START_ACCOUNT_ACTIVATION,
  payload: accountActivationToken,
});

export const accountActivationSuccess = (user) => ({
  type: authActionTypes.ACCOUNT_ACTIVATION_SUCCESS,
  payload: user,
});

export const accountActivationFailure = () => ({
  type: authActionTypes.ACCOUNT_ACTIVATION_FAILURE,
  payload: ACCOUNT_ACTIVATION_CMS_DATA.errObj.invalid,
});

export const startLogin = (values, isInitiatedFromAgentShowcase) => ({
  type: authActionTypes.START_LOGIN,
  payload: { values, isInitiatedFromAgentShowcase },
});

export const loginSuccess = (user, token) => ({
  type: authActionTypes.LOGIN_SUCCESS,
  payload: { user, token },
});

export const loginFailure = () => ({
  type: authActionTypes.LOGIN_FAILURE,
  payload: LOGIN_CMS_DATA.errObj.invalid,
});

export const startForgotPassword = (values) => ({
  type: authActionTypes.START_FORGOT_PASSWORD,
  payload: values,
});

export const forgotPasswordSuccess = () => ({
  type: authActionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: FORGOT_PASSWORD_CMS_DATA.errObj.account.exists,
});

export const forgotPasswordFailure = () => ({
  type: authActionTypes.FORGOT_PASSWORD_FAILURE,
  payload: FORGOT_PASSWORD_CMS_DATA.errObj.account.missing,
});

export const startResetPassword = (user) => ({
  type: authActionTypes.START_RESET_PASSWORD,
  payload: user,
});

export const resetPasswordSuccess = (user) => ({
  type: authActionTypes.RESET_PASSWORD_SUCCESS,
  payload: user,
});

export const resetPasswordFailure = (e) => ({
  type: authActionTypes.RESET_PASSWORD_FAILURE,
  payload: e.response.data.error.email,
});

export const logout = () => (dispatch) => {
  dispatch({
    type: authActionTypes.LOGOUT,
  });

  dispatch({
    type: navigationActionTypes.SHOW_ACCOUNT_ACTIVATION_VIEW,
    payload: false,
  });

  dispatch({
    type: navigationActionTypes.SHOW_MEMBER_SHOWCASE_VIEW,
    payload: false,
  });

  dispatch({
    type: navigationActionTypes.SHOW_AGENT_SHOWCASE_VIEW,
    payload: false,
  });

  dispatch({
    type: navigationActionTypes.SHOW_MEMBER_DASHBOARD_VIEW,
    payload: false,
  });

  dispatch({
    type: navigationActionTypes.SHOW_AGENT_DASHBOARD_VIEW,
    payload: false,
  });

  dispatch({
    type: agentActionTypes.RESET_AGENT,
  });
};

export const startFetchingUsersFromDB = () => ({
  type: authActionTypes.FETCH_USERS_FROM_DB_START,
});

export const fetchUsersFromDBSuccess = (members) => ({
  type: authActionTypes.FETCH_USERS_FROM_DB_SUCCESS,
  payload: members,
});

export const fetchUsersFromDBFailure = (e) => ({
  type: authActionTypes.FETCH_USERS_FROM_DB_FAILURE,
  payload: e,
});
