import React from 'react';

const passwordRequirements = ({
  data: { title, firstRule, secondRule, thirdRule, fourthRule },
}) => {
  return (
    <ul className='font_size password_requirements text-dark'>
      {title}
      <li>{firstRule}</li>
      <li>{secondRule}</li>
      <li>{thirdRule}</li>
      <li>{fourthRule}</li>
    </ul>
  );
};

export default passwordRequirements;
