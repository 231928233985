import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  vehicleBiddingSaveSuccess,
  vehicleBiddingSaveFailure,
  vehicleBiddingUpdateSuccess,
  vehicleBiddingUpdateFailure,
} from "./vehicle-bidding.actions";
import vehicleBiddingActionTypes from "./vehicle-bidding.types";
import { convertVehicleBiddingForDBSave } from "./vehicle-bidding.utils";

function* saveVehicleBidding({ payload: formData }) {
  try {
    const data = yield call(convertVehicleBiddingForDBSave, formData);
    yield axios.post("/api/vehicle-bidding", data, configureAxios());
    yield put(vehicleBiddingSaveSuccess());
  } catch (e) {
    yield put(vehicleBiddingSaveFailure(e));
  }
}

function* updateVehicleBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertVehicleBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/vehicle-bidding/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(vehicleBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(vehicleBiddingUpdateFailure(e));
  }
}

function* onSaveVehicleBiddingStart() {
  yield takeEvery(
    vehicleBiddingActionTypes.START_VEHICLE_BIDDING_SAVE,
    saveVehicleBidding
  );
}

function* onUpdateVehicleBiddingStart() {
  yield takeEvery(
    vehicleBiddingActionTypes.START_VEHICLE_BIDDING_UPDATE,
    updateVehicleBidding
  );
}

export function* vehicleBiddingSagas() {
  yield all([
    call(onSaveVehicleBiddingStart),
    call(onUpdateVehicleBiddingStart),
  ]);
}
