const lifeBiddingActionTypes = {
  SET_SHOW_LIFE_QUOTES: 'SET_SHOW_LIFE_QUOTES',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_LIFE_BID: 'SET_IS_LIFE_BID',
  SET_IS_LIFE_SELECTED_BY_AI: 'SET_IS_LIFE_SELECTED_BY_AI',
  START_LIFE_BIDDING_SAVE: 'START_LIFE_BIDDING_SAVE',
  LIFE_BIDDING_SAVE_SUCCESS: 'LIFE_BIDDING_SAVE_SUCCESS',
  START_LIFE_BIDDING_UPDATE: 'START_LIFE_BIDDING_UPDATE',
  LIFE_BIDDING_UPDATE_SUCCESS: 'LIFE_BIDDING_UPDATE_SUCCESS',
  RESET_FORM: 'RESET_FORM',
};

export default lifeBiddingActionTypes;
