import { ModalState, ModalTypes } from '../../modal/modal.types';
import vehicleBiddingActionTypes from './vehicle-bidding.types';

const payload = {
  context: 'vehicleBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: vehicleBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsVehicleBid = (toggle) => ({
  type: vehicleBiddingActionTypes.SET_IS_VEHICLE_BID,
  payload: toggle,
});

export const setShowVehicleQuotes = (toggle) => ({
  type: vehicleBiddingActionTypes.SET_SHOW_VEHICLE_QUOTES,
  payload: toggle,
});

export const startVehicleBiddingSave = (values) => ({
  type: vehicleBiddingActionTypes.START_VEHICLE_BIDDING_SAVE,
  payload: values,
});

export const vehicleBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const vehicleBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startVehicleBiddingUpdate = (id, formData) => ({
  type: vehicleBiddingActionTypes.START_VEHICLE_BIDDING_UPDATE,
  payload: { id, formData },
});

export const vehicleBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const vehicleBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

