import * as yup from 'yup';

const agentInfoSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .required('Please enter first name'),
  middleName: yup
    .string()
    .strict()
    .trim()
    .min(1, 'Please enter at least one characters')
    .max(255, 'Please enter less than 255 characters')
    .optional(),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .required('Please enter last name'),
  dateOfBirth: yup.date().required('Please provide a date of birth'),
  agencyName: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .optional(),
  BStreet: yup
    .string()
    .trim()
    .min(0)
    .max(255, 'Please enter no more than 255 characters')
    .required('Please enter street address'),
  BCity: yup
    .string()
    .trim()
    .min(0)
    .max(255, 'Please enter no more than 255 characters')
    .required('Please add city'),
  BState: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .required('Please select state'),
  BZipCode: yup
    .number()
    .typeError('Please enter valid zip code')
    .min(1001, 'Please enter valid zip code')
    .max(99950, 'Please enter valid zip code')
    .required('Please enter zip code'),
});

export default agentInfoSchema;
