export const LIFE_QUOTE_CMS_DATA = {
  form: {
    button: {
      next: 'Next',
      previous: 'Previous',
      submit: 'Submit',
      getQuotes: 'Get quotes',
    },
    life: {
      title: {
        lifeTypes: 'Life Insurance Types',
        member: 'Member Selection',
        health: 'Update Member Health',
      },
      description: {
        lifeTypes: 'Please select the type of life insurance you are wanting and answer the corresponding inquiries that will populate. Note that there\'s space below for any concerns, in case you want to be more specific or are not sure, etc.',
        member: `Please select the Household Member to be associated with this quote. Also, please use "Update existing" if any changes have occured to the Member being included. If you have not entered them yet, please "Add another."`,
        health: 'For each Household Member getting insurance, a Health form will need to be filled out or updated.',
      },
      label: {
        lifeTypes: "Policy type",
        member: 'Select member',
        health: 'Update members health information',
        yearsTerm: 'Term length',
        FVTerm: `Term face value (approximate)`,
        FVWhole: `Whole Life's face value (approximate)`,
        uniInv: 'How would you like to invest?',
        FVUni: `Universal Life's face value? (approximate)`,
        DPUni: `Down payment (initial investment, if any)`,
        varInv: 'Would you like to be involved in what investments are made?',
        addInfo: 'Any other requests or concerns',
        addInfoNS: 'What is it that you are wanting from a Life insurance policy?',
      },
      dropdown: {
        lifeTypes: [
          { value: 'termLife', key: 'Term life' },
          { value: 'wholeLife', key: 'Whole life' },
          { value: 'uniLife', key: 'Universal life' },
          { value: 'notSure', key: 'Not sure' },
        ],
        yearsTerm: [
          { value: '10', key: '10 years' },
          { value: '15', key: '15 years' },
          { value: '20', key: '20 years' },
          { value: '25', key: '25 years' },
          { value: '30+', key: '30+ years' },
        ],
        faceValue: [
          { value: '50,000', key: '$50,000' },
          { value: '100,000', key: '$100,000' },
          { value: '200,000', key: '$200,000' },
          { value: '300,000', key: '$300,000' },
          { value: '500,000', key: '$500,000' },
          { value: '750,000', key: '$750,000' },
          { value: '1,000,000', key: '$1,000,000' },
          { value: '2,000,000', key: '$2,000,000' },
          { value: '3,000,000', key: '$3,000,000' },
          { value: '5,000,000', key: '$5,000,000' },
          { value: '10,000,000', key: '$10,000,000+' },
          { value: 'notSure', key: 'Not sure' },
        ],
        FVWhole: [
          { value: '10,000', key: '$10,000' },
          { value: '15,000', key: '$15,000' },
          { value: '20,000', key: '$20,000' },
          { value: '25,000', key: '$25,000' },
          { value: '30,000+', key: '$30,000+' },
          { value: 'notSure', key: 'Not sure' },
        ],
        uniInv: [
          { value: 'fixed', key: 'Fixed - Fixed rate of interest' },
          { value: 'indexed', key: 'Indexed –Guarantees a minimum rate but also caps the maximum interest' },
          { value: 'variable', key: 'Variable - No guaranteed minimum rate but no limit either' },
          { value: 'notSure', key: 'Not sure' },
        ],
        varInv: [
          { value: 'true', key: 'Yes' },
          { value: 'false', key: 'No' },
        ],
      }
    },
  },
  popOver: {
    insuranceTypes: {
      content: {
        lifeTypes: "What type of policy do you want? Term life only lasts for a certain period of time and is designed to cover loved ones when it comes to paying off things like a mortgage, large loans, tuition, etc. Whole Life is designed to cover funeral costs but it also has a savings account attached that can be borrowed from. Universal Life is a combination of Term and Life (and sometimes an Annuity), from which money can be borrowed but is more of an investment account. Both Whole and Universal Life are perminent policies that stay in force as long as the mimimum premium is paid or until its maturity date (usually around 100 years of age).",
        yearsTerm: "How many years do you want the Term to last? Consider how long it will take to pay off or, at least, significantly reduce any major loans that need to be paid for your family to continue without this Member.",
        FVTerm: "If this Member passed away, how much would be needed to keep the rest of the family financially secure? Add up all major loans, consider future loans, like tuition, and lost salary over so many years, depending on how many years your family would be dependent on that income.",
        FVWhole: "This is the amount for covering the Member's funeral and maybe a little extra for good measure, maybe lost income for time taking care of arrangements, etc. This and how much has already been paid in will also dictate how much can be borrowed. Since this policy will last as long as the premiums are met or until the Member passes away, a higher face value can be requested but will get very expensive - in this case, a Universal Life might make more sense.",
        uniInv: 'This will determine how much risk you want to take with your investment. Fixed is the least risky and least expensive as the interest rate is set and does not fluctuate. An Indexed policy can fluctuate but will not go below or above set interest rates (e.g. some might not go below 3% interest but might be capped at 8% interest). Variable is completely reliant on the market and in which stocks are being invested in (usually S&P market). You have the choice of being involved in those investments or simply letting the Carrier decide.',
        varInv: "Do you want to be involved in what stocks are invested in? Carriers have formulas for what they invest in but you might have some insight yourself that you want to flaunt.",
        FVUni: "If the Member passed away, how much would be needed to keep the rest of the family financially secure? Maybe the Member has a separate Term policy to take care of all major loans, future loans, like tuition, and lost salary over so many years, depending on how many years your family would be dependent on that income, but this could be an additional amount to put them over the top. Remember this policy will keep going until the Member passes away or stops keeping up with minimum premium payments.",
        DPUni: "Remember this is an investment into the Member's and/or their families future. So, a down payment can either reduce the premium amount or generate a much larger ROI (Return On Investment). But, it is completely optional. Agetns will likely want to discuss this with you.",
        addInfo: "Are there any Riders that you would like to attach, like Return of Premium - getting back all of the money paid in premiums after the Term is over and no claim is made; Guaranteed Insurability Rider - allows the insured to add more coverage in the future without needing to go through the entire screening process; etc.? Or, are there any questions or concerns?",
        addInfoNS: "What do you want you and your family to be protected from in case the Member passes away? Are you simply wanting your family to be free from debt or do you want to make an investment that has a face value just in case something bad should happen? Consider things like mortgage and other major expenses, funeral costs, whether you want to invest money, etc.",      }
    }
  }
}
