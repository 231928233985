import * as yup from 'yup';
import { FORGOT_PASSWORD_CMS_DATA } from './forgot-password.cms';

const { email } = FORGOT_PASSWORD_CMS_DATA;

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required(email.required)
    .email(email.invalidEmail)
});

export default forgotPasswordSchema;
