import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DatePicker } from '../date-picker/date-picker.component';
import { selectAgent } from '../../redux/agent/agent.selectors';
import {
  selectPrimeMember,
} from '../../redux/member/member.selectors';
import {
  setFormData,
} from '../../redux/agent/agent.actions';
import {
  selectFormData,
} from '../../redux/agent/agent.selectors';

const Details = ({
  values,
  handleChange,
  touched,
  errors,
}) => {
  const {
    form: {
      personalDetails: { title, description, label },
    },
  } = AGENT_CMS_DATA;
  // 1975-01-29T00:00:00.000+00:00
  const pdtDate =
    values.dateOfBirth && values.dateOfBirth !== ''
      ? new Date(values.dateOfBirth)
      : '';

  // Get the UTC time by adding the offset
  const utcTime = pdtDate
    ? new Date(pdtDate.getTime() + pdtDate.getTimezoneOffset() * 60000)
    : ''; // Adding 7 hours in milliseconds (1 hour = 60 minutes = 60 * 1000 milliseconds)

  // Convert the UTC time to a string in ISO 8601 format (GMT)
  const gmtTime = utcTime ? utcTime.toISOString() : '';

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={4} controlId='first-name'>
              <Form.Label>{label.firstName}</Form.Label>
              <Form.Control
                readOnly={values.firstName !== '' ? true : false}
                name='firstName'
                value={values.firstName}
                onChange={handleChange}
                isValid={touched.firstName && !errors.firstName}
                isInvalid={!!errors.firstName}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId='middle-name' optional='true'>
              <Form.Label>{label.middleName}</Form.Label>
              <Form.Control
                name='middleName'
                value={values.middleName}
                onChange={handleChange}
                isValid={touched.middleName && !errors.middleName}
                isInvalid={!!errors.middleName}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.middleName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId='last-name'>
              <Form.Label>{label.lastName}</Form.Label>
              <Form.Control
                readOnly={values.lastName !== '' ? true : false}
                name='lastName'
                value={values.lastName}
                onChange={handleChange}
                isValid={touched.lastName && !errors.lastName}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={4} controlId='date-of-birth'>
              <Form.Label>{label.dateOfBirth}</Form.Label>
              <DatePicker
                // readOnly={values.dateOfBirth ? true : false}
                name='dateOfBirth'
                value={gmtTime}
                minDate={new Date('01-01-1960')}
                maxDate={new Date()}
                autoComplete='nope'
                touched={touched}
                errors={errors}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.dateOfBirth}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={4} controlId='agency-name' optional='true'>
              <Form.Label>{label.agencyName}</Form.Label>
              <Form.Control
                name='agencyName'
                value={values.agencyName}
                onChange={handleChange}
                isValid={touched.agencyName && !errors.agencyName}
                isInvalid={!!errors.agencyName}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.agencyName}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  agent: selectAgent,
  primeMember: selectPrimeMember,
  data: selectFormData,
});

export default connect(mapStateToProps, {
  setFormData,
})(Details);

