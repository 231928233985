import { FieldArray } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HEALTH_QUOTE_CMS_DATA } from '../../../pages/quotes/health/health.cms';
import Dropdown from '../../dropdown/dropdown.component';
import ErrorMessage from '../../error-message/error-message.component';
import { createStructuredSelector } from 'reselect';
import { selectRelationshipOptions } from '../../../redux/quotes/health/health.selectors';
import InfoIcon from '../../info-icon/info-icon.component';
import {
  selectHealthInfos,
  selectCurrentCount,
} from '../../../redux/requested-quotes/health/health.selectors';
import {
  selectHealthFromDB,
} from '../../../redux/quotes/health/health.selectors';
import {
  selectHealthInfosLife,
} from '../../../redux/requested-quotes/life/life.selectors';

const FamilyHistories = ({
  values,
  errors,
  touched,
  healthInfos,
  healthInfosLife,
  handleChange,
  memberHealth,
  relationshipOptions,
}) => {
  const {
    form: {
      familyHistories: { title, description, label },
    },
    popOver: { familyHistories: { content } }
  } = HEALTH_QUOTE_CMS_DATA;

  const relations = values.relationships;
  const path = window.location.pathname.replace(/\//g, '');
  const lifeQ = 'household-life-quotes';
  const healthQ = 'household-health-quotes';
  const agentBid = 'agent-bids';

  let info;

  if (path === lifeQ) {
    info = healthInfosLife;
  }

  if (path === healthQ) {
    info = healthInfos;
  }

  const filterOptions = (index) => {
    return relationshipOptions.filter(
      ({ value }) =>
        value === relations[index].relationship ||
        value === 's' ||
        !relations.map((h) => h.relationship).includes(value)
    );
  };

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          {relations &&
            relations.length !== 0 ? ( 
          <FieldArray
            name='relationships'
            render={(arrayHelpers) => (
              <>
                <div>
                  {relations.map((r, i) => (
                    <div key={i}>
                      {/* add some space before next section */}
                      <Form.Row>
                        <Form.Group as={Col} lg={6} controlId='relationship'>
                          <Form.Label>{label.relationships}</Form.Label>
                          <InfoIcon
                            className='float-right'
                            content={content.relation}
                          />
                          <Dropdown
                            isDisabled={path === agentBid ? true : false}
                            name={`relationships[${i}].relationship`}
                            selected={relations[i].relationship}
                            options={filterOptions(i)}
                            touched={touched}
                            errors={errors}
                            type='relationships'
                            values={
                              memberHealth && 
                              !memberHealth._id &&
                                info &&
                                info.primeMemberId &&
                                info.relationships[i]
                                  ? info
                                  : values
                            }
                            currentIndex={i}
                          /> 
                          <Form.Control.Feedback type='invalid'>
                            <ErrorMessage
                              name={`relationships[${i}].relationship`}
                            />
                          </Form.Control.Feedback>
                        </Form.Group>
                        {relations[i].relationship !== '' ? (
                          <>
                            <Form.Group as={Col} lg={3} controlId='doa'>
                              <Form.Label>{label.doa}</Form.Label>
                              <Dropdown
                                isDisabled={path === agentBid ? true : false}
                                name={`relationships[${i}].doa`}
                                selected={relations[i].doa}
                                options={label.dropdown.doa}
                                touched={touched}
                                errors={errors}
                                type='relationships'
                                values={
                                  memberHealth && 
                                  !memberHealth._id &&
                                    info &&
                                    info.primeMemberId &&
                                    info.relationships[i]
                                      ? info
                                      : values
                                }
                                currentIndex={i}
                              />
                              <Form.Control.Feedback type='invalid'>
                                <ErrorMessage name={`relationships[${i}].doa`} />
                              </Form.Control.Feedback>
                            </Form.Group>
                            {relations[i].doa !== '' ? (
                              <Form.Group as={Col} lg={3} controlId='age'>
                                <Form.Label>
                                  {relations[i].doa === 'yes'
                                    ? label.age
                                    : label.ageAtDeath
                                  }
                                </Form.Label>
                                <Form.Control
                                  readOnly={path === agentBid ? true : false}
                                  name={`relationships[${i}].age`}
                                  defaultValue={relations[i].age}
                                  onChange={handleChange}
                                  isValid={
                                    touched.relationships &&
                                    !errors.relationships
                                  }
                                  isInvalid={!!errors.relationships}
                                />
                                <Form.Control.Feedback type='invalid'>
                                  <ErrorMessage
                                    name={`relationships[${i}].age`}
                                  />
                                </Form.Control.Feedback>
                              </Form.Group>
                            ) : null}
                          </>
                        ) : null}
                      </Form.Row> 
                      {relations[i].doa !== '' ? (
                        <Form.Row>
                          <Form.Group as={Col} controlId='health-status'>
                            <Form.Label>
                                {relations[i].doa === 'yes'
                                ? label.healthNow
                                : label.healthAtDeath}
                            </Form.Label>
                            <Form.Control
                              readOnly={path === agentBid ? true : false}
                              name={`relationships[${i}].healthDetails`}
                              as='textarea'
                              rows={5}
                              value={relations[i].healthDetails}
                              onChange={handleChange}
                              isValid={
                                touched.healthDetails &&
                                !errors.healthDetails
                              }
                              isInvalid={!!errors.healthDetails}
                              maxLength={255}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                              <ErrorMessage
                                name={`relationships[${i}].healthDetails`}
                              />
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      ) : null}
                      <Form.Row>
                        <Form.Group as={Col} className='text-right'>
                          {i === 0 &&
                            relations.length > 1 &&
                            path !== agentBid ? (
                          <Button
                            variant='outline-danger'
                            onClick={() => {
                              arrayHelpers.remove(i);
                            }}
                          >
                            Remove
                          </Button>
                          ) : null} 
                          {i >= 1 && path !== agentBid ? (
                            <Button
                              className='mr-1'
                              variant='outline-danger'
                              onClick={() => {
                                arrayHelpers.remove(i);
                              }}
                            >
                              Remove
                            </Button>
                          ) : null} 
                          {relations.length - 1 === i &&
                            path !== agentBid ? (
                            <Button
                              className='ml-1'
                              variant='primary'
                              onClick={() => {
                                // add another section
                                if (relations[i].age !== '') {
                                  arrayHelpers.push({
                                    relationship: '',
                                    doa: '',
                                    age: '',
                                    healthDetails: '',
                                  });
                                }
                              }}
                            >
                              Add Another
                            </Button>
                          ) : null}
                        </Form.Group>
                      </Form.Row>
                    </div>
                  ))}
                </div>
              </>
            )}
            ></FieldArray>
          ): null} 
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  relationshipOptions: selectRelationshipOptions,
  healthInfos: selectHealthInfos,
  healthInfosLife: selectHealthInfosLife,
  count: selectCurrentCount,
  memberHealth: selectHealthFromDB,
});

export default connect(mapStateToProps, {})(FamilyHistories);
