import { PROPERTY_CMS_DATA }
  from '../../../components/requested-quotes/property/property.cms';

export const convertPropertyQuoteForDBSave = (data) => {
  const {
    form: {
      property: {
        dropdown: {
          reOrACV, scale, acv, floodEarthquake, deductible,
        }
      },
    }
  } = PROPERTY_CMS_DATA;

  const payload = {
    members: data.members ? data.members : [],
    isLastStep: data.isLastStep ? data.isLastStep : false,
    createdDate: new Date(),
    quoteType: data.quoteType ? data.quoteType : '',
    property: data.property ? data.property : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    membersInfo: data.membersInfo ? data.membersInfo : [],
    propertyInfo: data.propertyInfo ? data.propertyInfo : {},
    primeMemberInfo: data.primeMemberInfo ? data.primeMemberInfo : {},
    isCometChatGr: data.isCometChatGr ? data.isCometChatGr : false,
    isMsgSent: data.isMsgSent ? data.isMsgSent : false,
    selAgentsByAI: data.selAgentsByAI ? data.selAgentsByAI : {},
    createdCometDate: data.createdCometDate ? data.createdCometDate : '',
    isCometExp: data.isCometExp ? data.isCometExp : false,
    isDeleteComet: data.isDeleteComet ? data.isDeleteComet : false,
    propertyCV: {
      ownOrFinance: {
        scaleCV: {
          scale1: {
            isTypeOf:
              scale !== null
                ? data.scale === scale[0].value
                : false,
            acv:
              acv !== null
                ? data.acv === acv[0].value
                : false,
          },
          scale2:
            scale !== null
              ? data.scale === scale[1].value
              : false,
          scale3:
            scale !== null
              ? data.scale === scale[2].value
              : false,
          scale4:
            scale !== null
              ? data.scale === scale[3].value
              : false,
          scale5:
            scale !== null
              ? data.scale === scale[4].value
              : false,
        },
        floodEarthquake: {
          yes:
            floodEarthquake !== null
              ? data.floodEarthquake === floodEarthquake[0].value
              : false,
          no:
            floodEarthquake !== null
              ? data.floodEarthquake === floodEarthquake[1].value
              : false,
          unsure:
            floodEarthquake !== null
              ? data.floodEarthquake === floodEarthquake[2].value
              : false,
        },
        deductible: {
          none:
            deductible !== null
              ? data.deductible === deductible[0].value
              : false,
          500:
            deductible !== null
              ? data.deductible === deductible[1].value
              : false,
          1000:
            deductible !== null
              ? data.deductible === deductible[2].value
              : false,
          1500:
            deductible !== null
              ? data.deductible === deductible[3].value
              : false,
          2000:
            deductible !== null
              ? data.deductible === deductible[4].value
              : false,
          onePercent:
            deductible !== null
              ? data.deductible === deductible[5].value
              : false,
          twoPercent:
            deductible !== null
              ? data.deductible === deductible[6].value
              : false,
          threePercent:
            deductible !== null
              ? data.deductible === deductible[7].value
              : false,
          fourPercent:
            deductible !== null
              ? data.deductible === deductible[8].value
              : false,
          fivePercent:
            deductible !== null
              ? data.deductible === deductible[9].value
              : false,
        },
      },
      reACV: {
        reOrACV: {
          replacement:
            reOrACV !== null
              ? data.reOrACV === reOrACV[0].value
              : false,
          acv:
            reOrACV !== null
              ? data.reOrACV === reOrACV[1].value
              : false,
          unsure:
            reOrACV !== null
              ? data.reOrACV === reOrACV[2].value
              : false,
        },
        additionalInfo: data.additionalInfo ? data.additionalInfo : '',
      },
    },
    combineCV: data.combineCV ? data.combineCV : '',
  };

  return payload;
};








