import { calculateAge } from '../../../redux/member/member.utils';

const memberFormData = (
  data,
  primeMember,
  currentUser,
  isLastStep
) => {
  return (
    {
      householdMembers:
        primeMember.householdMembers && primeMember.type && primeMember.type.self
          ? primeMember.householdMembers
          : [],
      propertyPolicies:
        primeMember.propertyPolicies && primeMember.type && primeMember.type.self
          ? primeMember.propertyPolicies
          : [],
      firstName:
        data.firstName
          ? data.firstName
          : primeMember.details && primeMember.details.firstName 
            ? primeMember.details.firstName
            : '',
      middleName:
        data.middleName
          ? data.middleName
          : primeMember.details && primeMember.details.middleName 
            ? primeMember.details.middleName
            : '',
      lastName:
        data.lastName
          ? data.lastName
          : primeMember.details && primeMember.details.lastName 
            ? primeMember.details.lastName
            : '',
      dateOfBirth:
        data.dateOfBirth
          ? data.dateOfBirth
          : primeMember.details && primeMember.details.dateOfBirth 
            ? new Date(primeMember.details.dateOfBirth)
            : '',
      gender:
        data.gender
          ? data.gender
          : primeMember.details && primeMember.details.male
            ? 'male'
            : primeMember.details && primeMember.details.female
              ? 'female'
              : primeMember.details && primeMember.details.otherGenders
                ? 'other'
                : '',
      memberType:
        data.memberType
          ? data.memberType
          : primeMember.details && primeMember.details.email === currentUser.email
            ? 'self'
            : '',
      email:
        primeMember.details && primeMember.details.email
          ? primeMember.details.email
          : '',
      mobile:
        data.mobile
          ? data.mobile
          : primeMember.details && primeMember.details.mobile 
            ? primeMember.details.mobile
            : '',
      landline:
        data.landline
          ? data.landline
          : primeMember.details && primeMember.details.landline 
            ? primeMember.details.landline
            : '',
      isPhoneValid: 
        data.isPhoneValid
          ? data.isPhoneValid
          : primeMember.details && primeMember.details.isPhoneValid 
            ? primeMember.details.isPhoneValid
            : '',
      street:
        data.street
          ? data.street
          : primeMember.address && primeMember.address.streetAddress
            ? primeMember.address.streetAddress
            : '',
      city:
        data.city
          ? data.city
          : primeMember.address && primeMember.address.city
            ? primeMember.address.city
            : '',
      zipCode:
        data.zipCode
          ? data.zipCode
          : primeMember.address && primeMember.address.zipCode 
            ? primeMember.address.zipCode
            : '',
      state:
        data.state
          ? data.state
          : primeMember.address
            ? primeMember.address.state
            : '',
      documentType:
        data.documentType &&
          calculateAge(data.dateOfBirth) >= 15
          ? data.documentType
          : primeMember.identification &&
            calculateAge(data.dateOfBirth) >= 15
            ? (primeMember.identification.driverPermit && 'driversPermit') ||
              (primeMember.identification.driversLicense && 'driversLicense') ||
              (primeMember.identification.passport && 'passport') ||
              (primeMember.identification.stateID && 'stateID')
            : '',
      documentNumber:
        data.documentNumber
          ? data.documentNumber
          : primeMember.identification &&
            primeMember.identification.number &&
            calculateAge(data.dateOfBirth) >= 15
              ? primeMember.identification.number
              : '',
      expiration:
        data.expiration && calculateAge(data.dateOfBirth) >= 15
          ? data.expiration
          : primeMember.identification &&
            primeMember.identification.expirationDate &&
            calculateAge(data.dateOfBirth) >= 15
              ? primeMember.identification.expirationDate
              : '',
      documentIssueState:
        data.documentIssueState &&
          calculateAge(data.dateOfBirth) >= 15
          ? data.documentIssueState
          : primeMember.identification &&
            primeMember.identification.state &&
            calculateAge(data.dateOfBirth) >= 15
              ? primeMember.identification.state
              : '',
      legalDocumentType:
        data.legalDocumentType
          ? data.legalDocumentType
          : primeMember.legalStatus && primeMember.legalStatus.citizen
            ? 'citizen'
            : primeMember.legalStatus && primeMember.legalStatus.alienRegistration 
              ? 'alienRegistration'
              : primeMember.legalStatus && primeMember.legalStatus.visa 
                ? 'visa'
                : '',
      legalDocumentNumber:
        data.legalDocumentNumber
          ? data.legalDocumentNumber
          : primeMember.legalStatus
            ? primeMember.legalStatus.ssn ||
              primeMember.legalStatus.alienRegistrationNumber ||
              primeMember.legalStatus.uscisNumber
            : '',
      occupations:
        data.occupations
          ? data.occupations
          : primeMember.occupations && primeMember.occupations.length !== 0
            ? primeMember.occupations
            : [{ occupation: '' }],
      annualIncome:
        data.annualIncome
          ? data.annualIncome
          : primeMember.annualIncome && primeMember.annualIncome 
            ? primeMember.annualIncome
            : '',
      militaryServiceType:
        data.militaryServiceType
          ? data.militaryServiceType
          : primeMember.militaryService
            ? (primeMember.militaryService.isDirectlyRelatedTo && 'isDirectlyRelatedTo') ||
              (primeMember.militaryService.isInReserves && 'isInReserves') ||
              (primeMember.militaryService.isServing && 'isServing') ||
              (primeMember.militaryService.isVeteran && 'isVeteran') ||
              (primeMember.militaryService.none && 'none')
            : '',
      businessName:
        data.businessName
          ? data.businessName
          : primeMember.business
            ? primeMember.business.name
            : '',
      businessOwnership:
        data.businessOwnership
          ? data.businessOwnership
          : primeMember.business
            ? primeMember.business.isOwner
              ? 'true'
              : 'false'
            : '',
      businessStreet:
        data.businessStreet
          ? data.businessStreet
          : primeMember.business &&
              primeMember.business.address &&
              primeMember.business.address.streetAddress
              ? primeMember.business.address.streetAddress
              : '',
      businessCity:
        data.businessCity
          ? data.businessCity
          : primeMember.business &&
            primeMember.business.address &&
            primeMember.business.address.city
            ? primeMember.business.address.city
            : '',
      businessZipCode:
        data.businessZipCode
          ? data.businessZipCode
          : primeMember.business &&
            primeMember.business.address &&
            primeMember.business.address.zipCode 
              ? primeMember.business.address.zipCode
              : '',
      businessState:
        data.businessState
          ? data.businessState
          : primeMember.business &&
            primeMember.business.address &&
            primeMember.business.address.state
            ? primeMember.business.address.state
            : '',
      acquisitions:
        data.acquisitions
          ? data.acquisitions
          : primeMember.education &&
            primeMember.education.acquisitions &&
              primeMember.education.acquisitions.length !== 0
              ? primeMember.education.acquisitions
              : [],
      degreeType:
        data.degreeType
          ? data.degreeType
          : primeMember.education && primeMember.education.degreeType 
            ? primeMember.education.degreeType
            : '',
      felonyStatus:
        data.felonyStatus
          ? data.felonyStatus
          : primeMember.felonyStatus &&
            primeMember.felonyStatus.hasBeenConvicted
              ? 'true'
              : primeMember.felonyStatus && 
                !primeMember.felonyStatus.hasBeenConvicted
                ? 'false'
                : '',
      felonyReason:
        data.felonyReason
          ? data.felonyReason
          : primeMember.felonyStatus
            ? primeMember.felonyStatus.reasonForConviction
            : '',
      primeMemberId:
        currentUser ? currentUser.member : '',
      agentId: currentUser ? currentUser.agent : '',
      isMemberLastStep: isLastStep ? isLastStep : '',
    }
  )
}

export default memberFormData;