import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  setClearAnimalForm,
} from '../../../redux/quotes/companion-animal/companion-animal.actions';
import CompanionAnimalQuote
  from '../../../pages/quotes/companion-animal/companion-animal.component';

const AnimalFormModal = ({
  showAnimalInfo,
  setShowAnimalInfo,
  setClearAnimalForm,
}) => {
  
  const handleClose = () => {
    setShowAnimalInfo(false);
    setClearAnimalForm(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Modal
      size='xl'
      show={showAnimalInfo}
      dialogClassName='modal-100w'
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded border-bottom-0 justify-content-end'>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body className='border-top border-grey'>
        <CompanionAnimalQuote setShowAnimalInfo={setShowAnimalInfo}/>
      </Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='btn btn-outline-danger'
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, {
  setClearAnimalForm,
})(AnimalFormModal);
