const healthQuoteActionTypes = {
  FETCH_HEALTH_QUOTES_FROM_DB_START: 'FETCH_HEALTH_QUOTES_FROM_DB_START',
  FETCH_HEALTH_QUOTES_FROM_DB_SUCCESS: 'FETCH_HEALTH_QUOTES_FROM_DB_SUCCESS',
  FETCH_HEALTH_QUOTES_FROM_DB_FAILURE: 'FETCH_HEALTH_QUOTES_FROM_DB_FAILURE',
  FETCH_HEALTH_QUOTE_FROM_DB_START: 'FETCH_HEALTH_QUOTE_FROM_DB_START',
  FETCH_HEALTH_QUOTE_FROM_DB_SUCCESS: 'FETCH_HEALTH_QUOTE_FROM_DB_SUCCESS',
  FETCH_HEALTH_QUOTE_FROM_DB_FAILURE: 'FETCH_HEALTH_QUOTE_FROM_DB_FAILURE',
  START_HEALTH_QUOTE_SAVE: 'START_HEALTH_QUOTE_SAVE',
  HEALTH_QUOTE_SAVE_SUCCESS: 'HEALTH_QUOTE_SAVE_SUCCESS',
  START_HEALTH_QUOTE_UPDATE: 'START_HEALTH_QUOTE_UPDATE',
  HEALTH_QUOTE_UPDATE_SUCCESS: 'HEALTH_QUOTE_UPDATE_SUCCESS',
  START_LAST_STEP_HEALTH_QUOTE_UPDATE: 'START_LAST_STEP_HEALTH_QUOTE_UPDATE',
  HEALTH_DECREMENT_CURRENT_STEP: 'HEALTH_DECREMENT_CURRENT_STEP',
  HEALTH_INCREMENT_CURRENT_STEP: 'HEALTH_INCREMENT_CURRENT_STEP',
  SET_HEALTH_QUOTE_ID: 'SET_HEALTH_QUOTE_ID',
  SET_HEALTH_FORM_DATA: 'SET_HEALTH_FORM_DATA',
  START_CHECKING_CHAT_GROUP_HEALTH: 'START_CHECKING_CHAT_GROUP_HEALTH',
  CHECKING_CHAT_GROUP_SUCCESS_HEALTH: 'CHECKING_CHAT_GROUP_SUCCESS_HEALTH',
  SET_HEALTH_INCREMENT_COUNT: 'SET_HEALTH_INCREMENT_COUNT',
  SET_HEALTH_DECREMENT_COUNT: 'SET_HEALTH_DECREMENT_COUNT',
  SAVE_DELETE_HEALTH_COMET_CHAT: 'SAVE_DELETE_HEALTH_COMET_CHAT',
  SAVE_DELETE_HEALTH_COMET_CHAT_SUCCESS: 'SAVE_DELETE_HEALTH_COMET_CHAT_SUCCESS',
  SET_HEALTH_INFOS: 'SET_HEALTH_INFOS',
  SET_MEMBERS_INFO: 'SET_MEMBERS_INFO',
  RESET_FORM: 'RESET_FORM',
}

export default healthQuoteActionTypes;
