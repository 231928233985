import { Form as FormikForm, withFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { agentData } from './form-data/agent-data';
import {
  setFormData,
  startAgentUpdate,
  startFetchingAgentFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectAgent,
  selectStates,
  selectFormData,
  selectClientToken,
} from '../../redux/agent/agent.selectors';
import { selectUser } from '../../redux/auth/auth.selectors';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import BrowserWarningModal from './browser-warn-modal.component';
import ErrorMessages from '../../components/error-message/error-messages.component';
import PaypalPayment from '../paypal-payment/paypal.component';

const AgentPayment = ({
  values,
  touched,
  errors,
  agent,
  currentUser,
  handleSubmit,
  isSubmitting,
  setFormData,
  startFetchingAgentFromDB,
}) => {
  const formikProps = { values, touched, errors };
  const {
    form: { button, paymentInfo },
  } = AGENT_CMS_DATA;

  const [showBrowserWarn, setShowBrowserWarn] = useState(false);

  useEffect(() => {
    startFetchingAgentFromDB(currentUser.agent);
  }, [
    currentUser,
    startFetchingAgentFromDB,
  ]);

  useEffect(() => {
    if (agent.isFeePaid) {
      values.isFeePaid = true;
    }
  }, [agent.isFeePaid, values.isFeePaid])

  useEffect(() => {
    if (values && agent._id) {
      setFormData(values);
    }
  }, [setFormData, values, agent._id])

  useEffect(() => {
    if (
      agent._id &&
      agent.veriff &&
      agent.veriff.length === 0
    ) {
      let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      if (!isChrome) {
        setShowBrowserWarn(true);
      }
    }
  }, [agent, setShowBrowserWarn])

  return (
    <>
      <section>
        <FormikForm noValidate {...formikProps}>
          <div className='ie-form'>
            <Row>
              <Col lg={4}>
                <h5>{paymentInfo.title}</h5>
                <h6>{paymentInfo.description}</h6>
              </Col>
              <Col lg={8}>
                <Form.Row>
                  <Form.Group as={Col} lg={12} controlId='free'>
                    <Form.Label>{paymentInfo.label}</Form.Label>
                    {agent.isFeePaid ? (
                      <div className='pt-5'>
                        <p className='text-success'>Payment is successful</p>
                      </div>  
                    ) : (
                      <PaypalPayment
                        fee='10.00'
                        quoteId='' 
                        isFeePaid={true}  
                        isQuoteFeePaid={false}
                        quoteType=''
                      />    
                    )}  
                  </Form.Group>
                </Form.Row>
              </Col>
            </Row>
          </div>
          <ErrorMessages errors={errors} />
          <div className='text-right pb-5'>
            {agent.isFeePaid && !showBrowserWarn ? (
              <Button
                disabled={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {button.next}
              </Button>
            ) : null}  
          </div>
        </FormikForm>
      </section>
      {showBrowserWarn ? (
        <BrowserWarningModal
          show={showBrowserWarn}
          setShow={setShowBrowserWarn}
        />
      ) : null}
    </>
  );
};

const FormikAgentPayment = withFormik({
  mapPropsToValues: ({ data, currentUser, agent }) => (
    agentData(agent, data, currentUser, false)
  ),
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: { startAgentUpdate, currentUser, increment }, setSubmitting }
  ) => {
    setSubmitting(true);
    if (currentUser.agent) {
      startAgentUpdate(currentUser.agent, values);
    }
    setSubmitting(false);
    increment();
    window.scrollTo({ top: 0 });
  },
})(AgentPayment);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agent: selectAgent,
  states: selectStates,
  data: selectFormData,
  clientToken: selectClientToken,
});

export default withRouter(
  connect(mapStateToProps, {
    setFormData,
    startAgentUpdate,
    startFetchingAgentFromDB,
  })(FormikAgentPayment)
);