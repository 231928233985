import React from 'react';
import loadable from '@loadable/component';
import { Button, Modal } from 'react-bootstrap';

const BidPledgingTable = loadable(() => import('./bid-pledging-table.component'));

const BidPledgingModal = ({
  showBidPledging,
  setShowBidPledging,
}) => {
  
  const handleClose = () => {
    setShowBidPledging(false);
  }

  return (
    <Modal
      size='xl'
      show={showBidPledging}
      dialogClassName='modal-100w'
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded border-bottom-0 justify-content-end'>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body className='border-top border-grey'>
        <BidPledgingTable />
      </Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='btn btn-outline-danger'
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BidPledgingModal;
