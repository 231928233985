import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import memberDocumentActionTypes from "./document.types";
import {
  documentDeleteSuccess,
  documentDeleteFailure,
  fetchUploadedFileFromDBSuccess,
  fetchUploadedFileFromDBFailure,
  fetchUploadedFilesFromDBSuccess,
  fetchUploadedFilesFromDBFailure,
  fetchUploadedChunksFromDBSuccess,
  fetchUploadedChunksFromDBFailure,
  fetchUploadedChunkFromDBSuccess,
  fetchUploadedChunkFromDBFailure,
} from "./document.actions";

function* fetchUploadedFilesFromDB() {
  try {
    const response = yield axios.get(`/api/upload-document`);
    const files = yield response.data;
    yield put(fetchUploadedFilesFromDBSuccess(files));
  } catch (e) {
    yield put(fetchUploadedFilesFromDBFailure(e));
  }
}

function* fetchUploadedFileFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/upload-document/${id}`);
    const uploadedFile = yield response.data;
    yield put(fetchUploadedFileFromDBSuccess(uploadedFile));
  } catch (e) {
    yield put(fetchUploadedFileFromDBFailure(e));
  }
}

function* fetchUploadedChunksFromDB({ payload: ids }) {
  try {
    const response = axios.get(
      `/api/upload-chunk/`,
      {
        params: { ids: ids },
      },
      configureAxios()
    );
    const uploadedChunks = yield response;
    yield put(fetchUploadedChunksFromDBSuccess(uploadedChunks));
  } catch (e) {
    yield put(fetchUploadedChunksFromDBFailure(e));
  }
}

function* fetchUploadedChunkFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/upload-chunk/${id}`);
    const uploadedChunk = yield response;
    yield put(fetchUploadedChunkFromDBSuccess(uploadedChunk));
  } catch (e) {
    yield put(fetchUploadedChunkFromDBFailure(e));
  }
}

function* deleteDocument({
  payload: { id, policyId, policyType, chunkId, quoteType },
}) {
  try {
    axios.delete(
      `/api/upload-document/${id}`,
      {
        params: {
          fileId: id,
          policyId: policyId,
          policyType: policyType,
          quoteType: quoteType,
          chunkId: chunkId,
        },
      },
      configureAxios()
    );
    yield put(documentDeleteSuccess());
  } catch (e) {
    yield put(documentDeleteFailure(e));
  }
}

function* onFetchUploadedFileFromDB() {
  yield takeEvery(
    memberDocumentActionTypes.FETCH_UPLOADED_FILE_FROM_DB_START,
    fetchUploadedFileFromDB
  );
}

function* onFetchUploadedFilesFromDB() {
  yield takeEvery(
    memberDocumentActionTypes.FETCH_UPLOADED_FILES_FROM_DB_START,
    fetchUploadedFilesFromDB
  );
}

function* onFetchUploadedChunksFromDB() {
  yield takeEvery(
    memberDocumentActionTypes.FETCH_UPLOADED_CHUNKS_FROM_DB_START,
    fetchUploadedChunksFromDB
  );
}

function* onFetchUploadedChunkFromDB() {
  yield takeEvery(
    memberDocumentActionTypes.FETCH_UPLOADED_CHUNK_FROM_DB_START,
    fetchUploadedChunkFromDB
  );
}

function* onDeleteDocument() {
  yield takeEvery(
    memberDocumentActionTypes.START_DELETE_DOCUMENT,
    deleteDocument
  );
}

export function* memberDocumentSagas() {
  yield all([
    call(onDeleteDocument),
    call(onFetchUploadedFileFromDB),
    call(onFetchUploadedFilesFromDB),
    call(onFetchUploadedChunksFromDB),
    call(onFetchUploadedChunkFromDB),
  ]);
}
