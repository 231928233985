import React, { useState, useEffect } from 'react';
import { Button, Table, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../redux/auth/auth.selectors';
import { useHistory } from 'react-router-dom';
import {
  toggleMemberForm,
  toggleMemberTypes,
  setClearMemberForm,
  startMemberDelete,
  setIsUpdated,
  startFetchingMemberFromDB,
  startFetchingPrimeMemberFromDB,
} from '../../redux/member/member.actions';
import {
  selectMember,
  selectPrimeMember,
  selectShowMemberForm,
  selectShowMemberTypes,
} from '../../redux/member/member.selectors';
import {
  selectMemberHistoryById
} from '../../redux/quotes/member-history/member-history.selectors';
import { MEMBER_CMS_DATA } from '../member/member.cms';
import ShowModal from '../../components/modal/show-modal.component';
import MemberFormModal from '../../components/household-members/member-form-modal.component';
import HouseholdMember from './household-member.component';

const EditMembers = ({
  primeMember,
  currentUser,
  showMemberForm,
  setClearMemberForm,
  toggleMemberTypes,
  toggleMemberForm,
  startMemberDelete,
  startFetchingMemberFromDB,
  startFetchingPrimeMemberFromDB,
}) => {
  const {
    heading,
    subHeading,
    modal
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname;
  const householdP = '/household-members-information';

  const [show, setShow] = useState(false);
  const [showMemberInfo, setShowMemberInfo] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [storeName, setStoreName] = useState([]);
  const [showMemberFormOnly, setShowMemberFormOnly] = useState(false);

  const handleDelete = () => {
    startMemberDelete(storeIds[0], storeIds[1], storeIds[2]);
    setClearMemberForm();
    toggleMemberTypes(false);
    toggleMemberForm(false);
    setShow(false);
    window.location.reload();
  }

  const handleCancel = () => {
    if (!showMemberForm) {
      setStoreIds([]);
      setStoreName([]);
    } 
    setShow(false);
  }

  const getHouseholdMembers = (pm) => {
    let allMembers = [];
    if (path !== householdP) {
      allMembers.push(pm);
    } 
    if (pm._id) {
      let hms = pm.householdMembers;
      if (hms && hms.length) {
        for (let hm of hms) {
          if (hm._id) {
            allMembers.push(hm);
          }
        }
      }
    }
    return allMembers;
  }

  const householdMembers = getHouseholdMembers(primeMember);

  const getMemberType = (hm) => {
    if (hm.type) {
      for (let t in hm.type) {
        if (hm.type[t]) {
          return t;
        }
      }
    }
  } 
  
  useEffect(() => {
    startFetchingPrimeMemberFromDB(currentUser.member);
  }, [startFetchingPrimeMemberFromDB, currentUser])

  useEffect(() => {
    if (primeMember &&
      primeMember._id &&
      primeMember.householdMembers
    ) {
      let hms = primeMember.householdMembers;
      if (hms.length === 0) {
        setShowMemberFormOnly(true);
        toggleMemberTypes(true);
      }
      if (hms.length > 0) {
        setShowMemberFormOnly(false);
        toggleMemberTypes(false);
      }
    }
  }, [
    primeMember,
    toggleMemberTypes,
    setShowMemberFormOnly,
  ])

  return (
    <section className='ie-form-scaffold'>
      <Container>
        {!showMemberFormOnly ? (
          <header className='py-4'>
            <h2>{heading.myHousehold}</h2>
            <p>{subHeading.myHousehold}</p>
          </header>
        ): null}
        {showMemberFormOnly ? (
          <HouseholdMember />
        ) : (
          <>
            <div className={
              path === householdP
                ? 'pb-5 mb-4 text-right'
                : 'mb-3 text-right'
            }
            >
              {householdMembers.length > 0 ? (
                <>
                  <Table
                    bordered
                    responsive
                    className='shadow-sm p-3 mb-4 bg-white rounded'
                  >
                    <thead className='bg-light'>
                      <tr>
                        <th style={{ width: '6rem' }} className='text-left'>Relation</th>
                        <th style={{ width: '6rem' }}>First Name</th>
                        <th style={{ width: '7rem' }}>Middle Name</th>
                        <th style={{ width: '6rem' }}>Last Name</th>
                        <th style={{ width: '12rem' }}></th>
                      </tr>
                    </thead>
                    {householdMembers.map((hm, i) => (
                      <tbody key={i}>
                        <tr>
                          <td className='text-left'>
                            <p style={{ width: '6rem' }} className='m-0'>
                              {
                              getMemberType(hm) 
                                ? getMemberType(hm).charAt(0).toUpperCase() + getMemberType(hm).slice(1)
                                : ''
                              }
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '6rem' }} className='m-0 float-right'>
                              {hm.details ? hm.details.firstName : ''}
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '7rem' }} className='m-0 float-right'>
                              {hm.details ? hm.details.middleName : ''}
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '6rem' }} className='m-0 float-right'>
                              {hm.details ? hm.details.lastName : ''}
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '12rem' }} className='m-0 float-right'>
                              <Button
                                className='mr-1'
                                variant='outline-danger'
                                onClick={() => {
                                  setStoreIds([hm._id, currentUser.member, hm.healthId]);
                                  setStoreName([hm.details.firstName, hm.details.middleName, hm.details.lastName])
                                  setShow(true);
                                }}
                              >
                                {modal.action.delete}
                              </Button>
                              <Button
                                className='ml-1'
                                variant={
                                  showMemberForm && (hm._id === storeIds[0]) 
                                    ? 'warning'
                                    : 'primary'
                                }
                                onClick={() => {
                                  if (hm._id) {
                                    startFetchingMemberFromDB(hm._id);
                                  }
                                  toggleMemberForm(true);
                                  setShowMemberInfo(!showMemberInfo);
                                }}
                              >
                                {modal.action.update}                   
                              </Button>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                  <div className='float-left'>
                    <Button
                      variant='primary'
                      onClick={() => {
                        setStoreIds([]);
                        setStoreName([]);
                        toggleMemberTypes(true);
                        setShowMemberInfo(true);
                      }}
                    >
                      {modal.action.add}
                    </Button>
                  </div>
                </>
              ) : null}
            </div>
          </>
        )}
        <MemberFormModal
          showMemberInfo={showMemberInfo}
          setShowMemberInfo={setShowMemberInfo}
        />
        <ShowModal
          show={show}
          heading={`${modal.heading} ${storeName[0]} ${storeName[1]} ${storeName[2]}?`}
          secondary={modal.action.cancel}
          primary={modal.action.delete}
          handleClose={handleCancel}
          handleSecondary={handleCancel}
          handlePrimary={handleDelete}
        />
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  member: selectMember,
  primeMember: selectPrimeMember,
  memberHistoryById: selectMemberHistoryById,
  showMemberForm: selectShowMemberForm,
  showMemberTypes: selectShowMemberTypes,
});

export default connect(mapStateToProps, {
  setIsUpdated,
  startMemberDelete,
  toggleMemberTypes,
  toggleMemberForm,
  setClearMemberForm,
  startFetchingMemberFromDB,
  startFetchingPrimeMemberFromDB,
})(EditMembers);