import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  combineBiddingSaveSuccess,
  combineBiddingSaveFailure,
  combineBiddingUpdateSuccess,
  combineBiddingUpdateFailure,
} from "./combine-bidding.actions";
import combineBiddingActionTypes from "./combine-bidding.types";
import { convertCombineBiddingForDBSave } from "./combine-bidding.utils";

function* saveCombineBidding({ payload: formData }) {
  try {
    const data = yield call(convertCombineBiddingForDBSave, formData);
    yield axios.post("/api/combine-bidding", data, configureAxios());
    yield put(combineBiddingSaveSuccess());
  } catch (e) {
    yield put(combineBiddingSaveFailure(e));
  }
}

function* updateCombineBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertCombineBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/combine-bidding/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(combineBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(combineBiddingUpdateFailure(e));
  }
}

function* onSaveCombineBiddingStart() {
  yield takeEvery(
    combineBiddingActionTypes.START_COMBINE_BIDDING_SAVE,
    saveCombineBidding
  );
}

function* onUpdateCombineBiddingStart() {
  yield takeEvery(
    combineBiddingActionTypes.START_COMBINE_BIDDING_UPDATE,
    updateCombineBidding
  );
}

export function* combineBiddingSagas() {
  yield all([
    call(onSaveCombineBiddingStart),
    call(onUpdateCombineBiddingStart),
  ]);
}
