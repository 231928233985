import * as yup from 'yup';

const FelonyHistorySchema = yup.object().shape({
  felonyStatus: yup.string().trim().required('Please select an option'),
  felonyReason: yup
    .string()
    .trim()
    .min(2, 'Please provide at least 2 characters')
    .max(255, 'Please provide relevant information')
    .optional(),
})

export default FelonyHistorySchema;