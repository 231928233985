import { Form as FormikForm, withFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import MemberFelonyHistory from '../../components/quotes/member-history/member-felony-history.component';
import BusinessDetails from '../../components/member/member-business-details.component';
import Education from '../../components/member/member-education.component';
import Identification from '../../components/member/member-identification.component';
import LegalStatus from '../../components/member/member-legal-status.component';
import PersonalDetails from '../../components/member/member-personal-details.component';
import WorkDetails from '../../components/member/member-work-details.component';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  startFetchingMemberHistoryByIdFromDB
} from '../../redux/quotes/member-history/member-history.actions';
import {
  selectMemberHistoryById
} from '../../redux/quotes/member-history/member-history.selectors';
import {
  startMemberUpdate,
  startLoadingStatesFromDB,
  startLoadingOccupationsFromDB,
  startMemberQuoteUpdate,
  startFetchingPrimeMemberFromDB,
} from '../../redux/member/member.actions';
import {
  selectPrimeMember,
  selectOccupations,
  selectReset,
  selectStates,
} from '../../redux/member/member.selectors';
import { MEMBER_CMS_DATA } from './member.cms';
import MemberSchema from './member.validation.schema';
import { calculateAge } from '../../redux/member/member.utils';
import { useHistory } from 'react-router-dom';
import ErrorMessages from '../../components/error-message/error-messages.component';

import loadable from '@loadable/component';

const ContactInfo = loadable(() => import('../../components/member/member-contact-info.component'));

const Member = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  states,
  occupations,
  currentUser,
  primeMember,
  resetForm,
  reset,
  startFetchingPrimeMemberFromDB,
  startFetchingMemberHistoryByIdFromDB,
  startLoadingStatesFromDB,
  startLoadingOccupationsFromDB,
}) => {
  const formikProps = { values, handleChange, touched, errors, reset };
  const {
    heading,
    subHeading ,
    form: { button },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const MPath = 'member-information';
  const PPath = 'member-profile';
  const DPath = 'member-dashboard';

  const screenW = window.innerWidth;

  const [isAdult, setIsAdult] = useState(false);

  useEffect(() => {
    reset && resetForm();
  }, [reset, resetForm]);

  useEffect(() => {
    startFetchingPrimeMemberFromDB(currentUser.member);
  }, [currentUser.member, startFetchingPrimeMemberFromDB]);

  useEffect(() => {
    if (primeMember.memberHistoryId) {
      startFetchingMemberHistoryByIdFromDB(primeMember.memberHistoryId);
    }
  }, [
    primeMember.memberHistoryId,
    startFetchingMemberHistoryByIdFromDB
  ]);

  useEffect(() => {
    if (states.length === 0) {
      startLoadingStatesFromDB();
    }
  }, [states, startLoadingStatesFromDB]);

  useEffect(() => {
    if (occupations.length === 0) {
      startLoadingOccupationsFromDB();
    }
  }, [occupations, startLoadingOccupationsFromDB]);

  useEffect(() => {
    let dt;
    if (values.dateOfBirth) {
      dt = values.dateOfBirth;
      dt.length !== 0
        ? calculateAge(dt) >= 15
          ? setIsAdult(true)
          : setIsAdult(false)
        : setIsAdult(false);
    }
  }, [values.dateOfBirth, setIsAdult]);

  useEffect(() => {
    if (!isAdult) {
      values.documentType = '';
      values.documentNumber = '';
      values.expiration = '';
      values.documentIssueState = '';
    }
  }, [
    isAdult,
    values.documentType,
    values.documentNumber,
    values.expiration,
    values.documentIssueState,
  ])

  return (
    <section className={path === MPath ? 'ie-form-scaffold' : 'pb-4'} >
      <Container className={path !== MPath ? 'px-0' : ''} >
        {path === MPath ||
          path === DPath ? (
          <header className={screenW < 525 ? 'pb-5' : 'py-4'}>
            <h2>{heading.myInfo}</h2>
            <p>{subHeading.myInfo}</p>
          </header>
        ) : null} 
        <FormikForm noValidate>
          {path === PPath ||
            path === MPath ||
            path === DPath ? (
            <>
              <PersonalDetails {...formikProps} />
              <ContactInfo {...formikProps} />
            </>
          ) : null}
          {path === MPath ||
            path === DPath ? (
            <>
              {isAdult ? <Identification {...formikProps} /> : null}
              <LegalStatus {...formikProps} />
              <WorkDetails {...formikProps} />
              <BusinessDetails {...formikProps} />
              <Education {...formikProps} />
              <MemberFelonyHistory {...formikProps} />
            </>
          ) : null}
          <div className='float-right pb-5'>
            <ErrorMessages errors={errors} />
            <div className='float-right'>
              {primeMember && primeMember._id ? (
                <Button disabled={isSubmitting} onClick={handleSubmit}>
                  {button.update}
                </Button>
              ) : !primeMember && !primeMember._id ? (
                <Button disabled={isSubmitting} onClick={handleSubmit}>
                  {button.save}
                    </Button>
              ) : null}
            </div>
          </div>
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikMember = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({
    primeMember,
    currentUser
  }) => ({
    householdMembers:
      primeMember.householdMembers && primeMember.type && primeMember.type.self
        ? primeMember.householdMembers
        : [],
    propertyPolicies:
      primeMember.propertyPolicies && primeMember.type && primeMember.type.self
        ? primeMember.propertyPolicies
        : [],
    firstName:
      primeMember.details && primeMember.details.firstName 
        ? primeMember.details.firstName
        : '',
    middleName:
      primeMember.details && primeMember.details.middleName 
        ? primeMember.details.middleName
        : '',
    lastName:
      primeMember.details && primeMember.details.lastName 
        ? primeMember.details.lastName
        : '',
    dateOfBirth:
      primeMember.details && primeMember.details.dateOfBirth 
        ? new Date(primeMember.details.dateOfBirth)
        : '',
    gender:
      primeMember.details && primeMember.details.male
        ? 'male'
        : primeMember.details && primeMember.details.female
          ? 'female'
          : primeMember.details && primeMember.details.otherGenders
            ? 'other'
            : '',
    memberType:
      primeMember.details && primeMember.details.email === currentUser.email
        ? 'self'
        : '',
    email:
      primeMember.details && primeMember.details.email
        ? primeMember.details.email
        : '',
    mobile:
      primeMember.details && primeMember.details.mobile 
        ? primeMember.details.mobile
        : '',
    landline:
      primeMember.details && primeMember.details.landline 
        ? primeMember.details.landline
        : '',
    isPhoneValid:
      primeMember.details &&
      primeMember.details.isPhoneValid 
        ? true
        : false,
    street:
      primeMember.address && primeMember.address.streetAddress
        ? primeMember.address.streetAddress
        : '',
    city: primeMember.address && primeMember.address.city ? primeMember.address.city : '',
    zipCode:
      primeMember.address && primeMember.address.zipCode 
        ? primeMember.address.zipCode
        : '',
    state: primeMember.address ? primeMember.address.state : '',
    documentType: primeMember.identification
      ? (primeMember.identification.driverPermit && 'driversPermit') ||
        (primeMember.identification.driversLicense && 'driversLicense') ||
        (primeMember.identification.passport && 'passport') ||
        (primeMember.identification.stateID && 'stateID')
      : '',
    documentNumber:
      primeMember.identification && primeMember.identification.number
        ? primeMember.identification.number
        : '',
    expiration:
      primeMember.identification && primeMember.identification.expirationDate 
        ? primeMember.identification.expirationDate
        : '',
    documentIssueState:
      primeMember.identification && primeMember.identification.state
        ? primeMember.identification.state
        : '',
    legalDocumentType: primeMember.legalStatus
      ? (primeMember.legalStatus.citizen && 'citizen') ||
        (primeMember.legalStatus.alienRegistration && 'alienRegistration') ||
        (primeMember.legalStatus.visa && 'visa')
      : '',
    legalDocumentNumber: primeMember.legalStatus
      ? primeMember.legalStatus.ssn ||
        primeMember.legalStatus.alienRegistrationNumber ||
        primeMember.legalStatus.uscisNumber
      : '',
    occupations:
      primeMember.occupations && primeMember.occupations.length !== 0
        ? primeMember.occupations
        : [{ occupation: '' }],
    annualIncome:
      primeMember.annualIncome && primeMember.annualIncome 
        ? primeMember.annualIncome
        : '',
    militaryServiceType: primeMember.militaryService
      ? (primeMember.militaryService.isDirectlyRelatedTo && 'isDirectlyRelatedTo') ||
        (primeMember.militaryService.isInReserves && 'isInReserves') ||
        (primeMember.militaryService.isServing && 'isServing') ||
        (primeMember.militaryService.isVeteran && 'isVeteran') ||
        (primeMember.militaryService.none && 'none')
      : '',
    businessName: primeMember.business ? primeMember.business.name : '',
    businessOwnership: primeMember.business
      ? primeMember.business.isOwner
        ? 'true'
        : 'false'
      : '',
    businessStreet:
      primeMember.business &&
        primeMember.business.address &&
        primeMember.business.address.streetAddress
        ? primeMember.business.address.streetAddress
        : '',
    businessCity:
      primeMember.business &&
        primeMember.business.address &&
        primeMember.business.address.city
        ? primeMember.business.address.city
        : '',
    businessZipCode:
      primeMember.business &&
      primeMember.business.address &&
      primeMember.business.address.zipCode 
        ? primeMember.business.address.zipCode
        : '',
    businessState:
      primeMember.business &&
        primeMember.business.address &&
        primeMember.business.address.state
        ? primeMember.business.address.state
        : '',
    acquisitions:
      primeMember.education &&
      primeMember.education.acquisitions &&
        primeMember.education.acquisitions.length !== 0
        ? primeMember.education.acquisitions
        : [],
    degreeType: primeMember.education && primeMember.education.degreeType 
      ? primeMember.education.degreeType
      : '',
    felonyStatus:
      primeMember.felonyStatus &&
      primeMember.felonyStatus.hasBeenConvicted
        ? 'true'
        : primeMember.felonyStatus && 
          !primeMember.felonyStatus.hasBeenConvicted
          ? 'false'
          : '',
    felonyReason: primeMember.felonyStatus
      ? primeMember.felonyStatus.reasonForConviction
      : '',
    memberHistory: '',
    primeMemberId: currentUser ? currentUser.member : '',
    isMemberLastStep: primeMember.isMemberLastStep ? primeMember.isMemberLastStep : '',
    profileId: currentUser ? currentUser.profile : '',
    agentId: currentUser ? currentUser.agent : '',
  }),
  validationSchema: MemberSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: { startMemberUpdate, primeMember }, resetForm, setSubmitting }
  ) => {
    setSubmitting(true);
    if (primeMember && primeMember._id) {
      startMemberUpdate(primeMember._id, values);
    }
    resetForm();
    setSubmitting(false);
  },
})(Member);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  primeMember: selectPrimeMember,
  memberHistoryById: selectMemberHistoryById,
  states: selectStates,
  occupations: selectOccupations,
  reset: selectReset,
});

export default withRouter(
  connect(mapStateToProps, {
    startFetchingPrimeMemberFromDB,
    startFetchingMemberHistoryByIdFromDB,
    startLoadingStatesFromDB,
    startLoadingOccupationsFromDB,
    startMemberUpdate,
    startMemberQuoteUpdate,
  })(FormikMember)
);
