export const AGENT_HOW_IT_WORKS_CMS_DATA = {
  title: 'How it works',
  imageUrl: './images/timeline.webp',
  steps: [
    {
      title: `Step 1: Bid on Quotes`,
      desc: `Bid on quotes requests from members that you're interested in`,
      imageUrl: './images/auction.webp',
    },
    {
      title: 'Step 2: Wait for AI',
      desc: 'Wait for AI to select the optimal agents for that member',
      imageUrl: './images/hourglass.webp',
    },
    {
      title: 'Step 3: Talk to Member',
      desc: 'Selected agents communicate with and create quotes for the member',
      imageUrl: './images/chat.webp',
    },
    {
      title: 'Step 4: Member Selects Quote',
      desc: 'Wait for member to select the best possible quote from multiple agents',
      imageUrl: './images/menu.webp',
    },
    {
      title: `Step 5: Chosen Agent Pays Pledge`,
      desc: `Chosen agent pays pledge amount to get client's Ccntact info`,
      imageUrl: './images/credit-card.webp',
    },
    {
      title: 'Step 6: Turn Quote into Policy',
      desc: 'Communicate with member directly to turn the quote into a policy',
      imageUrl: './images/policy.webp',
    },
  ]
}

