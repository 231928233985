export const FORGOT_PASSWORD_CMS_DATA = {
  email: {
    placeholder: 'Enter Email Address',
    invalid: 'Invalid Email Format',
    required: 'Email is a required field'
  },
  label: {
    heading:
      'Please enter your email and we will send a link to reset your password.'
  },
  action: {
    submit: 'Submit'
  },
  errObj: {
    account: {
      exists:
        'A link has been sent. Please check your email for the next steps.',
      missing: 'Oops, we do not seem to have this email registered with us. '
      + 'It could be a typo or that you used another email account. '
      + 'Please try again or register with us on the home page.'
    }
  }
};
