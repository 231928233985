import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startFetchingPropertyFromDB,
  togglePropertyForm,
  setClearPropertyForm,
  startPropertyDelete,
} from '../../../redux/quotes/property/property.actions';
import {
  selectPropertyById,
  selectShowPropertyForm,
} from '../../../redux/quotes/property/property.selectors';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import { PROPERTY_QUOTES_CMS_DATA } from '../../../pages/quotes/property/property.cms';
import { useHistory } from 'react-router-dom';
import ShowModal from '../../modal/show-modal.component';

const EditProperties = ({
  primeMember,
  currentUser,
  togglePropertyForm,
  startPropertyDelete,
  setClearPropertyForm,
  showPropertyForm,
  startFetchingPropertyFromDB,
}) => {
  const {
    modal: { heading, action }
  } = PROPERTY_QUOTES_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname;
  const propertyP = '/household-properties-information';

  const [show, setShow] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [storeAddress, setStoreAddress] = useState([]);

  const handleUpdate = (id) => {
    if (id) {
      startFetchingPropertyFromDB(id);
    }
    if (!showPropertyForm) {
      togglePropertyForm(true);
    }
    if (showPropertyForm) {
      togglePropertyForm(false);
      setClearPropertyForm(true);
    }
  }

  const handleAddAnother = () => {
    if (!showPropertyForm) {
      togglePropertyForm(true);
      setClearPropertyForm(true);
    }
    if (showPropertyForm) {
      togglePropertyForm(false);
    }
  }

  const handleDelete = () => {
    startPropertyDelete(storeIds[0], storeIds[1]);
    setShow(false);
    window.location.reload();
  }

  const handleCancel = () => {
    if (!showPropertyForm) {
      setStoreIds([]);
      setStoreAddress([]);
    } 
    setShow(false);
    if (path === propertyP) {
      window.scrollTo({ top: 0 });
    }
  }

  const getHouseholdProperties = (m) => {
    if (
      m &&
      m.householdProperties &&
      m.householdProperties.length > 0
    ) {
      return m.householdProperties;
    }
  }

  const householdProperties = getHouseholdProperties(primeMember);

  const getOwnerShipType = (pt) => {
    if (pt.ownership) {
      for (let t in pt.ownership) {
        if (pt.ownership[t]) {
          return t;
        }
      }
    }
  }  

  const getPurposeType = (pt) => {
    if (pt.purpose) {
      for (let t in pt.purpose) {
        if (pt.purpose[t]) {
          return t;
        }
      }
    }
  }  

  return (
    <div className={
      path === propertyP
        ? 'pb-5 mb-4 text-right'
        : 'mb-3 text-right'
      }
    >
      <Table
        bordered
        responsive
        className='shadow-sm p-3 mb-4 bg-white rounded'
      >
        {householdProperties && householdProperties.length > 0 ? (
          <>
            <thead className='thead-light'>
              <tr>
                <th>Purpose</th>
                <th>Ownership</th>
                <th>Street</th>
                <th>City</th>
                <th>State</th>
                <th>Zip Code</th>
                <th></th>
              </tr>
            </thead>
            {householdProperties.map((hp, i) => (
              <tbody key={hp._id}>
                <tr key={i}>
                  <td>
                    {
                      getPurposeType(hp).charAt(0).toUpperCase() + getPurposeType(hp).slice(1)
                    }
                  </td>
                  <td>
                    {
                      getOwnerShipType(hp).charAt(0).toUpperCase() + getOwnerShipType(hp).slice(1)
                    }
                  </td>
                  <td>{hp.address.streetAddress}</td>
                  <td>{hp.address.city}</td>
                  <td>{hp.address.state}</td>
                  <td>{hp.address.zipCode}</td>
                  <td>
                    {path === propertyP ? (
                      <Button
                        className='mr-1'
                        variant='outline-danger'
                        onClick={() => {
                          setStoreIds([hp._id, currentUser.member]);
                          setStoreAddress([hp.address.streetAddress, hp.address.city, hp.address.state, hp.address.zipCode])
                          setShow(true);
                        }}
                      >
                        {action.delete}
                      </Button>
                    ) : null}
                    <Button
                      className='ml-1'
                      variant={
                        showPropertyForm && hp._id === storeIds[0]
                          ? 'warning'
                          : 'primary'
                      }
                      onClick={() => {
                        handleUpdate(hp._id);
                        setStoreIds([hp._id, currentUser.member]);
                        setStoreAddress([hp.address.streetAddress, hp.address.city, hp.address.state, hp.address.zipCode]);
                      }}
                    >
                      {showPropertyForm && hp._id === storeIds[0]
                        ? action.hide
                        : action.update
                      }                      
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </>
        ) : null}
      </Table>
      <div className='text-left'>
        {path === propertyP ? (
          <Button
            variant={
              showPropertyForm && storeIds.length === 0
                ? 'warning'
                : 'primary'
            }
            onClick={() => {
              handleAddAnother();
              setStoreIds([]);
              setStoreAddress([]);
            }}
          >
            {
              showPropertyForm && storeIds.length === 0
                ? action.hide
                : action.add
            }
          </Button>
        ) : null }
      </div>
      <ShowModal
        show={show}
        heading={`${heading} ${storeAddress[0]}, ${storeAddress[1]}, ${storeAddress[2]}, ${storeAddress[3]}?`}
        secondary={action.cancel}
        primary={action.delete}
        handleClose={handleCancel}
        handleSecondary={handleCancel}
        handlePrimary={handleDelete}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  property: selectPropertyById,
  primeMember: selectPrimeMember,
  showPropertyForm: selectShowPropertyForm,
});

export default connect(mapStateToProps, {
  startPropertyDelete,
  togglePropertyForm,
  setClearPropertyForm,
  startFetchingPropertyFromDB,
})(EditProperties);