const propertyBiddingActionTypes = {
  SET_SHOW_PROPERTY_QUOTES: 'SET_SHOW_PROPERTY_QUOTES',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_PROPERTY_BID: 'SET_IS_PROPERTY_BID',
  SET_IS_PROPERTY_SELECTED_BY_AI: 'SET_IS_PROPERTY_SELECTED_BY_AI',
  START_PROPERTY_BIDDING_SAVE: 'START_PROPERTY_BIDDING_SAVE',
  PROPERTY_BIDDING_SAVE_SUCCESS: 'PROPERTY_BIDDING_SAVE_SUCCESS',
  START_PROPERTY_BIDDING_UPDATE: 'START_PROPERTY_BIDDING_UPDATE',
  PROPERTY_BIDDING_UPDATE_SUCCESS: 'PROPERTY_BIDDING_UPDATE_SUCCESS',
  RESET_FORM: 'RESET_FORM',
};

export default propertyBiddingActionTypes;
