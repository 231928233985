import { AGENT_MEDICAL_QUOTE_CMS_DATA }
  from '../../../pages/agent-quotes/medical/medical-quote.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import agentMedicalQuoteActionTypes from './medical.types';

const payload = {
  context: 'agentMedicalQuote',
  status: '',
  error: '',
}

export const setClearForm = (clear) => ({
  type: agentMedicalQuoteActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const setIsAgent = (agent) => ({
  type: agentMedicalQuoteActionTypes.SET_IS_AGENT,
  payload: agent
});

export const startFetchingAgentMedicalQuoteFromDB = (id) => ({
  type: agentMedicalQuoteActionTypes.FETCH_AGENT_MEDICAL_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAgentMedicalQuoteFromDBSuccess = (policy) => ({
  type: agentMedicalQuoteActionTypes.FETCH_AGENT_MEDICAL_QUOTE_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchAgentMedicalQuoteFromDBFailure = (e) => ({
  type: agentMedicalQuoteActionTypes.FETCH_AGENT_MEDICAL_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: agentMedicalQuoteActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: agentMedicalQuoteActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: agentMedicalQuoteActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: AGENT_MEDICAL_QUOTE_CMS_DATA.errMsg.states,
});

export const startAgentMedicalQuoteSave = (data) => ({
  type: agentMedicalQuoteActionTypes.START_AGENT_MEDICAL_QUOTE_SAVE,
  payload: data
});

export const agentMedicalQuoteSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_QUOTE_SUCCESS,
  },
});

export const agentMedicalQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentMedicalQuoteUpdate = (id, formData) => ({
  type: agentMedicalQuoteActionTypes.START_AGENT_MEDICAL_QUOTE_UPDATE,
  payload: { id, formData }
});

export const agentMedicalQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_UPDATE_QUOTE_SUCCESS,
  },
});

export const agentMedicalQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentMedicalQuoteDelete = (id, pId) => ({
  type: agentMedicalQuoteActionTypes.START_AGENT_MEDICAL_QUOTE_DELETE,
  payload: { id, pId },
});

export const agentMedicalQuoteDeleteSuccess = () => ({
  type: agentMedicalQuoteActionTypes.AGENT_MEDICAL_QUOTE_DELETE_SUCCESS,
});

export const agentMedicalQuoteDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});