import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import vehicleQuoteActionTypes from "./vehicle.types";
import { convertVehicleQuoteForDBSave } from "./vehicle.utils";
import {
  fetchVehicleQuotesFromDBSuccess,
  fetchVehicleQuotesFromDBFailure,
  fetchVehicleQuoteFromDBSuccess,
  fetchVehicleQuoteFromDBFailure,
  vehicleQuoteSaveSuccess,
  vehicleQuoteSaveFailure,
  vehicleQuoteUpdateSuccess,
  vehicleQuoteUpdateFailure,
  vehicleQuoteDeleteSuccess,
  vehicleQuoteDeleteFailure,
  checkingChatGroupSuccess,
  saveDeleteCometChatSuccess,
  lastStepVehicleQuoteUpdateSuccess,
} from "./vehicle.actions";

function* fetchVehicleQuotesFromDB() {
  try {
    const response = yield axios.get("/api/vehicle-quote");
    const quotes = yield response.data;
    yield put(fetchVehicleQuotesFromDBSuccess(quotes));
  } catch (e) {
    yield put(fetchVehicleQuotesFromDBFailure(e));
  }
}

function* fetchVehicleQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios(`/api/vehicle-quote/${id}`);
    const quote = yield response.data;
    yield put(fetchVehicleQuoteFromDBSuccess(quote));
  } catch (e) {
    yield put(fetchVehicleQuoteFromDBFailure(e));
  }
}

function* saveVehicleQuote({ payload: formData }) {
  try {
    const data = yield call(convertVehicleQuoteForDBSave, formData);
    yield axios.post("/api/vehicle-quote", data, configureAxios());
    yield put(vehicleQuoteSaveSuccess());
  } catch (e) {
    yield put(vehicleQuoteSaveFailure(e));
  }
}

function* updateVehicleQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertVehicleQuoteForDBSave, formData);
    const response = yield axios.patch(
      `/api/vehicle-quote/${id}`,
      data,
      configureAxios()
    );
    const quote = yield JSON.parse(response.config.data);
    if (data.isLastStep) {
      yield put(lastStepVehicleQuoteUpdateSuccess());
    } else {
      yield put(vehicleQuoteUpdateSuccess(quote));
    }
  } catch (e) {
    yield put(vehicleQuoteUpdateFailure(e));
  }
}

function* deleteVehicleQuote({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/vehicle-quote/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(vehicleQuoteDeleteSuccess());
  } catch (e) {
    yield put(vehicleQuoteDeleteFailure(e));
  }
}

function* checkingChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/vehicle-quote/${id}/check-chat-group`);
    yield checkingChatGroupSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* saveDeleteChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/vehicle-quote/${id}/save-delete-chat`);
    yield saveDeleteCometChatSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* onFetchVehicleQuotesStart() {
  yield takeEvery(
    vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTES_FROM_DB_START,
    fetchVehicleQuotesFromDB
  );
}

function* onFetchVehicleQuoteStart() {
  yield takeEvery(
    vehicleQuoteActionTypes.FETCH_VEHICLE_QUOTE_FROM_DB_START,
    fetchVehicleQuoteFromDB
  );
}

function* onVehicleQuoteSaveStart() {
  yield takeEvery(
    vehicleQuoteActionTypes.START_VEHICLE_QUOTE_SAVE,
    saveVehicleQuote
  );
}

function* onVehicleQuoteUpdateStart() {
  yield takeEvery(
    vehicleQuoteActionTypes.START_VEHICLE_QUOTE_UPDATE,
    updateVehicleQuote
  );
}

function* onVehicleQuoteDeleteStart() {
  yield takeEvery(
    vehicleQuoteActionTypes.START_VEHICLE_QUOTE_DELETE,
    deleteVehicleQuote
  );
}

function* onCheckingChatGroup() {
  yield takeEvery(
    vehicleQuoteActionTypes.START_CHECKING_CHAT_GROUP_VEHICLE,
    checkingChatGroup
  );
}

function* onSaveDeleteChatGroup() {
  yield takeEvery(
    vehicleQuoteActionTypes.SAVE_DELETE_VEHICLE_COMET_CHAT,
    saveDeleteChatGroup
  );
}

export function* vehicleQuoteSagas() {
  yield all([
    call(onFetchVehicleQuotesStart),
    call(onFetchVehicleQuoteStart),
    call(onVehicleQuoteSaveStart),
    call(onVehicleQuoteUpdateStart),
    call(onVehicleQuoteDeleteStart),
    call(onCheckingChatGroup),
    call(onSaveDeleteChatGroup),
  ]);
}
