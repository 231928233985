import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import animalQuoteActionTypes from "./companion-animal.types";
import { convertAnimalQuoteForDBSave } from "./companion-animal.utils";
import {
  fetchAnimalQuotesFromDBSuccess,
  fetchAnimalQuotesFromDBFailure,
  fetchAnimalQuoteFromDBSuccess,
  fetchAnimalQuoteFromDBFailure,
  animalQuoteSaveSuccess,
  animalQuoteSaveFailure,
  animalQuoteUpdateSuccess,
  animalQuoteUpdateFailure,
  checkingChatGroupSuccess,
  saveDeleteCometChatSuccess,
  lastStepAnimalQuoteUpdateSuccess,
} from "./companion-animal.actions";

function* fetchAnimalQuotesFromDB() {
  try {
    const response = yield axios.get(`/api/animal-quote`);
    const quotes = yield response.data;
    yield put(fetchAnimalQuotesFromDBSuccess(quotes));
  } catch (e) {
    yield put(fetchAnimalQuotesFromDBFailure(e));
  }
}

function* fetchAnimalQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios(`/api/animal-quote/${id}`);
    const quote = yield response.data;
    yield put(fetchAnimalQuoteFromDBSuccess(quote));
  } catch (e) {
    yield put(fetchAnimalQuoteFromDBFailure(e));
  }
}

function* saveAnimalQuote({ payload: formData }) {
  try {
    const data = yield call(convertAnimalQuoteForDBSave, formData);
    yield axios.post("/api/animal-quote", data, configureAxios());
    yield put(animalQuoteSaveSuccess());
  } catch (e) {
    yield put(animalQuoteSaveFailure(e));
  }
}

function* updateAnimalQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAnimalQuoteForDBSave, formData);
    yield axios.patch(`/api/animal-quote/${id}`, data, configureAxios());

    if (data.isLastStep) {
      yield put(lastStepAnimalQuoteUpdateSuccess());
    } else {
      yield put(animalQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(animalQuoteUpdateFailure(e));
  }
}

function* checkingChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/animal-quote/${id}/check-chat-group`);
    yield checkingChatGroupSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* saveDeleteChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/animal-quote/${id}/save-delete-chat`);
    yield saveDeleteCometChatSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* onFetchAnimalQuotesStart() {
  yield takeEvery(
    animalQuoteActionTypes.FETCH_ANIMALS_QUOTES_FROM_DB_START,
    fetchAnimalQuotesFromDB
  );
}

function* onFetchAnimalQuoteStart() {
  yield takeEvery(
    animalQuoteActionTypes.FETCH_ANIMALS_QUOTE_FROM_DB_START,
    fetchAnimalQuoteFromDB
  );
}

function* onAnimalQuoteSaveStart() {
  yield takeEvery(
    animalQuoteActionTypes.START_ANIMALS_QUOTE_SAVE,
    saveAnimalQuote
  );
}

function* onAnimalQuoteUpdateStart() {
  yield takeEvery(
    animalQuoteActionTypes.START_ANIMALS_QUOTE_UPDATE,
    updateAnimalQuote
  );
}

function* onCheckingChatGroup() {
  yield takeEvery(
    animalQuoteActionTypes.START_CHECKING_CHAT_GROUP_ANIMAL,
    checkingChatGroup
  );
}

function* onSaveDeleteChatGroup() {
  yield takeEvery(
    animalQuoteActionTypes.SAVE_DELETE_ANIMAL_COMET_CHAT,
    saveDeleteChatGroup
  );
}

export function* animalQuoteSagas() {
  yield all([
    call(onFetchAnimalQuoteStart),
    call(onAnimalQuoteSaveStart),
    call(onAnimalQuoteUpdateStart),
    call(onFetchAnimalQuotesStart),
    call(onCheckingChatGroup),
    call(onSaveDeleteChatGroup),
  ]);
}
