import { ModalState, ModalTypes } from '../../modal/modal.types';
import animalQuoteActionTypes from './companion-animal.types';

const payload = {
  context: 'animalQuote',
  status: '',
  error: '',
};

export const decrementCurrentStep = (currentStep) => ({
  type: animalQuoteActionTypes.ANIMALS_DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: animalQuoteActionTypes.ANIMALS_INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const setAnimalFormData = (data) => ({
  type: animalQuoteActionTypes.SET_ANIMAL_FORM_DATA,
  payload: data,
});

export const setQuoteId = (id) => ({
  type: animalQuoteActionTypes.SET_ANIMAL_QUOTE_ID,
  payload: id,
});

export const setMembersInfo = (members) => ({
  type: animalQuoteActionTypes.SET_MEMBERS_INFO,
  payload: members,
});

export const startCheckingChatGroup = (id) => ({
  type: animalQuoteActionTypes.START_CHECKING_CHAT_GROUP_ANIMAL,
  payload: id,
})

export const checkingChatGroupSuccess = () => ({
  type: animalQuoteActionTypes.CHECKING_CHAT_GROUP_ANIMAL_SUCCESS,
})

export const saveDeleteCometChat = (id) => ({
  type: animalQuoteActionTypes.SAVE_DELETE_ANIMAL_COMET_CHAT,
  payload: id,
})

export const saveDeleteCometChatSuccess = () => ({
  type: animalQuoteActionTypes.SAVE_DELETE_ANIMAL_COMET_CHAT_SUCCESS,
})

export const startFetchingAnimalQuotesFromDB = () => ({
  type: animalQuoteActionTypes.FETCH_ANIMALS_QUOTES_FROM_DB_START,
});

export const fetchAnimalQuotesFromDBSuccess = (quote) => ({
  type: animalQuoteActionTypes.FETCH_ANIMALS_QUOTES_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchAnimalQuotesFromDBFailure = (e) => ({
  type: animalQuoteActionTypes.FETCH_ANIMALS_QUOTES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingAnimalQuoteFromDB = (id) => ({
  type: animalQuoteActionTypes.FETCH_ANIMALS_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchAnimalQuoteFromDBSuccess = (quote) => ({
  type: animalQuoteActionTypes.FETCH_ANIMALS_QUOTE_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchAnimalQuoteFromDBFailure = (e) => ({
  type: animalQuoteActionTypes.FETCH_ANIMALS_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startAnimalQuoteSave = (quotes) => ({
  type: animalQuoteActionTypes.START_ANIMALS_QUOTE_SAVE,
  payload: quotes,
});

export const animalQuoteSaveSuccess = () => ({
  type: animalQuoteActionTypes.ANIMALS_QUOTE_SAVE_SUCCESS,
});

export const animalQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAnimalQuoteUpdate = (id, formData) => ({
  type: animalQuoteActionTypes.START_ANIMALS_QUOTE_UPDATE,
  payload: { id, formData },
});

export const animalQuoteUpdateSuccess = () => ({
  type: animalQuoteActionTypes.ANIMALS_QUOTE_UPDATE_SUCCESS,
});

export const animalQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const lastStepAnimalQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.QUOTE_REQUEST_SUCCESS,
  },
});



