import { ModalState, ModalTypes } from '../../modal/modal.types';
import healthBiddingActionTypes from './health-bidding.types';

const payload = {
  context: 'healthBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: healthBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsHealthBid = (toggle) => ({
  type: healthBiddingActionTypes.SET_IS_HEALTH_BID,
  payload: toggle,
});

export const startHealthBiddingSave = (values) => ({
  type: healthBiddingActionTypes.START_HEALTH_BIDDING_SAVE,
  payload: values,
});

export const healthBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const healthBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startHealthBiddingUpdate = (id, formData) => ({
  type: healthBiddingActionTypes.START_HEALTH_BIDDING_UPDATE,
  payload: { id, formData },
});

export const healthBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const healthBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

