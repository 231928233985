import animalQuoteActionTypes from "./companion-animal.types";
import { ModalTypes } from "../../modal/modal.types";
import authActionTypes from "../../auth/auth.types";

let INITIAL_STATE = {
  currentStep: 0,
  formData: {},
  quoteId: "",
  membersInfo: [],
  animalQuotesFromDB: [],
  animalQuoteFromDB: {},
  reset: false,
};

const PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.animalQuote) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.animalQuote);
}

const animalQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case animalQuoteActionTypes.ANIMALS_QUOTE_SAVE_SUCCESS:
    case animalQuoteActionTypes.ANIMALS_QUOTE_UPDATE_SUCCESS:
    case animalQuoteActionTypes.ANIMALS_INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case animalQuoteActionTypes.ANIMALS_DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case animalQuoteActionTypes.SET_ANIMAL_QUOTE_ID:
      return {
        ...state,
        quoteId: action.payload,
      };
    case animalQuoteActionTypes.SET_ANIMAL_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case animalQuoteActionTypes.SET_MEMBERS_INFO:
      return {
        ...state,
        membersInfo: action.payload,
      };
    case animalQuoteActionTypes.FETCH_ANIMALS_QUOTES_FROM_DB_SUCCESS:
      return {
        ...state,
        animalQuotesFromDB: action.payload,
      };
    case animalQuoteActionTypes.FETCH_ANIMALS_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        animalQuoteFromDB: action.payload,
      };
    case ModalTypes.OPEN_MODAL:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        quoteId: "",
        membersInfo: [],
        animalQuotesFromDB: [],
        animalQuoteFromDB: {},
        reset: false,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        quoteId: "",
        membersInfo: [],
        animalQuotesFromDB: [],
        animalQuoteFromDB: {},
        reset: false,
      };
    case animalQuoteActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default animalQuoteReducer;
