export const AGENT_MEDICAL_QUOTE_CMS_DATA = {
  heading: 'Medical Quote',
  subHeading: 'Enter the information pertaining to the Medical policy for the following Member; a separate policy should be created for different Members that have different policies.',
  form: {
    button: {
      submit: 'Submit',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    },
    personalDetails: {
      title: 'Member Information',
      description: '',
      label: {
        member: 'Select a member on the list or "Add another"',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        gender: 'Gender',
        memberType: 'Member relation',
        male: 'Male',
        female: 'Female',
        dateOfBirth: 'Date of birth',
      },
      dropdown: {
        memberType: [
          { value: 'spouse', label: 'Spouse' },
          { value: 'domesticPartner', label: 'Domestic partner' },
          { value: 'child', label: 'Child' },
          { value: 'adoptedChild', label: 'Adopted child' },
          { value: 'parent', label: 'Parent' },
          { value: 'sibling', label: 'Sibling' },
          { value: 'careGiver', label: 'Caregiver' },
          { value: 'stepChild', label: 'Stepchild' },
          { value: 'transferStudent', label: 'Transfer student' },
        ],
        gender: [
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
        ],
      },
    },
    documents: {
      title: 'Document',
      description: 'Please upload the quote document for the Member to view.',
      label: 'Upload quote here',
    },
    effectiveDates: {
      title: 'Effective Dates',
      description: 'This is the date the policy began or begins and when it is up for renewal.',
      label: {
        start: 'Policy start date',
        end: 'Policy renewal date',
      }
    },
    isProvidedByEmployer: {
      title: 'Provided by Employer',
      description: 'This is simply asking if the policy is through a family member\'s employer.',
      label: 'Is this policy provided by an employer?',
      dropdown: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ]
    },
    policyType: {
      title: 'Policy Type',
      description: 'Please specify whether the plan is Catastrophic, High Deductible Health Plan (HDHP), Medigap, or Standard (also known as Full).',
      label: {
        policyType: 'Policy Type',
        catastrophic: 'Catastrophic plan',
        HDHP: 'High Deductible Health Plan (HDHP)',
        medicare: 'Medicare',
        medigapPlan: 'Medigap plan',
        LOfCoverage: 'Level of coverage',
      },
      dropdown: {
        policyType: [
          { value: 'catastrophic', label: 'Catastrophic plan' },
          { value: 'HDHP', label: 'High Deductible Health Plan (HDHP)' },
          { value: 'medigap', label: 'Medigap' },
          { value: 'standardOrFull', label: 'Standard or Full' },
        ],
        medicareWith: [
          { value: 'WOMedigap', label: 'Without Medigap' },
          { value: 'WMedigap', label: 'With Medigap' },
        ],
        medigapPlan: [
          { value: 'planA', label: 'Part A' },
          { value: 'planB', label: 'Part B' },
          { value: 'planC', label: 'Part C' },
          { value: 'planD', label: 'Part D' },
          { value: 'planF', label: 'Part F' },
          { value: 'planG', label: 'Part G' },
          { value: 'planK', label: 'Part K' },
          { value: 'planL', label: 'Part L' },
          { value: 'planM', label: 'Part M' },
          { value: 'planN', label: 'Part N' },
        ],
        LOfCoverage: [
          { value: 'bronze', label: 'Bronze' },
          { value: 'silver', label: 'Silver' },
          { value: 'gold', label: 'Gold' },
          { value: 'platinum', label: 'Platinum' },
        ]
      }
    },
    networkType: {
      title: 'Network',
      description: 'These have to do with the network and the flexibility given to access specialists, with or without a referral from a Primary Care Physician (PCP).',
      label: 'Network',
      dropdown: {
        networkType: [
          { value: 'POS', label: 'POS (Point Of Service)' },
          { value: 'HMO', label: 'HMO (Health maintenance organization)' },
          { value: 'PPO', label: 'PPO (Preferred provider organization)' },
          { value: 'EPO', label: 'EPO (Exclusive provider organization)' },
        ]
      }
    },
    outOfNetworkCo: {
      title: 'Out-of-Network Coinsurance',
      description: 'The percentage of Co-insurance that the Member is responsible for, if they go Out-of-Network. Enter 100% if not covered.',
      label: 'Out-of-Network Coinsurance',
    },
    deductible: {
      title: 'Deductible Amounts',
      description: 'Amount that must be met before the insurance starts coverage for any individual or family, depending on whether the procedures are In or Out-of-Network. This does not pertain to doctor visits where there\'s a Copay. Leave blank for N/A or not covered.',
      label: {
        inNetInd: 'In-Network individual',
        inNetFam: 'In-Network family',
        outOfNetInd: 'Out-of-Network individual',
        outOfNetFam: 'Out-of-Network family',
      }
    },
    maxOut: {
      title: 'Maximum Out-Of-Pocket',
      description: 'This is the most that will be paid by a single Member or family in a year for any medical treatments, depending on whether they are In or Out-of-Network, per year. Leave blank for N/A or not covered.',
      label: {
        inNetInd: 'In-Network individual',
        inNetFam: 'In-Network family',
        outOfNetInd: 'Out-of-Network individual',
        outOfNetFam: 'Out-of-Network family',
      }
    },
    copay: {
      title: 'Copay Amounts',
      description: 'These are the amounts paid when going to physician office visits, whether In or Out-of-Network. Does not include procedures. If Out-Of-Network is not included, leave blank.',
      label: {
        inNetVisit: 'In-Network routine visit',
        inNetSpecialist: 'In-Network specialist',
        inNetCare: 'In-Network urgent Care',
        outOfNetVisit: 'Out-of-Network routine visit',
        outOfNetSpecialist: 'Out-of-Network specialist',
        outOfNetCare: 'Out-of-Network urgent Care',
      }
    },
    presCoverage: {
      title: 'Prescription Drug Coverage',
      description: 'There are up to 6 Tiers of Copays for Prescription drugs. This is how much the Member would pay for each. Must enter, at least, the first 3.',
      label: {
        tier1: 'Tier 1 Copay',
        tier2: 'Tier 2 Copay',
        tier3: 'Tier 3 Copay',
        tier4: 'Tier 4 Copay',
        tier5: 'Tier 5 Copay',
        tier6: 'Tier 6 Copay',
      },
    },
    dentalPlan: {
      title: 'Dental Plan',
      description: 'This is to let the Member know if a Dental Plan was included and will give them a place to enter their Dentist\'s information.',
      label: {
        dentalPlan: 'Dental plan',
      },
      address: {
        firstName: 'Your dentist\'s first name',
        lastName: 'Your dentist\'s last name',
        streetAddress: 'Street address',
        city: 'City',
        state: 'State',
        phoneNumber: 'Phone number',
        website: 'Website',
        elaborate: 'Other information about Claims, login, etc.',
      },
      dropdown: {
        hasDentalPlan: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]
      }
    },
    visionPlan: {
      title: 'Vision Plan',
      description: 'This is to let the Member know if a Vision Plan was included and will give them a place to enter their Optometrist\'s information.',
      label: {
        vision: 'Vision plan',
      },
      address: {
        firstName: 'Your optometrist\'s first name',
        lastName: 'Your optometrist\'s last name',
        streetAddress: 'Street address',
        city: 'City',
        state: 'State',
        phoneNumber: 'Phone number',
        website: 'Website',
        elaborate: 'Other information about Claims, login, etc.',
      },
      dropdown: {
        hasVisionPlan: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]
      }
    },
    additionalInfo: {
      title: 'Additional Information',
      description: 'Any other pertinent information about the policy, including highlights unique to this Carrier, additional endorcements, etc. Also, feel free to sell yourself and your quote here.',
      label: {
        additionalInfo: 'Please provide additional information'
      }
    }
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
  },
  modal: {
    heading: 'Do you want to delete',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    content: {
      policyType: {
        policyType: 'Catastrophic plans have low premiums but high deductibles and Max Out-Of-Pocket expenses. They are also very limited on preventative care. High Deductible Health Plans (HDHP) are great for healthy people in case something unfortunate happens to them. Health Savings Accounts (HSAs) are tax free savings accounts that go great with HDHPs. Medigap might be an option whether you have a Medicare, Medicaid, or CHIP. Standard (sometimes called Full) is the typical Medical insurance with the medal plans.',
        LOfCoverage: 'The medals signify the percentage that the insurance pays once you have met the deductible for anything other than routine visits or visits with a Copay, along with some tests. Copay visits would include your Primary care, a visit to a Specialist, and an Urgent care visit (this does not necessarilly include Emergency and never includes Hospital care). The percentages the the Carrier pays goes as follows: for Platinum - 90%, for Gold - 80%, for Silver - 70%, and for Bronze - 60%.',
        medigap: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
      },
      network: "Health Maintenance Organization (HMO) and Point of Service (POS) plans require a referral from a Primary Care Physician (PCP) but POS's also covers Physicians that are Out-of-Network (with a higher charge). Exclusive Provider Organization (EPO) and Preferred Provider Organization (PPO) plans allow you to go directly to a specialist but EPOs are like HMOs in that they are limited to In-Network Physicians. Although, EPOs are usually larger than HMOs. PPOs have the most freedom with the largest In-Network list of physicians and hospitals.",
      outOfNetworkCo: 'The type of plan you have, whether a medal (Platinum, Gold, etc.) or other designation determines the In-Network Coinsurance but there might be a different Coinsurance for Out-of-Network. This usually does not change. However, some plans do not cover Out-of-Network at all, like HMOs and EPOs, in which case this value is 100%.',
      deductible: {
        inNetwork: {
          individual: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is one person on the plan and you stay In-Network, per year. After the deductible is paid, the Coinsurance starts at the percentage designated by the medal (Platinum, Gold, etc.) or as mentioned in the policy.',
          family: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is more than one person on the plan and you stay In-Network, per year. After the deductible is paid, the Coinsurance starts at the percentage designated by the medal (Platinum, Gold, etc.) or as mentioned in the policy.',
        },
        outOfNetwork: {
          individual: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is one person on the plan and you go Out-of-Network, per year. After the deductible is paid, the Out-of-Network Coinsurance starts, which might be the same percentage as In-Network. Blank means not covered.',
          family: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is more than one person on the plan and you go Out-of-Network, per year. After the deductible is paid, the Out-of-Network Coinsurance starts, which might be the same percentage as In-Network. Blank means not covered.',
        },
      },
      maxOut: {
        inNetwork: {
          individual: 'This is the Maximum that you will pay if there is one person on the plan and you stay In-Network, per year. There might be a cap but it is usually $1,000,000 or more.',
          family: 'This is the Maximum that you will pay if there is more than one person on the plan and you stay In-Network, per year. There might be a cap but it is usually $1,000,000 or more.',
        },
        outOfNetwork: {
          individual: 'This is the Maximum that you will pay if there is one person on the plan and you go Out-of-Network, per year. There might be a cap but it is usually $1,000,000 or more. Blank means not covered.',
          family: 'This is the Maximum that you will pay if there is more than one person on the plan and you go Out-of-Network, per year. There might be a cap but it is usually $1,000,000 or more. Blank means not covered.',
        },
      },
      copay: {
        inNetwork: {
          routineVisit: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a Primary Care Physcian (PCP) visit, In-Netowrk. This might include some tests and some small procdures but not necessarily.',
          specialist: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to a Specialist, In-Netowrk. This does not necessarily include any additional procedures the specialist performs, which you will need to meet the In-Netork deductible for in order to receive more help from insurance.',
          urgentCare: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to Urgent care, In-Netowrk. This does not necessarily include any additional procedures, which you will need to meet the In-Netork deductible for in order to receive more help from insurance.',
        },
        outOfNetwork: {
          routineVisit: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a Primary Care Physcian (PCP) visit, Out-of-Netowrk. This might include some tests and some small procdures but not necessarily. Blank means not covered.',
          specialist: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to a Specialist, Out-of-Netowrk. This does not necessarily include any additional procedures the specialist performs, which you will need to meet the Out-of-Netork deductible for in order to receive more help from insurance. Blank means not covered.',
          urgentCare: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to Urgent care, Out-of-Netowrk. This does not necessarily include any additional procedures, which you will need to meet the Out-of-Netork deductible for in order to receive more help from insurance. Blank means not covered.',
        },
      },
      presCoverage: 'Each Tier has a list of Prescriptions that the Carrier covers for certain Copay amount. The higher the Tier, the higher the Copay.',
      dentalPlan: 'If you have a Dental plan attached to your Medical plan, you can add information about your Dentist here. If you are not already in Edit mode, use that (above) to update this section.',
      visionPlan: 'If you have a Vision plan attached to your Medical plan, you can add information about your Optometrist here. If you are not already in Edit mode, use that (above) to update this section.',
    }
  },
  readOnlyTitles: {
    policyType: {
      catastrophic: {
        booleans: [
          {
            key: 'catastrophic',
            title: 'Catastrophic',
            dataType: 'boolean',
            value: ''
          },
        ]
      },
      HDHP: {
        booleans: [
          {
            key: 'HDHP',
            title: 'HDHP',
            dataType: 'boolean',
            value: ''
          },
        ]
      },
      medigap: {
        label: 'Medigap',
        medigapPlan: {
          booleans: [
            {
              key: 'planA',
              title: 'plan A',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planB',
              title: 'Plan B',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planC',
              title: 'Plan C',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planD',
              title: 'Plan D',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planF',
              title: 'Plan F',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planG',
              title: 'Plan G',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planK',
              title: 'Plan K',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planL',
              title: 'Plan L',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planM',
              title: 'plan M',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
            {
              key: 'planN',
              title: 'plan N',
              dataType: 'boolean',
              info: 'Part A is for Hospital care. Part B is for Medical care (Physicians, medical equipment, etc.). Part C is Medicare Advantage - it includes Parts A and B with the possible additions of Prescription drugs, Dental, Vision, Massages, etc. Part D covers Prescription drugs. Parts F and G are the most extensive coverages; the only difference is G has a deductible which is usually less than the premium difference between the two plans. Parts K through M are à la carte versions of F and G.',
              value: ''
            },
          ]
        },
      },
      standardOrFull: {
        label: 'Standard or full',
        LOfCoverage: {
          label: 'Level of coverage',
          booleans: [
            {
              key: 'bronze',
              title: 'Bronze',
              dataType: 'boolean',
              info: 'The medals signify the percentage that the insurance pays once you have met the deductible for anything other than routine visits or visits with a Copay, along with some tests. Copay visits would include your Primary care, a visit to a Specialist, and an Urgent care visit (this does not necessarilly include Emergency and never includes Hospital care). The percentages the the Carrier pays goes as follows: for Platinum - 90%, for Gold - 80%, for Silver - 70%, and for Bronze - 60%.',
              value: ''
            },
            {
              key: 'silver',
              title: 'Silver',
              dataType: 'boolean',
              info: 'The medals signify the percentage that the insurance pays once you have met the deductible for anything other than routine visits or visits with a Copay, along with some tests. Copay visits would include your Primary care, a visit to a Specialist, and an Urgent care visit (this does not necessarilly include Emergency and never includes Hospital care). The percentages the the Carrier pays goes as follows: for Platinum - 90%, for Gold - 80%, for Silver - 70%, and for Bronze - 60%.',
              value: ''
            },
            {
              key: 'gold',
              title: 'Gold',
              dataType: 'boolean',
              info: 'The medals signify the percentage that the insurance pays once you have met the deductible for anything other than routine visits or visits with a Copay, along with some tests. Copay visits would include your Primary care, a visit to a Specialist, and an Urgent care visit (this does not necessarilly include Emergency and never includes Hospital care). The percentages the the Carrier pays goes as follows: for Platinum - 90%, for Gold - 80%, for Silver - 70%, and for Bronze - 60%.',
              value: ''
            },
            {
              key: 'platinum',
              title: 'Platinum',
              dataType: 'boolean',
              info: 'The medals signify the percentage that the insurance pays once you have met the deductible for anything other than routine visits or visits with a Copay, along with some tests. Copay visits would include your Primary care, a visit to a Specialist, and an Urgent care visit (this does not necessarilly include Emergency and never includes Hospital care). The percentages the the Carrier pays goes as follows: for Platinum - 90%, for Gold - 80%, for Silver - 70%, and for Bronze - 60%.',
              value: ''
            },
          ]
        },
      },
    },
    networkType: {
      booleans: [
        {
          key: 'POS',
          title: 'POS (Point Of Service)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'HMO',
          title: 'HMO (Health Maintenance Organization)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'PPO',
          title: 'PPO (Preferred Provider Organization)',
          dataType: 'boolean',
          value: ''
        },
        {
          key: 'EPO',
          title: 'EPO (Exclusive Provider Organization)',
          dataType: 'boolean',
          value: ''
        },
      ]
    },
    effectiveDates: {
      dates: [
        {
          key: 'start',
          title: 'Start',
          dataType: 'date',
          value: ''
        },
        {
          key: 'end',
          title: 'End',
          dataType: 'date',
          value: ''
        },
      ]
    },
    deductible: {
      inNetwork: {
        numbers: [
          {
            key: 'individual',
            title: 'Individual',
            dataType: 'number',
            info: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is one person on the plan and you stay In-Network, per year. After the deductible is paid, the Coinsurance starts at the percentage designated by the medal (Platinum, Gold, etc.) or as mentioned in the policy.',
            value: ''
          },
          {
            key: 'family',
            title: 'Family',
            dataType: 'number',
            info: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is more than one person on the plan and you stay In-Network, per year. After the deductible is paid, the Coinsurance starts at the percentage designated by the medal (Platinum, Gold, etc.) or as mentioned in the policy.',
            value: ''
          },
        ],
      },
      outOfNetwork: {
        numbers: [
          {
            key: 'individual',
            title: 'Individual',
            dataType: 'number',
            info: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is one person on the plan and you go Out-of-Network, per year. After the deductible is paid, the Out-of-Network Coinsurance starts, which might be the same percentage as In-Network.',
            value: ''
          },
          {
            key: 'family',
            title: 'Family',
            dataType: 'number',
            info: 'This is the deductible - the amount that is covered by you before the insurance starts (except for Copays) - if there is more than one person on the plan and you go Out-of-Network, per year. After the deductible is paid, the Out-of-Network Coinsurance starts, which might be the same percentage as In-Network.',
            value: ''
          },
        ],
      },
    },
    maxOut: {
      inNetwork: {
        numbers: [
          {
            key: 'individual',
            title: 'Individual',
            dataType: 'number',
            info: 'This is the Maximum that you will pay if there is one person on the plan and you stay In-Network, per year. There might be a cap but it is usually $1,000,000 or more.',
            value: ''
          },
          {
            key: 'family',
            title: 'Family',
            dataType: 'number',
            info: 'This is the Maximum that you will pay if there is more than one person on the plan and you stay In-Network, per year. There might be a cap but it is usually $1,000,000 or more.',
            value: ''
          },
        ],
      },
      outOfNetwork: {
        numbers: [
          {
            key: 'individual',
            title: 'Individual',
            dataType: 'number',
            info: 'This is the Maximum that you will pay if there is one person on the plan and you go Out-of-Network, per year. There might be a cap but it is usually $1,000,000 or more.',
            value: ''
          },
          {
            key: 'family',
            title: 'Family',
            dataType: 'number',
            info: 'This is the Maximum that you will pay if there is more than one person on the plan and you go Out-of-Network, per year. There might be a cap but it is usually $1,000,000 or more.',
            value: ''
          },
        ],
      }
    },
    copay: {
      inNetwork: {
        numbers: [
          {
            key: 'routineVisit',
            title: 'Routine visit',
            dataType: 'number',
            info: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a Primary Care Physcian (PCP) visit, In-Netowrk. This might include some tests and some small procdures but not necessarily.',
            value: ''
          },
          {
            key: 'specialist',
            title: 'Specialist',
            dataType: 'number',
            info: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to a Specialist, In-Netowrk. This does not necessarily include any additional procedures the specialist performs, which you will need to meet the In-Netork deductible for in order to receive more help from insurance.',
            value: ''
          },
          {
            key: 'urgentCare',
            title: 'Urgent care',
            dataType: 'number',
            info: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to Urgent care, In-Netowrk. This does not necessarily include any additional procedures, which you will need to meet the In-Netork deductible for in order to receive more help from insurance.',
            value: ''
          },
        ],
      },
      outOfNetwork: {
        numbers: [
          {
            key: 'routineVisit',
            title: 'Routine visit',
            dataType: 'number',
            info: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a Primary Care Physcian (PCP) visit, Out-of-Netowrk. This might include some tests and some small procdures but not necessarily.',
            value: ''
          },
          {
            key: 'specialist',
            title: 'Specialist',
            dataType: 'number',
            info: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to a Specialist, Out-of-Netowrk. This does not necessarily include any additional procedures the specialist performs, which you will need to meet the Out-of-Netork deductible for in order to receive more help from insurance.',
            value: ''
          },
          {
            key: 'urgentCare',
            title: 'Urgent care',
            dataType: 'number',
            info: 'This amount disregards the deductible and is the agreed upon amount that you will pay for a visit to Urgent care, Out-of-Netowrk. This does not necessarily include any additional procedures, which you will need to meet the Out-of-Netork deductible for in order to receive more help from insurance.',
            value: ''
          },
        ],
      }
    },
    presCoverage: {
      numbers: [
        {
          key: 'tier1',
          title: 'Tier 1 Copay',
          dataType: 'number',
          value: ''
        },
        {
          key: 'tier2',
          title: 'Tier 2 Copay',
          dataType: 'number',
          value: ''
        },
        {
          key: 'tier3',
          title: 'Tier 3 Copay',
          dataType: 'number',
          value: ''
        },
        {
          key: 'tier4',
          title: 'Tier 4 Copay',
          dataType: 'number',
          value: ''
        },
        {
          key: 'tier5',
          title: 'Tier 5 Copay',
          dataType: 'number',
          value: ''
        },
        {
          key: 'tier6',
          title: 'Tier 6 Copay',
          dataType: 'number',
          value: ''
        },
      ]
    },
    dentalPlan: {
      strings: [
        {
          key: 'firstName',
          title: 'First name',
          dataType: 'string',
          value: ''
        },
        {
          key: 'lastName',
          title: 'Last name',
          dataType: 'string',
          value: ''
        },
        {
          key: 'streetAddress',
          title: 'Street address',
          dataType: 'string',
          value: ''
        },
        {
          key: 'city',
          title: 'City',
          dataType: 'string',
          value: ''
        },
        {
          key: 'state',
          title: 'State',
          dataType: 'string',
          value: ''
        },
        {
          key: 'website',
          title: 'Website',
          dataType: 'string',
          value: ''
        },
        {
          key: 'elaborate',
          title: 'Elaborate',
          dataType: 'string',
          value: ''
        },
      ],
      numbers: [
        {
          key: 'phoneNumber',
          title: 'Phone number',
          dataType: 'phoneNumber',
          value: ''
        },
      ]
    },
    visionPlan: {
      strings: [
        {
          key: 'firstName',
          title: 'First name',
          dataType: 'string',
          value: ''
        },
        {
          key: 'lastName',
          title: 'Last name',
          dataType: 'string',
          value: ''
        },
        {
          key: 'streetAddress',
          title: 'Street address',
          dataType: 'string',
          value: ''
        },
        {
          key: 'city',
          title: 'City',
          dataType: 'string',
          value: ''
        },
        {
          key: 'state',
          title: 'State',
          dataType: 'string',
          value: ''
        },
        {
          key: 'website',
          title: 'Website',
          dataType: 'string',
          value: ''
        },
        {
          key: 'elaborate',
          title: 'Elaborate',
          dataType: 'string',
          value: ''
        },
      ],
      numbers: [
        {
          key: 'phoneNumber',
          title: 'Phone number',
          dataType: 'phoneNumber',
          value: ''
        },
      ]
    },
  }
}


