import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { createBrowserHistory } from 'history';
import { useHistory } from 'react-router-dom';
import { VEHICLE_CMS_DATA } from './vehicle.cms';
import VehicleSchema from './vehicle.validation.schema';
import VehicleInfo from '../../../components/quotes/vehicle/vehicle-info.component';
import VehicleSpecs from '../../../components/quotes/vehicle/vehicle-specs.component';
import EditVehicles from '../../../components/quotes/vehicle/vehicle-editing.component';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startLoadingVehicleMakeFromDB,
  startFetchingAllVehiclesFromDB,
  setIsSubmitted,
  startVehicleSave,
  startVehicleUpdate,
  startVehicleInQuoteSave,
  startVehicleInQuoteUpdate,
  toggleVehicleInfoEdit,
  toggleVehicleForm,
} from '../../../redux/quotes/vehicle/vehicle.actions';
import {
  selectMakes,
  selectReset,
  selectVehicles,
  selectIsSubmitted,
  selectVehicleById,
  selectShowVehicleForm,
  selectShowVehicleInfoEdit,
} from '../../../redux/quotes/vehicle/vehicle.selectors';
import ErrorMessages from '../../../components/error-message/error-messages.component';
import {
  setVehicleFormData,
} from '../../../redux/requested-quotes/vehicle/vehicle.actions';
import {
  selectVehicleFormData,
} from '../../../redux/requested-quotes/vehicle/vehicle.selectors';
import {
  setCombinedFormData,
} from '../../../redux/requested-quotes/combine-coverages/combine-coverages.actions';
import {
  selectCombinedFormData,
} from '../../../redux/requested-quotes/combine-coverages/combine-coverages.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import {
  scrollIntoViewForms
} from '../../../components/auto-scrolling/scroll-into-view-forms.utils';

const VehicleQuote = ({
  values,
  touched,
  errors,
  reset,
  resetForm,
  handleSubmit,
  handleChange,
  isSubmitting,
  makes,
  vehicle,
  primeMember,
  currentUser,
  isSubmitted,
  setIsSubmitted,
  showVehicleInfoEdit,
  showVehicleForm,
  toggleVehicleForm,
  toggleVehicleInfoEdit,
  startFetchingPrimeMemberFromDB,
  startLoadingVehicleMakeFromDB,
}) => {
  const formikProps = { values, handleChange, touched, errors, reset };

  const {
    heading,
    subHeading,
    form: { button },
  } = VEHICLE_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const vehicleP = 'household-vehicle-information';
  const agentBid = 'agent-bids';

  const selection = document.getElementById('vehicle-slt');
  const selectionHeight =
    selection
      ? selection.getBoundingClientRect().height + 60
      : '';

  useEffect(() => {
    scrollIntoViewForms(
      vehicleP,
      selectionHeight,
      showVehicleForm,
      showVehicleInfoEdit,
    )
  }, [
    vehicleP,
    selectionHeight,
    showVehicleForm,
    showVehicleInfoEdit,
  ])

  useEffect(() => {
    if (path !== vehicleP && isSubmitted) {
      setIsSubmitted(false);
      setTimeout(() => {
        startFetchingPrimeMemberFromDB(currentUser.member);
        window.scrollTo({
          top: selectionHeight,
          behavior: 'smooth'
        });
      }, 500)
    }
  }, [
    history,
    path,
    vehicleP,
    selectionHeight,
    isSubmitted, 
    setIsSubmitted,
    currentUser.member,
    startFetchingPrimeMemberFromDB,
  ])

  useEffect(() => {
    reset && resetForm();
  }, [reset, resetForm]);

  useEffect(() => {
    if (!primeMember._id)
    startFetchingPrimeMemberFromDB(currentUser.member);
  }, [
    primeMember,
    currentUser.member,
    startFetchingPrimeMemberFromDB
  ]);

  useEffect(() => {
    if (makes.length === 0) {
      startLoadingVehicleMakeFromDB();
    }
  }, [makes, startLoadingVehicleMakeFromDB]);

  return (
    <section>
      <Container className='px-0'>
        {path === vehicleP ? (
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
        ) : null}
        <FormikForm noValidate>
          
          {showVehicleInfoEdit ? (
            <EditVehicles {...formikProps} />
          ) : null }
            
          {showVehicleForm ? (
            <>
              <VehicleSpecs {...formikProps} />
              <VehicleInfo {...formikProps} />
              <div className='float-right'>
                <ErrorMessages errors={errors} />
                {path !== agentBid ? (
                  <div className='float-right'>
                    {path !== vehicleP ? (
                      <Button
                        variant='outline-danger'
                        className='mr-3'
                        onClick={() => {
                          toggleVehicleForm(false);
                          toggleVehicleInfoEdit(false);
                        }}
                      >
                        {button.cancel}
                      </Button>
                    ) : null}
                    {vehicle._id ? (
                      <Button
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        {button.update}
                      </Button>
                    ) : (
                      <Button
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                      >
                        {button.save}
                      </Button>
                    )}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </FormikForm>
      </Container>
    </section>
  );
};

const history = createBrowserHistory();
const path = history.location.pathname.replace(/\//g, '');
const combinedQP = 'household-combined-quotes';
const vehicleQP = 'household-vehicle-quotes';
const vehicleP = 'household-vehicle-information';

const setVehiclesValue = (data, values) => {
  if (
    data.vehicles &&
    data.vehicles.length !== 0
  ) {
    for (let i = 0; i < data.vehicles.length; i++) {
      let dv = data.vehicles[i].value.split(' ');
      let vl = dv.length;

      if (dv[vl - 1] === values.vehicleId) {
        data.vehicles.splice(i, 1);
      }
    }
    data.vehicles.push(
      {
        value: `ownership: ${values.ownership}, details: ${values.make}, ${values.model}, ${values.vin}, id: ${values.vehicleId}`,
        label: `${values.make}, ${values.model}, ${values.vin}`
      }
    )
  }
  if (
    data.vehicles &&
    data.vehicles.length === 0
  ) {
    data.vehicles.push(
      {
        value: `ownership: ${values.ownership}, details: ${values.make}, ${values.model}, ${values.vin}, id: ${values.vehicleId}`,
        label: `${values.make}, ${values.model}, ${values.vin}`
      }
    )

  }
  return data;
}

const FormikVehicleQuote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ vehicle, currentUser }) => ({
    addVehicle: '',
    primeMemberId: currentUser ? currentUser.member : '',
    vehicleId: vehicle._id ? vehicle._id : '',
    vin: vehicle.specs ? vehicle.specs.vin : '',
    year: vehicle.specs ? vehicle.specs.year : '',
    make: vehicle.specs ? vehicle.specs.make : '',
    model: vehicle.specs ? vehicle.specs.model : '',
    subModel: vehicle.specs ? vehicle.specs.subModel : '',
    ownership: vehicle.ownership
      ? (vehicle.ownership.finance && 'finance') ||
      (vehicle.ownership.lease && 'lease') ||
      (vehicle.ownership.own && 'own')
      : '',
    annualMileage: vehicle.annualMileage ? vehicle.annualMileage : '',
    primaryUse: vehicle.usage ? vehicle.usage : [],
    parking: vehicle.parking ? vehicle.parking : [],
    additionalInformation: vehicle.additionalInfo ? vehicle.additionalInfo : '',
  }),
  validationSchema: VehicleSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: {
      vehicleData,
      vehicle,
      combinedData,
      setVehicleFormData,
      startVehicleSave,
      startVehicleUpdate,
      setCombinedFormData,
      startVehicleInQuoteSave,
      startVehicleInQuoteUpdate,
      toggleVehicleForm,
      setIsSubmitted,
      setShowVehicleInfo,
      toggleVehicleInfoEdit,
    },
    resetForm,
    setSubmitting
    }
  ) => {
    setSubmitting(true);

    if (path === vehicleP) {
      if (
        vehicle &&
        vehicle._id 
      ) {
        startVehicleUpdate(vehicle._id, values);
      } else {
        startVehicleSave(values);
      }
    }

    if (path !== vehicleP) {
      if (
        vehicle &&
        vehicle._id 
      ) {
        startVehicleInQuoteUpdate(vehicle._id, values);
      } else {
        startVehicleInQuoteSave(values);
      }
    }

    resetForm();
    setSubmitting(false);

    setIsSubmitted(true);
    toggleVehicleForm(false);
    toggleVehicleInfoEdit(false);
    
    if (setShowVehicleInfo) {
      setShowVehicleInfo(false);
    }
    
    if (path === vehicleQP) {
      let vArr = setVehiclesValue(vehicleData, values);
      setVehicleFormData(vArr);
    }

    if (path === combinedQP) {
      let vArr = setVehiclesValue(combinedData, values);
      setCombinedFormData(vArr);
    }
  },
})(VehicleQuote);

const mapStateToProps = createStructuredSelector({
  makes: selectMakes,
  vehicles: selectVehicles,
  vehicle: selectVehicleById,
  currentUser: selectUser,
  reset: selectReset,
  isSubmitted: selectIsSubmitted,
  vehicleData: selectVehicleFormData,
  combinedData: selectCombinedFormData,
  primeMember: selectPrimeMember,
  showVehicleForm: selectShowVehicleForm,
  showVehicleInfoEdit: selectShowVehicleInfoEdit,
});

export default withRouter(
  connect(mapStateToProps, {
    startLoadingVehicleMakeFromDB,
    startFetchingAllVehiclesFromDB,
    startVehicleSave,
    startVehicleUpdate,
    startVehicleInQuoteSave,
    startVehicleInQuoteUpdate,
    setVehicleFormData,
    setCombinedFormData,
    setIsSubmitted,
    startFetchingPrimeMemberFromDB,
    toggleVehicleInfoEdit,
    toggleVehicleForm,
  })(FormikVehicleQuote)
);
