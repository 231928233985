import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { MEMBER_CMS_DATA } from '../member/member.cms';
import MemberSchema from '../member/member.validation.schema';
import { useHistory } from 'react-router-dom';
import AddMembers
  from '../../components/household-members/member-adding.component';
import BusinessDetails
  from '../../components/member/member-business-details.component';
import Education
  from '../../components/member/member-education.component';
import Identification
  from '../../components/member/member-identification.component';
import LegalStatus
  from '../../components/member/member-legal-status.component';
import PersonalDetails
  from '../../components/member/member-personal-details.component';
import WorkDetails
  from '../../components/member/member-work-details.component';
import MemberFelonyHistory
  from '../../components/quotes/member-history/member-felony-history.component';
import { calculateAge } from '../../redux/member/member.utils';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  startFetchingPrimeMemberFromDB, 
  startLoadingOccupationsFromDB,
  startLoadingStatesFromDB,
  startMemberSave,
  startMemberUpdate,
  startMemberQuoteSave,
  startMemberQuoteUpdate,
  toggleMemberTypes,
  toggleMemberForm,
  setIsMember,
  toggleMemberInfoEdit,
  setIsSubmitted,
} from '../../redux/member/member.actions';
import {
  selectReset,
  selectStates,
  selectIsUpdated,
  selectMember,
  selectIsMember,
  selectPrimeMember,
  selectOccupations,
  selectIsSubmitted,
  selectShowMemberForm,
  selectShowMemberTypes,
  selectShowMemberInfoEdit,
} from '../../redux/member/member.selectors';
import {
  selectMemberHistoryById
} from '../../redux/quotes/member-history/member-history.selectors';
import ErrorMessages
  from '../../components/error-message/error-messages.component';

import loadable from '@loadable/component';

const ContactInfo = loadable(() => import('../../components/member/member-contact-info.component'));
  
const HouseholdMembers = ({
  values,
  touched,
  errors,
  reset,
  resetForm,
  isSubmitting,
  handleSubmit,
  handleChange,
  states,
  occupations,
  isMember,
  setIsMember,
  showMemberTypes,
  showMemberForm,
  startLoadingStatesFromDB,
  startLoadingOccupationsFromDB,
}) => {
  
  const {
    heading,
    subHeading,
    form: { button },
  } = MEMBER_CMS_DATA;

  const formikProps = { values, handleChange, touched, errors, reset };

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');

  const [isAdult, setIsAdult] = useState(false);

  if (
    path.includes('member') ||
    path.includes('household')
  ) {
    if (!isMember) {
      setIsMember(true);
    }
  }
  
  useEffect(() => {
    reset && resetForm();
  }, [reset, resetForm]);

  useEffect(() => {
    if (states.length === 0) {
      startLoadingStatesFromDB();
    }
  }, [states, startLoadingStatesFromDB]);

  useEffect(() => {
    if (occupations.length === 0) {
      startLoadingOccupationsFromDB();
    }
  }, [occupations, startLoadingOccupationsFromDB]);

  useEffect(() => {
    let dt;
    if (values.dateOfBirth) {
      dt = values.dateOfBirth;
      dt.length !== 0
        ? calculateAge(dt) >= 15
          ? setIsAdult(true)
          : setIsAdult(false)
        : setIsAdult(false);
    }
  }, [values.dateOfBirth, setIsAdult]);

  return (
    <section>
      <Container className='px-0'>
        <header className='py-4'>
          <h2>{heading.myHousehold}</h2>
          <p>{subHeading.myHousehold}</p>
        </header>

        <FormikForm noValidate>
          {showMemberTypes ? (
            <AddMembers {...formikProps} />
          ) : null} 
            
          {showMemberForm ? (
            <>
              <PersonalDetails {...formikProps} />
              <ContactInfo {...formikProps} />
              {isAdult ? <Identification {...formikProps} /> : null}
              <LegalStatus {...formikProps} />
              <WorkDetails {...formikProps} />
              <BusinessDetails {...formikProps} />
              <Education {...formikProps} />
              <MemberFelonyHistory {...formikProps} />
              <div className='float-right'>
                <ErrorMessages errors={errors} />
                <div className='float-right'>
                  {values.HMemberId ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                      {button.update}
                    </Button>
                  ) : !values.HMemberId ? (
                    <Button
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {button.save}
                    </Button>
                  ) : null}
                </div>
              </div>
            </>
          ) : null} 
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikHouseholdMember = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({
    currentUser,
    HMember,
    primeMember,
    memberHistoryById
  }) => ({
    HMemberType: '',
    householdMembers: HMember.householdMembers ? HMember.householdMembers : [],
    propertyPolicies: HMember.propertyPolicies ? HMember.propertyPolicies : [],
    firstName: HMember.details ? HMember.details.firstName : '',
    middleName: HMember.details ? HMember.details.middleName : '',
    lastName: HMember.details ? HMember.details.lastName : '',
    dateOfBirth:
      HMember.details && HMember.details.dateOfBirth 
        ? HMember.details.dateOfBirth
        : '',
    gender:
      HMember.details && HMember.details.male
        ? 'male'
        : HMember.details && HMember.details.female
          ? 'female'
          : HMember.details && HMember.details.otherGenders
            ? 'other'
            : '',
    memberType: HMember.type 
      ? (HMember.type.self && 'self') ||
      (HMember.type.spouse && 'spouse') ||
      (HMember.type.partner && 'partner') ||
      (HMember.type.child && 'child') ||
      (HMember.type.adoptedChild && 'adoptedChild') ||
      (HMember.type.parent && 'parent') ||
      (HMember.type.sibling && 'sibling') ||
      (HMember.type.careGiver && 'careGiver') ||
      (HMember.type.stepChild && 'stepChild') ||
      (HMember.type.transferStudent && 'transferStudent')
      : '',
    email:
      HMember.details
        ? HMember.details.email
        : primeMember && primeMember.details
          ? primeMember.details.email
          : '',
    mobile:
      HMember.details && HMember.details.mobile 
        ? HMember.details.mobile
        : primeMember.details && primeMember.details.mobile 
          ? primeMember.details.mobile
          : '',
    landline:
      HMember.details && HMember.details.landline 
        ? HMember.details.landline
        : primeMember.details && primeMember.details.landline 
          ? primeMember.details.landline
          : '',
    street:
      HMember.address && HMember.address.streetAddress
        ? HMember.address.streetAddress
        : primeMember.address && primeMember.address.streetAddress
          ? primeMember.address.streetAddress
          : '',
    city:
      HMember.address && HMember.address.city
        ? HMember.address.city
        : primeMember.address && primeMember.address.city
          ? primeMember.address.city
          : '',
    zipCode:
      HMember.address && HMember.address.zipCode 
        ? HMember.address.zipCode
        : primeMember.address && primeMember.address.zipCode 
          ? primeMember.address.zipCode
          : '',
    state:
      HMember.address
        ? HMember.address.state
        : primeMember.address && primeMember.address.state
          ? primeMember.address.state
          : '',
    documentType: HMember.identification 
      ? (HMember.identification.driverPermit && 'driversPermit') ||
      (HMember.identification.driversLicense && 'driversLicense') ||
      (HMember.identification.passport && 'passport') ||
      (HMember.identification.stateID && 'stateID')
      : '',
    documentNumber: HMember.identification ? HMember.identification.number : '',
    expiration:
      HMember.identification && HMember.identification.expirationDate 
        ? HMember.identification.expirationDate
        : '',
    documentIssueState: HMember.identification
      ? HMember.identification.state
      : '',
    legalDocumentType: HMember.legalStatus
      ? (HMember.legalStatus.citizen && 'citizen') ||
      (HMember.legalStatus.alienRegistration && 'alienRegistration') ||
      (HMember.legalStatus.visa && 'visa')
      : '',
    legalDocumentNumber: HMember.legalStatus
      ? HMember.legalStatus.ssn ||
      HMember.legalStatus.alienRegistrationNumber ||
      HMember.legalStatus.uscisNumber
      : '',
    occupations:
      HMember.occupations && HMember.occupations.length !== 0
        ? HMember.occupations
        : [{ occupation: '' }],
    annualIncome:
      HMember.annualIncome && HMember.annualIncome 
        ? HMember.annualIncome
        : '',
    militaryServiceType: HMember.militaryService
      ? (HMember.militaryService.isDirectlyRelatedTo &&
        'isDirectlyRelatedTo') ||
      (HMember.militaryService.isInReserves && 'isInReserves') ||
      (HMember.militaryService.isServing && 'isServing') ||
      (HMember.militaryService.isVeteran && 'isVeteran') ||
      (HMember.militaryService.none && '')
      : '',
    businessName: HMember.business ? HMember.business.name : '',
    businessOwnership: HMember.business
      ? HMember.business.isOwner
        ? 'true'
        : 'false'
      : '',
    businessStreet:
      HMember.business && HMember.business.address
        ? HMember.business.address.streetAddress
        : '',
    businessCity:
      HMember.business && HMember.business.address && HMember.business.address.city
        ? HMember.business.address.city
        : '',
    businessZipCode:
      HMember.business &&
        HMember.business.address &&
        HMember.business.address.zipCode 
        ? HMember.business.address.zipCode
        : '',
    businessState:
      HMember.business && HMember.business.address
        ? HMember.business.address.state
        : '',
    acquisitions:
      HMember.education && HMember.education.acquisitions.length 
        ? HMember.education.acquisitions
        : [],
    degreeType: HMember.education ? HMember.education.degreeType : '',
    felonyStatus: HMember.felonyStatus
      ? HMember.felonyStatus.hasBeenConvicted
        ? 'true'
        : 'false'
      : '',
    felonyReason: HMember.felonyStatus
      ? HMember.felonyStatus.reasonForConviction
      : '',
    primeMemberId: currentUser.member ? currentUser.member : '',
    HMemberId: HMember && HMember._id ? HMember._id : '',
    profileId: currentUser ? currentUser.profile : '',
    agentId: '',
  }),
  validationSchema: MemberSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: {
      HMember,
      startMemberSave,
      startMemberUpdate,
      toggleMemberForm,
      setIsSubmitted,
      setShowMemberInfo,
    },
      resetForm,
      setSubmitting
    }
  ) => {

    setSubmitting(true);

    if (HMember && HMember._id) {
      startMemberUpdate(HMember._id, values);
    } else {
      startMemberSave(values);
    }
    
    resetForm();
    setSubmitting(false);
    
    setIsSubmitted(true);
    toggleMemberForm(false);
    setShowMemberInfo(false);
  },
})(HouseholdMembers);

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  HMember: selectMember,
  primeMember: selectPrimeMember,
  memberHistoryById: selectMemberHistoryById,
  states: selectStates,
  occupations: selectOccupations,
  reset: selectReset,
  isSubmitted: selectIsSubmitted,
  isUpdated: selectIsUpdated,
  isMember: selectIsMember,
  showMemberForm: selectShowMemberForm,
  showMemberTypes: selectShowMemberTypes,
  showMemberInfoEdit: selectShowMemberInfoEdit,
});

export default withRouter(
  connect(mapStateToProps, {
    setIsMember,
    setIsSubmitted,
    startMemberSave,
    startMemberUpdate,
    startMemberQuoteSave,
    startMemberQuoteUpdate,
    toggleMemberTypes,
    toggleMemberForm,
    toggleMemberInfoEdit,
    startLoadingStatesFromDB,
    startFetchingPrimeMemberFromDB,
    startLoadingOccupationsFromDB,
  })(FormikHouseholdMember)
);
