const authActionTypes = {
  CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',
  START_REGISTRATION: 'START_REGISTRATION',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',
  START_ACCOUNT_ACTIVATION: 'START_ACCOUNT_ACTIVATION',
  ACCOUNT_ACTIVATION_SUCCESS: 'ACCOUNT_ACTIVATION_SUCCESS',
  ACCOUNT_ACTIVATION_FAILURE: 'ACCOUNT_ACTIVATION_FAILURE',
  START_LOGIN: 'START_LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  START_FORGOT_PASSWORD: 'START_FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  START_RESET_PASSWORD: 'START_RESET_PASSWORD',
  SET_IS_SUBMITTED: 'SET_IS_SUBMITTED',
  SET_IS_REFRESH: 'SET_IS_REFRESH',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  FETCH_USERS_FROM_DB_START: 'FETCH_USERS_FROM_DB_START',
  FETCH_USERS_FROM_DB_SUCCESS: 'FETCH_USERS_FROM_DB_SUCCESS',
  FETCH_USERS_FROM_DB_FAILURE: 'FETCH_USERS_FROM_DB_FAILURE',
};

export default authActionTypes;
