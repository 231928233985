export const MEMBER_PROFILE_DASHBOARD_CMS_DATA = {
  initial: {
    heading: 'My Profile',
    subHeading: `Enter, view, and edit information to speed up your future Quotes Requests. Keep this current to make periodic shopping easier.`,
    cards: [
      {
        title: `My`,
        title2: `Information`,
        text: ``,
        goto: `/member-information`,
      },
      {
        title: `My`,
        title2: `Family`,
        text: ``,
        goto: `/household-members-information`,
      },
      {
        title: `My Family`,
        title2: `Health`,
        text: ``,
        goto: `/household-health-information`,
      },
      {
        title: `My`,
        title2: `Properties`,
        text: ``,
        goto: `/household-properties-information`,
      },
      {
        title: `My`,
        title2: `Vehicles`,
        text: ``,
        goto: `/household-vehicle-information`,
      },
      {
        title: `My`,
        title2: `Animals`,
        text: ``,
        goto: `/household-animals-information`,
      },
    ],
  },
};