import { HEALTH_QUOTE_CMS_DATA }
  from '../../../pages/quotes/health/health.cms';

export const convertHealthForDBSave = (data) => {
  const {
    form: {
      measurement,
      handicaps,
      tobaccoNicotine,
    },
  } = HEALTH_QUOTE_CMS_DATA;

  const weightChanges = measurement.weight.dropdown;
  const mentalHandicapCondition = handicaps.hasMentalHandicap.dropdown;
  const physicalDisabilityCondition = handicaps.hasPhysicalDis.dropdown;
  const tobaccoOrNicotine = tobaccoNicotine.tobaccoUsage.dropdown;
  const heightInFeet = measurement.height.dropdown.feet;
  const heightInInches = measurement.height.dropdown.inches;
  const stoppedUsageTobacco = tobaccoNicotine.hasStopped.dropdown;
  const cessationProgram = tobaccoNicotine.isInCessation.dropdown;
  const planToJoinCessation =
    tobaccoNicotine.hasPlansToJoinCes.dropdown;

  const payload = {};
  payload.body = {
    height: {
      feet: {
        zero: data.heightF !== null
          ? data.heightF === heightInFeet[0].value
          : false,
        one: data.heightF !== null
          ? data.heightF === heightInFeet[1].value
          : false,
        two: data.heightF !== null
          ? data.heightF === heightInFeet[2].value
          : false,
        three: data.heightF !== null
          ? data.heightF === heightInFeet[3].value
          : false,
        four: data.heightF !== null
          ? data.heightF === heightInFeet[4].value
          : false,
        five: data.heightF !== null
          ? data.heightF === heightInFeet[5].value
          : false,
        six: data.heightF !== null
          ? data.heightF === heightInFeet[6].value
          : false,
        seven: data.heightF !== null
          ? data.heightF === heightInFeet[7].value
          : false,
        eight: data.heightF !== null
          ? data.heightF === heightInFeet[8].value
          : false,
        nine: data.heightF !== null
          ? data.heightF === heightInFeet[9].value
          : false,
      },
      inches: {
        zero: data.heightI !== null
          ? data.heightI === heightInInches[0].value
          : false,
        one: data.heightI !== null
          ? data.heightI === heightInInches[1].value
          : false,
        two: data.heightI !== null
          ? data.heightI === heightInInches[2].value
          : false,
        three: data.heightI !== null
          ? data.heightI === heightInInches[3].value
          : false,
        four: data.heightI !== null
          ? data.heightI === heightInInches[4].value
          : false,
        five: data.heightI !== null
          ? data.heightI === heightInInches[5].value
          : false,
        six: data.heightI !== null
          ? data.heightI === heightInInches[6].value
          : false,
        seven: data.heightI !== null
          ? data.heightI === heightInInches[7].value
          : false,
        eight: data.heightI !== null
          ? data.heightI === heightInInches[8].value
          : false,
        nine: data.heightI !== null
          ? data.heightI === heightInInches[9].value
          : false,
        ten: data.heightI !== null
          ? data.heightI === heightInInches[10].value
          : false,
        eleven: data.heightI !== null
          ? data.heightI === heightInInches[11].value
          : false,
      },
    },
    weight: {
      current: data.weight ? data.weight : '',
      increased: data.weightChange === weightChanges[0].value,
      increasedBy:
        data.weightDiff ? data.weightDiff : 0,
      decreased: data.weightChange === weightChanges[1].value,
      decreasedBy:
        data.weightDiff ? data.weightDiff : 0,
      noChange: data.weightChange === weightChanges[2].value,
    },
  };

  payload.handicaps = {
    mentalHandicap: data.hasMentalHandicap === mentalHandicapCondition[0].value,
    physicalDisability:
      data.hasPhysicalDis === physicalDisabilityCondition[0].value,
    additionalInfo: data.handicapAddInfo ? data.handicapAddInfo : '',
  };

  payload.tobaccoNicotine = {
    status: data.tobaccoUsage
      ? data.tobaccoUsage === tobaccoOrNicotine[0].value
      : false,
    type: data.tobaccoUsageTypes ? data.tobaccoUsageTypes : [],
    inCessationProgram: data.isInCessation
      ? data.isInCessation === cessationProgram[0].value
      : false,
    plansToJoinCessationProgram: data.hasPlansToJoinCes
      ? data.hasPlansToJoinCes === planToJoinCessation[0].value
      : false,
    stoppedUsage: data.hasStopped
      ? data.hasStopped === stoppedUsageTobacco[0].value
      : false,
    stoppedUsageDate: data.stoppedDate !== null
      ? data.stoppedDate
      : '',
  };

  payload.preExistingConditions = {
    heart: data.hasHeartDis ? data.hasHeartDis : false,
    lung: data.hasLungDis ? data.hasLungDis : false,
    digestive: data.hasDigestiveDis ? data.hasDigestiveDis : false,
    genitoUrinary:
      data.hasGenUrinDis ? data.hasGenUrinDis : false,
    nervousSystem: data.hasMentalDis ? data.hasMentalDis : false,
    boneOrJoint: data.hasBoneOrJointDis ? data.hasBoneOrJointDis : false,
    vision: data.hasVisionIm ? data.hasVisionIm : false,
    hearing: data.hasHearingIm ? data.hasHearingIm : false,
    glandular: data.hasGlandDis ? data.hasGlandDis : false,
    hiv: data.isHIVPos ? data.isHIVPos : false,
    pregnancy: data.isPregnant ? data.isPregnant : false,
    expDelDate: data.expDelDate ? data.expDelDate : '',
    onMedication: data.isOnMed ? data.isOnMed : false,
    unexpFever: data.hasUnexpFever ? data.hasUnexpFever : false,
    studies: data.hadGotStudiesDone ? data.hadGotStudiesDone : false,
    surgery: data.hadSurgery ? data.hadSurgery : false,
    alcoholUsage:
      data.advisedAlcoholUsage ? data.advisedAlcoholUsage : false,
    drugAbuse: data.advisedDrugAbuse ? data.advisedDrugAbuse : false,
    AANAMember: data.isAANAMember ? data.isAANAMember : false,
    additionalInfo: data.preExistAddInfo ? data.preExistAddInfo : '',
  };

  payload.familyHistories = data.relationships;

  payload.additionalInfo = {
    reinstatementStatus: data.isReinst ? data.isReinst : false,
    hazardousActivity:
      data.engHazAct ? data.engHazAct : false,
    travelStatus: data.travelStatus ? data.travelStatus : false,
    pilotStatus: data.pilotStatus ? data.pilotStatus : false,
    drivingViolations:
      data.engDrivVi ? data.engDrivVi : false,
    illegalDrugs: data.hasIllDrugs ? data.hasIllDrugs : false,
    illness: data.hasIllness ? data.hasIllness : false,
    additionalInfo: data.addInfo ? data.addInfo : '',
  };

  payload.memberId = data.memberId ? data.memberId : '';
  payload.primeMemberId = data.primeMemberId ? data.primeMemberId : '';

  return payload;
};

