import { Field, FieldArray } from 'formik';
import React, { createRef, useEffect, useState } from 'react';
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import { selectOccupations } from '../../redux/agent/agent.selectors';
import Dropdown from '../dropdown/dropdown.component';
import ErrorMessage from '../error-message/error-message.component';

const ServicesProvidedTo = ({
  values,
  touched,
  errors,
  occupationsFromDB,
  reset,
}) => {
  const {
    form: {
      servicesProvidedTo: { title, description, occupation, dropdown },
    },
  } = AGENT_CMS_DATA;

  const [showOccupations, setShowOccupations] = useState(false);

  const occupationRef = createRef();

  let applyTo = values.applyTo;
  let occupations = values.occupations;

  useEffect(() => {
    reset && occupationRef.current.clear();
  }, [reset, occupationRef]);

  useEffect(() => {
    if (
      values.applyTo === 'specificOccupations' ||
      values.applyTo === 'militaryOccupations'
    ) {
      setShowOccupations(true);
    }
    if (
      values.applyTo === 'anyOne' ||
      values.applyTo === 'military'
    ) {
      setShowOccupations(false);
      values.occupations = [{occupation: ''}];
    }    
  }, [
    values.applyTo,
    values.occupations,
    setShowOccupations
  ]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={12} controlId='apply'>
              <Form.Label>{title}</Form.Label>
              <Dropdown
                name={'applyTo'}
                selected={applyTo}
                options={dropdown}
                touched={touched}
                errors={errors}
                values={values}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.applyTo}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {showOccupations ? (
            <Form.Row>
              <Form.Group as={Col} lg={12}>
                <FieldArray
                  name='occupations'
                  render={(arrayHelpers) => (
                    <div>
                      {occupations.map((o, i) => (
                        <div key={i}>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              lg={12}
                              controlId='occupations'
                            >
                              <Form.Label>{occupation}</Form.Label>
                              <InputGroup>
                                <Field name={`occupations[${i}].occupation`}>
                                  {({ field, form }) => (
                                    <Typeahead
                                      id={`select-occupation${i}`}
                                      ref={occupationRef}
                                      name={`occupations[${i}].occupation`}
                                      options={occupationsFromDB}
                                      selected={[occupations[i].occupation]}
                                      isValid={
                                        errors && errors.occupations && errors.occupations[i]
                                          ? touched.occupations[i].occupation &&
                                            !errors.occupations[i].occupation
                                          : touched.occupations && !errors.occupations
                                      }
                                      isInvalid={
                                        errors && errors.occupations && errors.occupations[i]
                                          ? !!errors.occupations[i].occupation
                                          : !!errors.occupations
                                      }
                                      onChange={(selectedOccupation) => {
                                        if (selectedOccupation.length !== 0) {
                                          form.setFieldValue(
                                            field.name,
                                            selectedOccupation[0]
                                          );
                                        }
                                      }}
                                      onInputChange={(text) => {
                                        form.setFieldValue(field.name, text);                                 
                                      }}
                                    />
                                  )}
                                </Field>
                                {i >= 0 && occupations.length !== 1 ? (
                                  <InputGroup.Prepend>
                                    <Button
                                      variant='secondary'
                                      onClick={() => {
                                        arrayHelpers.remove(i);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </InputGroup.Prepend>
                                ) : null}
                                <Form.Control.Feedback type='invalid'>
                                  <ErrorMessage
                                    name={`occupations[${i}].occupation`}
                                  ></ErrorMessage>
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} lg={12}>
                              {occupations.length - 1 === i ? (
                                <div className='float-right'>
                                  <Button
                                    variant='primary'
                                    onClick={() => {
                                      if (occupations[i].occupation !== '') {
                                        arrayHelpers.push({ occupation: '' });
                                      }
                                    }}
                                  >
                                    Add Another
                                  </Button>
                                </div>
                              ) : null}
                            </Form.Group>
                          </Form.Row>
                        </div>
                      ))}
                    </div>
                  )}
                ></FieldArray>
              </Form.Group>
            </Form.Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  occupationsFromDB: selectOccupations,
});

export default connect(mapStateToProps, {})(ServicesProvidedTo);
