const profileActionTypes = {
  FETCH_PROFILES_FROM_DB_START: 'FETCH_PROFILES_FROM_DB_START',
  FETCH_PROFILES_FROM_DB_SUCCESS: 'FETCH_PROFILES_FROM_DB_SUCCESS',
  FETCH_PROFILES_FROM_DB_FAILURE: 'FETCH_PROFILES_FROM_DB_FAILURE',
  FETCH_PROFILE_FROM_DB_START: 'FETCH_PROFILE_FROM_DB_START',
  FETCH_PROFILE_FROM_DB_SUCCESS: 'FETCH_PROFILE_FROM_DB_SUCCESS',
  FETCH_PROFILE_FROM_DB_FAILURE: 'FETCH_PROFILE_FROM_DB_FAILURE',
  START_PROFILE_SAVE: 'START_PROFILE_SAVE',
  START_PROFILE_UPDATE: 'START_PROFILE_UPDATE',
  START_RESET_PASSWORD_IN_PROFILE: 'START_RESET_PASSWORD_IN_PROFILE',
  RESET_PASSWORD_SUCCESS_IN_PROFILE: 'RESET_PASSWORD_SUCCESS_IN_PROFILE',
  RESET_PASSWORD_FAILURE_IN_PROFILE: 'RESET_PASSWORD_FAILURE_IN_PROFILE',
  SET_IS_SUBMITTED: 'SET_IS_SUBMITTED',
  RESET_FORM: 'RESET_FORM',
};

export default profileActionTypes;
