import React, { useState, useEffect } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectMember,
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import { 
  setClearHealthForm,
  toggleHealthFormView,
  startFetchingHealthFromDB,
} from '../../../redux/quotes/health/health.actions';
import {
  selectShowHealthForm,
} from '../../../redux/quotes/health/health.selectors';
import { HEALTH_QUOTE_CMS_DATA } from '../../../pages/quotes/health/health.cms';
import HealthFormModal
  from '../../../components/quotes/health/health-form-modal.component';

const MembersHealthEditing = ({
  currentUser,
  HFormRef,
  primeMember,
  showHealthForm,
  startFetchingHealthFromDB,
  startFetchingPrimeMemberFromDB,
}) => {
  const {
    heading,
    subHeading,
    form: { button },
  } = HEALTH_QUOTE_CMS_DATA;

  const [storeIds, setStoreIds] = useState([]);
  const [showHealthInfo, setShowHealthInfo] = useState(false);

  let allMembers = [];

  const getMemberType = (hm) => {
    if (hm.type) {
      for (let t in hm.type) {
        if (hm.type[t]) {
          return t;
        }
      }
    }
  }  

  const getAllMembers = (m) => {
    allMembers.push(m);
    if (
      m.householdMembers &&
      m.householdMembers.length > 0
    ) {
      for (let hm of m.householdMembers) {
        allMembers.push(hm);
      }
    }
    return allMembers;
  }

  const householdMembers = getAllMembers(primeMember);

  useEffect(() => {
    if (!primeMember._id) {
      startFetchingPrimeMemberFromDB(currentUser.member);
    }
  }, [startFetchingPrimeMemberFromDB, primeMember, currentUser])

  return (
    <section className='ie-form-scaffold'>
      <Container>
        <header className='py-4'>
          <h2>{heading}</h2>
          <p>{subHeading}</p>
        </header>
        <div className='mb-4 text-right' ref={HFormRef}>
          <Table
            bordered
            responsive
            className='shadow-sm p-3 bg-white rounded'
          >
            {householdMembers &&
              householdMembers.length !== 0 ? (
              <>
                <thead className='bg-light'>
                  <tr>
                    <th style={{ width: '6rem' }} className='text-left'>Relation</th>
                    <th style={{ width: '6rem' }}>First Name</th>
                    <th style={{ width: '7rem' }}>Middle Name</th>
                    <th style={{ width: '6rem' }}>Last Name</th>
                    <th style={{ width: '6rem' }}></th>
                  </tr>
                </thead>
                {householdMembers.map((hm, i) => (
                  <tbody key={i}>
                    <tr key={`${i}${i}`}>
                      {hm.type && hm.details ? (
                        <>
                          <td className='text-left'>
                            <p style={{ width: '6rem' }} className='m-0'>
                              {
                                getMemberType(hm).charAt(0).toUpperCase() + getMemberType(hm).slice(1)
                              }
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '6rem' }} className='m-0 float-right'>
                              {hm.details.firstName}
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '7rem' }} className='m-0 float-right'>
                              {hm.details.middleName}
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '6rem' }} className='m-0 float-right'>
                              {hm.details.lastName}
                            </p>
                          </td>
                          <td>
                            <p style={{ width: '6rem' }} className='m-0 float-right'>
                              <Button
                                variant={
                                  showHealthForm && (hm._id === storeIds[0]) 
                                    ? 'warning'
                                    : 'primary'
                                }
                                onClick={() => {
                                  if (hm.healthId) {
                                    startFetchingHealthFromDB(hm.healthId);
                                  }
                                  setShowHealthInfo(!showHealthInfo);
                                  setStoreIds([hm._id]);
                                }}
                              >
                                {button.update}                      
                              </Button>
                            </p>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  </tbody>
                ))}
              </>
            ) : null}
          </Table>
        </div>
        <HealthFormModal
          memberId={storeIds[0]}
          showHealthInfo={showHealthInfo}
          setShowHealthInfo={setShowHealthInfo}
        />
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  member: selectMember,
  primeMember: selectPrimeMember,
  showHealthForm: selectShowHealthForm,
});

export default connect(mapStateToProps, {
  setClearHealthForm,
  toggleHealthFormView,
  startFetchingHealthFromDB,
  startFetchingPrimeMemberFromDB,
})(MembersHealthEditing);