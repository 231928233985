import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const selectUser = createSelector([selectAuth], (auth) => auth.user);

export const selectUsers = createSelector([selectAuth], (auth) => auth.users);

export const selectIsUserLoggedIn = createSelector(
  [selectAuth],
  (auth) => auth.isAuthenticated && !!auth.token && auth.user.isActive
);

export const selectIsRegisteredUser = createSelector(
  [selectAuth],
  (auth) => auth.isAuthenticated && !auth.user.isActive
);

export const selectIsActiveUser = createSelector(
  [selectAuth],
  (auth) => auth.isAuthenticated && auth.user.isActive
);

export const selectIsSubmitted = createSelector(
  [selectAuth],
  (auth) => auth.isSubmitted
);

export const selectDisplayMessage = createSelector(
  [selectAuth],
  (auth) => auth.displayMessage
);

export const selectIsRefresh = createSelector(
  [selectAuth],
  (auth) => auth.isRefresh
);
