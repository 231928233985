import { HEALTH_QUOTE_CMS_DATA } from '../../../components/requested-quotes/health/health.cms';

export const convertHealthQuoteForDBSave = (data) => {
  const {
    form: {
      health: {
        dropdown: {
          healthPlan,
          medcSOrMedg,
          majorPlan,
          networkType,
          policyType,
          deductRange,
          maxOutOfPock,
          visionPlan,
          dentalPlan,
          active,
        }
      }
    }
  } = HEALTH_QUOTE_CMS_DATA;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    primeMemberInfo: data.primeMemberInfo ? data.primeMemberInfo : {},
    createdDate: new Date(),
    membersInfo: data.membersInfo ? data.membersInfo : [],
    members: data.members ? data.members : [],
    isLastStep: data.isLastStep ? data.isLastStep : false,
    isCometChatGr: data.isCometChatGr ? data.isCometChatGr : false,
    isMsgSent: data.isMsgSent ? data.isMsgSent : false,
    selAgentsByAI: data.selAgentsByAI ? data.selAgentsByAI : {},
    createdCometDate: data.createdCometDate ? data.createdCometDate : '',
    isCometExp: data.isCometExp ? data.isCometExp : false,
    isDeleteComet: data.isDeleteComet ? data.isDeleteComet : false,
    healthPlan: {
      majorMedical:
        data.healthPlan !== null
          ? data.healthPlan === healthPlan[0].value
          : false,
      medicare:
        data.healthPlan !== null
          ? data.healthPlan === healthPlan[1].value
          : false,
    },
    active:
      data.active !== null
        ? data.active === active[0].value
        : false,
    covPart: data.covPart ? data.covPart : [],
    medcSOrMedg: {
      g:
        data.medcSOrMedg !== null
          ? data.medcSOrMedg === medcSOrMedg[0].value
          : false,
      h:
        data.medcSOrMedg !== null
          ? data.medcSOrMedg === medcSOrMedg[1].value
          : false,
      j:
        data.medcSOrMedg !== null
          ? data.medcSOrMedg === medcSOrMedg[2].value
          : false,
      k:
        data.medcSOrMedg !== null
          ? data.medcSOrMedg === medcSOrMedg[3].value
          : false,
      l:
        data.medcSOrMedg !== null
          ? data.medcSOrMedg === medcSOrMedg[4].value
          : false,
      m:
        data.medcSOrMedg !== null
          ? data.medcSOrMedg === medcSOrMedg[5].value
          : false,
      n:
        data.medcSOrMedg !== null
          ? data.medcSOrMedg === medcSOrMedg[6].value
          : false,
    },
    majorPlan: {
      LT:
        data.majorPlan !== null
          ? data.majorPlan === majorPlan[0].value
          : false,
      ST:
        data.majorPlan !== null
          ? data.majorPlan === majorPlan[1].value
          : false,
      notSure:
        data.majorPlan !== null
          ? data.majorPlan === majorPlan[2].value
          : false,
    },
    networkType: {
      HMO:
        data.networkType !== null
          ? data.networkType === networkType[0].value
          : false,
      PPO:
        data.networkType !== null
          ? data.networkType === networkType[1].value
          : false,
      EPO:
        data.networkType !== null
          ? data.networkType === networkType[2].value
          : false,
      POS:
        data.networkType !== null
          ? data.networkType === networkType[3].value
          : false,
      notSure:
        data.networkType !== null
          ? data.networkType === networkType[4].value
          : false,
    },
    policyType: {
      catastrophic:
        data.policyType !== null
          ? data.policyType === policyType[0].value
          : false,
      bronze:
        data.policyType !== null
          ? data.policyType === policyType[1].value
          : false,
      silver:
        data.policyType !== null
          ? data.policyType === policyType[2].value
          : false,
      gold:
        data.policyType !== null
          ? data.policyType === policyType[3].value
          : false,
      platinum:
        data.policyType !== null
          ? data.policyType === policyType[4].value
          : false,
      notSure:
        data.policyType !== null
          ? data.policyType === policyType[5].value
          : false,
    },
    deductRange: {
      '500To1000':
        data.deductRange !== null
          ? data.deductRange === deductRange[0].value
          : false,
      '1000To1500':
        data.deductRange !== null
          ? data.deductRange === deductRange[1].value
          : false,
      '1500To2000':
        data.deductRange !== null
          ? data.deductRange === deductRange[2].value
          : false,
      '2000To2500':
        data.deductRange !== null
          ? data.deductRange === deductRange[3].value
          : false,
      '2500To3000':
        data.deductRange !== null
          ? data.deductRange === deductRange[4].value
          : false,
      '3000To4000':
        data.deductRange !== null
          ? data.deductRange === deductRange[5].value
          : false,
      higher:
        data.deductRange !== null
          ? data.deductRange === deductRange[6].value
          : false,
      notSure:
        data.deductRange !== null
          ? data.deductRange === deductRange[7].value
          : false,
    },
    maxOutOfPock: {
      '2000To3000':
        data.maxOutOfPock !== null
          ? data.maxOutOfPock === maxOutOfPock[0].value
          : false,
      '3000To4000':
        data.maxOutOfPock !== null
          ? data.maxOutOfPock === maxOutOfPock[1].value
          : false,
      '4000To5000':
        data.maxOutOfPock !== null
          ? data.maxOutOfPock === maxOutOfPock[2].value
          : false,
      '5000To6000':
        data.maxOutOfPock !== null
          ? data.maxOutOfPock === maxOutOfPock[3].value
          : false,
      '6000To7000':
        data.maxOutOfPock !== null
          ? data.maxOutOfPock === maxOutOfPock[4].value
          : false,
      '7000To8000':
        data.maxOutOfPock !== null
          ? data.maxOutOfPock === maxOutOfPock[5].value
          : false,
      higher:
        data.maxOutOfPock !== null
          ? data.maxOutOfPock === maxOutOfPock[6].value
          : false,
      notSure:
        data.deductRange !== null
          ? data.deductRange === deductRange[7].value
          : false,
    },
    visionPlan: {
      basic:
        data.visionPlan !== null
          ? data.visionPlan === visionPlan[0].value
          : false,
      preferred:
        data.visionPlan !== null
          ? data.visionPlan === visionPlan[1].value
          : false,
      notSure:
        data.visionPlan !== null
          ? data.visionPlan === visionPlan[2].value
          : false,
      none:
        data.visionPlan !== null
          ? data.visionPlan === visionPlan[3].value
          : false,
    },
    dentalPlan: {
      basic:
        data.dentalPlan !== null
          ? data.dentalPlan === dentalPlan[0].value
          : false,
      preferred:
        data.dentalPlan !== null
          ? data.dentalPlan === dentalPlan[1].value
          : false,
      notSure:
        data.dentalPlan !== null
          ? data.dentalPlan === dentalPlan[2].value
          : false,
      none:
        data.dentalPlan !== null
          ? data.dentalPlan === dentalPlan[3].value
          : false,
    },
  }

  return payload;
};

