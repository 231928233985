import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { COMPANION_ANIMAL_CMS_DATA } from './companion-animal.cms';
import companionAnimalSchema from './companion-animal.validation.schema';
import EditAnimals from '../../../components/quotes/companion-animal/animal-editing.component';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  setIsSubmitted,
  toggleAnimalForm,
  toggleAnimalInfoEdit,
  startCompanionAnimalSave,
  startCompanionAnimalUpdate,
  startCompanionAnimalInQuoteSave,
  startCompanionAnimalInQuoteUpdate,
} from '../../../redux/quotes/companion-animal/companion-animal.actions';
import {
  selectIsSubmitted,
  selectReset,
  selectAnimal,
  selectAnimals,
  selectShowAnimalForm,
  selectShowAnimalInfoEdit,
} from '../../../redux/quotes/companion-animal/companion-animal.selectors';
import {
  setAnimalFormData,
} from '../../../redux/requested-quotes/companion-animal/companion-animal.actions';
import {
  selectAnimalFormData,
} from '../../../redux/requested-quotes/companion-animal/companion-animal.selectors';
import {
  startFetchingPrimeMemberFromDB,
} from '../../../redux/member/member.actions';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import ErrorMessages from '../../../components/error-message/error-messages.component';
import {
  scrollIntoViewForms
} from '../../../components/auto-scrolling/scroll-into-view-forms.utils';

import loadable from '@loadable/component';

const GeneralDetails = loadable(() => import('../../../components/quotes/companion-animal/animal-details.component'));

const CompanionAnimalQuote = ({
  values,
  touched,
  errors,
  reset,
  resetForm,
  currentUser,
  isSubmitting,
  handleChange,
  handleSubmit,
  isSubmitted,
  setIsSubmitted,
  showAnimalForm,
  toggleAnimalForm,
  showAnimalInfoEdit,
  toggleAnimalInfoEdit,
  startFetchingPrimeMemberFromDB,
}) => {
  const {
    heading,
    subHeading,
    form: { button }
  } = COMPANION_ANIMAL_CMS_DATA;

  const formikProps = { values, handleChange, touched, errors, reset };

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const animalP = 'household-animals-information';
  const agentBid = 'agent-bids';

  const selection = document.getElementById('animal-slt');
  const selectionHeight =
    selection ?
      selection.getBoundingClientRect().height + 60
      : '';

  useEffect(() => {
    scrollIntoViewForms(
      animalP,
      selectionHeight,
      showAnimalForm,
      showAnimalInfoEdit,
    )
  }, [
    animalP,
    selectionHeight,
    showAnimalForm,
    showAnimalInfoEdit,
  ])

  useEffect(() => {
    reset && resetForm();
  }, [reset, resetForm]);

  useEffect(() => {
    if (path !== animalP && isSubmitted) {
      setIsSubmitted(false);
      setTimeout(() => {
        startFetchingPrimeMemberFromDB(currentUser.member);
        window.scrollTo({
          top: selectionHeight,
          behavior: 'smooth'
        });
      }, 500)
    }
  }, [
    path,
    history,
    animalP,
    selectionHeight,
    isSubmitted,
    setIsSubmitted,
    currentUser.member,
    startFetchingPrimeMemberFromDB,
  ])

  return (
    <section>
      <Container className='px-0'>
        {path === animalP ? (
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
        ) : null}
        <FormikForm noValidate>
          {showAnimalInfoEdit ? (
            <EditAnimals {...formikProps}/>
          ) : null}
          {showAnimalForm ? (
            <>
              <GeneralDetails {...formikProps} />
              {path !== agentBid ? (
                <div className='float-right'>
                  <ErrorMessages errors={errors}/>
                  <div className='float-right'>
                    {path !== animalP ? (
                      <Button
                        variant='outline-danger'
                        className='mr-3'
                        onClick={() => {
                          toggleAnimalForm(false);
                          toggleAnimalInfoEdit(false);
                        }}
                      >
                        {button.cancel}
                      </Button>
                    ) : null}
                    {values.animalId ? (
                      <Button disabled={isSubmitting} onClick={handleSubmit}>
                        {button.update}
                      </Button>
                    ) : !values.animalId ? (
                      <Button disabled={isSubmitting} onClick={handleSubmit}>
                        {button.save}
                      </Button>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikCompanionAnimalQuote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ animal, currentUser }) => ({
    addAnimal: '',
    primeMemberId: currentUser ? currentUser.member : '',
    animalId: animal._id ? animal._id : '',
    isHerd:
      animal.isHerd
        ? (animal.isHerd.yes && 'yes') ||
        (animal.isHerd.no && 'no') 
        : '',
    numOfHerds: animal.numOfHerds ? animal.numOfHerds : '',
    name: animal.name ? animal.name : '',
    dateOfBirth: animal.dateOfBirth ? animal.dateOfBirth : '',
    type: animal.type
      ? (animal.type.bird && 'bird') ||
      (animal.type.cat && 'cat') ||
      (animal.type.dog && 'dog') ||
      (animal.type.fish && 'fish') ||
      (animal.type.horse && 'horse') ||
      (animal.type.livestock && 'livestock') ||
      (animal.type.exotic && 'exotic') 
      : '',
    breed: animal.breedName ? animal.breedName : '',
    breedType: animal.breed
      ? (animal.breed.mixed && 'mixed') ||
      (animal.breed.pure && 'pure') ||
      (animal.breed.unsure && 'unsure') 
      : '',
    weight: animal.weight ? animal.weight : '',
    height: animal.height ? animal.height : '',
    length: animal.length ? animal.length : '',
    healthConcerns: animal.healthConcerns ? animal.healthConcerns : '',
  }),
  validationSchema: companionAnimalSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: {
      animal,
      animalData,
      setIsSubmitted,
      setAnimalFormData,
      toggleAnimalForm,
      setShowAnimalInfo,
      toggleAnimalInfoEdit,
      startCompanionAnimalSave,
      startCompanionAnimalUpdate,
      startCompanionAnimalInQuoteSave,
      startCompanionAnimalInQuoteUpdate,
    },
      resetForm,
      setSubmitting
    }
  ) => {
    const history = createBrowserHistory();
    const path = history.location.pathname.replace(/\//g, '');
    const animalP = 'household-animals-information';
    
    setSubmitting(true);

    if (path === animalP) {
      if (animal && animal._id) {
        startCompanionAnimalUpdate(animal._id, values);
      } else {
        startCompanionAnimalSave(values);
      }
    } else {
      if (animal && animal._id) {
        startCompanionAnimalInQuoteUpdate(animal._id, values);
      } else {
        startCompanionAnimalInQuoteSave(values);
      }
    }

    resetForm();
    setSubmitting(false);

    setIsSubmitted(true);
    toggleAnimalForm(false);
    toggleAnimalInfoEdit(false);
    
    if (setShowAnimalInfo) {
      setShowAnimalInfo(false);
    }

    if (path !== animalP) {
      if (animalData.animals && animalData.animals.length > 0) {
        for (let i = 0; i < animalData.animals.length; i++) {
          let da = animalData.animals[i].value.split(' ');
          let al = da.length;
          if (da[al - 1] === values.animalId) {
            animalData.animals.splice(i, 1);
          }
        }
        animalData.animals.push(
          {
            value: `Type: ${values.type}, Name: ${values.name}, id: ${values.animalId}`,
            label: `Type: ${values.type}, Name: ${values.name}`,
          }
        )
      }
      if (animalData.animals && animalData.animals.length === 0) {
        animalData.animals.push(
          {
            value: `Type: ${values.type}, Name: ${values.name}, id: ${values.animalId}`,
            label: `Type: ${values.type}, Name: ${values.name}`,
          }
        )
      }
      setAnimalFormData(animalData);
    }
  },
})(CompanionAnimalQuote);

const mapStateToProps = createStructuredSelector({
  reset: selectReset,
  animals: selectAnimals,
  animal: selectAnimal,
  primeMember: selectPrimeMember,
  currentUser: selectUser,
  isSubmitted: selectIsSubmitted,
  animalData: selectAnimalFormData,
  showAnimalForm: selectShowAnimalForm,
  showAnimalInfoEdit: selectShowAnimalInfoEdit,
});

export default withRouter(
  connect(mapStateToProps, {
    setIsSubmitted,
    setAnimalFormData,
    toggleAnimalForm,
    toggleAnimalInfoEdit,
    startCompanionAnimalSave,
    startCompanionAnimalUpdate,
    startCompanionAnimalInQuoteSave,
    startCompanionAnimalInQuoteUpdate,
    startFetchingPrimeMemberFromDB,
  })(FormikCompanionAnimalQuote)
);


