import profileActionTypes from './profile.types';
import authActionTypes from '../auth/auth.types';
import agentActionTypes from '../agent/agent.types';

const INITIAL_STATE = {
  profile: {},
  user: {},
  isAuthenticated: false,
  token: null,
  displayMessage: null,
  isSubmitted: false,
  reset: false,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case profileActionTypes.FETCH_PROFILE_FROM_DB_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case profileActionTypes.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    case profileActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case agentActionTypes.RESET_AGENT:
    case authActionTypes.LOGOUT:
      return {
        ...state,
        agent: {},
        profile: {},
        user: {},
        isAuthenticated: false,
        token: null,
        displayMessage: null,
        isSubmitted: false,
      };
    default:
      return state;
  }
};
export default profileReducer;
