export const MEMBER_DASHBOARD_CMS_DATA = {
  initial: {
    heading: 'My Dashboard',
    subHeading: `In which way can we make the pain of insurance Easier?`,
    cards: [
      {
        imageUrl: `./images/insurance.webp`,
        title: `Get Quotes`,
        text: `Choose from our growing selection of insurances to get the best quote from multiple Agents and thousands of possible Carriers.`,
        goto: '/member-quotes',
      },
      {
        imageUrl: `./images/archive.webp`,
        title: `View Quotes`,
        text: `After requesting quotes and communicating with the agents, this is where you can view and select from the quotes that the agents create.`,
        goto: '/member-view-quotes',
      },
      {
        imageUrl: `./images/policy.webp`,
        title: `My Policies`,
        text: `Store and reference all your insurance policies for a hassle free experience or get a policy with us and let an Agent enter it for you.`,
        goto: '/member-policies',
      },
      {
        imageUrl: `./images/gear.webp`,
        title: `My Profile`,
        text: `Enter, view, and edit information to speed up your future Quotes Requests. Keep this current to make periodic shopping Easier.`,
        goto: '/member-profile-dashboard',
      },
    ],
  },
};
