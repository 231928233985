import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import BussDetailsSchema
  from './validations/business-details.validation.schema';
import memberFormData from './form-data/member-data';
import { useHistory } from 'react-router-dom';
import BusinessDetails
  from '../../components/member/member-business-details.component';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  setFormData,
  startMemberUpdate,
} from '../../redux/member/member.actions';
import {
  selectReset,
  selectFormData,
  selectPrimeMember,
} from '../../redux/member/member.selectors';
import ErrorMessages
  from '../../components/error-message/error-messages.component';

const MemberBussDetails = ({
  values,
  touched,
  errors,
  reset,
  decrement,
  isSubmitting,
  handleSubmit,
  handleChange,
  setFormData,
}) => {
  const formikProps = { values, handleChange, touched, errors, reset };
  const {
    form: { button },
  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const MPath = 'member-information';

  useEffect(() => {
    if (values) {
      setFormData(values);
    }
  }, [
    values,
    setFormData,
  ])
  
  return (
    <section className='pb-4'>
      <Container className={path !== MPath ? 'px-0' : ''}> 
        <FormikForm noValidate>
          <BusinessDetails {...formikProps} />
          <div className='float-left pb-5'>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                decrement();
                window.scrollTo({ top: 0 });
              }}
            >
              {button.prev}
            </Button>
          </div>
          <div className='float-right pb-5'>
            <ErrorMessages errors={errors} />
            <div className='float-right'>
              <Button disabled={isSubmitting} onClick={handleSubmit}>
                {button.next}
              </Button>
            </div>
          </div>
        </FormikForm>
      </Container>
    </section>
  );
};

const FormikMemberBussDetails = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({
    data,
    primeMember,
    currentUser
  }) => (
    memberFormData(data, primeMember, currentUser, false)
  ),
  validationSchema: BussDetailsSchema,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (
    values,
    { props: {
      increment,
      primeMember,
      startMemberUpdate,
    }, setSubmitting }
  ) => {
    setSubmitting(true);

    if (primeMember && primeMember._id) {
      startMemberUpdate(primeMember._id, values);
    }

    setSubmitting(false);
    increment();
    window.scrollTo({ top: 0 });
  },
})(MemberBussDetails);

const mapStateToProps = createStructuredSelector({
  reset: selectReset,
  data: selectFormData,
  currentUser: selectUser,
  primeMember: selectPrimeMember,
});

export default withRouter(
  connect(mapStateToProps, {
    setFormData,
    startMemberUpdate,
  })(FormikMemberBussDetails)
);
