import { createSelector } from 'reselect';

const selectAnimalQuote = (state) => state.animalQuote

export const selectCurrentStep = createSelector(
  [selectAnimalQuote],
  (animalQuote) => animalQuote.currentStep
);

export const selectAnimalFormData = createSelector(
  [selectAnimalQuote],
  (animalQuote) => animalQuote.formData
);

export const selectQuoteId = createSelector(
  [selectAnimalQuote],
  (animalQuote) => animalQuote.quoteId
);

export const selectAnimalQuotesFromDB = createSelector(
  [selectAnimalQuote],
  (animalQuote) => animalQuote.animalQuotesFromDB
);

export const selectAnimalQuoteFromDB = createSelector(
  [selectAnimalQuote],
  (animalQuote) => animalQuote.animalQuoteFromDB
);

export const selectMembersInfo = createSelector(
  [selectAnimalQuote],
  (animalQuote) => animalQuote.membersInfo
);

export const selectReset = createSelector(
  [selectAnimalQuote],
  (animalQuote) => animalQuote.reset
);







