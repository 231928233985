import * as yup from 'yup';

const propertySchema = yup.object().shape({
  purpose: yup.string().trim().required('Please select a purpose'),
  residenceType: yup
    .string()
    .trim()
    .when('purpose', {
      is: (val) => val === 'residence' || val === 'both',
      then: yup.string().required('Please select a residence type'),
      otherwise: yup.string().notRequired(),
    }),
  businessType: yup
    .string()
    .trim()
    .when('purpose', {
      is: (val) => val === 'business' || val === 'both',
      then: yup.string().required('Please select a business type'),
      otherwise: yup.string().notRequired(),
    }),
  businessTypeElaboration: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
  yearObtained: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid year')
    .positive('Please enter a valid year')
    .min(1800, 'Please enter a year no less than 1800')
    .max(2030, 'Please enter a year no more than 2030')
    .required('Please provide year of occupation'),
  streetAddress: yup
    .string()
    .trim()
    .required('Please enter your street address')
    .min(4, ' Please provide at least 4 charaters')
    .max(255, 'Please limit content to no more than 255 characters'),
  city: yup
    .string()
    .trim()
    .required('Please enter your city')
    .min(2, ' Please provide at least 2 charaters')
    .max(20, 'Please enter content with not more than 20 characters'),
  state: yup
    .string()
    .trim()
    .required('Please enter your city')
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters'),
  zipCode: yup
    .number()
    .typeError('Please enter a numerical value')
    .required('Please enter the zip code')
    .integer('Please enter a valid zip code')
    .positive('Please enter a valid zip code')
    .min(1001, 'Please enter a valid zip code')
    .max(99950, 'Please enter a valid zip code'),
  ownershipType: yup.string().trim().required('Please select ownership'),
  responsibilityType: yup
    .string()
    .trim()
    .when('ownershipType', {
      is: (val) => val === 'own' || val === 'finance',
      then: yup.string().required('Please select responsibility'),
      otherwise: yup.string().notRequired(),
    }),
  propertyType: yup.string().trim().required('Please select a property type'),
  noOfUnits: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please provide a valid number')
    .positive('Please provide a valid number')
    .min(0, 'Number should be at least 1')
    .max(5000, 'Cannot enter more than 5000 units')
    .when(['propertyType', 'purpose'], {
      is: (pt, p) =>
        (pt === 'townhome' ||
          pt === 'multiplex' ||
          pt === 'apartmentComplex' ||
          pt === 'condoBuilding' ||
          pt === 'unitInABuilding') && (p === 'business' || p === 'both'),
      then: yup.number().required('Please provide no. of units'),
      otherwise: yup.number().notRequired(),
    }),
  noOfBuildings: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please provide a valid number')
    .positive('Please provide a valid number')
    .min(0, 'Please enter number of building')
    .max(99, 'Cannot enter more than 99 buildings')
    .when(['propertyType', 'purpose'], {
      is: (pt, p) => pt === 'apartmentComplex' && (p === 'business' || p === 'both'),
      then: yup.number().required('Please provide no. of buildings'),
      otherwise: yup.number().notRequired(),
    }),
  frame: yup
    .array()
    .ensure()
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.array().required('Please select a frame type'),
      otherwise: yup.array().notRequired(),
    }),
  wall: yup
    .array()
    .ensure()
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.array().required('Please select a wall type'),
      otherwise: yup.array().notRequired(),
    }),
  roofShape: yup
    .array()
    .ensure()
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.array().required('Please select a roof type'),
      otherwise: yup.array().notRequired(),
    }),
  roofMaterial: yup
    .array()
    .ensure()
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.array().required('Please select a material type'),
      otherwise: yup.array().notRequired(),
    }),
  roofYear: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
  roofInstallationYear: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid year')
    .positive('Please enter a valid year')
    .min(1800, 'Please enter a year no less than 1800')
    .max(2030, 'Please enter a year no more than 2030')
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.number().required('Please provide the year of roof installation'),
      otherwise: yup.number().notRequired(),
    }),
  hasBasement: yup
    .string()
    .trim()
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.string().required('Please select whether you have a basement'),
      otherwise: yup.string().notRequired(),
    }),
  noOfBasements: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please provide a valid number')
    .positive('Please provide a valid number')
    .min(0, 'Number should be at least 1')
    .max(99, 'Cannot enter more than 99 basements')
    .when(['hasBasement'], {
      is: (val) => val === 'yes',
      then: yup.number().required('Please provide number of basements'),
      otherwise: yup.number().notRequired(),
    }),
  basementFoundation: yup
    .array()
    .ensure()
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.array().required('Please select a foundation type'),
      otherwise: yup.array().notRequired(),
    }),
  otherConstructionInfo: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
  value: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid value')
    .positive('Please enter a valid value')
    .min(0, 'Please enter a positive value')
    .max(9999999999, 'Please enter a value less than $10000000000')
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.number().required('Please provide the property value'),
      otherwise: yup.number().notRequired(),
    }),
  year: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid year')
    .positive('Please enter a valid year')
    .min(1800, 'Please enter a year no less than 1800')
    .max(2030, 'Please enter a year no more than 2030')
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.number().required('Please provide the construction year'),
      otherwise: yup.number().notRequired(),
    }),
  area: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid square footage')
    .positive('Please enter a valid square footage')
    .min(0, 'Please enter a positive square footage')
    .max(99999999, 'Please enter a square footage no more than 100000000')
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.number().required('Please provide square footage'),
      otherwise: yup.number().notRequired(),
    }),
  stories: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid no. of stories')
    .positive('Please enter a valid no. of stories')
    .min(0, 'Please enter a positive no. of stories')
    .max(99, 'Please enter a no. of stories less than 100')
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.number().required('Please provide number of stories'),
      otherwise: yup.number().notRequired(),
    }),
  hasRenovations: yup
    .string()
    .trim()
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => (o === 'own' || o === 'finance') && r === 'entireStructure',
      then: yup.string().required('Please select whether there are renovations'),
      otherwise: yup.string().notRequired(),
    }),
  renovationDetails: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
  requiredCoverage: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid value')
    .positive('Please enter a valid value')
    .min(0, 'Please enter a value')
    .max(9999999, 'Please enter a value less than 10000000')
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => o === 'lease' || (o === 'own' && r === 'interiorOnly') || (o === 'finance' && r === 'interiorOnly'),
      then: yup.number().required('Please provide required coverage'),
      otherwise: yup.number().notRequired(),
    }),
  requiredLiability: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please enter a valid value')
    .positive('Please enter a valid value')
    .min(0, 'Please enter a value')
    .max(99999999, 'Please enter a value less than 100000000')
    .when(['ownershipType', 'responsibilityType'], {
      is: (o, r) => o === 'lease' || (o === 'own' && r === 'interiorOnly') || (o === 'finance' && r === 'interiorOnly'),
      then: yup.number().required('Please provide required liability'),
      otherwise: yup.number().notRequired(),
    }),
  scheduledItems: yup
    .array()
    .of(
      yup.object().shape({
        item: yup.string().trim(),
        replacementValue: yup.string().trim(),
      })
    )
    .optional(),
  scheduledItemsInfo: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
});

export default propertySchema;
