import { createSelector } from 'reselect';

const selectVehicle = (state) => state.vehicle;

export const selectVehicles = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.vehiclesFromDB
);

export const selectVehicleById = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.vehicleFromDB
);

export const selectMakes = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.makes
);

export const selectSelectedMake = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.selectedMake
);

export const selectModelsForSelectedMake = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.modelsForSelectedMake
);

export const selectSelectedModel = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.selectedModel
);

export const selectIsSubmitted = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.isSubmitted
);

export const selectShowVehicleForm = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.showVehicleForm
);

export const selectShowVehicleOptions = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.showVehicleOptions
);

export const selectShowVehicleInfoEdit = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.showVehicleInfoEdit
);

export const selectClearVehicleForm = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.clearVehicleForm
);

export const selectReset = createSelector(
  [selectVehicle],
  (vehicle) => vehicle.reset
);
  