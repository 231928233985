const vehicleActionTypes = {
  POPULATE_MAKES: 'POPULATE_MAKES',
  SELECT_MAKE: 'SELECT_MAKE',
  POPULATE_MODELS_FOR_SELECTED_MAKE: 'POPULATE_MODELS_FOR_SELECTED_MAKE',
  SELECT_MODEL: 'SELECT_MODEL',
  FETCH_VEHICLE_FROM_DB_START: 'FETCH_VEHICLE_FROM_DB_START',
  FETCH_VEHICLE_FROM_DB_SUCCESS: 'FETCH_VEHICLE_FROM_DB_SUCCESS',
  FETCH_VEHICLE_FROM_DB_FAILURE: 'FETCH_VEHICLE_FROM_DB_FAILURE',
  FETCH_ALL_VEHICLES_FROM_DB_START: 'FETCH_ALL_VEHICLES_FROM_DB_START',
  FETCH_ALL_VEHICLES_FROM_DB_SUCCESS: 'FETCH_ALL_VEHICLES_FROM_DB_SUCCESS',
  FETCH_ALL_VEHICLES_FROM_DB_FAILURE: 'FETCH_ALL_VEHICLES_FROM_DB_FAILURE',
  LOAD_VEHICLE_MAKE_FROM_DB_START: 'LOAD_VEHICLE_MAKE_FROM_DB_START',
  LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS: 'LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS',
  LOAD_VEHICLE_MAKE_FROM_DB_FAILURE: 'LOAD_VEHICLE_MAKE_FROM_DB_FAILURE',
  LOAD_VEHICLE_MODELS_FROM_DB_START: 'LOAD_VEHICLE_MODELS_FROM_DB_START',
  LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS: 'LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS',
  LOAD_VEHICLE_MODELS_FROM_DB_FAILURE: 'LOAD_VEHICLE_MODELS_FROM_DB_FAILURE',
  START_VEHICLE_SAVE: 'START_VEHICLE_SAVE',
  START_VEHICLE_UPDATE: 'START_VEHICLE_UPDATE',
  START_VEHICLE_DELETE: 'START_VEHICLE_DELETE',
  VEHICLE_DELETE_SUCCESS: 'VEHICLE_DELETE_SUCCESS',
  START_VEHICLE_IN_QUOTE_SAVE: 'START_VEHICLE_IN_QUOTE_SAVE',
  VEHICLE_IN_QUOTE_SAVE_SUCCESS: 'VEHICLE_IN_QUOTE_SAVE_SUCCESS',
  START_VEHICLE_IN_QUOTE_UPDATE: 'START_VEHICLE_IN_QUOTE_UPDATE',
  VEHICLE_IN_QUOTE_UPDATE_SUCCESS: 'VEHICLE_IN_QUOTE_UPDATE_SUCCESS',
  SET_IS_SUBMITTED: 'SET_IS_SUBMITTED',
  TOGGLE_VEHICLE_FORM: 'TOGGLE_VEHICLE_FORM',
  TOGGLE_VEHICLE_OPTIONS: 'TOGGLE_VEHICLE_OPTIONS',
  TOGGLE_VEHICLE_INFO_EDIT: 'TOGGLE_VEHICLE_INFO_EDIT',
  SET_CLEAR_VEHICLE_FORM: 'SET_CLEAR_VEHICLE_FORM',
  RESET_FORM: 'RESET_FORM',
};

export default vehicleActionTypes;
