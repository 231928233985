const vehicleBiddingActionTypes = {
  SET_SHOW_VEHICLE_QUOTES: 'SET_SHOW_VEHICLE_QUOTES',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  SET_IS_VEHICLE_BID: 'SET_IS_VEHICLE_BID',
  SET_IS_VEHICLE_SELECTED_BY_AI: 'SET_IS_VEHICLE_SELECTED_BY_AI',
  START_VEHICLE_BIDDING_SAVE: 'START_VEHICLE_BIDDING_SAVE',
  VEHICLE_BIDDING_SAVE_SUCCESS: 'VEHICLE_BIDDING_SAVE_SUCCESS',
  START_VEHICLE_BIDDING_UPDATE: 'START_VEHICLE_BIDDING_UPDATE',
  VEHICLE_BIDDING_UPDATE_SUCCESS: 'VEHICLE_BIDDING_UPDATE_SUCCESS',
  RESET_FORM: 'RESET_FORM',
};

export default vehicleBiddingActionTypes;
