import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import React from 'react';

const NumberFormation = ({
  name,
  value,
  errors,
  touched,
  type,
  currentIndex,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <NumberFormat
      {...props}
      name={name}
      thousandSeparator={true}
      value={value}
      onValueChange={val => {
        setFieldValue(name, val.value);
      }}
      className={
        currentIndex !== undefined
          ? `form-control ${touched[type] ? `is-${!!errors[type] ? 'invalid' : 'valid'}` : ''}`
          : `form-control ${touched[name] ? `is-${!!errors[name] ? 'invalid' : 'valid'}` : ''}`
      }
    />
  )
}

export default NumberFormation;
