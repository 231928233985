import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import annuityQuoteActionTypes from "./annuity.types";
import { convertAnnuityQuoteForDBSave } from "./annuity.utils";
import {
  fetchAnnuityQuotesFromDBSuccess,
  fetchAnnuityQuotesFromDBFailure,
  fetchAnnuityQuoteFromDBSuccess,
  fetchAnnuityQuoteFromDBFailure,
  annuityQuoteSaveSuccess,
  annuityQuoteSaveFailure,
  annuityQuoteUpdateSuccess,
  annuityQuoteUpdateFailure,
  annuityQuoteDeleteSuccess,
  annuityQuoteDeleteFailure,
  checkingChatGroupSuccess,
  saveDeleteCometChatSuccess,
  lastStepAnnuityQuoteUpdateSuccess,
} from "./annuity.actions";

function* fetchAnnuityQuotesFromDB() {
  try {
    const response = yield axios.get("/api/annuity-quote");
    const quotes = yield response.data;
    yield put(fetchAnnuityQuotesFromDBSuccess(quotes));
  } catch (e) {
    yield put(fetchAnnuityQuotesFromDBFailure(e));
  }
}

function* fetchAnnuityQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios(`/api/annuity-quote/${id}`);
    const quote = yield response.data;
    yield put(fetchAnnuityQuoteFromDBSuccess(quote));
  } catch (e) {
    yield put(fetchAnnuityQuoteFromDBFailure(e));
  }
}

function* saveAnnuityQuote({ payload: formData }) {
  try {
    const data = yield call(convertAnnuityQuoteForDBSave, formData);
    yield axios.post("/api/annuity-quote", data, configureAxios());
    yield put(annuityQuoteSaveSuccess());
  } catch (e) {
    yield put(annuityQuoteSaveFailure(e));
  }
}

function* updateAnnuityQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAnnuityQuoteForDBSave, formData);
    yield axios.patch(`/api/annuity-quote/${id}`, data, configureAxios());

    if (data.isLastStep) {
      yield put(lastStepAnnuityQuoteUpdateSuccess());
    } else {
      yield put(annuityQuoteUpdateSuccess());
    }
  } catch (e) {
    yield put(annuityQuoteUpdateFailure(e));
  }
}

function* deleteAnnuityQuote({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/annuity-quote/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(annuityQuoteDeleteSuccess());
  } catch (e) {
    yield put(annuityQuoteDeleteFailure(e));
  }
}

function* checkingChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/annuity-quote/${id}/check-chat-group`);
    yield checkingChatGroupSuccess(response.data);
  } catch (e) {
    throw new Error(e);
  }
}

function* saveDeleteChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/annuity-quote/${id}/save-delete-chat`);
    yield saveDeleteCometChatSuccess(response.data);
  } catch (e) {
    throw new Error(e);
  }
}

function* onFetchAnnuityQuotesStart() {
  yield takeEvery(
    annuityQuoteActionTypes.FETCH_ANNUITY_QUOTES_FROM_DB_START,
    fetchAnnuityQuotesFromDB
  );
}

function* onFetchAnnuityQuoteStart() {
  yield takeEvery(
    annuityQuoteActionTypes.FETCH_ANNUITY_QUOTE_FROM_DB_START,
    fetchAnnuityQuoteFromDB
  );
}

function* onAnnuityQuoteSaveStart() {
  yield takeEvery(
    annuityQuoteActionTypes.START_ANNUITY_QUOTE_SAVE,
    saveAnnuityQuote
  );
}

function* onAnnuityQuoteUpdateStart() {
  yield takeEvery(
    annuityQuoteActionTypes.START_ANNUITY_QUOTE_UPDATE,
    updateAnnuityQuote
  );
}

function* onAnnuityQuoteDeleteStart() {
  yield takeEvery(
    annuityQuoteActionTypes.START_ANNUITY_QUOTE_DELETE,
    deleteAnnuityQuote
  );
}

function* onCheckingChatGroup() {
  yield takeEvery(
    annuityQuoteActionTypes.START_CHECKING_CHAT_GROUP,
    checkingChatGroup
  );
}

function* onSaveDeleteChatGroup() {
  yield takeEvery(
    annuityQuoteActionTypes.SAVE_DELETE_ANNUITY_COMET_CHAT,
    saveDeleteChatGroup
  );
}

export function* annuityQuoteSagas() {
  yield all([
    call(onFetchAnnuityQuotesStart),
    call(onFetchAnnuityQuoteStart),
    call(onAnnuityQuoteSaveStart),
    call(onAnnuityQuoteUpdateStart),
    call(onAnnuityQuoteDeleteStart),
    call(onCheckingChatGroup),
    call(onSaveDeleteChatGroup),
  ]);
}
