import propertyQuoteActionTypes from "./property.types";
import { ModalTypes } from "../../modal/modal.types";
import authActionTypes from "../../auth/auth.types";

let INITIAL_STATE = {
  currentStep: 0,
  formData: {},
  noCombineId: "",
  combineId: "",
  combineCV: false,
  membersInfo: {},
  propertyQuotesFromDB: [],
  propertyQuoteFromDB: {},
  isSubmitted: false,
  reset: false,
};

const PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.propertyQuote) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.propertyQuote);
}

const propertyQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case propertyQuoteActionTypes.PROPERTY_QUOTE_SAVE_SUCCESS:
    case propertyQuoteActionTypes.PROPERTY_QUOTE_UPDATE_SUCCESS:
    case propertyQuoteActionTypes.PROPERTY_INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case propertyQuoteActionTypes.PROPERTY_DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case propertyQuoteActionTypes.SET_PROPERTY_NO_COMBINE_ID:
      return {
        ...state,
        noCombineId: action.payload,
      };
    case propertyQuoteActionTypes.SET_PROPERTY_COMBINE_ID:
      return {
        ...state,
        combineId: action.payload,
      };
    case propertyQuoteActionTypes.SET_PROPERTY_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case propertyQuoteActionTypes.SET_MEMBERS_INFO:
      return {
        ...state,
        membersInfo: action.payload,
      };
    case propertyQuoteActionTypes.FETCH_PROPERTY_QUOTES_FROM_DB_SUCCESS:
      return {
        ...state,
        propertyQuotesFromDB: action.payload,
      };
    case propertyQuoteActionTypes.FETCH_PROPERTY_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        propertyQuoteFromDB: action.payload,
      };
    case propertyQuoteActionTypes.SET_PROPERTY_COMBINE_CV:
      return {
        ...state,
        combineCV: action.payload,
      };
    case ModalTypes.OPEN_MODAL:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        noCombineId: "",
        combineId: "",
        combineCV: false,
        reset: false,
        isSubmitted: false,
        propertyQuotesFromDB: [],
        propertyQuoteFromDB: {},
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        noCombineId: "",
        combineId: "",
        combineCV: false,
        membersInfo: {},
        propertyQuotesFromDB: [],
        propertyQuoteFromDB: {},
        isSubmitted: false,
        reset: false,
      };
    case propertyQuoteActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default propertyQuoteReducer;
