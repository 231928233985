import { ModalState, ModalTypes } from '../../modal/modal.types';
import propertyQuoteActionTypes from './property.types';

const payload = {
  context: 'propertyQuote',
  status: '',
  error: '',
};

export const decrementCurrentStep = (currentStep) => ({
  type: propertyQuoteActionTypes.PROPERTY_DECREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const incrementCurrentStep = (currentStep) => ({
  type: propertyQuoteActionTypes.PROPERTY_INCREMENT_CURRENT_STEP,
  payload: currentStep,
});

export const setPropertyFormData = (data) => ({
  type: propertyQuoteActionTypes.SET_PROPERTY_FORM_DATA,
  payload: data,
});

export const setNoCombineId = (id) => ({
  type: propertyQuoteActionTypes.SET_PROPERTY_NO_COMBINE_ID,
  payload: id,
});

export const setCombineId = (id) => ({
  type: propertyQuoteActionTypes.SET_PROPERTY_COMBINE_ID,
  payload: id,
});

export const setCombineCV = (combine) => ({
  type: propertyQuoteActionTypes.SET_PROPERTY_COMBINE_CV,
  payload: combine,
});

export const setMembersInfo = (members) => ({
  type: propertyQuoteActionTypes.SET_MEMBERS_INFO,
  payload: members,
});

export const startCheckingChatGroup = (id) => ({
  type: propertyQuoteActionTypes.START_CHECKING_CHAT_GROUP_PROPERTY,
  payload: id,
})

export const checkingChatGroupSuccess = () => ({
  type: propertyQuoteActionTypes.CHECKING_CHAT_GROUP_PROPERTY_SUCCESS,
})

export const saveDeleteCometChat = (id) => ({
  type: propertyQuoteActionTypes.SAVE_DELETE_PROPERTY_COMET_CHAT,
  payload: id,
})

export const saveDeleteCometChatSuccess = () => ({
  type: propertyQuoteActionTypes.SAVE_DELETE_PROPERTY_COMET_CHAT_SUCCESS,
})

export const startFetchingPropertyQuotesFromDB = () => ({
  type: propertyQuoteActionTypes.FETCH_PROPERTY_QUOTES_FROM_DB_START,
});

export const fetchPropertyQuotesFromDBSuccess = (quote) => ({
  type: propertyQuoteActionTypes.FETCH_PROPERTY_QUOTES_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchPropertyQuotesFromDBFailure = (e) => ({
  type: propertyQuoteActionTypes.FETCH_PROPERTY_QUOTES_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingPropertyQuoteFromDB = (id) => ({
  type: propertyQuoteActionTypes.FETCH_PROPERTY_QUOTE_FROM_DB_START,
  payload: id,
});

export const fetchPropertyQuoteFromDBSuccess = (quote) => ({
  type: propertyQuoteActionTypes.FETCH_PROPERTY_QUOTE_FROM_DB_SUCCESS,
  payload: quote,
});

export const fetchPropertyQuoteFromDBFailure = (e) => ({
  type: propertyQuoteActionTypes.FETCH_PROPERTY_QUOTE_FROM_DB_FAILURE,
  payload: e,
});

export const startPropertyQuoteSave = (quotes) => ({
  type: propertyQuoteActionTypes.START_PROPERTY_QUOTE_SAVE,
  payload: quotes,
});

export const propertyQuoteSaveSuccess = () => ({
  type: propertyQuoteActionTypes.PROPERTY_QUOTE_SAVE_SUCCESS,
});

export const propertyQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startPropertyQuoteUpdate = (id, formData) => ({
  type: propertyQuoteActionTypes.START_PROPERTY_QUOTE_UPDATE,
  payload: { id, formData },
});

export const propertyQuoteUpdateSuccess = () => ({
  type: propertyQuoteActionTypes.PROPERTY_QUOTE_UPDATE_SUCCESS,
});

export const propertyQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLastStepPropertyQuoteUpdate = (id, formData) => ({
  type: propertyQuoteActionTypes.START_LAST_STEP_PROPERTY_QUOTE_UPDATE,
  payload: { id, formData },
});

export const lastStepPropertyQuoteUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.QUOTE_REQUEST_SUCCESS,
  },
});








