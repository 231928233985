import { createSelector } from 'reselect';

const selectLifeQuote = (state) => state.lifeQuote

export const selectCurrentStep = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.currentStep
);

export const selectLifeFormData = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.formData
);

export const selectQuoteId = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.quoteId
);

export const selectLifeQuotesFromDB = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.lifeQuotesFromDB
);

export const selectLifeQuoteFromDB = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.lifeQuoteFromDB
);

export const selectHealthInfosLife = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.healthInfos
);

export const selectMembersInfo = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.membersInfo
);

export const selectReset = createSelector(
  [selectLifeQuote],
  (lifeQuote) => lifeQuote.reset
);









