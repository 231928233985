import memberPropertyPolicyActionTypes from './property.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  statesFromDB: [],
  propertiesFromDB: [],
  propertyPolicyFromDB: {},
  reset: false,
  clearForm: false,
}

const memberPropertyPolicyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberPropertyPolicyActionTypes.LOAD_STATES_PROPERTY_FROM_DB_START:
      return {
        ...state,
        statesFromDB: action.payload,
      }
    case memberPropertyPolicyActionTypes.FETCH_PROPERTIES_FROM_DB_SUCCESS:
      return {
        ...state,
        propertiesFromDB: action.payload,
      }
    case memberPropertyPolicyActionTypes.FETCH_PROPERTY_POLICY_FROM_DB_SUCCESS:
      return {
        ...state,
        propertyPolicyFromDB: action.payload,
      }
    case memberPropertyPolicyActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        clearForm: action.payload,
        propertyPolicyFromDB: {},
      }
    case memberPropertyPolicyActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        statesFromDB: [],
        propertiesFromDB: [],
        propertyPolicyFromDB: {},
        reset: false,
        clearForm: false,
      }
    default:
      return state
  }
}

export default memberPropertyPolicyReducer;