export const MEMBER_CMS_DATA = {
  heading: {
    myInfo: 'My Information',
    myHousehold: 'Members of My Household',
  },
  subHeading: {
    myInfo: 'Information about Self. Required for all quotes, including quotes for other household members. Note that the more detailed and accurate the information you provide, the better our Agents can place you with the appropriate Carrier and more accurate quotes.',
    myHousehold: 'For any household member that you might want to store for quoting purposes. Note that the more detailed and accurate the information you provide, the better our Agents can place you with the appropriate Carrier and more accurate quotes.',
  },
  form: {
    button: {
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel',
      next: 'Next',
      prev: 'previous',
      verify: 'Verify',
      submit: 'Submit',
    },
    stepperButton: "Let's move forward!",
    selectMemberType: {
      title: 'Member Type',
      description: 'Please select the relationship this member has with you (the account holder).',
      memberRelation: 'Member relation',
      dropdown: {
        memberTypes: [
          { value: 'spouse', label: 'Spouse' },
          { value: 'partner', label: 'Domestic partner' },
          { value: 'child', label: 'Child' },
          { value: 'adoptedChild', label: 'Adopted child' },
          { value: 'parent', label: 'Parent' },
          { value: 'sibling', label: 'Sibling' },
          { value: 'careGiver', label: 'Caregiver' },
          { value: 'stepChild', label: 'Stepchild' },
          { value: 'transferStudent', label: 'Transfer student' },
        ],
      }
    },
    personalDetails: {
      title: 'Personal Information',
      description:
        'We do not share personal data with anyone, except for the purposes of getting quotes and policies.',
      label: {
        member: 'Select a member on the list or "Add another"',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        dateOfBirth: 'Date of birth',
        memberType: 'Member relation',
        dropdown: {
          memberTypes: [
            { value: 'self', label: 'Self' },
            { value: 'spouse', label: 'Spouse' },
            { value: 'partner', label: 'Domestic partner' },
            { value: 'child', label: 'Child' },
            { value: 'adoptedChild', label: 'Adopted child' },
            { value: 'parent', label: 'Parent' },
            { value: 'sibling', label: 'Sibling' },
            { value: 'careGiver', label: 'Caregiver' },
            { value: 'stepChild', label: 'Stepchild' },
            { value: 'transferStudent', label: 'Transfer student' },
            ],
          genders: [
            { value: 'male', key: 'Male' },
            { value: 'female', key: 'Female' },
            { value: 'other', key: 'Other' },
          ],
        },
      },
    },
    contactInfo: {
      title: 'Contact Information',
      titleTwl: 'Phone Verification',
      descriptionTwl: 'Please enter your phone number to retreive a verification code that will then need to be entered here. By entering your phone number, you agree to receive text notifications from InsurEasier. Note that we will only disclose your phone number to an Agent that YOU have chosen a quote from, for the sole purpose of turning your quote into a policy.',
      description:
        "NO Agent, except ones that you select a quote from, will be allowed to contact you outside of the Chat Room. If any Agent contacts you before you've selected a quote, please let us know as you could be monetarily compensated. If a Member is missing contact information, the account holder's contact information will be used.",
      label: {
        email: 'Email',
        mobile: 'Mobile',
        landline: 'Landline',
        phoneNumber: 'Enter your phone number',
        code: 'Please enter the verification code sent to your phone',
        valid: 'Your phone number is verified! Please click Next to continue.',
        invCode: 'Invalid code. Please try again.',
        invCodeFv: 'You entered an invalid code more than five times.',
        invPhone: 'Too many requests',
        lgOut: 'Please log out, wait 10 minutes, and try again.',
        street: 'Street address',
        city: 'City',
        zipCode: 'Zip code',
        state: 'State',
      },
    },
    identification: {
      title: 'Identification Details',
      description:
        "Feel confident in knowing that we encrypt all data.",
      label: {
        documentType: 'Document type',
        documentNumber: 'Document number',
        documentIssueState: 'Issued state of document',
        expiration: 'Document expiration',
        dropdown: {
          documentTypes: [
            { value: 'driversLicense', key: "Driver's license" },
            { value: 'stateID', key: 'State Id' },
            { value: 'driversPermit', key: "Driver's permit" },
            { value: 'passport', key: 'Passport' },
          ],
        },
      },
    },
    legalStatus: {
      title: 'Legal Status',
      description:
        'Please select a document type. The Social Security Number, Alien Registration or Visa Number can be entered now or later. It is necessary for most policies and could be considered for a discount, especially if you have good credit. Note that this will not negatively affect your credit.',
      label: {
        legalDocumentType: 'Document type',
        legalDocumentNumber: 'Document number',
        dropdown: {
          legalDocumentTypes: [
            { value: 'citizen', key: 'Citizen' },
            { value: 'alienRegistration', key: 'Alien registration' },
            { value: 'visa', key: 'Visa' },
          ],
        },
      },
    },
    workDetails: {
      title: 'Work Details',
      description:
        "Most Carriers give discounts for certain occupations or military families and some Carriers only service certain occupations or military families. So it could greatly benefit you to include this information.",
      label: {
        occupation: 'Occupation',
        annualIncome: 'Annual income',
        militaryServiceType: 'Military service',
        dropdown: {
          militaryServiceTypes: [
            {
              value: 'isServing',
              key: 'Currently serving',
            },
            {
              value: 'isInReserves',
              key: 'In Reserves',
            },
            {
              value: 'isVeteran',
              key: 'Veteran',
            },
            {
              value: 'isDirectlyRelatedTo',
              key: 'Directly related kin',
            },
            {
              value: 'none',
              key: 'None of the above',
            },
          ],
        },
      },
    },
    businessDetails: {
      title: 'Business Information',
      description:
        "We know... But, Carriers like to be able to verify things. This also helps the Agent better place you. And, inquiries like these are our way of keeping you from having to respond to as many additional inquiries that any Agent might have for you later. However, please expect an Agent to contact you through the Chat Room for additional inquiries. It's always something with some of the Carriers. ;)",
      label: {
        businessName: 'Name',
        businessOwnership: 'Are you the owner?',
        businessStreet: 'Street address',
        businessCity: 'City',
        businessZipCode: 'Zip code',
        businessState: 'State',
        dropdown: {
          businessOwnershipChoices: [
            {
              value: 'true',
              key: 'Yes',
            },
            {
              value: 'false',
              key: 'No',
            },
          ],
        },
      },
    },
    education: {
      title: 'Education',
      description:
        'Another possible discount. After selecting any Acquisition(s), please provide the name(s) of any Degree(s), Certificate(s), or License(s) that you might hold. If none apply, no worries, simply skip this section.',
      label: {
        acquisitions: 'Acquisition(s)',
        degreeType: 'Degree/Certification/License',
        dropdown: {
          acquisitionTypes: [
            { value: 'student', label: 'Student' },
            { value: 'highSchoolDiploma', label: 'High school diploma' },
            { value: 'GED', label: 'General Educational Development' },
            { value: 'college', label: 'Some college' },
            { value: 'vocationalStudies', label: 'Vocational' },
            { value: 'twoYears', label: 'Two year program' },
            { value: 'certifications', label: 'Certificate/License holder' },
            { value: 'underGraduate', label: 'Undergraduate' },
            { value: 'masters', label: 'Masters' },
            { value: 'doctorate', label: 'Doctorate' },
          ],
        },
      },
    },
    history: {
      felony: {
        title: 'Felony History',
        description:
          "If the answer is 'Yes,' don't worry. We're all trying to get away with something - like lower insurance costs ;). But, this is another question that will help agents better place you. So, thanks for your understanding!",
        label: {
          status: 'Felony conviction within the last 10 years?',
          reason: 'Please explain and include the conviction',
          dropdown: {
            currentStatus: [
              { value: 'true', key: 'Yes' },
              { value: 'false', key: 'No' },
            ],
          },
        },
      },
    },
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
    occupations:
      'There was an error loading occupations from database. Please try again later',
  },
  modal: {
    heading: 'Caution! Removing this Member might delete associated Policies. Consider updating the Form. Do you want to continue deleting',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    contactInfo: {
      content: {
        email: "Note that Emails are not given to all Agents creating quotes. Only once you have selected a quote from a specific Agent will that Agent be given your Email to set up your policy.",
        mobile: "You must provide a Mobile number. Note that phone numbers are not given to all Agents creating quotes. Only once you have selected a quote from a specific Agent will that Agent be given your phone number to set up your policy.",
        landline: "You must provide a Mobile number; Landline is optional. Note that phone numbers are not given to all Agents creating quotes. Only once you have selected a quote from a specific Agent will that Agent be given your phone number to set up your policy.",
      }
    },
    idDeatails: {
      content: {
        docType: "A driver's license or permit is required for all drivers getting vehicle insurance and some form of ID is required for anyone over 18 years of age. Passport is the only form of ID that does not require the issued state.",
      },
    },
    legalStatus: {
      content: {
        docType: 'Legal status is required but the number is only required for certain types of insurances.',
        docNum: 'Document number is a Social Security Number or Alien Registration Number and is optional. You can store it without needing to re-enter it every time you get a quote. It will be requested (required for certain quotes) later with the option of having it deleted once you have signed a policy with an Agent.',
      }
    },
    workDetails: {
      content: {
        occupation: 'Start by typing and try to select from the list provided because Agents use this list to cater to specific types of occupations. Some Agents/Carriers only service specific occupations and others might offer a discount for people with a specific occupation.',
        annualIncome: 'Annual income can help an Agent in many ways. It gives an idea of liability coverage, especially if you get Professional liability (coming soon). Or, it can help get a better estimate on Life or Disability insurances, available Annuity contributions, etc.',
        military: "If you are in the Military, have served, or are directly related to a Military Service member, there are some Agents/Carriers that cater specifically to you. Either way, you are likely going to get a discount from most Carriers."
      }
    }
  }
};

