import React, { useEffect,useState } from 'react';
import { Col, Form, Row, ButtonGroup } from 'react-bootstrap';
import { HEALTH_QUOTE_CMS_DATA } from '../../../pages/quotes/health/health.cms';
import RadioButton from '../../radio-button/radio-button.component';

const Handicaps = ({
  values,
  handleChange,
  touched,
  errors,
}) => {
  const {
    form: {
      handicaps: {
        title,
        description,
        handicapAddInfo,
        hasMentalHandicap,
        hasPhysicalDis,
      },
    },
  } = HEALTH_QUOTE_CMS_DATA;

  const [showHandicapDetailsView, setShowHandicapDetailsView] = useState(false);

  const path = window.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  useEffect(() => {
    if (
      values.hasMentalHandicap === 'true' ||
      values.hasPhysicalDis === 'true'
    ) {
      setShowHandicapDetailsView(true);
    }

    if (
      (values.hasMentalHandicap === 'false' &&
        values.hasPhysicalDis === '') ||
      (values.hasMentalHandicap === '' &&
        values.hasPhysicalDis === 'false') ||
      (values.hasMentalHandicap === 'false' &&
        values.hasPhysicalDis === 'false')
    ) {
      setShowHandicapDetailsView(false);
    }
  }, [
    values.hasMentalHandicap, 
    values.hasPhysicalDis, 
    setShowHandicapDetailsView
  ]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={6} controlId='mental-handicap'>
              <Form.Label>{hasMentalHandicap.label}</Form.Label>
              <br/>
              <ButtonGroup>
                {hasMentalHandicap.dropdown.map((option, i) => (
                  <RadioButton
                    key={i}
                    idx={i}
                    name='hasMentalHandicap'
                    option={option}
                  />
                ))}
              </ButtonGroup>
              <Form.Control.Feedback type='invalid'>
                {errors.hasMentalHandicap}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={6} controlId='physical-disability'>
              <Form.Label>{hasPhysicalDis.label}</Form.Label>
              <br/>
              <ButtonGroup>
                {hasPhysicalDis.dropdown.map((option, i) => (
                  <RadioButton
                    key={i}
                    idx={i}
                    name='hasPhysicalDis'
                    option={option}
                  />
                ))}
              </ButtonGroup>
              <Form.Control.Feedback type='invalid'>
                {errors.hasPhysicalDis}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {showHandicapDetailsView ? (
            <Form.Row>
              <Form.Group as={Col} lg={12} controlId='mental-handicap-details'>
                <Form.Label>{handicapAddInfo}</Form.Label>
                <Form.Control
                  readOnly={path === agentBid ? true : false}
                  name='handicapAddInfo'
                  as='textarea'
                  rows={5}
                  value={values.handicapAddInfo}
                  onChange={handleChange}
                  isValid={
                    touched.handicapAddInfo &&
                    !errors.handicapAddInfo
                  }
                  isInvalid={!!errors.handicapAddInfo}
                  maxLength={255}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.handicapAddInfo}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default Handicaps;
