import React, {useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  startFetchingAllAgentsFromDB,
} from '../../redux/agent/agent.actions';
import {
  selectAgents,
} from '../../redux/agent/agent.selectors';
import AgentsListTable from '../../components/agents-list/agents-list-table.component';

const AgentsList = ({
  agents,
  startFetchingAllAgentsFromDB,
}) => {

  useEffect(() => {
    startFetchingAllAgentsFromDB();
  }, [startFetchingAllAgentsFromDB]);
  
  return (
    <section className='ie-form-scaffold'>
      <Container>
        <h1 className='mb-5 text-center'>Agents</h1>
        {agents && agents.length !== 0 ? (
          <AgentsListTable agents={agents} />
        ) : null}
      </Container> 
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  agents: selectAgents,
});

export default connect(mapStateToProps, {
  startFetchingAllAgentsFromDB,
})(AgentsList);