export const PROFILE_CMS_DATA = {
  heading: 'My Profile',
  subHeading:
    'Here you can change basic informaion and your password.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
    },
    password: {
      title: 'Reset Password',
      description: '',
      label: {
        newP: 'New password',
        reNewP: 'Re-enter new password',
      },
      invalid: 'Invalid Password. Please refer below.',
      required: 'Password is a required field',
      rules: {
        title: 'Your password must contain at least:',
        firstRule: '8 characters',
        secondRule: '1 uppercase letter',
        thirdRule: '1 lowercase letter',
        fourthRule: '1 number'
      }
    },
    errObj: {
      notMatches: 'Passwords must match'
    },
  },
};

