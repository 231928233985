import { ModalState, ModalTypes } from '../modal/modal.types';
import agentBiddingActionTypes from './agent-bidding.types';

const payload = {
  context: 'agentBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsAnimalBid = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_ANIMAL_BID,
  payload: toggle,
});

export const setIsVehicleBid = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_VEHICLE_BID,
  payload: toggle,
});

export const setIsPropertyBid = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_PROPERTY_BID,
  payload: toggle,
});

export const setIsCombineBid = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_COMBINE_BID,
  payload: toggle,
});

export const setIsLifeBid = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_LIFE_BID,
  payload: toggle,
});

export const setIsHealthBid = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_HEALTH_BID,
  payload: toggle,
});

export const setIsAnnuityBid = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_ANNUITY_BID,
  payload: toggle,
});

export const setShowAllQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_ALL_QUOTES,
  payload: toggle,
});

export const setIsPropertySelectedByAi = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_PROPERTY_SELECTED_BY_AI,
  payload: toggle,
});

export const setIsVehicleSelectedByAi = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_VEHICLE_SELECTED_BY_AI,
  payload: toggle,
});

export const setIsCombinedSelectedByAi = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_COMBINED_SELECTED_BY_AI,
  payload: toggle,
});

export const setIsAnnuitySelectedByAi = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_ANNUITY_SELECTED_BY_AI,
  payload: toggle,
});

export const setIsLifeSelectedByAi = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_LIFE_SELECTED_BY_AI,
  payload: toggle,
});

export const setIsHealthSelectedByAi = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_HEALTH_SELECTED_BY_AI,
  payload: toggle,
});

export const setIsAnimalSelectedByAi = (toggle) => ({
  type: agentBiddingActionTypes.SET_IS_ANIMAL_SELECTED_BY_AI,
  payload: toggle,
});

export const setShowAnimalQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_ANIMAL_QUOTES,
  payload: toggle,
});

export const setShowLifeQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_LIFE_QUOTES,
  payload: toggle,
});

export const setShowHealthQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_HEALTH_QUOTES,
  payload: toggle,
});

export const setShowAnnuityQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_ANNUITY_QUOTES,
  payload: toggle,
});

export const setShowPropertyQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_PROPERTY_QUOTES,
  payload: toggle,
});

export const setShowVehicleQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_VEHICLE_QUOTES,
  payload: toggle,
});

export const setShowCombineQuotes = (toggle) => ({
  type: agentBiddingActionTypes.SET_SHOW_COMBINE_QUOTES,
  payload: toggle,
});

export const startFetchingAgentBiddingsFromDB = () => ({
  type: agentBiddingActionTypes.FETCH_AGENT_BIDDINGS_FROM_DB_START,
});

export const fetchAgentBiddingsFromDBSuccess = (bids) => ({
  type: agentBiddingActionTypes.FETCH_AGENT_BIDDINGS_FROM_DB_SUCCESS,
  payload: bids,
});

export const fetchAgentBiddingsFromDBFailure = (e) => ({
  type: agentBiddingActionTypes.FETCH_AGENT_BIDDINGS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingAgentBiddingFromDB = (id) => ({
  type: agentBiddingActionTypes.FETCH_AGENT_BIDDING_FROM_DB_START,
  payload: id,
});

export const fetchAgentBiddingFromDBSuccess = (bid) => ({
  type: agentBiddingActionTypes.FETCH_AGENT_BIDDING_FROM_DB_SUCCESS,
  payload: bid,
});

export const fetchAgentBiddingFromDBFailure = (e) => ({
  type: agentBiddingActionTypes.FETCH_AGENT_BIDDING_FROM_DB_FAILURE,
  payload: e,
});

export const startAgentBiddingSave = (values) => ({
  type: agentBiddingActionTypes.START_AGENT_BIDDING_SAVE,
  payload: values,
});

export const agentBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const agentBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAgentBiddingUpdate = (id, formData) => ({
  type: agentBiddingActionTypes.START_AGENT_BIDDING_UPDATE,
  payload: { id, formData },
});

export const agentBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const agentBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

