import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms';
import Dropdown from '../dropdown/dropdown.component';

const Policies = ({
  values,
  touched,
  errors,
}) => {
  const {
    form: {
      policies: { title, description, pAndC, lAndH }
    },
  } = AGENT_CMS_DATA;

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={6} controlId='pAndC'>
              <Form.Label>{pAndC.label}</Form.Label>
              <Dropdown
                name={'pAndC'}
                selected={values.pAndC}
                options={pAndC.dropdown}
                touched={touched}
                errors={errors}
                values={values}
                isMulti={true}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.pAndC}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={6} controlId='lAndH'>
              <Form.Label>{lAndH.label}</Form.Label>
              <Dropdown
                name={'lAndH'}
                selected={values.lAndH}
                options={lAndH.dropdown}
                touched={touched}
                errors={errors}
                values={values}
                isMulti={true}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.lAndH}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
}

export default Policies;
