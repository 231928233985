import * as yup from 'yup';

const LegalStatusSchema = yup.object().shape({
  legalDocumentType: yup
    .string()
    .trim()
    .required('Please select a valid document type'),
  legalDocumentNumber: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid document number')
    .max(30, 'Please enter a valid document number')
    .optional(),
})

export default LegalStatusSchema;