import React from 'react';

const AgentPatent = () => {
  return (
    <>
      <h4 className='py-3'>Patent Pending</h4>
      <p>Per patents labeled, "Carrier Optimization Process for Agent Selection and User Election,"
        and, "Platform to Handle Correspondence Between Agents and Users Entirely on a Single Site,"
        the processes used on this site are "Patent Pending."
      </p>
{/*   <p>The Carrier Optimization Process for Agent Selection and User Election connects users with
        the optimal agents, based on which will give the greatest carrier selection, as well as
        offering slots for captive agents/carriers, so that they have access to agents and are not
        limited on carriers. Nor will users need to be redirected but will be able to complete the 
        process of getting quotes and policies on one site.
      </p>
      <p>The Platform to Handle Correspondence Between Agents and Users Entirely on a Single Site
        connects Users with Agents that are designated to create quotes for the User in one place
        such that there is an open channel of communication between said User and Agents so that
        they can all see each other’s inquiries and responses. Therefore, the Agents will not
        need to have the User repeat themselves and they can all offer advice, which they can also
        peer review. Also, this same site will be where the User can select a quote, from the Agents
        creating them, to turn into a policy. In addition, this site stores policies for Users to
        view and edit at any time.
      </p>*/}
    </>
  );
};

export default AgentPatent;