import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  showAccountActivation,
  showAgentDashboard,
  showAgentShowcase,
  showMemberDashboard,
  showMemberShowcase,
} from '../navigation/navigation.actions';
import {
  accountActivationFailure,
  accountActivationSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  loginFailure,
  loginSuccess,
  registrationFailure,
  registrationSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  fetchUsersFromDBSuccess,
  fetchUsersFromDBFailure,
} from './auth.actions';
import authActionTypes from './auth.types';

import { loginToChat, createChatUser } from '../../CometChat';

export function* register({ payload: user }) {
  try {
    const response = yield axios.post('/api/users', user);
    yield createChatUser(response.data.user.id, user.firstName);
    yield put(registrationSuccess(response.data.user));
    yield put(showAccountActivation(true));
    yield put(showMemberShowcase(false));
    yield put(showAgentShowcase(false));
  } catch (e) {
    yield put(registrationFailure());
  }
}

export function* activateAccount({ payload: accountActivationToken }) {
  try {
    const response = yield axios.post(
      '/api/auth/activate/account',
      accountActivationToken
    );

    const { user } = yield response.data;
    yield put(accountActivationSuccess(user));

    if (user.isActive) {
      if (user.isMember) {
        if (user.isAgent) {
          yield put(showMemberShowcase(false));
          yield put(showAgentShowcase(true));
        } else {
          yield put(showMemberShowcase(true));
          yield put(showAgentShowcase(false));
        }
      }
    }
  } catch (e) {
    yield put(accountActivationFailure());
  }
}

export function* login({ payload: { values, isInitiatedFromAgentShowcase } }) {
  try {
    const response = yield axios.post('/api/auth/login', {
      email: values.email,
      password: values.password,
      isInitiatedFromAgentShowcase,
    });

    const { user, token } = yield response.data;
    if (user.isAgent && isInitiatedFromAgentShowcase) {
      yield put(loginSuccess(user, token));
      yield loginToChat(user.agent, values.email.split('@')[0]);
    }

    if (user.isMember && !isInitiatedFromAgentShowcase) {
      yield put(loginSuccess(user, token));
      yield loginToChat(user.member, values.email.split('@')[0]);
    }

    if (user.isActive) {
      if (user.isMember && !isInitiatedFromAgentShowcase) {
        yield put(showMemberShowcase(false));
        yield put(showAgentShowcase(false));
        yield put(showMemberDashboard(true));
        yield put(showAgentDashboard(false));
      }

      if (user.isAgent && isInitiatedFromAgentShowcase) {
        yield put(showMemberShowcase(false));
        yield put(showAgentShowcase(false));
        yield put(showMemberDashboard(false));
        yield put(showAgentDashboard(true));
      }
    }
  } catch (e) {
    yield put(loginFailure());
  }
}

export function* forgotPassword({ payload: values }) {
  try {
    yield axios.post('/api/users/forgot/password', values);
    yield put(forgotPasswordSuccess());
  } catch (e) {
    yield put(forgotPasswordFailure());
  }
}

export function* resetPassword({ payload: user }) {
  try {
    const response = yield axios.post('/api/users/reset/password', user);
    yield put(resetPasswordSuccess(response.data.user));
    yield put(showMemberShowcase(true));
    yield put(showAgentShowcase(false));
  } catch (e) {
    yield put(resetPasswordFailure(e));
  }
}

function* fetchUsersFromDB() {
  try {
    const response = yield axios.get(`/api/users`);
    const users = yield response.data;
    yield put(fetchUsersFromDBSuccess(users));
  } catch (e) {
    yield put(fetchUsersFromDBFailure(e));
  }
}

export function* onRegistrationStart() {
  yield takeLatest(authActionTypes.START_REGISTRATION, register);
}

export function* onAccountActivationStart() {
  yield takeLatest(authActionTypes.START_ACCOUNT_ACTIVATION, activateAccount);
}

export function* onLoginStart() {
  yield takeLatest(authActionTypes.START_LOGIN, login);
}

export function* onForgotPasswordStart() {
  yield takeLatest(authActionTypes.START_FORGOT_PASSWORD, forgotPassword);
}

export function* onResetPasswordStart() {
  yield takeLatest(authActionTypes.START_RESET_PASSWORD, resetPassword);
}

export function* onFetchUsersFromDBStart() {
  yield takeLatest(authActionTypes.FETCH_USERS_FROM_DB_START, fetchUsersFromDB);
}

export function* authSagas() {
  yield all([
    call(onRegistrationStart),
    call(onAccountActivationStart),
    call(onLoginStart),
    call(onForgotPasswordStart),
    call(onResetPasswordStart),
    call(onFetchUsersFromDBStart),
  ]);
}
