import { ModalState, ModalTypes } from '../../modal/modal.types';
import healthActionTypes from './health.types';

const payload = {
  context: 'health',
  status: '',
  error: ''
}

export const setIsSubmitted = (toggle) => ({
  type: healthActionTypes.SET_IS_SUBMITTED,
  payload: toggle,
});

export const toggleHealthFormView = (toggle) => ({
  type: healthActionTypes.TOGGLE_HEALTH_FORM_VIEW,
  payload: toggle,
});

export const setMemberId = (id) => ({
  type: healthActionTypes.SET_MEMBER_ID,
  payload: id,
});

export const setMemberHealthId = (id) => ({
  type: healthActionTypes.SET_MEMBER_HEALTH_ID,
  payload: id,
});

export const setClearHealthForm = (clear) => ({
  type: healthActionTypes.SET_CLEAR_HEALTH_FORM,
  payload: clear,
});

export const startFetchingHealthsFromDB = () => ({
  type: healthActionTypes.FETCH_HEALTHS_FROM_DB_START,
});

export const fetchHealthsFromDBSuccess = (healths) => ({
  type: healthActionTypes.FETCH_HEALTHS_FROM_DB_SUCCESS,
  payload: healths,
});

export const fetchHealthsFromDBFailure = (e) => ({
  type: healthActionTypes.FETCH_HEALTHS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingHealthFromDB = (id) => ({
  type: healthActionTypes.FETCH_HEALTH_FROM_DB_START,
  payload: id,
});

export const fetchHealthFromDBSuccess = (health) => ({
  type: healthActionTypes.FETCH_HEALTH_FROM_DB_SUCCESS,
  payload: health,
});

export const fetchHealthFromDBFailure = (e) => ({
  type: healthActionTypes.FETCH_HEALTH_FROM_DB_FAILURE,
  payload: e,
});

export const startHealthSave = (health) => ({
  type: healthActionTypes.START_HEALTH_SAVE,
  payload: health,
});

export const healthSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const healthSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startHealthUpdate = (id, formData) => ({
  type: healthActionTypes.START_HEALTH_UPDATE,
  payload: { id, formData },
});

export const healthUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const healthUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startHealthQuotePageSave = (health) => ({
  type: healthActionTypes.START_HEALTH_QUOTE_PAGE_SAVE,
  payload: health,
});

export const healthQuotePageSaveSuccess = (health) => ({
  type: healthActionTypes.HEALTH_QUOTE_PAGE_SAVE_SUCCESS,
  payload: health,
});

export const healthQuotePageSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startHealthQuotePageUpdate = (id, formData) => ({
  type: healthActionTypes.START_HEALTH_QUOTE_PAGE_UPDATE,
  payload: { id, formData },
});

export const healthQuotePageUpdateSuccess = () => ({
  type: healthActionTypes.HEALTH_QUOTE_PAGE_UPDATE_SUCCESS,
});

export const healthQuotePageUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});