const memberVehiclePolicyActionTypes = {
  POPULATE_MAKES: 'POPULATE_MAKES',
  SELECT_MAKE: 'SELECT_MAKE',
  POPULATE_MODELS_FOR_SELECTED_MAKE: 'POPULATE_MODELS_FOR_SELECTED_MAKE',
  SELECT_MODEL: 'SELECT_MODEL',
  FETCH_VEHICLES_FROM_DB_START: 'FETCH_VEHICLES_FROM_DB_START',
  FETCH_VEHICLES_FROM_DB_SUCCESS: 'FETCH_VEHICLES_FROM_DB_SUCCESS',
  FETCH_VEHICLES_FROM_DB_FAILURE: 'FETCH_VEHICLES_FROM_DB_FAILURE',
  FETCH_VEHICLE_POLICY_FROM_DB_START: 'FETCH_VEHICLE_POLICY_FROM_DB_START',
  FETCH_VEHICLE_POLICY_FROM_DB_SUCCESS: 'FETCH_VEHICLE_POLICY_FROM_DB_SUCCESS',
  FETCH_VEHICLE_POLICY_FROM_DB_FAILURE: 'FETCH_VEHICLE_POLICY_FROM_DB_FAILURE',
  LOAD_VEHICLE_MAKE_FROM_DB_START_POLICY: 'LOAD_VEHICLE_MAKE_FROM_DB_START_POLICY',
  LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS_POLICY: 'LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS_POLICY',
  LOAD_VEHICLE_MAKE_FROM_DB_FAILURE_POLICY: 'LOAD_VEHICLE_MAKE_FROM_DB_FAILURE_POLICY',
  LOAD_VEHICLE_MODELS_FROM_DB_START: 'LOAD_VEHICLE_MODELS_FROM_DB_START',
  LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS: 'LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS',
  LOAD_VEHICLE_MODELS_FROM_DB_FAILURE: 'LOAD_VEHICLE_MODELS_FROM_DB_FAILURE',
  LOAD_STATES_FROM_DB_START: 'LOAD_STATES_FORM_DB_START',
  LOAD_STATES_FROM_DB_SUCCESS: 'LOAD_STATES_FROM_DB_SUCCESS',
  LOAD_STATES_FROM_DB_FAILURE: 'LOAD_STATES_FROM_DB_FAILURE',
  START_VEHICLE_POLICY_SAVE: 'START_VEHICLE_POLICY_SAVE',
  START_VEHICLE_POLICY_UPDATE: 'START_VEHICLE_POLICY_UPDATE',
  START_VEHICLE_POLICY_DELETE: 'START_VEHICLE_POLICY_DELETE',
  VEHICLE_POLICY_DELETE_SUCCESS: 'VEHICLE_POLICY_DELETE_SUCCESS',
  SET_CLEAR_FORM: 'SET_CLEAR_FORM',
  RESET_FORM: 'RESET_FORM',
}

export default memberVehiclePolicyActionTypes;