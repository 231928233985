import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import propertyQuoteActionTypes from "./property.types";
import { convertPropertyQuoteForDBSave } from "./property.utils";
import {
  fetchPropertyQuotesFromDBSuccess,
  fetchPropertyQuotesFromDBFailure,
  fetchPropertyQuoteFromDBSuccess,
  fetchPropertyQuoteFromDBFailure,
  propertyQuoteSaveSuccess,
  propertyQuoteSaveFailure,
  propertyQuoteUpdateSuccess,
  propertyQuoteUpdateFailure,
  checkingChatGroupSuccess,
  saveDeleteCometChatSuccess,
  lastStepPropertyQuoteUpdateSuccess,
} from "./property.actions";

function* fetchPropertyQuotesFromDB() {
  try {
    const response = yield axios.get("/api/property-quote");
    const quotes = yield response.data;
    yield put(fetchPropertyQuotesFromDBSuccess(quotes));
  } catch (e) {
    yield put(fetchPropertyQuotesFromDBFailure(e));
  }
}

function* fetchPropertyQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios(`/api/property-quote/${id}`);
    const quote = yield response.data;
    yield put(fetchPropertyQuoteFromDBSuccess(quote));
  } catch (e) {
    yield put(fetchPropertyQuoteFromDBFailure(e));
  }
}

function* savePropertyQuote({ payload: formData }) {
  try {
    const data = yield call(convertPropertyQuoteForDBSave, formData);
    yield axios.post("/api/property-quote", data, configureAxios());
    yield put(propertyQuoteSaveSuccess());
  } catch (e) {
    yield put(propertyQuoteSaveFailure(e));
  }
}

function* updatePropertyQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertPropertyQuoteForDBSave, formData);
    const response = yield axios.patch(
      `/api/property-quote/${id}`,
      data,
      configureAxios()
    );
    const quote = yield JSON.parse(response.config.data);
    if (data.isLastStep) {
      yield put(lastStepPropertyQuoteUpdateSuccess(quote));
    } else {
      yield put(propertyQuoteUpdateSuccess(quote));
    }
  } catch (e) {
    yield put(propertyQuoteUpdateFailure(e));
  }
}

function* checkingChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/property-quote/${id}/check-chat-group`);
    yield checkingChatGroupSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* saveDeleteChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/property-quote/${id}/save-delete-chat`);
    yield saveDeleteCometChatSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* onFetchPropertyQuotesStart() {
  yield takeEvery(
    propertyQuoteActionTypes.FETCH_PROPERTY_QUOTES_FROM_DB_START,
    fetchPropertyQuotesFromDB
  );
}

function* onFetchPropertyQuoteStart() {
  yield takeEvery(
    propertyQuoteActionTypes.FETCH_PROPERTY_QUOTE_FROM_DB_START,
    fetchPropertyQuoteFromDB
  );
}

function* onPropertyQuoteSaveStart() {
  yield takeEvery(
    propertyQuoteActionTypes.START_PROPERTY_QUOTE_SAVE,
    savePropertyQuote
  );
}

function* onPropertyQuoteUpdateStart() {
  yield takeEvery(
    propertyQuoteActionTypes.START_PROPERTY_QUOTE_UPDATE,
    updatePropertyQuote
  );
}

function* onCheckingChatGroup() {
  yield takeEvery(
    propertyQuoteActionTypes.START_CHECKING_CHAT_GROUP_PROPERTY,
    checkingChatGroup
  );
}

function* onSaveDeleteChatGroup() {
  yield takeEvery(
    propertyQuoteActionTypes.SAVE_DELETE_PROPERTY_COMET_CHAT,
    saveDeleteChatGroup
  );
}

export function* propertyQuoteSagas() {
  yield all([
    call(onFetchPropertyQuotesStart),
    call(onFetchPropertyQuoteStart),
    call(onPropertyQuoteSaveStart),
    call(onPropertyQuoteUpdateStart),
    call(onCheckingChatGroup),
    call(onSaveDeleteChatGroup),
  ]);
}
