import { useFormikContext } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import React from 'react';

const PhoneFormat = ({ name, value, errors, touched, disabled }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <PhoneInput
      disabled={disabled}
      country={'us'}
      onlyCountries={['us']}
      specialLabel={false}
      name={name}
      value={value}
      onChange={phoneNumber => {
        setFieldValue(name, phoneNumber);
      }}
      inputClass={
        !!errors[name]
        ? 'phone-invalid'
          : !errors[name] && touched[name]
            ? 'phone-valid'
            : ''
      }
    />
  )
}

export default PhoneFormat;
