import memberDocumentActionTypes from './document.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  uploadedFilesFromDB: [],
  uploadedChunksFromDB: [],
  uploadedFileFromDB: {},
  uploadedChunkFromDB: {},
  docChunkInfo: [],
  isAgent: false,
  showForm: false,
}

const memberDocumentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberDocumentActionTypes.FETCH_UPLOADED_FILES_FROM_DB_SUCCESS:
      return {
        ...state,
        uploadedFilesFromDB: action.payload,
      }
    case memberDocumentActionTypes.FETCH_UPLOADED_CHUNKS_FROM_DB_SUCCESS:
      return {
        ...state,
        uploadedChunksFromDB: action.payload,
      }
    case memberDocumentActionTypes.FETCH_UPLOADED_FILE_FROM_DB_SUCCESS:
      return {
        ...state,
        uploadedFileFromDB: action.payload,
      }
    case memberDocumentActionTypes.FETCH_UPLOADED_CHUNK_FROM_DB_SUCCESS:
      return {
        ...state,
        uploadedChunkFromDB: action.payload,
      }
    case memberDocumentActionTypes.STORE_DOC_CHUNK_INFO:
      return {
        ...state,
        docChunkInfo: action.payload,
      }
    case memberDocumentActionTypes.SET_SHOW_FORM:
      return {
        ...state,
        showForm: action.payload,
      }
    case memberDocumentActionTypes.SET_IS_AGENT:
      return {
        ...state,
        isAgent: action.payload,
      }
    case memberDocumentActionTypes.SET_CLEAR_UPLOAD_FILE_AND_CHUNK:
      return {
        ...state,
        uploadedFilesFromDB: [],
        uploadedChunksFromDB: [],
        uploadedFileFromDB: {},
        uploadedChunkFromDB: {},
      }
    case authActionTypes.LOGOUT:
      return {
        ...state,
        uploadedFilesFromDB: [],
        uploadedChunksFromDB: [],
        uploadedFileFromDB: {},
        uploadedChunkFromDB: {},
        docChunkInfo: [],
      }
    default:
      return state
  }
}

export default memberDocumentReducer;