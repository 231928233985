import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ShowModal = ({
  show,
  body,
  heading,
  secondary,
  primary,
  handleClose,
  handleSecondary,
  handlePrimary,
}) => {

  return (
    <Modal
      size='m'
      show={show}
      dialogClassName={`ie-modal`}
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded border-bottom-0 ml-auto w-100'>
        <Modal.Title className='pt-5'>{heading}</Modal.Title>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        {primary
          ?
          <Button
            variant='btn btn-outline-danger'
            onClick={handlePrimary}
          >
            {primary}
          </Button>
          : ''
        }
        {secondary
          ?
          <Button
            variant='success'
            onClick={handleSecondary}
          >
            {secondary}
          </Button>
          : ''
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ShowModal;