import authActionTypes from '../auth/auth.types';

let initialState = {
  users: [],
  user: {},
  isAuthenticated: false,
  token: null,
  isRefresh: true,
  displayMessage: null,
  isSubmitted: false,
};

const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));

if (PERSISTED_STATE && PERSISTED_STATE.auth) {
  initialState = JSON.parse(PERSISTED_STATE.auth);
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.FETCH_USERS_FROM_DB_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case authActionTypes.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        displayMessage: null,
      };
    case authActionTypes.SET_IS_REFRESH:
      return {
        ...state,
        isRefresh: action.payload,
      };
    case authActionTypes.REGISTRATION_SUCCESS:
    case authActionTypes.ACCOUNT_ACTIVATION_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        displayMessage: null,
      };
    case authActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        displayMessage: null,
        isRefresh: true,
      };
    case authActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: false,
        token: null,
        displayMessage: null,
        isRefresh: true,
      };
    case authActionTypes.REGISTRATION_FAILURE:
    case authActionTypes.ACCOUNT_ACTIVATION_FAILURE:
    case authActionTypes.LOGIN_FAILURE:
    case authActionTypes.FORGOT_PASSWORD_FAILURE:
    case authActionTypes.FORGOT_PASSWORD_SUCCESS:
    case authActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        displayMessage: action.payload,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        users: [],
        user: {},
        isAuthenticated: false,
        token: null,
        isRefresh: true,
        displayMessage: null,
        isSubmitted: false,
      };
    case authActionTypes.SET_IS_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
