import { AGENT_MEDICAL_QUOTE_CMS_DATA }
  from '../../../pages/agent-quotes/medical/medical-quote.cms';

// const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));
// let memberDetails;

// if (PERSISTED_STATE) {
//   memberDetails = JSON.parse(PERSISTED_STATE.auth);
// }

export const convertAgentMedicalQuoteFromDBSave = (data) => {
  const {
    form: {
      policyType,
      networkType,
      dentalPlan,
      visionPlan,
    }
  } = AGENT_MEDICAL_QUOTE_CMS_DATA;

  const policyTypes = policyType.dropdown.policyType;
  const medigapPlans = policyType.dropdown.medigapPlan;
  const LOfCoverages = policyType.dropdown.LOfCoverage;
  const networkTypes = networkType.dropdown.networkType;
  const dentalPlans = dentalPlan.dropdown.hasDentalPlan;
  const visionPlans = visionPlan.dropdown.hasVisionPlan;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    createdAgentQuoteDate: !data.isSelected ? new Date() : '',
    policyStartDate: data.isSelected ? new Date() : '',
    storedMembers: data.storedMembers ? data.storedMembers : '',
    documents: data.documents ? data.documents : [],
    polDocuments: data.polDocuments ? data.polDocuments : [],
    quoteDetails: {
      type: data.quoteType ? data.quoteType : '',
      quoteId: data.quoteId ? data.quoteId : '',
      agentId: data.agentId ? data.agentId : '',
      biddingId: data.biddingId ? data.biddingId : '',
      createdDate: data.quoteCreatedDate ? data.quoteCreatedDate : '',
      isCaptive: data.isCaptive ? data.isCaptive : '',
      servicesProvidedTo: data.servicesProvidedTo ? data.servicesProvidedTo : '',
      isSelected: data.isSelected ? data.isSelected : false,
      isPolicy: data.isPolicy ? data.isPolicy : false,
    },
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    effectiveDates: {
      start: data.startDate ? data.startDate : '',
      end: data.endDate ? data.endDate : '',
    },
    policyType: {
      catastrophic:
        data.medicalPolicyType !== null
          ? data.medicalPolicyType === policyTypes[0].value
          : false,
      HDHP:
        data.medicalPolicyType !== null
          ? data.medicalPolicyType === policyTypes[1].value
          : false,
      medigap: {
        isTypeOf:
          data.medicalPolicyType !== null
            ? data.medicalPolicyType === policyTypes[2].value
            : false,
        medigapPlan: {
          planA:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[0].value
              : false,
          planB:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[1].value
              : false,
          planC:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[2].value
              : false,
          planD:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[3].value
              : false,
          planF:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[4].value
              : false,
          planG:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[5].value
              : false,
          planK:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[6].value
              : false,
          planL:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[7].value
              : false,
          planM:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[8].value
              : false,
          planN:
            data.medigapPlan !== null
              ? data.medigapPlan === medigapPlans[9].value
              : false,
        }
      },
      standardOrFull: {
        isTypeOf:
          data.medicalPolicyType !== null
            ? data.medicalPolicyType === policyTypes[3].value
            : false,
        LOfCoverage: {
          bronze:
            data.LOfCoverage !== null
              ? data.LOfCoverage === LOfCoverages[0].value
              : false,
          silver:
            data.LOfCoverage !== null
              ? data.LOfCoverage === LOfCoverages[1].value
              : false,
          gold:
            data.LOfCoverage !== null
              ? data.LOfCoverage === LOfCoverages[2].value
              : false,
          platinum:
            data.LOfCoverage !== null
              ? data.LOfCoverage === LOfCoverages[3].value
              : false,
        }
      }
    },
    networkType: {
      POS:
        data.networkType !== null
          ? data.networkType === networkTypes[0].value
          : false,
      HMO:
        data.networkType !== null
          ? data.networkType === networkTypes[1].value
          : false,
      PPO:
        data.networkType !== null
          ? data.networkType === networkTypes[2].value
          : false,
      EPO:
        data.networkType !== null
          ? data.networkType === networkTypes[3].value
          : false,
    },
    outOfNetworkCo: data.outOfNetworkCo ? data.outOfNetworkCo : '',
    deductible: {
      inNetwork: {
        individual: data.deductInNetInd ? data.deductInNetInd : '',
        family: data.deductInNetFam ? data.deductInNetFam : '',
      },
      outOfNetwork: {
        individual: data.deductOutOfNetInd ? data.deductOutOfNetInd : '',
        family: data.deductOutOfNetFam ? data.deductOutOfNetFam : '',
      },
    },
    maxOut: {
      inNetwork: {
        individual: data.maxInNetInd ? data.maxInNetInd : '',
        family: data.maxInNetFam ? data.maxInNetFam : '',
      },
      outOfNetwork: {
        individual: data.maxOutOfNetInd ? data.maxOutOfNetInd : '',
        family: data.maxOutOfNetFam ? data.maxOutOfNetFam : '',
      },
    },
    copay: {
      inNetwork: {
        routineVisit: data.inNetVisit ? data.inNetVisit : '',
        specialist: data.inNetSpecialist ? data.inNetSpecialist : '',
        urgentCare: data.inNetCare ? data.inNetCare : '',
      },
      outOfNetwork: {
        routineVisit: data.outOfNetVisit ? data.outOfNetVisit : '',
        specialist: data.outOfNetSpecialist ? data.outOfNetSpecialist : '',
        urgentCare: data.outOfNetCare ? data.outOfNetCare : '',
      },
    },
    presCoverage: {
      tier1: data.tier1 ? data.tier1 : '',
      tier2: data.tier2 ? data.tier2 : '',
      tier3: data.tier3 ? data.tier3 : '',
      tier4: data.tier4 ? data.tier4 : '',
      tier5: data.tier5 ? data.tier5 : '',
      tier6: data.tier6 ? data.tier6 : '',
    },
    dentalPlan: {
      isTypeOf:
        data.hasDentalPlan !== null
          ? data.hasDentalPlan === dentalPlans[0].value
          : false,
      dentist: {
        firstName: data.DFirstName ? data.DFirstName : '',
        lastName: data.DLastName ? data.DLastName : '',
        streetAddress: data.DStreet ? data.DStreet : '',
        city: data.DCity ? data.DCity : '',
        state: data.DState ? data.DState : '',
        phoneNumber: data.DPhoneNumber ? data.DPhoneNumber : '',
        website: data.DWebsite ? data.DWebsite : '',
        elaborate: data.DElaborate ? data.DElaborate : '',
      }
    },
    visionPlan: {
      isTypeOf:
        data.hasVisionPlan !== null
          ? data.hasVisionPlan === visionPlans[0].value
          : false,
      optometrist: {
        firstName: data.VFirstName ? data.VFirstName : '',
        lastName: data.VLastName ? data.VLastName : '',
        streetAddress: data.VStreet ? data.VStreet : '',
        city: data.VCity ? data.VCity : '',
        state: data.VState ? data.VState : '',
        phoneNumber: data.VPhoneNumber ? data.VPhoneNumber : '',
        website: data.VWebsite ? data.VWebsite : '',
        elaborate: data.VElaborate ? data.VElaborate : '',
      }
    },
    additionalInfo: data.additionalInfo ? data.additionalInfo : '',
  }
  return payload;
}




