import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../../redux/auth/auth.selectors';
import {
  startFetchingVehicleFromDB,
  toggleVehicleForm,
  toggleVehicleInfoEdit,
  setClearVehicleForm,
  startVehicleDelete,
} from '../../../redux/quotes/vehicle/vehicle.actions';
import {
  selectVehicleById,
  selectShowVehicleForm,
  selectShowVehicleInfoEdit,
} from '../../../redux/quotes/vehicle/vehicle.selectors';
import {
  selectPrimeMember,
} from '../../../redux/member/member.selectors';
import { VEHICLE_CMS_DATA } from '../../../pages/quotes/vehicle/vehicle.cms';
import { useHistory } from 'react-router-dom';
import ShowModal from '../../modal/show-modal.component';

const EditVehicles = ({
  primeMember,
  currentUser,
  showVehicleForm,
  toggleVehicleForm,
  startVehicleDelete,
  setClearVehicleForm,
  startFetchingVehicleFromDB,
}) => {
  const {
    modal: { heading, action }
  } = VEHICLE_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname;
  const vehicleP = '/household-vehicle-information';

  const [show, setShow] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const [storeVehicleInfo, setStoreVehicleInfo] = useState([]);

  const handleUpdate = (id) => {
    if (id) {
      startFetchingVehicleFromDB(id);
    }
    if (!showVehicleForm) {
      toggleVehicleForm(true);
    } else {
      toggleVehicleForm(false);
      setClearVehicleForm(true);
    } 
  }

  const handleDelete = () => {
    startVehicleDelete(storeIds[0], storeIds[1]);
    setClearVehicleForm(true);
    setShow(false);
    window.location.reload();
  }

  const handleCancel = () => {
    if (!showVehicleForm) {
      setStoreIds([]);
      setStoreVehicleInfo([]);
    } 
    setShow(false);
    if (path === vehicleP) {
      window.scrollTo({ top: 0 });
    }
  }

  const householdVehicles =
    primeMember && primeMember.householdVehicles
      ? primeMember.householdVehicles 
      : []

  return (
    <div className={
      path === vehicleP
        ? 'pb-5 mb-4 text-right'
        : 'mb-3 text-right'
      }
    >
      <Table
        bordered
        responsive
        className='shadow-sm p-3 mb-4 bg-white rounded'
      >
        {householdVehicles && householdVehicles.length > 0 ? (
          <>
            <thead className='bg-light'>
              <tr>
                <th>Vin</th>
                <th>Make</th>
                <th>Model</th>
                <th>Year</th>
                <th></th>
              </tr>
            </thead>
            {householdVehicles.map((hv, i) => (
              <tbody key={hv._id}>
                <tr key={i}>
                  <td>{hv.specs.vin}</td>
                  <td>{hv.specs.make}</td>
                  <td>{hv.specs.model}</td>
                  <td>{hv.specs.year}</td>
                  <td>
                    <Button
                      className='ml-1'
                      variant={
                        showVehicleForm && hv._id === storeIds[0]
                          ? 'warning'
                          : 'primary'
                      }
                      onClick={() => {
                        handleUpdate(hv._id)
                        setStoreIds([hv._id, currentUser.member]);
                        setStoreVehicleInfo([hv.specs.vin, hv.specs.make, hv.specs.model, hv.specs.year])
                      }}
                    >
                      {showVehicleForm && hv._id === storeIds[0]
                        ? action.hide
                        : action.update
                      }                      
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </>
        ) : null}
      </Table>
      <ShowModal
        show={show}
        heading={`${heading} ${storeVehicleInfo[0]}, ${storeVehicleInfo[1]}, ${storeVehicleInfo[2]}, ${storeVehicleInfo[3]}?`}
        secondary={action.cancel}
        primary={action.delete}
        handleClose={handleCancel}
        handleSecondary={handleCancel}
        handlePrimary={handleDelete}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  vehicle: selectVehicleById,
  primeMember: selectPrimeMember,
  showVehicleForm: selectShowVehicleForm,
  showVehicleInfoEdit: selectShowVehicleInfoEdit,
});

export default connect(mapStateToProps, {
  startVehicleDelete,
  toggleVehicleForm,
  toggleVehicleInfoEdit,
  setClearVehicleForm,
  startFetchingVehicleFromDB,
})(EditVehicles);