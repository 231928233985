const agentActionTypes = {
  FETCH_ALL_AGENTS_FROM_DB_START: "FETCH_ALL_AGENTS_FROM_DB_START",
  FETCH_ALL_AGENTS_FROM_DB_SUCCESS: "FETCH_ALL_AGENTS_FROM_DB_SUCCESS",
  FETCH_ALL_AGENTS_FROM_DB_FAILURE: "FETCH_ALL_AGENTS_FROM_DB_FAILURE",
  FETCH_AGENT_FROM_DB_START: "FETCH_AGENT_FROM_DB_START",
  FETCH_AGENT_FROM_DB_SUCCESS: "FETCH_AGENT_FROM_DB_SUCCESS",
  FETCH_AGENT_FROM_DB_FAILURE: "FETCH_AGENT_FROM_DB_FAILURE",
  LOAD_STATES_FROM_DB_START: "LOAD_STATES_FROM_DB_START",
  LOAD_STATES_FROM_DB_SUCCESS: "LOAD_STATES_FROM_DB_SUCCESS",
  LOAD_STATES_FROM_DB_FAILURE: "LOAD_STATES_FROM_DB_FAILURE",
  LOAD_AGENT_CARRIERS_FROM_DB_START: "LOAD_AGENT_CARRIERS_FROM_DB_START",
  LOAD_AGENT_CARRIERS_FROM_DB_SUCCESS: "LOAD_AGENT_CARRIERS_FROM_DB_SUCCESS",
  LOAD_AGENT_CARRIERS_FROM_DB_FAILURE: "LOAD_AGENT_CARRIERS_FROM_DB_FAILURE",
  LOAD_OCCUPATIONS_FROM_DB_START: "LOAD_OCCUPATIONS_FROM_DB_START",
  LOAD_OCCUPATIONS_FROM_DB_SUCCESS: "LOAD_OCCUPATIONS_FROM_DB_SUCCESS",
  LOAD_OCCUPATIONS_FROM_DB_FAILURE: "LOAD_OCCUPATIONS_FROM_DB_FAILURE",
  START_AGENT_UPDATE: "START_AGENT_UPDATE",
  AGENT_UPDATE_SUCCESS: "AGENT_UPDATE_SUCCESS",
  START_AGENT_UPDATE_LAST_STEP: "START_AGENT_UPDATE_LAST_STEP",
  AGENT_UPDATE_LAST_STEP_SUCCESS: "AGENT_UPDATE_LAST_STEP_SUCCESS",
  SET_AGENT_CARRIERS_OBJECT: "SET_AGENT_CARRIERS_OBJECT",
  SET_FORM_DATA: "SET_FORM_DATA",
  DECREMENT_CURRENT_STEP: "DECREMENT_CURRENT_STEP",
  INCREMENT_CURRENT_STEP: "INCREMENT_CURRENT_STEP",
  FETCH_VERIFY_AGENT_START: "FETCH_VERIFY_AGENT_START",
  FETCH_VERIFY_AGENT_SUCCESS: "FETCH_VERIFY_AGENT_SUCCESS",
  FETCH_VERIFY_AGENT_FAILURE: "FETCH_VERIFY_AGENT_FAILURE",
  FETCH_BRAINTREE_CLIENT_TOKEN_START: "FETCH_BRAINTREE_CLIENT_TOKEN_START",
  FETCH_BRAINTREE_CLIENT_TOKEN_SUCCESS: "FETCH_BRAINTREE_CLIENT_TOKEN_SUCCESS",
  FETCH_BRAINTREE_CLIENT_TOKEN_FAILURE: "FETCH_BRAINTREE_CLIENT_TOKEN_FAILURE",
  START_CHECKOUT_PAYPAL: "START_CHECKOUT_PAYPAL",
  CHECKOUT_PAYPAL_SUCCESS: "CHECKOUT_PAYPAL_SUCCESS",
  CHECKOUT_PAYPAL_FAILURE: "CHECKOUT_PAYPAL_FAILURE",
  START_GET_PERSON_FROM_VERIFF: 'START_GET_PERSON_FROM_VERIFF',
  GET_PERSON_FROM_VERIFF_SUCCESS: 'GET_PERSON_FROM_VERIFF_SUCCESS',
  GET_PERSON_FROM_VERIFF_FAILURE: 'GET_PERSON_FROM_VERIFF_FAILURE',
  START_GET_CODE_TO_VERIFY_PHONE_NUMBER: "START_GET_CODE_TO_VERIFY_PHONE_NUMBER",
  GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS: "GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS",
  GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE: "GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE",
  START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER: "START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER",
  CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS: "CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS",
  CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE: "CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE",
  RESET_FORM: "RESET_FORM",
  RESET_AGENT: "RESET_AGENT",
};

export default agentActionTypes;