export const REGISTRATION_CMS_DATA = {
  email: {
    label: 'Email Address',
    placeholder: 'Enter Email Address',
    invalid: 'Invalid Email Format',
    required: 'Email is a required field'
  },
  password: {
    label: 'Password',
    placeholder: 'Create Password',
    invalid: 'Invalid Password. Please refer below.',
    required: 'Password is a required field',
    rules: {
      title: 'Your password must contain at least:',
      firstRule: '8 characters',
      secondRule: '1 uppercase letter',
      thirdRule: '1 lowercase letter',
      fourthRule: '1 number'
    }
  },
  label: {
    tou: 'By continuing, you agree to the',
    login: 'Already have an account?'
  },
  action: {
    register: 'Register',
    tou: 'InsurEasier Terms of Service and Privacy Policy',
    login: 'Login'
  },
  errObj: {
    accountExists: 'Hmm, it seems that an account with this email already exists. '
    + 'If you have forgotten your password, go back to login and click on Forgot Password?'
  },
};
