import { Field, FieldArray } from 'formik';
import React, { createRef, useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import {
  selectOccupations,
} from '../../redux/member/member.selectors';
import ErrorMessage from '../error-message/error-message.component';
import InfoIcon from '../info-icon/info-icon.component';
import NumberFormation from '../number-format/number-format.component';
import RadioButton from '../radio-button/radio-button.component';

const WorkDetails = ({
  values,
  touched,
  errors,
  occupations,
  reset,
}) => {
  const {
    form: {
      workDetails: { title, description, label },
    },
    popOver: { workDetails: { content } },

  } = MEMBER_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  const occupationRef = createRef();

  let occupationsA = values.occupations;

  useEffect(() => {
    reset && occupationRef.current.clear();
  }, [reset, occupationRef]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <div>
            {occupationsA.length !== 0 ? (
              <FieldArray
                name='occupations'
                render={(arrayHelpers) => (
                  <div>
                    {occupationsA.map((o, i) => (
                      <div key={i}>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            control-id={`occupation${i}`}
                            optional='true'
                          >
                            <Form.Label>{label.occupation}</Form.Label>
                            <InfoIcon
                              className='float-right'
                              content={content.occupation}
                            />
                            <InputGroup>
                              <Field name={`occupations[${i}].occupation`}>
                                {({ field, form }) => (
                                  <Typeahead
                                    disabled={
                                      path === agentBid ? true : false
                                    }
                                    id={`select-member-occupation${i}`}
                                    ref={occupationRef}
                                    name={`occupations[${i}].occupation`}
                                    options={occupations}
                                    isValid={
                                      touched.occupations && !errors.occupations
                                    }
                                    isInvalid={!!errors.occupations}
                                    selected={[
                                      occupationsA[i].occupation,
                                    ]}
                                    onChange={(selectedOccupation) => {
                                      if (selectedOccupation.length !== 0) {
                                        form.setFieldValue(
                                          field.name,
                                          selectedOccupation[0]
                                        );
                                      } 
                                    }}
                                    onInputChange={(text) => {
                                      form.setFieldValue(field.name, text);
                                    }}
                                  />
                                )}
                              </Field>
                              {i >= 0 && occupationsA.length !== 1 ? (
                                <InputGroup.Prepend>
                                  <Button
                                    variant='outline-danger'
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </InputGroup.Prepend>
                              ) : null}
                              <Form.Control.Feedback type='invalid'>
                                <ErrorMessage
                                  name={`occupations[${i}].occupation`}
                                ></ErrorMessage>
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} lg={12}>
                            {occupationsA.length - 1 === i &&
                              path !== agentBid ? (
                              <div className='text-left'>
                                <Button
                                  variant='primary'
                                  onClick={() => {
                                    if (occupationsA[i].occupation !== '') {
                                      arrayHelpers.push({ occupation: '' })
                                    }
                                  }}
                                >
                                  Add Another
                                </Button>
                              </div>
                            ) : null}
                          </Form.Group>
                        </Form.Row>
                      </div>
                    ))}
                  </div>
                )}
              ></FieldArray>
            ) : null}
            <Form.Row>
              <Form.Group
                as={Col}
                lg={6}
                control-id='annual-inc'
                optional='true'
              >
                <Form.Label>{label.annualIncome}</Form.Label>
                <InfoIcon
                  className='float-right'
                  content={content.annualIncome}
                />
                <InputGroup className='mb-2'>
                  <InputGroup.Prepend>
                    <InputGroup.Text>$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <NumberFormation
                    readOnly={
                      path === agentBid ? true : false
                    }
                    name='annualIncome'
                    value={values.annualIncome}
                    errors={errors}
                    touched={touched}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.annualIncome}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group
                as={Col}
                lg={12}
                controlId='military-service'
                optional='true'
              >
                <Form.Label>{label.militaryServiceType}</Form.Label>
                <InfoIcon
                  className='float-right'
                  content={content.military}
                />
                <br/>
                {label.dropdown.militaryServiceTypes.map((option, i) => (
                  <RadioButton
                    key={i}
                    idx={i}
                    name='militaryServiceType'
                    option={option}
                  />
                ))}
                <Form.Control.Feedback type='invalid'>
                  {errors.militaryServiceType}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  occupations: selectOccupations,
});

export default connect(mapStateToProps, {})(WorkDetails);
