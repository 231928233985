import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';
import { ModalState, ModalTypes } from '../modal/modal.types';
import memberActionTypes from './member.types';

const payload = {
  context: 'member',
  status: '',
  error: '',
};

export const setIsMember = (toggle) => ({
  type: memberActionTypes.SET_IS_MEMBER,
  payload: toggle,
});

export const setIsSubmitted = (toggle) => ({
  type: memberActionTypes.SET_IS_SUBMITTED,
  payload: toggle,
});

export const setFormData = (data) => ({
  type: memberActionTypes.MEMBER_SET_FORM_DATA,
  payload: data,
})

export const decrementCurrentStep = (currentStep) => ({
  type: memberActionTypes.DECREMENT_CURRENT_STEP,
  payload: currentStep,
})

export const incrementCurrentStep = (currentStep) => ({
  type: memberActionTypes.INCREMENT_CURRENT_STEP,
  payload: currentStep,
})

export const setIsUpdated = (toggle) => ({
  type: memberActionTypes.SET_IS_UPDATED,
  payload: toggle,
});

export const setClearMemberForm = () => ({
  type: memberActionTypes.SET_CLEAR_MEMBER_FORM,
});

export const startFetchingAllMembersFromDB = () => ({
  type: memberActionTypes.FETCH_ALL_MEMBERS_FROM_DB_START,
});

export const fetchAllMembersFromDBSuccess = (members) => ({
  type: memberActionTypes.FETCH_ALL_MEMBERS_FROM_DB_SUCCESS,
  payload: members,
});

export const fetchAllMembersFromDBFailure = (e) => ({
  type: memberActionTypes.FETCH_ALL_MEMBERS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingPrimeMemberFromDB = (id) => ({
  type: memberActionTypes.FETCH_PRIME_MEMBER_FROM_DB_START,
  payload: id,
});

export const fetchPrimeMemberFromDBSuccess = (member) => ({
  type: memberActionTypes.FETCH_PRIME_MEMBER_FROM_DB_SUCCESS,
  payload: member,
});

export const fetchPrimeMemberFromDBFailure = (e) => ({
  type: memberActionTypes.FETCH_PRIME_MEMBER_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingMemberFromDB = (id) => ({
  type: memberActionTypes.FETCH_MEMBER_FROM_DB_START,
  payload: id,
});

export const fetchMemberFromDBSuccess = (member) => ({
  type: memberActionTypes.FETCH_MEMBER_FROM_DB_SUCCESS,
  payload: member,
});

export const fetchMemberFromDBFailure = (e) => ({
  type: memberActionTypes.FETCH_MEMBER_FROM_DB_FAILURE,
  payload: e,
});

export const toggleMemberForm = (toggle) => ({
  type: memberActionTypes.TOGGLE_MEMBER_FORM,
  payload: toggle,
});

export const toggleMemberInfoEdit = (toggle) => ({
  type: memberActionTypes.TOGGLE_MEMBER_INFO_EDIT,
  payload: toggle,
});

export const toggleMemberTypes = (toggle) => ({
  type: memberActionTypes.TOGGLE_MEMBER_TYPES,
  payload: toggle,
});

export const startLoadingStatesFromDB = () => ({
  type: memberActionTypes.LOAD_STATES_FROM_DB_START,
});

export const loadStatesFromDBSuccess = (data) => ({
  type: memberActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data,
});

export const loadStatesFromDBFailure = (e) => ({
  type: memberActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_CMS_DATA.errMsg.states,
});

export const startLoadingOccupationsFromDB = () => ({
  type: memberActionTypes.LOAD_OCCUPATIONS_FROM_DB_START,
});

export const loadOccupationsFromDBSuccess = (data) => ({
  type: memberActionTypes.LOAD_OCCUPATIONS_FROM_DB_SUCCESS,
  payload: data,
});

export const loadOccupationsFromDBFailure = (e) => ({
  type: memberActionTypes.LOAD_OCCUPATIONS_FROM_DB_FAILURE,
  payload: MEMBER_CMS_DATA.errMsg.occupations,
});

export const startGetCodeToVerifyPhoneNumber = (id, data) => ({
  type: memberActionTypes.MEMBER_START_GET_CODE_TO_VERIFY_PHONE_NUMBER,
  payload: { id, data }
}); 

export const getCodeToVerifyPhoneNumberSuccess = (data) => ({
  type: memberActionTypes.MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS,
  payload: data,
});

export const getCodeToVerifyPhoneNumberFailure = (e) => ({
  type: memberActionTypes.MEMBER_GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE,
  payload: e,
})

export const startCheckCodeToVerifyPhoneNumber = (id, data) => ({
  type: memberActionTypes.MEMBER_START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER,
  payload: { id, data }
});

export const checkCodeToVerifyPhoneNumberSuccess = (data) => ({
  type: memberActionTypes.MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS,
  payload: data,
});

export const checkCodeToVerifyPhoneNumberFailure = (e) => ({
  type: memberActionTypes.MEMBER_CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE,
  payload: e,
});

export const startMemberSave = (member) => ({
  type: memberActionTypes.START_MEMBER_SAVE,
  payload: member,
});

export const memberSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const memberSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startMemberUpdate = (id, formData) => ({
  type: memberActionTypes.START_MEMBER_UPDATE,
  payload: { id, formData },
});

export const memberUpdateSuccess = () => ({
  type: memberActionTypes.MEMBER_UPDATE_SUCCESS,
});

export const memberUpdateLastStepSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.SUCCESS,
  },
});

export const memberUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startMemberQuoteSave = (member) => ({
  type: memberActionTypes.START_MEMBER_QUOTE_SAVE,
  payload: member,
});

export const memberQuoteSaveSuccess = () => ({
  type: memberActionTypes.MEMBER_QUOTE_SAVE_SUCCESS,
});

export const memberQuoteSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startMemberQuoteUpdate = (id, formData) => ({
  type: memberActionTypes.START_MEMBER_QUOTE_UPDATE,
  payload: { id, formData },
});

export const memberQuoteUpdateSuccess = () => ({
  type: memberActionTypes.MEMBER_QUOTE_UPDATE_SUCCESS,
});

export const memberQuoteUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startMemberDelete = (id, PId, HId) => ({
  type: memberActionTypes.START_MEMBER_DELETE,
  payload: { id, PId, HId },
});

export const memberDeleteSuccess = () => ({
  type: memberActionTypes.MEMBER_DELETE_SUCCESS,
});

export const memberDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});
