export const QUOTE_REQUESTS_CMS_DATA = {
  imagesSteps: [
    {
      title: 'Request quotes',
      title2: '(seamlessly)',
      overlay: 'Register/Login, fill out the main member form, request/Get Quotes, and complete the forms specific to the policy type you are requesting. We have growing list of insurances we support.',
      url: './images/relax.webp'
    },
    {
      title: 'AI selects',
      title2: 'optimal agents',
      overlay: 'Wait for our AI to select the optimal agents to create quotes for you, based on your needs and the carriers represented. You could get instant quotes elsewhere but they are inaccurate and end up wasting more time.',
      url: './images/ai.webp'
    },
    {
      title: 'Message',
      title2: 'all agents (at once)',
      overlay: 'Once agents are selected, you will be able to communicate with all of them, at once, in a chat room so that you do not need to reiterate your concerns or responses to multiple agents or carriers.',
      url: './images/chat.webp'
    },
    {
      title: 'Select a quote',
      title2: '/ get a policy',
      overlay: 'The entire process is on one site. Only InsurEasier allows you to select your desired quote, and the corresponding agent will work with you to turn the quote into a policy, which will be stored here for access at any time, anywhere.',
      url: './images/policy.webp'
    },
  ],
}