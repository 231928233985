export const AGENT_ANNUITY_QUOTE_CMS_DATA = {
  heading: 'Annuity Quote',
  subHeading: 'Please complete this form to submit the Annuity quote you created for the following Member. This form is designed to give the Member a fair comparison of quotes.',
  form: {
    button: {
      submit: 'Submit',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    },
    personalDetails: {
      title: 'Member Information',
      description: '',
      label: {
        member: 'Select a member on the list or "Add another"',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        gender: 'Gender',
        memberType: 'Member relation',
        male: 'Male',
        female: 'Female',
        dateOfBirth: 'Date of birth',
      },
      dropdown: {
        memberTypes: [
          { value: 'spouse', label: 'Spouse' },
          { value: 'partner', label: 'Domestic partner' },
          { value: 'child', label: 'Child' },
          { value: 'adoptedChild', label: 'Adopted child' },
          { value: 'parent', label: 'Parent' },
          { value: 'sibling', label: 'Sibling' },
          { value: 'careGiver', label: 'Caregiver' },
          { value: 'stepChild', label: 'Stepchild' },
          { value: 'transferStudent', label: 'Transfer student' },
        ],
        genders: [
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
        ],
      },
    },
    documents: {
      title: 'Document',
      description: 'Please upload the quote document for the Member to view.',
      label: 'Upload quote here',
    },
    guaranteedPremium: {
      title: 'Guaranteed Level Premium',
      description: 'If the premium is set to increase at any point please select No and explain the increment amounts and period(s) in which the increase happens.',
      label: {
        guaranteedPremium: 'Is the policy a Guaranteed Level Premium?',
        increments: 'Increment periods and amounts',
      },
      dropdown: [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
      ]
    },
    type: {
      title: 'Annuity Type',
      description: '"Immediate Income" and "Deferred" Annuity are the two main types of annuities. After selecting one other fields will populate so that you can elaborate.',
      label: {
        typeOfAnnuity: 'Type of Annuity',
        startDate: 'Income start date',
        endDate: 'Income end date',
        monthlyIncome: 'Monthly income',
        maturityDate: 'Maturity date',
        maturityPayOut: 'Maturity pay out',
        paidFullAmount: 'Paid in full amount',
        monthlyAmount: 'Monthly amount',
      },
      dropdown: {
        annuity: [
          { value: 'immediateIncome', label: 'Immediate Income' },
          { value: 'deferred', label: 'Deferred' },
        ],
        maturityPayOut: [
          { value: 'paidFull', label: 'Paid in full' },
          { value: 'monthly', label: 'Monthly' },
        ],
      }
    },
    subType: {
      title: 'Subtype',
      description: 'This is the investment type and depends on how much risk someone wants to take on their investments.',
      label: {
        subType: 'Subtype',
        setInterest: 'Set interest',
        guaranteedInterest: 'Guaranteed interest',
        predictedInterest: 'Predicted interest',
      },
      dropdown: [
        { value: 'fixed', label: 'Fixed' },
        { value: 'indexed', label: 'Indexed' },
        { value: 'variable', label: 'Variable' },
      ]
    },
    additionalInfo: {
      title: 'Additional Information',
      description: 'Any other pertinent information about the policy, including highlights unique to this Carrier, additional riders, etc. Also, feel free to sell yourself and your quote here.',
      label: {
        additionalInfo: 'Please provide additional information'
      }
    }
  },
  errMsg: {
    states:
      'There was an error loading states from database. Please try again later',
    carriers:
      'There was an error loading cariers from database. Please try again later',
  },
  modal: {
    heading: 'Do you want to delete',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    content: {
      guaranteedPremium: 'Beware: If you did not already know, many Annuity and Life policies have premiums that periodically increase, whether each year or after so many years. If you have a Guaranteed Level Premium then you do not need to worry and will continue paying the same premium. This is the type of information that InsurEasier has the Agents provide up-front when purchasing policies through us.',
      annuityType: {
        type: 'An Immediate Income Annuity is when a lump sum is paid into the Annuity and the Annuity starts paying the benefit immediately, while it is earning interest. Since you only have access to some of the money, the rest is being invested so that it lasts longer. A Deferred Annuity is the typical Annuity that is paid into on a periodic (usually monthly) basis and is generally used for retirement by collecting a lump sum or periodic payments from the Carrier (once retired).',
        subType: 'Fixed interest is a set interest amount that does not fluctate. Indexed is when there is a minimum, guaranteed interest, but also a cap or maximum interest amount. Variable has no minimum or maximum limit of interest but there is also a risk of losing invested money.',
        incomeEndDate: 'This date might be an agreed upon date or it might be indefinite, meaning that you will collect payments until you are deceased, at which point a spouse could continue receiving payments. But, it might also have a maturity date when payments would stop before someone or their spouse is deceased. That would usually be when one or the other lives sometime over the age of 100.',
        monthlyIncome: 'This is the amount expected to be received from the Carrier each month. If the pay out period is something other than monthly, please calculate equivalent for if it were monthly.',
        maturityDate: 'The Maturity date of an Annuity is when the Annuity is designed to start paying the invested money back. It could be a lump sum or a periodic payment.',
        maturityPayOut: 'You can decide whether you want a lump some of money (Paid in full) or periodic payments from the Carrier, once your Annuity matures. Different Carriers have different leniencies but however you initially set up the Annuity (Paid in full or Monthly) there is always work arounds to get the other if desired when it comes time to collecting.',
        monthlyAmount: 'This is the amount expected to be received from the Carrier each month. If the pay out period is something other than monthly, please calculate equivalent for if it were monthly.',
      },
      subType: {
        setInterest: 'This is the set interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement.',
        guaranteedInterest: 'This is the minimum interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement. Being an Indexed policy, the interest could be greater so it is likely that, if premimums are kept up, the investment will be much greater.',
        predictedInterest: 'Being that the interest is variable, there is not a minimum or maximum interest rate. Therefore, to give an idea of the potential investment, Insurance Carriers might provide a predicted interest rate based on their investment history with other clients.',
      }
    }
  },
  readOnlyTitles: {
    type: {
      immediateIncome: {
        title: 'Immediate income',
        numbers: [
          {
            key: 'monthlyIncome',
            title: 'Monthly income',
            dataType: 'number',
            info: 'This is the amount expected to be received from the Carrier each month. If the pay out period is something other than monthly, please calculate equivalent for if it were monthly.',
            value: ''
          },
        ],
        durationOfIncome: {
          dates: [
            {
              key: 'start',
              title: 'Income start date',
              dataType: 'date',
              value: ''
            },
            {
              key: 'end',
              title: 'Income end date',
              dataType: 'date',
              info: 'This date might be an agreed upon date or it might be indefinite, meaning that you will collect payments until you are deceased, at which point a spouse could continue receiving payments. But, it might also have a maturity date when payments would stop before someone or their spouse is deceased. That would usually be when one or the other lives sometime over the age of 100.',
              value: ''
            },
          ],
        },
      },
      deferred: {
        title: 'Deffered',
        dates: [
          {
            key: 'maturityDate',
            title: 'Maturity date',
            dataType: 'date',
            info: 'The Maturity date of an Annuity is when the Annuity is designed to start paying the invested money back. It could be a lump sum or a periodic payment.',
            value: ''
          },
        ],
        maturityPayOut: {
          paidFull: {
            numbers: [
              {
                key: 'paidFullAmount',
                title: 'Paid in full amount',
                dataType: 'number',
                value: ''
              },
            ],
          },
          monthly: {
            numbers: [
              {
                key: 'monthlyAmount',
                title: 'Monthly',
                dataType: 'number',
                info: 'This is the amount expected to be received from the Carrier each month. If the pay out period is something other than monthly, please calculate equivalent for if it were monthly.',
                value: ''
              },
            ],
          },
        }
      }
    },
    subType: {
      fixed: {
        title: 'Fixed',
        numbers: [
          {
            key: 'setInterest',
            title: 'Set interest',
            dataType: 'number',
            info: 'This is the set interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement.',
            value: ''
          },
        ],
      },
      indexed: {
        title: 'Indexed',
        numbers: [
          {
            key: 'guaranteedInterest',
            title: 'Guaranteed interest',
            dataType: 'number',
            info: 'This is the minimum interest amount which is used to figure out how much the investment will be worth after so many years of paying in, as well as how much it will pay out after retirement. Being an Indexed policy, the interest could be greater so it is likely that, if premimums are kept up, the investment will be much greater.',
            value: ''
          },
        ],
      },
      variable: {
        title: 'Variable',
        numbers: [
          {
            key: 'predictedInterest',
            title: 'Predicted interest',
            dataType: 'number',
            info: 'Being that the interest is variable, there is not a minimum or maximum interest rate. Therefore, to give an idea of the potential investment, Insurance Carriers might provide a predicted interest rate based on their investment history with other clients.',
            value: ''
          },
        ],
      },
    },
  }
}

