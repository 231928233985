const propertyActionTypes = {
  FETCH_ALL_PROPERTIES_FROM_DB_START: 'FETCH_ALL_PROPERTIES_FROM_DB_START',
  FETCH_ALL_PROPERTIES_FROM_DB_SUCCESS: 'FETCH_ALL_PROPERTIES_FROM_DB_SUCCESS',
  FETCH_ALL_PROPERTIES_FROM_DB_FAILURE: 'FETCH_ALL_PROPERTIES_FROM_DB_FAILURE',
  FETCH_PROPERTY_FROM_DB_START: 'FETCH_PROPERTY_FROM_DB_START',
  FETCH_PROPERTY_FROM_DB_SUCCESS: 'FETCH_PROPERTY_FROM_DB_SUCCESS',
  FETCH_PROPERTY_FROM_DB_FAILURE: 'FETCH_PROPERTY_FROM_DB_FAILURE',
  LOAD_STATES_FROM_DB_START: 'LOAD_STATES_FROM_DB_START',
  LOAD_STATES_FROM_DB_SUCCESS: 'LOAD_STATES_FROM_DB_SUCCESS',
  LOAD_STATES_FROM_DB_FAILURE: 'LOAD_STATES_FROM_DB_FAILURE',
  START_PROPERTY_SAVE: 'START_PROPERTY_SAVE',
  START_PROPERTY_UPDATE: 'START_PROPERTY_UPDATE',
  START_PROPERTY_DELETE: 'START_PROPERTY_DELETE',
  PROPERTY_DELETE_SUCCESS: 'PROPERTY_DELETE_SUCCESS',
  START_PROPERTY_IN_QUOTE_SAVE: 'START_PROPERTY_IN_QUOTE_SAVE',
  PROPERTY_IN_QUOTE_SAVE_SUCCESS: 'PROPERTY_IN_QUOTE_SAVE_SUCCESS',
  START_PROPERTY_IN_QUOTE_UPDATE: 'START_PROPERTY_IN_QUOTE_UPDATE',
  PROPERTY_IN_QUOTE_UPDATE_SUCCESS: 'PROPERTY_IN_QUOTE_UPDATE_SUCCESS',
  SET_IS_SUBMITTED: 'SET_IS_SUBMITTED',
  TOGGLE_PROPERTY_FORM: 'TOGGLE_PROPERTY_FORM',
  TOGGLE_PROPERTY_OPTIONS: 'TOGGLE_PROPERTY_OPTIONS',
  TOGGLE_PROPERTY_INFO_EDIT: 'TOGGLE_PROPERTY_INFO_EDIT',
  SET_CLEAR_PROPERTY_FORM: 'SET_CLEAR_PROPERTY_FORM',
  RESET_FORM: 'RESET_FORM',
};

export default propertyActionTypes;

