export const MEMBER_POLICIES_DASHBOARD_CMS_DATA = {
  initial: {
    heading: 'My Policies',
    subHeading: `Enter a policy or view and edit any policies that you or one of our Agents has entered for you. This is a place where you can conveniently store and reference all of your insurance policies.`,
    cards: [
      {
        imageUrl: `../get-quotes/images/car.png`,
        title: `Vehicle`,
        text: ``,
        goto: `/member-vehicle-policy`,
      },
      {
        imageUrl: `../get-quotes/images/house.png`,
        title: `Property`,
        text: ``,
        goto: `/member-property-policy`,
      },
      {
        imageUrl: `../get-quotes/images/first-aid-kit.png`,
        title: `Medical`,
        text: ``,
        goto: `/member-medical-policy`,
      },
      // {
      //   title: `Supplemental Insurances`,
      //   text: `Meet with a Supplemental Insurance Agent for more options (e.g., Disability Income, Critical Illness, Cancer, etc.).`,
      //   goto: `/member-supplemental-quote`,
      // },
      {
        title: `Life`,
        imageUrl: `../get-quotes/images/cardiogram-r.png`,
        text: ``,
        goto: `/member-life-policy`,
      },
      {
        imageUrl: `../get-quotes/images/money-bag-2.png`,
        title: `Annuity`,
        text: ``,
        goto: `/member-annuity-policy`,
      },
      {
        imageUrl: `../get-quotes/images/dog-2.png`,
        title: `Animal`,
        text: ``,
        goto: `/member-animal-policy`,
      },
    ],
  },
};