import { MEMBER_ANNUITY_POLICY_CMS_DATA } 
  from '../../../pages/policies/annuity/annuity-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import memberAnnuityPolicyActionTypes from './annuity.types';

const payload = {
  context: 'memberAnnuityPolicy',
  status: '',
  error: '',
}

export const setClearForm = (clear) => ({
  type: memberAnnuityPolicyActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const startFetchingMembersFromDB = () => ({
  type: memberAnnuityPolicyActionTypes.FETCH_MEMBERS_FROM_DB_START,
});

export const fetchMembersFromDBSuccess = (data) => ({
  type: memberAnnuityPolicyActionTypes.FETCH_MEMBERS_FROM_DB_SUCCESS,
  payload: data,
});

export const fetchMembersFromDBFailure = (e) => ({
  type: memberAnnuityPolicyActionTypes.FETCH_MEMBERS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingAnnuityPolicyFromDB = (id) => ({
  type: memberAnnuityPolicyActionTypes.FETCH_ANNUITY_POLICY_FROM_DB_START,
  payload: id,
});

export const fetchAnnuityPolicyFromDBSuccess = (policy) => ({
  type: memberAnnuityPolicyActionTypes.FETCH_ANNUITY_POLICY_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchAnnuityPolicyFromDBFailure = (e) => ({
  type: memberAnnuityPolicyActionTypes.FETCH_ANNUITY_POLICY_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: memberAnnuityPolicyActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: memberAnnuityPolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: memberAnnuityPolicyActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_ANNUITY_POLICY_CMS_DATA.errMsg.states,
});

export const startAnnuityPolicySave = (data) => ({
  type: memberAnnuityPolicyActionTypes.START_ANNUITY_POLICY_SAVE,
  payload: data
});

export const annuityPolicySaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const annuityPolicySaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAnnuityPolicyUpdate = (id, formData) => ({
  type: memberAnnuityPolicyActionTypes.START_ANNUITY_POLICY_UPDATE,
  payload: { id, formData }
});

export const annuityPolicyUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const annuityPolicyUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startAnnuityPolicyDelete = (id, PId) => ({
  type: memberAnnuityPolicyActionTypes.START_ANNUITY_POLICY_DELETE,
  payload: { id, PId },
});

export const annuityPolicyDeleteSuccess = () => ({
  type: memberAnnuityPolicyActionTypes.ANNUITY_POLICY_DELETE_SUCCESS,
});

export const annuityPolicyDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});