import { MEMBER_PROPERTY_POLICY_CMS_DATA } 
  from '../../../pages/policies/property/property-policy.cms';

const PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));
let memberDetails;

if (PERSISTED_STATE && PERSISTED_STATE.auth) {
  memberDetails = JSON.parse(PERSISTED_STATE.auth);
}

export const convertMemberPropertyPolicyFromDBSave = (data) => {
  const {
    form: {
      policyType,
      coverageTypes,
      deductible,
    },
  } = MEMBER_PROPERTY_POLICY_CMS_DATA;

  const policyTypes = policyType.dropdown;
  const structureOrContentsTypes = coverageTypes.dropdown;
  const namedPerilsTypes = deductible.dropdown;
  const unNamedPerilsTypes = deductible.dropdown;

  const payload = {
    quoteType: data.quoteType ? data.quoteType : '',
    memberId: memberDetails ? memberDetails.user._id : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    storedProperty: data.storedProperty,
    additionalProperty: data.additionalProperty,
    documents: data.documents ? data.documents : [],
    carrier: {
      name: data.carrier ? data.carrier : '',
      address: {
        streetAddress: data.CStreet ? data.CStreet : '',
        city: data.CCity ? data.CCity : '',
        state: data.CState ? data.CState : '',
        phoneNumber: data.CPhoneNumber ? data.CPhoneNumber : '',
        website: data.CWebsite ? data.CWebsite : '',
        elaborate: data.CElaborate ? data.CElaborate : '',
      }
    },
    agent: {
      name: data.agent ? data.agent : '',
      address: {
        streetAddress: data.AStreet ? data.AStreet : '',
        city: data.ACity ? data.ACity : '',
        state: data.AState ? data.AState : '',
        phoneNumber: data.APhoneNumber ? data.APhoneNumber : '',
        website: data.AWebsite ? data.AWebsite : '',
        email: data.AEmail ? data.AEmail : '',
      }
    },
    claimsDepartment: {
      name: data.claimsDepartment ? data.claimsDepartment : '',
      address: {
        streetAddress: data.CDStreet ? data.CDStreet : '',
        city: data.CDCity ? data.CDCity : '',
        state: data.CDState ? data.CDState : '',
        phoneNumber: data.CDPhoneNumber ? data.CDPhoneNumber : '',
        website: data.CDWebsite ? data.CDWebsite : '',
        elaborate: data.CDElaborate ? data.CDElaborate : '',
      }
    },
    effectiveDates: {
      start: data.startDate ? data.startDate : '',
      end: data.endDate ? data.endDate : '',
    },
    policyType: {
      HO1: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[0].value
          : false,
      HO2: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[1].value
          : false,
      HO3: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[2].value
          : false,
      HOB: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[3].value
          : false,
      HO4: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[4].value
          : false,
      HO5: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[5].value
          : false,
      HO6: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[6].value
          : false,
      HO7: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[7].value
          : false,
      HO8: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[8].value
          : false,
      other: 
        data.propertyPolicyType !== null
          ? data.propertyPolicyType === policyTypes[9].value
          : false,
      otherType:
        data.otherPolicy ? data.otherPolicy : '',
    },
    coverageTypes: {
      structureCoverage: {
        replacementCost: 
          data.structureCoverage !== null
            ? data.structureCoverage === structureOrContentsTypes[0].value
            : false,
        replacementCostValue:
          data.structureCoverage === structureOrContentsTypes[0].value
            ? data.structureRV
            : '',
        actualCash: 
          data.structureCoverage !== null
            ? data.structureCoverage === structureOrContentsTypes[1].value
            : false,
        actualCashValue:
          data.structureCoverage === structureOrContentsTypes[1].value
            ? data.structureCV
            : '',
      },
      contentsCoverage: {
        replacementCost: 
          data.contentsCoverage !== null
            ? data.contentsCoverage === structureOrContentsTypes[0].value
            : false,
        replacementCostValue:
          data.contentsCoverage === structureOrContentsTypes[0].value
            ? data.contentsRV
            : '',
        actualCash: 
          data.contentsCoverage !== null
            ? data.contentsCoverage === structureOrContentsTypes[1].value
            : false,
        actualCashValue:
          data.contentsCoverage === structureOrContentsTypes[1].value
            ? data.contentsCV
            : '',
      },
    },
    deductible: {
      namedPerils: {
        percentage:
          data.namedPerils !== null
            ? data.namedPerils === namedPerilsTypes[0].value
            : false,
        percentageValue:
          data.namedPerils === namedPerilsTypes[0].value
            ? data.namedPerilsP
            : '',
        amount: 
          data.namedPerils !== null
            ? data.namedPerils === namedPerilsTypes[1].value
            : false,
        amountValue:
          data.namedPerils === namedPerilsTypes[1].value
            ? data.namedPerilsA
            : '',
      },
      unNamedPerils: {
        percentage:
          data.unNamedPerils !== null
            ? data.unNamedPerils === unNamedPerilsTypes[0].value
            : false,
        percentageValue:
          data.unNamedPerils === unNamedPerilsTypes[0].value
            ? data.unNamedPerilsP
            : '',
        amount: 
          data.unNamedPerils !== null
            ? data.unNamedPerils === unNamedPerilsTypes[1].value
            : false,
        amountValue:
          data.unNamedPerils === unNamedPerilsTypes[1].value
            ? data.unNamedPerilsA
            : '',
      },
    },
    scheduledItems: data.scheduledItems ? data.scheduledItems : [],
    additionalInfo: data.additionalInfo ? data.additionalInfo : '',
    uploadedFiles: data.uploadedFiles ? data.uploadedFiles : [],
  };

  return payload;
}



