import { ModalState, ModalTypes } from '../../modal/modal.types';
import combineBiddingActionTypes from './combine-bidding.types';

const payload = {
  context: 'combineBidding',
  status: '',
  error: '',
};

export const setIsSubmit = (toggle) => ({
  type: combineBiddingActionTypes.SET_IS_SUBMIT,
  payload: toggle,
});

export const setIsCombineBid = (toggle) => ({
  type: combineBiddingActionTypes.SET_IS_COMBINE_BID,
  payload: toggle,
});

export const setShowCombineQuotes = (toggle) => ({
  type: combineBiddingActionTypes.SET_SHOW_COMBINE_QUOTES,
  payload: toggle,
});

export const startCombineBiddingSave = (values) => ({
  type: combineBiddingActionTypes.START_COMBINE_BIDDING_SAVE,
  payload: values,
});

export const combineBiddingSaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.AGENT_BID_SUCCESS,
  },
});

export const combineBiddingSaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startCombineBiddingUpdate = (id, formData) => ({
  type: combineBiddingActionTypes.START_COMBINE_BIDDING_UPDATE,
  payload: { id, formData },
});

export const combineBiddingUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.MEMBER_SELECT_SUCCESS,
  },
});

export const combineBiddingUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

