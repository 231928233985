import { MEMBER_CMS_DATA } from '../../pages/member/member.cms';

export const convertProfileForDBSave = (data) => {
  const {
    form: {
      personalDetails: {
        label: {
          dropdown: { genders },
        },
      },
    },
  } = MEMBER_CMS_DATA;

  const payload = {
    details: {
      firstName: data.firstName ? data.firstName : '',
      middleName: data.middleName ? data.middleName : '',
      lastName: data.lastName ? data.lastName : '',
      email: data.email ? data.email : '',
      male: data.gender === genders[0].value,
      female: data.gender === genders[1].value,
      dateOfBirth: data.dateOfBirth ? data.dateOfBirth : '',
      mobile: data.mobile ? data.mobile : '',
      landline: data.landline ? data.landline : '',
    },
    address: {
      streetAddress: data.street ? data.street : '',
      city: data.city ? data.city : '',
      state: data.state ? data.state : '',
      zipCode: data.zipCode ? data.zipCode : '',
    },
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  };
  return payload;
};

