import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import healthQuoteActionTypes from "./health.types";
import { convertHealthQuoteForDBSave } from "./health.utils";
import {
  fetchHealthQuotesFromDBSuccess,
  fetchHealthQuotesFromDBFailure,
  fetchHealthQuoteFromDBSuccess,
  fetchHealthQuoteFromDBFailure,
  healthQuoteSaveSuccess,
  healthQuoteSaveFailure,
  healthQuoteUpdateSuccess,
  healthQuoteUpdateFailure,
  checkingChatGroupSuccess,
  saveDeleteCometChatSuccess,
  lastStepHealthQuoteUpdateSuccess,
  lastStepHealthQuoteUpdateFailure,
} from "./health.actions";

function* fetchHealthQuotesFromDB() {
  try {
    const response = yield axios.get("/api/health-quote");
    const quotes = yield response.data;
    yield put(fetchHealthQuotesFromDBSuccess(quotes));
  } catch (e) {
    yield put(fetchHealthQuotesFromDBFailure(e));
  }
}

function* fetchHealthQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios(`/api/health-quote/${id}`);
    const quote = yield response.data;
    yield put(fetchHealthQuoteFromDBSuccess(quote));
  } catch (e) {
    yield put(fetchHealthQuoteFromDBFailure(e));
  }
}

function* saveHealthQuote({ payload: formData }) {
  try {
    const data = yield call(convertHealthQuoteForDBSave, formData);
    yield axios.post("/api/health-quote", data, configureAxios());
    yield put(healthQuoteSaveSuccess());
  } catch (e) {
    yield put(healthQuoteSaveFailure(e));
  }
}

function* updateHealthQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertHealthQuoteForDBSave, formData);
    const response = yield axios.patch(
      `/api/health-quote/${id}`,
      data,
      configureAxios()
    );
    const quote = yield JSON.parse(response.config.data);
    yield put(healthQuoteUpdateSuccess(quote));
  } catch (e) {
    yield put(healthQuoteUpdateFailure(e));
  }
}

function* updateLastStepHealthQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertHealthQuoteForDBSave, formData);
    const response = yield axios.patch(
      `/api/health-quote/${id}`,
      data,
      configureAxios()
    );
    const quotes = yield JSON.parse(response.config.data);
    yield put(lastStepHealthQuoteUpdateSuccess(quotes));
  } catch (e) {
    yield put(lastStepHealthQuoteUpdateFailure(e));
  }
}

function* checkingChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/health-quote/${id}/check-chat-group`);
    yield checkingChatGroupSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* saveDeleteChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/health-quote/${id}/save-delete-chat`);
    yield saveDeleteCometChatSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* onFetchHealthQuotesStart() {
  yield takeEvery(
    healthQuoteActionTypes.FETCH_HEALTH_QUOTES_FROM_DB_START,
    fetchHealthQuotesFromDB
  );
}

function* onFetchHealthQuoteStart() {
  yield takeEvery(
    healthQuoteActionTypes.FETCH_HEALTH_QUOTE_FROM_DB_START,
    fetchHealthQuoteFromDB
  );
}

function* onHealthQuoteSaveStart() {
  yield takeEvery(
    healthQuoteActionTypes.START_HEALTH_QUOTE_SAVE,
    saveHealthQuote
  );
}

function* onHealthQuoteUpdateStart() {
  yield takeEvery(
    healthQuoteActionTypes.START_HEALTH_QUOTE_UPDATE,
    updateHealthQuote
  );
}

function* onLastStepHealthQuoteUpdateStart() {
  yield takeEvery(
    healthQuoteActionTypes.START_LAST_STEP_HEALTH_QUOTE_UPDATE,
    updateLastStepHealthQuote
  );
}

function* onCheckingChatGroup() {
  yield takeEvery(
    healthQuoteActionTypes.START_CHECKING_CHAT_GROUP_HEALTH,
    checkingChatGroup
  );
}

function* onSaveDeleteChatGroup() {
  yield takeEvery(
    healthQuoteActionTypes.SAVE_DELETE_HEALTH_COMET_CHAT,
    saveDeleteChatGroup
  );
}

export function* healthQuoteSagas() {
  yield all([
    call(onFetchHealthQuotesStart),
    call(onFetchHealthQuoteStart),
    call(onHealthQuoteSaveStart),
    call(onHealthQuoteUpdateStart),
    call(onCheckingChatGroup),
    call(onLastStepHealthQuoteUpdateStart),
    call(onSaveDeleteChatGroup),
  ]);
}
