export const getAgentServices = (agent) => {
  let services = {
    isOfferProperty: false,
    isOfferAuto: false,
    isOfferAnimal: false,
    isOfferMedical: false,
    isOfferMedicare: false,
    isOfferAnnuity: false,
    isOfferLife: false,
    isOfferSup: false,
    isCaptive: false,
    isIndependent: false,
    isServeAnyone: false,
    isServeMilitary: false,
    isServeOccupations: {
      isTypeOf: false,
      occupations: [],
    }
  }

  if (agent.policies) {
    let aP = agent.policies.pAndC;
    let aPH = agent.policies.lAndH;
    for (let i = 0; i < aP.length; i++) {
      if (aP[i].value === 'property') {
        services.isOfferProperty = true;
      }
      if (aP[i].value === 'auto') {
        services.isOfferAuto = true;
      }
      if (aP[i].value === 'pet') {
        services.isOfferAnimal = true;
      }
    }
    for (let i = 0; i < aPH.length; i++) {
      if (aPH[i].value === 'medical') {
        services.isOfferMedical = true;
      }
      if (aPH[i].value === 'medicare') {
        services.isOfferMedicare = true;
      }
      if (aPH[i].value === 'annuity') {
        services.isOfferAnnuity = true;
      }
      if (aPH[i].value === 'life') {
        services.isOfferLife = true;
      }
      if (aPH[i].value === 'supplemental') {
        services.isOfferSup = true;
      }
    }
  }

  if (agent.servicesProvidedTo) {
    let agS = agent.servicesProvidedTo;
    if (agent.isCaptive && agent.isCaptive.isTypeOf) {
      services.isCaptive = true;
      if (agS.anyOne) {
        services.isServeAnyone = true;
      }
      if (
        agS.military ||
        (agS.militaryOccupations &&
          agS.militaryOccupations.isTypeOf)
      ) {
        services.isServeMilitary = true;
      }
      if (
        (agS.specificOccupations &&
          agS.specificOccupations.isTypeOf) ||
        (agS.militaryOccupations &&
          agS.militaryOccupations.isTypeOf)
      ) {
        if (
          services.isServeOccupations
        ) {
          services.isServeOccupations.isTypeOf = true;
        }
        if (
          services.isServeOccupations &&
          agS.specificOccupations.occupations &&
          agS.specificOccupations.occupations.length !== 0 &&
          agS.specificOccupations.occupations[0].occupation.value !== ''
        ) {
          services.isServeOccupations.occupations = agS.specificOccupations.occupations;
        }
        if (
          services.isServeOccupations &&
          agS.militaryOccupations.occupations &&
          agS.militaryOccupations.occupations.length !== 0 &&
          agS.militaryOccupations.occupations[0].occupation.value !== ''
        ) {
          services.isServeOccupations.occupations = agS.militaryOccupations.occupations;
        }
      }
    }
    if (agent.isCaptive && !agent.isCaptive.isTypeOf) {
      services.isIndependent = true;
      services.isServeAnyone = true;
    }
  }
  return services;
}
