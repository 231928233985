import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { 
  setClearHealthForm,
  toggleHealthFormView,
  startFetchingHealthFromDB,
} from '../../../redux/quotes/health/health.actions';
import {
  selectShowHealthForm,
} from '../../../redux/quotes/health/health.selectors';
import HealthInfo from '../../../pages/quotes/health/health.component';

const HealthFormModal = ({
  memberId,
  showHealthInfo,
  setShowHealthInfo,
  setClearHealthForm,
  toggleHealthFormView,
}) => {
  
  const handleClose = () => {
    toggleHealthFormView(false);
    setShowHealthInfo(false);
    setClearHealthForm(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Modal
      size='xl'
      show={showHealthInfo}
      dialogClassName='modal-100w'
      onHide={handleClose}
      centered
    >
      <Modal.Header className='rounded border-bottom-0 justify-content-end'>
        <Button
          variant='light'
          onClick={handleClose}
        >
          &#x2715;
        </Button>
      </Modal.Header>
      <Modal.Body className='border-top border-grey'>
        <HealthInfo
          memberId={memberId}
          setShowHealthInfo={setShowHealthInfo}
        />
      </Modal.Body>
      <Modal.Footer className='rounded mb-0'>
        <Button
          variant='btn btn-outline-danger'
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  showHealthForm: selectShowHealthForm,
});

export default connect(mapStateToProps, {
  setClearHealthForm,
  toggleHealthFormView,
  startFetchingHealthFromDB,
})(HealthFormModal);
