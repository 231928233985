import * as yup from 'yup';

const healthSchema = yup.object().shape({
  heightF: yup
    .string()
    .trim()
    .required('Please select your height in feet'),
  heightI: yup
    .string()
    .trim()
    .required('Please select your height in feet'),
  weight: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please provide a valid number')
    .positive('Please provide a valid number')
    .min(1, 'Number should be at least 1')
    .max(1500, 'Cannot be more than 1500 lbs')
    .required('Please enter your weight'),
  weightChange: yup
    .string()
    .trim()
    .required('Please select whether your weight has changed'),
  weightDiff: yup
    .number()
    .typeError('Please enter a numerical value')
    .integer('Please provide a valid number')
    .positive('Please provide a valid number')
    .min(0, 'Number should be at least 1')
    .max(500, 'Cannot be more than 500 lbs')
    .when('weightChange', {
      is: (val) => val === 'increased' || val === 'decreased',
      then: yup.number().required('Please provide amount of weight change'),
      otherwise: yup.number().notRequired(),
    }),
  hasMentalHandicap: yup
    .string()
    .trim()
    .required('Please select whether you have mental handicap'),
  hasPhysicalDis: yup
    .string()
    .trim()
    .required('Please select whether you have physical disability'),
  handicapAddInfo: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
  tobaccoUsage: yup
    .string()
    .trim()
    .required('Please select whether you have ever used tobacco or nicotine'),
  tobaccoUsageTypes: yup
    .array()
    .when('tobaccoUsage', {
      is: (val) => val === 'true',
      then: yup.array().required('Please select tobacco or nicotine type'),
      otherwise: yup.array().notRequired(),
    }),
  hasStopped: yup
    .string()
    .trim()
    .when('tobaccoUsage', {
      is: (val) => val === 'true',
      then: yup
        .string()
        .required(
          `Please select whether you've stopped using tobacco or nicotine`
        ),
      otherwise: yup.string().notRequired(),
    }),
  stoppedDate: yup
    .string()
    .trim()
    .when('hasStopped', {
      is: (val) => val === 'true',
      then: yup
        .string()
        .required(
          'Please select or enter the last date of tobacco or nicotine use'
        ),
      otherwise: yup.string().notRequired(),
    }),
  isInCessation: yup
    .string()
    .trim()
    .when('hasStopped', {
      is: (val) => val === 'false',
      then: yup
        .string()
        .required('Please select whether you are in cessation program'),
      otherwise: yup.string().notRequired(),
    }),
  hasPlansToJoinCes: yup
    .string()
    .trim()
    .when('isInCessation', {
      is: (val) => val === 'false',
      then: yup
        .string()
        .required(
          'Please select whether you plan on joining a cessation program'
        ),
      otherwise: yup.string().notRequired(),
    }),
  hasHeartDis: yup
    .string()
    .trim()
    .required(`Please select whether you've have circulatory disease`),
  hasLungDis: yup
    .string()
    .trim()
    .required(`Please select whether you've have respitory disease`),
  hasMentalDis: yup
    .string()
    .trim()
    .required(`Please select whether you've have nervous system disease`),
  advisedAlcoholUsage: yup
    .string()
    .trim()
    .required(
      `Please select whether you've have been advised against alcohol usage`
    ),
  advisedDrugAbuse: yup
    .string()
    .trim()
    .required(
      `Please select whether you've have been advised against drug usage`
    ),
  hasBoneOrJointDis: yup
    .string()
    .trim()
    .required(`Please select whether you've have bone or joint disease`),
  hasDigestiveDis: yup
    .string()
    .trim()
    .required(`Please select whether you've have digestive disease`),
  hasGenUrinDis: yup
    .string()
    .trim()
    .required(`Please select whether you've have genitourinary disease`),
  hadGotStudiesDone: yup
    .string()
    .trim()
    .required(`Please select whether you've had studies done`),
  hadSurgery: yup
    .string()
    .trim()
    .required(
      `Please select whether you've been advised by a physician to have a surgery`
    ),
  hasGlandDis: yup
    .string()
    .trim()
    .required(`Please select whether you've had glandular disease`),
  hasHearingIm: yup
    .string()
    .trim()
    .required(`Please select whether you've had a hearing impairment`),
  hasVisionIm: yup
    .string()
    .trim()
    .required(`Please select whether you've had vision impairment`),
  hasUnexpFever: yup
    .string()
    .trim()
    .required(`Please select whether you've had an unexplained fever`),
  isAANAMember: yup
    .string()
    .trim()
    .required('Please select whether you are ANNAmember'),
  isHIVPos: yup
    .string()
    .trim()
    .required('Please select whether you have AIDS'),
  isOnMed: yup
    .string()
    .trim()
    .required('Please select whether you are on medication'),
  isPregnant: yup
    .string()
    .trim()
    .required('Please select whether you are pregnant'),
  expDelDate: yup
    .string()
    .trim()
    .when('isPregnant', {
      is: (val) => val === 'true',
      then: yup.string().required('Please select expected delivery date'),
      otherwise: yup.string().notRequired(),
    }),
  preExistAddInfo: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
  isReinst: yup
    .string()
    .trim()
    .required(
      `Please select whether you've ever been denied, limited, or asked to pay more`
    ),
  engHazAct: yup
    .string()
    .trim()
    .required(
      `Please select whether you've been engaged in hazardous activity`
    ),
  travelStatus: yup.string().trim().required('Please select your travel plans'),
  pilotStatus: yup
    .string()
    .trim()
    .required('Please select your piloting status'),
  engDrivVi: yup
    .string()
    .trim()
    .required(`Please select whether you've incurred any driving violations`),
  hasIllDrugs: yup
    .string()
    .trim()
    .required('Please select whether you have taken illegal drugs'),
  hasIllness: yup
    .string()
    .trim()
    .required(`Please select whether you've had a significant illness`),
  addInfo: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
  relationships: yup
    .array()
    .of(
      yup.object().shape({
        relationship: yup.string().trim().optional(),
        doa: yup
          .string()
          .trim()
          .when('relationship', {
            is: (val) => val !== undefined,
            then: yup.string().required('Please select an option'),
            otherwise: yup.string().notRequired(),
          }),
        age: yup
          .number()
          .typeError('Please enter a numerical value')
          .integer('Please enter a valid value')
          .positive('Please enter a valid value')
          .min(0, 'Please enter a value > 1')
          .max(200, 'Please enter a value no more than 200')
          .when('relationship', {
            is: (val) => val !== undefined,
            then: yup.number().required('Please enter your age'),
            otherwise: yup.number().notRequired(),
          }),
        healthDetails: yup
          .string()
          .trim()
          .max(255, 'Please limit content to no more than 255 characters'),
      })
    )
    .required('must have relationships'),
});

export default healthSchema;
