import { COMPANION_ANIMAL_CMS_DATA } from '../../../pages/quotes/companion-animal/companion-animal.cms';

export const convertCompanionAnimalForDBSave = (data) => {
  const {
    form: {
      details: {
        label: {
          dropdown: { species, breedType, isHerd },
        },
      },
    },
  } = COMPANION_ANIMAL_CMS_DATA;

  const payload = {
    isHerd: {
      yes:
        data.isHerd
          ? data.isHerd === isHerd[0].value
          : false,
      no:
        data.isHerd
          ? data.isHerd === isHerd[1].value
          : false,
    },
    numOfHerds: data.numOfHerds ? data.numOfHerds : '',
    name: data.name ? data.name : '',
    dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth) : '',
    type: {
      dog: data.type === species[0].value,
      cat: data.type === species[1].value,
      bird: data.type === species[2].value,
      fish: data.type === species[3].value,
      horse: data.type === species[4].value,
      livestock: data.type === species[5].value,
      exotic: data.type === species[6].value,
    },
    breedName: data.breed ? data.breed : '',
    breed: {
      pure: data.breedType === breedType[0].value,
      mixed: data.breedType === breedType[1].value,
      unsure: data.breedType === breedType[2].value,
    },
    weight: data.weight ? data.weight : '',
    height: data.height ? data.height : '',
    length: data.length ? data.length : '',
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  };

  //Send only if truthy.
  if (data.healthConcerns) {
    payload.healthConcerns = data.healthConcerns;
  }
  return payload;
};
