import memberHistoryActionTypes from './member-history.types';
import memberActionTypes from '../../member/member.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  memberHistory: {},
  memberHistoryById: {},
};

const memberHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberHistoryActionTypes.FETCH_MEMBER_HISTORY_FROM_DB_SUCCESS:
      return {
        ...state,
        memberHistory: action.payload,
      };
    case memberHistoryActionTypes.FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_SUCCESS:
      return {
        ...state,
        memberHistoryById: action.payload,
      };
    case memberActionTypes.SET_CLEAR_MEMBER_FORM:
      return {
        ...state,
        memberHistoryById: {},
      };
    case authActionTypes.LOGOUT:
      return {
        memberHistory: {},
        memberHistoryById: {},
      };
    case memberHistoryActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default memberHistoryReducer;
  
