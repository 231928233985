import navigationActionTypes from './navigation.types';

const INITIAL_STATE = {
  showLoginOrRegistrationView: 'login',
  initiatedFromAgentShowcase: false,
  initiatedFromMemberShowcase: false,
  goToActivateAccount: false,
  goToMemberShowcase: false,
  goToAgentShowcase: false,
  goToMemberDashboard: false,
  goToAgentDashboard: false,
};

const navigationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case navigationActionTypes.SET_CURRENT_USER_TYPE:
      return {
        ...state,
        initiatedFromAgentShowcase: action.payload === 'agent',
        initiatedFromMemberShowcase: action.payload === 'member',
      };
    case navigationActionTypes.TOGGLE_LOGIN_REGISTRATION_VIEW:
      return {
        ...state,
        showLoginOrRegistrationView: action.payload,
      };
    case navigationActionTypes.SHOW_ACCOUNT_ACTIVATION_VIEW:
      return {
        ...state,
        goToActivateAccount: action.payload,
      };
    case navigationActionTypes.SHOW_MEMBER_SHOWCASE_VIEW:
      return {
        ...state,
        goToMemberShowcase: action.payload,
      };
    case navigationActionTypes.SHOW_AGENT_SHOWCASE_VIEW:
      return {
        ...state,
        goToAgentShowcase: action.payload,
      };
    case navigationActionTypes.SHOW_MEMBER_DASHBOARD_VIEW:
      return {
        ...state,
        goToMemberDashboard: action.payload,
      };
    case navigationActionTypes.SHOW_AGENT_DASHBOARD_VIEW:
      return {
        ...state,
        goToAgentDashboard: action.payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;
