import { createSelector } from 'reselect';

const selectAgentBiddingDetails = (state) => state.agentBidding;

export const selectIsSubmit = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isSubmit
);

export const selectIsAnimalBid = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isAnimalBid
);

export const selectIsVehicleBid = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isVehicleBid
);

export const selectIsPropertyBid = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isPropertyBid
);

export const selectIsCombineBid = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isCombineBid
);

export const selectIsLifeBid = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isLifeBid
);

export const selectIsHealthBid = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isHealthBid
);

export const selectIsAnnuityBid = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isAnnuityBid
);

export const selectAgentBiddings = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.agentBiddingsFromDB
);

export const selectAgentBidding = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.agentBiddingFromDB
);

export const selectShowAllQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showAllQuotes
);

export const selectShowAnimalQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showAnimalQuotes
);

export const selectShowLifeQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showLifeQuotes
);

export const selectShowAnnuityQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showAnnuityQuotes
);

export const selectShowHealthQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showHealthQuotes
);

export const selectShowPropertyQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showPropertyQuotes
);

export const selectShowVehicleQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showVehicleQuotes
);

export const selectShowCombineQuotes = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.showCombineQuotes
);

export const selectIsPropertySelectedByAi = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isPropertySelectedByAi
);

export const selectIsVehicleSelectedByAi = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isVehicleSelectedByAi
);

export const selectIsCombinedSelectedByAi = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isCombinedSelectedByAi
);

export const selectIsAnnuitySelectedByAi = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isAnnuitySelectedByAi
);

export const selectIsLifeSelectedByAi = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isLifeSelectedByAi
);

export const selectIsHealthSelectedByAi = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isHealthSelectedByAi
);

export const selectIsAnimalSelectedByAi = createSelector(
  [selectAgentBiddingDetails],
  (agentBidding) => agentBidding.isAnimalSelectedByAi
);
