import memberLifePolicyActionTypes from './life.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  statesFromDB: [],
  membersFromDB: [],
  lifePolicyFromDB: {},
  reset: false,
  clearForm: false,
}

const memberLifePolicyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberLifePolicyActionTypes.FETCH_MEMBERS_FROM_DB_SUCCESS:
      return {
        ...state,
        membersFromDB: action.payload,
      }
    case memberLifePolicyActionTypes.FETCH_LIFE_POLICY_FROM_DB_SUCCESS:
      return {
        ...state,
        lifePolicyFromDB: action.payload,
      }
    case memberLifePolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      }
    case memberLifePolicyActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        lifePolicyFromDB: {},
      }
    case memberLifePolicyActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      }
    case authActionTypes.LOGOUT:
      return {
        ...state,
        statesFromDB: [],
        membersFromDB: [],
        lifePolicyFromDB: {},
        reset: false,
        clearForm: false,
      }
    default: 
      return state
  }
}

export default memberLifePolicyReducer;