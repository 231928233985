import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  memberHistorySaveFailure,
  memberHistorySaveSuccess,
  fetchMemberHistoryFromDBSuccess,
  fetchMemberHistoryFromDBFailure,
  fetchMemberHistoryByIdFromDBSuccess,
  fetchMemberHistoryByIdFromDBFailure,
  memberHistoryUpdateSuccess,
  memberHistoryUpdateFailure,
} from "./member-history.actions";
import memberHistoryActionTypes from "./member-history.types";
import { convertMemberHistoryForDBSave } from "./member-history.utils";

function* saveMemberHistory({ payload: formData }) {
  try {
    const data = yield call(convertMemberHistoryForDBSave, formData);
    yield axios.post("/api/member-history", data, configureAxios());
    yield put(memberHistorySaveSuccess());
  } catch (e) {
    yield put(memberHistorySaveFailure(e));
  }
}

function* fetchMemberHistoryFromDB() {
  try {
    const response = yield axios.get(`/api/member-history`);
    const memberHistory = yield response.data;
    yield put(fetchMemberHistoryFromDBSuccess(memberHistory));
  } catch (e) {
    yield put(fetchMemberHistoryFromDBFailure(e));
  }
}

function* fetchMemberHistoryByIdFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/member-history/${id}`);
    const memberHisByID = yield response.data;
    yield put(fetchMemberHistoryByIdFromDBSuccess(memberHisByID));
  } catch (e) {
    yield put(fetchMemberHistoryByIdFromDBFailure(e));
  }
}

function* updateMemberHistory({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberHistoryForDBSave, formData);
    const response = yield axios.patch(
      `/api/member-history/${id}`,
      data,
      configureAxios()
    );
    const member = yield JSON.parse(response.config.data);
    yield put(memberHistoryUpdateSuccess(member));
  } catch (e) {
    yield put(memberHistoryUpdateFailure(e));
  }
}

function* onMemberHistorySaveStart() {
  yield takeEvery(
    memberHistoryActionTypes.START_MEMBER_HISTORY_SAVE,
    saveMemberHistory
  );
}

function* onFetchMemberHistoryFromDB() {
  yield takeEvery(
    memberHistoryActionTypes.FETCH_MEMBER_HISTORY_FROM_DB_START,
    fetchMemberHistoryFromDB
  );
}

function* onFetchMemberHistoryByIdFromDB() {
  yield takeEvery(
    memberHistoryActionTypes.FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_START,
    fetchMemberHistoryByIdFromDB
  );
}

function* onMemberHistoryUpdateStart() {
  yield takeEvery(
    memberHistoryActionTypes.START_MEMBER_HISTORY_UPDATE,
    updateMemberHistory
  );
}

export function* memberHistorySagas() {
  yield all([
    call(onMemberHistorySaveStart),
    call(onFetchMemberHistoryFromDB),
    call(onFetchMemberHistoryByIdFromDB),
    call(onMemberHistoryUpdateStart),
  ]);
}
