import combineCoverageQuoteActionTypes from "./combine-coverages.types";
import { ModalTypes } from "../../modal/modal.types";
import authActionTypes from "../../auth/auth.types";

let INITIAL_STATE = {
  currentStep: 0,
  combineId: "",
  formData: {},
  bidCombine: false,
  combineCoverageQuotesFromDB: [],
  combineCoverageQuoteFromDB: {},
};

const PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.combineCoverageQuote) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.combineCoverageQuote);
}

const combineCoverageQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case combineCoverageQuoteActionTypes.COMBINE_INCREMENT_CURRENT_STEP:
    case combineCoverageQuoteActionTypes.COMBINE_COVERAGE_QUOTE_SAVE_SUCCESS:
    case combineCoverageQuoteActionTypes.COMBINE_COVERAGE_QUOTE_UPDATE_SUCCESS:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case combineCoverageQuoteActionTypes.COMBINE_DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_SUCCESS:
      return {
        ...state,
        combineCoverageQuotesFromDB: action.payload,
      };
    case combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        combineCoverageQuoteFromDB: action.payload,
      };
    case combineCoverageQuoteActionTypes.SET_COMBINE_ID:
      return {
        ...state,
        combineId: action.payload,
      };
    case combineCoverageQuoteActionTypes.SET_COMBINED_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case combineCoverageQuoteActionTypes.SET_BID_COMBINE:
      return {
        ...state,
        bidCombine: action.payload,
      };
    case ModalTypes.OPEN_MODAL:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        combineId: "",
        bidCombine: false,
        combineCoverageQuotesFromDB: [],
        combineCoverageQuoteFromDB: {},
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        currentStep: 0,
        combineId: "",
        formData: {},
        bidCombine: false,
        combineCoverageQuotesFromDB: [],
        combineCoverageQuoteFromDB: {},
      };
    default:
      return state;
  }
};

export default combineCoverageQuoteReducer;
