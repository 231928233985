import agentBiddingActionTypes from './agent-bidding.types';
import authActionTypes from '../auth/auth.types';

let INITIAL_STATE = {
  agentBiddingsFromDB: [],
  agentBiddingFromDB: {},
  showAllQuotes: false,
  showAnimalQuotes: true,
  showLifeQuotes: true,
  showHealthQuotes: true,
  showAnnuityQuotes: true,
  showPropertyQuotes: true,
  showVehicleQuotes: true,
  showCombineQuotes: true,
  isPropertySelectedByAi: false,
  isVehicleSelectedByAi: false,
  isCombinedSelectedByAi: false,
  isAnnuitySelectedByAi: false,
  isLifeSelectedByAi: false,
  isHealthSelectedByAi: false,
  isAnimalSelectedByAi: false,
  isSubmit: false,
  isAnimalBid: false,
  isVehicleBid: false,
  isPropertyBid: false,
  isCombineBid: false,
  isLifeBid: false,
  isHealthBid: false,
  isAnnuityBid: false,
  reset: false,
};

// let PERSISTED_STATE = JSON.parse(localStorage.getItem('persist:insureasier'));

// if (PERSISTED_STATE) {
//   INITIAL_STATE = JSON.parse(PERSISTED_STATE.agent);
// }

const agentBiddingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case agentBiddingActionTypes.FETCH_AGENT_BIDDINGS_FROM_DB_SUCCESS:
      return {
        ...state,
        agentBiddings: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_SUBMIT:
      return {
        ...state,
        isSubmit: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_ANIMAL_BID:
      return {
        ...state,
        isAnimalBid: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_VEHICLE_BID:
      return {
        ...state,
        isVehicleBid: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_PROPERTY_BID:
      return {
        ...state,
        isPropertyBid: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_COMBINE_BID:
      return {
        ...state,
        isCombineBid: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_LIFE_BID:
      return {
        ...state,
        isLifeBid: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_ANNUITY_BID:
      return {
        ...state,
        isAnnuityBid: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_HEALTH_BID:
      return {
        ...state,
        isHealthBid: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_ALL_QUOTES:
      return {
        ...state,
        showAllQuotes: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_PROPERTY_SELECTED_BY_AI:
      return {
        ...state,
        isPropertySelectedByAi: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_VEHICLE_SELECTED_BY_AI:
      return {
        ...state,
        isVehicleSelectedByAi: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_COMBINED_SELECTED_BY_AI:
      return {
        ...state,
        isCombinedSelectedByAi: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_ANNUITY_SELECTED_BY_AI:
      return {
        ...state,
        isAnnuitySelectedByAi: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_LIFE_SELECTED_BY_AI:
      return {
        ...state,
        isLifeSelectedByAi: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_HEALTH_SELECTED_BY_AI:
      return {
        ...state,
        isHealthSelectedByAi: action.payload,
      };
    case agentBiddingActionTypes.SET_IS_ANIMAL_SELECTED_BY_AI:
      return {
        ...state,
        isAnimalSelectedByAi: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_ANIMAL_QUOTES:
      return {
        ...state,
        showAnimalQuotes: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_LIFE_QUOTES:
      return {
        ...state,
        showLifeQuotes: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_HEALTH_QUOTES:
      return {
        ...state,
        showHealthQuotes: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_ANNUITY_QUOTES:
      return {
        ...state,
        showAnnuityQuotes: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_PROPERTY_QUOTES:
      return {
        ...state,
        showPropertyQuotes: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_VEHICLE_QUOTES:
      return {
        ...state,
        showVehicleQuotes: action.payload,
      };
    case agentBiddingActionTypes.SET_SHOW_COMBINE_QUOTES:
      return {
        ...state,
        showCombineQuotes: action.payload,
      };
    case agentBiddingActionTypes.FETCH_AGENT_BIDDING_FROM_DB_SUCCESS:
      return {
        ...state,
        agentBidding: action.payload,
      };
    case agentBiddingActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        isSubmit: false,
        agentBiddings: [],
        agentBidding: {},
        showAllQuotes: false,
        showAnimalQuotes: true,
        showLifeQuotes: true,
        showHealthQuotes: true,
        showAnnuityQuotes: true,
        showPropertyQuotes: true,
        showVehicleQuotes: true,
        showCombineQuotes: true,
        isAnimalBid: false,
        isVehicleBid: false,
        isPropertyBid: false,
        isCombineBid: false,
        isLifeBid: false,
        isHealthBid: false,
        isAnnuityBid: false,
        reset: false,
      };
    default:
      return state;
  }
};

export default agentBiddingReducer;
