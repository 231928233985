import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import combineCoverageQuoteActionTypes from "./combine-coverages.types";
import { convertCombineQuoteForDBSave } from "./combine-coverages.utils";
import {
  checkingChatGroupSuccess,
  fetchCombineCoverageQuotesFromDBSuccess,
  fetchCombineCoverageQuotesFromDBFailure,
  fetchCombineCoverageQuoteFromDBSuccess,
  fetchCombineCoverageQuoteFromDBFailure,
  combineCoverageQuoteSaveSuccess,
  combineCoverageQuoteSaveFailure,
  combineCoverageQuoteUpdateSuccess,
  combineCoverageQuoteUpdateFailure,
  saveDeleteCometChatSuccess,
  lastStepCombineCoverageQuoteUpdateSuccess,
} from "./combine-coverages.actions";

function* fetchCombineCoverageQuotesFromDB() {
  try {
    const response = yield axios.get("/api/combine-quote");
    const quotes = yield response.data;
    yield put(fetchCombineCoverageQuotesFromDBSuccess(quotes));
  } catch (e) {
    yield put(fetchCombineCoverageQuotesFromDBFailure(e));
  }
}

function* fetchCombineCoverageQuoteFromDB({ payload: id }) {
  try {
    const response = yield axios(`/api/combine-quote/${id}`);
    const quote = yield response.data;
    yield put(fetchCombineCoverageQuoteFromDBSuccess(quote));
  } catch (e) {
    yield put(fetchCombineCoverageQuoteFromDBFailure(e));
  }
}

function* saveCombineCoverageQuote({ payload: formData }) {
  try {
    const data = yield call(convertCombineQuoteForDBSave, formData);
    yield axios.post("/api/combine-quote", data, configureAxios());
    yield put(combineCoverageQuoteSaveSuccess());
  } catch (e) {
    yield put(combineCoverageQuoteSaveFailure(e));
  }
}

function* updateCombineCoverageQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertCombineQuoteForDBSave, formData);
    const response = yield axios.patch(
      `/api/combine-quote/${id}`,
      data,
      configureAxios()
    );
    const quote = yield JSON.parse(response.config.data);
    if (data.isLastStep) {
      yield put(lastStepCombineCoverageQuoteUpdateSuccess(quote));
    } else {
      yield put(combineCoverageQuoteUpdateSuccess(quote));
    }
  } catch (e) {
    yield put(combineCoverageQuoteUpdateFailure(e));
  }
}

function* checkingChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/combine-quote/${id}/check-chat-group`);
    yield checkingChatGroupSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* saveDeleteChatGroup({ payload: id }) {
  try {
    const response = axios.get(`/api/combine-quote/${id}/save-delete-chat`);
    yield saveDeleteCometChatSuccess(response.data);
  } catch (e) {
    throw e;
  }
}

function* onFetchCombineCoverageQuotesStart() {
  yield takeEvery(
    combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTES_FROM_DB_START,
    fetchCombineCoverageQuotesFromDB
  );
}

function* onFetchCombineCoverageQuoteStart() {
  yield takeEvery(
    combineCoverageQuoteActionTypes.FETCH_COMBINE_COVERAGE_QUOTE_FROM_DB_START,
    fetchCombineCoverageQuoteFromDB
  );
}

function* onCombineCoverageQuoteSaveStart() {
  yield takeEvery(
    combineCoverageQuoteActionTypes.START_COMBINE_COVERAGE_QUOTE_SAVE,
    saveCombineCoverageQuote
  );
}

function* onCombineCoverageQuoteUpdateStart() {
  yield takeEvery(
    combineCoverageQuoteActionTypes.START_COMBINE_COVERAGE_QUOTE_UPDATE,
    updateCombineCoverageQuote
  );
}

function* onCheckingChatGroup() {
  yield takeEvery(
    combineCoverageQuoteActionTypes.START_CHECKING_CHAT_GROUP_COMBINE,
    checkingChatGroup
  );
}

function* onSaveDeleteChatGroup() {
  yield takeEvery(
    combineCoverageQuoteActionTypes.SAVE_DELETE_COMBINE_COMET_CHAT,
    saveDeleteChatGroup
  );
}

export function* combineCoverageQuoteSagas() {
  yield all([
    call(onFetchCombineCoverageQuotesStart),
    call(onFetchCombineCoverageQuoteStart),
    call(onCombineCoverageQuoteSaveStart),
    call(onCombineCoverageQuoteUpdateStart),
    call(onCheckingChatGroup),
    call(onSaveDeleteChatGroup),
  ]);
}
