import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  annuityBiddingSaveSuccess,
  annuityBiddingSaveFailure,
  annuityBiddingUpdateSuccess,
  annuityBiddingUpdateFailure,
} from "./annuity-bidding.actions";
import annuityBiddingActionTypes from "./annuity-bidding.types";
import { convertAnnuityBiddingForDBSave } from "./annuity-bidding.utils";

function* saveAnnuityBidding({ payload: formData }) {
  try {
    const data = yield call(convertAnnuityBiddingForDBSave, formData);
    yield axios.post("/api/annuity-bidding", data, configureAxios());
    yield put(annuityBiddingSaveSuccess());
  } catch (e) {
    yield put(annuityBiddingSaveFailure(e));
  }
}

function* updateAnnuityBidding({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAnnuityBiddingForDBSave, formData);
    const response = yield axios.patch(
      `/api/annuity-bidding/${id}`,
      data,
      configureAxios()
    );
    const bidding = yield JSON.parse(response.config.data);
    yield put(annuityBiddingUpdateSuccess(bidding));
  } catch (e) {
    yield put(annuityBiddingUpdateFailure(e));
  }
}

function* onSaveAnnuityBiddingStart() {
  yield takeEvery(
    annuityBiddingActionTypes.START_ANNUITY_BIDDING_SAVE,
    saveAnnuityBidding
  );
}

function* onUpdateAnnuityBiddingStart() {
  yield takeEvery(
    annuityBiddingActionTypes.START_ANNUITY_BIDDING_UPDATE,
    updateAnnuityBidding
  );
}

export function* annuityBiddingSagas() {
  yield all([
    call(onSaveAnnuityBiddingStart),
    call(onUpdateAnnuityBiddingStart),
  ]);
}
