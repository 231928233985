import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../../helpers/configure-axios";
import {
  loadVehicleMakeFromDBFailure,
  loadVehicleMakeFromDBSuccess,
  loadVehicleModelsFromDBFailure,
  loadVehicleModelsFromDBSuccess,
  fetchAllVehiclesFromDBSuccess,
  fetchAllVehiclesFromDBFailure,
  fetchVehicleFromDBSuccess,
  fetchVehicleFromDBFailure,
  vehicleSaveSuccess,
  vehicleSaveFailure,
  vehicleUpdateSuccess,
  vehicleUpdateFailure,
  vehicleDeleteSuccess,
  vehicleDeleteFailure,
  vehicleInQuoteSaveSuccess,
  vehicleInQuoteSaveFailure,
  vehicleInQuoteUpdateSuccess,
  vehicleInQuoteUpdateFailure,
} from "./vehicle.actions";
import vehicleActionTypes from "./vehicle.types";
import { convertVehicleForDBSave } from "./vehicle.utils";

function* loadVehicleMakesFromDB() {
  try {
    const url = "./db/vehicle-makes.json";
    const response = yield axios.get(url);
    const makes = yield response.data.Results.map(({ Make_Name }) => Make_Name);
    yield put(loadVehicleMakeFromDBSuccess(makes));
  } catch (e) {
    yield put(loadVehicleMakeFromDBFailure(e));
  }
}

function* loadVehicleModelsFromDB({ payload: selectedMake }) {
  try {
    const url = `https://vpic.nhtsa.dot.gov/api//vehicles/GetModelsForMake/${selectedMake}?format=json`;
    const response = yield axios.get(url);
    const models = yield response.data.Results.map(
      ({ Model_Name }) => Model_Name
    );
    yield put(loadVehicleModelsFromDBSuccess(models));
  } catch (e) {
    yield put(loadVehicleModelsFromDBFailure(e));
  }
}

function* saveVehicle({ payload: formData }) {
  try {
    const data = yield call(convertVehicleForDBSave, formData);
    yield axios.post("/api/vehicles", data, configureAxios());
    yield put(vehicleSaveSuccess());
  } catch (e) {
    yield put(vehicleSaveFailure(e));
  }
}

function* updateVehicle({ payload: { id, formData } }) {
  try {
    const data = yield call(convertVehicleForDBSave, formData);
    const response = yield axios.patch(
      `/api/vehicles/${id}`,
      data,
      configureAxios()
    );
    const vehicle = yield JSON.parse(response.config.data);
    yield put(vehicleUpdateSuccess(vehicle));
  } catch (e) {
    yield put(vehicleUpdateFailure(e));
  }
}

function* deleteVehicle({ payload: { id, PId } }) {
  try {
    axios.delete(
      `/api/vehicles/${id}`,
      { params: { primeMemberId: PId } },
      configureAxios()
    );
    yield put(vehicleDeleteSuccess());
  } catch (e) {
    yield put(vehicleDeleteFailure(e));
  }
}

function* saveVehicleInQuote({ payload: formData }) {
  try {
    const data = yield call(convertVehicleForDBSave, formData);
    yield axios.post("/api/vehicles", data, configureAxios());
    yield put(vehicleInQuoteSaveSuccess());
  } catch (e) {
    yield put(vehicleInQuoteSaveFailure(e));
  }
}

function* updateVehicleInQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertVehicleForDBSave, formData);
    const response = yield axios.patch(
      `/api/vehicles/${id}`,
      data,
      configureAxios()
    );
    const vehicle = yield JSON.parse(response.config.data);
    yield put(vehicleInQuoteUpdateSuccess(vehicle));
  } catch (e) {
    yield put(vehicleInQuoteUpdateFailure(e));
  }
}

function* fetchAllVehiclesFromDB() {
  try {
    const response = yield axios.get(`/api/vehicles`);
    const vehicles = yield response.data;
    yield put(fetchAllVehiclesFromDBSuccess(vehicles));
  } catch (e) {
    yield put(fetchAllVehiclesFromDBFailure(e));
  }
}

function* fetchVehicleFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/vehicles/${id}`);
    const vehicle = yield response.data;
    yield put(fetchVehicleFromDBSuccess(vehicle));
  } catch (e) {
    yield put(fetchVehicleFromDBFailure(e));
  }
}

function* onStartLoadingVehicleMakesFromDB() {
  yield takeEvery(
    vehicleActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_START,
    loadVehicleMakesFromDB
  );
}

function* onStartLoadingVehicleModelsFromDB() {
  yield takeEvery(
    vehicleActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_START,
    loadVehicleModelsFromDB
  );
}

function* onVehicleSaveStart() {
  yield takeEvery(vehicleActionTypes.START_VEHICLE_SAVE, saveVehicle);
}

function* onVehicleUpdateStart() {
  yield takeEvery(vehicleActionTypes.START_VEHICLE_UPDATE, updateVehicle);
}

function* onVehicleDeleteStart() {
  yield takeEvery(vehicleActionTypes.START_VEHICLE_DELETE, deleteVehicle);
}

function* onVehicleInQuoteSaveStart() {
  yield takeEvery(
    vehicleActionTypes.START_VEHICLE_IN_QUOTE_SAVE,
    saveVehicleInQuote
  );
}

function* onVehicleInQuoteUpdateStart() {
  yield takeEvery(
    vehicleActionTypes.START_VEHICLE_IN_QUOTE_UPDATE,
    updateVehicleInQuote
  );
}

function* onFetchAllVehiclesFromDB() {
  yield takeEvery(
    vehicleActionTypes.FETCH_ALL_VEHICLES_FROM_DB_START,
    fetchAllVehiclesFromDB
  );
}

function* onFetchVehicleFromDB() {
  yield takeEvery(
    vehicleActionTypes.FETCH_VEHICLE_FROM_DB_START,
    fetchVehicleFromDB
  );
}

export function* vehicleSagas() {
  yield all([
    call(onVehicleSaveStart),
    call(onVehicleUpdateStart),
    call(onVehicleDeleteStart),
    call(onVehicleInQuoteSaveStart),
    call(onVehicleInQuoteUpdateStart),
    call(onStartLoadingVehicleMakesFromDB),
    call(onStartLoadingVehicleModelsFromDB),
    call(onFetchAllVehiclesFromDB),
    call(onFetchVehicleFromDB),
  ]);
}
