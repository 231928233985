import memberVehiclePolicyActionTypes from './vehicle.types';
import authActionTypes from '../../auth/auth.types';

const INITIAL_STATE = {
  vehiclesFromDB: [],
  vehiclePolicyFromDB: {},
  statesFromDB: [],
  makes: [],
  selectedMake: [],
  modelsForSelectedMake: [],
  selectedModel: [],
  clearForm: false,
  reset: false,
}

const memberVehiclePolicyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case memberVehiclePolicyActionTypes.LOAD_VEHICLE_MAKE_FROM_DB_SUCCESS_POLICY:
      return {
        ...state,
        makes: action.payload,
      };
    case memberVehiclePolicyActionTypes.SELECT_MAKE:
      return {
        ...state,
        selectedMake: action.payload,
      };
    case memberVehiclePolicyActionTypes.LOAD_VEHICLE_MODELS_FROM_DB_SUCCESS:
      return {
        ...state,
        modelsForSelectedMake: action.payload,
      };
    case memberVehiclePolicyActionTypes.SELECT_MODEL:
      return {
        ...state,
        selectedModel: action.payload,
      };
    case memberVehiclePolicyActionTypes.FETCH_VEHICLES_FROM_DB_SUCCESS:
      return {
        ...state,
        vehiclesFromDB: action.payload
      };
    case memberVehiclePolicyActionTypes.FETCH_VEHICLE_POLICY_FROM_DB_SUCCESS:
      return {
        ...state,
        vehiclePolicyFromDB: action.payload
      };
    case memberVehiclePolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload
      }
    case memberVehiclePolicyActionTypes.SET_CLEAR_FORM:
      return {
        ...state,
        clearForm: action.payload,
        vehiclePolicyFromDB: {}
      };
    case memberVehiclePolicyActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        vehiclesFromDB: [],
        vehiclePolicyFromDB: {},
        statesFromDB: [],
        makes: [],
        selectedMake: [],
        modelsForSelectedMake: [],
        selectedModel: [],
        clearForm: false,
        reset: false,
      }
    default:
      return state;
  }
}

export default memberVehiclePolicyReducer;