import { VEHICLE_CMS_DATA } from '../../../components/requested-quotes/vehicle/vehicle.cms';
import { PROPERTY_CMS_DATA } from '../../../components/requested-quotes/property/property.cms';

export const convertCombineQuoteForDBSave = (data) => {
  const {
    form: {
      vehicle: {
        liabilityC: {
          label: {
            dropdown: { liability, mdpi, deductibleV, propDamage },
          },
        },
      },
      history: {
        auto: {
          label: {
            dropdown: { coverageOptions, coverageLength },
          },
        },
        dl: {
          label: {
            dropdown: { revocationOptions },
          },
        },
      }
    }
  } = VEHICLE_CMS_DATA;

  const {
    form: {
      property: {
        dropdown: {
          reOrACV, scale, acv, floodEarthquake, deductible,
        }
      },
    }
  } = PROPERTY_CMS_DATA;

  const payload = {
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
    membersInfo: data.membersInfo ? data.membersInfo : [],
    primeMemberInfo: data.primeMemberInfo ? data.primeMemberInfo : {},
    createdDate: new Date(),
    quoteType: data.quoteType ? data.quoteType : '',
    propertyInfo: data.propertyInfo ? data.propertyInfo : {},
    vehiclesInfo: data.vehiclesInfo ? data.vehiclesInfo : [],
    members: data.members ? data.members : [],
    isLastStep: data.isLastStep ? data.isLastStep : false,
    isCometChatGr: data.isCometChatGr ? data.isCometChatGr : false,
    isMsgSent: data.isMsgSent ? data.isMsgSent : false,
    selAgentsByAI: data.selAgentsByAI ? data.selAgentsByAI : {},
    createdCometDate: data.createdCometDate ? data.createdCometDate : '',
    vehicles: data.vehicles ? data.vehicles : [],
    property: data.property ? data.property : '',
    isCometExp: data.isCometExp ? data.isCometExp : false,
    isDeleteComet: data.isDeleteComet ? data.isDeleteComet : false,
    propertyCV: {
      ownOrFinance: {
        scaleCV: {
          scale1: {
            isTypeOf:
              scale !== null
                ? data.scale === scale[0].value
                : false,
            acv:
              acv !== null
                ? data.acv === acv[0].value
                : false,
          },
          scale2:
            scale !== null
              ? data.scale === scale[1].value
              : false,
          scale3:
            scale !== null
              ? data.scale === scale[2].value
              : false,
          scale4:
            scale !== null
              ? data.scale === scale[3].value
              : false,
          scale5:
            scale !== null
              ? data.scale === scale[4].value
              : false,
        },
        floodEarthquake: {
          yes:
            floodEarthquake !== null
              ? data.floodEarthquake === floodEarthquake[0].value
              : false,
          no:
            floodEarthquake !== null
              ? data.floodEarthquake === floodEarthquake[1].value
              : false,
          unsure:
            floodEarthquake !== null
              ? data.floodEarthquake === floodEarthquake[2].value
              : false,
        },
        deductible: {
          none:
            deductible !== null
              ? data.deductible === deductible[0].value
              : false,
          500:
            deductible !== null
              ? data.deductible === deductible[1].value
              : false,
          1000:
            deductible !== null
              ? data.deductible === deductible[2].value
              : false,
          1500:
            deductible !== null
              ? data.deductible === deductible[3].value
              : false,
          2000:
            deductible !== null
              ? data.deductible === deductible[4].value
              : false,
          onePercent:
            deductible !== null
              ? data.deductible === deductible[5].value
              : false,
          twoPercent:
            deductible !== null
              ? data.deductible === deductible[6].value
              : false,
          threePercent:
            deductible !== null
              ? data.deductible === deductible[7].value
              : false,
          fourPercent:
            deductible !== null
              ? data.deductible === deductible[8].value
              : false,
          fivePercent:
            deductible !== null
              ? data.deductible === deductible[9].value
              : false,
        },
      },
      reACV: {
        reOrACV: {
          replacement:
            reOrACV !== null
              ? data.reOrACV === reOrACV[0].value
              : false,
          acv:
            reOrACV !== null
              ? data.reOrACV === reOrACV[1].value
              : false,
          unsure:
            reOrACV !== null
              ? data.reOrACV === reOrACV[2].value
              : false,
        },
        additionalInfo: data.additionalInfo ? data.additionalInfo : '',
      },
    },
    vehicleCV: {
      onlyLiability: {
        isTypeOf:
          data.isOnlyLia !== null
            ? data.isOnlyLia
            : '',
        vehicles: data.onlyLiability ? data.onlyLiability : [],
      },
      liabilityCov: {
        '25000/50000':
          data.liabilityC !== null
            ? data.liabilityC === liability[0].value
            : false,
        '50000/100000':
          data.liabilityC !== null
            ? data.liabilityC === liability[1].value
            : false,
        '100000/300000':
          data.liabilityC !== null
            ? data.liabilityC === liability[2].value
            : false,
        '250000/500000':
          data.liabilityC !== null
            ? data.liabilityC === liability[3].value
            : false,
        '500000/500000':
          data.liabilityC !== null
            ? data.liabilityC === liability[4].value
            : false,
      },
      propDamage: {
        '25000':
          data.propDamage !== null
            ? data.propDamage === propDamage[0].value
            : false,
        '50000':
          data.propDamage !== null
            ? data.propDamage === propDamage[1].value
            : false,
        '100000':
          data.propDamage !== null
            ? data.propDamage === propDamage[2].value
            : false,
        '250000':
          data.propDamage !== null
            ? data.propDamage === propDamage[3].value
            : false,
      },
      medicalPip: {
        medical:
          mdpi !== null
            ? data.mdpi === mdpi[0].value
            : false,
        pip:
          mdpi !== null
            ? data.mdpi === mdpi[1].value
            : false,
        neither:
          mdpi !== null
            ? data.mdpi === mdpi[2].value
            : false,
        unsure:
          mdpi !== null
            ? data.mdpi === mdpi[3].value
            : false,
      },
      deductible: {
        none:
          deductibleV !== null
            ? data.deductibleV === deductibleV[0].value
            : false,
        250:
          deductibleV !== null
            ? data.deductibleV === deductibleV[1].value
            : false,
        500:
          deductibleV !== null
            ? data.deductibleV === deductibleV[2].value
            : false,
        1000:
          deductibleV !== null
            ? data.deductibleV === deductibleV[3].value
            : false,
        1500:
          deductibleV !== null
            ? data.deductibleV === deductibleV[4].value
            : false,
        2000:
          deductibleV !== null
            ? data.deductibleV === deductibleV[5].value
            : false,
        3000:
          deductibleV !== null
            ? data.deductibleV === deductibleV[6].value
            : false,
      },
      autoCoverage: {
        memberHasAutoCoverage:
          data.autoCoverage === coverageOptions[0].value,
        startDate:
          data.autoCoverageStartDate &&
            data.autoCoverageStartDate !== null
            ? data.autoCoverageStartDate
            : '',
        expirationDate:
          data.autoCoverageExpirationDate &&
            data.autoCoverageExpirationDate !== null
            ? data.autoCoverageExpirationDate
            : '',
        coverageLength: {
          lessThanOneYear:
            data.coverageLength !== null
              ? data.coverageLength === coverageLength[0].value
              : false,
          oneToThreeYears:
            data.coverageLength !== null
              ? data.coverageLength === coverageLength[1].value
              : false,
          threeToFiveYears:
            data.coverageLength !== null
              ? data.coverageLength === coverageLength[2].value
              : false,
          moreThanFiveYear:
            data.coverageLength !== null
              ? data.coverageLength === coverageLength[3].value
              : false,
        },
        priorExpirationDate:
          data.autoCoveragePriorExpirationDate &&
            data.autoCoveragePriorExpirationDate !== null
            ? data.autoCoveragePriorExpirationDate
            : '',
        reasonForLapse:
          data.reasonForAutoCoverageLapse
            ? data.reasonForAutoCoverageLapse : '',
      },
      driversLicenseStatus: {
        suspendedOrRevoked:
          data.dlRevocationStatus === revocationOptions[0].value,
        reasonForSuspension:
          data.reasonForDLRevocation ? data.reasonForDLRevocation : '',
      },
      additionalInfo: data.addInfoL ? data.addInfoL : '',
    }
  }

  return payload;
};








