import { MEMBER_LIFE_POLICY_CMS_DATA } 
  from '../../../pages/policies/life/life-policy.cms';
import { ModalState, ModalTypes } from '../../modal/modal.types';
import memberLifePolicyActionTypes from './life.types';

const payload = {
  context: 'memberLifePolicy',
  status: '',
  error: '',
}

export const setClearForm = (clear) => ({
  type: memberLifePolicyActionTypes.SET_CLEAR_FORM,
  payload: clear
});

export const startFetchingMembersFromDB = () => ({
  type: memberLifePolicyActionTypes.FETCH_MEMBERS_FROM_DB_START,
});

export const fetchMembersFromDBSuccess = (data) => ({
  type: memberLifePolicyActionTypes.FETCH_MEMBERS_FROM_DB_SUCCESS,
  payload: data,
});

export const fetchMembersFromDBFailure = (e) => ({
  type: memberLifePolicyActionTypes.FETCH_MEMBERS_FROM_DB_FAILURE,
  payload: e,
});

export const startFetchingLifePolicyFromDB = (id) => ({
  type: memberLifePolicyActionTypes.FETCH_LIFE_POLICY_FROM_DB_START,
  payload: id,
});

export const fetchLifePolicyFromDBSuccess = (policy) => ({
  type: memberLifePolicyActionTypes.FETCH_LIFE_POLICY_FROM_DB_SUCCESS,
  payload: policy,
});

export const fetchLifePolicyFromDBFailure = (e) => ({
  type: memberLifePolicyActionTypes.FETCH_LIFE_POLICY_FROM_DB_FAILURE,
  payload: e,
});

export const startLoadingStatesFromDB = () => ({
  type: memberLifePolicyActionTypes.LOAD_STATES_FROM_DB_START
});

export const loadStatesFromDBSuccess = (data) => ({
  type: memberLifePolicyActionTypes.LOAD_STATES_FROM_DB_SUCCESS,
  payload: data
});

export const loadStatesFromDBFailure = (e) => ({
  type: memberLifePolicyActionTypes.LOAD_STATES_FROM_DB_FAILURE,
  payload: MEMBER_LIFE_POLICY_CMS_DATA.errMsg.states,
});

export const startLifePolicySave = (data) => ({
  type: memberLifePolicyActionTypes.START_LIFE_POLICY_SAVE,
  payload: data
});

export const lifePolicySaveSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const lifePolicySaveFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLifePolicyUpdate = (id, formData) => ({
  type: memberLifePolicyActionTypes.START_LIFE_POLICY_UPDATE,
  payload: { id, formData }
});

export const lifePolicyUpdateSuccess = () => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.POLICY_SUCCESS,
  },
});

export const lifePolicyUpdateFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});

export const startLifePolicyDelete = (id, PId) => ({
  type: memberLifePolicyActionTypes.START_LIFE_POLICY_DELETE,
  payload: { id, PId },
});

export const lifePolicyDeleteSuccess = () => ({
  type: memberLifePolicyActionTypes.LIFE_POLICY_DELETE_SUCCESS,
});

export const lifePolicyDeleteFailure = (e) => ({
  type: ModalTypes.OPEN_MODAL,
  payload: {
    ...payload,
    status: ModalState.ERROR,
    error: e.response.data.message,
  },
});
