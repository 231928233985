import { Form as FormikForm, withFormik } from 'formik';
import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { startResetPassword } from '../../redux/auth/auth.actions';
import { selectDisplayMessage } from '../../redux/auth/auth.selectors';
import { toggleLoginRegistrationView } from '../../redux/navigation/navigation.actions';
import { setIsSubmitted } from '../../redux/profile/profile.actions';
import { selectIsSubmitted } from '../../redux/profile/profile.selectors';
import PasswordRequirements from '../registration/password.requirements.component';
import { RESET_PASSWORD_CMS_DATA } from './reset-password.cms';
import resetPasswordSchema from './reset-password.validation.schema';

const ResetPassword = ({
  values,
  touched,
  errors,
  displayMessage,
  match: {
    params: { email },
  },
  handleChange,
  handleSubmit,
  history,
  isSubmitted,
  setIsSubmitted,
}) => {
  const { password, action } = RESET_PASSWORD_CMS_DATA;
  values.email = email;

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitted(false);
      history.push('/');
    }
  }, [isSubmitted, setIsSubmitted, history]);

  return (
    <Container className='ie-form-scaffold py-5 mh-100'>
      <Row>
        <Col lg='3'></Col>
        <Col lg='6' className='text-center'>
          {displayMessage ? (
            <p className='text-danger'>{displayMessage}</p>
          ) : null}
        </Col>
        <Col lg='3'></Col>
      </Row>
      <Row>
        <Col lg='4'></Col>
        <Col lg='4'>
          <br />
          <br />
          <FormikForm>
            <Form.Group controlId='newPassword'>
              <Form.Control
                type='password'
                name='password'
                placeholder={password.placeholder}
                value={values.password}
                onChange={handleChange}
                isValid={touched.password && !errors.password}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='confirmNewPassword'>
              <Form.Control
                type='password'
                name='confirmPassword'
                placeholder={password.confirmPassword}
                value={values.confirmPassword}
                onChange={handleChange}
                isValid={touched.confirmPassword && !errors.confirmPassword}
                isInvalid={!!errors.confirmPassword}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <PasswordRequirements data={password.rules} />
            <Button
              variant='success'
              className='float-right'
              onClick={() => handleSubmit()}
            >
              {action.resetPassword}
            </Button>
          </FormikForm>
        </Col>
        <Col lg='4'></Col>
      </Row>
    </Container>
  );
};

const FormikResetPassword = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    confirmPassword: '',
  }),
  validationSchema: resetPasswordSchema,
  handleSubmit: (
    { email, password },
    { props: { startResetPassword, setIsSubmitted }, resetForm, setSubmitting }
  ) => {
    setSubmitting(true);
    startResetPassword({
      email,
      password,
    });
    resetForm();
    setSubmitting(false);
    setIsSubmitted(true);
  },
})(ResetPassword);

const mapStateToProps = createStructuredSelector({
  displayMessage: selectDisplayMessage,
  isSubmitted: selectIsSubmitted,
});

// wrap formik component with redux functions
export default withRouter(
  connect(mapStateToProps, {
    startResetPassword,
    setIsSubmitted,
    toggleLoginRegistrationView,
  })(
    FormikResetPassword
  )
);