export const VEHICLE_CMS_DATA = {
  form: {
    button: {
      next: 'Next',
      previous: 'Previous',
      submit: 'Submit',
      getQuotes: 'Get quotes',
      add: 'Add another',
      update: 'Update existing',
      hide: 'Hide',
    },
    vehicle: {
      member: {
        title: 'Member Selection',
        description: `Please select the Household Member(s) to be associated with this quote. Also, please use "Update existing" if any changes have occured to any Member that is to be included. If you have not entered (all of) them yet, please "Add another."`,
        label: 'Select member(s)',
      },
      vehicle: {
        title: 'Vehicle Selection',
        description: 'Please select the Vehicle(s) that are to be associated with this quote. Also, please use "Update existing" if any changes have occured to any Vehicle that is to be included. If you have not entered (all of) them yet, please "Add another."',
        label: 'Select vehicle(s)',
      },
      onlyLiability: {
        title: 'Liability Only Coverage (NOT Recommended)',
        description: 'If you Finance or Lease your vehicle it is likely that you are required to have "Collision" (always required) and "Comprehensive or Other Than Collision" Coverage (sometimes referred to as "Full Coverage"), which will automatically be included. However, if you own your vehicle, you have the option of getting those coverages or getting "Liability Only" coverage.',
        label: {
          isOnlyLia: 'Do you want Liability Only coverage for any vehicle (NOT Recommended)?',
          vehOnlyLia: 'Vehicles for which you want Liability Only coverage',
          dropdown: {
            isOnlyLia: [
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
            ],
          },
          radios: [
            { key: 'Yes', value: 'true' },
            { key: 'No', value: 'false' },
            { key: 'Unsure', value: 'unsure' },
          ]
        }
      },
      liabilityC: {
        title: 'Suggested Coverages',
        description: 'Feel free to select from any of these inquiries to give the Agents a better idea of your preferences.',
        label: {
          liability: 'Select liability coverage amounts',
          propDamage: 'Select property damage amount',
          mdpi: 'Select Medical or PIP',
          deductibleV: 'Select a preferred deductible amount',
          addInfoL: 'Are there any other coverage preferences or concerns?',
          dropdown: {
            liability: [
              { value: '25000/50000', key: '25,000/50,000' },
              { value: '50000/100000', key: '50,000/100,000' },
              { value: '100000/300000', key: '100,000/300,000' },
              { value: '250000/500000', key: '250,000/500,000' },
              { value: '500000/500000', key: '500,000/500,000' },
            ],
            propDamage: [
              { value: '25000', key: '25,000' },
              { value: '50000', key: '50,000' },
              { value: '100000', key: '100,000' },
              { value: '250000', key: '250,000' },
            ],
            mdpi: [
              { value: 'medical', key: 'Medical' },
              { value: 'pip', key: 'PIP' },
              { value: 'neither', key: 'Neither' },
              { value: 'unsure', key: 'Unsure' },
            ],
            deductibleV: [
              { value: 'none', key: 'Unsure' },
              { value: '250', key: '$250' },
              { value: '500', key: '$500' },
              { value: '1000', key: '$1,000' },
              { value: '1500', key: '$1,500' },
              { value: '2000', key: '$2,000' },
              { value: '3000', key: '$3,000' },
            ],
          },
        }
      },
      combineCV: {
        title: 'Combine Coverages',
        description: 'Most Carriers offer discounts for combining Home and Auto (Vehicle) coverages together.',
        label: {
          combineCV: 'Would you like to combine your Home insurance?',
          combineV: 'Which property would you like to combine with the Auto insurance?',
          dropdown: {
            combineCV: [
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' },
            ],
          }
        }
      }
    },    
    history: {
      auto: {
        title: 'Auto History',
        description:
          "What's great about this site is that you can come back in 6 months and get more quotes, with little effort (simple editing of the already completed form), whether you have had a lapse in coverage or you just want to shop.",
        label: {
          coverage: 'Is your auto coverage current?',
          coverageLength: "What's the span of your most recent coverage?",
          coverageStartDate: 'Current policy start date',
          coverageExpirationDate: 'Current policy expiration date',
          coveragePriorExpirationDate: 'Date coverage expired',
          reasonForCoverageLapse: 'Reason for lapse in coverage',
          dropdown: {
            coverageOptions: [
              { value: 'true', key: 'Yes' },
              { value: 'false', key: 'No' },
            ],
            coverageLength: [
              { value: 'lessThanOneYear', key: 'Less than 1 year' },
              { value: 'oneToThreeYears', key: '1 to 3 years' },
              { value: 'threeToFiveYears', key: '3 to 5 years' },
              { value: 'moreThanFiveYear', key: 'More than 5 years' },
            ],
          },
        },
      },
      dl: {
        title: 'Driving History',
        description: "The Carrier will find out either way so it's better to let the Agent know up front.",
        label: {
          revocationStatus:
            'Any Driver\'s License suspended or revoked in the past 3 years?',
          reasonForRevocation: 'Please explain',
          dropdown: {
            revocationOptions: [
              { value: 'true', key: 'Yes' },
              { value: 'false', key: 'No' },
            ],
          },
        },
      },
    },
  },
  popOver: {
    autoHistory: {
      content: {
        autoCoverage: 'Do you have auto coverage currently? Unfortunately, rates are higher for those who have had a lapse in coverage.',
        coverageLength: 'This is how long you have had constant coverage - without a break. Insurance Carriers want to know that you are planning on keeping coverage for an extended time and are consistant.',
        autoCoveragePriorExpirationDate: 'What was the date for which your last coverage ended? If you never had coverage, leave blank.',
        reasonForAutoCoverageLapse: "It's a good idea to let the Agent know why you might of had a lapse in coverage, as it will help the Agent place you with the appropriate Carrier. If it's a negative reason, the Agent might have you fill out the appropriate forms.",
        autoCoverageStartDate: "What is your current auto coverage's start date?",
        autoCoverageExpirationDate: "What is your current auto coverage's end date? If it's close, it might be easier to switch on this date.",
      }
    },
    drivingHistory: {
      content: {
        dlRevocationStatus: "Has anyone, getting a quote, had their license suspended or revoked? The Carriers will look into this but being upfront will help the Agents place you with the appropriate Carrier.",
      }
    },
    compCov: {
      content: {
        vehicles: 'Please select the owned Vehicles that you want "Liability Only" Coverage for. If you want Comprehensive Coverage on all Vehicles, do not select any. Selecting a Vehicle here will decrease your premium but it will also mean that you are not personally covered, unless someone else is at fault and their insurance covers you.',
      }
    },
    suggestedCov: {
      content: {
        liabilityC: 'Liability coverage format is the "maximum paid to the individual / maximum paid out towards the total cost of the accident." This is if you are at fault and these limits go to the victims. When Comprehensive coverage is included, some Agents will make the limits the same but beware that others might make it lower to lower the premium but it also lowers the coverage. Feel free to state in the box below if you have preference.',
        propDamage: 'Property damage is the monitary value reserved for vehicles and other property that might have been damaged in an accident for which you were at fault. When Comprehensive coverage is included, some Agents will make the Property damage the same but beware that others might make it lower to lower the premium but it also lowers the coverage. Feel free to state in the box below if you have preference.',
        mdpi: 'Medical coverage is coverage that is paid without question for any accident in or around the vehicle - can go towards medical deductible, etc. PIP is similar to Medical but can go towards paying for more than just medical, like time off work, daycare, etc., but keep in mind that it will go to medical first.',
        deductibleV: 'Deductibles might have lower and upper limits. If you finace or lease, you might need to be below a certain range and some Carriers only go so low.',
        addInfoL: "Some coverages include towing, rental, Pet coverage while in the Vehicle, etc. Please let the Agents know if there's something you are wanting to add that's not here or elaborate on one of the coverages.",
      }
    }
  },
}


