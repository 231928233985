export const RESET_PASSWORD_CMS_DATA = {
  password: {
    placeholder: 'Enter New Password',
    confirmPassword: 'Confirm Password',
    invalid: 'Invalid Password. Please refer below.',
    required: 'Password is a required field',
    rules: {
      title: 'Your password must contain at least:',
      firstRule: '8 characters',
      secondRule: '1 uppercase letter',
      thirdRule: '1 lowercase letter',
      fourthRule: '1 number'
    }
  },
  action: {
    resetPassword: 'Reset Password',
  },
  errObj: {
    notMatches: 'Passwords must match'
  }
};
  