export const MODAL_CMS_DATA = {
  success: {
    default: {
      heading: 'Great! Your data has been successfully saved.',
      description: {
        primeMember: 'Your information is in safe keeping for quotes. You can go to the Dashboard or to use this information to get better policies and save money, select Go get Quotes. Especially if it has been almost 6 months for Vehicle or a year for Property, why not?',
        quote: 'Your information is in safe keeping for quotes. If you want to view, edit, or add another, please select Stay in Forms. Or, to use this information to get Quotes, select Go get Quotes. If it has been almost 6 months for vehicle or a year for property, why not?',
      },
      action: {
        dashboard: 'Go to Dashboard',
        primary: 'Go get Quotes',
        secondary: 'Stay in Forms',
      },
      goto: {
        quotes: '/member-quotes',
        household: '/household-members-information',
      }
    },
  },
  policySuccess: {
    default: {
      heading: 'Great! Your policy has been successfully saved.',
      description: 'It will be here to reference at any time, for your convenience.',
      action: {
        primary: 'Go to Dashboard',
        secondary: 'Stay in Policies',
      },
      goto: {
        dashboard: '/member-quotes',
      }
    },
  },
  agentSuccess: {
    default: {
      heading: 'Great! Your data has been successfully updated.',
      description: 'Thank you for updating your information. Keeping your profile up to date helps the site be more accurate with the bidding and could increase your likeliness of being included in creating quotes.',
      action: {
        primary: 'Go to Dashboard',
      },
      goto: {
        dashboard: '/agent-dashboard',
      }
    },
  },
  agentBidSuccess: {
    default: {
      heading: 'Thank you for your bid!',
      description: "Excellent! Your bid is active. You will receive a text if chosen to create a quote. If you wish to place more bids, select Stay in Bidding; if you wish to view the status of the bid you just made or other bids (already placed), please select Continue to My Bids. Reminder: Your bid does not ensure that you will be included in creating quotes for the Member; the site's AI must select you first. If selected, you will receive a text to chat with and enter the member's quote. WARNING: please be aware that any attempt to bypass this site and communicate with the Member directly, before your quote is chosen by the Member and you have paid the pledge amount, is against our policy and the actions outlined in the Terms and Conditions will be taken.",
      action: {
        primary: 'Continue to My Bids',
        secondary: 'Stay in Bidding',
      },
      goto: {
        bid: '/agent-bids',
        bidding: '/agent-bidding'
      }
    },
  },
  memberSelectSuccess: {
    default: {
      heading: 'Excellent Choice!',
      description: 'The agent, whose quote you selected, will be notified to contact you directly in order to turn your quote into a policy. Again, they do need to get ahold of you directly to convert your quote to a policy so please respond to them. Congratulations on your new policy! And, thank you for using InsurEasier!',
      action: {
        primary: 'Continue in My Quotes',
      },
      goto: {
        bid: '/member-view-quotes',
      }
    },
  },
  agentQuoteSuccess: {
    default: {
      heading: 'Quote Uploaded!',
      description: "Thank you for uploading the quote for the Memeber to view! Best wishes on the Member choosing your quote! If your quote is chosen, you will need to pay the pledge fee and upload the policy for the member to access. If you are not chosen, the Member will be removed. NOTE: If this is for a Combined Quotes Request, please make sure to enter a quote for Vehicle and Property seperately. Thanks!",
      action: {
        primary: 'Close',
      },
      goto: {
        bid: '/agent-bids',
      }
    },
  },
  agentUpdateQuoteSuccess: {
    default: {
      heading: 'Policy Updated',
      description: "Thank you for uploading the policy for the Member to view! For the duration of the policy, you can view it as well, under the My Clients tab or through the Dashboard. Thanks for using InsurEasier!",
      action: {
        primary: 'Close',
      },
      goto: {
        bid: '/agent-bids',
      }
    },
  },
  resetPasswordSuccess: {
    default: {
      heading: 'Your password has been successfully reset!',
      description: "It's a good idea to change it periodically, so we are glad you did. Whether or not it's because you forgot ;).",
      action: {
        primary: 'Dismiss',
      },
    },
  },
  quoteRequestSuccess: {
    default: {
      heading: 'Great! Your Quotes Request has been successfully submitted.',
      description: `Our AI is seeking the Agents to best serve your request. We will notify you via text, when Agents have been selected, with more steps. WARNING: although agents are required to go through extensive background checks, there might be some that might try to bypass this site and try to contact you directly. That is against our policy (Terms and Conditions) and any party that is complicit in such behavior could be fined and banned. Please let us know if an agent contacts you directly as you could be monetarily compensated. However, note that once you have selected a quote, that corresponding agent will then need to contact you directly. Thank you for your understanding; we want this to be a pleasant experience for everyone!`,
      action: {
        primary: 'Get more Quotes',
        secondary: 'Go to Dashboard',
      },
      goto: {
        dashboard: '/member-quotes',
        quotes: '/member-quotes',
      }
    },
  },
  error: {
    default: {
      heading: 'Oh Snap! An error occured',
      description:
        'Please, try again!',
      action: {
        primary: 'Dismiss',
      },
    },
  },
};
