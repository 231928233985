export const COMPANION_ANIMAL_CMS_DATA = {
  heading: 'My Companion Animals',
  subHeading: 'For Companion Animal (Pet) insurance. The more detailed and accurate the information you provide, the better our Agents can place you with more accurate quotes.',
  form: {
    button: {
      save: 'Save',
      update: 'Update',
      cancel: 'Cancel',
    },
    selectAnimals: {
      title: 'Animal Selection',
      description: 'Please select the Animal(s) that are to be associated with this quote. If you have not entered (all of) them yet, please Add Another, which will take you to the My Animals page. There you\'ll have the option of returning to get a quote. Note that the required forms are listed above.',
      label: 'Select animals',
      button: 'Move forward',
    },
    addAnimal: {
      title: 'My Animal(s)',
      description: 'To add an animal to your list, select Add an animal. If you have already entered an animal and you want to view or edit them, please select their Type and Name from the drop down.',
      label: 'My animal(s)',
      dropdown: [
        { value: 'addAnimal', label: 'Add an animal' },
      ]
    },
    details: {
      title: 'General Details',
      description:
        "If not for a farm/ranch, please add another animal for each companion animal and provide the specifics to that animal. If a farm/ranch, you can be more general by following the information tabs (i) to the right of each inquiry.",
      label: {
        name: 'Name',
        isHerd: 'Is there a herd?',
        numOfHerds: 'How many in the herd?',
        dateOfBirth: 'Date of Birth',
        species: 'Species',
        breedType: 'Pure breed?',
        breed: 'Type(s) or Breed(s)',
        weight: 'Weight',
        height: 'Height',
        length: 'Length',
        healthConcerns: 'Additional details',
        button: 'Get a quote',
        stepperButton: "Let's move forward",
        dropdown: {
          species: [
            { value: 'dog', key: 'Dog' },
            { value: 'cat', key: 'Cat' },
            { value: 'bird', key: 'Bird' },
            { value: 'fish', key: 'Fish' },
            { value: 'horse', key: 'Equine/Horse' },
            { value: 'livestock', key: 'Livestock' },
            { value: 'exotic', key: 'Exotic' },
          ],
          breedType: [
            { value: 'pure', key: 'Yes' },
            { value: 'mixed', key: 'No' },
            { value: 'unsure', key: 'Unsure' },
          ],
          isHerd: [
            { value: 'yes', key: 'Yes' },
            { value: 'no', key: 'No' },
          ],
        },
      },
    },
  },
  modal: {
    heading: 'Caution! Removing this Animal might delete associated Policies. Consider updating the Form. Do you want to continue deleting',
    action: {
      delete: 'Delete',
      cancel: 'Cancel',
      update: 'Update',
      hide: 'Hide',
      add: 'Add another',
    }
  },
  popOver: {
    details: {
      content: {
        name: "If not a farm/ranch this should be a name specific to your animal. If a farm/ranch, this can be a herd name for a specific Type/Breed and Species. The Date of Birth can be an average.",
        breed: "Try to give the specific type(s) of animal(s) or breed(s). It can be more than one breed. If a farm/ranch, this should be the same across the herd. If there are other types or breeds, those need to be addressed separately (create another Quotes Request).",
        species: "If it's not listed, consider it exotic and feel free to add specifics below in the Additional details box. If a farm/ranch, this should be the same across the herd. If there are other Species, those need to be addressed separately (add another).",
        weight: "What's the weight of the animal or the average weight of the herd? If the animal is light enough that they are measured in ounces, just divide that by 16 (decimals are acceptable).",
        height: "Height is usually from the ground to the top of the shoulder. If a farm/ranch, this would be an average. If you have a prized animal, you might want to separate them from the herd.",
        length: 'Length is usually from the base of the tail (tail not included) and the top of the head (not snout). Again, average for a herd.',
        healthConcerns: "Please include any health concerns (pre-existing conditions, etc.) or any other concerns. For a herd, please give an estimate on how many there are in the single herd.",
      }
    },
  }
};
