import React from 'react';
import { Row, Col, CardGroup, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QUOTE_REQUESTS_CMS_DATA } from './quote-requests-steps.cms';
import '../showcase/showcase.styles.css';

const QuoteRequestsSteps = () => {

  const { imagesSteps } = QUOTE_REQUESTS_CMS_DATA;

  const screenW = window.innerWidth;

  return (
    <Row className={screenW < 525 ? 'mt-5' : ''}>
      {imagesSteps.map((img, i) => (
        <CardGroup
          key={i} as={Col} xs={6} md={6} lg={5}
          className={screenW < 525 ? 'mb-1' : 'mb-4'}
        >
          <OverlayTrigger
            key={i}
            placement='bottom'
            className='pe-auto'
            overlay={<Tooltip id='button-tooltip-2'>{img.overlay}</Tooltip>}
          >
            <Card
              className='text-center pt-3 pb-0 req-steps-bg'
            >
              <Card.Img
                variant='top'
                src={require(`${img.url}`)}
                alt={img.title}
                width={i % 2 === 0 ? '50' : '45'}
                height={i === 0 ? '41' : '39'}
                className={
                  screenW < 525 
                    ? 'w-30 m-auto fluid'
                    : screenW > 525 && i === 0
                      ? 'w-21 m-auto fluid'
                      : 'w-19 m-auto fluid'
                }
              />
              <Card.Body className='px-1 py-3'>
                {img.title2 ? (
                  <>
                    <p className='benefit-text mb-1 font-weight-normal' style={{fontSize: '0.9rem'}}>Step {`${i + 1}: `}{img.title}</p>
                    <p className='benefit-text mb-1 font-weight-normal' style={{fontSize: '0.9rem'}}>{img.title2}</p>
                  </>
                ) : (
                  <p className = 'benefit-text mb-1 font-weight-normal'>Step{`${i + 1}: `}{img.title}</p>
                )}
              </Card.Body>
            </Card>
          </OverlayTrigger>
        </CardGroup>
      ))}
    </Row>         
  );
};

export default QuoteRequestsSteps;