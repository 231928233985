import { Field, FieldArray } from 'formik'
import React, { createRef, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { AGENT_CMS_DATA } from '../../pages/agent/agent.cms'
import { selectStates, selectAgent } from '../../redux/agent/agent.selectors'
import { selectPrimeMember } from '../../redux/member/member.selectors'
import ErrorMessage from '../error-message/error-message.component'

const Licenses = ({
  values,
  handleChange,
  touched,
  errors,
  states,
  agent,
  reset,
}) => {
  const {
    form: {
      license: { title, description, label },
    },
  } = AGENT_CMS_DATA

  const stateRef = createRef()

  const path = window.location.pathname
  const agentP = '/agent-profile'

  let licenses = values.licenses

  for (let i = 0; i < licenses.length; i++) {
    if (licenses[i].state === '') {
      licenses[i].number = ''
    }
  }

  useEffect(() => {
    reset && stateRef.current.clear()
  }, [reset, stateRef])

  return (
    <div className="ie-form">
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={6} controlId="npn">
              <Form.Label>{label.nationalProducerNumber}</Form.Label>
              <Form.Control
                readOnly={path === '/agent-profile' ? true : false}
                name="nationalProducerNumber"
                value={values.nationalProducerNumber}
                onChange={handleChange}
                isValid={
                  touched.nationalProducerNumber &&
                  !errors.nationalProducerNumber
                }
                isInvalid={!!errors.nationalProducerNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nationalProducerNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={6} controlId="ssn">
              <Form.Label>{label.ssn}</Form.Label>
              <Form.Control
                readOnly={path === agentP ? true : false}
                name="ssn"
                value={values.ssn}
                onChange={handleChange}
                isValid={touched.ssn && !errors.ssn}
                isInvalid={!!errors.ssn}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ssn}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          {licenses && licenses.length !== 0 ? (
            <FieldArray
              name="licenses"
              render={(arrayHelpers) => (
                <div>
                  {licenses.map((o, i) => (
                    <div key={i}>
                      <Form.Row>
                        <Form.Group as={Col} lg={6} controlId={`state${i}`}>
                          <Form.Label>{label.states}</Form.Label>
                          <Field name={`licenses[${i}].state`}>
                            {({ field, form }) => (
                              <Typeahead
                                disabled={
                                  path === agentP &&
                                  agent &&
                                  agent.licenses &&
                                  agent.licenses.length > 0 &&
                                  agent.licenses[i] &&
                                  agent.licenses[i].state
                                    ? true
                                    : false
                                }
                                id={`select-license-state${i}`}
                                ref={stateRef}
                                name={`licenses[${i}].state`}
                                options={states}
                                isValid={
                                  errors &&
                                  errors.licenses &&
                                  errors.licenses[i]
                                    ? touched.licenses[i].state &&
                                      !errors.licenses[i].state
                                    : touched.licenses && !errors.licenses
                                }
                                isInvalid={
                                  errors &&
                                  errors.licenses &&
                                  errors.licenses[i]
                                    ? !!errors.licenses[i].state
                                    : !!errors.licenses
                                }
                                selected={[licenses[i].state]}
                                onChange={(selectedState, i) => {
                                  if (selectedState.length !== 0) {
                                    form.setFieldValue(
                                      field.name,
                                      selectedState[0].value,
                                    )
                                  }
                                }}
                                onInputChange={(text) => {
                                  form.setFieldValue(field.name, text)
                                }}
                              />
                            )}
                          </Field>
                          <Form.Control.Feedback type="invalid">
                            <ErrorMessage name={`licenses[${i}].state`} />
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId={`number${i}`}>
                          <Form.Label>{label.number}</Form.Label>
                          <Form.Control
                            readOnly={
                              path === agentP &&
                              agent &&
                              agent.licenses &&
                              agent.licenses.length > 0 &&
                              agent.licenses[i] &&
                              agent.licenses[i].number
                                ? true
                                : false
                            }
                            name={`licenses[${i}].number`}
                            value={licenses[i].number}
                            onChange={handleChange}
                            isValid={
                              errors && errors.licenses && errors.licenses[i]
                                ? touched.licenses[i].number &&
                                  !errors.licenses[i].number
                                : touched.licenses && !errors.licenses
                            }
                            isInvalid={
                              errors && errors.licenses && errors.licenses[i]
                                ? !!errors.licenses[i].number
                                : !!errors.licenses
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            <ErrorMessage name={`licenses[${i}].number`} />
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} lg={12}>
                          {i >= 0 && licenses.length !== 1 ? (
                            <div className="float-right">
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  arrayHelpers.remove(i)
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          ) : null}
                          {licenses.length - 1 === i ? (
                            <div className="float-left">
                              <Button
                                variant="primary"
                                onClick={() => {
                                  if (
                                    licenses[i].state !== '' &&
                                    licenses[i].number !== ''
                                  ) {
                                    arrayHelpers.push({
                                      state: '',
                                      number: '',
                                    })
                                  }
                                }}
                              >
                                Add Another
                              </Button>
                            </div>
                          ) : null}
                        </Form.Group>
                      </Form.Row>
                    </div>
                  ))}
                </div>
              )}
            ></FieldArray>
          ) : null}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  states: selectStates,
  agent: selectAgent,
  primeMember: selectPrimeMember,
})

export default connect(mapStateToProps, {})(Licenses)
