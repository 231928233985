import React, { useEffect, useState } from 'react';
import { Col, Form, Row, InputGroup } from 'react-bootstrap';
import { PROPERTY_QUOTES_CMS_DATA } from '../../../pages/quotes/property/property.cms';
import InfoIcon from '../../info-icon/info-icon.component';
import NumberFormation from '../../number-format/number-format.component';
import RadioButton from '../../radio-button/radio-button.component';
import { useHistory } from 'react-router-dom';

const Ownership = ({ values, handleChange, touched, errors }) => {
  const {
    form: {
      property: {
        ownership: {
          title,
          description,
          value,
          year,
          area,
          stories,
          renovation,
        },
      },
    },
    popOver: { ownership: { content } }

  } = PROPERTY_QUOTES_CMS_DATA;

  const history = useHistory();
  const path = history.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  const [hasRenovations, setHasRenovations] = useState(false);

  useEffect(() => {
    values.hasRenovations === 'true'
      ? setHasRenovations(true)
      : setHasRenovations(false);
  }, [values, setHasRenovations]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group as={Col} lg={7} controlId='value'>
              <Form.Label>{value.label}</Form.Label>
              <InfoIcon 
                className='float-right'
                content={content.value}
              />
              <InputGroup className='mb-2 rounded-right'>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <NumberFormation
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='value'
                  value={values.value}
                  errors={errors}
                  touched={touched}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.value}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} lg={5} controlId='year'>
              <Form.Label>{year.label}</Form.Label>
              <InfoIcon 
                className='float-right'
                content={content.year}
              />
              <Form.Control
                readOnly={
                  path === agentBid ? true : false
                }
                name='year'
                value={values.year}
                onChange={handleChange}
                isValid={touched.year && !errors.year}
                isInvalid={!!errors.year}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.year}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg={7} controlId='area'>
              <Form.Label>{area.label}</Form.Label>
              <InfoIcon 
                className='float-right'
                content={content.area}
              />
              <NumberFormation
                readOnly={
                  path === agentBid ? true : false
                }
                name='area'
                value={values.area}
                errors={errors}
                touched={touched}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.area}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={5} controlId='stories'>
              <Form.Label>{stories.label}</Form.Label>
              <InfoIcon 
                className='float-right'
                content={content.stories}
              />
              <Form.Control
                readOnly={
                  path === agentBid ? true : false
                }
                name='stories'
                value={values.stories}
                onChange={handleChange}
                isValid={touched.stories && !errors.stories}
                isInvalid={!!errors.stories}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.stories}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg={4} controlId='has-renovations'>
              <Form.Label>{renovation.label}</Form.Label>
              <InfoIcon 
                className='float-right'
                content={content.hasRenovations}
              />
              <br />
              {renovation.dropdown.map((option, i) => (
                <RadioButton
                  key={i}
                  idx={i}
                  name='hasRenovations'
                  option={option}
                />
              ))}
              <Form.Control.Feedback type='invalid'>
                {errors.hasRenovations}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          {/* show this if user has selected yes for renovations */}
          {hasRenovations ? (
            <Form.Row>
              <Form.Group as={Col} lg={8} controlId='renovation-details'>
                <Form.Label>{renovation.details.label}</Form.Label>
                <Form.Control
                  readOnly={
                    path === agentBid ? true : false
                  }
                  name='renovationDetails'
                  as='textarea'
                  value={values.renovationDetails}
                  onChange={handleChange}
                  isValid={
                    touched.renovationDetails && !errors.renovationDetails
                  }
                  isInvalid={!!errors.renovationDetails}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.renovationDetails}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default Ownership;
