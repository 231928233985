import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import { store } from './redux/store';
import { Modal } from './components/modal/modal.component';
import ScriptTag from 'react-script-tag';
import { initChat } from './CometChat';

import loadable from '@loadable/component';

const Navigation = loadable(() => import('./pages/navigation/navigation.component'));
const Footer = loadable(() => import('./components/footer/footer.component'));

function App(props) {
  return (
    <Provider store={store}>
      <div className='App'>
        <Navigation />
          {props.children}
        <Footer />
        <Modal />
      </div>
      <ScriptTag
        async
        defer
        type="text/javascript"
        title="CometChat Widget"
        src="https://widget-js.cometchat.io/v3/cometchatwidget.js"
        onLoad={() => initChat()}
      />
    </Provider>
  );
}

export default App;
