import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import Login from '../login/login.component';
import Registration from '../registration/registration.component';
import './showcase.styles.css';
import badge from './badge.webp';

const Showcase = ({ showLoginOrRegistrationView }) => {

  const path = window.location.pathname;
  const agentHP = '/agent-home';
  const screenW = window.innerWidth;

  return (
    <section id='agent-showcase-section'>
      <div className='dark-overlay'>
        <div className='home-inner'>
          <Container>
            <Row
              className={
                screenW < 991
                  ? 'px-1'
                  : 'px-3'
                } 
              >
              <Col lg={8} className='m-auto'>
                <h1
                  className='mb-0 font-size-xxl'
                >
                  We empower insurance professionals to thrive with live, real-time, hot lead generation!
                </h1>
                <p className='pl-1 pt-4 pb-3 d-none d-lg-block text-white font-size-xl'>
                  Expand your client base effortlessly without paying upfront! Join us to streamline
                  your business growth and unlock new opportunities in the insurance market.
                </p>
                <div className='d-none d-lg-block font-size-xl'>
                  <div className='px-1 py-1 d-flex flex-row'>
                    <div className='align-self-start'>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className='showcase-icons'
                      />
                    </div>
                    <p className='px-3 align-self-end text-white'>
                      Connect with genuine leads seeking insurance
                    </p>
                  </div>
                  <div className='px-1 py-1 d-flex flex-row'>
                    <div className='align-self-start'>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className='showcase-icons'
                      />
                    </div>
                    <p className='px-3 align-self-end text-white'>
                      No harassing leads with phone calls, emails, etc.
                    </p>
                    </div>
                  <div className='px-1 py-1 d-flex flex-row'>
                    <div className='align-self-start'>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className='showcase-icons'
                      />
                    </div>
                    <p className='px-3 align-self-end text-white'>
                      Pay fee only upon policy sales. Win-win!
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={4} className='m-auto'>
               <div className='text-center'>
                  <img
                    alt='Badge'
                    src={badge}
                    height='45'
                    width='45'
                    className='mb-2'
                  />
                  <p
                    className='text-l-green font-size-l font-weight-normal pb-0 mb-1'
                  >
                    {path === agentHP
                      ? 'Join now, pay only for converted'
                      : 'Register for best possible policy!'
                    }
                  </p>
                  <p
                    className='text-l-green font-size-l font-weight-normal pb-1'
                  >
                    {path === agentHP
                      ? 'leads, skyrocket your sales!'
                      : ''
                    }
                  </p>
                  {path === agentHP ? (
                    <p className='text-l-green pb-0 mb-0'>Enjoy 12 months free subscription today*</p>
                  ) : null}
                </div>
                {showLoginOrRegistrationView === 'login' ? (
                  <Login />
                ) : (
                  <Registration />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ navigation: { showLoginOrRegistrationView } }) => ({
  showLoginOrRegistrationView,
});

export default connect(mapStateToProps)(Showcase);



