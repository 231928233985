import * as yup from 'yup';

const companionAnimalSchema = yup.object().shape({
  isHerd: yup
    .string()
    .trim()
    .required('Please select if there is herd'),
  numOfHerds: yup
    .number()
    .typeError('Please enter a valid number')
    .positive('Please enter a valid height')
    .min(5, 'Please enter a number of heards at least five')
    .optional(),
  name: yup
    .string()
    .trim()
    .required('Please enter a name')
    .min(2, 'Please enter a name with atleast two characters'),
  dateOfBirth: yup
    .date()
    .required('Please provide a date of birth'),
  type: yup
    .string()
    .required('Please provide a species type')
    .min(1, 'Please select a type.'),
  breed: yup
    .string()
    .trim()
    .required('Please provide a breed')
    .min(2, 'Please enter a breed with at least two characters'),
  breedType: yup
    .string()
    .trim()
    .required('Please provide a breed type')
    .ensure()
    .min(1, 'Please select a type'),
  weight: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Please provide a weight')
    .positive('Please enter a valid weight')
    .max(15000, 'Please enter a weight less than 15000 lbs'),
  height: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Please provide a height')
    .positive('Please enter a valid height')
    .max(999, 'Please enter a height no more than 999 inches'),
  length: yup
    .number()
    .typeError('Please enter a valid number')
    .required('Please provide a height')
    .positive('Please enter a valid height')
    .max(999, 'Please enter a height not more than 999 inches'),
  healthConcerns: yup
    .string()
    .trim()
    .max(255, 'Please limit content to no more than 255 characters'),
});

export default companionAnimalSchema;
