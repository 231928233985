import * as yup from 'yup';

const agentIdentificationSchema = yup.object().shape({
  documentType: yup
    .string()
    .trim()
    .required('Please select valid document type'),
  documentNumber: yup
    .string()
    .trim()
    .min(0)
    .max(13, 'Please enter no more than 13 characters')
    .required('Please provide the document number'),
  expiration: yup.date().required('Please select document expiration date'),
  documentIssueState: yup
    .string()
    .trim()
    .min(2, 'Please enter at least two characters')
    .max(255, 'Please enter no more than 255 characters')
    .when('documentType', {
      is: (val) => val !== 'passport',
      then: yup.string().required('Please select state'),
      otherwise: yup.string().notRequired(),
    }),
});

export default agentIdentificationSchema;
