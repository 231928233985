const memberHistoryActionTypes = {
  START_MEMBER_HISTORY_SAVE: 'START_MEMBER_HISTORY_SAVE',
  MEMBER_HISTORY_SAVE_SUCCESS: 'MEMBER_HISTORY_SAVE_SUCCESS',
  MEMBER_HISTORY_SAVE_FAILURE: 'MEMBER_HISTORY_SAVE_FAILURE',
  FETCH_MEMBER_HISTORY_FROM_DB_START: 'FETCH_MEMBER_HISTORY_FROM_DB_START',
  FETCH_MEMBER_HISTORY_FROM_DB_SUCCESS: 'FETCH_MEMBER_HISTORY_FROM_DB_SUCCESS',
  FETCH_MEMBER_HISTORY_FROM_DB_FAILURE: 'FETCH_MEMBER_HISTORY_FROM_DB_FAILURE',
  FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_START: 'FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_START',
  FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_SUCCESS: 'FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_SUCCESS',
  FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_FAILURE: 'FETCH_MEMBER_HISTORY_BY_ID_FROM_DB_FAILURE',
  START_MEMBER_HISTORY_UPDATE: 'START_MEMBER_HISTORY_UPDATE',
  RESET_FORM: 'RESET_FORM',
};

export default memberHistoryActionTypes;
