import { ModalTypes } from './modal.types';

const INITAL_STATE = {
  status: '',
  name: 'default',
  error: ''
};

const modalReducer = (state = INITAL_STATE, { type, payload }) => { 
  switch(type) {
    case ModalTypes.OPEN_MODAL:
      return {
        ...state,
        ...payload
      };
    case ModalTypes.DISMISS_MODAL:
      return {
        ...state,
        ...INITAL_STATE
      }
    default:
      return state;
  }
};

export default modalReducer;
