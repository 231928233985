import annuityQuoteActionTypes from "./annuity.types";
import authActionTypes from "../../auth/auth.types";
import { ModalTypes } from "../../modal/modal.types";

let INITIAL_STATE = {
  currentStep: 0,
  formData: {},
  quoteId: "",
  membersInfo: [],
  annuityQuotesFromDB: [],
  annuityQuoteFromDB: {},
  reset: false,
};

const PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.annuityQuote) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.annuityQuote);
}

const annuityQuoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case annuityQuoteActionTypes.ANNUITY_QUOTE_SAVE_SUCCESS:
    case annuityQuoteActionTypes.ANNUITY_QUOTE_UPDATE_SUCCESS:
    case annuityQuoteActionTypes.ANNUITY_INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case annuityQuoteActionTypes.ANNUITY_DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case annuityQuoteActionTypes.SET_ANNUITY_QUOTE_ID:
      return {
        ...state,
        quoteId: action.payload,
      };
    case annuityQuoteActionTypes.SET_ANNUITY_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case annuityQuoteActionTypes.SET_MEMBERS_INFO:
      return {
        ...state,
        membersInfo: action.payload,
      };
    case ModalTypes.OPEN_MODAL:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        quoteId: "",
        membersInfo: [],
        annuityQuotesFromDB: [],
        annuityQuoteFromDB: {},
        reset: false,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        currentStep: 0,
        formData: {},
        quoteId: "",
        membersInfo: [],
        annuityQuotesFromDB: [],
        annuityQuoteFromDB: {},
        reset: false,
      };
    case annuityQuoteActionTypes.FETCH_ANNUITY_QUOTES_FROM_DB_SUCCESS:
      return {
        ...state,
        annuityQuotesFromDB: action.payload,
      };
    case annuityQuoteActionTypes.FETCH_ANNUITY_QUOTE_FROM_DB_SUCCESS:
      return {
        ...state,
        annuityQuoteFromDB: action.payload,
      };
    case annuityQuoteActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    default:
      return state;
  }
};

export default annuityQuoteReducer;
