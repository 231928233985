import React, {useEffect} from 'react';
import { Container, Card, CardGroup, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { MEMBER_DASHBOARD_CMS_DATA } from './member.dashboard.cms';
import { selectUser } from '../../redux/auth/auth.selectors';
import {
  startFetchingMemberFromDB} from '../../redux/member/member.actions';
import { selectMember, selectPrimeMember } from '../../redux/member/member.selectors';
import {
  setIsRefresh
} from '../../redux/auth/auth.actions';
import {
  selectIsRefresh,
  selectIsUserLoggedIn,
} from '../../redux/auth/auth.selectors';
import './member-dashboard.styles.css';

const MemberDashboard = ({
  member,
  currentUser,
  startFetchingMemberFromDB,
}) => {
  const {
    initial: {
      heading, subHeading, cards
    }
  } = MEMBER_DASHBOARD_CMS_DATA;

  const screenW = window.innerWidth;
  
  const navigateToPage = (event) => {
    window.location.href = event.currentTarget.dataset.goto;
  };

  const isMemberLastStep = member && member.isMemberLastStep;

  useEffect(() => {
    if (currentUser.member) {
      startFetchingMemberFromDB(currentUser.member);
    }
  }, [
    currentUser.member,
    startFetchingMemberFromDB,
  ])
            
  return (
    <section className='ie-form-scaffold'>
      {isMemberLastStep === true ? (
        <Container>
          <header className='py-4'>
            <h2>{heading}</h2>
            <p>{subHeading}</p>
          </header>
          <Row>
            {cards.map((card, i) => (
              <CardGroup key={i} as={Col} xs={12} md={6} lg={3} className='mb-4'>
                <Card
                  onClick={navigateToPage}
                  data-goto={card.goto}
                  className='text-center pt-4 pb-3 px-2'
                >
                  <Card.Img
                    src={require(`${card.imageUrl}`)}
                    alt={card.title}
                    width={screenW < 525 ? '65' : '68'}
                    height={screenW < 525 ? '74' : '68'}
                    className={
                      screenW < 525
                        ? 'w-25 m-auto fluid'
                        : 'w-30 m-auto fluid'
                    }
                  />
                  <Card.Body className='p-2'>
                    <p className='pt-3 font-size-l font-weight-normal'>{card.title}</p>
                    <p className='text-left'>{card.text}</p>
                  </Card.Body>
                </Card>
              </CardGroup>
            ))}
          </Row>
        </Container> 
      ) : null} 
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectUser,
  primeMember: selectPrimeMember,
  member: selectMember,
  isRefresh: selectIsRefresh,
  isUserLoggedIn: selectIsUserLoggedIn,
});

export default withRouter(connect(mapStateToProps, {
  setIsRefresh,
  startFetchingMemberFromDB
})(MemberDashboard));