import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { HEALTH_QUOTE_CMS_DATA } from '../../../pages/quotes/health/health.cms';
import InfoIcon from '../../info-icon/info-icon.component';
import RadioButton from '../../radio-button/radio-button.component';

const BodyMeasurement = ({
  values,
  handleChange,
  touched,
  errors,
}) => {
  const {
    form: {
      measurement: { title, description, weight, height },
    },
    popOver: { body: { content } }
  } = HEALTH_QUOTE_CMS_DATA;

  const [showWeightChangeView, setShowWeightChangeView] = useState(false);

  const path = window.location.pathname.replace(/\//g, '');
  const agentBid = 'agent-bids';

  useEffect(() => {
    values.weightChange === 'increased' ||
    values.weightChange === 'decreased'
      ? setShowWeightChangeView(true)
      : setShowWeightChangeView(false);
  }, [values.weightChange, setShowWeightChangeView]);

  return (
    <div className='ie-form'>
      <Row>
        <Col lg={4}>
          <h5>{title}</h5>
          <h6>{description}</h6>
        </Col>
        <Col lg={8}>
          <Form.Row>
            <Form.Group
              as={Col}
              lg={12}
              controlId='height-feet'
              ie-hint-feet='true'
            >
              <Form.Label>{height.label.feet}</Form.Label>
              <br/>
              {height.dropdown.feet.map((option, i) => (
                <RadioButton
                  key={i}
                  idx={i}
                  name='heightF'
                  option={option}
                />
              ))}
              <Form.Control.Feedback type='invalid'>
                {errors.heightF}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              lg={11}
              controlId='height-inches'
              ie-hint-inches='true'
            >
              <Form.Label>{height.label.inches}</Form.Label>
              <br/>
              {height.dropdown.inches.map((option, i) => (
                <RadioButton
                  key={i}
                  idx={i}
                  name='heightI'
                  option={option}
                />
              ))}
              <Form.Control.Feedback type='invalid'>
                {errors.heightI}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={4} controlId='weight-lb' ie-hint-lbs='true'>
              <Form.Label>{weight.label.weight}</Form.Label>
              <Form.Control
                readOnly={path === agentBid ? true : false}
                name='weight'
                value={values.weight}
                onChange={handleChange}
                isValid={touched.weight && !errors.weight}
                isInvalid={!!errors.weight}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.weight}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={8} controlId='weight-change'>
              <Form.Label>{weight.label.weightChange}</Form.Label>
              <InfoIcon 
                className='float-right'
                content={content.weightChange}
              />
              <br/>
              {weight.dropdown.map((option, i) => (
                <RadioButton
                  key={i}
                  idx={i}
                  name='weightChange'
                  option={option}
                />
              ))}
              <Form.Control.Feedback type='invalid'>
                {errors.weightChange}
              </Form.Control.Feedback>
            </Form.Group>
            {showWeightChangeView ? (
              <Form.Group
                as={Col}
                lg={4}
                controlId='weight-difference'
                ie-hint-lbs='true'
              >
                <Form.Label>{weight.label.weightDiff}</Form.Label>
                <InfoIcon 
                  className='float-right'
                  content={content.weightDiff}
                />
                <Form.Control
                  readOnly={path === agentBid ? true : false}
                  name='weightDiff'
                  defaultValue={values.weightDiff}
                  onChange={handleChange}
                  isValid={touched.weightDiff && !errors.weightDiff}
                  isInvalid={!!errors.weightDiff}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.weightDiff}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
          </Form.Row>
        </Col>
      </Row>
    </div>
  );
};

export default BodyMeasurement;
