import agentActionTypes from "./agent.types";
import authActionTypes from "../auth/auth.types";

let INITIAL_STATE = {
  currentStep: 0,
  agent: {},
  agents: [],
  formData: {},
  statesFromDB: [],
  carriersFromDB: [],
  carriersObj: {},
  occupationsFromDB: [],
  reset: false,
  verifyAgent: {},
  clientToken: null,
  startPhoneVerify: null,
  isPhoneVerified: null,
  codePhoneErr: null,
  sendPhoneErr: null,
};

let PERSISTED_STATE = JSON.parse(localStorage.getItem("persist:insureasier"));

if (PERSISTED_STATE && PERSISTED_STATE.agent) {
  INITIAL_STATE = JSON.parse(PERSISTED_STATE.agent);
}

const agentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case agentActionTypes.FETCH_AGENT_FROM_DB_SUCCESS:
      return {
        ...state,
        agent: action.payload,
      };
    case agentActionTypes.FETCH_ALL_AGENTS_FROM_DB_SUCCESS:
      return {
        ...state,
        agents: action.payload,
      };
    case agentActionTypes.SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case agentActionTypes.INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case agentActionTypes.DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case agentActionTypes.LOAD_STATES_FROM_DB_SUCCESS:
      return {
        ...state,
        statesFromDB: action.payload,
      };
    case agentActionTypes.LOAD_AGENT_CARRIERS_FROM_DB_SUCCESS:
      return {
        ...state,
        carriersFromDB: action.payload,
      };
    case agentActionTypes.SET_AGENT_CARRIERS_OBJECT:
      return {
        ...state,
        carriersObj: action.payload,
      };
    case agentActionTypes.LOAD_OCCUPATIONS_FROM_DB_SUCCESS:
      return {
        ...state,
        occupationsFromDB: action.payload,
      };
    case agentActionTypes.FETCH_VERIFY_AGENT_SUCCESS:
      return {
        ...state,
        verifyAgent: action.payload,
      };
    case agentActionTypes.FETCH_BRAINTREE_CLIENT_TOKEN_SUCCESS:
      return {
        ...state,
        clientToken: action.payload,
      };
    case agentActionTypes.CHECKOUT_PAYPAL_SUCCESS:
      return {
        ...state,
        agent: action.payload,
      };
    case agentActionTypes.CHECKOUT_PAYPAL_FAILURE:
      return {
        ...state,
        agent: {
          ...state.agent,
          isPaymentError: action.payload,
        },
      };
    case agentActionTypes.GET_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        startPhoneVerify: action.payload,
      };
    case agentActionTypes.GET_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        sendPhoneErr: action.payload,
      };
    case agentActionTypes.CHECK_CODE_TO_VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isPhoneVerified: action.payload,
      };
    case agentActionTypes.CHECK_CODE_TO_VERIFY_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        codePhoneErr: action.payload,
      };
    case agentActionTypes.RESET_FORM:
      return {
        ...state,
        reset: true,
      };
    case agentActionTypes.RESET_AGENT:
    case authActionTypes.LOGOUT:
      return {
        ...state,
        currentStep: 0,
        agent: {},
        agents: [],
        formData: {},
        statesFromDB: [],
        carriersFromDB: [],
        carriersObj: {},
        occupationsFromDB: [],
        reset: false,
        verifyAgent: {},
        clientToken: null,
        startPhoneVerify: null,
        isPhoneVerified: null,
        sendPhoneErr: null,
        codePhoneErr: null,
        personVeriff: null,
        attemptsVeriff: null,
      };
    default:
      return state;
  }
};

export default agentReducer;
