import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../helpers/configure-axios";
import {
  fetchMemberFromDBFailure,
  fetchMemberFromDBSuccess,
  fetchPrimeMemberFromDBFailure,
  fetchPrimeMemberFromDBSuccess,
  fetchAllMembersFromDBFailure,
  fetchAllMembersFromDBSuccess,
  loadOccupationsFromDBFailure,
  loadOccupationsFromDBSuccess,
  loadStatesFromDBFailure,
  loadStatesFromDBSuccess,
  memberSaveSuccess,
  memberSaveFailure,
  memberUpdateSuccess,
  memberUpdateLastStepSuccess,
  memberUpdateFailure,
  memberDeleteSuccess,
  memberDeleteFailure,
  memberQuoteSaveSuccess,
  memberQuoteSaveFailure,
  memberQuoteUpdateSuccess,
  memberQuoteUpdateFailure,
  getCodeToVerifyPhoneNumberSuccess,
  getCodeToVerifyPhoneNumberFailure,
  checkCodeToVerifyPhoneNumberSuccess,
  checkCodeToVerifyPhoneNumberFailure,
} from "./member.actions";
import memberActionTypes from "./member.types";
import { convertMemberForDBSave } from "./member.utils";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* loadOccupationsFromDB() {
  try {
    let occupations = [];
    const url = "./db/occupations.json";
    const response = yield axios.get(url);
    occupations = yield response.data;
    yield put(loadOccupationsFromDBSuccess(occupations));
  } catch (e) {
    yield put(loadOccupationsFromDBFailure(e));
  }
}

function* fetchAllMembersFromDB() {
  try {
    const response = yield axios.get(`/api/members`);
    const members = yield response.data;
    yield put(fetchAllMembersFromDBSuccess(members));
  } catch (e) {
    yield put(fetchAllMembersFromDBFailure(e));
  }
}

function* fetchPrimeMemberFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/members/${id}`);
    const member = yield response.data;
    yield put(fetchPrimeMemberFromDBSuccess(member));
  } catch (e) {
    yield put(fetchPrimeMemberFromDBFailure(e));
  }
}

function* fetchMemberFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/members/${id}`);
    const member = yield response.data;
    yield put(fetchMemberFromDBSuccess(member));
  } catch (e) {
    yield put(fetchMemberFromDBFailure(e));
  }
}

function* saveMember({ payload: formData }) {
  try {
    const data = yield call(convertMemberForDBSave, formData);
    yield axios.post("/api/members", data, configureAxios());
    yield put(memberSaveSuccess());
  } catch (e) {
    yield put(memberSaveFailure(e));
  }
}

function* updateMember({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberForDBSave, formData);
    yield axios.patch(`/api/members/${id}`, data, configureAxios());

    const path = window.location.pathname;

    if (
      (data &&
        data.isMemberLastStep &&
        data.details &&
        data.details.isPhoneValid) ||
      path === "/household-members-information" ||
      path === "/member-information"
    ) {
      yield put(memberUpdateLastStepSuccess());
    } else {
      yield put(memberUpdateSuccess());
    }
  } catch (e) {
    yield put(memberUpdateFailure(e));
  }
}

function* deleteMember({ payload: { id, PId, HId } }) {
  try {
    axios.delete(
      `/api/members/${id}`,
      { params: { primeMemberId: PId, healthId: HId } },
      configureAxios()
    );
    yield put(memberDeleteSuccess());
  } catch (e) {
    yield put(memberDeleteFailure(e));
  }
}

function* saveMemberQuote({ payload: formData }) {
  try {
    const data = yield call(convertMemberForDBSave, formData);
    yield axios.post("/api/members", data, configureAxios());
    yield put(memberQuoteSaveSuccess());
  } catch (e) {
    yield put(memberQuoteSaveFailure(e));
  }
}

function* updateMemberQuote({ payload: { id, formData } }) {
  try {
    const data = yield call(convertMemberForDBSave, formData);
    const response = yield axios.patch(
      `/api/members/${id}`,
      data,
      configureAxios()
    );
    const member = yield JSON.parse(response.config.data);
    yield put(memberQuoteUpdateSuccess(member));
  } catch (e) {
    yield put(memberQuoteUpdateFailure(e));
  }
}

function* startPhoneNumberVerify({ payload: { id, data } }) {
  try {
    const url = `/api/members/${id}/${data.phoneNumber}/start-verify`;
    const response = yield axios.get(url);
    const verification = yield response.data.verificationRequest;
    yield put(getCodeToVerifyPhoneNumberSuccess(verification));
  } catch (e) {
    yield put(getCodeToVerifyPhoneNumberFailure(e.response.data));
  }
}

function* checkPhoneNumberVerify({ payload: { id, data } }) {
  try {
    const url = `/api/members/${id}/phone/check-verify`;
    const response = yield axios.post(url, data, configureAxios());
    const codeVerified = yield response.data;
    yield put(checkCodeToVerifyPhoneNumberSuccess(codeVerified));
  } catch (e) {
    yield put(checkCodeToVerifyPhoneNumberFailure(e.response.data));
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(
    memberActionTypes.LOAD_STATES_FROM_DB_START,
    loadStatesFromDB
  );
}

function* onstartLoadingOccupationsFromDB() {
  yield takeEvery(
    memberActionTypes.LOAD_OCCUPATIONS_FROM_DB_START,
    loadOccupationsFromDB
  );
}

function* onFetchAllMembersFromDB() {
  yield takeEvery(
    memberActionTypes.FETCH_ALL_MEMBERS_FROM_DB_START,
    fetchAllMembersFromDB
  );
}

function* onFetchPrimeMemberFromDB() {
  yield takeEvery(
    memberActionTypes.FETCH_PRIME_MEMBER_FROM_DB_START,
    fetchPrimeMemberFromDB
  );
}

function* onFetchMemberFromDB() {
  yield takeEvery(
    memberActionTypes.FETCH_MEMBER_FROM_DB_START,
    fetchMemberFromDB
  );
}

function* onMemberSaveStart() {
  yield takeEvery(memberActionTypes.START_MEMBER_SAVE, saveMember);
}

function* onMemberUpdateStart() {
  yield takeEvery(memberActionTypes.START_MEMBER_UPDATE, updateMember);
}

function* onMemberDeleteStart() {
  yield takeEvery(memberActionTypes.START_MEMBER_DELETE, deleteMember);
}

function* onMemberQuoteSaveStart() {
  yield takeEvery(memberActionTypes.START_MEMBER_QUOTE_SAVE, saveMemberQuote);
}

function* onMemberQuoteUpdateStart() {
  yield takeEvery(
    memberActionTypes.START_MEMBER_QUOTE_UPDATE,
    updateMemberQuote
  );
}

function* onStartPhoneNumberVerify() {
  yield takeEvery(
    memberActionTypes.MEMBER_START_GET_CODE_TO_VERIFY_PHONE_NUMBER,
    startPhoneNumberVerify
  );
}
function* onCheckPhoneNumberVerify() {
  yield takeEvery(
    memberActionTypes.MEMBER_START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER,
    checkPhoneNumberVerify
  );
}

export function* memberSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onstartLoadingOccupationsFromDB),
    call(onFetchAllMembersFromDB),
    call(onFetchMemberFromDB),
    call(onMemberSaveStart),
    call(onMemberUpdateStart),
    call(onMemberDeleteStart),
    call(onMemberQuoteSaveStart),
    call(onMemberQuoteUpdateStart),
    call(onFetchPrimeMemberFromDB),
    call(onStartPhoneNumberVerify),
    call(onCheckPhoneNumberVerify),
  ]);
}
