import React from 'react';
import { PRODUCTS_CMS_DATA } from './product.cms';
import { Container, Card, Col, Row } from 'react-bootstrap';

const ProductCTA = () => {
  const { products } = PRODUCTS_CMS_DATA;
  const screenW = window.innerWidth;

  return (
    <section id='product-section'>
      <Container>
        <h3 className='display-b3 w-100 mt-0 pt-0 text-m-purple'>Products</h3>
        {products.map((product, i) => (
          <Row
            key={i}
            className={
              i !== products.length - 1
                ? 'align-items-center justify-content-between border-blue py-1'
                : 'align-items-center justify-content-between py-1'
            }
          >
            <Col
              lg={ i % 2 === 0 ? {order: 'first'} : {order: 'last'}}
              xs={12}
              className={
                i % 2 === 0 && screenW > 925
                  ? 'text-left'
                  : i % 2 !== 0 && screenW > 925 
                    ? 'text-right'
                    : 'text-center'
              }
            >
              <Card.Img
                src={require(`${product.imageUrl}`)}
                alt={product.title}
                width='280'
                height='320'
                className={screenW < 500 ? 'w-100' : 'w-75 m-auto'}
              />
            </Col>
            <Col
              lg={5}
              xs={12}
            >
              <div>
                <h4 className='py-3 text-m-purple text-center'>{product.title}</h4>
                <p>{product.text}</p>
              </div>
            </Col>
          </Row> 
        ))}
      </Container>
    </section>
  );
};

export default ProductCTA;