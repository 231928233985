import { PROPERTY_QUOTES_CMS_DATA } from '../../../pages/quotes/property/property.cms';

export const convertPropertyForDBSave = (data) => {
  const {
    form: {
      property: { purpose, details, construction, ownership },
    },
  } = PROPERTY_QUOTES_CMS_DATA;

  const purposes = purpose.type.dropdown;
  const residences = purpose.subType.residence.dropdown;
  const businesses = purpose.subType.business.dropdown;
  const crop = purpose.subType.farm.dropdown;
  const ownerships = details.ownershipType.dropdown;
  const responsibilities = details.responsibilityType.dropdown;
  const residentialProperties = details.propertyType.isResidence.dropdown;
  const businessProperties = details.propertyType.isBusiness.dropdown;
  const roofYearTypes = construction.roof.year.dropdown;
  const hasBasement = details.basement.exists.dropdown;
  const hasRenovations = ownership.renovation.dropdown;

  const payload = {
    purpose: {
      residence: data.purpose === purposes[0].value,
      business: data.purpose === purposes[1].value,
      both: data.purpose === purposes[2].value,
    },
    residenceType: {
      homeResidence: data.residenceType === residences[0].value,
      dorm: data.residenceType === residences[1].value,
      forDependent: data.residenceType === residences[2].value,
    },
    businessType: {
      farming: {
        isTypeOf:
          data.businessType !== null
            ? data.businessType === businesses[0].value
            : false,
        isCrop:
          data.isCrop !== null
            ? data.isCrop === crop[0].value
            : false,
        farmSize:
          data.businessType !== null &&
            data.businessType === businesses[0].value
            ? data.farmSize
            : '',
        farmingInfo:
          data.businessType !== null &&
            data.businessType === businesses[0].value
            ? data.businessTypeElaboration
            : '',
      },
      garage: {
        isTypeOf:
          data.businessType !== null
            ? data.businessType === businesses[1].value
            : false,
        garageInfo:
          data.businessType !== null &&
            data.businessType === businesses[1].value
            ? data.businessTypeElaboration
            : '',
      },
      office: {
        isTypeOf:
          data.businessType !== null
            ? data.businessType === businesses[2].value
            : false,
        officeInfo:
          data.businessType !== null &&
            data.businessType === businesses[2].value
            ? data.businessTypeElaboration
            : '',
      },
      rental: {
        isTypeOf:
          data.businessType !== null
            ? data.businessType === businesses[3].value
            : false,
        rentalInfo:
          data.businessType !== null &&
            data.businessType === businesses[3].value
            ? data.businessTypeElaboration
            : '',
      },
      shop: {
        isTypeOf:
          data.businessType !== null
            ? data.businessType === businesses[4].value
            : false,
        shopInfo:
          data.businessType !== null &&
            data.businessType === businesses[4].value
            ? data.businessTypeElaboration
            : '',
      },
      other: {
        isTypeOf:
          data.businessType !== null
            ? data.businessType === businesses[5].value
            : false,
        otherInfo:
          data.businessType !== null &&
            data.businessType === businesses[5].value
            ? data.businessTypeElaboration
            : '',
      },
    },
    address: {
      streetAddress: data.streetAddress,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
    },
    ownership: {
      own: data.ownershipType === ownerships[0].value,
      finance: data.ownershipType === ownerships[1].value,
      lease: data.ownershipType === ownerships[2].value,
    },
    responsibility: {
      entireStructure:
        data.responsibilityType !== null
          ? data.responsibilityType === responsibilities[0].value
          : false,
      interiorOnly:
        data.responsibilityType !== null
          ? data.responsibilityType === responsibilities[1].value
          : false,
    },
    housingProperty: {
      house:
        residentialProperties !== null
          ? data.propertyType === residentialProperties[0].value
          : false,
      townHome:
        residentialProperties !== null
          ? data.propertyType === residentialProperties[1].value
          : false,
      multiplex:
        residentialProperties !== null
          ? data.propertyType === residentialProperties[2].value
          : false,
      apartment:
        residentialProperties !== null
          ? data.propertyType === residentialProperties[3].value
          : false,
      condo:
        residentialProperties !== null
          ? data.propertyType === residentialProperties[4].value
          : false,
      tinyHouse:
        residentialProperties !== null
          ? data.propertyType === residentialProperties[5].value
          : false,
      mobileHome:
        residentialProperties !== null
          ? data.propertyType === residentialProperties[6].value
          : false,
    },
    businessProperty: {
      house:
        businessProperties !== null
          ? data.propertyType === businessProperties[0].value
          : false,
      townHome: {
        isTypeOf:
          businessProperties !== null
            ? data.propertyType === businessProperties[1].value
            : false,
        noOfUnits:
          data.noOfUnits === ''
            ? ''
            : data.propertyType === businessProperties[1].value
              ? data.noOfUnits
              : '',
      },
      multiplex: {
        isTypeOf:
          businessProperties !== null
            ? data.propertyType === businessProperties[2].value
            : false,
        noOfUnits:
          data.noOfUnits === ''
            ? ''
            : data.propertyType === businessProperties[2].value
              ? data.noOfUnits
              : '',
      },
      apartment:
        businessProperties !== null
          ? data.propertyType === businessProperties[3].value
          : false,
      apartmentComplex: {
        isTypeOf:
          businessProperties !== null
            ? data.propertyType === businessProperties[4].value
            : false,
        noOfUnits:
          data.noOfUnits === ''
            ? ''
            : data.propertyType === businessProperties[4].value
              ? data.noOfUnits
              : '',
        noOfBuildings:
          data.noOfBuildings === ''
            ? ''
            : data.propertyType === businessProperties[4].value
              ? data.noOfBuildings
              : '',
      },
      condo:
        businessProperties !== null
          ? data.propertyType === businessProperties[5].value
          : false,
      condoBuilding: {
        isTypeOf:
          businessProperties !== null
            ? data.propertyType === businessProperties[6].value
            : false,
        noOfUnits:
          data.noOfUnits === ''
            ? ''
            : data.propertyType === businessProperties[6].value
              ? data.noOfUnits
              : '',
      },
      tinyHouse:
        businessProperties !== null
          ? data.propertyType === businessProperties[7].value
          : false,
      mobileHome:
        businessProperties !== null
          ? data.propertyType === businessProperties[8].value
          : false,
      smallBusinessBuilding:
        businessProperties !== null
          ? data.propertyType === businessProperties[9].value
          : false,
      unitInABuilding: {
        isTypeOf:
          businessProperties !== null
            ? data.propertyType === businessProperties[10].value
            : false,
        noOfUnits:
          data.noOfUnits === ''
            ? ''
            : data.propertyType === businessProperties[10].value
              ? data.noOfUnits
              : '',
      },
      barn:
        businessProperties !== null
          ? data.propertyType === businessProperties[11].value
          : false,
      noStructures:
        businessProperties !== null
          ? data.propertyType === businessProperties[12].value
          : false,
    },
    yearObtained: data.yearObtained ? data.yearObtained : '',
    frameType: data.frame ? data.frame : [],
    wallType: data.wall ? data.wall : [],
    roofType: {
      shape: data.roofShape ? data.roofShape : [],
      material: data.roofMaterial ? data.roofMaterial : [],
      yearType: {
        fifteen: data.roofYear === roofYearTypes[0].value,
        twenty: data.roofYear === roofYearTypes[1].value,
        twentyFive: data.roofYear === roofYearTypes[2].value,
        thirty: data.roofYear === roofYearTypes[3].value,
        fourty: data.roofYear === roofYearTypes[4].value,
        fiftyPlus: data.roofYear === roofYearTypes[5].value,
      },
      yearOfInstallation: data.roofInstallationYear ? data.roofInstallationYear : '',
    },
    foundationType: data.basementFoundation ? data.basementFoundation : [],
    basement: {
      hasBasement: data.hasBasement === hasBasement[0].value,
      noOfBasements: data.noOfBasements ? data.noOfBasements : '',
    },
    ownershipDetails: {
      valueOfStructure: data.value ? data.value : '',
      yearBuilt: data.year ? data.year : '',
      squareFootage: data.area ? data.area : '',
      noOfStories: data.stories ? data.stories : '',
      renovations: data.hasRenovations === hasRenovations[0].value,
      renovationDetails: data.renovationDetails,
    },
    rentalDetails: {
      contentsCoverage:
        data.requiredCoverage ? data.requiredCoverage : '',
      liabilityCoverage:
        data.requiredLiability ? data.requiredLiability : '',
    },
    scheduledItemsInfo: data.scheduledItemsInfo ? data.scheduledItemsInfo : '',
    scheduledItems: data.scheduledItems ? data.scheduledItems : [],
    otherConstructionInfo: data.otherConstructionInfo,
    primeMemberId: data.primeMemberId ? data.primeMemberId : '',
  };
  return payload;
};


