import axios from "axios";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { configureAxios } from "../../helpers/configure-axios";
import {
  agentUpdateFailure,
  agentUpdateSuccess,
  agentUpdateLastStepFailure,
  agentUpdateLastStepSuccess,
  fetchAgentFromDBFailure,
  fetchAgentFromDBSuccess,
  fetchAllAgentsFromDBSuccess,
  fetchAllAgentsFromDBFailure,
  loadCarriersFromDBFailure,
  loadCarriersFromDBSuccess,
  loadOccupationsFromDBFailure,
  loadOccupationsFromDBSuccess,
  loadStatesFromDBFailure,
  loadStatesFromDBSuccess,
  fetchVerifyAgentFromDBSuccess,
  fetchVerifyAgentFromDBFailure,
  checkoutPaypalSuccess,
  checkoutPaypalFailure,
  getPersonFromVeriffSuccess,
  getPersonFromVeriffFailure,
  getCodeToVerifyPhoneNumberSuccess,
  getCodeToVerifyPhoneNumberFailure,
  checkCodeToVerifyPhoneNumberSuccess,
  checkCodeToVerifyPhoneNumberFailure,
} from "./agent.actions";
import agentActionTypes from "./agent.types";
import { convertAgentForDBSave } from "./agent.utils";

function* loadStatesFromDB() {
  try {
    let states = [];
    const url = "./db/regions.json";
    const response = yield axios.get(url);
    states = yield response.data;
    yield put(loadStatesFromDBSuccess(states));
  } catch (e) {
    yield put(loadStatesFromDBFailure(e));
  }
}

function* loadCarriersFromDB() {
  try {
    let carriers = [];
    const url = "./db/carriers.json";
    const response = yield axios.get(url);
    carriers = yield response.data;
    yield put(loadCarriersFromDBSuccess(carriers));
  } catch (e) {
    yield put(loadCarriersFromDBFailure(e));
  }
}

function* loadOccupationsFromDB() {
  try {
    let occupations = [];
    const url = "./db/occupations.json";
    const response = yield axios.get(url);
    occupations = yield response.data;
    yield put(loadOccupationsFromDBSuccess(occupations));
  } catch (e) {
    yield put(loadOccupationsFromDBFailure(e));
  }
}

function* fetchAllAgentsFromDB() {
  try {
    const response = yield axios.get(`/api/agents`);
    const agents = yield response.data;
    yield put(fetchAllAgentsFromDBSuccess(agents));
  } catch (e) {
    yield put(fetchAllAgentsFromDBFailure(e));
  }
}

function* fetchAgentFromDB({ payload: id }) {
  try {
    const response = yield axios.get(`/api/agents/${id}`);
    const agent = yield response.data.agent;
    yield put(fetchAgentFromDBSuccess(agent));
  } catch (e) {
    yield put(fetchAgentFromDBFailure(e));
  }
}

function* fetchVerifyAgentFromDB({ payload: { data } }) {
  try {
    const url = `/api/agents/${data.id}/${data.authNipr}/verify`;
    const response = yield axios.get(url);
    const verify = yield response.data;
    yield put(fetchVerifyAgentFromDBSuccess(verify));
  } catch (e) {
    yield put(fetchVerifyAgentFromDBFailure(e));
  }
}

function* checkoutPaypal({ payload: { id, data } }) {
  try {
    const url = `/api/agents/${id}/checkout`;
    const response = yield axios.post(url, data, configureAxios());
    const agent = yield response.data.agent;
    yield put(checkoutPaypalSuccess(agent));
  } catch (e) {
    yield put(checkoutPaypalFailure(e));
  }
}

function* updateAgent({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentForDBSave, formData);
    const response = yield axios.patch(
      `/api/agents/${id}`,
      data,
      configureAxios()
    );
    const agent = yield JSON.parse(response.config.data);
    yield put(fetchAgentFromDBSuccess(agent));
    yield put(agentUpdateSuccess());
  } catch (e) {
    yield put(agentUpdateFailure(e));
  }
}

function* updateAgentLastStep({ payload: { id, formData } }) {
  try {
    const data = yield call(convertAgentForDBSave, formData);
    const response = yield axios.patch(
      `/api/agents/${id}`,
      data,
      configureAxios()
    );
    const agent = yield JSON.parse(response.config.data);
    yield put(fetchAgentFromDBSuccess(agent));
    yield put(agentUpdateLastStepSuccess(agent));
  } catch (e) {
    yield put(agentUpdateLastStepFailure(e));
  }
}

function* startPhoneNumberVerify({ payload: { id, data } }) {
  try {
    const url = `/api/agents/${id}/${data.phoneNumber}/start-verify`;
    const response = yield axios.get(url);
    const verification = yield response.data.verificationRequest;
    yield put(getCodeToVerifyPhoneNumberSuccess(verification));
  } catch (e) {
    yield put(getCodeToVerifyPhoneNumberFailure(e.response.data));
  }
}

function* checkPhoneNumberVerify({ payload: { id, data } }) {
  try {
    const url = `/api/agents/${id}/phone/check-verify`;
    const response = yield axios.post(url, data, configureAxios());
    const codeVerified = yield response.data;

    yield put(checkCodeToVerifyPhoneNumberSuccess(codeVerified));
  } catch (e) {
    yield put(checkCodeToVerifyPhoneNumberFailure(e.response.data));
  }
}

function* getPersonInfoFromVeriff({ payload: { id, veriffId } }) {
  try {
    const url = `/api/agents/${id}/${veriffId}/veriff/person`;
    const response = yield axios.get(url);
    const person = yield response.data;

    yield put(getPersonFromVeriffSuccess(person));
  } catch (e) {
    if (e.response) {
      yield put(getPersonFromVeriffFailure(e.response.data));
    }
  }
}

function* onStartLoadingStatesFromDB() {
  yield takeEvery(agentActionTypes.LOAD_STATES_FROM_DB_START, loadStatesFromDB);
}

function* onStartloadingCarriersFromDB() {
  yield takeEvery(
    agentActionTypes.LOAD_AGENT_CARRIERS_FROM_DB_START,
    loadCarriersFromDB
  );
}

function* onStartLoadingOccupationsFromDB() {
  yield takeEvery(
    agentActionTypes.LOAD_OCCUPATIONS_FROM_DB_START,
    loadOccupationsFromDB
  );
}

function* onFetchAllAgentsFromDB() {
  yield takeEvery(
    agentActionTypes.FETCH_ALL_AGENTS_FROM_DB_START,
    fetchAllAgentsFromDB
  );
}

function* onFetchAgentFromDB() {
  yield takeEvery(agentActionTypes.FETCH_AGENT_FROM_DB_START, fetchAgentFromDB);
}

function* onFetchVerifyAgentFromDB() {
  yield takeEvery(
    agentActionTypes.FETCH_VERIFY_AGENT_START,
    fetchVerifyAgentFromDB
  );
}

function* onCheckoutPaypalStart() {
  yield takeEvery(agentActionTypes.START_CHECKOUT_PAYPAL, checkoutPaypal);
}

function* onAgentUpdateStart() {
  yield takeEvery(agentActionTypes.START_AGENT_UPDATE, updateAgent);
}

function* onAgentUpdateLastStepStart() {
  yield takeEvery(
    agentActionTypes.START_AGENT_UPDATE_LAST_STEP,
    updateAgentLastStep
  );
}

function* onStartPhoneNumberVerify() {
  yield takeEvery(
    agentActionTypes.START_GET_CODE_TO_VERIFY_PHONE_NUMBER,
    startPhoneNumberVerify
  );
}

function* onCheckPhoneNumberVerify() {
  yield takeEvery(
    agentActionTypes.START_CHECK_CODE_TO_VERIFY_PHONE_NUMBER,
    checkPhoneNumberVerify
  );
}

function* onGetPersonInfoFromVeriff() {
  yield takeEvery(
    agentActionTypes.START_GET_PERSON_FROM_VERIFF,
    getPersonInfoFromVeriff
  );
}

export function* agentSagas() {
  yield all([
    call(onStartLoadingStatesFromDB),
    call(onStartloadingCarriersFromDB),
    call(onStartLoadingOccupationsFromDB),
    call(onAgentUpdateStart),
    call(onFetchAgentFromDB),
    call(onFetchAllAgentsFromDB),
    call(onAgentUpdateLastStepStart),
    call(onFetchVerifyAgentFromDB),
    call(onCheckoutPaypalStart),
    call(onStartPhoneNumberVerify),
    call(onCheckPhoneNumberVerify),
    call(onGetPersonInfoFromVeriff),
  ]);
}
